import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ClickAwayListener, createStyles, makeStyles, Theme } from "@material-ui/core";
import Tooltip from "@amplyfi/ui-components/components/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      cursor: "pointer",
      color: theme.palette.primary.contrastText,
    },
    tooltip: {
      backgroundColor: theme.palette.componentBackground.main,
      padding: theme.spacing(2),
      borderRadius: 10,
      minWidth: 300,
      boxShadow: `0 0px 10px ${theme.palette.borders.dark}`,
      fontSize: 30,
      maxHeight: 300,
      overflow: "auto",
    },
    tooltipArrow: {
      color: theme.palette.componentBackground.main,
    },
  })
);

export default function ViewMoreButton(props: { children?: JSX.Element }): JSX.Element {
  const { children } = props;
  const classes = useStyles();
  const [viewPopover, setViewPopover] = useState(false);
  const title = <div>{children}</div>;

  return (
    <ClickAwayListener onClickAway={() => setViewPopover(false)} mouseEvent={"onMouseUp"}>
      <div>
        <Tooltip
          title={title}
          interactive
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={viewPopover}
          onClose={() => setViewPopover(false)}
          arrow
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.tooltipArrow,
          }}
        >
          <MoreVertIcon onClick={() => setViewPopover(true)} className={classes.icon} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
