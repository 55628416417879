import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PositionedKeyPhrase, TimelinePoint, KeyPhrase } from "../../../models/keyPhrase";
import { resetState } from "../searchRequest/actions";

interface OverviewState {
  keyPhrasesLoading: boolean;
  keyPhrasesSearchError: boolean;
  keyPhrasesSearchResults: PositionedKeyPhrase[] | null;
}

const initialState: OverviewState = {
  keyPhrasesLoading: false,
  keyPhrasesSearchError: false,
  keyPhrasesSearchResults: null,
};

const searchSlice = createSlice({
  name: "overview",
  initialState: initialState,
  reducers: {
    onKeyPhrasesSearchLoading: (state: OverviewState, action: PayloadAction<string>) => {
      state.keyPhrasesLoading = true;
      state.keyPhrasesSearchError = false;
    },
    onKeyPhrasesSearchSuccess: (state: OverviewState, action: PayloadAction<PositionedKeyPhrase[]>) => {
      state.keyPhrasesLoading = false;
      state.keyPhrasesSearchResults = deduplicate(action.payload);
    },
    onKeyPhrasesSearchError: (state: OverviewState) => {
      state.keyPhrasesSearchError = true;
      state.keyPhrasesLoading = false;
    },
    resetKeyPhrasesSearch: (state: OverviewState) => {
      state.keyPhrasesSearchError = false;
      state.keyPhrasesLoading = false;
      state.keyPhrasesSearchResults = null;
    },
  },
  extraReducers: {
    [resetState.type]: function () {
      return initialState;
    },
  },
});

export const {
  onKeyPhrasesSearchLoading,
  onKeyPhrasesSearchSuccess,
  onKeyPhrasesSearchError,
  resetKeyPhrasesSearch,
} = searchSlice.actions;

export default searchSlice.reducer;

function deduplicate(...topics: KeyPhrase[][]) {
  return topics
    .flat()
    .reduce<KeyPhrase[]>((accum, current) => {
      const matching = accum.find((x) => x.name === current.name);
      if (matching) {
        matching.prevalence += current.prevalence;
      } else {
        accum.push(current);
      }
      return accum;
    }, [])
    .sort((a, b) => b.prevalence - a.prevalence);
}

export function addInMissingDates(dates: string[], points: TimelinePoint[]): TimelinePoint[] {
  if (points.length > 0) {
    dates.forEach((date) => {
      const point = points.find((x) => x.startDate === date);
      if (!point) {
        points.push({ documentCount: 0, corpusSignificance: 0, startDate: date });
      }
    });
    return points.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
  }
  return [];
}
