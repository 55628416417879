import { createContext, useState } from "react";
import { List } from "react-virtualized";
import { VirtuosoHandle } from "react-virtuoso";

interface AlertListState {
  list: List | undefined;
  insightList: VirtuosoHandle | null;
  scrollToIndex: (index: number) => void;
}

interface AlertListStateProps {
  children: React.ReactNode;
}

export const AlertListContext = createContext<[AlertListState, (state: AlertListState) => void]>([
  { list: undefined, insightList: null, scrollToIndex: () => void 0 },
  () => void 0,
]);

export function AlertListStateProvider(props: AlertListStateProps) {
  const [state, setState] = useState<AlertListState>({
    list: undefined,
    insightList: null,
    scrollToIndex: () => void 0,
  });
  return <AlertListContext.Provider value={[state, setState]}>{props.children}</AlertListContext.Provider>;
}
