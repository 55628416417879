import { InputBase, MenuItem, Select, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import { LinkBlueElectric, White } from "@amplyfi/ui-components/theme/colors";
import React, { useCallback, useState } from "react";
import { EntityType } from "../../../models/entity";
import { SearchType } from "../../../models/search";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typeSelectInput: (props: SearchTypeSelectorProps) => ({
      height: "100%",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      border: "none",
      background: theme.palette.links.primary,
      color: White,

      "& .MuiSelect-icon": {
        color: White,
      },
      "& .MuiSelect-select.MuiSelect-select": {
        display: "flex",
        alignItems: "center",
        paddingRight: theme.spacing(2),
        fontSize: props.small ? 0 : "unset",
      },
      "& .MuiListItemIcon-root": {
        display: props.small ? "unset" : "none",
        position: "relative",
        top: 3,
        color: White,
      },
    }),
    menuAnchor: {
      position: "absolute",
      bottom: -20,
    },
    menuPaper: {
      background: LinkBlueElectric,
    },
    menuList: {
      borderRadius: 10,
      "& .MuiListItem-root": {
        color: White,
        "& .MuiListItemIcon-root": {
          color: White,
          minWidth: 35,
        },
      },
    },
    container: {
      position: "relative",
    },
    label: {
      color: White,
    },
    menuItem: {
      display: "flex",
    },
    icon: {
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(1),
    },
  })
);

interface SearchTypeListItem {
  type: SearchType;
  label: string;
  icon: JSX.Element;
}

const searchTypeList: SearchTypeListItem[] = [
  {
    type: "FreeText",
    label: "Free text",
    icon: <SearchIcon />,
  },
  {
    type: EntityType.Organisation,
    label: "Organisations",
    icon: <BusinessIcon />,
  },
  {
    type: EntityType.Person,
    label: "People",
    icon: <PersonIcon />,
  },
  {
    type: EntityType.Country,
    label: "Locations",
    icon: <LocationOnIcon />,
  },
];

interface SearchTypeSelectorProps {
  type: SearchType | null;
  setSearchType: (type: SearchType) => void;
  small?: boolean;
  list?: SearchTypeListItem[];
}

export default function SearchTypeSelector(props: SearchTypeSelectorProps): JSX.Element {
  const { type, setSearchType, list = searchTypeList } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useCallback((node) => {
    if (node !== null) {
      setAnchorEl(node);
    }
  }, []);
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <Select
        id="search-type-selector"
        onChange={(e) => {
          setSearchType(e.target.value as SearchType);
        }}
        value={type}
        MenuProps={{
          getContentAnchorEl: null,
          anchorEl: anchorEl,
          PaperProps: {
            className: classes.menuPaper,
          },
          MenuListProps: {
            className: classes.menuList,
          },
          anchorOrigin: {
            horizontal: "left",
            vertical: "bottom",
          },
        }}
        input={<InputBase className={classes.typeSelectInput} />}
      >
        {list.map(({ type, label, icon }) => {
          return (
            <MenuItem key={type} value={type} className={classes.menuItem}>
              <div className={classes.icon}>{icon}</div>
              <Typography variant="h4" className={classes.label}>
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
      <div ref={menuRef} className={classes.menuAnchor} />
    </div>
  );
}
