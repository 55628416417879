import { useTheme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import React from "react";

interface LoadingAdornmentProps {
  loading: boolean;
}

export default function LoadingAdornment(props: LoadingAdornmentProps): JSX.Element {
  const loading = props.loading;
  const theme = useTheme();
  const iconStyle: React.CSSProperties = {
    width: "0.9em",
    height: "0.9em",
    position: "relative",
    left: 8,
  };
  return (
    <InputAdornment style={{ width: 40 }} position="end">
      {loading ? (
        <CircularProgress style={iconStyle} color="inherit" size={20} />
      ) : (
        <Search style={{ ...iconStyle, ...{ color: theme.palette.secondary.contrastText } }} />
      )}
    </InputAdornment>
  );
}
