import { useEffect, useState } from "react";
import parseQAMessage from "../question_answer/parsers";
import useAmplyfiSocketParser from "../sockets/useAmplyfiSocketParser";
import { isInsight } from "../../../helpers/documentHelpers";

export default function useInvestigationSuggestions(libraryId: string, documentId: string) {
  const { socket, status } = useAmplyfiSocketParser(["investigation-questions"]);
  const [suggestions, setSuggestions] = useState<string[]>([""]);
  const [messageSent, setSent] = useState(false);

  useEffect(() => {
    if (libraryId && documentId && !messageSent) {
      socket.sendJsonMessage({
        action: "generative",
        type: "get-investigation-questions",
        libraryId,
        [isInsight(documentId) ? "insightId" : "documentId"]: documentId,
      });
      setSent(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryId, documentId]);

  useEffect(() => {
    if (socket.lastJsonMessage) {
      const { message } = parseQAMessage({ message: socket.lastJsonMessage, messages: [] });
      setSuggestions((prevSuggestions) => {
        const lastSuggestion = prevSuggestions[prevSuggestions.length - 1];
        if (message.endsWith("||")) {
          return [...prevSuggestions, ""];
        }
        return [
          ...prevSuggestions.slice(0, prevSuggestions.length - 1),
          `${lastSuggestion}${message.replace("||", "")}`,
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket.lastJsonMessage]);
  return { suggestions, status, socket };
}
