import moment from "moment";

export type AlertHour =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;

export enum AlertPeriod {
  morning = "Morning",
  afternoon = "Afternoon",
  evening = "Evening",
}

const TIMEZONE_OFFSET: number = Math.round(moment().utcOffset() / 60);

export const convertAlertHourToLocale = (
  alertHourUtc: AlertHour,
  utcOffsetHour: number = TIMEZONE_OFFSET
): AlertHour => {
  let offsetHour = alertHourUtc + utcOffsetHour;

  if (offsetHour >= 24) {
    offsetHour = offsetHour % 24;
  } else if (offsetHour < 0) {
    offsetHour = 24 + (offsetHour % 24);
  }
  return offsetHour as AlertHour;
};

export const convertAlertHourToUtc = (alertHourLocale: AlertHour, utcOffsetHour: number = TIMEZONE_OFFSET): AlertHour =>
  convertAlertHourToLocale(alertHourLocale, -1 * utcOffsetHour);

export const convertAlertHourToAlertPeriod = (alertHour: AlertHour): AlertPeriod => {
  if (alertHour < 12) {
    return AlertPeriod.morning;
  }
  if (alertHour < 18) {
    return AlertPeriod.afternoon;
  }
  return AlertPeriod.evening;
};

export const friendlyAlertHour = (twentyFourHour: AlertHour): string => {
  const twelveHours = twentyFourHour > 12 ? twentyFourHour - 12 : twentyFourHour;
  return [twelveHours < 10 ? "0" : "", twelveHours, ":00", twentyFourHour < 12 ? "am" : "pm"].join("");
};

const defaultPeriodHourLocale: Record<AlertPeriod, AlertHour> = {
  [AlertPeriod.morning]: 8,
  [AlertPeriod.afternoon]: 14,
  [AlertPeriod.evening]: 20,
};

export const defaultPeriodAlertHour = (period: AlertPeriod): AlertHour => defaultPeriodHourLocale[period];

export const selectAlertPeriodOptions: AlertHour[] = [
  defaultPeriodHourLocale.Morning,
  defaultPeriodHourLocale.Afternoon,
  defaultPeriodHourLocale.Evening,
];

export const selectExactAlertHourOptions: AlertHour[] = new Array(24).fill(0).map((_, i) => i as AlertHour);

export const selectDayOptions: string[] = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
