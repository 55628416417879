import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Box, Divider, Typography, List, ListItem } from "@material-ui/core";
import { Close as QuestionInvalidIcon, Check as QuestionValidIcon } from "@material-ui/icons";
import { useEffect, useState } from "react";
import useVerifyQuestion, {
  ApiResponseQuestionVerification,
} from "../../../../../../../hooks/queries/reports/useVerifyQuestion";
import { useAmplyfiEntityId } from "../../../../../../../hooks/useAmplyfiEntities";
import useDebounce from "../../../../../../../hooks/useDebounce";
import useWizardState from "../../../../../../../hooks/useWizardState";
import TextEntryField from "../../../../Configuration/Components/TextEntryField";
import Stack from "../../../stack/Stack";
import { CreateReportWizardState } from "../CreateReportWizard";

function getValidIcon(data: ApiResponseQuestionVerification | undefined) {
  if (!data) {
    return undefined;
  }
  return data.question_valid ? <QuestionValidIcon /> : <QuestionInvalidIcon />;
}

export default function ReportQuestionPage() {
  const [state, setState] = useWizardState<CreateReportWizardState>("create-report");
  const { data: entity } = useAmplyfiEntityId(state.selectedEntityId || undefined);
  const [reportQuestion, setReportQuestion] = useState(state.reportQuestion);
  const debouncedText = useDebounce(reportQuestion as string, 1000);
  const { data, isLoading } = useVerifyQuestion(state.selectedEntityId as string, state.reportQuestion as string);

  useEffect(() => {
    if (data) {
      setState({ ...state, nextDisabled: !data.question_valid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setState({ ...state, reportQuestion: debouncedText });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  return (
    <Box padding={4}>
      <Stack spacing={2}>
        <Typography variant="h2">Enter your research question</Typography>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 12 }}>
          <Typography variant="body1">Selected Entity:</Typography>
          <Typography
            style={{
              border: "1px solid #f0f0f0",
              borderRadius: 5,
              paddingInline: 8,
              paddingBlock: 4,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.10)",
            }}
            variant="h4"
          >
            {entity?.name}
          </Typography>
        </Box>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 12 }}>
          <Typography variant="body1">Selected Time Frame: </Typography>
          <Typography
            style={{
              border: "1px solid #f0f0f0",
              borderRadius: 5,
              paddingInline: 8,
              paddingBlock: 4,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.10)",
            }}
            variant="h4"
          >
            {state.timeFrame === "custom"
              ? ("" + state.startDate).split("T")[0] + " to " + ("" + state.endDate).split("T")[0]
              : state.timeFrame}
          </Typography>
        </Box>
        <TextEntryField
          onChange={(e) => {
            setReportQuestion(e.target.value);
            setState({ ...state, nextDisabled: true });
          }}
          defaultValue={state.reportQuestion}
          fullWidth
          label="Enter a research question"
          InputProps={{
            endAdornment: isLoading ? <AiCircularProgress useContainer={false} size={14} /> : getValidIcon(data),
          }}
        />
        {data?.question_valid === false && !isLoading && (
          <>
            <Typography style={{ marginTop: 16 }} variant="h4">
              Question Invalid
            </Typography>
            <Typography variant="body2">{data.explanation}</Typography>
            <Divider />
          </>
        )}
        <Typography variant="body2">
          <em>
            After entering a question it’s verified to ensure it can be answered using the information present in your
            feed.
          </em>
        </Typography>
        <List>
          <ListItem>
            <Typography variant="h4">Good practice guidance:</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <strong>Be specific:</strong> The more specific your question is, the more relevant the report will
                  be.
                </li>
              </ul>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  <strong> Avoid ambiguity:</strong> Make sure your questions are clear and unambiguous; vague questions
                  can lead to broad or irrelevant answers.
                </li>
              </ul>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h4">Examples of good questions:</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  "What are the latest developments in electric vehicle technology at Tesla and how do they compare with
                  traditional automotive companies?"
                </li>
              </ul>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <ul style={{ listStyleType: "disc" }}>
                <li>
                  "What impact has the global chip shortage had on consumer electronics and automotive industries?"
                </li>
              </ul>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <ul style={{ listStyleType: "disc" }}>
                <li>"How have new AI developments impacted pharmaceutical companies?"</li>
              </ul>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h4">Examples of questions that are too broad or ambiguous:</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <ul style={{ listStyleType: "disc" }}>
                <li>"List all of the companies with a major presence in the automotive market" </li>
              </ul>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              <ul style={{ listStyleType: "disc" }}>
                <li>"What are all of the uses of generative AI?"</li>
              </ul>
            </Typography>
          </ListItem>
        </List>
      </Stack>
    </Box>
  );
}
