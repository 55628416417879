import { createStyles, IconButton, makeStyles, StyleRules, Theme, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { Harvest } from "../../../../models/harvest";
import { dateFormat } from "../../../../helpers/dateHelpers";
import AiChip from "@amplyfi/ui-components/components/Chip";
import {
  Chardonnay,
  Positive,
  LightSilver,
  PastelHighlightPurple,
  Negative,
} from "@amplyfi/ui-components/theme/colors";
import clsx from "clsx";
import { FileCopy as FileCopyIcon } from "@material-ui/icons";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { getStatus } from "../helpers/harvest";
import { HARVEST_ID_PARAM, ORGANISATION_ID_PARAM } from "../helpers/queryParams";
import { HarvestConfig } from "../models";

interface Column {
  textAlign?: string;
  style?: CSSProperties;
  Decorator: React.FC<{ harvest: Harvest; harvestConfig?: HarvestConfig }>;
  header: React.ReactNode;
  onClick?: (harvest: Harvest) => () => void;
  width: number;
}

const useStyles = makeStyles(({ spacing, typography }: Theme) => {
  const styles: StyleRules = {
    chip: {
      cursor: "inherit",
    },
    chipComplete: {
      background: Positive,
      "& .MuiChip-label": {
        color: "#000",
      },
    },
    chipHarvesting: {
      background: Chardonnay,
      color: "inherit",
      "& .MuiChip-label": {
        color: "#000",
      },
    },
    chipProcessing: {
      background: PastelHighlightPurple,
      color: "inherit",
      "& .MuiChip-label": {
        color: "#000",
      },
    },
    chipQueued: {
      background: LightSilver,
      color: "inherit",
      "& .MuiChip-label": {
        color: "#000",
      },
    },
    chipFailed: {
      background: Negative,
      "& .MuiChip-label": {
        color: "#000",
      },
    },
    chipUnknown: {
      background: LightSilver,
      "& .MuiChip-label": {
        color: "#000",
      },
    },
    estimatedCount: {
      fontSize: 10,
    },
    harvestDetails: {
      cursor: "pointer",
    },
    icon: {
      padding: spacing(0.5),
    },
    iconButton: {
      float: "left",
      verticalAlign: "middle",
    },
    paragraph: {
      cursor: "pointer",
      padding: spacing(0.5),
    },
    tooltip: {
      fontSize: typography.h6.fontSize,
      position: "relative",
      top: -1 * spacing(1.5),
    },
  };
  return createStyles(styles);
});

const copy = navigator.clipboard;

export const COLUMNS: Column[] = [
  {
    Decorator: ({ harvest: { query } }) => {
      const classes = useStyles();

      return (
        <>
          {!!copy && (
            <IconButton size="small" className={classes.iconButton}>
              <FileCopyIcon className={classes.icon} />
            </IconButton>
          )}
          <Tooltip arrow placement="bottom-start" title={query} classes={{ tooltip: classes.tooltip }}>
            <Typography className={classes.paragraph}>{query}</Typography>
          </Tooltip>
        </>
      );
    },
    header: "Harvest Query",
    onClick: !!copy
      ? ({ query }: Harvest) =>
          () =>
            copy.writeText(query)
      : undefined,
    style: {
      color: "blue",
      letterSpacing: 1.1,
    },
    width: 26,
  },
  {
    Decorator: ({ harvest: { libraryId, libraryName } }) => <Typography>{libraryName || libraryId}</Typography>,
    header: "Library",
    width: 10,
  },
  {
    Decorator: ({ harvest: { organisationId, organisationName } }) => (
      <Typography>{organisationName || organisationId}</Typography>
    ),
    header: "Organisation",
    width: 10,
  },
  {
    Decorator: ({ harvest: { status } }) => {
      const classes = useStyles();
      const { className = "chipUnknown", label = "Unknown" } = getStatus(status);

      return <AiChip chipClasses={clsx(classes.chip, classes[className || "chipUnknown"])} label={label} />;
    },
    header: "Status",
    style: {
      minWidth: 100,
    },
    width: 10,
  },
  {
    Decorator: ({ harvest: { source }, harvestConfig: { name } = {} }) => (
      <Typography>{name || <i>{source}</i>}</Typography>
    ),
    header: "Source",
    width: 10,
  },
  {
    Decorator: ({ harvest: { createdAt } }) => (
      <Typography title={dateFormat(createdAt, { outputFormat: "HH:mm:ss on DD MMM YYYY" })}>
        {dateFormat(createdAt, { outputFormat: "DD MMM YYYY" })}
      </Typography>
    ),
    header: "Created On",
    width: 6,
  },
  {
    textAlign: "right",
    Decorator: ({ harvest: { documentsHarvested, estimatedDocuments, harvestId, organisationId, status } }) => {
      const classes = useStyles();
      const { search } = useLocation();
      const { replace } = useHistory();

      const viewHarvest = (harvest: string, organisation: string) => {
        const query = new URLSearchParams(search);
        query.set(HARVEST_ID_PARAM, harvest);
        query.set(ORGANISATION_ID_PARAM, organisation);
        replace({
          search: query.toString(),
        });
      };

      return (
        <div>
          <Typography className={clsx(classes.harvestDetails)} onClick={() => viewHarvest(harvestId, organisationId)}>
            {status === "processing" || status === "complete" || status === "failed" || status === "harvesting"
              ? documentsHarvested
              : "-"}
            /<span className={classes.estimatedCount}>{estimatedDocuments}</span>
          </Typography>
        </div>
      );
    },
    header: (
      <>
        Harvest Actual/
        <br />
        Estimated
      </>
    ),
    width: 6,
  },
];

const COLUMN_TOTAL_WIDTH = COLUMNS.reduce((acc, { width }) => acc + width, 0);

const getPercentWidth = (width: number): number => Math.round((width / COLUMN_TOTAL_WIDTH) * 100 * 1e2) / 1e2;

export const columnValueClassName = (n: number): string => `columnValue${n}`;
export const columnHeaderClassName = (n: number): string => `columnHeader${n}`;

export const useHavestTableStyles = makeStyles(({ spacing }: Theme) => {
  const styles: StyleRules = {
    column: {
      flex: 1,
      minWidth: 0,
      alignSelf: "center",
      padding: `${spacing(1.5)}px 0`,
      "& p": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    ...COLUMNS.reduce((prev, { textAlign, style, width }, i) => {
      const base = {
        textAlign,
        flexBasis: `${getPercentWidth(width)}%`,
        ...(textAlign === "right" ? { paddingLeft: spacing(1) } : { paddingRight: spacing(1) }),
      };
      return {
        ...prev,
        [columnHeaderClassName(i)]: base,
        [columnValueClassName(i)]: {
          ...base,
          "& p": { ...style },
        },
      };
    }, {}),
  };
  return createStyles(styles);
});
