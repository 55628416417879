import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { DarkGreyBlue, LinkBlueSky, White } from "@amplyfi/ui-components/theme/colors";
import React, { useEffect, useState } from "react";
import { Entity, ExcludeOrInclude } from "../../models/entity";
import { useAnalyseSelector } from "../../store/reducers";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { FilterType, getLabelFromFilterType } from "../../models/filter";
import {
  clearAllFilters,
  onGlobalDatesChange,
  removeOption,
  toggleOption,
} from "../../store/reducers/searchRequest/urlReducer";
import clsx from "clsx";
import { getSelectedFiltersLists } from "../../helpers/getSelectedFiltersList";
import { removeEmptyArraysFromObject } from "../../helpers/removeEmptyArraysFromObject";
import moment from "moment";
import { Days } from "../../helpers/timeIntervals";
import { DEFAULT_DISPLAY_END } from "../../store/reducers/searchRequest/url-functions";
import { queryTypeJoinerMap } from "../Widgets/Filters/FilterDrawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2.5, 3, 0, 3.5),
      margin: theme.spacing(0, -3, 0, -3.5),
      borderTop: `1px solid ${theme.palette.borders.main}`,
      maxHeight: 800,
      overflow: "auto",
    },
    itemColumns: {
      backgroundColor: theme.palette.componentBackground.main,
      padding: theme.spacing(1.5, 1),
      marginTop: theme.spacing(1),
      border: `1px solid ${theme.palette.borders.main}`,
    },
    item: {
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
    },
    itemPlus: {
      "& + &": {
        "&:before": {
          content: "'+'",
          color: White,
          fontWeight: "bold",
          padding: theme.spacing(0, 0.5),
        },
      },
    },
    text: {
      fontWeight: "bold",
      width: "100%",
    },
    icon: {
      width: 18,
      height: 18,
      color: theme.palette.primary.main,
    },
    clearText: {
      color: theme.palette.links.primary,
      alignSelf: "center",
      fontWeight: "bold",
      cursor: "pointer",
    },
    noFiltersText: {
      color: White,
    },
    activeFiltersContainer: {
      borderRadius: 10,
      display: "flex",
      whiteSpace: "nowrap",
    },
    activeFiltersBackground: {
      padding: theme.spacing(1),
      backgroundColor: DarkGreyBlue,
    },
    activeText: {
      display: "flex",
      fontWeight: "bold",
    },
    activeTextColor: {
      color: White,
    },
    activeNumber: {
      color: LinkBlueSky,
      fontWeight: "bold",
      marginRight: theme.spacing(1),
    },
    filtersHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(1.5, 0),
    },
    previewContainer: {
      display: "flex",
      overflow: "hidden",
      width: "100%",
      justifyContent: "space-between",
    },
    previewItemsContainer: {
      position: "relative",
      overflow: "hidden",
      display: "flex",
    },
    previewItemsText: {
      "& h6": {
        color: White,
      },
    },
    previewOverflowDots: {
      "&:after": {
        content: "'...'",
        position: "absolute",
        bottom: "50%",
        transform: "translateY(50%)",
        right: 2,
        color: White,
        fontWeight: "bold",
      },
    },
    previewItems: {
      display: "flex",
      overflow: "hidden",
      marginRight: theme.spacing(1.5),
    },
    plus: {
      padding: theme.spacing(0, 0.5),
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
    dot: {
      height: theme.spacing(1),
      width: theme.spacing(1),
      backgroundColor: "#00FF00",
      borderRadius: "50%",
      display: "inline-block",
      marginRight: theme.spacing(1),
      flex: `0 0 ${theme.spacing(1)}px`,
    },
    redDot: {
      backgroundColor: "#FF0000",
    },
  })
);

interface SelectedFiltersHeaderProps {
  hideHeader?: boolean;
}

export default function SelectedFiltersHeader(props: SelectedFiltersHeaderProps): JSX.Element {
  const { hideHeader } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const urlState = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { generalFilters, academicFilters, newsFilters, patentFilters } = getSelectedFiltersLists(urlState);
  const { startDate, endDate } = urlState;

  const handleClose = (entity: Entity, type: FilterType) => {
    if (type === FilterType.Keywords) {
      dispatch(removeOption({ type, entity }));
    } else {
      dispatch(
        toggleOption({
          entity: entity,
          type: type,
        })
      );
    }
  };

  const handleClearAllFilters = () => {
    dispatch(clearAllFilters());
  };

  const checkHasFilters = (data: Record<string, Entity[]>) => {
    return Object.keys(data).some((filterKey: string) => data[filterKey].length > 0);
  };

  const hasFilters =
    checkHasFilters(generalFilters) ||
    checkHasFilters(newsFilters) ||
    checkHasFilters(academicFilters) ||
    checkHasFilters(patentFilters) ||
    !!startDate ||
    !!endDate;

  return (
    <>
      {!hideHeader && (
        <div className={classes.filtersHeader}>
          <SelectedFilterPreview withoutBackground />
          {hasFilters && (
            <Typography variant="h6" className={classes.clearText} onClick={handleClearAllFilters}>
              Clear filters
            </Typography>
          )}
        </div>
      )}
      <div>
        <div className={classes.container}>
          <GetDateFilters
            startDate={startDate}
            endDate={endDate === Days.Today ? DEFAULT_DISPLAY_END.valueOf() : Number(endDate) || undefined}
          />
          <GetFilters data={generalFilters} label="General" handleClose={handleClose} />
          <GetFilters data={newsFilters} label="News" handleClose={handleClose} />
          <GetFilters data={academicFilters} label="Academic" handleClose={handleClose} />
          <GetFilters data={patentFilters} label="Patents" handleClose={handleClose} />
        </div>
      </div>
    </>
  );
}

export const GetFilters = ({
  data,
  label,
  handleClose,
  inline = false,
}: {
  data: Record<string, Entity[]>;
  label: string;
  handleClose?: (filter: Entity, filterKey: FilterType, itemIndex?: number) => void;
  inline?: boolean;
}): JSX.Element => {
  const clearedData = removeEmptyArraysFromObject(data);
  const classes = useStyles();
  return (
    <>
      {Object.keys(clearedData).map((filterKey: string, listIndex) => {
        const filterList = inline ? data[filterKey].slice(0, 7) : data[filterKey];
        return filterList.map((filter: Entity, itemIndex) => (
          <div
            key={filter.id}
            className={clsx(classes.item, !inline && classes.itemColumns, inline && classes.itemPlus)}
          >
            {!inline && <Filter className={classes.icon} />}
            <Typography variant="h6" className={clsx(classes.text, inline && classes.noWrap)}>
              {label}:{label === "Date" ? filterKey : getLabelFromFilterType(filterKey as FilterType)}:{filter.name}
            </Typography>
            {[FilterType.Sources, FilterType.Locations, FilterType.Sectors].includes(filterKey as FilterType) && (
              <div
                className={clsx(classes.dot, filter.excludeOrInclude === ExcludeOrInclude.Exclude && classes.redDot)}
              />
            )}
            {!!handleClose && (
              <CloseIcon
                onClick={() => handleClose(filter, filterKey as FilterType, filter.index ?? itemIndex)}
                className={classes.icon}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        ));
      })}
    </>
  );
};

export const GetMetionedFilters = ({
  data,
  label,
  handleClose,
  inline = false,
}: {
  data: Record<string, Entity[]>;
  label: string;
  handleClose?: (filter: Entity, filterKey: FilterType, itemIndex?: number) => void;
  inline?: boolean;
}): JSX.Element => {
  const classes = useStyles();
  const clearedData = removeEmptyArraysFromObject(data);
  return (
    <>
      {Object.keys(clearedData).map((filterKey: string) => {
        const filterList = inline ? data[filterKey].slice(0, 7) : data[filterKey];
        return filterList.map((filter: Entity, itemIndex) => (
          <div
            key={filter.id}
            className={clsx(classes.item, !inline && classes.itemColumns, inline && classes.itemPlus)}
          >
            {!inline && <Filter className={classes.icon} />}
            <Typography variant="h6" className={clsx(classes.text, inline && classes.noWrap)}>
              {label}:{filter.name} ({filter.queryType ? queryTypeJoinerMap[filter.queryType] : ""})
            </Typography>
            {!!handleClose && (
              <CloseIcon
                onClick={() => handleClose(filter, filterKey as FilterType, filter.index ?? itemIndex)}
                className={classes.icon}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        ));
      })}
    </>
  );
};

function SelectedFilterPreview(props: { withoutBackground?: boolean }): JSX.Element {
  const { withoutBackground = false } = props;
  const urlState = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { startDate, endDate } = urlState;
  const [showDots, setShowDots] = useState(false);
  const classes = useStyles();
  const { generalFilters, academicFilters, newsFilters, patentFilters } = getSelectedFiltersLists(urlState);
  const getFiltersLength = (data: Record<string, Entity[]>) => {
    return Object.values(data).reduce((prev, current) => {
      return prev + current.length;
    }, 0);
  };
  const generalLength = getFiltersLength(generalFilters);
  const newsLength = getFiltersLength(newsFilters);
  const patentsLength = getFiltersLength(patentFilters);
  const academicLength = getFiltersLength(academicFilters);
  const startDateLength = !!startDate ? 1 : 0;
  const endDateLength = !!endDate ? 1 : 0;

  // Adding 2 for start and end dates
  const activeFiltersLength =
    generalLength + newsLength + patentsLength + academicLength + startDateLength + endDateLength;

  useEffect(() => {
    const items = document.querySelector("[data-item='preview-item']");
    if (items) {
      setShowDots(items.clientWidth < items.scrollWidth);
    }
  }, [activeFiltersLength]);

  return (
    <div className={classes.previewContainer}>
      {!withoutBackground && (
        <div className={clsx(classes.previewItemsContainer, showDots && classes.previewOverflowDots)}>
          <div
            className={clsx(classes.previewItems, !withoutBackground && classes.previewItemsText)}
            data-item="preview-item"
          >
            <GetDateFilters
              startDate={startDate}
              endDate={endDate === Days.Today ? DEFAULT_DISPLAY_END.valueOf() : Number(endDate) || undefined}
              inline
            />
            {generalLength > 0 && <GetFilters data={generalFilters} label="General" inline />}
            {newsLength > 0 && <GetFilters data={newsFilters} label="News" inline />}
            {patentsLength > 0 && <GetFilters data={patentFilters} label="Patents" inline />}
            {academicLength > 0 && <GetFilters data={academicFilters} label="Academic" inline />}
          </div>
        </div>
      )}
      <div className={clsx(classes.activeFiltersContainer, !withoutBackground && classes.activeFiltersBackground)}>
        <div className={classes.activeText}>
          <Typography className={classes.activeNumber}>{activeFiltersLength}</Typography>
          <Typography variant="h6" className={clsx(!withoutBackground && classes.activeTextColor)}>
            Active Filters
          </Typography>
        </div>
      </div>
    </div>
  );
}

const GetDateFilters = (props: { startDate?: number; endDate?: number; inline?: boolean }) => {
  const dispatch = useDispatch();
  const { startDate, endDate, inline = false } = props;
  return (
    <>
      {startDate && (
        <GetFilters
          data={{ "Start Date": [{ id: "start-date", name: moment(startDate).format("DD/MM/YYYY") }] }}
          label="Date"
          inline={!!inline}
          handleClose={
            !inline
              ? () => {
                  dispatch(onGlobalDatesChange({ dates: [undefined, endDate] }));
                }
              : undefined
          }
        />
      )}
      {endDate && (
        <GetFilters
          data={{ "End Date": [{ id: "end-date", name: moment.utc(endDate).format("DD/MM/YYYY") }] }}
          label="Date"
          inline={!!inline}
          handleClose={
            !inline
              ? () => {
                  dispatch(onGlobalDatesChange({ dates: [startDate, undefined] }));
                }
              : undefined
          }
        />
      )}
    </>
  );
};
