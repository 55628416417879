import { createStyles, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { ArrowDownward, ArrowLeft, ArrowRight, ArrowUpward, FiberManualRecord } from "@material-ui/icons";
import clsx from "clsx";
import { useState } from "react";
import { Cell, Row, TableInstance, UsePaginationState, UseSortByInstanceProps } from "react-table";
import { getCellColor, getChartForCell } from "../../../helpers/tableHelper";
import { columnMetaData, ElementData } from "../../../models/element";
import { Entity, EntityType } from "../../../models/entity";
import { TooltipType } from "../../../models/search";
import EntityTooltip from "../Entity/EntityTooltip";

export const DEFAULT_PAGE_SIZE = 100;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(0),
      minWidth: "100%",
    },
    tableActions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tableHead: {
      background: "#4e4e4e",
      color: theme.palette.getContrastText("#4e4e4e"),
      position: "sticky",
      top: 0,
      zIndex: 5,
      height: 60,

      "& > *:first-child": {
        borderRadius: 8,
      },
    },
    tableHeaderCell: {
      userSelect: "none",
      textAlign: "left",
      padding: theme.spacing(2),
      alignItems: "center",
      position: "sticky",
      top: 0,
      display: "inline-flex !important",
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    tableRowCell: {
      display: "inline-flex !important",
      alignItems: "center",
    },
    tableFooter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    searchField: {
      maxWidth: 400,
    },
    directionArrow: {
      marginLeft: theme.spacing(1),
      height: 18,
    },
    tableSubheader: {
      display: "block",
      padding: theme.spacing(2),
      backgroundColor: "#FFF",
      position: "sticky",
      top: 72,
      zIndex: 5,
    },
    even: {
      background: "#fbfbfb",
    },
    odd: {},
  })
);

export type ExtendedTableInstance<T extends object> = TableInstance<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

function TCell(props: { cell: Cell<ElementData>; onClick?: () => void }): JSX.Element {
  const { cell, onClick } = props;

  if (Array.isArray(cell.value)) {
    return getChartForCell(cell.value);
  }

  return (
    <Typography
      onClick={onClick}
      style={{
        cursor: onClick ? "pointer" : "default",
        fontWeight: cell.column.id === "name" ? "bolder" : undefined,
        color:
          columnMetaData.find((c) => c.accessor === cell.column.id)?.valueIndicator === true
            ? getCellColor(cell.value as number)
            : undefined,
      }}
      variant="body1"
    >
      {cell.render("Cell")}
    </Typography>
  );
}

interface TableProps {
  tableProps: ExtendedTableInstance<ElementData>;
  page: number;
  pageCount: number;
  isFullscreen?: boolean;
  pinnedEntities: Row<ElementData>[];
  setPinnedEntities: (entities: Row<ElementData>[]) => void;
}

export default function Table(props: TableProps): JSX.Element {
  const { tableProps, page, pageCount, isFullscreen, pinnedEntities, setPinnedEntities } = props;
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    rows,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
  } = tableProps;

  const classes = useStyles({ isFullscreen });
  const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);

  return (
    <>
      <div style={{ overflow: "auto", borderRadius: 8, height: isFullscreen ? undefined : 600 }}>
        <table className={classes.table} {...getTableProps()}>
          <thead className={classes.tableHead}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className={classes.tableHeaderCell} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Typography style={{ color: "#FFF" }} variant="h4">
                      {column.render("Header")}
                    </Typography>
                    {column.isSorted && (
                      <span className={classes.directionArrow}>
                        {column.isSortedDesc ? <ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {pinnedEntities.length > 0 && (
              <tr className={classes.tableSubheader}>
                <td>
                  <Typography variant="h4">Pinned Entities</Typography>
                </td>
              </tr>
            )}
            {pinnedEntities.map((entity, ind) => (
              <tr
                className={clsx(classes.tableRow, ind % 2 === 0 ? classes.even : classes.odd)}
                {...entity.getRowProps()}
              >
                {entity.cells.map((cell, index) => (
                  <td className={classes.tableRowCell} {...cell.getCellProps()}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {index === 0 && (
                        <span style={{ width: 20, marginRight: 32 }}>
                          <IconButton
                            onClick={() => setPinnedEntities(pinnedEntities.filter((e) => e.id !== entity.id))}
                          >
                            <FiberManualRecord style={{ color: "#008AE0" }} />
                          </IconButton>
                        </span>
                      )}
                      {selectedEntity && selectedEntity.id === cell.row.original.id && index === 0 && (
                        <EntityTooltip
                          onClose={() => {
                            setSelectedEntity(null);
                          }}
                          entity={selectedEntity || undefined}
                        >
                          <div></div>
                        </EntityTooltip>
                      )}
                      <TCell
                        key={cell.value}
                        cell={cell}
                        onClick={
                          index === 0
                            ? () => {
                                setSelectedEntity({
                                  id: cell.row.original.id,
                                  name: cell.value,
                                  type: cell.row.original.type as EntityType,
                                });
                              }
                            : undefined
                        }
                      />
                    </span>
                  </td>
                ))}
              </tr>
            ))}
            <tr className={classes.tableSubheader}>
              <td>
                <Typography variant="h4">All Entities</Typography>
              </td>
            </tr>
            {rows.map((row, ind) => {
              prepareRow(row);
              return (
                <tr
                  className={clsx(classes.tableRow, ind % 2 === 0 || ind === 0 ? classes.even : classes.odd)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td className={classes.tableRowCell} {...cell.getCellProps()}>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {index === 0 && (
                            <span style={{ width: 20, marginRight: 32 }}>
                              <IconButton
                                onClick={() => {
                                  if (pinnedEntities.find((entity) => entity.id === cell.row.id)) {
                                    setPinnedEntities(pinnedEntities.filter((e) => e.id !== cell.row.id));
                                  } else {
                                    setPinnedEntities([...pinnedEntities, row]);
                                  }
                                }}
                              >
                                <FiberManualRecord
                                  style={{
                                    color: pinnedEntities.find((entity) => entity.id === cell.row.id)
                                      ? "#008AE0"
                                      : "#CCC",
                                  }}
                                />
                              </IconButton>
                            </span>
                          )}
                          {selectedEntity && selectedEntity.id === cell.row.original.id && index === 0 && (
                            <EntityTooltip
                              onClose={() => {
                                setSelectedEntity(null);
                              }}
                              entity={
                                {
                                  ...selectedEntity,
                                  type: selectedEntity.type === EntityType.KeyPhrase ? undefined : selectedEntity.type,
                                } || undefined
                              }
                              tooltipType={
                                selectedEntity.type === EntityType.KeyPhrase ? TooltipType.KeyPhrase : undefined
                              }
                            >
                              <div></div>
                            </EntityTooltip>
                          )}
                          <TCell
                            key={cell.value}
                            cell={cell}
                            onClick={
                              index === 0
                                ? () => {
                                    setSelectedEntity({
                                      id: cell.row.original.id,
                                      name: cell.value,
                                      type: cell.row.original.type as EntityType,
                                    });
                                  }
                                : undefined
                            }
                          />
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={classes.tableFooter}>
        <Typography>{`${page} / ${pageCount}`}</Typography>
        <IconButton disabled={!canPreviousPage} onClick={() => previousPage()}>
          <ArrowLeft></ArrowLeft>
        </IconButton>
        <IconButton disabled={!canNextPage} onClick={() => nextPage()}>
          <ArrowRight></ArrowRight>
        </IconButton>
      </div>
    </>
  );
}
