import { Box, makeStyles } from "@material-ui/core";

interface ResponsiveContentProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  responsiveColumn: {
    flex: "1 1 100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%",
    },
  },
}));

export default function ResponsiveContent(props: ResponsiveContentProps) {
  const classes = useStyles();
  return <Box className={classes.responsiveColumn}>{props.children}</Box>;
}
