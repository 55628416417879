import { Box, IconButton, makeStyles } from "@material-ui/core";
import Quill from "quill";
import React, { useEffect, useState } from "react";
import useDebounce from "../../../../../hooks/useDebounce";
import useUsers from "../../../../../hooks/useUsers";
import { AmplyfiUser } from "../../../../../models/user";
import { Send } from "@material-ui/icons";

interface CustomTextAreaProps {
    value: string;
    onTextChange?: (value: string) => void;
    ref?: React.Ref<HTMLDivElement>;
    onSubmit: () => void;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    "@keyframes loading": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    loadingAnim: {
        width: 200,
        height: 100,
        background: "white",
        position: "relative",
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&::after": {
            content: "''",
            width: 50,
            height: 50,
            borderRadius: "50%",
            border: "5px solid #f3f3f3",
            borderTopColor: palette.primary.main,
            animation: "$loading 1s linear infinite"
        }
    },
    root: {
        height: 100,
        border: "1px solid #e3e3e3",
        borderRadius: 4,
        fontSize: "16px !important",
        fontWeight: "normal",
        fontStyle: "normal !important",
        "& strong": {
            fontSize: "16px !important",
            fontWeight: "normal",
            fontStyle: "normal !important",
        },
        "& .ql-editor": {
            height: "100%",
            overflow: "auto"
        },
        "& .ql-mention-list-container": {
            display: "flex",
            alignItems: "flex-end",
            height: 250,
            width: 400,
            borderRadius: 4,
            position: "relative",
        },
        "& .mention": {
            borderRadius: 8,
            background: palette.secondary.main,
            padding: spacing(0.3),
            fontSize: 12,
            fontWeight: "bold",
            color: "white"
        },
        "& * ul": {
            listStyle: "none",
            maxHeight: 250,
            overflowY: "auto",
            padding: 0, margin: 0,
            background: "white",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
        },
    },
    listItem: {
        listStyle: "none",
        border: "1px solid #e3e3e3",
        padding: 16,
        cursor: "pointer",
        background: "white",
        minWidth: 100,
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: "46px 1fr",
    },
    listItemTextContainer: {
        display: "inline-block"
    },
    listItemText: {
        margin: 0, padding: 0,
        wordWrap: "break-word",
        overflowWrap: "anywhere"
    },
    listItemImage: {
        width: 30, height: 30,
        borderRadius: "50%",
        marginRight: spacing(2)
    }
}))

const convertToString = (value: string) => {
    const temp = document.createElement("div");
    temp.innerHTML = value;
    const mentions = temp.getElementsByClassName("mention");
    for (let i = 0; i < mentions.length; i++) {
        const mention = mentions[i];
        mention.innerHTML = `{{${mention.getAttribute("data-id")}}}`;
    }
    // replace P tags with new lines and remove all other html
    const result = temp.innerHTML.replace(/<p>/g, "\n").replace(/<\/p>/g, "").replace(/<[^>]*>/g, "");
    return result;
}

export default function CustomTextArea(props: CustomTextAreaProps) {
    const { onTextChange } = props;
    const classes = useStyles();
    const [mentionText, setMentionText] = useState("");
    const debouncedText = useDebounce(mentionText, 600);
    const [renderList, setRenderList] = useState<(values: AmplyfiUser[]) => void>(() => () => void 0);
    const { data } = useUsers(debouncedText);
    const [quillInstance, setQuillInstance] = useState<Quill | null>(null);
    const [quillText, setQuillText] = useState("");

    useEffect(() => {
        if (data) {
            renderList(data.users.map(user => ({ ...user, id: user.userId, value: user.name })))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        const quill = new Quill("#quill-editor", {
            modules: {
                toolbar: false,
                mention: {
                    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                    mentionDenotationChars: ["@", "#"],
                    minChars: 3,
                    renderLoading: () => {
                        const elem = document.createElement("div");
                        elem.className = classes.loadingAnim;
                        return elem;
                    },

                    renderItem: function (item: AmplyfiUser) {
                        const listItem = document.createElement("div");
                        listItem.className = classes.listItem;

                        const img = document.createElement("img");
                        img.className = classes.listItemImage
                        img.src = item.picture;

                        const name = document.createElement("p");
                        name.textContent = item.name;
                        name.className = classes.listItemText;

                        const email = document.createElement("p");
                        email.textContent = item.email;
                        email.className = classes.listItemText;
                        email.style.color = "gray";

                        const textContainer = document.createElement("div");
                        textContainer.className = classes.listItemTextContainer;

                        textContainer.appendChild(name);
                        textContainer.appendChild(email);

                        listItem.appendChild(img);
                        listItem.appendChild(textContainer);
                        return listItem;
                    },
                    source: async function (searchTerm: string, renderList: (values: AmplyfiUser[]) => void) {
                        setMentionText(searchTerm);
                        setRenderList(() => renderList);
                    }
                }
            },
        })
        setQuillInstance(quill);
        quill.on("text-change", () => handleInput(quill.root.innerHTML));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleInput(html: string) {
        setQuillText(html);
        onTextChange?.(convertToString(html));
    }

    function handleSubmit() {
        props.onSubmit();
        setMentionText("");
        quillInstance?.setText("");
    }

    return (
        <Box position="relative">
            <div className={classes.root} id="quill-editor"></div>
            <IconButton
                disabled={quillText.length === 0}
                style={{ position: "absolute", bottom: 8, right: 4, opacity: quillText.trim().length === 0 ? 0.6 : 1 }}
                onClick={() => handleSubmit()}>
                <Send fontSize="small" color="secondary" />
            </IconButton>
        </Box>
    )
}