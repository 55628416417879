import { HarvestStatus } from "../../../../models/harvest";
import { HarvestConfig, HarvestSourceOption } from "../models";

interface StatusConfig {
  className: string;
  label: string;
}

const HARVEST_STATUS: Record<HarvestStatus, StatusConfig> = {
  queued: { className: "chipQueued", label: "Queued" },
  harvesting: { className: "chipHarvesting", label: "Harvesting" },
  processing: { className: "chipProcessing", label: "In-pipeline" },
  complete: { className: "chipComplete", label: "Complete" },
  failed: { className: "chipFailed", label: "Failed" },
  unknown: { className: "chipUnknown", label: "Unknown" },
} as const;

export const getStatus = (status: HarvestStatus): StatusConfig => HARVEST_STATUS[status] || {};

export const getHarvestConfig = (harvestConfigId: string, harvestConfigs: HarvestConfig[] = []): HarvestConfig =>
  harvestConfigs.find((c) => c.id === harvestConfigId) as HarvestConfig;

export const harvestSourceHasLanguageDefaults = ({
  oneOffConfiguration: { hasLanguageDefaults = false },
}: HarvestConfig): boolean => hasLanguageDefaults;

export const harvestSourceCanReturnPreviews = ({
  oneOffConfiguration: { returnsPreviews = false },
}: HarvestConfig): boolean => returnsPreviews;

export const getHarvestSourceOptions = (harvestConfigs: HarvestConfig[] = []): HarvestSourceOption[] =>
  harvestConfigs.filter(({ visibleIn }) => visibleIn.includes("ONE_OFF_HARVEST")).map(({ id, name }) => ({ id, name }));
