import axios from "axios";
import { useQueryClient } from "react-query";
import { FeedViewType } from "../../../components/Pages/Feed/components/filterMenu/feedFilters";
import { getStartOfPastDayUtc } from "../../../helpers/dateHelpers";
import { useFeedViewStore } from "../../../store/hooks/useFeedViewStore";
import { areAnyUserTagFiltersSet, useFiltersStore } from "../../../store/hooks/useFiltersStore";
import useSearchStore from "../../../store/hooks/useSearchStore";
import useAPI from "../../queries/api/useAPI";
import { useQuery } from "../../useQuery";
import useMutation from "../../useMutation";
import { useDispatch } from "react-redux";
import { setSnack } from "../../../store/reducers/ui/snackReducer";
import { isInsight } from "../../../helpers/documentHelpers";
import {
  addUserTagFailed,
  addUserTagSucceeded,
  removeUserTagFailed,
  removeUserTagSucceeded,
} from "../../../components/Pages/Feed/helpers/snack";
import { useInsightKey } from "../../queries/insights/useInsight";
import { getSortingOptions, useFeedDocumentKey, useFeedkey } from "../../useFeed";
import { useInsightsKey } from "../../queries/insights/useInsights";

export const userTagsKey = "user-tags";
export const userTagsCountKey = "user-tags-count";

export interface UserTagCount {
  count: number;
  name: string;
}

export function useUserTags() {
  const { type } = useFeedViewStore(({ view }) => view);
  const signals = useAPI("v3", "alerts-feed/user-tags-count");
  const insights = useAPI("v5", "insights-feed/user-tags-count");
  const url = type === FeedViewType.Insight ? insights : signals;

  return useQuery([userTagsKey, type], async () => {
    const { data } = await axios.get<UserTagCount[]>(url);
    return data;
  });
}

export function useUserTagsCount() {
  const { id: feedType, type } = useFeedViewStore(({ view }) => view);
  const { amplyfiEntityId } = useFeedViewStore();
  const signals = useAPI("v3", "alerts-feed/user-tags-count");
  const insights = useAPI("v5", "insights-feed/user-tags-count");
  const url = type === FeedViewType.Insight ? insights : signals;
  const libraryId = useFeedViewStore(({ libraryId }) => libraryId);
  const searchTerm = useSearchStore(({ search }) => search);
  const {
    events,
    signalTypes: alertTypes,
    selectedSort,
    selectedSortInsights,
    strength,
    timeFilter,
    sectors,
    sources,
    topics,
  } = useFiltersStore(
    ({ events, signalTypes, selectedSort, selectedSortInsights, strength, timeFilter, sectors, sources, topics }) => ({
      events,
      signalTypes,
      selectedSort,
      selectedSortInsights,
      strength,
      timeFilter,
      sectors,
      sources,
      topics,
    })
  );
  const { sortBy, sortOrder } = getSortingOptions(type, selectedSort, selectedSortInsights);
  return useQuery(
    [
      userTagsCountKey,
      feedType,
      type,
      amplyfiEntityId,
      libraryId,
      searchTerm,
      alertTypes,
      timeFilter,
      selectedSort,
      strength,
      events,
      sectors,
      sources,
      topics,
    ],
    async () => {
      const { data } = await axios.get<UserTagCount[]>(url, {
        params: {
          alertTypes,
          feedType,
          sortBy,
          sortOrder,
          entityId: amplyfiEntityId ?? undefined,
          events,
          libraryId: libraryId ?? undefined,
          searchTerm: searchTerm ?? undefined,
          startDate: getStartOfPastDayUtc(timeFilter),
          strength,
          sectors,
          sources,
          topics,
        },
      });
      return data;
    }
  );
}

export const useRefreshListings = (documentId: string) => {
  const queryClient = useQueryClient();

  return () => {
    const feedKey = isInsight(documentId) ? useInsightsKey : useFeedkey;

    [userTagsKey, userTagsCountKey, ...(areAnyUserTagFiltersSet() ? [feedKey] : [])].forEach((k) =>
      queryClient.invalidateQueries(k)
    );
  };
};

export function useSetUserTag(libraryId: string, documentId: string, amplyfiEntityId: string | null) {
  const insightsFeed = useAPI("v5", "insights-feed");
  const alertsFeed = useAPI("v3", "alerts-feed");
  const { key, type, uri } = isInsight(documentId)
    ? { key: useInsightKey, type: FeedViewType.Insight, uri: insightsFeed }
    : { key: useFeedDocumentKey, type: FeedViewType.Signal, uri: alertsFeed };
  const dispatch = useDispatch();

  return {
    add: useMutation<string[]>(
      async (tags) =>
        await axios.patch(
          uri,
          tags.map((value) => ({ value, path: "/userTags", op: "add" })),
          {
            params: { documentId, libraryId, entityId: amplyfiEntityId },
          }
        ),
      [userTagsKey, key],
      {
        onError: (_, tags) => {
          dispatch(setSnack(addUserTagFailed(type, tags)));
        },
        onSuccess: (_, tags) => {
          dispatch(setSnack(addUserTagSucceeded(type, tags)));
        },
      }
    ),
    remove: useMutation<string[]>(
      async (tags) =>
        await axios.patch(
          uri,
          tags.map((value) => ({ value, path: "/userTags", op: "remove" })),
          {
            params: { documentId, libraryId, entityId: amplyfiEntityId },
          }
        ),
      [userTagsKey, key],
      {
        onError: (_, tags) => {
          dispatch(setSnack(removeUserTagFailed(type, tags)));
        },
        onSuccess: (_, tags) => {
          dispatch(setSnack(removeUserTagSucceeded(type, tags)));
        },
      }
    ),
  };
}
