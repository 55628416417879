import { B10Highlight, DarkSilver, Silver, White } from "@amplyfi/ui-components/theme/colors";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { AxisType } from "../../../models/customization/customization";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    axisBtnsContainer: {
      backgroundColor: White,
      display: "flex",
      width: "fit-content",
      borderRadius: 8,
    },
    axisBtn: {
      padding: theme.spacing(1, 1.5),
      border: "none",
      backgroundColor: "transparent",
      borderRadius: 8,

      "& h4": {
        color: DarkSilver,
      },

      "&:not([disabled])": {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: B10Highlight,
          "& h4": {
            color: "#0335BA", //TODO add in ui-components
          },
        },
      },
      "&:disabled": {
        "& h4": {
          color: Silver,
        },
      },
    },
    axisSelected: {
      backgroundColor: B10Highlight,
      cursor: "initial",
      pointerEvents: "none",
      "& h4": {
        color: "#0335BA", //TODO add in ui-components
      },
    },
  })
);

interface CustomizationAxisSelectionProps {
  axis: AxisType;
  setAxis: (args: AxisType) => void;
  disabledSecondary?: boolean;
}

export default function CustomizationAxisSelection(props: CustomizationAxisSelectionProps): JSX.Element {
  const classes = useStyles();
  const { axis, setAxis, disabledSecondary = false } = props;

  return (
    <div className={classes.axisBtnsContainer}>
      <button
        onClick={() => setAxis(AxisType.Primary)}
        className={clsx(classes.axisBtn, axis === AxisType.Primary && classes.axisSelected)}
      >
        <Typography variant="h4">Primary Axis</Typography>
      </button>
      <button
        disabled={disabledSecondary}
        onClick={() => setAxis(AxisType.Secondary)}
        className={clsx(classes.axisBtn, axis === AxisType.Secondary && classes.axisSelected)}
      >
        <Typography variant="h4">Secondary Axis</Typography>
      </button>
    </div>
  );
}
