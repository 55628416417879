import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatList } from "../../../helpers/sentence";
import { Entity } from "../../../models/entity";
import { Sentence, SentenceResponse } from "../../../models/sentence";
import { resetState } from "../searchRequest/actions";

interface SectorsState {
  sentencesLoading: boolean;
  sentencesError: boolean;
  filters: Entity[];
  sentences: Sentence[];
  sectorFilters: string[];
  selectedSectorFilters: string[];
}

const initialState: SectorsState = {
  sentencesError: false,
  sentencesLoading: false,
  sentences: [],
  filters: [],
  sectorFilters: [],
  selectedSectorFilters: [],
};

const sectorsSlice = createSlice({
  name: "sectors",
  initialState,
  reducers: {
    onSectorSentencesLoading: (state: SectorsState) => {
      state.sentencesLoading = true;
      state.sentencesError = false;
    },
    onSectorSentencesSuccess(state: SectorsState, action: PayloadAction<SentenceResponse>) {
      const { mentions, mentionedProperty } = action.payload;
      state.sentencesLoading = false;
      state.sentencesError = false;
      formatList(mentions.sentences);
      if (mentionedProperty.length) {
        state.sectorFilters = mentionedProperty.sort();
      }
      state.sentences = mentions.sentences;
    },
    onSectorSentencesError: (state: SectorsState) => {
      state.sentencesLoading = false;
      state.sentencesError = true;
      state.sentences = [];
    },
    addSectorSentencesFilter(state: SectorsState, action: PayloadAction<Entity>) {
      state.filters.push(action.payload);
    },
    removeSectorSentencesFilter(state: SectorsState, action: PayloadAction<number>) {
      const filterIndex = action.payload;
      if (filterIndex > -1) {
        state.filters.splice(filterIndex, 1);
      }
    },
    onChangeSectorSentencesFilter(state: SectorsState, action: PayloadAction<string>) {
      if (state.selectedSectorFilters.includes(action.payload)) {
        state.selectedSectorFilters = state.selectedSectorFilters.filter((x) => x !== action.payload);
      } else {
        state.selectedSectorFilters.push(action.payload);
      }
    },
  },
  extraReducers: {
    [resetState.type]: function () {
      return initialState;
    },
  },
});

export const {
  onSectorSentencesError,
  onSectorSentencesLoading,
  onSectorSentencesSuccess,
  addSectorSentencesFilter,
  removeSectorSentencesFilter,
  onChangeSectorSentencesFilter,
} = sectorsSlice.actions;

export default sectorsSlice.reducer;
