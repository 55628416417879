import { Positive } from "@amplyfi/ui-components/theme/colors";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
    },
    circle: {
      color: Positive,
    },
    text: {
      fontWeight: "bold",
      marginLeft: theme.spacing(1),
    },
  })
);

export default function ValidationCheckMark(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CheckCircleIcon className={classes.circle} />
      <Typography data-testid="validation-text" className={classes.text}>
        Query validated
      </Typography>
    </div>
  );
}
