import { createStyles, List, ListItem, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { link } from "../../../css/mixins";

interface ReadMoreTemplateProps {
  overview: string;
  usefulFor?: string;
  basedOn?: string;
  availableFor?: string;
  functionality?: string;
  customisations?: JSX.Element | string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      "& >li": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      "& h6": {
        color: theme.palette.primary.light,
      },
    },
    normalText: {
      fontWeight: "normal",
    },
    subList: {
      paddingTop: 0,
      "&>li": {
        paddingTop: theme.spacing(1),
        "&::before": {
          content: "'•'",
          color: theme.palette.primary.light,
          position: "absolute",
          fontWeight: "bold",
          display: "inline-block",
          width: "1em",
          marginLeft: "-1em",
        },
      },
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    readMore: {
      ...link,
    },
  })
);

export default function ReadMoreTemplate(props: ReadMoreTemplateProps): JSX.Element {
  const { availableFor, basedOn, customisations, functionality, overview, usefulFor } = props;
  const classes = useStyles();

  return (
    <List className={classes.container}>
      <ListItem>
        <Typography variant="h6">Overview</Typography>
        <Typography variant="h6" className={classes.normalText}>
          {overview}
        </Typography>
      </ListItem>
      {!!usefulFor && (
        <ListItem>
          <Typography variant="h6">Useful For</Typography>
          <Typography variant="h6" className={classes.normalText}>
            {usefulFor}
          </Typography>
        </ListItem>
      )}
      {!!basedOn && (
        <ListItem>
          <Typography variant="h6">Based On</Typography>
          <Typography variant="h6" className={classes.normalText}>
            {basedOn}
          </Typography>
        </ListItem>
      )}
      {!!availableFor && (
        <ListItem>
          <Typography variant="h6">Available For</Typography>
          <Typography variant="h6" className={classes.normalText}>
            {availableFor}
          </Typography>
        </ListItem>
      )}
      {!!functionality && (
        <ListItem>
          <Typography variant="h6">Functionality</Typography>
          <Typography variant="h6" className={classes.normalText}>
            {functionality}
          </Typography>
        </ListItem>
      )}
      {!!customisations && (
        <ListItem>
          <Typography variant="h6">Customisations</Typography>
          {typeof customisations === "string" ? (
            <Typography variant="h6" className={classes.normalText}>
              {customisations}
            </Typography>
          ) : (
            customisations
          )}
        </ListItem>
      )}
    </List>
  );
}

interface CustomisationListProps {
  data: { title: string; text: string }[];
}

export function CustomisationList(props: CustomisationListProps): JSX.Element {
  const { data } = props;
  const classes = useStyles();

  return (
    <List className={classes.subList}>
      {data.map(({ text, title }) => (
        <ListItem key={text} className={classes.listItem}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h6" style={{ fontWeight: "normal" }}>
            {text}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
}
