import { FormControl, FormGroup, FormHelperText, TextField } from "@material-ui/core";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { FieldHandlerOptions, FieldHandlerValidatorOptions, Handler } from "../../../helpers/fieldHandlers";
import { useFieldStyles } from "./styles";
import { useEffect } from "react";
import { invalidMessageFn, requiredValidationMessage } from "./helper";

interface StringHandlerOptions extends FieldHandlerOptions, FieldHandlerValidatorOptions {
  placeholder?: string;
}

export const stringField: (props: StringHandlerOptions) => Handler<string> =
  ({ label, placeholder, required = false, validators = [] }) =>
  (value = "", name, onChange, { disabled, error, validation }) => {
    const classes = useFieldStyles();

    const invalidMessage = invalidMessageFn(validators, required);

    useEffect(() => {
      if (required && !value?.trim()) {
        onChange(value, requiredValidationMessage);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <FormControl className={classes.control} error={!!error || validation !== undefined}>
        <FormGroup>
          <TextField
            fullWidth
            data-testid={testId("entity-knowledge-base", "edit", name)}
            variant="outlined"
            placeholder={`Enter ${placeholder || label.toLowerCase()}`}
            label={label}
            onChange={({ target: { value: val } }) => onChange(val, invalidMessage(val))}
            defaultValue={value}
            InputLabelProps={{ shrink: true }}
            error={!!error || validation !== undefined}
            disabled={disabled}
            aria-invalid={validation !== undefined}
            aria-required={required}
          />
        </FormGroup>
        {(error || validation) && <FormHelperText className={classes.error}>{error || validation}</FormHelperText>}
      </FormControl>
    );
  };
