import AiButton from "@amplyfi/ui-components/components/Button";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { Box, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { DataUsage as SectorIcon, Add as AddIcon, Done, Warning } from "@material-ui/icons";
import { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOrgId } from "../../../../helpers/user";
import useAIHistory from "../../../../hooks/useAIHistory";
import useLibrary from "../../../../hooks/useLibrary";
import { blockLibrarySector } from "../../../../http/search/documentLibrary";
import { MonitoringDocument } from "../../../../models/monitoring";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import { goToLibrarySettingsTab } from "../Routing";
import { LibraryFilter } from "../../../../http/libraryFilters";
import SimpleDialog from "../../../Widgets/Dialog/SimpleDialog";
import { useFeedHistoricFilterCounts } from "../../../../hooks/useFeedHistoricFilterCounts";
import DynamicComponent from "../Configuration/Components/DynamicComponent";
import { pluraliseDays, pluraliseWeeks } from "../../../../helpers/textHelper";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { analyticsEvent } from "../../../../helpers/analytics";
import useDialogStore from "../../../../store/hooks/useDialogStore";
import useFeedUpdates from "../../../../hooks/useFeedUpdates";
import { blueShades } from "../../../Charts/helpers/colourScaleHexes";

interface BlockSectorsProps {
  "data-testId"?: string;
  document: MonitoringDocument;
  onClose: (e: React.MouseEvent<Element, MouseEvent>, changed: boolean) => void;
}

interface BlockSectorsContentProps {
  document: MonitoringDocument;
  handleBlockSector: (documentId: string, sector: LibraryFilter) => Promise<void>;
  isBusy: boolean;
  onClose: MouseEventHandler;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  icon: {
    marginRight: spacing(1),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  subTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: spacing(3),
    gap: spacing(1),
  },
  content: {
    marginBottom: spacing(2),
  },
  list: {
    display: "flex",
    listStyle: "none",
    maxHeight: 200,
    overflow: "auto",
    padding: 0,
    flexWrap: "wrap",
    gap: spacing(1),
    marginBlock: spacing(2),
  },
}));

const INCREMENT_ITEMS = 5;
const HISTORIC_FILTER_COUNT_DAYS = 7;

const PLURALISE_OPTIONS = { includeCount: false, showPluralCount: true };

const unifySector = ({ id }: LibraryFilter): string => id;

interface SectorProps {
  libraryId: string;
  sector: LibraryFilter;
  disabled: boolean;
  onClick: () => void;
  isSelected?: boolean;
}

function Sector(props: SectorProps) {
  const { sector, disabled, onClick, libraryId, isSelected } = props;
  const { data: historicCounts, isLoading } = useFeedHistoricFilterCounts(
    libraryId,
    "sector",
    [unifySector(sector)],
    HISTORIC_FILTER_COUNT_DAYS
  );
  return (
    <AiButton
      disabled={disabled}
      onClick={onClick}
      style={isSelected ? { opacity: 0.5 } : {}}
    >
      {isSelected && <Done style={{ marginRight: 8, fontSize: 18 }} />}
      {sector.name}
      <Box style={{ marginLeft: 8 }}>{isLoading && <AiCircularProgress size={12} />}</Box>
      {!!historicCounts?.[unifySector(sector)] && ` (${historicCounts[unifySector(sector)].documentCount})`}
    </AiButton>
  );
}

const BlockSectorContent = ({
  document: { documentId, documentSectors = [], libraryId },
  handleBlockSector,
  onClose,
}: BlockSectorsContentProps) => {
  const classes = useStyles();
  const history = useAIHistory();
  const { data: library } = useLibrary(libraryId);
  const [blockedSectors, setBlockedSectors] = useState<LibraryFilter["id"][]>(library?.blockedDocumentSectors || []);
  const [noItems, setNoItems] = useState<number>(INCREMENT_ITEMS);
  const [selectedSectors, setSelectedSectors] = useState<LibraryFilter["id"][]>([]);
  const removeDialog = useDialogStore((state) => state.removeDialog);
  const { reset } = useFeedUpdates();

  const handleConfirm = async () => {
    for (const sectorId of selectedSectors) {
      const sector = documentSectors.find(s => unifySector(s) === sectorId);
      if (sector) {
        await handleBlockSector(documentId, sector);
      }
    }
    removeDialog();
    reset();
  };

  useEffect(() => {
    if (library?.blockedDocumentSectors) {
      setBlockedSectors(library.blockedDocumentSectors);
    }
  }, [library]);

  return (
    <SimpleDialog
      title={
        <Typography variant="h2" className={classes.title}>
          <SectorIcon className={classes.icon} /> Block Sectors
        </Typography>
      }
      data-testid={testId("document", "block-sectors")}
      onClose={onClose}
      content={
        <DynamicComponent>
          <>
            <Box className={classes.content}>
              <Box className={classes.subTitle}>
                <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <Warning />
                  <Typography variant="h4">Blocking these sectors will remove past signals, as well as preventing future ones.</Typography>
                </Box>
                <Typography variant="h4"> Please Note: <Typography component="span" style={{ fontStyle: "italic" }}>Unblocking a sector will not return previously removed signals.</Typography></Typography>
              </Box>
              <Box className={classes.list}>
                {documentSectors.slice(0, noItems).map((sector, i) => {
                  return (
                    <Box key={sector.id}>
                      <Sector
                        libraryId={libraryId}
                        sector={sector}
                        disabled={blockedSectors.includes(unifySector(sector))}
                        isSelected={selectedSectors.includes(unifySector(sector))}
                        onClick={() => {
                          setSelectedSectors(prevSectors => {
                            if (prevSectors.includes(unifySector(sector))) {
                              return prevSectors.filter(s => s !== unifySector(sector));
                            } else {
                              return [...prevSectors, unifySector(sector)];
                            }
                          });
                        }}
                      />
                    </Box>
                  );
                })}
                {noItems < documentSectors.length && (
                  <Typography style={{ marginTop: 0, textAlign: "right" }}>
                    <AiButton
                      amplyfiType="secondary"
                      disabled={noItems >= documentSectors.length}
                      onClick={() => setNoItems(Math.min(noItems + INCREMENT_ITEMS, documentSectors.length))}
                    >
                      <AddIcon />See more
                    </AiButton>
                  </Typography>
                )}
              </Box>
              <Box style={{ marginBlock: 8 }}>
                <Typography>
                  Counts show the number of signals received in the last
                  {HISTORIC_FILTER_COUNT_DAYS % 7 === 0
                    ? pluraliseWeeks(HISTORIC_FILTER_COUNT_DAYS / 7, PLURALISE_OPTIONS)
                    : pluraliseDays(HISTORIC_FILTER_COUNT_DAYS, PLURALISE_OPTIONS)}{" "}
                  containing that sector.
                </Typography>
                {libraryId && (
                  <Typography style={{ marginTop: 4 }}>
                    Review your blocked sectors within{" "}
                    <Link
                      style={{ textDecoration: "none", color: blueShades[3] }}
                      href={goToLibrarySettingsTab(libraryId, "filters")}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        history.pushFeedSettings(libraryId, "filters");
                        removeDialog();
                      }}
                    >
                      Interest filter settings
                    </Link>
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        </DynamicComponent>
      }
      onConfirm={handleConfirm}
      primary="Confirm"
      secondary="Cancel"
    />
  );
};

export const BlockSectors = ({ document, onClose }: BlockSectorsProps): JSX.Element => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const { refetch } = useLibrary(document.libraryId);
  const removeDialog = useDialogStore((state) => state.removeDialog);

  const handleBlockSector = async (documentId: string, sector: LibraryFilter) => {
    setIsBusy(true);
    setHasChanged(true);
    const body = await blockLibrarySector(getOrgId(user), document.libraryId, documentId, sector);
    if (!body) {
      analyticsEvent("Feed", "FilterSectorBlocked", sector.id);
    }
    refetch();
    dispatch(
      setSnack({
        body: body || `${sector.name} sector has now been blocked within this library`,
        title: body ? "Failed" : "Success",
      })
    );
  };

  useEffect(() => {
    if (!document) {
      setIsBusy(false);
    }
  }, [document]);

  return document ? (
    <BlockSectorContent
      document={document}
      handleBlockSector={handleBlockSector}
      isBusy={isBusy}
      onClose={(e) => {
        onClose(e, hasChanged)
        removeDialog();
      }}
    />
  ) : (
    <></>
  );
};
