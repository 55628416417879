import { MidGrey } from "@amplyfi/ui-components/theme/colors";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(8),
      position: "relative",
      "& *": {
        whiteSpace: "pre",
      },
      "& h1": {
        marginBottom: theme.spacing(2),
      },
      "& h2": {
        margin: theme.spacing(2, 0),
      },
      "& h3": {
        margin: theme.spacing(2, 0),
      },
      "& code": {
        background: MidGrey,
        margin: theme.spacing(2, 0),
        display: "inline-block",
      },
    },
    scroller: {
      overflow: "auto",
      height: 700,
    },
    closeIcon: {
      position: "absolute",
      right: 10,
      top: 10,
      cursor: "pointer",
    },
  })
);

export default function SyntaxGuide(props: { onClose: () => void }): JSX.Element {
  const { onClose } = props;
  const classes = useStyles();
  return (
    <div data-testid="syntax-guide-dialog" className={classes.container}>
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
      <div className={classes.scroller}>
        <Typography variant="h1">Syntax Guide</Typography>
        <Typography>
          If you are having problems with any part of this process, please contact support@amplyfi.com.
        </Typography>
        <Typography variant="h2">Terms</Typography>
        <Typography>
          <strong>
            A Single Term is a single word such as test or hello - these do not need quotes.{"\n"}A Phrase is a group of
            words surrounded by double quotes such as "hello dolly".
          </strong>
        </Typography>
        <Typography variant="h3">Term Grouping</Typography>
        <Typography>
          To search for a document that mentions either "artificial" or "intelligence", and also includes "website":
        </Typography>
        <code>(artificial OR intelligence) AND website</code>
        <Typography variant="h3">Wildcard Searches</Typography>
        <Typography>To search for "text" or "test":</Typography>
        <code>te?t</code>
        <Typography>For example, to search for test, tests or tester:</Typography>
        <code>test*</code>
        <Typography>You can also use the wildcard searches in the middle of a term.</Typography>
        <code>te*t</code>
        <Typography>Note: You cannot use a * or ? symbol as the first character of a search.</Typography>
        <Typography>Note: You cannot use a * or ? symbol within a quoted or multi-word search.</Typography>
        <Typography variant="h3">Fuzzy Searches</Typography>
        <Typography>To search for a term similar in spelling to "roam" like foam or roams:</Typography>
        <code>roam~</code>
        <Typography>Note: Mixing fuzzy and wildcard operators are not supported.</Typography>
        <Typography variant="h3">Proximity Searches</Typography>
        <Typography>
          To search for "intelligence" and "artificial" within 10 words of each other in a document use the search:
        </Typography>
        <code>"artificial intelligence"~10</code>
        <Typography variant="h2">Operators</Typography>
        <Typography>
          <strong>
            Operators must be ALL CAPS. If there is no Boolean operator between two terms, the OR operator is used.
          </strong>
        </Typography>
        <Typography variant="h3">Either Terms "OR"</Typography>
        <Typography>
          To search for documents that contain either "artificial intelligence" or just "artificial":
        </Typography>
        <code>"artificial intelligence" OR artificial</code>
        <Typography variant="h3">Required Terms "+"</Typography>
        <Typography>To search for documents that must contain "artificial" and may contain "intelligence":</Typography>
        <code>+artificial intelligence</code>
        <Typography variant="h3">Exclude Terms "NOT"</Typography>
        <Typography>
          To search for documents that contain "artificial intelligence" but not "intelligence Lucene":
        </Typography>
        <code>"artificial intelligence" NOT "intelligence Lucene"</code>
        <Typography>Note: The NOT operator cannot be used with just one term.</Typography>
      </div>
    </div>
  );
}
