import { MidDarkGrey } from "@amplyfi/ui-components/theme/colors";
import { createStyles, ListItem, makeStyles, Theme, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { link } from "../../../css/mixins";
import { useTooltipOnHighlightedText } from "../../../helpers/sentence";
import { Sentence } from "../../../models/sentence";
import { viewDocument } from "../../../store/reducers/searchResponse/relatedDocumentViewerReducer";

const useListStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontStyle: "italic",
      marginBottom: theme.spacing(),
      display: "inline",
    },
    title: {
      fontWeight: "bold",
      marginBottom: theme.spacing(),
    },
    footer: {
      display: "flex",
    },
    source: {
      color: MidDarkGrey,
    },
    toDocument: {
      marginLeft: theme.spacing(2),
      ...link(theme),
    },
  })
);

export default function SentenceListItem(props: { sentence: Sentence }): JSX.Element {
  const { sentence } = props;
  const dispatch = useDispatch();
  const classes = useListStyles();
  const highlightedTextWithTooltip = useTooltipOnHighlightedText({ sentence, className: classes.text });

  return (
    <ListItem divider key={sentence.documentId + sentence.text}>
      <div>"{highlightedTextWithTooltip}"</div>
      <Typography className={classes.title} variant="body2">
        {sentence.documentTitle}
      </Typography>
      <div className={classes.footer}>
        <Typography variant="body2" className={classes.source}>
          {sentence.source} | {moment.utc(sentence.publishDate).format("DD MMM")} -{" "}
          {moment.utc(sentence.publishDate).fromNow()}
        </Typography>
        <span
          className={classes.toDocument}
          onClick={() => {
            dispatch(viewDocument(sentence.documentId));
          }}
          data-testid="sentence-view-doc"
        >
          View Document
        </span>
      </div>
    </ListItem>
  );
}
