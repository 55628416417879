import { createStyles, makeStyles, Theme, Typography, FormControlLabel } from "@material-ui/core";
import { BlueRibbon, Grey, Silver, TorchRed } from "@amplyfi/ui-components/theme/colors";
import React, { useState } from "react";
import Modal from "../Misc/Modal";
import AnalyseButton from "@amplyfi/ui-components/components/Button";
import { sendFeedback } from "../../../http/feedback";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import { getSearchQuery } from "../../../helpers/feedback";
import { useAnalyseSelector } from "../../../store/reducers";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import AmplyfiCheckbox from "../AmplyfiCheckbox";
import clsx from "clsx";
import { logError } from "../../../helpers/logger";
import { useDispatch } from "react-redux";
import { setSnack } from "../../../store/reducers/ui/snackReducer";
import useCountApi from "../../../hooks/useCountApi";
import { SnackFrequencyType } from "../../../models/snack";
import { useFullscreenContainer } from "@amplyfi/ui-components/hooks/useFullscreenContainer";

interface FeedbackProps extends React.ComponentProps<typeof Modal> {
  componentName: string;
  variant?: "feedback" | "analysisRequest";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: `0 ${theme.spacing(6)}px`,
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(2.5),
    },
    icon: {
      color: Grey,
      cursor: "pointer",

      "&:first-of-type": {
        marginRight: "12px",
      },
    },
    positive: {
      color: BlueRibbon[400],
    },
    negative: {
      color: TorchRed[500],
    },
    filterLabel: {
      "&>span": {
        color: BlueRibbon[400],
      },
    },
    filtersContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      marginBottom: theme.spacing(4),
    },
    checkboxContainer: {
      margin: 0,

      "&>span": {
        fontFamily: "inherit",
        fontSize: "13px",
        color: "black",
      },
    },
    title: {
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
    },
    subTitle: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    textAreaContainer: {
      margin: `0 -${theme.spacing(6.75)}px`,
    },
    textArea: {
      width: "100%",
      height: "100%",
      borderColor: Silver,
      borderRadius: "5px",
      resize: "none",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "center",
      paddingTop: theme.spacing(3.5),

      "&>:first-child": {
        marginRight: theme.spacing(1.25),
      },
    },
    button: {
      padding: `${theme.spacing(1.25)}px ${theme.spacing(6.75)}px `,
      width: 156,
    },
    error: {
      color: TorchRed[400],
    },
  })
);

const FeedbackModal = (props: FeedbackProps): JSX.Element => {
  const { onClose, componentName, variant = "feedback", ...rest } = props;
  const classes = useStyles();
  const [feedback, setFeedback] = useState("");
  const { user } = useAuth0();
  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { data: documentCount = 0 } = useCountApi(url);
  const [positiveSelected, setPositive] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const filters = ["Request more data", "Something is missing", "This is incorrect", "Other"];
  const showFeedbackControls = variant === "feedback";
  const filterChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.id;
    if (selectedFilter.includes(val)) {
      setSelectedFilter(selectedFilter.filter((x) => x !== val));
    } else {
      setSelectedFilter([...selectedFilter, val]);
    }
  };
  const dispatch = useDispatch();
  const popoverContainer = useFullscreenContainer();

  return (
    <Modal onClose={onClose} container={popoverContainer} {...rest}>
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h1">{showFeedbackControls ? "Feedback" : "Analysis Request"}</Typography>
        </div>
        {showFeedbackControls && (
          <>
            <div className={classes.iconContainer}>
              <ThumbUpIcon
                className={clsx(classes.icon, positiveSelected ? classes.positive : "")}
                onClick={() => setPositive(true)}
              />
              <ThumbDownIcon
                className={clsx(classes.icon, positiveSelected ? "" : classes.negative)}
                onClick={() => setPositive(false)}
              />
            </div>
            <div className={classes.filtersContainer}>
              {filters.map((filter, index) => (
                <FormControlLabel
                  key={index}
                  label={filter}
                  className={classes.checkboxContainer}
                  control={
                    <AmplyfiCheckbox
                      checked={selectedFilter.includes(filter)}
                      value={filter}
                      id={filter}
                      onChange={filterChanged}
                      filled
                    />
                  }
                />
              ))}
            </div>
          </>
        )}
        <Typography variant="h3" className={classes.subTitle}>
          {showFeedbackControls ? "Comments or Suggestions" : "Your request"}
        </Typography>
        <div className={classes.textAreaContainer}>
          <textarea
            value={feedback}
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
            className={classes.textArea}
            rows={8}
            cols={20}
          />
        </div>
        {error && <Typography className={classes.error}>The feedback failed to send</Typography>}
      </div>
      <div className={classes.buttonsContainer}>
        <AnalyseButton
          onClick={!!onClose ? (e) => onClose(e, "escapeKeyDown") : undefined}
          className={classes.button}
          color="secondary"
          variant="outlined"
          type="button"
        >
          Cancel
        </AnalyseButton>
        <AnalyseButton
          disabled={feedback.trim().length === 0}
          onClick={(e) => {
            const makeRequest = async () => {
              setError(false);
              try {
                const separator = "----------------------------------------------------";
                const currentComponent = `Component: ${componentName}`;
                const feedbackWithAdditionalInfo = [feedback, separator];

                if (showFeedbackControls) {
                  const positiveOrNegative = `User selected: ${positiveSelected ? "Thumbs up 👍" : "Thumbs Down 👎"}`;
                  const checkboxesSelected = `User selected checkboxes:\n\n${selectedFilter
                    .map((x) => x + " ✔️")
                    .join("\n")}`;
                  const searchQueryMeta = getSearchQuery();
                  const docsReturned = `Documents Returned: ${documentCount}`;

                  const feedbackInfo = [
                    positiveOrNegative,
                    checkboxesSelected,
                    searchQueryMeta,
                    docsReturned,
                    currentComponent,
                  ];
                  feedbackWithAdditionalInfo.push.apply(feedbackWithAdditionalInfo, feedbackInfo);
                } else {
                  feedbackWithAdditionalInfo.push(`This was an Analysis request sent via ${componentName}.`);
                }

                await sendFeedback({
                  name: user?.name || "",
                  text: feedbackWithAdditionalInfo.join("\n\n"),
                });
                setFeedback("");
                onClose && onClose(e, "escapeKeyDown");
                dispatch(
                  setSnack({
                    title: "Thank you for your feedback",
                    body: "",
                    type: SnackFrequencyType.Every,
                  })
                );
              } catch (e) {
                logError(e);
                setError(true);
              }
            };
            makeRequest();
          }}
          className={classes.button}
          type="submit"
        >
          Send
        </AnalyseButton>
      </div>

      {showFeedbackControls && (
        <em style={{ display: "block", marginTop: 16, textAlign: "center" }}>
          You are sending feedback about the "{componentName}" component.
        </em>
      )}
    </Modal>
  );
};

export default FeedbackModal;
