import AiButton from "@amplyfi/ui-components/components/Button";
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import { analyticsEvent } from "../../../../helpers/analytics";
import { getOrganisation } from "../../../../helpers/user";
import { createGroup } from "../../../../http/user-management";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import TextEntryField from "../../Feed/Configuration/Components/TextEntryField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      width: "100%",
      maxWidth: "100%",
      display: "block",
      marginBlock: theme.spacing(1),
    },
    textField: {
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      flex: "1 1 auto",
      "& .MuiFormControlLabel-label": {
        marginRight: "auto",
      },
    },
    dialog: {
      padding: theme.spacing(2),
      "& .MuiDialog-paper": {
        width: "100%",
        height: "100%",
        maxHeight: 400,
        flexwrap: "wrap",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiDialog-paper": {
          minWidth: "100%",
          padding: 0,
        },
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface AddUserProps {
  onClose: (name?: string) => void;
}

export default function AddGroupModal(props: AddUserProps): JSX.Element {
  const { onClose } = props;
  const classes = useStyles();
  const { user } = useAuth0();
  const organisation = getOrganisation(user);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [creatingGroup, setCreatingGroup] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog fullScreen={isSmallScreen} open onClose={() => onClose()} className={classes.dialog}>
      <DialogTitle disableTypography>
        <Typography variant="h2">Create User Group</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box className={classes.fieldWrapper}>
          <FormControlLabel
            className={classes.textField}
            control={
              <TextEntryField
                fullWidth
                defaultValue={name}
                style={{ display: "block" }}
                placeholder="Enter name"
                onChange={(e) => setName(e.target.value)}
              />
            }
            labelPlacement="top"
            label="Name"
          />
        </Box>
        <Box className={classes.fieldWrapper}>
          <FormControlLabel
            className={classes.textField}
            control={
              <TextEntryField
                fullWidth
                defaultValue={description}
                style={{ display: "block" }}
                placeholder="Enter description"
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
              />
            }
            labelPlacement="top"
            label="Description"
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ paddingRight: 24, paddingBottom: 16 }}>
        <AiButton
          disabled={creatingGroup}
          style={{ marginLeft: "auto" }}
          amplyfiType="secondary"
          onClick={() => onClose()}
        >
          Cancel
        </AiButton>
        {organisation && (
          <AiButton
            disabled={creatingGroup || name.trim() === ""}
            onClick={async () => {
              setCreatingGroup(true);
              await createGroup(organisation.id, name, description);
              analyticsEvent("Account", "GroupCreated", [organisation.id]);
              onClose(name);
            }}
          >
            Create
          </AiButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
