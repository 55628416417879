import { createStyles, List, ListItem, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { GeoItem } from "../../../models/geo";
import { CARD_PADDING } from "../../../css/useCardHeaderStyling";
import { EntityType } from "../../../models/entity";
import EntityTooltip from "../Entity/EntityTooltip";
import CountryListItem from "./CountryListItem";
interface CountryListProps {
  countries: GeoItem[];
  hiddenCountries: Record<string, boolean>;
  onHideCountry: (id: string) => void;
  onShowCountry: (id: string) => void;
  entityTooltipProps: Partial<React.ComponentProps<typeof EntityTooltip>>;
  selectedGeoType?: EntityType;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: `${CARD_PADDING}px`,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      maxHeight: 250,
      width: "100%",
    },
    list: {
      overflowX: "auto",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      columnGap: theme.spacing(2),
      rowGap: theme.spacing(2),

      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr 1fr",
      },
    },
    listItem: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(1.1, 1.5),
      borderRadius: 10,
      backgroundColor: theme.palette.accent.main,
    },
  })
);

export function CountryList(props: CountryListProps): JSX.Element {
  const { countries, ...rest } = props;

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <List className={classes.list}>
        {[...countries]
          .sort((a, b) => b.documentCount - a.documentCount)
          .map((country, index) => (
            <ListItem className={classes.listItem} key={country.countryName}>
              <CountryListItem country={country} index={index} {...rest} />
            </ListItem>
          ))}
      </List>
    </div>
  );
}
