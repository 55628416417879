import axios from "axios";
import { LibraryAlert } from "./libraries";
import Icon from "@material-ui/icons";

const apiUrl = `${process.env.REACT_APP_ANALYSE_API}/v2`;

export interface AlertType {
  name: string;
  description: string | null;
  alertTypeId?: string | null;
  isCustom?: boolean;
  fuzzyTopics: string[];
  exactTopics: string[];
  icon?: keyof Icon.SvgIconComponent;
}

export interface MappedAlertType extends AlertType {
  id: string;
}

export interface AlertConfig {
  alertId?: string;
  alertTypeId: string;
  libraryId: string;
  amplyfiEntityId: string;
}

export interface CombinedAlert extends AlertType, LibraryAlert {}

export async function createAlertType(organisationId: string, alertType: AlertType): Promise<AlertType> {
  const { data } = await axios.post<AlertType>(`${apiUrl}/organisations/${organisationId}/alert-types`, alertType);
  return data;
}

export async function editAlertType(organisationId: string, alertType: AlertType): Promise<AlertType> {
  const { data } = await axios.put<AlertType>(
    `${apiUrl}/organisations/${organisationId}/alert-types/${alertType.alertTypeId}`,
    alertType
  );
  return data;
}

export async function getAlertTypes(organisationId: string): Promise<MappedAlertType[]> {
  const { data } = await axios.get<AlertType[]>(`${apiUrl}/organisations/${organisationId}/alert-types`);
  return data.map((alertType) => ({ ...alertType, id: alertType.alertTypeId as string }));
}

export async function getAlertType(organisationId: string, alertId: string): Promise<MappedAlertType> {
  const { data } = await axios.get<AlertType>(`${apiUrl}/organisations/${organisationId}/alert-types/${alertId}`);
  return { ...data, id: data.alertTypeId as string };
}

export async function getAlertTypesForLibrary(libraryId: string): Promise<AlertType[]> {
  const {
    data: { alerts },
  } = await axios.get<{ alerts: AlertType[] }>(`${apiUrl}/libraries/${libraryId}/alerts`);
  return alerts;
}

export async function createAlertForLibrary(libraryId: string, alertConfig: AlertConfig): Promise<AlertConfig> {
  const { data } = await axios.post<AlertConfig>(`${apiUrl}/libraries/${libraryId}/alerts`, alertConfig);
  return data;
}

export async function removeAlertFromLibrary(libraryId: string, alertId: string): Promise<AlertConfig> {
  const { data } = await axios.delete<AlertConfig>(`${apiUrl}/libraries/${libraryId}/alerts/${alertId}`);
  return data;
}

export async function deleteAlertType(organisationId: string, alertId: string): Promise<void> {
  const { data } = await axios.delete<void>(`${apiUrl}/organisations/${organisationId}/alert-types/${alertId}`);
  return data;
}
