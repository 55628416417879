import SnackBar from "@amplyfi/ui-components/components/SnackBar";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAnalyseSelector } from "../../store/reducers";
import { clearSnack } from "../../store/reducers/ui/snackReducer";
import { zIndex } from "../../helpers/componentsZIndex";

export default function Snacker(): JSX.Element {
  const message = useAnalyseSelector((x) => x.snack.message);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!message);
  }, [message]);

  return (
    <div onClick={message?.onClick ?? undefined} style={{ cursor: message?.onClick ? "pointer" : undefined, zIndex: open ? zIndex.snacker : -1 }}>
      <SnackBar
        data-testid="snackbar"
        open={open}
        setOpen={setOpen}
        onClose={() => {
          dispatch(clearSnack(message));
        }}
        autoHideDuration={message?.autoHideDuration}
        title={message?.title ?? ""}
        body={message?.body ?? ""}
      />
    </div>
  );
}
