import { makeStyles, TextField } from "@material-ui/core";
import { useState } from "react";
import { getUserId } from "../../../../helpers/user";
import useCreateFeed from "../../../../hooks/mutations/feed/useCreateFeed";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import SimpleDialog, { AbstractDialogProps } from "../../../Widgets/Dialog/SimpleDialog";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputLabel-root": {
      zIndex: 50,
      pointerEvents: "none",
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
}));

interface CreateLibraryDialogProps extends AbstractDialogProps {
  onSave: (libraryId: string) => void;
}

export default function CreateLibraryDialog(props: CreateLibraryDialogProps) {
  const { onClose, onSave } = props;
  const { user } = useAuth0();
  const classes = useStyles();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { mutateAsync: createFeed } = useCreateFeed();

  async function confirm() {
    const result = await createFeed({ name, description, dashboards: [], subscribedUserIds: [getUserId(user)], subscribedGroupIds: [] });
    onSave(result.id);
  }

  return (
    <SimpleDialog
      {...props}
      title="Create new Library"
      onClose={onClose}
      onConfirm={name.length && description.length ? confirm : undefined}
      content={
        <div>
          <TextField className={classes.input} label="Name" fullWidth onChange={(e) => setName(e.target.value)} />
          <TextField
            label="Description"
            className={classes.input}
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      }
    />
  );
}
