import AnalyseTooltip from "@amplyfi/ui-components/components/Tooltip";
import Badge from "@amplyfi/ui-components/components/Badge";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { FilterOptionsData } from "./FilterDrawer";
import { zIndex } from "../../../helpers/componentsZIndex";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";

const useStyles = makeStyles(({ spacing, typography, palette }: Theme) =>
  createStyles({
    container: {
      backgroundColor: palette.componentBackground.main,
      padding: spacing(1),
      display: "flex",
      alignItems: "center",

      "&:hover": {
        backgroundColor: "#F7F9FA",

        "& $icon": {
          color: "#008AE0",
        },

        "& $title": {
          color: "#253946",
        },
      },

      "& .MuiBadge-badge": {
        background: "#008AE0",
      },
    },
    selected: {
      backgroundColor: "#89C5F0",

      "& $icon": {
        color: "#008AE0",
      },

      "& $title": {
        color: "#253946",
      },
    },
    icon: {
      cursor: "pointer",
      marginLeft: spacing(0.5),
      marginRight: spacing(1),
      verticalAlign: "middle",
      color: "#C0D0DA",
      fontSize: "21px",
    },
    tooltip: {
      zIndex: zIndex.snacker,
    },
    title: {
      marginRight: "auto",
      color: "#748998",
    },
    appliedDot: {
      height: spacing(1.8),
      width: spacing(1.8),
      backgroundColor: "#89C5F0",
      borderRadius: "50%",
      display: "inline-block",
    },
  })
);

interface FilterButtonProps {
  detail: FilterOptionsData;
  activeFilter?: FilterOptionsData;
  count?: number;
  setActiveFilter: (option: FilterOptionsData) => void;
  showApplied?: boolean;
}

export default function FilterButton(props: FilterButtonProps): JSX.Element {
  const { detail, setActiveFilter, activeFilter, count, showApplied } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, activeFilter?.panelName === detail.panelName && classes.selected)}
      onClick={() => setActiveFilter(detail)}
    >
      <AnalyseTooltip arrow title={detail.discription} placement="bottom-end">
        <InfoIcon className={classes.icon} />
      </AnalyseTooltip>
      <Typography variant="h6" className={classes.title}>
        {detail.title}
      </Typography>
      <Badge
        color={count === 0 ? "error" : "primary"}
        badgeContent={detail.showCounts ? count : 0}
        data-testid={`${detail.panelName}-count-id`}
      />
      {showApplied && <div className={classes.appliedDot} />}
      <ChevronRightIcon className={classes.icon} />
    </div>
  );
}
