import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useFieldStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    chip: {
      "& > .MuiChip-label": {
        textTransform: "none",
      },
    },
    chips: {
      marginBottom: spacing(1),
      marginTop: spacing(1),
      "& .MuiChip-root": {
        marginRight: spacing(1),
        marginBottom: spacing(1),
      },
    },
    control: {
      width: "100%",
    },
    error: {
      textTransform: "none",
    },
  })
);
