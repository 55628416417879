import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import { ConnectorSortBy, ConnectorSortOrder, SearchResult } from "../http/connectors";
import { searchResults } from "../http/queries/connectors";

export default function useConnectorResults(
  searchId: string,
  selectedConnectors: string[],
  selectedResults: string[],
  getCount = false,
  sortBy = ConnectorSortBy.Relevancy,
  sortOrder = ConnectorSortOrder.Descending,
  page: number,
  size: number
): {
  data: SearchResult;
  isLoading: boolean;
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  refetch: () => void;
} {
  const res: UseInfiniteQueryResult<SearchResult> = useInfiniteQuery(
    ["getSearchResults", searchId, selectedConnectors, selectedResults, getCount, sortBy, sortOrder, size],
    async ({ pageParam = 0 }) => {
      const results = await searchResults(
        searchId,
        selectedConnectors,
        selectedResults,
        0,
        sortBy,
        sortOrder,
        pageParam,
        size
      );
      if (!results || results.results.length === 0) {
        throw new Error();
      }
      return {
        ...results,
        pageNumber: pageParam,
      };
    },
    {
      retry: 6,
      retryDelay: 3000,
      getNextPageParam: (lastPage) => {
        return lastPage.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );
  const itemsProcessed = res.data?.pages[res.data?.pages.length - 1].itemsProcessed || 0;
  const totalResults =
    res.data?.pages.reduce((accum, current) => {
      return accum + current.results.length;
    }, 0) || 0;

  return {
    isLoading: res.isLoading,
    isFetching: res.isFetching,
    fetchNextPage: res.fetchNextPage,
    hasNextPage: totalResults < itemsProcessed,
    refetch: res.refetch,
    data: res.data?.pages.reduce<SearchResult>(
      (accum, current) => {
        return {
          results: [...accum.results, ...current.results],
          totalResults: accum.totalResults + current.itemsProcessed,
          connectorCompletions: {},
          connectorBreakdown: {},
          connectorStatuses: {},
          itemsProcessed: 0,
          searchId: current.searchId,
          timestamp: current.timestamp,
          pageNumber: current.pageNumber,
        };
      },
      {
        results: [],
        totalResults: 0,
        connectorCompletions: {},
        connectorBreakdown: {},
        connectorStatuses: {},
        itemsProcessed: 0,
        searchId: "",
        timestamp: 0,
        pageNumber: 0,
      }
    ) || {
      results: [],
      totalResults: 0,
      connectorCompletions: {},
      connectorBreakdown: {},
      connectorStatuses: {},
      itemsProcessed: 0,
      searchId: "",
      timestamp: 0,
      pageNumber: 0,
    },
  };
}
