import useAPI from "../../../queries/api/useAPI";
import { CommentsResponse, ResourceType, commentKey } from "../../../queries/comments/useComments";
import useDelete from "../../helpers/useDelete";
import useUpdateCommentCount from "./useUpdateCommentCount";

export default function useDeleteComment(resourceType: ResourceType, resourceId: string) {
    const api = useAPI("v5", `${resourceType}/${resourceId}/${commentKey}`, { useOrganisation: false, useLibrary: true });
    const { mutateAsync } = useUpdateCommentCount(resourceId);
    return useDelete<CommentsResponse>({
        url: api,
        key: commentKey,
        onDataChange: (old, id) => {
            const oldData = old || { comments: [] };
            mutateAsync(-1);
            return {
                ...oldData,
                comments: oldData.comments.filter((c) => c.commentId !== id)
            }
        }
    });
}