import { Box, BoxProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode } from "react";

interface StackProps extends BoxProps {
  children: ReactNode;
  direction?: "vertical" | "horizontal";
  spacing: number;
  noWrap?: boolean;
}

const useStyles = makeStyles(({ spacing }) => ({
  stack: {
    "& > *:not(:last-child)": {
      marginBottom: (props: StackProps) => (props.direction === "vertical" ? spacing(props.spacing) : 0),
      marginRight: (props: StackProps) => (props.direction === "horizontal" ? spacing(props.spacing) : 0),
    },
    display: (props: StackProps) => (props.direction === "horizontal" ? "flex" : undefined),
    flexWrap: (props: StackProps) => props.noWrap ? undefined : "wrap",
    alignItems: "center",
  },
}));

export default function Stack(props: StackProps) {
  const { direction = "vertical" } = props;
  const classes = useStyles({ ...props, direction });
  return (
    <Box {...props} className={clsx(classes.stack, props.className)}>
      {props.children}
    </Box>
  );
}
