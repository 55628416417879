import {
  Star as FavouriteIcon,
  DescriptionOutlined as ReportsIcon,
  Business as OrganisationIcon,
  EventAvailable,
} from "@material-ui/icons";
import { ReactComponent as InsightIcon } from "../../../../../assets/insight.svg";
import { ReactComponent as SignalIcon } from "../../../../../assets/signal.svg";
import { FeedResponse, UserResponseFeedbackState } from "../../../../../http/feed";
import { ReactNode } from "react";
import { EntityType } from "../../../EntityKnowledgeBase/models";

export enum FeedViewType {
  Profile = "profile",
  Insight = "insight",
  Signal = "signal",
  Reports = "reports",
  Events = "events"
}

export interface FeedFilter {
  id: UserResponseFeedbackState | "all";
  label: string;
  beta?: boolean;
  default?: boolean;
  icon: JSX.Element;
  count?: number | ((data: FeedResponse) => number);
  section?: boolean;
  entityType?: EntityType;
  isView?: boolean;
  empty: {
    title: string;
    description: string;
  };
  tooltip: NonNullable<ReactNode>;
  type: FeedViewType;
  featureFlag?: string;
}

export interface PageType {
  id: string;
  label: string;
  icon: JSX.Element;
  route: string;
  count: (data: FeedResponse) => number;
  empty: {
    title: string;
    description: string;
  };
  tooltip: string;
  type: FeedViewType;
  visibleIn?: EntityType[];
  hideSearch?: boolean;
  hideFilters?: boolean,
  featureFlag?: string;
}

export const signalsPage: PageType = {
  id: "inbox",
  label: "Signals",
  icon: <SignalIcon />,
  count: (data) => data.inboxDocumentCount,
  empty: {
    title: "You’re all caught up",
    description: "New Signals will arrive here",
  },
  tooltip: "A single source of information based on your interests and signal types",
  type: FeedViewType.Signal,
  route: "signals",
};

export const insightsPage: PageType = {
  id: "inbox",
  label: "Insights",
  icon: <InsightIcon />,
  count: (data) => data.inboxDocumentCount,
  empty: {
    title: "You’re all caught up",
    description: "New Insights will arrive here",
  },
  tooltip: "Analysis of signals that are significant or display a trend/pattern",
  type: FeedViewType.Insight,
  route: "insights",
};

export const reportsPage: PageType = {
  id: "inbox",
  label: "Reports",
  icon: <ReportsIcon />,
  count: (data) => data.inboxDocumentCount,
  empty: {
    title: "You’re all caught up",
    description: "New Reports will arrive here",
  },
  tooltip: "In-depth report creation. Detailed analysis for strategic decision-making",
  type: FeedViewType.Reports,
  route: "reports",
};

export const profilePage: PageType = {
  id: "inbox",
  label: "Profile",
  icon: <OrganisationIcon />,
  count: (data) => data.inboxDocumentCount,
  visibleIn: [EntityType.Organisation],
  empty: {
    title: "",
    description: "",
  },
  tooltip: "In-depth organisation profile. Structured data for fast knowledge onboarding",
  type: FeedViewType.Profile,
  route: "profile",
  hideSearch: true,
  hideFilters: true,
};

export const eventsPage: PageType = {
  id: "inbox",
  label: "Events",
  icon: <EventAvailable />,
  count: () => 0,
  empty: {
    title: "",
    description: "",
  },
  tooltip: "Event timeline. An overview of past events",
  type: FeedViewType.Events,
  visibleIn: [EntityType.Organisation, EntityType.Technology],
  route: "events",
  hideSearch: true,
  hideFilters: true,
  featureFlag: "feedEventTimeline"
};

export const defaultFeedViews: PageType[] = [signalsPage, insightsPage, reportsPage, profilePage, eventsPage];

export const favouriteFeedViews: FeedFilter[] = [
  {
    id: "favourites",
    label: "Favourites",
    count: (data) => data.favouriteDocumentCount,
    icon: <FavouriteIcon />,
    empty: {
      title: "You don’t have any favourites yet",
      description: "Favourite an insight to see it here",
    },
    tooltip: <></>,
    type: FeedViewType.Insight,
  },
  {
    id: "favourites",
    label: "Favourites",
    count: (data) => data.favouriteDocumentCount,
    icon: <FavouriteIcon />,
    empty: {
      title: "You don’t have any favourites yet",
      description: "Favourite a signal to see it here",
    },
    tooltip: <></>,
    type: FeedViewType.Signal,
  },
];
