import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
      marginRight: theme.spacing(),
    },
    errorContainer: {
      display: "inline-flex",
      alignItems: "center",
    },
    caretContainer: {
      position: "relative",
    },
    caret: {
      position: "absolute",
      top: 15,
      color: theme.palette.error.main,
    },
  })
);

export default function Error(props: { err: LuceneError; query: string }): JSX.Element {
  const { err, query } = props;
  const classes = useStyles();
  const offset = err.location.start.offset;
  const start = Math.max(offset - 50, 0);
  const end = Math.min(offset + 50, query.length - 1);
  return (
    <div className={classes.errorContainer}>
      <Typography className={classes.error}>Error on line {err.location.start.line}</Typography>
      <Typography>
        <span>
          {start > 0 && "..."}
          {query.slice(start, offset)}
        </span>
        <span className={classes.caretContainer}>
          <span className={classes.caret}>^</span>
        </span>
        <span>
          {query.slice(offset, end)}
          {end < query.length - 1 && "..."}
        </span>
      </Typography>
    </div>
  );
}

export interface LuceneError {
  location: {
    start: {
      offset: number;
      line: number;
      column: number;
    };
    end: {
      offset: number;
      line: number;
      column: number;
    };
  };
  message: string;
}
