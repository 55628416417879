import { testId } from "@amplyfi/ui-components/testHelpers";
import { TestDataProps } from "@amplyfi/ui-components/types";
import { createStyles, ListItemAvatar, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { EntityType, AmplyfiUnknownEntity } from "../../models";
import { DeletedChip } from "./DeletedChip";
import { UnverifiedChip } from "./UnverifiedChip";
import Image from "../../../Feed/components/Image";
import { getAmplyfiEntityLogo } from "../../../../../helpers/imageHelpers";
import { isEntityUsed } from "../../helpers/entityHelpers";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    chips: {
      marginBottom: spacing(1),
      marginTop: spacing(1),
      "& .MuiChip-root": {
        marginRight: spacing(1),
        marginBottom: spacing(1),
      },
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 40,
      maxHeight: 80,
    },
    image: {
      maxWidth: "100%",
    },
  })
);

interface EntityHeaderProps extends Partial<TestDataProps> {
  entity: AmplyfiUnknownEntity;
  isDeleted: boolean;
  isVerified: boolean;
}

export function EntityHeader({
  "data-testid": dataTestId,
  entity,
  isDeleted,
  isVerified,
}: EntityHeaderProps): JSX.Element {
  const { id, name, type, usedInLibraries } = entity;
  const classes = useStyles();
  const displayName = `${EntityType[type]}: ${name}`;
  const chips: JSX.Element[] = [
    ...(isDeleted ? [<DeletedChip data-testid={testId(dataTestId, "is-deleted")} id={id} key="deleted-chip" />] : []),
    ...(isVerified
      ? []
      : [<UnverifiedChip data-testid={testId(dataTestId, "is-unverified")} id={id} key="unverified-chip" />]),
  ];

  return (
    <>
      <section style={{ display: "flex", alignItems: "center" }}>
        <ListItemAvatar>
          <div className={classes.imageContainer}>
            <Image
              className={classes.image}
              alt={`${EntityType[type]}: ${name}`}
              src={getAmplyfiEntityLogo(id) as string}
            />
          </div>
        </ListItemAvatar>
        <div>
          <Typography data-testid={testId(dataTestId, "name")} variant="h3" title={displayName}>
            {displayName}
          </Typography>
          {!!usedInLibraries && (
            <Typography variant="body2" data-testid={testId(dataTestId, "library-count")}>
              {isEntityUsed(entity)
                ? `Used within ${usedInLibraries.length} ${usedInLibraries.length === 1 ? "library" : "libraries"}`
                : "Not used within any libraries"}
            </Typography>
          )}
        </div>
      </section>
      {!!chips.length && <div className={classes.chips}>{chips}</div>}
    </>
  );
}
