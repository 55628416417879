/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useAuth0 as useAuth0Auth } from "@auth0/auth0-react";
import axios, { AxiosError } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { jwtDecode, JwtHeader } from "jwt-decode";
import { useEffect } from "react";
import { accountClient } from "../../http/accounts";
import { analyseClient } from "../../http/autocomplete";
import { foragerClient } from "../../http/connectors";
import { harvestClient } from "../../http/harvest";
import { AuthUser } from "../../models/user";
import { configureScope } from "@sentry/browser";
import { createConnection } from "../../helpers/analytics";
import { getUserId } from "../../helpers/user";

export const useAuth0 = () => {
  const { user } = useAuth0Auth();
  return { ...useAuth0Auth(), user: user as AuthUser };
};

interface Jwks {
  keys: { kid: string }[];
}

async function getValidKids(): Promise<string[]> {
  try {
    const axiosStatic = await axios.create();
    const {
      data: { keys },
    } = await axiosStatic.get<Jwks>(`https://${process.env.REACT_APP_AUTH_DOMAIN}/.well-known/jwks.json`);
    return keys.map(({ kid }) => kid);
  } catch (err) {
    return [];
  }
}

// render children
export default function AuthWrapper(props: { children: JSX.Element }) {
  const { getAccessTokenSilently, logout, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user) {
      const id = getUserId(user);
      createConnection(user);
      configureScope((scope) => scope.setUser({ id }));
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const onResponseRejected = async (failedRequest: AxiosError) => {
      return new Promise(async (resolve, reject) => {
        const token = await getAccessTokenSilently();
        const { kid = "" } = jwtDecode<JwtHeader>(token, { header: true });
        const kids = await getValidKids();
        if (!kids.includes(kid)) {
          try {
            const token = await getAccessTokenSilently({ ignoreCache: true });
            if (failedRequest.response) {
              failedRequest.response.config.headers["Authorization"] = `Bearer ${token}`;
            }
          } catch (e2) {
            logout({ returnTo: window.location.origin });
            reject(failedRequest);
          }
        }
        resolve(failedRequest);
      });
    };
    [accountClient, axios, analyseClient, foragerClient, harvestClient].forEach((client) => {
      client.interceptors.request.use(async (c) => {
        try {
          // this must be called before each request
          const token = await getAccessTokenSilently();
          if (c.headers) {
            c.headers["Authorization"] = `Bearer ${token}`;
          }
          return c;
        } catch (e) {}
        return c;
      });
      client.interceptors.response.use((s) => s);
      createAuthRefreshInterceptor(client, onResponseRejected, {
        interceptNetworkError: true,
        statusCodes: [401, 403],
      });
    });
    axios.interceptors.response.use(
      async (response) => response,
      (err) => {
        if (err && err.response && err.response.config) {
          const { data, config, status } = err.response;
          throw new Error(`${status} ${config.url}\n\n${JSON.stringify(data)}`);
        }
        return Promise.reject(err);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.children;
}
