import { useState, useEffect } from "react";
import { useAuth0 } from "../../../components/Auth0/AuthWrapper";

export default function useAuthToken() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    async function fetchToken() {
      setToken((await getAccessTokenSilently()) as unknown as string);
    }

    fetchToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return token;
}
