import {
  DarkGrey,
  LightGrey,
  LinkBlueElectric,
  LinkBlueSky,
  MidBlue,
  White,
  Wind,
} from "@amplyfi/ui-components/theme/colors";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BusinessIcon from "@material-ui/icons/Business";
import ClearIcon from "@material-ui/icons/Clear";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import clsx from "clsx";
import React, { useState } from "react";
import { Entity, EntityType } from "../../../models/entity";
import { QueryType } from "../../../models/query";
import { SearchType } from "../../../models/search";
import EntityAutocomplete from "../Entity/EntityAutocomplete";
import SearchTypeSelector from "./SearchTypeSelector";

const useTagStyles = makeStyles((theme: Theme) =>
  createStyles({
    tag: {
      display: "flex",
      alignItems: "center",
      flex: "0 1 auto",
      height: "100%",
      maxWidth: "100%",
      borderRadius: 10,
      padding: theme.spacing(0.25, 1.5),
      marginRight: theme.spacing(),
      backgroundColor: theme.palette.accent.main,

      "&:last-of-type": {
        marginRight: 0,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cursor: (props: any) => (props?.onClick ? "pointer" : "default"),
    },
    tagBorder: {
      border: `1px solid ${Wind} !important`,
    },
    adderTag: {
      backgroundColor: theme.palette.accent.contrastText,
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    adderContainer: {
      display: "flex",
      height: 30,
      width: "100%",
      maxWidth: 350,
    },
    fullHeight: {
      height: "100%",
    },
    fullWidth: {
      width: "100%",
      maxWidth: "100%",
    },
    tagText: {
      color: theme.palette.primary.main,
      alignItems: "center",
      display: "flex",
      fontWeight: "bold",
    },
    clearIcon: {
      color: DarkGrey,
      padding: 0,
      "& svg": {
        width: 16,
      },
    },
    tagIcon: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(0.5),
      width: 20,
    },
    operatorTag: {
      "& > *": {
        color: White,
      },
      background: MidBlue,
      cursor: "pointer",
      padding: theme.spacing(0.25, 1.5),
      borderRadius: 10,
      position: "relative",
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tags: {
      maxHeight: 200,
      overflow: "auto",

      "& p": {
        paddingRight: theme.spacing(1),
        fontWeight: "bold",
      },
    },
    removeTag: {
      color: LightGrey,
      cursor: "pointer",
      fontSize: 13,
    },
    inputBase: {
      paddingRight: 0,
      "& svg": {
        display: "none",
      },
      border: "none",
      background: "transparent",
      color: theme.palette.links.primary,
      fontWeight: 600,
      "& > *::placeholder": {
        color: theme.palette.links.primary,
        opacity: 1,
      },
    },
    input: {
      height: 28,
    },
    autocomplete: {
      margin: 0,
    },
    orContainer: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(0, 1),
      "& > span": {
        color: LinkBlueSky,
        fontWeight: "bold",
      },
    },
    singleIcon: {
      display: "flex",
      alignItems: "center",
      backgroundColor: LinkBlueElectric,
      marginLeft: theme.spacing(-2),
      padding: theme.spacing(1),

      "&>svg": {
        color: White,
      },
    },
    hideOverflow: {
      overflow: "hidden",
      paddingTop: 0,
      paddingBottom: 0,
    },
    fullHeight: {
      height: "100%",
    },
  })
);

type Icons = Record<EntityType, JSX.Element>;

interface EntityListProps {
  inlineAdder?: boolean;
  onRemove?: (index: number, entity: Entity) => void;
  // only required if you have an inline adder
  onAdd?: (entity: Entity, type?: QueryType) => void;
  entities?: Entity[];
  joinedBy?: "or" | "and";
  className?: string;
  withTypeSelector?: boolean;
  singleTypeSelector?: EntityType;
  placeholderText?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  freeText?: boolean;
}

export default function EntityList(props: EntityListProps): JSX.Element {
  const classes = useStyles();
  const tagClasses = useTagStyles({});
  const icons: Icons = {
    [EntityType.Organisation]: <BusinessIcon className={tagClasses.tagIcon} />,
    [EntityType.Country]: <LocationOnIcon className={tagClasses.tagIcon} />,
    [EntityType.Person]: <PersonIcon className={tagClasses.tagIcon} />,
    [EntityType.KeyPhrase]: <></>,
    [EntityType.Settlement]: <></>,
    [EntityType.None]: <></>,
  };

  const {
    onRemove,
    inlineAdder,
    entities,
    onAdd,
    joinedBy = "or",
    className,
    withTypeSelector,
    singleTypeSelector,
    placeholderText,
    fullHeight = false,
    fullWidth = false,
    freeText,
  } = props;

  const singleTypeSelectorIcon = singleTypeSelector ? icons[singleTypeSelector] : undefined;

  return (
    <div className={className}>
      <Grid spacing={1} container className={clsx(classes.tags, fullHeight && classes.fullHeight)}>
        {inlineAdder && onAdd && (
          <Grid xs={fullWidth ? 12 : "auto"} key="adder" item>
            <EntityInlineAdder
              withTypeSelector={withTypeSelector}
              onAdd={onAdd}
              singleIcon={singleTypeSelectorIcon}
              placeholderText={placeholderText}
              selectedType={singleTypeSelector}
              fullHeight={fullHeight}
              freeText={freeText}
              fullWidth={fullWidth}
            />
          </Grid>
        )}
        {entities &&
          entities.map((entity, index) => (
            <React.Fragment key={index}>
              <Grid item>
                <EntityListItem
                  onRemove={onRemove}
                  index={index}
                  entity={entity}
                  icons={icons}
                  dataTestId={`tag_${entity.name}`}
                  removeBtnTestId={`btn_removeTag_${entity.name}`}
                />
              </Grid>
              {index !== entities.length - 1 && (
                <Grid xs="auto" item className={classes.orContainer}>
                  <span>{joinedBy}</span>
                </Grid>
              )}
            </React.Fragment>
          ))}
      </Grid>
    </div>
  );
}

interface EntityListItemProps {
  entity: Entity;
  index: number;
  onRemove?: (index: number, entity: Entity) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, entity: Entity) => void;
  icons: Icons;
  dataTestId?: string;
  removeBtnTestId?: string;
}

function EntityListItem(props: EntityListItemProps): JSX.Element {
  const classes = useTagStyles(props);
  const { entity, icons, index, onRemove, onClick, dataTestId, removeBtnTestId } = props;

  return (
    <div
      onClick={(e) => {
        if (onClick) {
          onClick(e, entity);
        }
      }}
      className={clsx(classes.tag, classes.tagBorder)}
      key={entity.id || entity.name}
      data-testid={dataTestId}
    >
      <Typography variant="h6" className={classes.tagText}>
        {(entity.type && icons[entity.type]) || ""}
        {entity.name}
      </Typography>
      <IconButton
        className={classes.clearIcon}
        onClick={(e) => {
          e.stopPropagation();
          onRemove && onRemove(index, entity);
        }}
        data-testid={removeBtnTestId}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
}

function EntityInlineAdder(props: {
  onAdd: (entity: Entity) => void;
  withTypeSelector?: boolean;
  singleIcon?: JSX.Element;
  placeholderText?: string;
  selectedType?: EntityType | null;
  fullHeight?: boolean;
  freeText?: boolean;
  fullWidth?: boolean;
}): JSX.Element {
  const tagClasses = useTagStyles({});
  const classes = useStyles();
  const {
    onAdd,
    withTypeSelector,
    singleIcon,
    placeholderText,
    selectedType = null,
    fullHeight = false,
    freeText,
    fullWidth,
  } = props;
  const switchSearchType: SearchType = freeText ? "FreeText" : (selectedType as SearchType);
  const [searchType, setSearchType] = useState<SearchType | null>(
    withTypeSelector ? EntityType.Organisation : switchSearchType
  );

  return (
    <div
      className={clsx(
        tagClasses.adderContainer,
        fullHeight && tagClasses.fullHeight,
        fullWidth && tagClasses.fullWidth
      )}
    >
      {withTypeSelector && <SearchTypeSelector small setSearchType={setSearchType} type={searchType} />}
      <div
        className={clsx(
          tagClasses.tag,
          tagClasses.tagBorder,
          withTypeSelector && tagClasses.adderTag,
          singleIcon && classes.hideOverflow,
          fullWidth && tagClasses.fullWidth
        )}
      >
        {singleIcon && <div className={classes.singleIcon}>{singleIcon}</div>}
        <EntityAutocomplete
          autocompleteClasses={classes.autocomplete}
          inputBaseClasses={classes.inputBase}
          inputClasses={classes.input}
          onAdd={onAdd}
          placeholder={placeholderText || "Add query..."}
          type={searchType}
          dataTestId="inliner-entity-autocomplete"
        />
      </div>
    </div>
  );
}
