import { useQueries, UseQueryResult } from "./useQuery";
import { useDispatch } from "react-redux";
import { convertSearchStateToSearchRequestV2 } from "../helpers/search";
import { searchDocuments } from "../http/document";
import { SearchResponse } from "../models/search";
import { useAnalyseSelector } from "../store/reducers";
import { DocumentTabRDV, setDocumentslist } from "../store/reducers/searchResponse/relatedDocumentViewerReducer";
import useRelatedDocumentFilters from "./useRelatedDocumentFilters";
import { useEffect } from "react";
import { SortType } from "../models/document";
import moment from "moment";

type QueryResponse = {
  [t in DocumentTabRDV]: SearchResponse;
};
export default function useRelatedDocumentList(
  type: DocumentTabRDV,
  sortOverride?: SortType,
  updateReducer?: boolean,
  withoutDates?: boolean,
  documentsCountOverride?: number,
  includeUndatedDocuments?: boolean
): UseQueryResult<QueryResponse, unknown>;
export default function useRelatedDocumentList(
  type: DocumentTabRDV[],
  sortOverride?: SortType,
  updateReducer?: boolean,
  withoutDates?: boolean,
  documentsCountOverride?: number,
  includeUndatedDocuments?: boolean
): UseQueryResult<QueryResponse, unknown>[];
export default function useRelatedDocumentList(
  type: DocumentTabRDV | DocumentTabRDV[],
  sortOverride?: SortType,
  updateReducer = true,
  withoutDates = false,
  documentsCountOverride?: number,
  includeUndatedDocuments?: boolean
): UseQueryResult<QueryResponse, unknown>[] | UseQueryResult<QueryResponse, unknown> {
  const url = useAnalyseSelector((state) => state.searchRequest.url.parsed);
  const dispatch = useDispatch();
  const typesArray = Array.isArray(type) ? type : [type];
  const { numberOfDocumentsShown, dates } = useAnalyseSelector((x) => x.searchResponse.relatedDocumentViewer);
  const rdv = useAnalyseSelector((x) => x.searchResponse.relatedDocumentViewer);
  const request = convertSearchStateToSearchRequestV2(url);
  request.systemFilters.documentsCount = documentsCountOverride ?? numberOfDocumentsShown;
  request.systemFilters.documentsFilters = useRelatedDocumentFilters();
  if (withoutDates) {
    request.userFilters.startDate = undefined;
    request.userFilters.endDate = undefined;
  } else {
    request.userFilters.startDate = dates.startDate
      ? moment.utc(dates.startDate)?.toISOString()
      : request.userFilters.startDate;
    request.userFilters.endDate = dates.endDate
      ? moment.utc(dates.endDate)?.toISOString()
      : request.userFilters.endDate;
  }
  if (includeUndatedDocuments !== undefined) {
    request.userFilters.includeUndatedDocuments = includeUndatedDocuments;
  }
  const documentsResponse = useQueries(
    typesArray.map((t) => {
      const { sortFilter } = rdv[t];
      request.systemFilters.publishedDateSortOrder = sortOverride ?? sortFilter;
      return {
        queryKey: ["related-viewer-document-list", request, t, url.libraries],
        queryFn: async () => {
          const data = await searchDocuments(request, t === "All" ? url.libraries : [t]);
          return { [t]: data } as QueryResponse;
        },
      };
    })
  );

  useEffect(() => {
    if (documentsResponse.length > 0 && updateReducer) {
      documentsResponse.forEach((response) => {
        if (!response.data) return;
        dispatch(
          setDocumentslist({
            response: Object.values(response.data)[0],
            type: Object.keys(response.data)[0] as DocumentTabRDV,
          })
        );
      });
    }
  }, [dispatch, documentsResponse, updateReducer]);

  return documentsResponse.length === 1 ? documentsResponse[0] : documentsResponse;
}
