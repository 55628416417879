import AnalyseSwitch from "@amplyfi/ui-components/components/Switch";
import Select from "@amplyfi/ui-components/components/Select";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { DarkSilver, LinkBlueElectric, SemiDarkSilver } from "@amplyfi/ui-components/theme/colors";
import { TestDataProps } from "@amplyfi/ui-components/types";
import { createStyles, FormControlLabel, makeStyles, MenuItem, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import { useAnalyseSelector } from "../../store/reducers";
import {
  setSkewCorrection,
  setSmoothing,
  SmoothingType,
} from "../../store/reducers/searchResponse/documentTimelineReducer";
import { zIndex } from "../../helpers/componentsZIndex";
import { TimeIntervalType, timeIntervalTypes } from "../../helpers/timeIntervals";
import { useFullscreenContainer } from "@amplyfi/ui-components/hooks/useFullscreenContainer";
import { updateInterval } from "../../store/reducers/searchRequest/urlReducer";

interface VisualisationSettingsContentProps extends TestDataProps {
  lowestInterval: TimeIntervalType;
  showSkewCorrection?: boolean;
  timeInterval: TimeIntervalType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0),
      maxWidth: 420,
    },
    subheading: {
      color: DarkSilver,
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.borders.main}`,

      "& h4": {
        marginLeft: theme.spacing(2),
      },
    },
    header: {
      marginLeft: theme.spacing(2),
    },
    entryHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
      alignItems: "center",
      margin: 0,
    },
    radioContainer: {
      borderBottom: `1px solid ${theme.palette.borders.main}`,
      padding: `${theme.spacing(2)}px 0`,
      "&:last-of-type": {
        borderBottom: 0,
      },
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    label: {
      color: LinkBlueElectric,
      cursor: "pointer",
    },
    sectionBody: {
      fontSize: 11,
      lineHeight: 1.5,
      letterSpacing: 0.2,
      color: SemiDarkSilver,
      marginTop: theme.spacing(0),
      width: "85%",
    },
    topMargin: {
      marginTop: theme.spacing(1),
    },
    radio: {
      "& :not(.Mui-disabled).MuiIconButton-colorSecondary ": {
        color: LinkBlueElectric,
      },
    },
  })
);

export default function VisualisationSettingsContent(props: VisualisationSettingsContentProps): JSX.Element {
  const { "data-testid": dataTestId, lowestInterval, showSkewCorrection, timeInterval } = props;
  const skewCorrection = useAnalyseSelector((x) => x.searchResponse.documentTimeline.skewCorrection);
  const smoothingValue = useAnalyseSelector((x) => x.searchResponse.documentTimeline.smoothing);
  const smoothing = Object.keys(SmoothingType)[Object.values(SmoothingType).indexOf(smoothingValue)];
  const dispatch = useDispatch();
  const classes = useStyles();
  const container = useFullscreenContainer();

  const lowestIntervalIndex = timeIntervalTypes.findIndex(({ id }) => id === lowestInterval);

  return (
    <div className={classes.container} data-testid={testId(dataTestId)}>
      <div className={classes.headerContainer}>
        <Typography variant="h4">Visual Settings</Typography>
      </div>

      <div className={classes.radioContainer}>
        <div className={classes.entryHeaderContainer}>
          <Typography className={classes.subheading} variant="h6">
            Smoothing
          </Typography>
        </div>
        <Select
          data-testid={testId(dataTestId, "smoothing")}
          options={Object.keys(SmoothingType)}
          value={smoothing}
          setValue={(value) => {
            setTimeout(() => {
              const val = value as keyof typeof SmoothingType;
              dispatch(setSmoothing(SmoothingType[val]));
            });
          }}
          MenuProps={{
            style: { zIndex: zIndex.selectMenu },
            container,
          }}
        />
        <Typography className={clsx(classes.sectionBody, classes.topMargin)} variant="body2">
          Smooth bumps in your analysis using curve interpolation.
        </Typography>
      </div>
      <div className={classes.radioContainer}>
        <div className={classes.entryHeaderContainer}>
          <Typography className={classes.subheading} variant="h6">
            Units of time
          </Typography>
        </div>
        <Select
          data-testid={testId(dataTestId, "time-units")}
          options={timeIntervalTypes.map(({ label, id }, i) => (
            <MenuItem key={id} disabled={i < lowestIntervalIndex} value={id}>
              {label}
            </MenuItem>
          ))}
          value={timeIntervalTypes.find(({ id }) => id === timeInterval)?.id || ""}
          setValue={(id) =>
            setTimeout(() => {
              dispatch(updateInterval(id as TimeIntervalType));
            })
          }
          MenuProps={{
            style: { zIndex: zIndex.selectMenu },
            container,
          }}
        />
        <Typography className={clsx(classes.sectionBody, classes.topMargin)} variant="body2">
          The best granularity of data points automatically selected for effective investigation of your analysis
        </Typography>
      </div>
      {showSkewCorrection && (
        <div className={classes.radioContainer}>
          <FormControlLabel
            className={classes.entryHeaderContainer}
            control={
              <AnalyseSwitch
                data-testid={testId(dataTestId, "normalise")}
                toggleSelected={() => {
                  dispatch(setSkewCorrection(!skewCorrection));
                }}
                selected={skewCorrection}
                selectedLabel=""
                unselectedLabel=""
              />
            }
            label={
              <Typography variant="h6" className={classes.subheading}>
                Normalise to Library data
              </Typography>
            }
            labelPlacement="start"
          />
          <Typography className={classes.sectionBody}>
            A varied number of documents are gathered for each period which can skew analysis by displaying trends or
            spikes driven by the volume of documents. This normalises based on the number of documents in your analysis
            for each period.
          </Typography>
        </div>
      )}
    </div>
  );
}
