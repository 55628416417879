import { LibraryItem } from "../models/search/documentLibrary";

export type Order = "asc" | "desc";

export function getComparator(order: Order, orderBy: keyof LibraryItem): (a: LibraryItem, b: LibraryItem) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T): number =>
  `${a[orderBy] ?? ""}`.localeCompare(`${b[orderBy] ?? ""}`, "en", { numeric: true });
