import { FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";

interface ConnectionStrengthProps {
  defaultValue: boolean;
  onChange: (value: boolean) => void;
}

export default function ConnectionStrength(props: ConnectionStrengthProps): JSX.Element {
  const { onChange, defaultValue } = props;

  return (
    <div>
      <Typography variant="h5">Connection Strength</Typography>
      <Typography style={{ marginTop: 8 }} variant="body2">
        Filter your content based on how closely your Mentioned Elements are connected
      </Typography>
      <RadioGroup
        value={defaultValue === true ? "withinSentence" : "withinDocument"}
        style={{ marginTop: 8 }}
        onChange={(e) => {
          onChange(e.target.value === "withinSentence");
        }}
      >
        <FormControlLabel value="withinDocument" control={<Radio />} label="Mentioned in the same document" />
        <FormControlLabel value="withinSentence" control={<Radio />} label="Mentioned in the same sentence" />
      </RadioGroup>
    </div>
  );
}
