import { DEFAULT_MAX_SENTIMENT, DEFAULT_MIN_SENTIMENT } from "../../../helpers/getRequestDefaults";
import { DocumentEntity, DocumentFilters, DocumentListItem, SortTypes } from "../../../models/document";
import { DocumentType, SearchResponse, TooltipType } from "../../../models/search";
import { Entity, EntityType, HeatMapEntry } from "../../../models/entity";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { MappedSentimentHistogramEntry } from "../../../models/sentiment";
import { PatentType } from "../../../models/patents";
import { SortType } from "../../../models/document";
import { convertCustomizationToEntity } from "../../../helpers/customization";
import { resetState } from "../searchRequest/actions";
import { LibrariesType } from "../../../models/queryParams";

export type DocumentTabRDV = LibrariesType | "All";

export interface DocumentListState {
  documentFilters: DocumentFilters;
  documents: DocumentListItem[];
  sortFilter: SortType;
  totalCount: number;
}

const defaultListState: DocumentListState = {
  documents: [],
  totalCount: 0,
  documentFilters: {
    minimumSentiment: DEFAULT_MIN_SENTIMENT,
    maximumSentiment: DEFAULT_MAX_SENTIMENT,
  },
  sortFilter: SortTypes.Descending,
};

export enum EntityGroupType {
  Person = "Person",
  Organisation = "Organisation",
  Country = "Country",
}

export type EntitiesGroups = {
  [key in EntityGroupType]?: DocumentEntity[];
};

export const defaultEntititesGroups: EntitiesGroups = {
  Person: undefined,
  Country: undefined,
  Organisation: undefined,
};

type ResultsType = EntitiesGroups & { Settlement?: DocumentEntity[] };

type DocumentTabsType = {
  [tab in DocumentTabRDV]: DocumentListState;
};
export interface DocumentState extends DocumentTabsType {
  selectedDocumentError: boolean;
  selectedDocumentLoading: boolean;
  selectedDocumentType: DocumentTabRDV;
  openRDV: boolean;
  selectedDocumentId: string;
  selectedListItemType: DocumentTabRDV;
  numberOfDocumentsShown: number;
  listScrollPosition: number;
  selectedDocumentEntites: EntitiesGroups;
  highlightedDocumentEntities: EntityGroupType[];
  firstResultLoaded: boolean;
  searchString: string;
  totalSearchStringMatches: number;
  currentSearchStringPosition?: number;
  loadingSearchMatches: boolean;
  showList: boolean;
  dates: {
    startDate: number | undefined;
    endDate: number | undefined;
  };
  documentFilters: {
    academicCountryFilters: Entity[];
    academicInstitutionsFilters: Entity[];
    keywordFilters: Entity[];
    keyPhraseFilters: Entity[];
    patentApplicantsOwnersFilters: Entity[];
    patentJurisdictions: Entity[];
    sectorsFilters: Entity[];
    publishLocationsFilters: string[];
    subSectorFilters: Entity[];
    adverseMediaFilters: Entity[];
    activeInFilters: Entity[];
  };
}
const initialState: DocumentState = {
  Academic: defaultListState,
  All: defaultListState,
  News: defaultListState,
  Patents: defaultListState,
  Private: defaultListState,
  selectedDocumentError: false,
  selectedDocumentLoading: false,
  selectedDocumentType: "All",
  selectedDocumentEntites: defaultEntititesGroups,
  openRDV: false,
  selectedDocumentId: "",
  selectedListItemType: "All",
  numberOfDocumentsShown: 100,
  listScrollPosition: 0,
  highlightedDocumentEntities: [],
  firstResultLoaded: false,
  searchString: "",
  totalSearchStringMatches: 0,
  currentSearchStringPosition: undefined,
  loadingSearchMatches: false,
  showList: true,
  dates: {
    startDate: undefined,
    endDate: undefined,
  },
  documentFilters: {
    academicCountryFilters: [],
    academicInstitutionsFilters: [],
    keywordFilters: [],
    keyPhraseFilters: [],
    patentApplicantsOwnersFilters: [],
    patentJurisdictions: [],
    sectorsFilters: [],
    publishLocationsFilters: [],
    subSectorFilters: [],
    adverseMediaFilters: [],
    activeInFilters: [],
  },
};

const searchSlice = createSlice({
  name: "relatedDocumentViewer",
  initialState: initialState,
  reducers: {
    viewRelatedDocuments(
      state: DocumentState,
      action: PayloadAction<{
        entities?: Entity[];
        startDate?: number;
        endDate?: number;
        type: DocumentType;
        patentType?: PatentType;
        publishLocations?: string[];
        tooltipType?: TooltipType;
      }>
    ) {
      state.listScrollPosition = 0;
      state.selectedDocumentId = "";
      const { entities = [], type, publishLocations = [], tooltipType, startDate, endDate } = action.payload;
      state.selectedDocumentType = "All";
      state.News.documentFilters.maximumSentiment = DEFAULT_MAX_SENTIMENT;
      state.News.documentFilters.minimumSentiment = DEFAULT_MIN_SENTIMENT;
      resetDocumentFilters(state);
      state.dates = {
        startDate,
        endDate,
      };
      if (type === DocumentType.News) {
        if (tooltipType === TooltipType.SuperSector) {
          state.documentFilters.sectorsFilters = entities;
        } else if (tooltipType === TooltipType.Sector) {
          state.documentFilters.subSectorFilters = entities;
        } else if (tooltipType === TooltipType.AdverseMedia) {
          state.documentFilters.adverseMediaFilters = entities;
        } else if (tooltipType === TooltipType.KeyPhrase) {
          state.documentFilters.keyPhraseFilters = entities;
        } else if (tooltipType === TooltipType.ActiveIn) {
          state.documentFilters.activeInFilters = entities;
        } else {
          state.documentFilters.keywordFilters = entities.filter((e) => e.type !== EntityType.KeyPhrase && e.type);
          state.documentFilters.keyPhraseFilters = entities.filter((e) => e.type === EntityType.KeyPhrase || !e.type);
        }
      } else if (type === DocumentType.Academic) {
        if (tooltipType === TooltipType.Geo) {
          state.documentFilters.academicCountryFilters = entities;
        } else {
          state.documentFilters.academicInstitutionsFilters = entities;
        }
      } else {
        if (tooltipType === TooltipType.Geo) {
          state.documentFilters.patentJurisdictions = entities;
        } else {
          state.documentFilters.patentApplicantsOwnersFilters = entities;
        }
      }
      state.documentFilters.publishLocationsFilters = publishLocations;
    },
    addSentimentFilter(state: DocumentState, action: PayloadAction<MappedSentimentHistogramEntry>) {
      resetDocumentFilters(state);
      if (action.payload.sentiment === null) {
        return;
      }
      state.listScrollPosition = 0;
      state.News.documentFilters.minimumSentiment = action.payload.sentiment;
      state.News.documentFilters.maximumSentiment = Number.parseFloat((action.payload.sentiment + 0.1).toFixed(1));
    },
    addHeatmapFilter(state: DocumentState, action: PayloadAction<HeatMapEntry>) {
      resetDocumentFilters(state);
      const { heatmapPrimary, heatmapSecondary } = action.payload;
      state.listScrollPosition = 0;
      state.selectedDocumentType = "All";
      state.documentFilters.keywordFilters = [
        convertCustomizationToEntity(heatmapPrimary),
        convertCustomizationToEntity(heatmapSecondary),
      ];
    },
    setSelectedDocumentTab(state: DocumentState, action: PayloadAction<DocumentTabRDV>) {
      state.selectedDocumentType = action.payload;
      state.selectedListItemType = action.payload;
      state.listScrollPosition = 0;
      // On tab change, it resets to the first toc
      state.selectedDocumentId = state[action.payload].documents[0]?.id ?? "";
    },
    onResetDocumentFilters(state: DocumentState) {
      state.selectedDocumentId = "";
      resetDocumentFilters(state);
    },
    changeSortFilterRDV(state: DocumentState, action: PayloadAction<{ type: DocumentTabRDV; value: SortType }>) {
      const { type, value } = action.payload;
      if (type === DocumentType.News) {
        state.News.sortFilter = value;
      } else if (type === DocumentType.Patents) {
        state.Patents.sortFilter = value;
      } else if (type === DocumentType.Academic) {
        state.Academic.sortFilter = value;
      } else {
        state.All.sortFilter = value;
      }
      state.selectedDocumentId = "";
      state.listScrollPosition = 0;
    },
    onSetOpenCloseDrawer(state: DocumentState, action: PayloadAction<boolean>) {
      state.openRDV = action.payload;
    },
    viewDocument(state: DocumentState, action: PayloadAction<string>) {
      state.selectedDocumentId = action.payload;
      state.openRDV = true;
    },
    setSelectedDocument(state: DocumentState, action: PayloadAction<{ id: string; type: DocumentTabRDV }>) {
      state.selectedDocumentId = action.payload.id;
      state.selectedListItemType = action.payload.type;
    },
    clearSelectedDocument(state: DocumentState) {
      state.selectedDocumentId = "";
      state.selectedListItemType = "All";
    },
    updateNumberOfDocs(state: DocumentState, action: PayloadAction<number>) {
      state.numberOfDocumentsShown = action.payload;
    },
    setListScrollPosition(state: DocumentState, action: PayloadAction<number>) {
      state.listScrollPosition = action.payload;
    },
    setDocumentslist(state: DocumentState, action: PayloadAction<{ response: SearchResponse; type: DocumentTabRDV }>) {
      const { response, type } = action.payload;
      state[type].documents = response.documents;
      state[type].totalCount = response.documentCount;
    },
    setDocumentLoading(state: DocumentState, action: PayloadAction<boolean>) {
      state.selectedDocumentLoading = action.payload;
    },
    setSelectedDocumentEntities(state: DocumentState, action: PayloadAction<DocumentEntity[] | undefined>) {
      const groups = groupEntities(action.payload);
      state.selectedDocumentEntites = groups;
      const highlightedGroups: EntityGroupType[] = [];
      Object.keys(groups).forEach((x) => {
        if (!!groups[x as EntityGroupType]) {
          highlightedGroups.push(x as EntityGroupType);
        }
      });
      state.highlightedDocumentEntities = highlightedGroups;
    },
    setHighlightedDocumentEntities(state: DocumentState, action: PayloadAction<EntityGroupType[]>) {
      state.highlightedDocumentEntities = action.payload;
    },
    setFirstResultLoaded(state: DocumentState, { payload }: PayloadAction<string | undefined>) {
      state.firstResultLoaded = true;
      if (payload) {
        state.selectedDocumentId = payload;
      }
    },
    setSearchString(state: DocumentState, action: PayloadAction<string>) {
      state.searchString = action.payload;
    },
    setTotalSearchStringMatches(state: DocumentState, action: PayloadAction<number>) {
      state.totalSearchStringMatches = action.payload;
    },
    setCurrentSearchStringPosition(state: DocumentState, action: PayloadAction<number | undefined>) {
      state.currentSearchStringPosition = action.payload;
    },
    setLoadingSearchMatches(state: DocumentState, action: PayloadAction<boolean>) {
      state.loadingSearchMatches = action.payload;
    },
    setSelectedDocumentError(state: DocumentState, action: PayloadAction<boolean>) {
      state.selectedDocumentError = action.payload;
    },
    setShowList(state: DocumentState, action: PayloadAction<boolean>) {
      state.showList = action.payload;
    },
  },
  extraReducers: {
    [resetState.type]: function () {
      return initialState;
    },
  },
});

export const {
  viewRelatedDocuments,
  addSentimentFilter,
  addHeatmapFilter,
  setSelectedDocumentTab,
  changeSortFilterRDV,
  onResetDocumentFilters,
  onSetOpenCloseDrawer,
  viewDocument,
  updateNumberOfDocs,
  setSelectedDocument,
  clearSelectedDocument,
  setListScrollPosition,
  setDocumentslist,
  setDocumentLoading,
  setSelectedDocumentEntities,
  setHighlightedDocumentEntities,
  setFirstResultLoaded,
  setSearchString,
  setTotalSearchStringMatches,
  setCurrentSearchStringPosition,
  setLoadingSearchMatches,
  setSelectedDocumentError,
  setShowList,
} = searchSlice.actions;

export default searchSlice.reducer;

function resetDocumentFilters(state: DocumentState) {
  state.documentFilters = {
    keywordFilters: [],
    keyPhraseFilters: [],
    sectorsFilters: [],
    subSectorFilters: [],
    academicCountryFilters: [],
    academicInstitutionsFilters: [],
    patentJurisdictions: [],
    patentApplicantsOwnersFilters: [],
    publishLocationsFilters: [],
    adverseMediaFilters: [],
    activeInFilters: [],
  };
  state.News.documentFilters.minimumSentiment = DEFAULT_MIN_SENTIMENT;
  state.News.documentFilters.maximumSentiment = DEFAULT_MAX_SENTIMENT;
  state.All.documents = [];
  state.News.documents = [];
  state.Academic.documents = [];
  state.Patents.documents = [];
  state.listScrollPosition = 0;
  state.dates = {
    startDate: undefined,
    endDate: undefined,
  };
}

const groupEntities = (entities?: DocumentEntity[]): EntitiesGroups => {
  if (!entities) return defaultEntititesGroups;
  const results: ResultsType = entities.reduce((prev, curr) => {
    const mergedType = curr.type as EntityType;
    if (!mergedType || mergedType === EntityType.KeyPhrase || mergedType === EntityType.None) return prev;
    const type = mergedType === EntityType.Settlement ? EntityType.Country : mergedType;
    if (!!type && !!prev[type]) {
      prev[type]?.push({ ...curr, type });
    } else {
      prev = { ...prev, [type]: [{ ...curr, type }] };
    }
    return prev;
  }, defaultEntititesGroups);

  // Return sorted arrays based on starting pos of Entities
  type K = keyof EntitiesGroups;
  return Object.keys(results).reduce((prev, key) => {
    return { ...prev, [key]: results[key as K]?.slice().sort((a, b) => (a.startingPos < b.startingPos ? -1 : 1)) };
  }, {});
};
