import AiButton from "@amplyfi/ui-components/components/Button";
import {
  Avatar,
  Box,
  createStyles,
  darken,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Block, Delete, MailOutline } from "@material-ui/icons";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { analyticsEvent } from "../../../helpers/analytics";
import { getOrganisation, getUserId } from "../../../helpers/user";
import {
  deleteUser,
  getUser,
  Group,
  removeUserFromGroups,
} from "../../../http/user-management";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import SimpleDialog from "../../Widgets/Dialog/SimpleDialog";
import { canUserAccountDelegatedAdmin } from "./permissions";
import AddUserModal from "./Users/AddUserModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      padding: theme.spacing(2),
    },
    header: {
      display: "flex",
      marginBottom: theme.spacing(2),
      alignItems: "center",
    },
    connectorList: {},
    connectorListItem: {
      display: "flex",
      height: 60,
      alignItems: "center",
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),

      "&:hover": {
        background: darken(theme.palette.componentBackground.main, 0.1),
        cursor: "pointer",
      },
    },
    connectorListIcon: {
      height: 36,
      width: 36,
      objectFit: "contain",
    },
    connectorListTitle: {
      marginLeft: theme.spacing(1),
      flex: "1 1 auto",
    },
    row: {},
    statusIcon: {
      marginLeft: theme.spacing(1),
    },
  })
);

function GroupList(props: {
  groups: Group[];
  isLoading: boolean;
  onDeleteClicked: (group: Group) => void;
}): JSX.Element {
  const { groups, isLoading, onDeleteClicked } = props;
  const { user } = useAuth0();
  const userIsDelegatedAdmin = canUserAccountDelegatedAdmin(user);

  if (groups.length === 0 && !isLoading) {
    return <Typography>This user isn't in any groups.</Typography>;
  }

  return (
    <List subheader={!isLoading ? (<ListSubheader>Groups</ListSubheader>) : (<ListSubheader></ListSubheader>)}>
      {isLoading ? (
        <>
          <ListItem>
            <Skeleton width={200} />
          </ListItem>
          <ListItem>
            <Skeleton width={400} />
          </ListItem>
        </>
      ) : (
        groups.map((group) => (
          <ListItem component={Link} to={`/manage/groups/${group.id}`} key={group.id}>
            <ListItemText primary={group.name} secondary={group.description} />
            {userIsDelegatedAdmin && (
              <ListItemIcon
                style={{ zIndex: 90 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteClicked(group);
                }}
              >
                <Delete style={{ zIndex: 90 }} />
              </ListItemIcon>
            )}
          </ListItem>
        ))
      )}
    </List>
  );
}

export default function UserPage(): JSX.Element {
  const { user } = useAuth0();
  const userIsDelegatedAdmin = canUserAccountDelegatedAdmin(user);
  const styles = useStyles();
  const organisation = getOrganisation(user);
  const location = useLocation();
  const history = useHistory();
  const paths = location.pathname.split("/");
  const userId = paths[paths.length - 1];
  const {
    data: fetchedUser,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useQuery(["user-profile", userId], () => getUser(organisation?.id as string, userId));
  const userGroups = fetchedUser?.groups || [];
  const [showEditDialog, setShowEdit] = useState(false);
  const [showConfirmDialog, setShowConfirm] = useState(false);
  const [isLoadingEdit, setLoadingEdit] = useState(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Box display="flex" alignSelf="flex-start" flex="1 1 auto">
          <IconButton edge="start" style={{ alignSelf: "flex-start" }} onClick={() => history.goBack()}>
            <ArrowBack />
          </IconButton>
          <Avatar style={{ marginLeft: 8 }} src={fetchedUser?.picture} />
          <div style={{ marginLeft: 8 }}>
            <Typography variant="h3">{fetchedUser?.name || <Skeleton height={20} width={100} />}</Typography>
            <Typography>{fetchedUser?.email || <Skeleton height={16} width={200} />}</Typography>
          </div>
          {!isLoadingUser && !fetchedUser?.email_verified && (
            <Tooltip title="Invite sent" placement="top">
              <IconButton size="small" className={styles.statusIcon}>
                <MailOutline />
              </IconButton>
            </Tooltip>
          )}
          {!isLoadingUser && fetchedUser?.blocked && (
            <Tooltip title="User is blocked" placement="top">
              <IconButton size="small" className={styles.statusIcon}>
                <Block />
              </IconButton>
            </Tooltip>
          )}
          <div style={{ marginLeft: "auto" }}>
            {!isLoadingUser && userIsDelegatedAdmin && (
              <AiButton size="small" amplyfiType="primary" onClick={() => setShowEdit(true)}>
                Edit
              </AiButton>
            )}
            {user && getUserId(user) !== userId && !isLoadingUser && userIsDelegatedAdmin && (
              <AiButton
                size="small"
                style={{ marginLeft: 8 }}
                amplyfiType="negative"
                onClick={() => setShowConfirm(true)}
              >
                Delete User
              </AiButton>
            )}
          </div>
        </Box>
      </div>
      <GroupList
        groups={userGroups}
        isLoading={isLoadingUser || isLoadingEdit}
        onDeleteClicked={(group) => {
          setSelectedGroup(group);
          setShowDeleteConfirmationDialog(true);
        }}
      />

      {showEditDialog && (
        <AddUserModal
          title="Edit User"
          userId={userId}
          onClose={async () => {
            setLoadingEdit(true);
            setShowEdit(false);

            await refetchUser();
            setLoadingEdit(false);
          }}
        />
      )}

      {showConfirmDialog && fetchedUser && organisation && (
        <SimpleDialog
          title={`Are you sure you want to delete "${fetchedUser.name}" (${fetchedUser.email})?`}
          content=""
          onClose={() => setShowConfirm(false)}
          onConfirm={async () => {
            await deleteUser(organisation?.id as string, userId);
            analyticsEvent("Account", "UserDeleted", [organisation.id, userId]);
            setShowConfirm(false);
            history.replace("/manage/users");
          }}
        />
      )}

      {showDeleteConfirmationDialog && fetchedUser && selectedGroup && organisation && (
        <SimpleDialog
          title={`Are you sure you want to remove this user from "${selectedGroup.name}"?`}
          content=""
          onClose={() => setShowDeleteConfirmationDialog(false)}
          onConfirm={async () => {
            setLoadingEdit(true);
            await removeUserFromGroups(organisation.id, userId, [selectedGroup.id]);
            analyticsEvent("Account", "UserRemovedFromGroup", [organisation.id, selectedGroup.id]);
            setShowDeleteConfirmationDialog(false);

            await refetchUser();
            setLoadingEdit(false);
          }}
        />
      )}
    </div>
  );
}
