import { Positive } from "@amplyfi/ui-components/theme/colors";
import { Button, darken, makeStyles, Typography } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { MouseEventHandler, useState } from "react";
import { useQuery } from "react-query";
import { getOrganisation, getOrgId } from "../../../../helpers/user";
import useDocumentLibraries from "../../../../hooks/useDocumentLibraries";
import { Connector, getConnectors, getHarvestUrl } from "../../../../http/connectors";
import { createHarvest } from "../../../../http/harvest";
import { LibraryItem } from "../../../../models/search/documentLibrary";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import SimpleDialog, { AbstractDialogProps } from "../../../Widgets/Dialog/SimpleDialog";
import { SelectLibrary } from "../../Harvest/CreateHarvest/SelectLibrary";
import { MAX_HARVEST_DOCUMENTS } from "../../Harvest/helpers/queryValidation";
import CreateLibraryDialog from "./CreateLibraryDialog";
import { Organisation } from "../../../../models/user";
import { LibraryEntity } from "../../../../http/harvesterCollections";

interface ExportToLibraryProps extends AbstractDialogProps {
  selectedConnectors: string[];
  documentCount: number;
  searchId: string;
}

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: "flex",
    flexDirection: "column",
    minWidth: 400,
    maxHeight: window.outerHeight - theme.spacing(2),
    minHeight: 160,
  },
  leftAlign: {
    marginLeft: theme.spacing(2),
    alignItems: "center",
    marginRight: "auto",
  },
  estimatedDocs: {
    display: "flex",
    alignItems: "center",
    "& > :last-child": {
      marginLeft: theme.spacing(1),
    },
  },
}));

function getUnharvestableConnectors(selectedConnectors: string[], connectors: Connector[]) {
  return connectors.filter(
    (connector) => selectedConnectors.includes(connector.connectorId) && !connector.canBeHarvested
  );
}

export default function ExportToLibraryDialog(props: ExportToLibraryProps): JSX.Element {
  const classes = useStyles();
  const { searchId, selectedConnectors, documentCount, onConfirm, ...rest } = props;
  const [library, setLibrary] = useState<LibraryItem | null>(null);
  const [entity] = useState<LibraryEntity | null>(null);
  const [creatingNewLibrary, toggleCreatingNewLibrary] = useState(false);
  const { data: connectors = [] } = useQuery("connectors", getConnectors);
  const { refetch } = useDocumentLibraries();
  const unharvestable = getUnharvestableConnectors(selectedConnectors, connectors);
  const { user } = useAuth0();

  const confirm: MouseEventHandler = async (e) => {
    e.persist();
    const { location, resultCount } = await getHarvestUrl(searchId);
    try {
      await createHarvest(
        getOrganisation(user) as Organisation,
        library as LibraryItem,
        entity as LibraryEntity,
        location,
        "urlsS3",
        resultCount,
        MAX_HARVEST_DOCUMENTS,
        true,
        false
      );
      onConfirm?.(e);
    } catch (e) {
      alert("Error adding documents to library. Please try again.");
    }
    return;
  };

  async function setLibraryFromId(libraryId: string) {
    const { data: results = [] } = await refetch();
    setLibrary(results.find((lib) => lib.id === libraryId) || null);
    toggleCreatingNewLibrary(false);
  }

  return (
    <>
      <SimpleDialog
        {...rest}
        title="Analyse Documents"
        primary="Analyse Documents"
        secondary={null}
        maxWidth="md"
        actions={
          <div className={classes.leftAlign}>
            <div className={classes.estimatedDocs}>
              <Typography variant="h4" color="textSecondary">
                Estimated Documents:
              </Typography>
              <Typography style={{ marginLeft: 8, fontWeight: "bold", fontSize: 12 }} variant="h4">
                {documentCount}
              </Typography>
              <CheckCircleOutline htmlColor={darken(Positive, 0.3)} />
            </div>
            {documentCount > 2500 && (
              <div>
                <Typography variant="body2" color="textSecondary">
                  We will only harvest the first 2,500 results
                </Typography>
              </div>
            )}
          </div>
        }
        onConfirm={library && unharvestable.length < selectedConnectors.length ? confirm : undefined}
        content={
          <div className={classes.modalContent}>
            <Typography style={{ marginBottom: 8 }} variant="body2">
              Results from the selected Source Group will be Analysed and added to a Library.
            </Typography>
            <Typography style={{ marginBottom: 8, fontWeight: "bold", fontSize: 10 }} variant="body2">
              This will add all the documents from the whole Source Group
            </Typography>
            <SelectLibrary organisationId={getOrgId(user)} libraryId={library?.id} setLibrary={setLibrary} />
            <Button
              onClick={() => toggleCreatingNewLibrary(true)}
              style={{ paddingLeft: 0, alignSelf: "flex-start", fontSize: 11 }}
              color="secondary"
              variant="text"
            >
              Create new Library
            </Button>
            {unharvestable.length > 0 && (
              <div style={{ marginTop: "auto", paddingTop: 16 }}>
                <Typography style={{ color: "gray" }} variant="body2">
                  The following sources cannot be harvested due to being internal data or non-English language:
                </Typography>
                <ul style={{ maxHeight: 400, overflow: "auto", paddingLeft: 32, marginLeft: 0 }}>
                  {unharvestable.map((u) => (
                    <li>
                      <Typography variant="body2">{u.name}</Typography>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        }
      />
      {creatingNewLibrary && (
        <CreateLibraryDialog onSave={setLibraryFromId} onClose={() => toggleCreatingNewLibrary(false)} />
      )}
    </>
  );
}
