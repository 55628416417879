import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warning: {
      display: "inline-flex",
      alignItems: "center",
    },
    warningTitle: {
      fontWeight: "bold",
    },
    warningInfo: {
      color: "#91979d",
      fontWeight: "bold",
    },
  })
);

export default function Warning(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.warning}>
      <Typography className={classes.warningTitle}>Queries are not transferable</Typography>
      <Typography className={classes.warningInfo}>&nbsp;-&nbsp;Save search before switching</Typography>
    </div>
  );
}
