import React, { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "../../hooks/useQuery";
import { convertSearchStateToSearchRequestV2 } from "../../helpers/search";
import { getDocument, getFirstDocument } from "../../http/document";
import { Entity } from "../../models/entity";
import { useAnalyseSelector } from "../../store/reducers";
import SingleDoc from "@amplyfi/ui-components/components/SingleDoc";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { drawerBreakPointStyling } from "../../css/mixins";
import useRelatedDocumentFilters from "../../hooks/useRelatedDocumentFilters";
import { useDispatch } from "react-redux";
import {
  defaultEntititesGroups,
  EntitiesGroups,
  EntityGroupType,
  setCurrentSearchStringPosition,
  setDocumentLoading,
  setFirstResultLoaded,
  setLoadingSearchMatches,
  setSelectedDocumentEntities,
  setSelectedDocumentError,
  setTotalSearchStringMatches,
} from "../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import Mark from "mark.js";
import clsx from "clsx";
import { DocumentEntity } from "../../models/document";
import DocumentHighlightEntitiesTooltip from "./DocumentHighlightEntitiesTooltip";
import { getDocumentColourRDV, highlightStyles } from "../../models/entityColourMap";
import parse from "html-react-parser";
import { PastelHighlightPurple } from "@amplyfi/ui-components/theme/colors";
import moment from "moment";
import DocumentSummary from "./Summary/DocumentSummary";
import { testId } from "@amplyfi/ui-components/testHelpers";
import useDocument from "../../hooks/useDocument";

interface DocumentProps {
  refreshSearch?: () => void;
  showListActions?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 845,
      width: "100%",
      minHeight: "100px",
      flexGrow: 1,
      ...drawerBreakPointStyling(theme),
    },
    doc: {
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
    },
    mark: {
      background: "none",
      ...highlightStyles(theme),
    },
    textMark: {
      background: "none",
    },
    markHighlight: {
      backgroundColor: PastelHighlightPurple,
      fontWeight: "bold",
    },
    Organisation: {
      borderBottomColor: getDocumentColourRDV(EntityGroupType.Organisation),
    },
    Country: {
      borderBottomColor: getDocumentColourRDV(EntityGroupType.Country),
    },
    Person: {
      borderBottomColor: getDocumentColourRDV(EntityGroupType.Person),
    },
    inlineElements: {
      display: "inline",
    },
  })
);

export const DOCUMENT_ID = "related-document-viewer__document";

export default function Document({ refreshSearch, showListActions }: DocumentProps): JSX.Element {
  const dispatch = useDispatch();
  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const documentFilters = useRelatedDocumentFilters();
  const bodyDivRef = useRef<HTMLDivElement>(null);
  const [markedElements, setMarkedElements] = useState<JSX.Element[]>();

  const {
    selectedDocumentId,
    documentFilters: { keywordFilters },
    All,
    News,
    Patents,
    Academic,
    selectedDocumentType,
    highlightedDocumentEntities,
    selectedDocumentEntites,
    searchString,
    Private,
    currentSearchStringPosition,
    dates: { endDate, startDate },
  } = useAnalyseSelector((x) => x.searchResponse.relatedDocumentViewer);
  const classes = useStyles();
  const currentDocuments = { All, News, Patents, Academic, Private };
  const searchRequest = convertSearchStateToSearchRequestV2(url);
  searchRequest.systemFilters.publishedDateSortOrder = currentDocuments[selectedDocumentType].sortFilter;
  searchRequest.systemFilters.documentsFilters = useRelatedDocumentFilters();
  const { data: doc } = useDocument(selectedDocumentId);

  const {
    data: { data, status } = {},
    isLoading,
    isError,
  } = useQuery(
    [
      "rdv-documents",
      url,
      documentFilters,
      selectedDocumentType,
      selectedDocumentId,
      Object.values(currentDocuments).flatMap((x) => x.sortFilter),
      startDate,
      endDate,
    ],
    async () => {
      if (selectedDocumentId) {
        const { any, all, keywords, libraries } = url;
        const topLevelQueries: Entity[] = [...any, ...all, ...keywords];
        dispatch(setFirstResultLoaded());
        return await getDocument(selectedDocumentId, [...keywordFilters, ...topLevelQueries], libraries);
      } else {
        searchRequest.userFilters.startDate = startDate
          ? moment.utc(startDate)?.toISOString()
          : searchRequest.userFilters.startDate;
        searchRequest.userFilters.endDate = endDate
          ? moment.utc(endDate)?.toISOString()
          : searchRequest.userFilters.endDate;
        const document = await getFirstDocument(searchRequest, url.libraries);
        dispatch(setFirstResultLoaded(document.data?.id));
        return document;
      }
    }
  );

  const getDocUrl = () => data?.url;

  const getErrorMessage = () => {
    if (status === 404) {
      return "No document to show";
    }
    if (isError) {
      return "There was an error fetching your document";
    }
    return undefined;
  };

  useEffect(() => {
    dispatch(setSelectedDocumentError(isError));
  }, [dispatch, isError]);

  useEffect(() => {
    dispatch(setDocumentLoading(isLoading));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (!!data && "entities" in data && !!data?.entities) {
      dispatch(setSelectedDocumentEntities(data.entities));
    } else {
      dispatch(setSelectedDocumentEntities(undefined));
    }
  }, [data, dispatch]);

  const markBody = useCallback(
    (context: string | HTMLElement | readonly HTMLElement[] | NodeList) => {
      const mark = new Mark(context);
      mark.unmark({ exclude: ["[data-testid='search-text-mark']"] });
      const filteredEntities = Object.keys(selectedDocumentEntites)
        .filter((key) => highlightedDocumentEntities.includes(key as EntityGroupType))
        .reduce((obj: EntitiesGroups, key) => {
          return { ...obj, [key as EntityGroupType]: selectedDocumentEntites[key as EntityGroupType] };
        }, defaultEntititesGroups);
      const arrayLength = Object.entries(filteredEntities).length;
      Object.entries(filteredEntities).forEach(([type, values = []], index) => {
        mark.markRanges(
          values.map((x) => {
            return {
              start: x.startingPos,
              length: x.endingPos - x.startingPos,
              ...x,
            };
          }),
          {
            className: clsx(classes[type as keyof typeof classes], classes.mark),
            each: (el: Element, range: Mark.Range & DocumentEntity) => {
              const { id, endingPos, name, type, startingPos } = range;
              el.setAttribute("data-testid", "reader-mark");
              el.setAttribute("id", id);
              el.setAttribute("name", name);
              if (type) {
                el.setAttribute("type", `${type}`);
              }
              const positionId = `${id}-${startingPos}-${endingPos}`;
              el.setAttribute("positionId", positionId);
            },
            done: (marksTotal: number) => {
              if (
                context instanceof HTMLElement &&
                !!context?.firstElementChild &&
                context.firstElementChild !== null &&
                markedElements === undefined &&
                index === arrayLength - 1 &&
                marksTotal > 0
              ) {
                const div = document.createElement("div");
                div.innerHTML = context.innerHTML.trim();
                if (div.firstElementChild) {
                  updateElements(div.firstElementChild as HTMLElement);
                }
              }
            },
          }
        );
      });
    },
    [classes, markedElements, highlightedDocumentEntities, selectedDocumentEntites]
  );

  const markSearchText = useCallback(
    (context: string | HTMLElement | readonly HTMLElement[] | NodeList) => {
      const mark = new Mark(context);
      let currentPos = 1;
      let tempText = "";
      mark.unmark({ exclude: ["[data-testid='reader-mark']"] });
      if (searchString === "") {
        dispatch(setLoadingSearchMatches(false));
        return;
      }
      mark.mark(searchString, {
        className: classes.textMark,
        each: (el: Element) => {
          el.setAttribute("data-testid", "search-text-mark");
          el.setAttribute("id", `${DOCUMENT_ID}__highlighted-text-${currentPos}`);
          if (
            (currentSearchStringPosition === undefined && currentPos === 1) ||
            currentSearchStringPosition === currentPos
          ) {
            el.classList.add(classes.markHighlight);
          }
          tempText += el.textContent;
          if (
            (el.textContent && el.textContent.toLowerCase() === searchString.toLowerCase()) ||
            tempText.toLowerCase() === searchString.toLowerCase()
          ) {
            tempText = "";
            currentPos++;
          }
        },
        separateWordSearch: false,
        acrossElements: true,
        diacritics: false,
        done: (totalCount) => {
          const selectedTotal = currentPos - 1 === 0 ? currentPos : currentPos - 1;
          dispatch(setTotalSearchStringMatches(totalCount === 0 ? totalCount : selectedTotal));
          if (currentSearchStringPosition === undefined) {
            dispatch(setCurrentSearchStringPosition(1));
          }
          dispatch(setLoadingSearchMatches(false));
        },
      });
    },
    [classes, currentSearchStringPosition, dispatch, searchString]
  );

  const dataBody = !!data && "body" in data ? data.body : undefined;

  const updateElements = (context: HTMLElement) => {
    const elements = parse(context.innerHTML);
    // unify the response type from parse, to JSX.Element[]
    if (Array.isArray(elements)) {
      setMarkedElements(elements);
    } else if (elements && typeof elements !== "string") {
      setMarkedElements([elements]);
    } else {
      setMarkedElements(undefined);
    }
  };

  const getBody = () =>
    document.querySelector(`[data-testid='${testId(DOCUMENT_ID, "entity-highlight")}']`) as HTMLElement;

  useEffect(() => {
    if (bodyDivRef && bodyDivRef.current) {
      const body = getBody();
      markBody(body);
    }
  }, [bodyDivRef, markBody]);

  useEffect(() => {
    if (bodyDivRef && bodyDivRef.current) {
      markSearchText(bodyDivRef.current);
    }
  }, [bodyDivRef, markSearchText]);

  useEffect(() => {
    setMarkedElements(undefined);
  }, [selectedDocumentId, data]);

  const getDocumentBody = () =>
    !!markedElements ? (
      <div style={{ position: "relative", whiteSpace: "pre-line" }}>
        {markedElements.map((item, index) => {
          const isDisabled = !(
            item.props?.type &&
            item.props.type !== undefined &&
            highlightedDocumentEntities.includes(item.props.type)
          );
          if (item.type === "mark") {
            return (
              <DocumentHighlightEntitiesTooltip
                item={item}
                key={index}
                disabled={isDisabled}
                showListActions={showListActions}
              />
            );
          } else {
            return item;
          }
        })}
      </div>
    ) : (
      <span>{dataBody}</span>
    );

  return (
    <div className={classes.container} ref={bodyDivRef}>
      <SingleDoc
        loading={isLoading}
        errorMessage={getErrorMessage()}
        {...data}
        url={getDocUrl()}
        className={classes.doc}
        startPage={undefined}
        endPage={undefined}
        data-testid={DOCUMENT_ID}
      >
        {dataBody ? (
          <>
            {doc && (
              <DocumentSummary
                documentId={selectedDocumentId}
                increaseContrast
                key={selectedDocumentId}
                refreshSearch={refreshSearch}
              />
            )}
            <div data-testid={testId(DOCUMENT_ID, "entity-highlight")}>{getDocumentBody()}</div>
          </>
        ) : undefined}
      </SingleDoc>
    </div>
  );
}
