import { useQuery, UseQueryResult } from "./useQuery";
import { Analysis } from "../models/analysis";
import { getAnalysis } from "../http/saved-analyses";

export default function useSavedAnalysis(id?: string): UseQueryResult<Analysis> {
  return useQuery(
    ["saved-analysis", id],
    () => {
      if (!id) {
        return undefined;
      }
      return getAnalysis(id);
    },
    { cacheTime: 0, refetchInterval: false }
  );
}
