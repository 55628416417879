import { Box, Button, Dialog, Slide, makeStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight, Close } from "@material-ui/icons";
import clsx from "clsx";
import { zIndex } from "../../../../../helpers/componentsZIndex";
import useAIHistory from "../../../../../hooks/useAIHistory";
import { useFeedViewStore } from "../../../../../store/hooks/useFeedViewStore";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import CardPanel from "./panels/CardPanel";
import CreateReportPanel from "./panels/CreateReportPanel";
import FeedInvestigationPanel from "./panels/FeedInvestigationPanel";
import ReportPanel from "./panels/ReportPanel";
import ResearchQuestionFeedPanel from "../../../Research/ResearchQuestionFeedPanel";
import { useLocation } from "react-router";

export enum ReaderPanel {
  Card = "card",
  FeedInvestigation = "feedInvestigation",
  CreateReport = "createReport",
  Report = "report",
  Custom = "custom"
}

const views = [
  {
    id: ReaderPanel.Card,
    component: CardPanel,
  },
  {
    id: ReaderPanel.FeedInvestigation,
    component: FeedInvestigationPanel,
  },
  {
    id: ReaderPanel.CreateReport,
    component: CreateReportPanel,
  },
  {
    id: ReaderPanel.Report,
    component: ReportPanel,
  },
  {
    id: ReaderPanel.Custom,
    component: () => null,
  },
];

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  readerContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    width: "100%", height: "100%",
    [breakpoints.down("sm")]: {
      width: "100%",
      flex: "1 1 auto",
    },
    backgroundColor: (props: { renderType: "default" | "overlay" }) => props.renderType === "overlay" ? "transparent" : undefined,
    zIndex: zIndex.reader,
  },
  reader: (props: { renderType: "default" | "overlay" }) => ({
    position: "relative",
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "40px auto",
    backgroundColor: "white",
    overflowY: "auto",
    flex: "1 1 auto",
    marginLeft: "auto",
    width: props.renderType === "overlay" ? 600 : "100%",
    [breakpoints.down("sm")]: {
      width: "100%",
      flex: "1 1 auto",
    },
  }),
  hideButton: {
    marginRight: "auto",
    marginTop: spacing(1),
    marginLeft: spacing(1),
    paddingBlock: spacing(2.5),
    paddingInline: spacing(2),
  },
  alternativeHide: {
    marginLeft: "auto",
    marginRight: spacing(2),
  },
  backButton: {
    position: "absolute",
    top: 0,
    marginTop: spacing(1),
    marginLeft: spacing(1),
    paddingBlock: spacing(1.5),
    paddingInline: spacing(2),
  },
}));

const alternativeHides = [ReaderPanel.CreateReport, ReaderPanel.Custom];

function OverlayWrapper(props: { children: JSX.Element, isOverlay: boolean }) {
  const reset = useReaderStore((state) => state.reset);
  return props.isOverlay ?
    <Dialog onClose={() => reset()} PaperProps={{ style: { background: "transparent" } }} fullScreen open>{props.children}</Dialog>
    : props.children;
}

export default function Reader() {

  const {
    selectedPanel,
    selectedDocumentId,
    selectedReportId,
    renderType,
    customPanel,
    isBackButtonVisible,
    setSelectedPanel,
    setCustomPanel,
    setIsCardSelected,
    setIsBackButtonVisible,
    setRenderType,
    setSelectedReportId,
  } = useReaderStore();
  const { view, libraryId: urlLibrary, amplyfiEntityId } = useFeedViewStore();
  const classes = useStyles({ renderType });
  const alternativeHide = selectedPanel && alternativeHides.includes(selectedPanel.id);
  const { pushFeed } = useAIHistory();
  const location = useLocation();


  function onClose() {
    if (selectedDocumentId) {
      pushFeed(urlLibrary, amplyfiEntityId, view.route)
    }
    if (selectedReportId) {
      setSelectedReportId(null);
    }
    setSelectedPanel(null);
    setIsCardSelected(false);
    setRenderType("default");
  }

  function onBack() {
    setSelectedPanel({
      id: ReaderPanel.Custom,
    })
    setRenderType("overlay");
    setCustomPanel(<ResearchQuestionFeedPanel />)
    setIsBackButtonVisible(false);
  }

  const showBackButton = isBackButtonVisible && location.pathname === "/generative-research";

  return (
    <OverlayWrapper isOverlay={renderType === "overlay"}>
      <Box className={classes.readerContainer} onClick={(e) => {
        if (e.currentTarget === e.target) {
          onClose();
        }
      }}>
        <Slide unmountOnExit direction="left" in={selectedPanel !== null}>
          <Box className={classes.reader}>
            <Button
              className={clsx(classes.hideButton, alternativeHide && classes.alternativeHide)}
              startIcon={!alternativeHide && <ChevronRight />}
              onClick={() => onClose()}
            >
              {alternativeHide ? <Close /> : "Hide"}
            </Button>
            {showBackButton && (
              <Button
                startIcon={<ChevronLeft />}
                className={clsx(classes.backButton)}
                onClick={() => onBack()}
              >
                Back
              </Button>
            )}
            {selectedPanel?.id === "custom" ? customPanel : views.find((v) => v.id === selectedPanel?.id)?.component()}
          </Box>
        </Slide>
        <Box id="footer-container"></Box>
      </Box>
    </OverlayWrapper>
  );
}
