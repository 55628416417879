import AiButton from "@amplyfi/ui-components/components/Button";
import IconButton from "@amplyfi/ui-components/components/IconButton";
import { LinkBlueElectric } from "@amplyfi/ui-components/theme/colors";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Add, Delete, Edit, ExpandLess, ExpandMore } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getWebsiteLogo } from "../../../../helpers/imageHelpers";
import {
  Connector,
  ConnectorGroup,
  ConnectorVisibility,
  createConnectorGroup,
  customLogoIds,
  deleteConnector,
  deleteConnectorGroup,
  editConnectorGroup,
  getConnectorGroups,
  getConnectors,
} from "../../../../http/connectors";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import SimpleDialog from "../../../Widgets/Dialog/SimpleDialog";
import { CONTAINER_WIDTH } from "../../useLandingStyles";
import ConnectorWizard from "../ConnectorWizard";
import { userConnectorCount } from "../helpers/connectors";
import { canUserDeleteOrganisationConnectors, canUserUpdateOrganisationConnectors } from "../permissions";
import CreateConnectorGroupModal from "./CreateConnectorGroupModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: `1 1 ${CONTAINER_WIDTH}px`,
      maxWidth: CONTAINER_WIDTH,
      marginLeft: "auto",
      marginRight: "auto",
      padding: theme.spacing(2),
    },
    header: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      borderBottom: "1px solid #E8E8E8",
      paddingBottom: theme.spacing(1),
    },
    connectorList: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(2),
    },
    groupListItem: {
      display: "flex",
      height: 48,
      alignItems: "center",
      paddingRight: theme.spacing(1),
      background: "white",
      boxShadow: "0px 0px 1px rgba(101, 108, 123, 0.25), 0px 4px 8px rgba(101, 108, 123, 0.15)",
      borderRadius: 4,
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
      flexWrap: "wrap",
    },
    connectorListIcon: {
      height: 36,
      width: 36,
      objectFit: "contain",
    },
    connectorListTitle: {
      marginLeft: theme.spacing(1),
      flex: "1 1 auto",
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(2),
    },
    cell: {
      display: "flex",
      alignItems: "center",
      width: "33%",
      flex: "0 0 33%",
    },
    body: {
      marginTop: theme.spacing(2),
    },
    connectorGroupExpandedContent: {
      display: "none",
      width: "100%",
      maxHeight: 500,
      overflow: "auto",
    },
    expanded: {
      height: "auto",
      display: "block",
    },
    introContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 48,
      width: "100%",
      paddingLeft: theme.spacing(2),
    },
    expandedContentHeader: {
      background: "#F7F8FA",
      height: 54,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(2),
    },
  })
);

interface ConnectorGroupItemProps extends ConnectorGroup {
  onEdit?: () => void;
  canEdit: boolean;
  canRemove: boolean;
  refresh: () => void;
}

function logo(props: { prototypeId: string; logoDomain: string }): string | undefined {
  const { prototypeId, logoDomain } = props;
  const logo = customLogoIds.includes(prototypeId)
    ? getWebsiteLogo(logoDomain)
    : `${process.env.REACT_APP_PROTOTYPE_LOGO_URL}/prototype/${prototypeId}/logo.png`;
  return logo;
}

function ConnectorGroupItem(props: ConnectorGroupItemProps) {
  const { user } = useAuth0();
  const styles = useStyles();
  const theme = useTheme();

  const { name, scope, canEdit, canRemove, connectors, groupId, refresh } = props;
  const [expanded, setExpanded] = useState(false);
  const [addSourceModal, setAddSourceModal] = useState(false);
  const [deleteGroupModal, setDeleteGroupModal] = useState(false);
  const [editConnectorModal, setEditConnectorModal] = useState(false);
  const [deleteConnectorModal, setDeleteConnectorModal] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState<Connector | null>(null);
  const [editGroupModal, setEditGroupModal] = useState(false);

  const maxConnectors = userConnectorCount(user);

  return (
    <div className={clsx(styles.groupListItem, expanded && styles.expanded)}>
      <div className={styles.introContent}>
        <span className={styles.cell}>
          <IconButton onClick={() => setExpanded(!expanded)} icon={expanded ? <ExpandLess /> : <ExpandMore />} />
          <Typography style={{ fontWeight: "bold" }} variant="body1">
            {name}
          </Typography>
        </span>
        <span className={styles.cell}>{scope === ConnectorVisibility.User ? "Me" : "My Organisation"}</span>
        <div className={styles.cell} style={{ display: "flex", justifyContent: "flex-end" }}>
          {(canEdit || scope === ConnectorVisibility.User) && (
            <IconButton onClick={() => setEditGroupModal(true)} icon={<Edit />} />
          )}
          {(canRemove || scope === ConnectorVisibility.User) && (
            <IconButton onClick={() => setDeleteGroupModal(true)} icon={<Delete />} />
          )}
        </div>
      </div>
      <div className={clsx(styles.connectorGroupExpandedContent, expanded && styles.expanded)}>
        <div className={styles.expandedContentHeader}>
          <Typography variant="body2">
            Sources ({connectors.length} / {maxConnectors})
          </Typography>
          {(canEdit || scope === ConnectorVisibility.User) && (
            <AiButton
              style={{ color: theme.palette.secondary.main }}
              disabled={connectors.length >= maxConnectors}
              onClick={() => setAddSourceModal(true)}
              color="primary"
              amplyfiType="tertiary"
            >
              Add source
            </AiButton>
          )}
        </div>
        <List>
          {connectors.map((connector) => (
            <ListItem key={connector.connectorId}>
              <ListItemAvatar>
                <Avatar src={logo({ prototypeId: connector.prototypeId, logoDomain: connector.logoDomain })} />
              </ListItemAvatar>
              <ListItemText>{connector.name}</ListItemText>
              <ListItemSecondaryAction>
                <div style={{ display: "flex" }}>
                  {(canEdit || scope === ConnectorVisibility.User) && (
                    <IconButton
                      onClick={() => {
                        setSelectedConnector(connector);
                        setEditConnectorModal(true);
                      }}
                      icon={<Edit />}
                    />
                  )}
                  {(canRemove || scope === ConnectorVisibility.User) && (
                    <IconButton
                      onClick={() => {
                        setSelectedConnector(connector);
                        setDeleteConnectorModal(true);
                      }}
                      icon={<Delete />}
                    />
                  )}
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      {editGroupModal && (
        <CreateConnectorGroupModal
          initialGroup={props}
          onConfirm={async (name: string, scope: ConnectorVisibility) => {
            setEditGroupModal(false);
            await editConnectorGroup(props.groupId, name, props.scope);
            refresh();
          }}
          onClose={() => {
            setEditGroupModal(false);
            refresh();
          }}
        />
      )}

      {addSourceModal && (
        <ConnectorWizard
          onClose={() => {
            setAddSourceModal(false);
            refresh();
          }}
          initialGroup={props}
        />
      )}
      {deleteGroupModal && (
        <SimpleDialog
          title={`Are you sure you want to delete ${name}?`}
          content="This group will be deleted immediately. You can't undo this action."
          onConfirm={async () => {
            setDeleteGroupModal(false);
            await deleteConnectorGroup(groupId);
            refresh();
          }}
          onClose={() => setDeleteGroupModal(false)}
        />
      )}

      {editConnectorModal && selectedConnector && (
        <ConnectorWizard
          initialConnector={selectedConnector}
          initialGroup={props}
          onClose={() => {
            setEditConnectorModal(false);
            refresh();
          }}
        />
      )}

      {deleteConnectorModal && selectedConnector && (
        <SimpleDialog
          title={`Are you sure you want to delete ${selectedConnector.name}?`}
          content="This source will be deleted immediately. You can't undo this action."
          onConfirm={async () => {
            setDeleteConnectorModal(false);
            await deleteConnector(selectedConnector.connectorId);
            refresh();
          }}
          onClose={() => setDeleteConnectorModal(false)}
        />
      )}
    </div>
  );
}

export default function ConnectorGroupsPage(): JSX.Element {
  const theme = useTheme();
  const styles = useStyles();
  const { user } = useAuth0();
  const { data: connectors, refetch: refetchConnectors } = useQuery("connectors", getConnectors);
  const { data, refetch } = useQuery("connector-groups", getConnectorGroups);
  const [addModal, toggleAddModal] = useState(false);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 70,
    },
    {
      field: "privacy",
      headerName: "Available to",
      width: 70,
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
    },
  ];

  if (!user) {
    return <></>;
  }

  const canEdit = canUserUpdateOrganisationConnectors(user);
  const canRemove = canUserDeleteOrganisationConnectors(user);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h2">Manage Source Groups</Typography>
        <AiButton startIcon={<Add />} style={{ marginLeft: "auto" }} onClick={() => toggleAddModal(true)}>
          Create source group
        </AiButton>
        <Link
          style={{
            color: LinkBlueElectric,
            textDecoration: "none",
            width: "100%",
            marginTop: theme.spacing(1),
            fontWeight: 500,
            fontSize: 13,
          }}
          to="/research"
        >
          Back to search
        </Link>
      </div>
      <div className={styles.connectorList}>
        <div>
          <div className={styles.row}>
            {columns.map(({ headerName }) => (
              <span key={headerName} className={styles.cell} style={{ fontWeight: "bold" }}>
                {headerName}
              </span>
            ))}
          </div>
        </div>
        <div className={styles.body}>
          {data?.map((group) => (
            <ConnectorGroupItem
              {...group}
              key={group.groupId}
              connectors={connectors?.filter((c) => c.connectorGroupId === group.groupId) || []}
              refresh={() => {
                refetch();
                refetchConnectors();
              }}
              canEdit={canEdit}
              canRemove={canRemove}
            />
          ))}
        </div>
        {addModal && (
          <CreateConnectorGroupModal
            onConfirm={async (name: string, scope: ConnectorVisibility) => {
              toggleAddModal(false);
              await createConnectorGroup(name, scope);
              refetch({ cancelRefetch: true, throwOnError: true });
            }}
            onClose={() => {
              toggleAddModal(false);
              refetch({ cancelRefetch: true, throwOnError: true });
            }}
          />
        )}
      </div>
    </div>
  );
}
