import ColoredSquare from "@amplyfi/ui-components/components/ColoredSquare";
import ModalFormContent from "@amplyfi/ui-components/components/ModalFormContent";
import Select from "@amplyfi/ui-components/components/Select";
import TextField from "@amplyfi/ui-components/components/TextField";
import { useFullscreenContainer } from "@amplyfi/ui-components/hooks/useFullscreenContainer";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { B1Base, White } from "@amplyfi/ui-components/theme/colors";
import { createStyles, MenuItem, Theme, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { link } from "../../../css/mixins";
import { zIndex } from "../../../helpers/componentsZIndex";
import useElement from "../../../hooks/useElement";
import { ActiveOption } from "../../../models/chart-customizations";
import { AxisType, CustomizationType } from "../../../models/customization/customization";
import { allElementType, ElementType } from "../../../models/element";
import { EntityType } from "../../../models/entity";
import { useAnalyseSelector } from "../../../store/reducers";
import { DEFAULT_INTERVAL } from "../../../store/reducers/searchRequest/url-functions";
import CustomizationAvailableOptions from "./CustomizationAvailableOptions";
import CustomizationAxisSelection from "./CustomizationAxisSelection";
import CustomizationSelectedOptions from "./CustomizationSelectedOptions";
import { entityTypeMapColor } from "./helpers/entityTypeMapColor";

interface CustomizationModalContentProps {
  types: EntityType[];
  onReset: () => ActiveOption[] | undefined;
  options: ActiveOption[];
  secondaryOptions?: ActiveOption[];
  onApply: (options: ActiveOption[], secondaryOptions: ActiveOption[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: B1Base,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      width: 644,
      height: 400,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      paddingBottom: 0,
      overflow: "auto",
      height: "100%",

      "& + &": {
        borderLeft: `1px solid ${theme.palette.borders.main}`,
      },
    },
    overflownOptions: {
      overflow: "auto",
      height: "100%",
      marginRight: theme.spacing(-2),
      paddingRight: theme.spacing(2),
    },
    typesSelect: {
      backgroundColor: White,

      "& .MuiSelect-select": {
        display: "flex",
        alignItems: "center",

        "&>:first-child": {
          display: "none",
        },
      },
    },
    popover: {
      width: 644,
    },
    group: {
      borderBottom: `1px solid ${theme.palette.borders.main}`,
      "& > *": {
        marginBottom: theme.spacing(1.5),
      },
    },
    clearBtn: {
      marginLeft: theme.spacing(1),
      background: "none",
      border: "none",
      "& h4": {
        ...link(theme),
      },
    },
    selectedHeader: {
      display: "flex",
      padding: theme.spacing(2.5, 0),
      borderBottom: `1px solid ${theme.palette.borders.main}`,
    },
    squarePlaceholder: {
      marginLeft: theme.spacing(2.25),
    },
  })
);

export default function CustomizationModalContent(props: CustomizationModalContentProps): JSX.Element {
  const { onReset, types, options, onApply, secondaryOptions } = props;
  const classes = useStyles();
  const selectTypes: CustomizationType[] = ["All", ...types];
  const [selectedType, onSetSelectedType] = useState<CustomizationType>(selectTypes[0]);
  const [selectedElementType, onSetSelectedElementType] = useState<ElementType[]>(allElementType);
  const [searchValue, setSearchValue] = useState("");
  const [axis, setAxis] = useState(AxisType.Primary);
  const [selectedOptions, setSelectedOptions] = useState(options);
  const [selectedSecondaryOptions, setSelectedSecondaryOptions] = useState(secondaryOptions || []);
  const [isReady, setIsReady] = useState(false);
  const { entitiesSearchLoading, keyPhrasesLoading } = useAnalyseSelector((state) => state.customization);
  const { interval = DEFAULT_INTERVAL } = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const container = useFullscreenContainer();
  const theme = useTheme();

  useEffect(() => {
    setSelectedOptions(options);
  }, [options]);

  useEffect(() => {
    setSelectedSecondaryOptions(secondaryOptions || []);
  }, [secondaryOptions]);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, theme.transitions.duration.enteringScreen);
  }, [theme.transitions.duration.enteringScreen]);

  useEffect(() => {
    if (selectedType === "All") {
      onSetSelectedElementType(allElementType);
    } else if (selectedType === EntityType.Country || selectedType === EntityType.Settlement) {
      onSetSelectedElementType(["country", "settlement"]);
    } else {
      onSetSelectedElementType([selectedType.toLocaleLowerCase() as ElementType]);
    }
  }, [searchValue, selectedType]);

  const { data = { elements: [] } } = useElement(interval, selectedElementType, searchValue);

  const keyPhrases = data.elements.filter((e) => e.type === EntityType.KeyPhrase);
  const people = data.elements.filter((e) => e.type === EntityType.Person);
  const organisations = data.elements.filter((e) => e.type === EntityType.Organisation);
  const location = data.elements.filter((e) => e.type === EntityType.Country || e.type === EntityType.Settlement);

  const dataType = {
    [EntityType.Organisation]: organisations,
    [EntityType.Person]: people,
    [EntityType.KeyPhrase]: keyPhrases,
    [EntityType.Country]: location,
    [EntityType.Settlement]: location,
    [EntityType.None]: [],
    All: [...keyPhrases, ...organisations, ...people, ...location],
  };

  const results = dataType[selectedType];

  return (
    <ModalFormContent
      handleReset={() => {
        const initial = onReset();
        if (initial) {
          const initialOptions = initial.filter((data) => !data.isSecondaryOption);
          if (initialOptions.length) {
            setSelectedOptions(initialOptions);
          }
          const initialSecondaryOptions = initial.filter((data) => data.isSecondaryOption);
          if (initialSecondaryOptions.length) {
            setSelectedSecondaryOptions(initialSecondaryOptions);
          }
        }
      }}
      handleSubmit={() => {
        onApply(selectedOptions, selectedSecondaryOptions);
      }}
      submitButtonText="Apply"
      title="Modify data"
      disableSubmit={!selectedOptions.length && !selectedSecondaryOptions.length}
      data-testid={testId("modify-data-content")}
    >
      <div className={classes.container}>
        <div className={classes.column}>
          <div className={classes.group}>
            <Select
              label="Element type: "
              labelInside
              options={selectTypes.map((type) => (
                <MenuItem value={type} key={type}>
                  {type === "All" ? (
                    <span className={classes.squarePlaceholder}></span>
                  ) : (
                    <ColoredSquare color={entityTypeMapColor[type]} />
                  )}
                  {type === EntityType.Country || type === EntityType.Settlement ? "Location" : type}
                </MenuItem>
              ))}
              value={selectedType}
              setValue={(val) => onSetSelectedType(val as EntityType)}
              className={classes.typesSelect}
              MenuProps={{
                style: { zIndex: zIndex.selectMenu },
                container,
              }}
            />
            <TextField
              isLoading={entitiesSearchLoading || keyPhrasesLoading}
              value={searchValue}
              onClear={() => setSearchValue("")}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className={classes.overflownOptions}>
            {isReady && (
              <CustomizationAvailableOptions
                axis={axis}
                options={results}
                selectedOptions={selectedOptions}
                selectedSecondaryOptions={selectedSecondaryOptions}
                setSelectedOptions={setSelectedOptions}
                setSelectedSecondaryOptions={setSelectedSecondaryOptions}
              />
            )}
          </div>
        </div>
        <div className={classes.column}>
          <CustomizationAxisSelection axis={axis} setAxis={setAxis} disabledSecondary={!secondaryOptions} />
          <div className={classes.selectedHeader}>
            <Typography>
              {axis === AxisType.Primary ? selectedOptions.length : selectedSecondaryOptions.length} elements selected
            </Typography>
            <button
              className={classes.clearBtn}
              onClick={() => (axis === AxisType.Primary ? setSelectedOptions([]) : setSelectedSecondaryOptions([]))}
            >
              <Typography variant="h4">Clear</Typography>
            </button>
          </div>
          <div className={classes.overflownOptions}>
            {isReady && (
              <CustomizationSelectedOptions
                selectedOptions={axis === AxisType.Primary ? selectedOptions : selectedSecondaryOptions}
                setSelectedOptions={axis === AxisType.Primary ? setSelectedOptions : setSelectedSecondaryOptions}
              />
            )}
          </div>
        </div>
      </div>
    </ModalFormContent>
  );
}
