import Button from "@amplyfi/ui-components/components/Button";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Box, CardContent, CardHeader, makeStyles, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import clsx from "clsx";
import React, { useState } from "react";

interface UserSectionCardProps {
  title: string;
  loading?: boolean;
  children: JSX.Element | JSX.Element[];
  confirmButtonDisabled?: boolean;
  confirmButtonText?: string;
  onConfirm?: () => void;
  className?: string;
}

interface UserSectionProps {
  className?: string;
  children: JSX.Element | JSX.Element[];
}

interface SimpleUserSectionProps {
  title: string;
  subtitle?: string;
  children: JSX.Element | JSX.Element[];
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  sectionCard: {
    position: "relative",
    minHeight: 100,
    marginBottom: spacing(2),
  },
  cardContent: {
    minHeight: 100,
    padding: 0,
  },
  footer: {
    padding: 0,
    "&.MuiCardContent-root:last-child": {
      padding: 0,
    },
  },
  sectionCardHeader: {
    borderBottom: "1px solid #E8E8E8",
  },
  simpleSection: {
    display: "flex",
    padding: spacing(2),
    alignItems: "center",
    "& > *": {
      flex: "0 0 50%",
    },
    [breakpoints.down("sm")]: {
      display: "block",
    },
  },
  buttonContainer: {
    padding: spacing(2),
    textAlign: "right",
    alignSelf: "flex-end",
    [breakpoints.down("sm")]: {
      textAlign: "left",
      alignSelf: "flex-start",
    },
  },
  loading: {
    marginLeft: spacing(1),
    flex: "0 0 auto",
  },
  loadingIndicator: {
    alignSelf: "center",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));

function UserSection(props: UserSectionProps) {
  return <div className={props.className}>{props.children}</div>;
}

export function SimpleUserSection(props: SimpleUserSectionProps) {
  const { title, subtitle, children } = props;
  const styles = useStyles();
  return (
    <UserSection className={styles.simpleSection}>
      <Box marginBottom={1}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </Box>
      <>{children}</>
    </UserSection>
  );
}

export function UserSectionCard(props: UserSectionCardProps) {
  const { children, title, confirmButtonText, confirmButtonDisabled, onConfirm, loading, className } = props;
  const styles = useStyles();
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function confirm() {
    setConfirmLoading(true);
    if (onConfirm) {
      await onConfirm();
    }
    setConfirmLoading(false);
  }

  return (
    <Card className={clsx(styles.sectionCard, className)}>
      {loading ? (
        <AiCircularProgress className={styles.loadingIndicator} />
      ) : (
        <>
          <CardHeader
            className={styles.sectionCardHeader}
            disableTypography
            title={<Typography variant="h4">{title}</Typography>}
          ></CardHeader>
          <CardContent className={styles.cardContent}>
            {Array.isArray(children)
              ? children.map((child, i) => <UserSection key={i}>{child}</UserSection>)
              : children}
          </CardContent>
          <CardContent className={styles.footer}>
            {onConfirm && (
              <div className={styles.buttonContainer}>
                <Button disabled={confirmButtonDisabled} amplyfiType="primary" onClick={confirm}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: 8 }}>{confirmButtonText || "Confirm"}</span>
                    {confirmLoading && (
                      <AiCircularProgress
                        className={styles.loading}
                        primaryColor="white"
                        secondaryColor="white"
                        size={14}
                        useContainer={false}
                      />
                    )}
                  </div>
                </Button>
              </div>
            )}
          </CardContent>
        </>
      )}
    </Card>
  );
}
