import AiButton from "@amplyfi/ui-components/components/Button";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import {
  Box,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useState } from "react";
import { useQuery } from "react-query";
import { analyticsEvent } from "../../../../helpers/analytics";
import { getOrganisation } from "../../../../helpers/user";
import {
  createUser,
  getNestedGroups,
  getUser,
  addUserToGroups,
  removeUserFromGroups,
} from "../../../../http/user-management";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import TextEntryField from "../../Feed/Configuration/Components/TextEntryField";
import { blueShades } from "../../../Charts/helpers/colourScaleHexes";
import { Done } from "@material-ui/icons";
import useValidation from "../../../../hooks/useValidation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      width: "100%",
      maxWidth: "100%",
      display: "block",
      marginBlock: theme.spacing(1),
    },
    textField: {
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      flex: "1 1 auto",
      "& .MuiFormControlLabel-label": {
        marginRight: "auto",
      },
    },
    groupList: {
      marginTop: theme.spacing(1),
      width: "100%",
      gap: theme.spacing(2),
    },
    dialog: {
      padding: theme.spacing(2),
      "& .MuiDialog-paper": {
        width: "100%",
        height: "100%",
        maxHeight: 500,
        flexwrap: "wrap",
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiDialog-paper": {
          minWidth: "100%",
          maxHeight: 550,
          padding: 0,
        },
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface AddUserProps {
  title: string;
  userId?: string;
  onClose: () => void;
}

function getGroupChanges(initialGroupIds: string[], selectedGroupIds: string[]) {
  return {
    toAdd: selectedGroupIds.filter((group) => !initialGroupIds.includes(group)),
    toRemove: initialGroupIds.filter((group) => !selectedGroupIds.includes(group)),
  };
}

export default function AddUserModal(props: AddUserProps): JSX.Element {
  const { title, onClose, userId } = props;
  const { user } = useAuth0();
  const organisation = getOrganisation(user);

  const { data: fetchedUser, isLoading: isLoadingUser } = useQuery(["user-profile", userId], () =>
    getUser(organisation?.id as string, userId)
  );

  const { data: allGroups = [], isLoading: groupsLoading } = useQuery(
    ["all-groups"],
    () => (organisation ? getNestedGroups(organisation.id) : undefined),
    { cacheTime: 0 }
  );
  const userGroups = fetchedUser?.groups || [];
  const classes = useStyles();

  const [name, setName] = useState(fetchedUser?.name || "");
  const [email, setEmail] = useState(fetchedUser?.email || "");
  const [selectedGroups, setSelectedGroups] = useState<string[]>(userGroups.map((group) => group.id));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSaving, setIsSaving] = useState(false);
  const [emailError, setEmailError] = useState("");
  const { valid, validationMessages } = useValidation(
    ["email"],
    [],
    [email]
  );

  async function onSave() {
    setIsSaving(true);
    if (organisation) {
      if (!userId) {
        const { user_id } = await createUser(organisation.id, name, email);

        const initialGroups =
          allGroups?.filter((group) => selectedGroups.includes(group.id)).map((group) => group.id) || [];
        if (initialGroups.length) {
          await addUserToGroups(organisation.id, user_id, initialGroups);
        }

        analyticsEvent("Account", "UserCreated", [organisation.id, user_id]);
      } else {
        const groupChanges = getGroupChanges(
          userGroups.map((g) => g.id),
          selectedGroups
        );
        if (groupChanges.toRemove.length > 0) {
          await removeUserFromGroups(organisation.id, userId, groupChanges.toRemove);
        }
        if (groupChanges.toAdd.length > 0) {
          analyticsEvent("Account", "UserAddedToGroups", [userId, ...groupChanges.toAdd]);
          await addUserToGroups(organisation.id, userId, groupChanges.toAdd);
        }
      }
    }
    setIsSaving(true);
    onClose();
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    const email = e.target.value;
    setEmail(email);
    setEmailError(valid ? "" : validationMessages[0].message);
  }

  let buttonText;
  if (isSaving) {
    buttonText = (
      <>
        <Typography variant="h4" style={{ marginRight: 4, color: "white" }}>Create</Typography>
        <AiCircularProgress size={16} />
      </>
    );
  } else if (userId) {
    buttonText = "Save";
  } else {
    buttonText = "Create";
  }

  return (
    <Dialog fullScreen={isSmallScreen} open onClose={props.onClose} className={classes.dialog}>
      <DialogTitle disableTypography>
        <Typography variant="h2">{title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {isLoadingUser || groupsLoading ? (
          <Box>
            <AiCircularProgress />
          </Box>
        ) : (
          <>
            <Box className={classes.fieldWrapper}>
              <Typography variant="h4" style={{ marginRight: 4, color: "white" }}>Create</Typography>
              <FormControlLabel
                className={classes.textField}
                control={
                  <TextEntryField
                    disabled={userId !== undefined}
                    fullWidth
                    defaultValue={fetchedUser?.name || ""}
                    style={{ display: "block" }}
                    placeholder="Enter name"
                    onChange={(e) => setName(e.target.value)}
                  />
                }
                labelPlacement="top"
                label="Name"
              />
            </Box>
            <Box className={classes.fieldWrapper}>
              <FormControlLabel
                className={classes.textField}
                control={
                  <TextEntryField
                    error={!!emailError}
                    helperText={emailError}
                    disabled={userId !== undefined}
                    fullWidth
                    defaultValue={fetchedUser?.email || ""}
                    style={{ display: "block" }}
                    placeholder="Enter email"
                    onChange={handleEmailChange}
                  />
                }
                labelPlacement="top"
                label="Email"
              />
            </Box>
            <Divider style={{ marginBlock: 8 }} />
            <Box className={classes.groupList}>
              <Typography variant="h4">Groups</Typography>
              <Box>
                {allGroups.map((group) => (
                  <Chip
                    key={group.id}
                    label={group.name}
                    clickable={!isSaving}
                    disabled={isSaving}
                    icon={selectedGroups.includes(group.id) ? <Done style={{ color: "white", fontSize: 18, paddingLeft: theme.spacing(0.5) }} /> : undefined}
                    style={{
                      backgroundColor: selectedGroups.includes(group.id) ? blueShades[3] : blueShades[1],
                      color: "white",
                      margin: theme.spacing(0.5)
                    }} onClick={() => {
                      setSelectedGroups(prevSelectedGroups =>
                        prevSelectedGroups.includes(group.id)
                          ? prevSelectedGroups.filter(id => id !== group.id)
                          : [...prevSelectedGroups, group.id]
                      );
                    }}
                  />
                ))}
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions style={{ paddingRight: 24, paddingBottom: 16 }}>
        <AiButton style={{ marginLeft: "auto" }} amplyfiType="secondary" onClick={onClose}>
          Cancel
        </AiButton>
        <AiButton
          disabled={name.length === 0 || email.length === 0 || isSaving || !!emailError}
          onClick={async () => {
            onSave();
          }}
        >
          {buttonText}
        </AiButton>
      </DialogActions>
    </Dialog>
  );
}
