import { createStyles, Container, makeStyles } from "@material-ui/core";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { SearchEntitiesCard } from "./Cards/SearchEntitiesCard";
import { CONTAINER_WIDTH } from "../useLandingStyles";
import { goToEntityKnowledgeBase, RouteParam } from "./Routing";
import { testId } from "@amplyfi/ui-components/testHelpers";
import Modal from "../../Widgets/Misc/Modal";
import { GetEntity } from "./components/GetEntity";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: CONTAINER_WIDTH,
      margin: "0 auto",
    },
  })
);

export default function EntitiesPage({
  match: { params },
}: RouteComponentProps<Record<keyof typeof RouteParam, string | undefined>>): JSX.Element {
  const classes = useStyles();
  const { push } = useHistory();
  const amplyfiEntityId = params[RouteParam.amplyfiEntityId];
  const handleClose = () => push(goToEntityKnowledgeBase());

  return (
    <Container maxWidth="xl">
      <div className={classes.container}>
        <SearchEntitiesCard amplyfiEntityId={amplyfiEntityId} />
      </div>
      <Modal
        showCloseIcon
        data-testid={testId("entity-knowledge-base", "edit-entity")}
        onClose={handleClose}
        open={!!amplyfiEntityId}
      >
        <GetEntity id={amplyfiEntityId as string} onClose={handleClose} />
      </Modal>
    </Container>
  );
}
