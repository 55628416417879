import { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult, useQueries, useQuery } from "react-query";

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;

// https://blog.johnnyreilly.com/2021/01/03/strongly-typing-react-query-s-usequeries/
const useWrappedQueries = <TQueries extends readonly UseQueryOptions[]>(
  queries: [...TQueries]
): {
  [ArrayElement in keyof TQueries]: UseQueryResult<
    TQueries[ArrayElement] extends { select: infer TSelect }
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        TSelect extends (data: any) => any
        ? ReturnType<TSelect>
        : never
      : Awaited<ReturnType<NonNullable<Extract<TQueries[ArrayElement], UseQueryOptions>["queryFn"]>>>
  >;
} => {
  const q: UseQueryOptions[] = queries.map(({ queryKey = [], queryFn }) => ({
    queryFn,
    queryKey,
  }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQueries(q) as any;
};

function useWrappedQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData, QueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, QueryKey>
): UseQueryResult<TData, TError> {
  const key: QueryKey = queryKey;
  return useQuery<TQueryFnData, TError, TData, QueryKey>(key, queryFn, options);
}

export { useWrappedQueries as useQueries, useWrappedQuery as useQuery };

export type { UseQueryResult };
