import { QueryKey, UseMutationOptions, useQueryClient, useMutation as useRQMutation } from "react-query";

export default function useMutation<TVariables, TData = unknown>(
  mutationFn: (args: TVariables) => Promise<TData>,
  invalidateQueries: QueryKey[],
  options?: UseMutationOptions<TData, unknown, TVariables, unknown>
) {
  const queryClient = useQueryClient();
  return useRQMutation<TData, unknown, TVariables, unknown>(mutationFn, {
    ...options,
    onSettled: async (...settledProps) => {
      await Promise.all(invalidateQueries.map((q) => queryClient.invalidateQueries(q)));
      options?.onSettled?.(...settledProps);
    },
  });
}
