import { createStyles, makeStyles, FormControl, TextField, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

interface EnterTextProps {
  disabled?: boolean;
  isError?: boolean;
  label: string;
  multiline?: boolean;
  placeholder?: string;
  setValue: (value: string) => void;
  value: string;
}

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    error: {
      "& .MuiInputBase-root": {
        borderColor: palette.error.main,
      },
    },
    item: {
      alignItems: "baseline",
      display: "flex",
    },
    input: {
      width: "100%",
    },
    multiline: {
      "& .MuiInputBase-root": {
        height: "auto",
        padding: spacing(1),
      },
    },
    control: {
      marginBottom: spacing(1),
      marginTop: spacing(1),
    },
    label: {
      paddingBottom: spacing(1),
    },
  })
);

export const EnterText = ({
  disabled = false,
  isError = false,
  label,
  multiline,
  placeholder,
  setValue,
  value,
}: EnterTextProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.control}>
      <Typography variant="h4" className={classes.label}>
        {label}
      </Typography>
      <TextField
        className={clsx(classes.input, multiline && classes.multiline, isError && classes.error)}
        disabled={disabled}
        multiline={multiline}
        rows={2}
        rowsMax={5}
        onChange={({ target: { value } }) => setValue(value)}
        placeholder={placeholder}
        value={value}
      />
    </FormControl>
  );
};
