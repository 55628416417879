import { Card, CardContent } from "@material-ui/core";
import { CheckCircle, CloseOutlined } from "@material-ui/icons";
import React from "react";
import { ValidationResult } from "../../../hooks/useValidation";

interface ValidationDisplayProps {
  options: ValidationResult[];
}

function ValidationOption(props: ValidationResult) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.valid ? <CheckCircle htmlColor="green" /> : <CloseOutlined htmlColor="red" />}
      {props.message}
    </div>
  );
}

export default function ValidationDisplay(props: ValidationDisplayProps) {
  return (
    <Card>
      <CardContent>
        {props.options.map((option) => (
          <ValidationOption {...option} />
        ))}
      </CardContent>
    </Card>
  );
}
