import Drawer from "@amplyfi/ui-components/components/Drawer";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { Silver } from "@amplyfi/ui-components/theme/colors";
import { Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { drawerBreakPointStyling } from "../../css/mixins";
import { zIndex } from "../../helpers/componentsZIndex";
import { useAnalyseSelector } from "../../store/reducers";
import { onSetOpenCloseDrawer } from "../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import Document from "./Document";
import DocumentEntities from "./DocumentEntities";
import DocumentFeedback from "./DocumentFeedback";
import DocumentIndexHeader from "./DocumentIndexHeader";
import DocumentIndexList from "./DocumentIndexList";
import DocumentTextSearch from "./DocumentTextSearch";
import { RelatedDocumentViewerProps } from "./RelatedDocumentViewer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      height: "100%",
      position: "relative",
      overflow: "hidden",
    },
    indexContainer: {
      display: "flex",
      flexDirection: "column",
      width: 340,
    },
    documentViewerContainer: {
      minWidth: 340,
      backgroundColor: theme.palette.appBackground.main,
      padding: theme.spacing(5),
      width: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    documentHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(1),
      },
    },
    textSearchContainer: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    closeIcon: {
      cursor: "pointer",
      color: Silver,
      fontSize: 22,
      marginLeft: "auto",
    },
    drawer: {
      "& .MuiDrawer-paper": {
        ...drawerBreakPointStyling(theme),
      },
    },
  })
);

export default function RelatedDocumentViewer(props: RelatedDocumentViewerProps): JSX.Element {
  const { onClose } = props;
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState<number>(Date.now());
  const { openRDV, showList } = useAnalyseSelector((x) => x.searchResponse.relatedDocumentViewer);
  const dispatch = useDispatch();
  const handleOpen = () => dispatch(onSetOpenCloseDrawer(true));
  const dataTestId = "related-document-viewer";

  return (
    <Drawer
      label="Related Documents"
      anchor="right"
      className={classes.drawer}
      style={{ zIndex: zIndex.modalAndMenu }}
      open={openRDV}
      onClose={onClose}
      onOpen={handleOpen}
      data-testid={testId(dataTestId)}
    >
      <div className={classes.container}>
        {showList && (
          <div className={classes.indexContainer}>
            <DocumentIndexHeader />
            <DocumentIndexList />
          </div>
        )}
        <div className={classes.documentViewerContainer}>
          <div className={classes.documentHeader}>
            <div className={classes.textSearchContainer}>
              <DocumentTextSearch reloadOnChange={searchKey} />
            </div>
            <DocumentEntities />
            <DocumentFeedback />
            <CloseIcon className={classes.closeIcon} onClick={onClose} data-testid={testId(dataTestId, "close")} />
          </div>
          <Document showListActions={showList} refreshSearch={() => setSearchKey(Date.now())} />
        </div>
      </div>
    </Drawer>
  );
}
