import { useFlags } from "launchdarkly-react-client-sdk";
import { AnalyseRoute, reports as allReports } from "../components/Navigation/analyseRoutes";

export default function useReports(pathname?: string): { reports: AnalyseRoute[]; selectedReport?: AnalyseRoute } {
  let selectedReport: undefined | AnalyseRoute = undefined;
  const flags = useFlags();
  const reports = allReports.filter(({ featureFlag }) => (featureFlag ? flags[featureFlag] : true));

  if (pathname) {
    const [lastPath] = pathname.split("/").reverse();
    selectedReport = lastPath ? reports.find((x) => x.to.includes(lastPath)) : undefined;
  }

  return {
    reports,
    selectedReport,
  };
}
