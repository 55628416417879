import { Box, Button, Card, CardContent, List, Typography } from "@material-ui/core";
import { useState } from "react";
import { BasicEmptyState } from "../../../Configuration/Components/DynamicComponent";

interface OverflowListProps {
  emptyText?: string;
  items: string[];
  fontStyle?: string;
}

interface OverflowItemProps {
  text: string;
  fontStyle?: string;
}

function OverflowItem(props: OverflowItemProps) {
  return (
    <Card elevation={0} style={{ background: "#F7FAFC", borderRadius: 6, marginBottom: 8 }}>
      <CardContent>
        <Box display="flex">
          <Typography style={{ fontSize: 15, fontStyle: props.fontStyle }} variant="body1">
            {props.text}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default function OverflowList(props: OverflowListProps) {
  const [expanded, toggleExpanded] = useState(false);
  return (
    <List>
      {props.items.length === 0 && <BasicEmptyState title={props.emptyText || "No items found"} />}
      {props.items
        .filter((_, index) => (!expanded ? index <= 3 : true))
        .map((item) => (
          <OverflowItem text={item} fontStyle={props.fontStyle} />
        ))}
      {props.items.length > 4 && (
        <Button color="secondary" onClick={() => toggleExpanded(!expanded)}>
          {expanded ? "Hide" : `Show more (${props.items.length})`}
        </Button>
      )}
    </List>
  );
}
