import { Moment } from "moment";
import { useQuery, UseQueryResult } from "./useQuery";
import { TimeIntervalType } from "../helpers/timeIntervals";
import { dateGranularity } from "../helpers/dateHelpers";
import { convertSearchStateToSearchRequestV2 } from "../helpers/search";
import { getTimeline } from "../http/timeline";
import { ParsedQueryParams } from "../models/queryParams";
import { SearchResponse } from "../models/search";
import { DEFAULT_END, DEFAULT_INTERVAL, DEFAULT_START } from "../store/reducers/searchRequest/url-functions";

export interface DocumentTimelineOptions {
  endDate?: Moment;
  interval?: TimeIntervalType;
  startDate?: Moment;
  includeUndatedDocuments?: boolean;
}

export default function useLandingTimeline(
  id: string,
  url: ParsedQueryParams,
  hasAnyQueries: boolean,
  opts?: DocumentTimelineOptions
): UseQueryResult<SearchResponse | undefined, unknown> {
  const {
    interval = DEFAULT_INTERVAL,
    startDate = undefined,
    endDate = DEFAULT_END.clone(),
    includeUndatedDocuments = true,
  } = opts ??
  ({
    interval: DEFAULT_INTERVAL,
    startDate: DEFAULT_START.clone(),
    endDate: DEFAULT_END.clone(),
    includeUndatedDocuments: true,
  } as DocumentTimelineOptions);
  const options = {
    interval,
    endDate: endDate,
    startDate: dateGranularity(startDate),
  };
  const response = useQuery(["documents-timeline", id, url, options], async () => {
    if (url.libraries.length === 0) {
      return;
    }
    const request = convertSearchStateToSearchRequestV2(url);

    request.systemFilters.dateInterval = interval;
    request.userFilters.startDate = startDate?.toISOString();
    request.userFilters.endDate = endDate.toISOString();
    request.userFilters.includeUndatedDocuments = includeUndatedDocuments;
    request.userFilters.libraryIds = url.libraryIds;

    return getTimeline(request, url.libraries);
  });
  return response;
}
