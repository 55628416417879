import AiButton from "@amplyfi/ui-components/components/Button";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { createStyles, makeStyles, Theme, FormGroup } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { AmplyfiAllEditableFields, AmplyfiEditableFields, AmplyfiUnknownEntity } from "../../models";
import { sanitiseFields, fieldHandler, sortFields } from "../../helpers/fieldHandlers";
import { deleteAmplyfiEntity, ValidationError } from "../../../../../http/amplyfiEntities";
import { isEntityDeleted, isEntityUsed, isEntityVerified, saveEntity } from "../../helpers/entityHelpers";
import { useDispatch } from "react-redux";
import { setSnack } from "../../../../../store/reducers/ui/snackReducer";
import { EntityHeader } from "./EntityHeader";
import { ConfirmDelete } from "./ConfirmDelete";

interface EntityProps<T> {
  entity: T;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    button: {
      minWidth: 120,
      whiteSpace: "nowrap",
      padding: `${spacing(1.25)}px ${spacing(2.45)}px `,
      "&.MuiButton-root.Mui-disabled": {
        color: "inherit",
      },
    },
    buttonPrimary: {
      "&.MuiButton-root.Mui-disabled": {
        color: "#fff",
      },
    },
    field: {
      paddingTop: spacing(2),
    },
    form: {
      display: "flex",
    },
    modalActions: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: spacing(1),
    },
  })
);

enum Busy {
  Deleting = "Deleting",
  Saving = "Saving",
}

export function Entity<T extends AmplyfiUnknownEntity>({ entity }: EntityProps<T>): JSX.Element {
  const {
    deleted,
    id,
    name,
    type,
    usedInLibraries,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    createdByOrganisation,
    ...editableFields
  } = entity;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState<Busy | undefined>();
  const [validation, setValidation] = useState<ValidationError[]>([]);
  const [idToConfirmDelete, setIdToConfirmDelete] = useState<AmplyfiUnknownEntity["id"]>();
  const [isVerified, setIsVerified] = useState(isEntityVerified(entity));
  const [isDeleted, setIsDeleted] = useState(isEntityDeleted(entity));
  const [errors, setErrors] = useState<ValidationError[]>([]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [fields, setFields] = useState<AmplyfiEditableFields<T>>(sanitiseFields<T>(editableFields));

  const sortedFields = Object.keys(fields).sort(sortFields);

  const handleSave = async (newEntity: T) => {
    setIsBusy(Busy.Saving);
    const errors = await saveEntity<T>(newEntity);
    setErrors(errors);
    if (!errors.length) {
      setIsVerified(!!newEntity.verified);
      dispatch(setSnack({ body: `Successfully updated ${newEntity.name}`, title: "Success" }));
    }
    setIsBusy(undefined);
  };

  const handleDelete = async (id: T["id"]) => {
    setIsBusy(Busy.Deleting);
    const success = await deleteAmplyfiEntity(id);
    if (success) {
      setErrors([]);
      setValidation([]);
      setIsDeleted(true);
    }
    setIsBusy(undefined);
  };

  return (
    <>
      <ConfirmDelete
        data-testid={testId("entity-knowledge-base", "edit-entity")}
        id={idToConfirmDelete}
        name={name}
        onClose={() => setIdToConfirmDelete(undefined)}
        onConfirm={handleDelete}
      />
      <article>
        <EntityHeader
          data-testid={testId("entity-knowledge-base", "edit-entity")}
          entity={entity}
          isDeleted={isDeleted}
          isVerified={isVerified}
        />
        <FormGroup className={classes.form}>
          {sortedFields.map((f) => {
            const handler = fieldHandler<T>(f as keyof AmplyfiEditableFields<T>);
            return handler ? (
              <div className={classes.field} key={f}>
                {handler(
                  fields[f as keyof AmplyfiEditableFields<T>] as never,
                  f as keyof AmplyfiAllEditableFields,
                  (newVal, invalidMessage) => {
                    setFields((fieldValues) => ({ ...fieldValues, [f]: newVal }));
                    setValidation((validationValues) => [
                      ...validationValues.filter(({ field }) => field !== f),
                      ...(invalidMessage !== undefined ? [{ field: f, error: invalidMessage }] : []),
                    ]);
                  },
                  {
                    disabled: isDeleted,
                    error: errors?.find(({ field }) => field === f)?.error,
                    validation: validation?.find(({ field }) => field === f)?.error,
                  }
                )}
              </div>
            ) : null;
          })}
        </FormGroup>
      </article>
      <div className={classes.modalActions}>
        {!isDeleted && [
          ...(!isEntityUsed(entity)
            ? [
                <AiButton
                  data-testid={testId("entity-knowledge-base", "edit-entity", "delete-action")}
                  className={clsx(classes.button, classes.buttonPrimary)}
                  onClick={() => setIdToConfirmDelete(entity.id)}
                  amplyfiType="negative"
                  type="button"
                  disabled={isBusy !== undefined}
                  key="delete-action"
                >
                  Delete {isBusy === Busy.Deleting && <AiCircularProgress size={20} />}
                </AiButton>,
              ]
            : []),
          <AiButton
            data-testid={testId("entity-knowledge-base", "edit", "save-action")}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={() => handleSave({ ...entity, ...fields })}
            type="button"
            disabled={isBusy !== undefined || !!validation.length}
            key="save-action"
          >
            Save {isBusy === Busy.Saving && <AiCircularProgress size={20} />}
          </AiButton>,
        ]}
      </div>
    </>
  );
}
