import axios from "axios";
import { EntitiesSearchResponse } from "../models/filter";
import { SearchRequestV2 } from "../models/search";
import { getAnalysisV2Url } from "./general";
import { LibrariesType } from "../models/queryParams";

export async function searchEntitiesV2(
  searchRequest: SearchRequestV2,
  searchTerm: string,
  docTypes: LibrariesType[] | null = null
): Promise<EntitiesSearchResponse> {
  const searchResponse = await axios.post<EntitiesSearchResponse>(
    getAnalysisV2Url(`search/entities/${encodeURIComponent(searchTerm)}`, docTypes),
    searchRequest
  );
  return searchResponse.data;
}
