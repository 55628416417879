import { Box } from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import Analysis from "./overviewSections/Analysis";
import Highlights from "./overviewSections/Highlights";
import InvestigationSuggestions, { InvestigationSuggestionsProps } from "./overviewSections/InvestigationSuggestions";
import Quotes from "./overviewSections/Quotes";
import Signals from "./overviewSections/Signals";
import Stats from "./overviewSections/Stats";
import Summary from "./overviewSections/Summary";

interface Section {
  component: (() => JSX.Element) | ((props: InvestigationSuggestionsProps) => JSX.Element);
  featureFlag?: string;
  id: string;
}

const signalSections: Section[] = [
  {
    id: "overview-summary",
    component: Summary,
  },
  {
    id: "overview-investigate-signals",
    component: InvestigationSuggestions,
  },
  {
    id: "overview-highlights",
    component: Highlights,
  },
  {
    id: "overview-quotes",
    component: Quotes,
  },
  {
    id: "overview-stats",
    component: Stats,
  },
];

const insightSections: Section[] = [
  {
    id: "overview-summary",
    component: Analysis,
  },
  {
    id: "overview-investigate-insights",
    component: InvestigationSuggestions,
  },
  {
    id: "overview-signals",
    component: Signals,
  },
];

interface OverviewProps {
  amplyfiEntityId?: string;
  libraryId: string;
}

export default function Overview({ libraryId }: OverviewProps) {
  const { selectedDocumentId } = useReaderStore((state) => state);
  const flags = useFlags();

  return (selectedDocumentId?.startsWith("AI-") ? insightSections : signalSections)
    .filter(({ featureFlag }) => (featureFlag ? flags[featureFlag] : true))
    .map(({ id, component }) => <Box key={id}>{component({ libraryId })}</Box>);
}
