import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import useRelatedDocumentList from "../../hooks/useRelatedDocumentList";
import { useAnalyseSelector } from "../../store/reducers";
import {
  setListScrollPosition,
  updateNumberOfDocs,
} from "../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import DocumentListItem from "./DocumentListItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflow: "auto",
      height: "100%",
    },
    spinner: {
      padding: theme.spacing(3),
      height: "fit-content",
    },
    errorText: {
      color: theme.palette.appBackground.light,
      fontWeight: "bold",
    },
    centerContainer: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
    },
  })
);

export default function DocumentIndexList(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const scrollComponent = useRef<HTMLDivElement>(null);
  const { selectedDocumentType, numberOfDocumentsShown, listScrollPosition, All, News, Patents, Academic, Private } =
    useAnalyseSelector((x) => x.searchResponse.relatedDocumentViewer);
  const currentDocuments = useMemo(
    () => ({
      All,
      News,
      Patents,
      Academic,
      Private,
    }),
    [Academic, All, News, Patents, Private]
  );

  const {
    data: { [selectedDocumentType]: data } = { [selectedDocumentType]: currentDocuments[selectedDocumentType] },
    isLoading,
    isError,
  } = useRelatedDocumentList(selectedDocumentType);
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight < 100 &&
      !isLoading &&
      currentDocuments[selectedDocumentType].totalCount > numberOfDocumentsShown
    ) {
      dispatch(updateNumberOfDocs(numberOfDocumentsShown + 20));
    }
    dispatch(setListScrollPosition(e.currentTarget.scrollTop));
  };

  useEffect(() => {
    if (!!scrollComponent.current) {
      scrollComponent.current.scrollTop = listScrollPosition;
    }
  }, [listScrollPosition]);

  const noDocumentsFound = !isError && !isLoading && All.totalCount === 0;
  return (
    <div className={classes.container} onScroll={handleScroll} ref={scrollComponent}>
      {isError || noDocumentsFound ? (
        <div className={classes.centerContainer}>
          <Typography className={classes.errorText}>
            {!noDocumentsFound ? "There was a problem fetching the documents" : "No documents found"}
          </Typography>
        </div>
      ) : (
        data?.documents.map((document, index) => (
          <DocumentListItem document={{ ...document, index }} key={document.id} />
        ))
      )}
      {isLoading && (
        <div className={classes.centerContainer}>
          <AiCircularProgress className={classes.spinner} size={30} />
        </div>
      )}
    </div>
  );
}
