import AiButton from "@amplyfi/ui-components/components/Button";
import { createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import AllInclusive from "@material-ui/icons/AllInclusive";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      textAlign: "center",
      maxWidth: 700,
      margin: "0 auto",
      marginTop: theme.spacing(6),
    },
    background: {
      width: "100%",
      marginLeft: theme.spacing(1),
    },
    onboardingItemContainer: {
      display: "flex",
      marginTop: theme.spacing(2),
    },
    onboardingItem: {
      width: 400,
      height: 200,
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #C2CCD4",
      marginRight: theme.spacing(1),
    },
    onboardingTitle: {
      marginTop: theme.spacing(4),
      fontWeight: "bold",
    },
    heading: {
      marginBottom: theme.spacing(4),
    },
    startButton: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function Landing(): JSX.Element {
  const styles = useStyles();
  const history = useHistory();

  function OnboardingItem(props: { icon: React.ReactNode; text: string }) {
    const { icon, text } = props;
    return (
      <div className={styles.onboardingItem}>
        {icon}
        <Typography className={styles.onboardingTitle} variant="h3">
          {text}
        </Typography>
      </div>
    );
  }

  function setKnowledgeOnboarded(isOnboarded: boolean) {
    localStorage.setItem("knowledgeOnboarded", `${isOnboarded}`);
    history.replace("/research");
  }

  return (
    <Paper className={styles.background}>
      <div className={styles.main}>
        <Typography className={styles.heading} variant="h1">
          About Research
        </Typography>
        <Typography style={{ fontStyle: "italic" }} className={styles.heading} variant="h2">
          Find Everything Faster
        </Typography>
        <div className={styles.onboardingItemContainer}>
          <OnboardingItem icon={<SearchIcon fontSize="large" />} text="An easy-to-use search tool." />
          <OnboardingItem icon={<AllInclusive fontSize="large" />} text="That helps you search everything at once." />
          <OnboardingItem
            icon={<FormatListBulletedIcon fontSize="large" />}
            text="And see all your results in one single list"
          />
        </div>
        <AiButton className={styles.startButton} onClick={() => setKnowledgeOnboarded(true)}>
          Start Searching
        </AiButton>
      </div>
    </Paper>
  );
}
