import { White } from "@amplyfi/ui-components/theme/colors";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { link } from "../../css/mixins";

const SEARCH_BAR_MAX_WIDTH = 832;
export const CONTAINER_WIDTH = 1426;

export const usePageStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flexGrow: 1,
      paddingLeft: "16px !important",
      paddingRight: "16px !important",

      "& > div": {
        marginBottom: theme.spacing(4),
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
    },
    title: {
      textAlign: "center",
      width: 650,
    },
    autocomplete: {
      position: "relative",
      display: "flex",
      width: "100%",
      maxWidth: `${SEARCH_BAR_MAX_WIDTH}px`,
    },
    autocompleteContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 52,
    },
    logo: {
      width: 200,
    },

    searchContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    footer: {
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(50%)",
    },
    landingMainContainer: {
      height: "100%",
      maxWidth: CONTAINER_WIDTH,
      margin: "0 auto",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    entityListContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.componentBackground.main,
      padding: theme.spacing(1, 0),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
      borderRadius: 10,
      margin: "0 auto",
      maxWidth: `${SEARCH_BAR_MAX_WIDTH}px`,
      border: `1px solid ${theme.palette.borders.contrastText}`,
    },
    heading: {
      display: "flex",
      maxWidth: "356px",
      textAlign: "center",
      marginBottom: theme.spacing(3),
    },
    card: {
      border: `1px solid ${theme.palette.borders.contrastText}`,
      borderRadius: 20,
      background: theme.palette.componentBackground.main,
      "& > :not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.borders.contrastText}`,
      },
    },
    cardTitle: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      whiteSpace: "nowrap",
      flexWrap: "wrap",
    },
    cardContent: {
      padding: theme.spacing(3),
    },
    searchButtonContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      "& .MuiButton-label": {
        display: "inline-flex",
        flexDirection: "column",
      },
      "& .MuiButton-root": {
        width: "100%",
        borderRadius: 10,
        height: 70,
      },
    },
    searchButtonLoadingIcon: {
      color: White,
    },
    searchButtonHeading: {
      fontSize: theme.typography.h3.fontSize,
      whiteSpace: "nowrap",
      color: White,
      fontWeight: "bold",
    },
    searchButtonSubHeading: {
      marginTop: 4,
      fontSize: 11,
      fontWeight: "normal",
      color: White,
    },
    landingContainer: {
      display: "grid",
      gridTemplateColumns: "2fr 1fr",
      gap: theme.spacing(2),
      marginRight: theme.spacing(),
    },
    landingSubContainer: {
      display: "grid",
      gap: theme.spacing(2),
      gridAutoRows: "min-content",
    },
    saveSearch: {
      ...link(theme),
      fontSize: theme.typography.caption.fontSize,
      fontWeight: "bold",
    },
    saveSearchDisabled: {
      color: theme.palette.borders.contrastText,
      fontSize: theme.typography.caption.fontSize,
      fontWeight: "bold",
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      height: 52,
      maxWidth: CONTAINER_WIDTH,
      marginRight: "auto",
    },
    topBarLogo: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    strapline: {
      fontWeight: "bold",
      color: "#91979d",
      marginLeft: theme.spacing(3),
    },
    savedSearchListContainer: {
      width: "100%",
      justifyContent: "center",
      paddingTop: 0,
      "& > *": {
        borderBottom: `1px solid ${theme.palette.borders.contrastText}`,
      },
    },
    savedSearchListItem: {
      paddingLeft: theme.spacing(2),
    },
    cardRight: {
      display: "flex",
      flexWrap: "wrap",
    },
    advancedLabel: {
      fontWeight: "bold",
    },
    queryBuilder: {
      minHeight: 300,
      display: "flex",
      justifyContent: "space-between",
    },
  })
);
