import AiChip from "@amplyfi/ui-components/components/Chip";
import { Negative } from "@amplyfi/ui-components/theme/colors";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";

interface DeletedChipProps {
  id: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    chipDeleted: {
      background: Negative,
      "& .MuiChip-label": {
        color: "#000",
      },
    },
  })
);

export function DeletedChip({ id }: DeletedChipProps): JSX.Element {
  const classes = useStyles();

  return (
    <AiChip
      data-testid={testId("entity-knowledge-base", "entity", "deleted")}
      key={`entity-${id}-is-deleted`}
      className={classes.chipDeleted}
      label="Deleted"
      size="small"
    />
  );
}
