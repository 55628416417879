import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import axios, { CancelTokenSource } from "axios";
import React, { ReactNode, Reducer, useEffect, useReducer, useState } from "react";
import { Entity, EntityType, formatEntity } from "../../../models/entity";

import { TextFieldProps } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { zIndex } from "../../../helpers/componentsZIndex";
import { logError } from "../../../helpers/logger";
import useDebounce from "../../../hooks/useDebounce";
import { autocompleteCountries, autocompleteEntities } from "../../../http/general";
import { SearchType } from "../../../models/search";
import { useAnalyseSelector } from "../../../store/reducers";
import AutocompleteSearchTextField from "../AutocompleteSearchTextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      width: "100%",
    },
    textField: {
      display: "flex",
      flexGrow: 1,
    },
    input: {},
    inputBase: {},
    errorMessage: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      textAligh: "center",
      transform: "translateY(100%)",
      paddingTop: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.error.main,
    },
    hidePopper: {
      display: "none",
    },
    showPopper: {
      zIndex: zIndex.modalAndMenu + 1,
    },
  })
);

interface EntityAutocompleteProps {
  onAdd: (entity: Entity) => void;
  placeholder?: string;
  autocompleteClasses?: string;
  inputClasses?: string;
  textFieldClasses?: string;
  inputBaseClasses?: string;
  type?: SearchType | null;
  disableAutocomplete?: boolean;
  startAdornment?: ReactNode | null;
  endAdornment?: ReactNode | null;
  containerClasses?: string;
  dataTestId?: string;
  disabled?: boolean;
}

interface AutocompleteState {
  loading: boolean;
  error: boolean;
  results: Entity[];
}

type AutocompleteAction =
  | { type: "LOADING" | "ERROR" | "CLEAR" | "BLUR"; payload: null }
  | { type: "SUCCESS"; payload: Entity[] };

const AutocompleteReducer: Reducer<AutocompleteState, AutocompleteAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "LOADING":
      return {
        loading: true,
        error: false,
        results: state.results,
      };
    case "SUCCESS":
      return {
        loading: false,
        error: false,
        results: payload as Entity[],
      };
    case "ERROR":
      return {
        loading: false,
        error: true,
        results: [],
      };
    case "CLEAR":
      return {
        loading: false,
        error: false,
        results: [],
      };
    default:
      return state;
  }
};
let cancelToken: undefined | CancelTokenSource;

export default function EntityAutocomplete(props: EntityAutocompleteProps): JSX.Element {
  const {
    onAdd,
    autocompleteClasses,
    inputClasses,
    textFieldClasses,
    inputBaseClasses,
    type = null,
    disableAutocomplete,
    startAdornment = null,
    endAdornment,
    containerClasses,
    dataTestId,
    disabled = false,
  } = props;
  const placeholder = props.placeholder || "Search";
  const styles = useStyles(props);
  const classes = styles;

  const { libraries } = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedValue = useDebounce<string>(searchTerm);
  const [state, dispatch] = useReducer(AutocompleteReducer, {
    loading: false,
    error: false,
    results: [],
  });

  useEffect(() => {
    dispatch({ type: "CLEAR", payload: null });
  }, [type]);

  const { loading, results } = state;
  const optionList: Entity[] = results;

  const inputChange = (_: React.ChangeEvent<unknown>, value: string, reason: "input" | "reset" | "clear") => {
    if (reason === "input") {
      setSearchTerm(value);
    }
  };

  useEffect(() => {
    const fetch = async (value: string) => {
      if (value) {
        if (type === "FreeText") {
          dispatch({
            type: "SUCCESS",
            payload: [
              {
                id: value.trim(),
                name: value.trim(),
              },
            ],
          });
          return;
        }

        if (cancelToken !== undefined) {
          cancelToken.cancel("Operation canceled due to new request.");
        }
        cancelToken = axios.CancelToken.source();
        dispatch({ type: "LOADING", payload: null });

        try {
          const func = type === "Country" ? autocompleteCountries : autocompleteEntities;
          const response = await func(value, cancelToken, libraries, type || EntityType.Organisation);
          const searchResults: {
            id: string;
            name: string;
            type?: EntityType;
            wikiDataId?: string;
          }[] = response.map(({ id, name, type, wikiDataId }) => ({ id, name, type, wikiDataId }));

          if (type === null) {
            searchResults.unshift({
              id: value.trim(),
              name: value.trim(),
            });
          }
          dispatch({ type: "SUCCESS", payload: searchResults });
        } catch (err) {
          if (axios.isCancel(err)) {
            return;
          }
          logError(err);
          dispatch({ type: "ERROR", payload: null });
        }
      }
    };

    const trimmedValue = debouncedValue.trim();
    if (!disableAutocomplete) {
      fetch(trimmedValue);
    }
  }, [debouncedValue, disableAutocomplete, type, libraries]);

  return (
    <div className={clsx(classes.container, containerClasses)}>
      <Autocomplete<Entity>
        data-testid="mock-autocomplete"
        ListboxProps={
          {
            "data-testid": "entity-autocomplete-list",
          } as TextFieldProps & { "data-testid": string }
        }
        classes={{
          popper: !optionList.length ? classes.hidePopper : classes.showPopper,
        }}
        onInputChange={inputChange}
        onChange={(_: React.ChangeEvent<unknown>, value: Entity | null) => {
          if (value) {
            onAdd(value);
            setSearchTerm("");
            dispatch({ type: "CLEAR", payload: null });
          }
        }}
        onBlur={() => {
          dispatch({ type: "BLUR", payload: null });
        }}
        blurOnSelect
        inputValue={searchTerm}
        options={optionList}
        autoHighlight
        // value stores the previous search,
        // we don't need this
        value={null}
        filterOptions={() => optionList}
        getOptionLabel={formatEntity}
        className={clsx(classes.textField, autocompleteClasses, textFieldClasses)}
        disabled={disabled}
        renderInput={(params) => (
          <AutocompleteSearchTextField
            params={params}
            placeholder={placeholder}
            textFieldClasses={textFieldClasses}
            inputBaseClasses={inputBaseClasses}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            inputClasses={inputClasses}
            loading={loading}
            dataTestId={dataTestId}
          />
        )}
      />
    </div>
  );
}
