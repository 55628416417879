export interface Snack {
  autoHideDuration?: number;
  body?: string;
  title: string;
  type?: SnackFrequencyType;
  onClick?: () => void;
}

export enum SnackFrequencyType {
  Every,
  OncePerSession,
}
