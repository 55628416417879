import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { link } from "../../../css/mixins";
import { AmplyfiLink } from "../../AmplyfiLink";
import { useDispatch } from "react-redux";
import { clearFilters } from "../../../store/reducers/searchRequest/actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    link: {
      ...link(theme),
    },
  })
);

export default function NoDocumentsFound(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.container}>
      <Typography variant="body2">
        No documents found!{" "}
        <AmplyfiLink
          to="/analyse"
          onClick={() => {
            dispatch(clearFilters());
          }}
          className={classes.link}
        >
          {"< Back to search page"}
        </AmplyfiLink>
      </Typography>
    </div>
  );
}
