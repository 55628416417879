import { getStatistics } from "../http/document";
import { useQuery } from "./useQuery";

export default function useDocumentStatistics(documentId?: string) {
  return useQuery(["document-statistics", documentId], async () => {
    if (!documentId) {
      return [];
    }
    return getStatistics(documentId);
  });
}
