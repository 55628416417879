import { create } from "zustand";
import { VirtuosoHandle } from "react-virtuoso";

interface InsightListStoreState {
  list: VirtuosoHandle | null;
  listScroller: HTMLElement | Window | null;
  setList: (list: VirtuosoHandle) => void;
  setListScroller: (list: HTMLElement | Window) => void;
  hiddenContentHeight: number;
  setHiddenContentHeight: (hiddenContentHeight: number) => void;
}

const useInsightListStore = create<InsightListStoreState>((set) => ({
  list: null,
  listScroller: null,
  setList: (list) => set({ list }),
  setListScroller: (listScroller) => set({ listScroller }),
  hiddenContentHeight: 0,
  setHiddenContentHeight: (hiddenContentHeight) => set({ hiddenContentHeight }),
}));

export default useInsightListStore;
