import { useTheme } from "@material-ui/core";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Route, Switch } from "react-router-dom";
import { pageView } from "../../../helpers/analytics";
import { useAnalyseSelector } from "../../../store/reducers";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import { setupChartjsDefaults } from "../../Charts/helpers/ChartUtility";
import RedirectToApp, { canUserReadCoreFeatures } from "../../Navigation/RedirectToApp";
import LeftPanel from "../../Widgets/Landing/LeftPanel";
import AuthorizationPage from "../DeepKnowledge/Authorization/Authorization";
import ConnectorGroupsPage from "../DeepKnowledge/ConnectorGroups/ConnectorGroups";
import KnowledgeLanding from "../DeepKnowledge/Landing";
import Search from "../DeepKnowledge/Search";
import { canUserReadResearch } from "../DeepKnowledge/permissions";
import EntityKnowledgeBaseEditorRoutes from "../EntityKnowledgeBase/Routing";
import { canUserUpdateEntityKnowledgeBase } from "../EntityKnowledgeBase/permissions";
import FeedRoutes from "../Feed/Routing";
import { canUserReadFeed } from "../Feed/permissions";
import HarvestPage from "../Harvest/HarvestPage";
import { canUserReadHarvest } from "../Harvest/permissions";
import SettingsPage from "../Settings/SettingsPage";
import UserPage from "../User/UserPage";
import AnalysisAndLibrariesPage from "./AnalysisAndLibrariesPage";
import Home from "./Home";
import Landing from "./Landing";
import NoPermissionsPage from "./NoPermissionsPage";
import { canUserReadAnalysis } from "./permissions";
import useDialogStore from "../../../store/hooks/useDialogStore";
import useUsers from "../../../hooks/useUsers";

export default function HomepageWrapper(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const savePending = useAnalyseSelector((x) => x.searchRequest.url.savePending);
  const ldClient = useLDClient();
  const dialog = useDialogStore((state) => state.dialog);
  const location = useLocation();
  useUsers();

  useEffect(() => {
    const unregister = history.listen((location) => {
      pageView(location.pathname);
    });

    return () => unregister();
  }, [history, dispatch]);

  const theme = useTheme();
  useEffect(() => {
    setupChartjsDefaults(theme);
  }, [theme]);

  useBeforeunload((event: Event) => {
    if (savePending) {
      event.preventDefault();
    }
  });

  if (!canUserReadCoreFeatures(user)) {
    return <NoPermissionsPage />;
  }

  // Ensures that we're hooked up to LaunchDarkly before rendering. Stops flickering
  if (ldClient?.identify === undefined) {
    return <div></div>;
  }

  if (location.search.includes("filter=insights") && location.pathname.includes("/tab/signals")) {
    history.push(location.pathname.replace("/tab/signals", "/tab/insights"));
    return <></>;
  }

  return (
    <>
      <LeftPanel width={70} />
      <Switch>
        {canUserReadResearch(user) && [
          <Route key="/research" path="/research" exact component={Search} />,
          <Route key="/research/landing" path="/research/landing" exact component={KnowledgeLanding} />,
          <Route key="/connectors" path="/connectors" exact component={ConnectorGroupsPage} />,
        ]}
        <Route key="/manage" path="/manage" component={SettingsPage} />,
        <Route key="/authorize" path="/authorize" exact component={AuthorizationPage} />,
        {canUserReadAnalysis(user) && [
          <Route key="/analyse" path="/analyse" exact component={AnalysisAndLibrariesPage} />,
          <Route key="/analyse/landing" path="/analyse/landing" exact component={Landing} />,
        ]}
        {canUserReadAnalysis(user) && <Route key="/analyse/*" path="/analyse/*" component={Home} />}
        {canUserReadHarvest(user) && <Route key="/harvest" path="/harvest" exact component={HarvestPage} />}
        {canUserUpdateEntityKnowledgeBase(user) && EntityKnowledgeBaseEditorRoutes}
        {canUserReadFeed(user) && FeedRoutes}
        <Route key="/userSettings" path="/userSettings" exact component={UserPage} />
        <Route key="/forbidden" path="/forbidden" exact component={NoPermissionsPage} />
        <Route key="/" path="/" exact component={RedirectToApp} />
      </Switch>
      {dialog && dialog}
    </>
  );
}
