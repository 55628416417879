import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useHeaderMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerIcon: {
      display: "flex",
      justifyContent: "center",
      border: `1px solid ${theme.palette.borders.contrastText}`,
      backgroundColor: theme.palette.componentBackground.main,
      borderRadius: 10,
    },
    themeSwitcher: {
      width: 52,
    },
    DIicon: {
      height: 25,
    },
  })
);
