import React, { useState } from "react";
import { CardDetails } from "../../helpers/cardDetailsHelper";
import { useAnalyseSelector } from "../../store/reducers";
import AnalysisComponent from "@amplyfi/ui-components/components/AnalysisComponent";
import { DEFAULT_INTERVAL } from "../../store/reducers/searchRequest/url-functions";
import { testId } from "@amplyfi/ui-components/testHelpers";
import FeedbackModal from "../Widgets/Feedback/FeedbackModal";
import TimelineDialog from "../Widgets/TimelineDialog";
import ExportModalContent from "@amplyfi/ui-components/components/ExportContent";
import { useAnalysisComponentState } from "../../hooks/useAnalysisComponentState";
import { useTimelineFilterLabel } from "../../hooks/useTimelineFilterLabel";
import BarChart from "../Widgets/BarChart/BarChart";
import { Element, ElementType } from "../../models/element";
import { getV3CSVExport } from "../../http/export";
import CustomizationModal from "../Charts/CustomizationModal/CustomizationModal";
import { ActiveOption } from "../../models/chart-customizations";
import { useCustomizationV2 } from "../../hooks/customisation/useCustomization";
import { getElements } from "../../http/element";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { FullScreenHandle } from "react-full-screen";
import clsx from "clsx";
import { BAR_CHARTS_ELEMENT_COUNT } from "../../hooks/useElement";
import { useFlags } from "launchdarkly-react-client-sdk";

const BAR_HEIGHT = 28;

interface ElementBarChartCardProps {
  elementTypes: ElementType[];
  cardDetail: CardDetails;
}

const leftOffset = 220;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
    },
  })
);

export default function ElementBarChartCard(props: ElementBarChartCardProps): JSX.Element {
  const { elementTypes, cardDetail } = props;
  const classes = useStyles();
  const flags = useFlags();

  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { interval = DEFAULT_INTERVAL, includeUndatedDocuments = true } = useAnalyseSelector(
    (x) => x.searchRequest.url.parsed
  );
  const {
    results = [],
    onReset,
    onSelectCustomization,
    isError,
    isLoading,
    customizationOptions,
  } = useCustomizationV2(
    interval,
    (req) =>
      getElements(
        {
          ...req,
          useElasticSampler: !flags.disableElasticSampling,
          systemFilters: {
            ...req.systemFilters,
            dateInterval: interval,
            size: BAR_CHARTS_ELEMENT_COUNT,
            elementTypes: elementTypes,
          },
        },
        url.libraries
      ),
    `elements-${elementTypes}-sampler=${!flags.disableElasticSampling}`
  );
  const elements = results;
  const { keyPhrasesLoading } = useAnalyseSelector((state) => state.searchResponse.overview);
  const [showModal, setShowModal] = useState(false);
  const analysisState = useAnalysisComponentState(
    isError,
    isLoading || keyPhrasesLoading,
    elements === null || elements.length === 0,
    setShowModal
  );

  const timelineFilterLabel = useTimelineFilterLabel();

  const data = elements
    .map(({ id, name, documentCount: value, type }: Element, index: number) => ({
      id,
      index,
      name,
      value,
      type,
    }))
    .reverse();

  const [fullScreenHandle, setFullScreenHandle] = useState<FullScreenHandle | undefined>(undefined);

  const onFullScreenChange = (state: boolean, handle: FullScreenHandle) => {
    if (state === handle.active) {
      setFullScreenHandle(handle);
    }
  };

  return (
    <>
      <AnalysisComponent
        id={`${elementTypes}s-chart`}
        data-testid={testId(`${elementTypes}s-chart`)}
        className={clsx(fullScreenHandle?.active && classes.container)}
        infoTooltipContent={cardDetail.readMore}
        onFeedbackClick={() => setShowModal(true)}
        title={cardDetail.title}
        onFullScreenChange={onFullScreenChange}
        exportContent={
          <ExportModalContent
            data-testid={testId(`${elementTypes}s-export`)}
            componentName={cardDetail.title}
            handleClose={() => void 0}
            handleExport={({ fileName }: { fileName: string }) => {
              getV3CSVExport(fileName, url, interval, elementTypes, {
                systemFilters: {
                  ...(customizationOptions ?? {
                    customizations: results.map(({ id, name, type }) => ({
                      id,
                      name,
                      type,
                    })),
                  }),
                },
              });
            }}
          />
        }
        modifyDataContent={
          <CustomizationModal
            options={[...data].reverse()}
            onApply={onSelectCustomization}
            onReset={() => {
              const originalValues = onReset();
              return originalValues?.map<ActiveOption>((x) => ({
                id: x.id,
                name: x.name,
                type: x.type,
              }));
            }}
          />
        }
        timeContent={<TimelineDialog includeUndatedDocuments={includeUndatedDocuments} />}
        analysisState={analysisState?.state}
        onPrimaryClick={analysisState?.primaryAction}
        onSecondaryClick={analysisState?.secondaryAction}
        timelineFilterLabel={timelineFilterLabel}
      >
        <div>
          <BarChart
            leftOffset={leftOffset}
            data={data}
            height={BAR_HEIGHT * elements.length}
            fullScreenHandle={fullScreenHandle}
          />
          <FeedbackModal
            open={showModal}
            onClose={(_e, _reason) => setShowModal(false)}
            showCloseIcon={false}
            componentName={cardDetail.title}
          />
        </div>
      </AnalysisComponent>
    </>
  );
}
