import { Box, Chip, List, makeStyles } from "@material-ui/core";
import { ChatMessageProps } from "./ChatMessage";

const useStyles = makeStyles(({ spacing }) => ({
  cardChip: {
    background: "#F0F3F7",
    color: "#000",
    padding: spacing(1),
    marginBottom: spacing(1),
    whiteSpace: "normal",
    height: "auto",

    "& .MuiChip-label": {
      whiteSpace: "normal",
    },
  },
}));

export default function SuggestionMessage({ onClick, suggestions }: ChatMessageProps) {
  const classes = useStyles();

  return (
    <List disablePadding>
      {suggestions.map((suggestion) => (
        <Box display="block" onClick={() => onClick?.(suggestion)}>
          <Chip
            className={classes.cardChip}
            style={{ cursor: !!onClick ? "pointer" : undefined }}
            key={suggestion}
            label={suggestion}
          />
        </Box>
      ))}
    </List>
  );
}
