import { createStyles, FormControl, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { zIndex } from "../../../../helpers/componentsZIndex";
import useDocumentLibraries from "../../../../hooks/useDocumentLibraries";
import { LibraryItem } from "../../../../models/search/documentLibrary";

interface SelectLibraryProps {
  libraryId?: string;
  setLibrary: (libraryItem: LibraryItem | null) => void;
  organisationId?: string;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    item: {
      alignItems: "baseline",
      display: "flex",
    },
    select: {
      width: "100%",
    },
    control: {
      marginBottom: spacing(1),
      marginTop: spacing(1),
    },
    label: {
      paddingBottom: spacing(1),
    },
    loading: {
      cursor: "wait",
    },
    placeholder: {
      fontStyle: "italic",
    },
  })
);

const REMOVE_LIBRARY_LIST = ["news", "private"];
const LIBRARY_PLACEHOLDER = "Select a library…";
const NO_LIBRARIES_PLACEHOLDER = "No libraries available for this organisation";

export const SelectLibrary = ({ organisationId, libraryId, setLibrary }: SelectLibraryProps): JSX.Element => {
  const classes = useStyles();
  const { data = [], isLoading } = useDocumentLibraries(organisationId);
  const placeholder = !!data.length || isLoading ? LIBRARY_PLACEHOLDER : NO_LIBRARIES_PLACEHOLDER;

  const libraries = data
    .filter(({ id }) => !REMOVE_LIBRARY_LIST.includes(id))
    .sort(({ name: a }, { name: b }) => a.localeCompare(b));

  return (
    <FormControl fullWidth className={classes.control}>
      <Typography variant="h4" className={classes.label}>
        Library
      </Typography>
      <Select
        MenuProps={{
          style: { zIndex: zIndex.selectMenu },
        }}
        className={clsx(classes.select, isLoading && classes.loading)}
        disabled={!organisationId || isLoading || !libraries?.length}
        onChange={
          libraries
            ? ({ target: { value } }) => setLibrary(libraries.find(({ id }) => id === value) || null)
            : undefined
        }
        value={libraryId || placeholder}
      >
        <MenuItem disabled value={placeholder} className={classes.placeholder}>
          {placeholder}
        </MenuItem>
        {libraries?.map(({ id, name }) => (
          <MenuItem key={id} value={id} className={classes.item}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
