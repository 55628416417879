import { getDocumentLibraries } from "../http/search/documentLibrary";
import { useAuth0 } from "../components/Auth0/AuthWrapper";
import { getOrgId } from "../helpers/user";
import { useQuery, UseQueryResult } from "./useQuery";
import { LibraryItem } from "../models/search/documentLibrary";

export default function useDocumentLibraries(organisationId?: string): UseQueryResult<LibraryItem[]> {
  const { user } = useAuth0();
  const orgId = organisationId || getOrgId(user);

  return useQuery(["document-libraries", orgId], async () => (await getDocumentLibraries(orgId)).libraries, {
    staleTime: Infinity,
  });
}

export function useDocumentLibraryFromList(libraryId: string, organisationId?: string): LibraryItem | undefined {
  const { user } = useAuth0();
  const orgId = organisationId || getOrgId(user);

  const { data } = useDocumentLibraries(orgId);
  return data?.find((d) => d.id === libraryId);
}
