import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Box, Button, Collapse, List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import clsx from "clsx";
import { useState } from "react";
import { Message } from "../../../../../../hooks/queries/question_answer/useQAQuery";
import { MessageFrom } from "../../../../../../hooks/queries/sockets/types";
import SuggestionMessage from "./SuggestionMessage";
import TextMessage from "./TextMessage";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import useQuestionAnswerStore from "../../../../../../store/hooks/useQuestionAnswerStore";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  chatWindow: {
    flex: "1 1 0",
    minHeight: 0,
    overflow: "auto",
    paddingBottom: spacing(4),
  },
  message: {
    display: "inline-block",
    maxWidth: "80%",
    width: "auto",
    whiteSpace: "pre-line",
    marginBottom: spacing(1),
    borderRadius: 5,
  },
  userMessage: {
    marginLeft: "auto",
    textAlign: "right",
    background: "#f0f3f6",
  },
  systemMessage: {
    background: "#e6f5f8",
    minWidth: 68,
  },
  textEntry: {
    flex: "0 0 auto",
    alignSelf: "flex-end",
    [breakpoints.down("sm")]: {
      marginBottom: spacing(2),
    },
  },
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100%",
  },
  noBackground: {
    background: "none",
    padding: 0,
    marginLeft: "auto",
  },
  button: {
    display: "block-inline",
    maxWidth: "80%",
    width: "auto",
    whiteSpace: "pre-line",
    marginBottom: spacing(1),
    borderRadius: 5,
    background: "#e6f5f8",
    minWidth: 68,
    paddingBlock: spacing,
  },
}));

export interface ChatMessageProps extends Message {
  onClick?: (text: string) => void;
  showSources: boolean;
  handleUnansweredQuestion?: () => void;
  isUnansweredMessage?: boolean;
}

function getMessage(message: ChatMessageProps) {
  if (message.type === "question" || message.type === "question-about-documents") {
    return <TextMessage {...message} />;
  }

  if (message.type === "investigation-questions") {
    return <SuggestionMessage {...message} />;
  }

  return <TextMessage {...message} />;
}

export default function ChatMessage(props: ChatMessageProps) {
  const { showSources = false, handleUnansweredQuestion, ...message } = props;
  const classes = useStyles();
  const [sourcesOpen, toggleSourcesOpen] = useState<boolean>(false);
  const documentIds = useQuestionAnswerStore((state) => state.documentIds);

  return (
    <Box display="flex" flexDirection="column">
      <ListItem
        className={clsx(
          classes.message,
          message.from === MessageFrom.User ? classes.userMessage : classes.systemMessage,
          message.type === "investigation-questions" && classes.noBackground
        )}
      >
        {getMessage({ ...message, showSources })}
        {handleUnansweredQuestion && documentIds.length > 0 && (
          <Button className={classes.button} onClick={handleUnansweredQuestion} startIcon={<HelpOutlineIcon />}>
            Ask in Feed Q&A
          </Button>
        )}
        {message.from === MessageFrom.System && showSources && message.sources && message.sources.length > 0 && (
          <Button
            focusRipple={false}
            endIcon={sourcesOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            style={{ fontSize: 10, textAlign: "left", marginLeft: -8 }}
            onClick={() => toggleSourcesOpen(!sourcesOpen)}
          >
            Sources
          </Button>
        )}
        {message.sources && (
          <Collapse in={sourcesOpen}>
            {message.sources?.length === 0 && <AiCircularProgress size={20} />}
            <List disablePadding>
              {message.sources.map((m) => (
                <ListItem style={{ cursor: "pointer" }} onClick={() => window.open(m.url, "_blank")} disableGutters>
                  <ListItemText
                    primary={m.title}
                    secondary={m.url}
                    secondaryTypographyProps={{ style: { overflowWrap: "anywhere" } }}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </ListItem>
    </Box>
  );
}
