import axios, { AxiosInstance, CancelTokenSource } from "axios";
import { AutocompleteType } from "../models/autocomplete";

interface Options {
  version?: number;
}

// TODO: we'll move this later
export const analyseClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ANALYSE_API,
});

export interface AutocompleteResponse {
  aliases: string[];
  id: string;
  name: string;
  description: string | null;
  industries: string[];
  wikiDataId: string;
  websites: string[];
}

export async function getAutocompleteResults(
  query: string,
  entityType: AutocompleteType,
  { version = 5 }: Options = {}
): Promise<AutocompleteResponse[]> {
  const { data } = await analyseClient.get<AutocompleteResponse[]>(
    `/v${version}/autocomplete/${encodeURIComponent(query)}`,
    { params: { entityType } }
  );

  return data.map(({ industries, websites, ...result }) => ({
    ...result,
    websites: websites || [],
    industries: industries || [],
  }));
}

export async function getAutocompletePhrases(
  searchTerm: string,
  { token: cancelToken }: CancelTokenSource,
  size?: number
): Promise<string[]> {
  const searchResponse = await analyseClient.get<string[]>(
    `/v2/autocomplete/phrases/${encodeURIComponent(searchTerm)}`,
    {
      params: { size },
      cancelToken,
    }
  );

  return searchResponse.data;
}
