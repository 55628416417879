import AnalyseCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import {
  createStyles,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Connector,
  ConnectorAttribute,
  ConnectorAuthentication,
  getPrototype,
  Prototype,
  PrototypeConnectorSetting,
} from "../../../../http/connectors";
import langs from "./languages.json";

const languages = JSON.parse(langs);

interface PrototypeConfigurationProps extends Connector {
  onUpdated: (prototype: Prototype | Connector) => void;
  onCanProgressChanged: (canProgress: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wizardPage: {},
  })
);

interface PrototypeConfigurationItemProps extends ConnectorAttribute {
  onUpdated: (value: string) => void;
}

function ConnectorConfigurationItem(props: PrototypeConfigurationItemProps): JSX.Element {
  const { onUpdated, name, label, type, options } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    if (value) {
      onUpdated(value);
    }
    // eslint-disable-next-line
  }, [value]);

  const elem =
    type === "options" ? (
      <>
        <Typography variant="body2">{label}</Typography>
        <Select
          onChange={({ target: { value } }) => setValue(value as string)}
          style={{ width: "100%", marginBottom: 8 }}
          label={name}
          value={props.value || ""}
        >
          {options
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <TextField
        style={{ marginBottom: 8 }}
        type={props.isSecureValue ? "password" : "text"}
        fullWidth
        variant="outlined"
        label={props.name}
        defaultValue={props.isSecureValue && !props.value ? "template" : props.value}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  return elem;
}

function attributeValid(attr: PrototypeConnectorSetting): boolean {
  if (attr.validationRegex) {
    if (!attr.value) {
      return false;
    }
    const regex = new RegExp(attr.validationRegex);
    return attr.value.length > 0 && regex.test(attr.value);
  } else {
    const val = attr.value ? attr.value : "";
    return attr.isSecureValue ? true : val.length > 0;
  }
}

export default function ConnectorConfigurationPage(props: PrototypeConfigurationProps): JSX.Element {
  const styles = useStyles();
  const { onUpdated, onCanProgressChanged, prototypeId, oAuthConfiguration, name, attributes, languageCode } = props;
  const { data: prototype, isLoading } = useQuery(["prototype", prototypeId], () => getPrototype(prototypeId));
  const [connectorName, setConnectorName] = useState(name);
  const [clientId, setClientId] = useState(oAuthConfiguration?.clientId || "");
  const [clientSecret, setClientSecret] = useState(oAuthConfiguration?.clientSecret || "");
  const [langCode, setLanguageCode] = useState(languageCode || "en");

  function onConfigurationUpdate(name: string, value: string) {
    if (!attributes) {
      return;
    }
    const attrs = attributes.map((attr) => {
      if (attr.name === name) {
        return {
          ...attr,
          value,
        };
      }
      return attr;
    });

    onUpdated({
      ...props,
      name: connectorName,
      attributes: attrs,
      languageCode: langCode,
      oAuthConfiguration:
        clientId && clientSecret
          ? {
              clientId,
              clientSecret,
            }
          : oAuthConfiguration,
    });
    onCanProgressChanged(attrs.every(attributeValid) && !!connectorName);
  }

  useEffect(() => {
    if (connectorName.length > 0) {
      onConfigurationUpdate("name", connectorName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorName]);

  useEffect(() => {
    onConfigurationUpdate("clientId", clientId);
    onConfigurationUpdate("clientSecret", clientSecret);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, clientSecret]);

  useEffect(() => {
    onConfigurationUpdate("languageCode", langCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langCode]);

  useEffect(() => {
    if (!attributes || !attributes.length) {
      onUpdated({
        ...props,
        attributes: prototype?.attributes as PrototypeConnectorSetting[],
      });
      onCanProgressChanged((prototype?.attributes || []).every(attributeValid) && !!connectorName);
    } else {
      onCanProgressChanged(attributes.every(attributeValid) && !!connectorName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prototype, connectorName]);

  if (isLoading) {
    return <AnalyseCircularProgress />;
  }

  return (
    <div className={clsx(styles.wizardPage)}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="h4">Enter the details to connect to this source.</Typography>
        {prototype && prototype.support && (
          <a rel="noreferrer" target="_blank" href={prototype.support}>
            Help
          </a>
        )}
      </div>
      <div style={{ marginTop: 8 }}>
        {(attributes || []).map((conf) => (
          <ConnectorConfigurationItem
            key={conf.name}
            {...conf}
            onUpdated={(value) => {
              onConfigurationUpdate(conf.name, value);
              const name = conf.options.find((o) => o.value === value)?.name;
              if (name) {
                setConnectorName(name);
              }
            }}
          />
        ))}
        {prototype?.authenticationType === ConnectorAuthentication.OAuth && (
          <>
            <TextField
              style={{ marginBottom: 8 }}
              fullWidth
              variant="outlined"
              label="Client ID"
              defaultValue={clientId}
              onChange={(e) => setClientId(e.target.value)}
            />
            <TextField
              style={{ marginBottom: 8 }}
              fullWidth
              variant="outlined"
              label="Client Secret"
              defaultValue={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
            />
          </>
        )}
      </div>
      <div style={{ marginTop: 8 }}>
        <TextField
          style={{ marginBottom: 8 }}
          fullWidth
          variant="outlined"
          label="Source Name"
          value={connectorName}
          onChange={({ target: { value } }) => setConnectorName(value)}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">Source Language: </Typography>
          <Select
            style={{ marginLeft: 16 }}
            defaultValue={langCode}
            onChange={(e) => setLanguageCode(e.target.value as string)}
          >
            {languages.map((lang: { [key: string]: string }) => (
              <MenuItem key={lang.id} value={lang.id}>
                <ListItemText primary={lang.name} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
