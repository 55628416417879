import axios from "axios";
import { AmplyfiUnknownEntity } from "../components/Pages/EntityKnowledgeBase/models";
import { enableStatus } from "../helpers/axios";

const apiUrl = `${process.env.REACT_APP_ANALYSE_API}/v5`;

export interface EntityResponse {
  count: number;
  entities: AmplyfiUnknownEntity[];
  paginationToken: string | null;
}

interface SearchOptions {
  id?: string;
  name?: string;
}

export async function searchAmplyfiEntities(options: SearchOptions): Promise<EntityResponse> {
  try {
    const { id, name } = options;
    const params: SearchOptions = {};
    if (id) params.id = id;
    if (name) params.name = name;

    const { data } = await axios.get<EntityResponse>(`${apiUrl}/amplyfi-entities/`, { params });
    return data;
  } catch (e) {
    return { count: 0, entities: [], paginationToken: null };
  }
}

export async function getAmplyfiEntity(id: string): Promise<AmplyfiUnknownEntity> {
  const { data } = await axios.get<AmplyfiUnknownEntity>(`${apiUrl}/amplyfi-entities/${id}`);
  return data;
}

export async function getUnverifiedAmplyfiEntities(paginationToken: string | null): Promise<EntityResponse> {
  const { data } = await axios.get<EntityResponse>(
    `${process.env.REACT_APP_ANALYSE_API}/v1/amplyfi-entities/unverified`,
    {
      params: {
        paginationToken: paginationToken ? decodeURIComponent(paginationToken) : undefined,
      },
    });
  return data;
}

export interface ValidationError {
  error: string;
  field: string;
}

interface SaveEntityResponse {
  errors: ValidationError[];
}

export async function updateAmplyfiEntity<T extends AmplyfiUnknownEntity>({
  id,
  ...rest
}: T): Promise<SaveEntityResponse> {
  const { data } = await axios.put<SaveEntityResponse>(`${apiUrl}/amplyfi-entities/${id}`, rest, {
    validateStatus: enableStatus(400),
  });
  return data || { errors: [] };
}

export async function deleteAmplyfiEntity(id: AmplyfiUnknownEntity["id"]): Promise<boolean> {
  try {
    await axios.delete(`${apiUrl}/amplyfi-entities/${id}`);
    return true;
  } catch (e) {
    return false;
  }
}
