import AiButton from "@amplyfi/ui-components/components/Button";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { WarningOutlined as WarningIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../../../helpers/analytics";
import { getUserId } from "../../../../helpers/user";
import useCreateFeed from "../../../../hooks/mutations/feed/useCreateFeed";
import { getDocumentLibraries } from "../../../../http/search/documentLibrary";
import { AccountGroup } from "../../../../models/account";
import { LibraryItem } from "../../../../models/search/documentLibrary";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import Modal from "../../../Widgets/Misc/Modal";
import { SelectOrganisation } from "../CreateHarvest/SelectOrganisation";
import { EnterText } from "../CreateLibrary/EnterText";
import { hasUniqueName, isValidDescription, isValidName } from "../helpers/libraryValidation";

type CreateLibraryModalProps = React.ComponentProps<typeof Modal>;

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    title: {
      display: "flex",
      justifyContent: "left",
      marginBottom: spacing(3),
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: spacing(3.5),
      "&>:first-child": {
        marginRight: spacing(4),
      },
    },
    button: {
      padding: `${spacing(1.25)}px ${spacing(6.75)}px `,
      width: 100,
    },
    icon: {
      fontSize: 14,
      marginRight: 4,
    },
    section: {
      marginBottom: spacing(2),
    },
    validation: {
      color: palette.error.main,
      display: "flex",
      fontSize: 10,
    },
  })
);

export const CreateLibraryModal = ({ onClose, open, ...rest }: CreateLibraryModalProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const [isBusy, setIsBusy] = useState(false);
  const [isLibraryNameError, setIsLibraryNameError] = useState(false);
  const [organisation, setOrganisation] = useState<AccountGroup | null>(null);
  const [libraryDescription, setLibraryDescription] = useState("");
  const [libraryName, setLibraryName] = useState("");
  const [existingLibraries, setExistingLibraries] = useState<LibraryItem[] | null>(null);
  const { mutateAsync: createFeed } = useCreateFeed();

  const handleClose = () => {
    onClose?.({}, "escapeKeyDown");
  };

  const handleCreateLibrary = async ({ id }: AccountGroup, name: string, description: string) => {
    setIsBusy(true);

    await createFeed(({ name, description, dashboards: [], subscribedUserIds: [getUserId(user)], subscribedGroupIds: [] }));
    analyticsEvent("Harvest", "HarvestLibraryCreated");
    dispatch(setSnack({ body: "Successfully created new library", title: "Success" }));
    handleClose();
  };

  const fetchExistingLibraries = async ({ id }: AccountGroup) => {
    setIsBusy(true);
    setExistingLibraries(null);

    try {
      const { libraries } = await getDocumentLibraries(id);
      setExistingLibraries(libraries);
    } catch (e) {
      setExistingLibraries([]);
    }
    setIsBusy(false);
  };

  useEffect(() => {
    if (organisation) {
      fetchExistingLibraries(organisation);
    } else {
      setExistingLibraries(null);
    }
  }, [organisation]);

  useEffect(() => {
    if (existingLibraries !== null && isValidName(libraryName)) {
      const names = existingLibraries.map(({ name }) => name);
      setIsLibraryNameError(!hasUniqueName(libraryName, names));
    } else {
      setIsLibraryNameError(false);
    }
  }, [existingLibraries, libraryName]);

  useEffect(() => {
    if (open) {
      setIsBusy(false);
      setOrganisation(null);
      setLibraryDescription("");
      setLibraryName("");
    }
  }, [open]);

  return (
    <Modal
      showCloseIcon
      data-testid={testId("harvest", "create-library")}
      maxWidth={false}
      onClose={handleClose}
      open={open}
      {...rest}
    >
      <div className={classes.title}>
        <Typography variant="h1">Create New Library</Typography>
      </div>

      <section className={classes.section}>
        <EnterText
          isError={isLibraryNameError}
          label="Library Name"
          placeholder="Enter library name here"
          value={libraryName}
          setValue={setLibraryName}
        />
        {isLibraryNameError && (
          <Typography className={classes.validation}>
            <WarningIcon color="error" className={classes.icon} /> This library already exists. Please try again.
          </Typography>
        )}
      </section>
      <section className={classes.section}>
        <EnterText
          label="Description"
          multiline
          placeholder="Enter library description here"
          setValue={setLibraryDescription}
          value={libraryDescription}
        />
      </section>
      <section className={classes.section}>
        <SelectOrganisation organisation={organisation} setOrganisation={setOrganisation} />
      </section>

      <div className={classes.buttonsContainer}>
        <AiButton
          className={classes.button}
          data-testid={testId("harvest", "create-library", "create")}
          disabled={
            !organisation ||
            isBusy ||
            existingLibraries === null ||
            !isValidName(libraryName) ||
            !isValidDescription(libraryDescription) ||
            isLibraryNameError
          }
          onClick={organisation ? () => handleCreateLibrary(organisation, libraryName, libraryDescription) : undefined}
        >
          {isBusy ? <AiCircularProgress size={20} /> : "Create"}
        </AiButton>
      </div>
    </Modal>
  );
};
