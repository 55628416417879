import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { MidLightBlue } from "@amplyfi/ui-components/theme/colors";
import { FilterOption } from "../../../models/filter";
import { Entity } from "../../../models/entity";

const useStyles = makeStyles(({ spacing, typography, palette }: Theme) =>
  createStyles({
    container: {
      backgroundColor: palette.componentBackground.main,
      display: "flex",
      alignItems: "center",
      padding: `${spacing(1)}px 0`,
    },
    addIcon: {
      color: MidLightBlue,
      marginRight: spacing(0.5),
      width: spacing(2.4),
      height: spacing(2.4),
      cursor: "pointer",
    },
    countText: {
      color: "#748998",
      marginLeft: "auto",
    },
    title: {
      marginRight: "auto",
      width: 150,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

interface SelectFilterLegendProps {
  detail: FilterOption;
  setActiveFilter: (option: Entity) => void;
}

export default function SelectFilterLegend(props: SelectFilterLegendProps): JSX.Element {
  const { detail, setActiveFilter } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <AddCircleIcon className={classes.addIcon} onClick={() => setActiveFilter(detail.entity)} />
      <Typography variant="h4" className={classes.title}>
        {detail.entity.name}
      </Typography>
      <Typography variant="h4" className={classes.countText}>
        {detail.count.toLocaleString()}
      </Typography>
    </div>
  );
}
