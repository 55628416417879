import AiButton from "@amplyfi/ui-components/components/Button";
import CircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { usePageStyles } from "../../Pages/useLandingStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    button: {
      padding: "10px 24px",
      minWidth: 200,
    },
    actionContainer: {
      display: "flex",
      alignItems: "center",
      "& > *": {
        "&:not(:last-child)": {
          marginRight: theme.spacing(2),
        },
      },
    },
  })
);

export default function Header(props: {
  title: string | JSX.Element;
  buttonText?: string;
  onButtonClicked?: () => void;
  buttonDisabled?: boolean;
  isLoading?: boolean;
  actions?: JSX.Element[];
}): JSX.Element {
  const classes = useStyles();
  const pageClasses = usePageStyles();
  const { title, buttonText, onButtonClicked, buttonDisabled = false, isLoading = false, actions } = props;
  return (
    <header className={classes.header}>
      {typeof title === "string" ? <Typography variant="h2">{title}</Typography> : title}
      <div className={classes.actionContainer}>
        {!!actions && actions.length > 0 && actions}
        {buttonText && (
          <AiButton
            data-testid="btn_search"
            className={classes.button}
            disabled={buttonDisabled}
            onClick={onButtonClicked}
          >
            {isLoading ? (
              <CircularProgress
                size={20}
                secondaryColor="white"
                data-testid="btn_search_loading"
                className={pageClasses.searchButtonLoadingIcon}
              />
            ) : (
              buttonText
            )}
          </AiButton>
        )}
      </div>
    </header>
  );
}
