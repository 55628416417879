import { CanUserPermissionCheck, getPermissions, userPermission } from "../../../helpers/user";

const feature = "harvest";
const organisationsFeature = "organisations";
const libraryFeature = "library";

const harvest = userPermission(feature);
const organisations = userPermission(organisationsFeature);
const library = userPermission(libraryFeature);

export const canUserReadHarvest: CanUserPermissionCheck = (user) => getPermissions(user).includes(harvest.read());
export const canUserCreateHarvest: CanUserPermissionCheck = (user) => getPermissions(user).includes(harvest.create());
export const canUserCreateMultilingualHarvest: CanUserPermissionCheck = (user) =>
  getPermissions(user).includes(harvest.create("multilingual"));

export const canUserReadOrganisations: CanUserPermissionCheck = (user) =>
  getPermissions(user).includes(organisations.read());

export const canUserCreateLibrary: CanUserPermissionCheck = (user) => getPermissions(user).includes(library.create());
