import { createStyles, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { zIndex } from "../../helpers/componentsZIndex";
import { Entity, EntityType } from "../../models/entity";
import { TooltipType } from "../../models/search";
import EntityTooltip from "../Widgets/Entity/EntityTooltip";

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      zIndex: zIndex.modalAndMenu,
    },
  })
);

export default function DocumentHighlightEntitiesTootlip(props: {
  item: JSX.Element;
  disabled?: boolean;
  showListActions?: boolean;
}): JSX.Element {
  const [selectedData, setSelectedData] = useState<Entity>();
  const { item, disabled = false, showListActions = true } = props;
  const classes = useStyles();

  const handleClick = () => {
    setSelectedData({
      id: item.props.id,
      name: item.props.name,
      type: item.props.type as EntityType,
    });
  };

  return (
    <EntityTooltip
      entity={selectedData}
      onClose={() => setSelectedData(undefined)}
      tooltipType={!selectedData?.type ? TooltipType.KeyPhrase : undefined}
      classes={{ popper: classes.tooltip }}
      showListActions={showListActions}
    >
      <span onClick={disabled ? undefined : handleClick} data-testid="reader-mark-highlighted">
        {item}
      </span>
    </EntityTooltip>
  );
}
