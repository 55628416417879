import { createStyles, makeStyles } from "@material-ui/core";
import { useAnalyseSelector } from "../../../store/reducers";
import TimelineDialog from "../TimelineDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(6),
      width: 460,
    },
  })
);

interface TimeRangeFiltersProps {
  onApply: () => void;
}

export default function TimeRangeFilters(props: TimeRangeFiltersProps): JSX.Element {
  const { onApply } = props;
  const classes = useStyles();
  const { includeUndatedDocuments } = useAnalyseSelector((x) => x.searchRequest.url.parsed);

  return (
    <div className={classes.container}>
      <TimelineDialog includeUndatedDocuments={includeUndatedDocuments} onApply={onApply} />
    </div>
  );
}
