import { Box, makeStyles, Tab, TabProps, Tooltip } from "@material-ui/core";
import { TabContext, TabPanel, TabList } from "@material-ui/lab";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import useDocument from "../../../hooks/useDocument";
import { MonitoringDocument } from "../../../models/monitoring";
import { extractDocumentMeta } from "./copyHelper";
import { DocumentSummaryTab, tabs } from "./tabs";

interface DocumentSummaryProps {
  documentId?: string;
  increaseContrast?: boolean;
  monitoredDocument?: MonitoringDocument;
  onLoaded?: () => void;
  refreshSearch?: () => void;
}

const MOBILE_BREAKPOINT = 700;

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  container: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  tab: {
    color: palette.text.primary,
    opacity: 1,
    flex: "1 1 auto",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: "0 38px",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#ccc",
    },
    [breakpoints.down(MOBILE_BREAKPOINT)]: {
      fontSize: 11,
    },
  },
  activeTab: {
    backgroundColor: "#dfdfdf",
    "&:hover": {
      backgroundColor: "#dfdfdf",
    },
  },
  content: {
    height: "100%",
    overflow: "auto",
    flex: "1 1 auto",
    backgroundColor: "#dfdfdf",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    [breakpoints.up("md")]: {
      flex: "1 1 auto",
    },
  },
  activeIndicator: {
    display: "none",
  },
  beta: {
    display: "block",
    position: "relative",
    "&:after": {
      color: "#2861FB",
      content: "'BETA'",
      fontSize: 8,
      position: "absolute",
      top: -4,
      left: "102%",
      userSelect: "none",
      [breakpoints.down(MOBILE_BREAKPOINT)]: {
        left: 0,
        top: -1 * spacing(1.2),
        width: "100%",
      },
    },
  },
}));

export default function DocumentSummary(props: DocumentSummaryProps) {
  const { documentId, monitoredDocument, onLoaded, refreshSearch } = props;
  const { data: document, isLoading } = useDocument(monitoredDocument ? undefined : documentId);
  const classes = useStyles(props);
  const [tab, setTab] = useState("0");
  const [tabCount, setTabCount] = useState<Record<string, number>>({});
  const tabCountRef = useRef(tabCount);
  tabCountRef.current = tabCount;

  const handleChange = (_: React.ChangeEvent<unknown>, index: typeof tab) => {
    setTab(index);
    refreshSearch?.();
  };

  function CloneProps(props: TabProps & { children: (props: TabProps) => JSX.Element }) {
    const { children, ...other } = props;
    return children(other);
  }

  const handleOnLoadCallback = (id: DocumentSummaryTab["id"]) => (count: number) =>
    window.setTimeout(() => {
      refreshSearch?.();
      setTabCount({ ...tabCountRef.current, [id]: count });
    });

  const documentMeta = (copyMultipleFeature: DocumentSummaryTab["copyMultipleFeature"]) => (feature: string[]) =>
    extractDocumentMeta(document?.data || monitoredDocument)(copyMultipleFeature(feature));

  useEffect(() => {
    if (!isLoading) {
      onLoaded?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return monitoredDocument || document ? (
    <Box className={classes.container}>
      <TabContext value={tab}>
        <TabList onChange={handleChange} TabIndicatorProps={{ className: classes.activeIndicator }} variant="fullWidth">
          {tabs.map(({ id, isBeta, name, tooltip }, index) => (
            <CloneProps key={`${id}-tab`} value={`${index}`}>
              {(props: TabProps) => (
                <Tooltip title="">
                  <>
                    <Tab
                      {...props}
                      selected={tab === `${index}`}
                      title={tooltip}
                      label={
                        <span className={isBeta ? classes.beta : undefined}>
                          {name}
                          {tabCount[id] !== undefined ? ` (${tabCount[id]})` : null}
                        </span>
                      }
                      className={clsx(classes.tab, tab === `${index}` && classes.activeTab)}
                    />
                  </>
                </Tooltip>
              )}
            </CloneProps>
          ))}
        </TabList>
        {tabs.map(({ copyMultipleFeature, component, id }, index) => (
          <TabPanel key={`${id}-content`} className={classes.content} value={`${index}`}>
            {component({
              documentId,
              document: document?.data || monitoredDocument,
              onLoadCallback: handleOnLoadCallback(id),
              documentMeta: documentMeta(copyMultipleFeature),
            })}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  ) : null;
}
