import AiIconButton from "@amplyfi/ui-components/components/IconButton";
import { ListItemIcon, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { FileCopy as FileCopyIcon, MoreVert as MoreVertIcon } from "@material-ui/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { zIndex } from "../../../../helpers/componentsZIndex";
import { copyText } from "../copyHelper";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";

type Item = string;
type Items = Item[];

type CopyHandler<T> = {
  handler: (items: T) => string;
  text: string;
  tooltipText?: string;
};

interface TabProps {
  bullets?: Items;
  content?: Items;
  noContent: string;
  copyMultiple: CopyHandler<Items>;
  copySingular: CopyHandler<Item>;
  showCopyMultiple?: boolean;
}

const useStyles = makeStyles(({ spacing }) => ({
  list: {
    margin: 0,
    marginLeft: spacing(2),
    marginTop: spacing(1),
    padding: 0,
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: spacing(1),
    marginTop: spacing(1),
    "& p": {
      margin: 0,
    },
  },
  menuIcon: {
    minWidth: 30,
    marginLeft: spacing(2),
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 0,
  },
}));

export default function Tab({
  bullets,
  noContent,
  content,
  copyMultiple,
  copySingular,
  showCopyMultiple,
}: TabProps): JSX.Element {
  const classes = useStyles();
  const [anchorTarget, setAnchorTarget] = useState<HTMLElement | null>(null);
  const [items, setItems] = useState<Items>([]);
  const [item, setItem] = useState<Item>();
  const dispatch = useDispatch();

  const copyToast = (): void => {
    dispatch(
      setSnack({
        title: "Copied to clipboard",
      })
    );
  };

  const transformTitleToTestDataId = (title: string) => title.toLowerCase().replace(" ", "-");

  const handleClose = () => {
    setAnchorTarget(null);
    setItem(undefined);
    setItems([]);
  };

  const ItemMenu = () => (
    <Menu
      style={{ zIndex: zIndex.selectMenu }}
      data-testid="sentence-feedback"
      anchorEl={anchorTarget}
      open={anchorTarget !== null}
      onClose={handleClose}
    >
      {(items.length > 1 || showCopyMultiple) && (
        <MenuItem
          className={classes.menuItem}
          data-testid={transformTitleToTestDataId(copyMultiple.text)}
          onClick={() => {
            copyText(copyMultiple.handler(items));
            copyToast();
            handleClose();
          }}
          title={copyMultiple.tooltipText}
        >
          <Typography variant="inherit">{copyMultiple.text}</Typography>
          <ListItemIcon className={classes.menuIcon}>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      )}
      {!!item && (
        <MenuItem
          className={classes.menuItem}
          data-testid={transformTitleToTestDataId(copySingular.text)}
          onClick={() => {
            copyText(copySingular.handler(item));
            copyToast();
            handleClose();
          }}
          title={copySingular.tooltipText}
        >
          <Typography variant="inherit">{copySingular.text}</Typography>
          <ListItemIcon className={classes.menuIcon}>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      )}
    </Menu>
  );

  if (bullets?.length) {
    return (
      <>
        <ItemMenu />
        <ul className={classes.list}>
          {bullets.map((b, i) => (
            <li key={`${i}-${b}`}>
              <div className={classes.item}>
                <div>{b}</div>
                <AiIconButton
                  data-testid="tab-copy-menu"
                  onClick={(e) => {
                    setItem(b);
                    setItems(bullets);
                    setAnchorTarget(e.currentTarget);
                  }}
                  icon={<MoreVertIcon />}
                  size="small"
                />
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  } else if (content?.length) {
    return (
      <>
        <ItemMenu />
        {content.map((c, i) => (
          <div key={`${i}-${c}`} className={classes.item}>
            <p>{c}</p>
            <AiIconButton
              data-testid="tab-copy-menu"
              onClick={(e) => {
                setItem(c);
                setItems(content);
                setAnchorTarget(e.currentTarget);
              }}
              icon={<MoreVertIcon />}
              size="small"
            />
          </div>
        ))}
      </>
    );
  }
  return <>{noContent}</>;
}
