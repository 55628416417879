import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatList } from "../../../helpers/sentence";
import { Entity } from "../../../models/entity";
import { SentimentSentencesResponse } from "../../../models/sentiment";
import { resetState } from "../searchRequest/actions";

interface SentimentState {
  sentencesLoading: boolean;
  sentencesError: boolean;
  sentiment: SentimentSentencesResponse;
  sentimentSentencesFilters: Entity[];
}

const initialState: SentimentState = {
  sentencesLoading: false,
  sentencesError: false,
  sentimentSentencesFilters: [],
  sentiment: {
    positive: {
      total: 0,
      sentences: [],
    },
    negative: {
      total: 0,
      sentences: [],
    },
  },
};

const sentimentSlice = createSlice({
  name: "sentiment",
  initialState,
  reducers: {
    onSentimentSentencesLoading(state: SentimentState) {
      state.sentencesLoading = true;
      state.sentencesError = false;
    },
    onSentimentSentencesSuccess(state: SentimentState, action: PayloadAction<SentimentSentencesResponse>) {
      const sentiment = action.payload;
      state.sentencesLoading = false;
      state.sentencesError = false;
      formatList([...sentiment.negative.sentences, ...sentiment.positive.sentences]);

      state.sentiment = sentiment;
    },
    onSentimentSentencesError(state: SentimentState) {
      state.sentencesLoading = false;
      state.sentencesError = true;
      state.sentiment = initialState.sentiment;
    },
    addSentimentSentencesFilter(state: SentimentState, action: PayloadAction<Entity>) {
      state.sentimentSentencesFilters.push(action.payload);
    },
    removeSentimentSentencesFilter(state: SentimentState, action: PayloadAction<number>) {
      const filterIndex = action.payload;
      if (filterIndex > -1) {
        state.sentimentSentencesFilters.splice(filterIndex, 1);
      }
    },
  },
  extraReducers: {
    [resetState.type]: function () {
      return initialState;
    },
  },
});

export const {
  onSentimentSentencesError,
  onSentimentSentencesLoading,
  onSentimentSentencesSuccess,
  addSentimentSentencesFilter,
  removeSentimentSentencesFilter,
} = sentimentSlice.actions;

export default sentimentSlice.reducer;
