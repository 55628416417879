import { FormatQuote } from "@material-ui/icons";
import useDocumentQuotes from "../../../../../../hooks/useDocumentQuotes";
import useReaderStore from "../../../../../../store/hooks/useReaderStore";
import OverflowList from "../overflowList/OverflowList";
import OverviewSection from "./OverviewSection";

export default function Quotes() {
  const { selectedDocumentId } = useReaderStore((state) => state);
  const { data, isLoading } = useDocumentQuotes(selectedDocumentId as string);
  return (
    <OverviewSection isLoading={isLoading} icon={<FormatQuote />} title="Quotes">
      {data && <OverflowList fontStyle="italic" emptyText="No Quotes found" items={data} />}
    </OverviewSection>
  );
}
