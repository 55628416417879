import { FormControlLabel, makeStyles, Select, SelectProps, Theme } from "@material-ui/core";
import clsx from "clsx";
import { zIndex } from "../../../../../helpers/componentsZIndex";

interface SelectFieldProps {
  inDialog?: boolean;
}

const useStyles = makeStyles<Theme, SelectFieldProps>(({ spacing }) => ({
  label: ({ inDialog = false }) => ({
    zIndex: inDialog ? zIndex.keyboardDatePicker : undefined,
    margin: 0,
    marginTop: spacing(2),
    "& .MuiFormControlLabel-label": {
      marginBottom: spacing(1),
      marginRight: "auto",
    },
  }),
  fullWidth: {
    width: "100%",
  },
}));

export default function SelectField(props: SelectFieldProps & SelectProps) {
  const { label, className, style, inDialog, ...rest } = props;
  const classes = useStyles({ inDialog });

  return (
    <FormControlLabel
      style={style}
      className={clsx(classes.label, className, props.fullWidth && classes.fullWidth)}
      labelPlacement="top"
      label={label}
      control={<Select {...rest} MenuProps={{ style: { zIndex: zIndex.selectMenu } }} />}
    ></FormControlLabel>
  );
}
