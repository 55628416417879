import axios from "axios";
import { QueryClient, UseMutationResult, useQueryClient } from "react-query";
import { PageType } from "../../../components/Pages/Feed/components/filterMenu/feedFilters";
import { FeedResponse, PagedData, UserResponseFeedbackState } from "../../../http/feed";
import { FeedDocument, MonitoringDocument, MonitoringFeedbackState } from "../../../models/monitoring";
import useAPI from "../../queries/api/useAPI";
import { useInsightKey } from "../../queries/insights/useInsight";
import { InsightDocument, useInsightCountsKey, useInsightsKey } from "../../queries/insights/useInsights";
import { useFeedCountsKey, useFeedDocumentKey, useFeedkey } from "../../useFeed";
import useMutation from "../../useMutation";

type UserResponseTypes = Lowercase<MonitoringFeedbackState>;

export const transformFeedback = (userResponse: UserResponseTypes): MonitoringFeedbackState =>
  [userResponse.charAt(0).toUpperCase(), userResponse.slice(1)].join("") as MonitoringFeedbackState;

interface UseFeedbackStateArgs {
  amplyfiEntityId?: string;
  currentFilter: PageType;
  documentId: string;
  feedContent: string | null;
  response: UserResponseFeedbackState;
}

const onMutateFn =
  <T extends FeedDocument>(queryClient: QueryClient, feedKey: string) =>
  ({ currentFilter, documentId, response }: UseFeedbackStateArgs) => {
    const removeFromList = response === "discarded" || (response === "inbox" && currentFilter.id !== "inbox");

    queryClient.setQueriesData<PagedData<T>>([feedKey], (oldData) => {
      const data = oldData || queryClient.getQueryData<PagedData<T>>([feedKey], { exact: false });
      const newResponse: PagedData<T> = {
        ...data,
        pages: (data?.pages || []).map<FeedResponse<T>>((page) => ({
          ...page,
          documents: page.documents
            .map((doc) =>
              doc.documentId === documentId ? { ...doc, feedbackState: transformFeedback(response) } : doc
            )
            .filter((doc) => (removeFromList ? doc.documentId !== documentId : true)),
        })),
      };

      return newResponse;
    });
  };

const useMutationGen = <T extends FeedDocument>(
  queryClient: QueryClient,
  feedKey: string,
  libraryId: string,
  uri: string,
  invalidateQueries: string[] = []
): UseMutationResult<void, unknown, UseFeedbackStateArgs> =>
  useMutation<UseFeedbackStateArgs, void>(
    async ({ documentId, amplyfiEntityId, response, feedContent }) =>
      await axios.patch(uri, [{ path: "/feedbackState", value: response, op: "replace" }], {
        params: { libraryId, documentId, feedContent, entityId: amplyfiEntityId },
      }),
    invalidateQueries,
    { onMutate: onMutateFn<T>(queryClient, feedKey) }
  );

export const useAlertsFeedbackState = (libraryId: string): UseMutationResult<void, unknown, UseFeedbackStateArgs> =>
  useMutationGen<MonitoringDocument>(useQueryClient(), useFeedkey, libraryId, useAPI("v3", "alerts-feed"), [
    useFeedCountsKey,
    useFeedDocumentKey,
  ]);

export const useInsightsFeedbackState = (libraryId: string): UseMutationResult<void, unknown, UseFeedbackStateArgs> =>
  useMutationGen<InsightDocument>(useQueryClient(), useInsightsKey, libraryId, useAPI("v5", "insights-feed"), [
    useInsightCountsKey,
    useInsightKey,
  ]);
