import { Avatar, Theme, Tooltip, createStyles, makeStyles } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { getAmplyfiEntityLogo } from "../../../../helpers/imageHelpers";
import { getUserName, getUserPicture } from "../../../../helpers/user";
import useAIHistory from "../../../../hooks/useAIHistory";
import useUsers from "../../../../hooks/useUsers";
import EditableListItem from "../../Feed/components/EditableListItem";
import { goToEntityKnowledgeBaseEditor } from "../Routing";
import { AmplyfiUnknownEntity, EntityType } from "../models";

interface EntityListItemProps {
  entity: AmplyfiUnknownEntity;
  organisationName?: string;
  lastUpdated?: string;
  libraries?: string[];
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    item: {
      position: "relative",
    },
    avatars: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: spacing(2),
      marginRight: spacing(1),
    },
    lastUpdated: {
      fontStyle: "italic",
      color: "grey",
    },
    librariesList: {
      paddingLeft: spacing(2),
    },
  })
);

export function EntityListItem({
  entity: { createdAt, createdBy, id, name, type, updatedAt, updatedBy },
  organisationName,
  lastUpdated,
  libraries,
}: EntityListItemProps): JSX.Element {
  const classes = useStyles();
  const { push } = useAIHistory();
  const { data: { users = [] } = {} } = useUsers();

  return (
    <div className={classes.item}>
      <EditableListItem
        image={getAmplyfiEntityLogo(id)}
        key={id}
        onClick={() => push(goToEntityKnowledgeBaseEditor(id))}
        title={`${EntityType[type]}: ${name}`}
        description={organisationName ? `Created by ${organisationName}` : undefined}
      />

      {lastUpdated && <p className={classes.lastUpdated}>Last Updated: {lastUpdated}</p>}

      {libraries && libraries.length > 0 && (
        <div className={classes.librariesList}>
          <p>Libraries:</p>
          <ul>
            {libraries?.map((library: string, index: number) => (
              <li key={index}>{library}</li>
            ))}
          </ul>
        </div>
      )}

      <AvatarGroup className={classes.avatars}>
        {!!createdBy && (
          <Tooltip title={getUserName(createdBy, users)} key={`created-${createdAt}`}>
            <Avatar src={getUserPicture(createdBy, users)} />
          </Tooltip>
        )}
        {!!updatedBy && (
          <Tooltip title={getUserName(updatedBy, users)} key={`updated-${updatedAt}`}>
            <Avatar src={getUserPicture(updatedBy, users)} />
          </Tooltip>
        )}
      </AvatarGroup>
    </div>
  );
}
