import axios from "axios";
import { TopicsSearchResponse } from "../models/filter";
import { SearchRequestV2 } from "../models/search";
import { getAnalysisV2Url } from "./general";

export async function searchKeyPhrases(
  searchRequest: SearchRequestV2,
  searchTerm: string
): Promise<TopicsSearchResponse> {
  const searchResponse = await axios.post<TopicsSearchResponse>(
    getAnalysisV2Url(`search/keyphrases/${encodeURIComponent(searchTerm)}`),
    searchRequest
  );
  return searchResponse.data;
}
