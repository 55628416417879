import {
  CollectionConfig,
  getCollectionConfig,
  getCollectionConfigs,
  getCollectionConfigsForLibrary,
  getLibraryEntities,
} from "../http/harvesterCollections";
import useOrgId from "./useOrgId";
import { UseQueryResult, useQuery } from "./useQuery";

export const collectionConfigsKey = "collection-configs";

export function useCollectionConfigs() {
  return useQuery([collectionConfigsKey], getCollectionConfigs);
}

export function useCollectionConfig(configId: string | undefined) {
  return useQuery(["collection-config", configId], () => (configId ? getCollectionConfig(configId) : null));
}

export const collectionConfigsForLibraryKey = "library-collection-configs";

export function useCollectionConfigsForLibrary(libraryId: string | null): UseQueryResult<CollectionConfig[]> {
  const orgId = useOrgId();
  return useQuery([collectionConfigsForLibraryKey, libraryId], () =>
    libraryId ? getCollectionConfigsForLibrary(orgId, libraryId) : Promise.resolve([])
  );
}

export function useLibraryEntities(libraryId?: string) {
  const orgId = useOrgId();
  return useQuery(["library-entities", libraryId], () => (libraryId ? getLibraryEntities(orgId, libraryId) : []));
};

export function useHarvestLibraryEntities(organisationId?: string | undefined, libraryId?: string | undefined) {
  return useQuery(["library-entities", libraryId, organisationId], () =>
    (libraryId && organisationId ? getLibraryEntities(organisationId, libraryId) : Promise.resolve([]))
  );
}