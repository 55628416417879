import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { authorize } from "../../../../http/oauth";

export default function AuthorizationPage(): JSX.Element {
  const [, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    async function authenticate() {
      if (localStorage.getItem("connectorAuthUrl")) {
        let code = "";
        setLoading(true);

        const params = new URLSearchParams(window.location.search);
        if (params.has("code")) {
          code = params.get("code") as string;
        }

        if (location.hash.includes("#token")) {
          code = location.hash.split("#token=")[1];
        }

        const success = await authorize(code);
        setError(!success);
        setLoading(false);
      }
    }
    authenticate();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ flex: "1 1 auto", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 16 }}>
      <Typography variant="h3">
        {error ? "There was an error" : "Authentication complete. You will be redirected in a few seconds."}
      </Typography>
    </div>
  );
}
