import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    dot: {
        height: 20,
        width: 20,
        borderRadius: "50%",
        position: "relative",
    },
    strongSignal: {
        backgroundColor: "rgba(107, 194, 115, 0.15)",
        "&::after": {
            content: '""',
            position: "absolute",
            top: 5,
            left: 5,
            right: 5,
            bottom: 5,
            backgroundColor: "#6BC273",
            borderRadius: "50%",
        }
    }
}));

export const StrongSignalIcon = () => {
    const classes = useStyles();
    return <div className={`${classes.dot} ${classes.strongSignal}`} />;
};
