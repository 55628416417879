import AiIconButton from "@amplyfi/ui-components/components/IconButton";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { TestDataProps } from "@amplyfi/ui-components/types";
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { MoreHoriz as MoreHorizIcon } from "@material-ui/icons";
import { DetailedHTMLProps, Fragment, HTMLAttributes, ReactNode, useState } from "react";
import clsx from "clsx";
import { zIndex } from "../../../helpers/componentsZIndex";
import DotSeparator from "./Configuration/Components/DotSeparator";
import { useCanUserChangeFeed } from "../../../hooks/useFeedUserType";

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  actions: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
  },
  description: {
    fontSize: 12,
    color: palette.text.disabled,
  },
  icon: {
    fontSize: 16,
    marginRight: spacing(1),
    marginTop: 1,
    minWidth: "auto",
  },
  item: {
    display: "flex",
    fontSize: 12,
  },
  menu: {
    [breakpoints.up("sm")]: {
      maxWidth: "50%",
    },
    [breakpoints.up("md")]: {
      maxWidth: "33%",
    },
    zIndex: zIndex.selectMenu,
  },
  title: {
    minWidth: 1,
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
}));

interface HeaderAction<T> {
  dataTestId: string;
  description?: string;
  divider?: boolean;
  icon?: JSX.Element;
  onClick?: () => void;
  title: T;
  align?: "left" | "right";
  separator?: boolean;
  isEditorAction?: boolean;
  visible?: boolean;
}

interface ItemHeaderActionsProps
  extends Partial<TestDataProps>,
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  actions?: HeaderAction<string>[];
  libraryId: string;
  menuActions?: HeaderAction<ReactNode>[];
}

export default function ItemHeaderActions({
  actions,
  menuActions: displayMenuActions,
  "data-testid": dataTestId,
  className,
  libraryId,
  ...props
}: ItemHeaderActionsProps) {
  const classes = useStyles();
  const [actionTarget, setActionTarget] = useState<HTMLElement | null>(null);
  const canUserChangeFeed = useCanUserChangeFeed(libraryId);
  const handleClose = () => setActionTarget(null);

  const filter = <T extends HeaderAction<unknown>>(actions: T[] = []): T[] =>
    actions.filter(({ isEditorAction = false, visible = true }) => !!visible && (!isEditorAction || canUserChangeFeed));

  const menuActions = filter(displayMenuActions);

  return (
    <div className={clsx(classes.actions, className)} onClick={(e) => e.stopPropagation()} {...props}>
      {filter(actions).map(({ dataTestId, icon, align, onClick, title, separator }) => (
        <Fragment key={title}>
          {icon ? (
            <Tooltip
              key={`action-${dataTestId}`}
              PopperProps={{ style: { zIndex: zIndex.modalAndMenu } }}
              placement="top"
              title={title}
            >
              <IconButton style={{ marginRight: 16 }} size="small" onClick={onClick}>
                {icon}
              </IconButton>
            </Tooltip>
          ) : (
            <Typography
              style={{ marginRight: align === "left" ? "auto" : undefined, color: "#606060", fontSize: 12 }}
              key={`action-${dataTestId}`}
            >
              {title}
            </Typography>
          )}
          {separator && <DotSeparator />}
        </Fragment>
      ))}
      {menuActions?.length && (
        <>
          <AiIconButton
            data-testid="alert-actions"
            onClick={(e) => {
              setActionTarget(e.currentTarget);
            }}
            icon={<MoreHorizIcon />}
            size="small"
          />
          <Menu
            anchorEl={actionTarget}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            className={classes.menu}
            open={actionTarget !== null}
            onClose={handleClose}
          >
            {menuActions.map(({ dataTestId: tId, description, divider, icon, title, onClick }, i) => (
              <MenuItem
                className={classes.item}
                data-testid={testId(dataTestId, tId)}
                divider={divider && i !== menuActions.length - 1}
                key={`option-${tId}-${i}`}
                onClick={() => {
                  onClick?.();
                  handleClose();
                }}
              >
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                <div className={classes.title}>
                  <Typography>{title}</Typography>
                  {!!description && <Typography className={classes.description}>{description}</Typography>}
                </div>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
}
