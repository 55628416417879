import { call, CallEffect } from "redux-saga/effects";
import { getParsedQueryParams } from "../reducers/searchRequest/url-functions";
import { DocumentType } from "../../models/search";

/**
 * Calls a function if a condition is met
 * @param condition The condition which must be met
 * @param fn The fn to call
 * @param params The argument to the function
 */
export function* callIf<T extends Array<unknown>>(
  condition: boolean,
  fn: (...params: T) => void,
  ...params: T
): void | Generator<CallEffect<(...params: T) => never>> {
  if (condition) {
    return yield call(fn, ...params);
  }
}

export function shouldIncludeDocumentType(type: DocumentType): boolean {
  const { dataTypes } = getParsedQueryParams();
  return dataTypes.length === 0 || dataTypes.some((x) => x.name === type);
}
