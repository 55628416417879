import { Cluster, ClusterSearch } from "../components/Widgets/TopicWheel/types";
import { getClusterWithConnectorsFilter } from "../http/topicWheel";
import { useQuery, UseQueryResult } from "./useQuery";

export default function useCluster(
  searchId: string | undefined,
  maxClusterDepth: number,
  minClusterSize: number,
  connectorIds: string[],
  resultsLoading: boolean
): UseQueryResult<{ clusters: Cluster[] }> {
  return useQuery(
    ["topic-cluster", searchId, resultsLoading, connectorIds],
    async () => {
      if (!searchId) {
        return;
      }
      const cluster = await getClusterWithConnectorsFilter(searchId, connectorIds, maxClusterDepth, minClusterSize);
      return {
        clusters: cluster.clusters.map((d) => {
          return mapSearchCluster(d);
        }),
      };
    },
    {
      enabled: !resultsLoading && searchId !== undefined,
      retryDelay: 2000,
      retry: 3,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );
}

function mapSearchCluster(cluster: ClusterSearch): Cluster {
  return {
    children: cluster.children.map((c) => mapSearchCluster(c)),
    count: cluster.count,
    resultIds: cluster.ids,
    name: cluster.label,
    clusterId: "",
    clusterType: "",
  };
}
