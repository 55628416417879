import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, makeStyles } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { useState } from "react";
import { zIndex } from "../../../../../helpers/componentsZIndex";
import useDialogStore from "../../../../../store/hooks/useDialogStore";
import Stack from "../../components/stack/Stack";
import clsx from "clsx";
import useScreenSize from "../../../../../hooks/useScreenSize";
import { useFlags } from "launchdarkly-react-client-sdk";

interface ItemList {
    name: string;
}

export interface Action {
    dataTestId?: string;
    icon: JSX.Element;
    title: JSX.Element | string;
    list?: ItemList[];
    onClick?: () => void;
    dialog?: JSX.Element;
    divider?: boolean;
    forceMenuItem?: boolean | "mobile" | "desktop";
    disableRipple?: boolean;
    featureFlag?: string;
}

interface ActionHeaderProps {
    actions: Action[];
}

const useStyles = makeStyles(({ breakpoints }) => ({
    menu: {
        [breakpoints.up("sm")]: {
            maxWidth: "50%",
        },
        [breakpoints.up("md")]: {
            maxWidth: "33%",
        },
        zIndex: zIndex.selectMenu,
    },
    disableRipple: {
        backgroundColor: "transparent !important",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "transparent !important",
        },

    }
}));

function isVisible(screenSize: string, forceMenuItem: boolean | "mobile" | "desktop" | undefined) {
    if (forceMenuItem === "mobile") {
        return screenSize === "desktop";
    }
    if (forceMenuItem === "desktop") {
        return screenSize === "mobile";
    }
    return forceMenuItem === undefined;
}

export default function ActionHeader(props: ActionHeaderProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { setDialog } = useDialogStore((state) => state);
    const screenSize = useScreenSize();
    const flags = useFlags();
    const filteredActions = props.actions.filter(action => !action.featureFlag || flags[action.featureFlag]);
    const menuItems = filteredActions.filter(action => !isVisible(screenSize, action.forceMenuItem));
    const visibleItems = filteredActions.filter(action => isVisible(screenSize, action.forceMenuItem));
    return <Stack noWrap direction="horizontal" spacing={1}>
        {visibleItems.map((action, index) => {
            return (
                <Tooltip title={action.title}>
                    <IconButton className={clsx(action.disableRipple && classes.disableRipple)} key={index} onClick={(e) => {
                        e.stopPropagation();
                        action.onClick?.()
                    }}>
                        {action.icon}
                    </IconButton>
                </Tooltip>)
        })}
        <IconButton onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget)
        }}><MoreHoriz /></IconButton>
        {anchorEl && <Menu anchorOrigin={{ vertical: "bottom", horizontal: "right" }} className={classes.menu} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={!!anchorEl}>
            {menuItems.filter(item => item.list ? item.list.length > 0 : true).map(item => <MenuItem style={{ position: "relative" }} onClick={(e) => {
                e.stopPropagation();
                if (item.dialog) { setDialog(item.dialog as JSX.Element) } else { item.onClick?.() };
                setAnchorEl(null);
            }}>
                <ListItemIcon style={{ fontSize: 20 }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} secondary={(item.list || []).map(item => item.name).slice(0, 3).join(", ")} />
                {item.divider && <Divider style={{ position: "absolute", bottom: 0, left: 0, right: 0 }} />}
            </MenuItem>)}
        </Menu>}
    </Stack>
}