import { useAuth0 } from "../components/Auth0/AuthWrapper";
import { getOrgId } from "../helpers/user";
import { FeedDocumentCountResponse, FilterType, getAlertFeedHistoricFilterCount } from "../http/feed";
import { UseQueryResult, useQuery } from "./useQuery";

export const useFeedHistoricFilterCountskey = "historic-library-feed-filter-counts";

export function useFeedHistoricFilterCounts(
  libraryId: string,
  filterType: FilterType,
  filters: string[],
  historicDays: number
): UseQueryResult<Record<string, FeedDocumentCountResponse>> {
  const { user } = useAuth0();

  const now = new Date();
  now.setDate(now.getDate() - historicDays);
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth();
  const day = now.getUTCDate();
  const startDate = new Date(Date.UTC(year, month, day)).toISOString();

  return useQuery(
    [useFeedHistoricFilterCountskey, filterType, libraryId, ...filters],
    async () => {
      const counts = await Promise.all(
        filters.map((id) => getAlertFeedHistoricFilterCount(getOrgId(user), libraryId, filterType, id, startDate))
      );
      return counts.reduce((acc, { id, ...documentCount }) => ({ ...acc, [id]: documentCount }), {});
    },
    {
      staleTime: 0,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
    }
  );
}
