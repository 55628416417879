import { CircularProgress, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { analyticsEvent } from "../../helpers/analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    exportLink: {
      color: theme.palette.links.primary,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
  })
);

export default function ExportBaseButton(props: { onClick: () => void; loading: boolean; dataTestId?: string }): JSX.Element {
  const classes = useStyles();
  const { onClick, loading, dataTestId } = props;

  return (
    <Typography
      style={{ cursor: loading ? "initial" : "pointer" }}
      className={classes.exportLink}
      variant="h6"
      data-testid={dataTestId}
      onClick={() => {
        if (!loading) {
          analyticsEvent("Export", "ExportsClicked", "");
          onClick();
        }
      }}
    >
      {loading ? <CircularProgress /> : "Export"}
    </Typography>
  );
}
