import { create } from "zustand";

interface DialogStoreState {
    dialog: JSX.Element | null;
    setDialog: (dialog: JSX.Element | null) => void;
    removeDialog: () => void;
}

const useDialogStore = create<DialogStoreState>((set) => ({
    dialog: null,
    setDialog: (dialog) => set({ dialog }),
    removeDialog: () => set({ dialog: null }),
}));

export default useDialogStore;
