import { CanUserPermissionCheck, getPermissions, userPermission } from "../../../helpers/user";

export const feature = "intelligence";

const { read, create } = userPermission(feature);

export const canUserReadFeed: CanUserPermissionCheck = (user) => getPermissions(user).includes(read());

export const canUserAdministrateFeed: CanUserPermissionCheck = (user) =>
  getPermissions(user).includes(userPermission("feedAdmin").update());

export const canUserCreateLibrary: CanUserPermissionCheck = (user) => getPermissions(user).includes(create("library"));
