import { useHistory, useParams } from "react-router";
import {
  UrlParams, goToDashboard, goToFeed, goToLibrarySettingsTab, goToReport,
} from "../components/Pages/Feed/Routing";
import { FeedViewType, defaultFeedViews } from "../components/Pages/Feed/components/filterMenu/feedFilters";
import { ReaderPanel } from "../components/Pages/Feed/components/reader/Reader";
import { useFeedViewStore } from "../store/hooks/useFeedViewStore";
import usePageStore from "../store/hooks/usePageStore";
import useQuestionAnswerStore from "../store/hooks/useQuestionAnswerStore";
import useReaderStore from "../store/hooks/useReaderStore";
import { TabId } from "./useFeedSettingTabs";

// This retains the URL params when pushing
// we can expand on it if we need more advanced functionality
export default function useAIHistory() {
  const history = useHistory();
  const { libraryId: urlLibraryId, amplyfiEntityId: urlAmplyfiEntityId, resourceId, tab: urlTab = "signals" } = useParams<UrlParams>();

  const { libraryId, amplyfiEntityId, setLibraryId, setAmplyfiEntityId, setView, view } = useFeedViewStore();
  const { pages, addPage, reset } = usePageStore();

  const {
    setSelectedDocumentId,
    setSelectedPanel,
    setSelectedTab,
    setFromDeepLink,
    setSelectedReportId,
    setSelectedEntityId,
    setSelectedLibraryId,
  } = useReaderStore((state) => state);
  const { setDocumentIds } = useQuestionAnswerStore();

  function push(path: string) {
    history.push({ pathname: path, search: window.location.search });
  }

  const pushFeed = (_libraryId: string | null, _amplyfiEntityId: string | null, _tab?: string) => {
    setLibraryId(_libraryId);
    setAmplyfiEntityId(_amplyfiEntityId);
    setSelectedPanel(null);
    setSelectedDocumentId(null);
    setSelectedReportId(null);
    setFromDeepLink(false);
    setView(defaultFeedViews.find(v => v.route === _tab) || defaultFeedViews[0]);
    reset();

    if (!_libraryId) {
      push(goToFeed() + (_tab ? `/tab/${_tab}` : "/tab/signals"));
      return;
    }
    push(goToDashboard(_libraryId, _amplyfiEntityId, _tab));
  };

  return {
    ...history,
    push,
    pushFeed,
    initialize: () => {
      setLibraryId(urlLibraryId || null);
      setAmplyfiEntityId(urlAmplyfiEntityId || null);

      const documentId = resourceId && !urlTab.includes("reports") ? resourceId : null;
      setSelectedDocumentId(documentId);

      setSelectedLibraryId(urlLibraryId || null);
      setSelectedEntityId(urlAmplyfiEntityId || null);
      const reportId = resourceId && urlTab.includes("reports") ? resourceId : null;
      setSelectedReportId(reportId);
      setView(defaultFeedViews.find(v => v.route === urlTab) || defaultFeedViews[0]);
      if (documentId) {
        setFromDeepLink(true);
      }
      setSelectedPanel(documentId ? { id: ReaderPanel.Card } : null);
      if (reportId) {
        setSelectedPanel({ id: ReaderPanel.Report });
      }

      addPage({ id: "feed-list", title: "Feed" });
      reset();
    },
    pushOrganisation: (_libraryId: string | null, _amplyfiEntityId: string | null) => {
      setLibraryId(_libraryId);
      setAmplyfiEntityId(_amplyfiEntityId);
      setSelectedPanel(null);
      setSelectedDocumentId(null);
      setFromDeepLink(false);
      reset();

      if (!_libraryId) {
        push(goToFeed());
        return;
      }
      if (pages.length === 0) {
        pushFeed(_libraryId, _amplyfiEntityId);
      }
      push(goToDashboard(_libraryId, _amplyfiEntityId, "profile"));
    },
    pushDocument: (documentId: string | null, _overrideTab?: string) => {
      reset();
      if (!documentId) {
        setFromDeepLink(false);
      }
      setDocumentIds(documentId ? [documentId] : []);
      setSelectedDocumentId(documentId);

      if (documentId) {
        setSelectedPanel({ id: ReaderPanel.Card });
        setSelectedTab({ id: "overview" });
        if (amplyfiEntityId && libraryId) {
          push(`/feed/library/${libraryId}/interest/${amplyfiEntityId}/tab/${_overrideTab || view.route}/${documentId}`);
          return;
        }

        if (libraryId) {
          push(`/feed/library/${libraryId}/tab/${_overrideTab || view.route}/${documentId}`);
        } else {
          push(`/feed/tab/${_overrideTab || view.route}/${documentId}`);
        }
      } else {
        setSelectedPanel(null);
        setDocumentIds([]);
        if (amplyfiEntityId && libraryId) {
          push(`/feed/library/${libraryId}/interest/${amplyfiEntityId}/tab/${_overrideTab || view.route}`);
          return;
        }

        if (libraryId) {
          push(`/feed/library/${libraryId}/tab/${_overrideTab || view.route}`);
        } else {
          push(`/feed/library/tab/${_overrideTab || view.route}`);
        }
      }
    },
    pushReport: (_libraryId: string, _amplyfiEntityId: string, _reportId: string, _deepLink: boolean) => {
      reset();
      setSelectedLibraryId(_libraryId);
      setSelectedEntityId(_amplyfiEntityId);
      setSelectedPanel({ id: ReaderPanel.Report });
      setSelectedReportId(_reportId);
      setView({ ...useFeedViewStore.getState().view, type: FeedViewType.Reports });
      setSelectedDocumentId(null);
      setFromDeepLink(false);

      push(goToReport(libraryId, amplyfiEntityId, _reportId));
    },
    pushFeedSettings: (_libraryId: string, _settingsFeature?: TabId) => {
      setLibraryId(_libraryId);
      setAmplyfiEntityId(null);
      setSelectedDocumentId(null);
      setFromDeepLink(false);
      push(goToLibrarySettingsTab(_libraryId, _settingsFeature || "general"));
    },
    pushTab: (_tab: string) => {
      pushFeed(libraryId, amplyfiEntityId, _tab);
    }
  };
}
