import AnalyseCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { BabyBlue, MidGrey, White } from "@amplyfi/ui-components/theme/colors";
import { Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getPrototypes, Prototype } from "../../../../http/connectors";
import BasicSearch from "../../../Widgets/Search/BasicSearch";

interface ChooseConnectorPageProps {
  onSelected: (prototype: Prototype) => void;
  onCanProgressChanged: (canProgress: boolean) => void;
}

interface PrototypeItemProps extends Prototype {
  selected: boolean;
  onSelected: (prototypeId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wizardPage: {
      minHeight: 600,
    },
    chooseConnectorPage: {},
    prototypeListItem: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        background: MidGrey,
      },
      marginBottom: theme.spacing(1),
    },
    selectedPrototype: {
      background: BabyBlue,
      "&:hover": {
        background: BabyBlue,
      },
    },
    prototypeThumbnail: {
      height: 36,
      width: 36,
      objectFit: "contain",
      marginRight: theme.spacing(1),
      background: White,
    },
    hidden: {
      display: "none",
    },
    connectorList: {
      marginTop: theme.spacing(2),
    },
  })
);

function ConnectorPrototype(props: PrototypeItemProps): JSX.Element {
  const { selected, onSelected } = props;
  const styles = useStyles();
  const logoUrl = `${process.env.REACT_APP_PROTOTYPE_LOGO_URL}/prototype/${props.prototypeId}/logo.png`;
  return (
    <div
      onClick={() => onSelected(props.prototypeId)}
      className={clsx(styles.prototypeListItem, { [styles.selectedPrototype]: selected })}
    >
      <img alt="" className={styles.prototypeThumbnail} src={logoUrl} />
      <Typography variant="body2">{props.name}</Typography>
    </div>
  );
}

export default function ChooseConnectorPage(props: ChooseConnectorPageProps): JSX.Element {
  const styles = useStyles();
  const { onSelected, onCanProgressChanged } = props;
  const { data: prototypes, isLoading } = useQuery("prototypes", getPrototypes);
  const [selectedPrototype, setSelectedPrototype] = useState<Prototype>();
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (selectedPrototype) {
      onSelected(selectedPrototype);
      onCanProgressChanged(true);
    }
    // eslint-disable-next-line
  }, [selectedPrototype, onSelected]);

  useEffect(() => {
    onCanProgressChanged(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={clsx(styles.wizardPage, styles.chooseConnectorPage)}>
      {!isLoading ? (
        <>
          <Typography variant="h4">Choose a source</Typography>
          <BasicSearch defaultValue={search} onTextChange={(e) => setSearch(e)} fullWidth />
          <div className={styles.connectorList}>
            {prototypes
              ?.filter((proto: Prototype) => proto.name.toLowerCase().includes(search.toLowerCase()))
              .map((proto) => (
                <ConnectorPrototype
                  {...proto}
                  key={proto.prototypeId}
                  selected={proto.prototypeId === selectedPrototype?.prototypeId}
                  onSelected={(prototypeId) => setSelectedPrototype(proto)}
                />
              ))}
          </div>
        </>
      ) : (
        <AnalyseCircularProgress />
      )}
    </div>
  );
}
