import axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import { convertSearchStateToSearchRequestV3 } from "../helpers/search";
import { TimeIntervalType } from "../helpers/timeIntervals";
import { ElementType } from "../models/element";
import { LibrariesType } from "../models/queryParams";
import { SearchRequestV2, SearchRequestV3 } from "../models/search";
import { getAnalysisV3Url, getAnalysisV2Url } from "./general";
import { ParsedQueryParams } from "../models/queryParams";
import { BAR_CHARTS_ELEMENT_COUNT } from "../hooks/useElement";

async function getCSVExport(
  url: string,
  defaultName: string,
  searchRequest: SearchRequestV2,
  librariesTypes: LibrariesType[] | null = null
): Promise<void> {
  const searchResponse = await axios.post<File>(getAnalysisV2Url(url, librariesTypes), searchRequest);
  const exportName = searchRequest.systemFilters.exportFileName || defaultName;
  exportResponse(searchResponse, `${exportName}.csv`);
}

export async function getV3CSVExport(
  defaultName: string,
  url: ParsedQueryParams,
  dateInterval: TimeIntervalType,
  elementTypes: ElementType[],
  override?: Partial<SearchRequestV3>,
  isTimeline?: boolean
): Promise<void> {
  const searchRequest: SearchRequestV3 = {
    ...convertSearchStateToSearchRequestV3(url),
    ...override,
  };

  searchRequest.systemFilters = {
    ...searchRequest.systemFilters,
    dateInterval,
    size: override?.systemFilters?.customizations?.length ?? BAR_CHARTS_ELEMENT_COUNT,
    elementTypes: elementTypes,
  };
  const exportUrl = isTimeline ? "elements/timeline" : "elements/document-count";
  const searchResponse = await axios.post<File>(getAnalysisV3Url(exportUrl, url.libraries), searchRequest, {
    headers: { Accept: "text/csv" },
  });
  const exportName = searchRequest.systemFilters.exportFileName || defaultName;
  exportResponse(searchResponse, `${exportName}.csv`);
}

export function exportResponse(searchResponse: AxiosResponse<File>, exportName: string) {
  fileDownload(searchResponse.data, exportName);
}

export type ExportComponent = "timeline" | "sentimentTimeline" | "geo" | "sentimentBar";

const exportData: Record<ExportComponent, { url: string; name: string }> = {
  geo: {
    url: "search/geography/export",
    name: "DI-AnComp4_DOAM_1.0",
  },
  sentimentBar: {
    url: "search/sentiment/export",
    name: "DI-AnComp10_NSD_1.0",
  },
  sentimentTimeline: {
    url: "search/sentiment/timeline/export",
    name: "DI-AnComp11_SOT_1.0",
  },
  timeline: {
    url: "search/timeline/export",
    name: "DI-AnComp1_DAT",
  },
};

type ExportCollectionV2 = {
  [x in ExportComponent]: (req: SearchRequestV2, librariesTypes: LibrariesType[]) => Promise<void>;
};

export const exportFunctionsV2: ExportCollectionV2 = Object.entries(exportData).reduce(
  (accum, [key, value]) => ({
    ...accum,
    [key]: (req: SearchRequestV2, librariesTypes: LibrariesType[]) =>
      getCSVExport(value.url, value.name, req, librariesTypes),
  }),
  {} as ExportCollectionV2
);
