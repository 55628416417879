import { CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { link } from "../../../../css/mixins";
import useDocumentLibraries from "../../../../hooks/useDocumentLibraries";
import { LibraryItem } from "../../../../models/search/documentLibrary";
import { useAnalyseSelector } from "../../../../store/reducers";
import { clearDocumentLibraryId, updateDocumentLibraryId } from "../../../../store/reducers/searchRequest/urlReducer";
import { usePageStyles } from "../../../Pages/useLandingStyles";
import { DocumentLibrary } from "../DocumentLibrary/DocumentLibrary";
import { clearSelectedDocument } from "../../../../store/reducers/searchResponse/relatedDocumentViewerReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      listStyle: "none",
      margin: 0,
      padding: 0,
    },
    childLibraries: {
      marginLeft: theme.spacing(3),
    },
    libraries: {
      whiteSpace: "nowrap",
    },
    libraryContent: {
      maxHeight: 500,
      overflow: "auto",
    },
    deselectAll: {
      ...link(theme),
    },
  })
);

export default function DocumentLibraries(): JSX.Element {
  const pageClasses = usePageStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data = [], isLoading } = useDocumentLibraries();
  const { libraryIds } = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const handleCheckboxChange = (libraryId: string) => {
    dispatch(updateDocumentLibraryId(libraryId));
  };

  const partition = (ary: LibraryItem[], predicate: (x: LibraryItem) => boolean) =>
    ary.reduce<[LibraryItem[], LibraryItem[]]>(
      (acc, e) => {
        acc[predicate(e) ? 0 : 1].push(e);
        return acc;
      },
      [[], []]
    );
  const isGuid = (guid: string) => /^[{]?[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}[}]?$/.test(guid.toLowerCase());
  const [privateLibraries, topLevelLibraries] = partition(data, ({ id }) => isGuid(id));
  const privateLibraryCatchAll = "private";
  const hasPrivateCatchAll = topLevelLibraries.some(({ id }) => id === privateLibraryCatchAll);

  useEffect(() => {
    if (data.length > 0) {
      const availableLibraries = data.filter((x) => x.documentCount > 0).flatMap((x) => x.id);
      if (libraryIds.length === 0) {
        dispatch(updateDocumentLibraryId(availableLibraries));
      } else {
        dispatch(updateDocumentLibraryId(libraryIds.filter((libraryId) => availableLibraries.includes(libraryId))));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  const content = isLoading ? (
    <CircularProgress />
  ) : (
    <ol className={classes.list}>
      {topLevelLibraries.map((libraryItem) => (
        <li key={libraryItem.id} className={classes.libraries}>
          <DocumentLibrary
            checked={libraryIds.includes(libraryItem.id) && libraryItem.documentCount > 0}
            libraryItem={libraryItem}
            onChange={handleCheckboxChange}
          />
          {hasPrivateCatchAll && libraryItem.id === privateLibraryCatchAll ? (
            <ol className={classes.list}>
              {privateLibraries.map((privateLibrary) => (
                <li key={privateLibrary.id} className={clsx(classes.libraries, classes.childLibraries)}>
                  <DocumentLibrary
                    checked={libraryIds.includes(privateLibrary.id) && privateLibrary.documentCount > 0}
                    libraryItem={privateLibrary}
                    onChange={handleCheckboxChange}
                  />
                </li>
              ))}
            </ol>
          ) : null}
        </li>
      ))}
      {!hasPrivateCatchAll
        ? privateLibraries.map((privateLibrary) => (
            <li key={privateLibrary.id} className={classes.libraries}>
              <DocumentLibrary
                checked={libraryIds.includes(privateLibrary.id) && privateLibrary.documentCount > 0}
                libraryItem={privateLibrary}
                onChange={handleCheckboxChange}
              />
            </li>
          ))
        : null}
    </ol>
  );

  return (
    <div className={pageClasses.card}>
      <div className={pageClasses.cardTitle}>
        <Typography variant="h3">Document libraries</Typography>
        <Typography
          variant="h6"
          className={clsx(pageClasses.cardRight, libraryIds?.length && classes.deselectAll)}
          onClick={
            libraryIds?.length
              ? () => {
                  dispatch(clearDocumentLibraryId());
                  dispatch(clearSelectedDocument());
                }
              : undefined
          }
        >
          Deselect All
        </Typography>
      </div>
      <div className={clsx(pageClasses.cardContent, classes.libraryContent)}>{content}</div>
    </div>
  );
}
