import { create } from "zustand";
import { analyticsEvent } from "../../helpers/analytics";
import { pluraliseDays } from "../../helpers/textHelper";
import { useFeedViewStore } from "./useFeedViewStore";

export type TimeFilterDays = null | 1 | 7 | 30 | 90;
export type StrengthFilter = "Strong" | "Weak" | null;
export type EventsFilter = "Events" | "NonEvents" | null;

const DEFAULT_TIME_FILTER: TimeFilterDays = 7;
const DEFAULT_EVENTS: EventsFilter = null;
const DEFAULT_SORT = "Relevance";
const DEFAULT_SORT_INSIGHTS = "Newest";
const DEFAULT_STRENGTH: StrengthFilter = null;
const DEFAULT_REPORT_TYPE = "All";
const DEFAULT_SORT_REPORTS = "Newest";

interface FilterStoreState {
  clear: () => void;
  display: boolean;
  setDisplay: (display: boolean) => void;

  events: EventsFilter;
  setEvents: (events: EventsFilter) => void;

  signalTypes: string[];
  setSignalTypes: (signalTypes: string[]) => void;

  timeFilter: TimeFilterDays;
  setTimeFilter: (timeFilter: TimeFilterDays) => void;

  userTags: string[];
  setUserTags: (userTags: string[]) => void;

  selectedSort: string;
  setSorting: (selectedSort: string) => void;

  selectedSortInsights: string;
  setSelectedSortInsights: (selectedSort: string) => void;

  strength: StrengthFilter;
  setStrength: (strength: StrengthFilter) => void;

  selectReportType: string;
  setSelectReportType: (reportType: string) => void;

  accordionExpandedStates: Record<string, boolean>;
  setAccordionExpanded: (id: string, isExpanded: boolean) => void;

  sortDateReports: string;
  setSortDateReports: (sortingOption: string) => void;

  sectors: string[];
  setSectors: (sectors: string[]) => void;

  sources: string[];
  setSources: (sources: string[]) => void;

  topics: string[];
  setTopics: (topics: string[]) => void;
}

export const useFiltersStore = create<FilterStoreState>((set) => ({
  clear: () => set({
    timeFilter: DEFAULT_TIME_FILTER,
    selectedSort: DEFAULT_SORT,
    selectedSortInsights: DEFAULT_SORT_INSIGHTS,
    events: DEFAULT_EVENTS,
    signalTypes: [],
    strength: DEFAULT_STRENGTH,
    userTags: [],
    sectors: [],
    sources: [],
    topics: [],
  }),
  display: false,
  setDisplay: (display) => set({ display }),

  events: DEFAULT_EVENTS,
  setEvents: (events) => set({ events }),

  signalTypes: [],
  setSignalTypes: (signalTypes) => {
    analyticsEvent("Feed", "FilterByAlertType", signalTypes);
    return set({ signalTypes });
  },

  timeFilter: DEFAULT_TIME_FILTER,
  setTimeFilter: (timeFilter) => {
    analyticsEvent("Feed", "FilterByTime", timeFilter ? pluraliseDays(timeFilter) : "All time");
    return set({ timeFilter });
  },

  userTags: [],
  setUserTags: (userTags) => set({ userTags }),

  selectedSort: DEFAULT_SORT,
  setSorting: (selectedSort) => {
    analyticsEvent("Feed", "FilterSort", selectedSort);
    return set({ selectedSort });
  },

  selectedSortInsights: DEFAULT_SORT_INSIGHTS,
  setSelectedSortInsights: (selectedSortInsights) => {
    analyticsEvent("Feed", "FilterSort", selectedSortInsights);
    return set({ selectedSortInsights });
  },

  strength: DEFAULT_STRENGTH,
  setStrength: (strength) => set({ strength: strength ?? null }),

  selectReportType: DEFAULT_REPORT_TYPE,
  setSelectReportType: (reportType) => set({ selectReportType: reportType }),

  accordionExpandedStates: {},
  setAccordionExpanded: (id, isExpanded) =>
    set((state) => ({
      accordionExpandedStates: {
        ...state.accordionExpandedStates,
        [id]: isExpanded,
      },
    })),

  sortDateReports: DEFAULT_SORT_REPORTS,
  setSortDateReports: (sortingOption) => set({ sortDateReports: sortingOption }),

  sectors: [],
  setSectors: (sectors) => set({ sectors }),

  sources: [],
  setSources: (sources) => set({ sources }),

  topics: [],
  setTopics: (topics) => set({ topics }),
}));

export const clearViewTypeSpecificFilters = (): void => {
  useFiltersStore.getState().clear();
};

export const areAnyUserTagFiltersSet = (): boolean => !!useFiltersStore.getState().userTags.length;

export const clearAllFilters = (): void => {
  clearViewTypeSpecificFilters();
  useFeedViewStore.getState().clearFavourite();
};

export const activeFilterCount = (): number => {
  const { view } = useFeedViewStore.getState();
  const { events, signalTypes, strength, userTags, sectors, sources, topics } = useFiltersStore.getState();
  const filterCount = [
    signalTypes.length,
    userTags.length,
    events !== null ? 1 : 0,
    sources.length,
    topics.length,
    strength !== null ? 1 : 0,
    view.id === "favourites" ? 1 : 0,
    sectors.length,
    sources.length,
  ];

  return filterCount.reduce((acc, current) => acc + current, 0);
};
