import { EntityType } from "./entity";

export type ElementType = "keyphrase" | "organisation" | "person" | "settlement" | "country";

export interface ElementResponse {
  elements: Element[];
}

export interface Element {
  name: string;
  documentCount: number;
  id: string;
  type: EntityType;
}

export interface ElementDataResponse {
  totalElementCount: number;
  totalPageCount: number;
  elements: ElementData[];
}

export interface ElementData {
  documentCount: number;
  mentionsCount: number;
  changeOfCount: number;
  name: string;
  id: string;
  [key: string]: string | number | number[];
}

export interface ColumnItem {
  Header?: string;
  accessor: string;
  width?: number;
  // Should only be set when the value is a number
  valueIndicator?: boolean;

  sortDescFirst?: boolean;
  disableSortBy?: boolean;
}

export const defaultOptions = {
  sortDescFirst: true,
  width: 180,
};

export const allElementType = ["person", "organisation", "keyphrase", "country", "settlement"] as ElementType[];

export const hiddenColumns: string[] = ["id", "type"];

export const defaultColumnOrder: string[] = ["name", "documentCount", "mentionsCount"];

export const columnMetaData: ColumnItem[] = [
  {
    ...defaultOptions,
    // The string to display
    Header: "Name",
    // The property id
    accessor: "name",
    width: 240,
    disableSortBy: true,
  },
  {
    ...defaultOptions,
    accessor: "documentCount",
  },
  {
    ...defaultOptions,
    Header: "Mention Count",
    accessor: "mentionsCount",
  },
  {
    ...defaultOptions,
    accessor: "documentCountTrends",
    disableSortBy: true,
  },
  {
    ...defaultOptions,
    accessor: "documentCountVelocity",
    disableSortBy: true,
  },
  {
    ...defaultOptions,
    accessor: "sentimentTrend",
    disableSortBy: true,
  },
  {
    ...defaultOptions,
    accessor: "sentimentVelocity",
    disableSortBy: true,
  },
  {
    ...defaultOptions,
    accessor: "averageSentiment",
    valueIndicator: true,
  },
];
