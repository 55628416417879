import EntityTooltip from "@amplyfi/ui-components/components/Tooltip/EntityTooltip";
import { MidBlue } from "@amplyfi/ui-components/theme/colors";
import { ClickAwayListener } from "@material-ui/core";
import { BarDatum, BarExtendedDatum, ResponsiveBarCanvas } from "@nivo/bar";
import React, { useCallback, useEffect, useState } from "react";
import { FullScreenHandle } from "react-full-screen";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../../helpers/analytics";
import { convertSearchStateToSearchRequestV2 } from "../../../helpers/search";
import { getTooltip, Tooltip } from "../../../http/tooltip";
import { Entity, EntityType } from "../../../models/entity";
import { FilterType } from "../../../models/filter";
import { QueryType } from "../../../models/query";
import { ParsedQueryParams } from "../../../models/queryParams";
import { DocumentType, TooltipType } from "../../../models/search";
import { useAnalyseSelector } from "../../../store/reducers";
import { getUpdateUrlSearch, resetAllParams } from "../../../store/reducers/searchRequest/url-functions";
import { addQuery } from "../../../store/reducers/searchRequest/urlReducer";
import {
  onSetOpenCloseDrawer,
  viewRelatedDocuments,
} from "../../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import { elementTypeMapColor } from "../../Charts/CustomizationModal/helpers/entityTypeMapColor";

interface BarData {
  id: string;
  index: number;
  name: string;
  type: EntityType;
  value: number;
}

interface Point {
  id: string;
  value: number;
  index: number;
  indexValue: string;
  data: BarDatum;
  x?: number;
  y?: number;
}

interface BarChartProps {
  data: BarData[];
  leftOffset: number;
  height: number;
  fullScreenHandle?: FullScreenHandle;
}

const theme = {
  axis: {
    ticks: {
      text: {
        fontSize: 13,
        fill: MidBlue,
      },
    },
  },
};

const BAR_CHART_CONTAINER_HEIGHT = 400;

export default function BarChart(props: BarChartProps): JSX.Element {
  const { data, leftOffset, height, fullScreenHandle } = props;
  const [selectedPoint, setSelectedPoint] = useState<Point | null>(null);
  const nivoLineChart = React.useRef<HTMLDivElement | null>(null);
  const [tooltip, setTooltip] = useState<Tooltip>();
  const [workbookOrUrlParsed, setWorkbookOrUrlParams] = useState<ParsedQueryParams | null>(null);
  const urlStateParsed = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onScrollHideTooltip = useCallback(() => {
    // removing tooltip when scrolled
    if (selectedPoint) {
      onClose();
    }
  }, [selectedPoint]);

  useEffect(() => {
    // clean up code
    window.removeEventListener("scroll", onScrollHideTooltip);
    window.addEventListener("scroll", onScrollHideTooltip, { passive: true });
    return () => window.removeEventListener("scroll", onScrollHideTooltip);
  }, [onScrollHideTooltip]);

  useEffect(() => {
    setWorkbookOrUrlParams(urlStateParsed);
  }, [urlStateParsed]);

  const onClose = () => {
    setTooltip(undefined);
    setSelectedPoint(null);
  };

  const exitFullScreen = () => {
    if (fullScreenHandle?.active) {
      fullScreenHandle.exit();
    }
  };

  const getTooltipInfo = async (entity: BarExtendedDatum) => {
    setLoading(true);

    const request = convertSearchStateToSearchRequestV2(workbookOrUrlParsed as ParsedQueryParams);

    const tooltip = await getTooltip(
      request,
      {
        id: entity.data.id.toString(),
        name: entity.data.name.toString(),
        type: entity.data.type !== EntityType.KeyPhrase ? (entity.data.type as EntityType) : undefined,
      },
      entity.data.type === EntityType.KeyPhrase ? TooltipType.KeyPhrase : undefined,
      urlStateParsed.libraries
    );
    setTooltip(tooltip);
    setLoading(false);
  };

  const getEntity = () => {
    if (selectedPoint) {
      return {
        id: selectedPoint.data.id,
        index: selectedPoint.data.index ?? 0,
        name: selectedPoint.data.name,
        type: selectedPoint.data.type !== EntityType.KeyPhrase ? selectedPoint.data.type : undefined,
      };
    }
    return null;
  };

  const onViewRelatedDocuments = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const entity = getEntity() as Entity;
    if (entity && selectedPoint) {
      dispatch(
        viewRelatedDocuments({
          entities: [entity],
          type: DocumentType.News,
          tooltipType: selectedPoint.data.type === EntityType.KeyPhrase ? TooltipType.KeyPhrase : undefined,
        })
      );
      onClose();
      exitFullScreen();
      dispatch(onSetOpenCloseDrawer(true));
    }
  };

  const onReplaceSearch = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const entity = getEntity() as Entity;
    if (entity) {
      const parsedURL: Partial<ParsedQueryParams> = {
        ...resetAllParams(),
        all: [entity],
        libraries: urlStateParsed.libraries,
      };
      const updatedURL = getUpdateUrlSearch(parsedURL);
      onClose();
      window.open(`/analyse/overview?${updatedURL}`, "_blank");
      exitFullScreen();
      analyticsEvent("TooltipSearch", "ReplacedSearch", `${entity.name}-${entity.id}`);
    }
  };

  const onRefineSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const entity = getEntity() as Entity;
    if (entity) {
      dispatch(addQuery({ entity, type: QueryType.AllQueries }));
      onClose();
      exitFullScreen();
      analyticsEvent("TooltipSearch", "RefinedSearch", `${entity.name}-${entity.id}`);
    }
  };

  return (
    <div
      style={{
        maxHeight: fullScreenHandle?.active ? "75vh" : BAR_CHART_CONTAINER_HEIGHT,
        minHeight: BAR_CHART_CONTAINER_HEIGHT > height ? height + 10 : BAR_CHART_CONTAINER_HEIGHT,
        overflow: "auto",
      }}
      onScroll={onScrollHideTooltip}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div style={{ height: height, position: "relative" }} ref={nivoLineChart}>
          <ResponsiveBarCanvas
            enableGridX
            enableGridY={false}
            colors={(bar) => elementTypeMapColor((bar.data as BarData).type)}
            margin={{ top: 0, right: 16, bottom: 0, left: leftOffset }}
            padding={0.45}
            labelSkipWidth={12}
            labelSkipHeight={12}
            layout="horizontal"
            valueScale={{ type: "linear", clamp: true, nice: true }}
            indexScale={{ type: "band", round: false }}
            enableLabel={false}
            indexBy="id"
            data={data}
            axisBottom={{
              tickValues: 11,
            }}
            axisLeft={{
              format: (id) => data.find((d) => d.id === id)?.name.replace(/(.{30})..+/, "$1…"),
            }}
            theme={theme}
            isInteractive={false}
            onClick={(point, event) => {
              event.persist();
              if (nivoLineChart.current) {
                setTooltip(undefined);
                const tempPoint = { ...point } as Point;
                const rect = nivoLineChart.current.getBoundingClientRect();
                tempPoint.x = Math.abs(event.clientX - rect.left);
                tempPoint.y = Math.abs(event.clientY - rect.top);
                setSelectedPoint(tempPoint);
                getTooltipInfo(point);
              }
            }}
          />
          {selectedPoint && (
            <div
              style={{
                left: `${selectedPoint?.x || 0}px`,
                top: `${selectedPoint?.y || 0}px`,
                position: "absolute",
              }}
            >
              <EntityTooltip
                title={selectedPoint.data.name}
                filterType={FilterType.Keywords}
                tooltipLoading={loading}
                open={!!selectedPoint}
                tooltip={tooltip}
                onViewRelatedDocuments={onViewRelatedDocuments}
                onReplaceSearch={onReplaceSearch}
                onRefineSearch={onRefineSearch}
              >
                <div></div>
              </EntityTooltip>
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
}
