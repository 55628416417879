import { FormControlLabel } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AnalyseTooltip from "@amplyfi/ui-components/components/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import React from "react";
import AmplyfiCheckbox from "../../AmplyfiCheckbox";
import { friendlyNumber } from "../../../../helpers/friendlyNumber";
import { LibraryItem } from "../../../../models/search/documentLibrary";

interface DocumentLibraryProps {
  checked: boolean;
  libraryItem: LibraryItem;
  onChange: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      cursor: "pointer",
      marginLeft: theme.spacing(0.5),
      verticalAlign: "middle",
    },
    libraryLabel: {
      padding: "0.25rem 0",
      margin: 0,
      userSelect: "none",
    },
  })
);

export const DocumentLibrary = ({
  checked,
  onChange,
  libraryItem: { documentCount, description, name, id },
}: DocumentLibraryProps): JSX.Element => {
  const classes = useStyles();
  const friendly = friendlyNumber(documentCount);
  const isRounded = parseInt(friendly, 10) !== documentCount;

  return (
    <>
      <FormControlLabel
        className={classes.libraryLabel}
        value="end"
        disabled={documentCount === 0}
        control={<AmplyfiCheckbox checked={checked} onChange={() => onChange(id)} />}
        label={`${name} (${isRounded ? "~" : ""}${friendly})`}
        labelPlacement="end"
      />
      <AnalyseTooltip arrow title={description} placement="bottom-end">
        <InfoIcon className={classes.icon} />
      </AnalyseTooltip>
    </>
  );
};
