import AiButton from "@amplyfi/ui-components/components/Button";
import AnalyseCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Negative } from "@amplyfi/ui-components/theme/colors";
import {
  Collapse,
  darken,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Settings, Warning } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { areConnectorsLoading } from "../../../helpers/connectors";
import { getWebsiteLogo } from "../../../helpers/imageHelpers";
import useConnectorStatus from "../../../hooks/useConnectorStatus";
import {
  Connector,
  ConnectorAuthentication,
  ConnectorGroup,
  ConnectorStatus,
  customLogoIds,
  getConnectorAuthStatus,
  getConnectorGroups,
  getConnectors,
  SearchResult,
} from "../../../http/connectors";
import { login } from "../../../http/oauth";
import AmplyfiCheckbox from "../AmplyfiCheckbox";

interface ConnectorListProps {
  searchId: string;
  onSelectedConnectorsChanged: (selectedConnectors: string[], allConnectorsInGroup: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    connectorListContainer: {
      position: "sticky",
      top: 0,
      minWidth: 300,
      maxWidth: 400,
      flex: "1 1 auto",
    },
    connectorList: {
      maxHeight: 600,
      overflowY: "auto",
      marginBottom: theme.spacing(1),
    },
    connectorGroupListItem: {
      marginRight: theme.spacing(2),
      borderBottom: "1px solid #E8E8E8",
      width: `calc(100% - ${theme.spacing(2)}px)`,
    },
    expanded: {
      borderBottom: 0,
    },
    collapseElement: {
      borderBottom: "1px solid #E8E8E8",
      width: `calc(100% - ${theme.spacing(2)}px)`,
    },
    connectorListItem: {
      display: "flex",
      position: "relative",
      height: 60,
      alignItems: "center",
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(1),

      "&:hover": {
        background: darken(theme.palette.componentBackground.main, 0.1),
        cursor: "pointer",
      },
    },
    connectorListIcon: {
      height: 36,
      width: 36,
      objectFit: "contain",
    },
    connectorListTitle: {
      marginLeft: theme.spacing(1),
      flex: "1 1 auto",
    },
    itemLoadingIndicator: {
      position: "absolute",
      right: 0,
    },
    disabledCheckbox: {
      border: `2px solid ${Negative}`,
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
    selected: {
      background: darken(theme.palette.componentBackground.main, 0.1),
    },
  })
);

function logo(props: { prototypeId: string; logoDomain: string }): string | undefined {
  const { prototypeId, logoDomain } = props;
  const logo = customLogoIds.includes(prototypeId)
    ? getWebsiteLogo(logoDomain)
    : `${process.env.REACT_APP_PROTOTYPE_LOGO_URL}/prototype/${prototypeId}/logo.png`;
  return logo;
}

// return all connectors that are in the same group as selected connectors
function getAllConnectorsInGroup(selectedConnectors: string[], allConnectors: Connector[]): string[] {
  const groupId = allConnectors.find((c) => selectedConnectors.includes(c.connectorId))?.connectorGroupId || "";
  const selectedConns = allConnectors.filter((group) => group.connectorGroupId === groupId);
  return selectedConns.map((group) => group.connectorId);
}

interface ConnectorGroupListItemProps extends ConnectorGroup {
  selectedConnectors: string[];
  connectorStatuses: SearchResult | undefined;
  onSelect: (connectorIds: string[]) => void;
}

function getGroupResultCount(breakdown: { [key: string]: number } | undefined, allowedIds: string[]): number | string {
  if (!breakdown) {
    return "";
  }

  return Object.keys(breakdown)
    .filter((c) => allowedIds.includes(c))
    .map((c) => breakdown[c])
    .reduce((accum, val) => accum + val, 0);
}

function ConnectorGroupListItem(props: ConnectorGroupListItemProps) {
  const { name, connectors, onSelect, selectedConnectors, connectorStatuses } = props;
  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ListItem className={clsx(styles.connectorGroupListItem, expanded && styles.expanded)} disableGutters>
        <div style={{ width: 30, height: 30 }}>
          {areConnectorsLoading(connectorStatuses) && (
            <AnalyseCircularProgress useContainer={false} size={30} className={styles.itemLoadingIndicator} />
          )}
        </div>
        <AmplyfiCheckbox
          onChange={() => {
            if (connectors.every((c) => selectedConnectors.includes(c.connectorId))) {
              onSelect(selectedConnectors.filter((c) => !connectors.map((conn) => conn.connectorId).includes(c)));
            } else {
              onSelect(connectors.map((c) => c.connectorId));
            }
          }}
          checked={connectors.every((c) => selectedConnectors.includes(c.connectorId))}
        />
        <ListItemText
          disableTypography
          primary={
            <Typography variant="body1" style={{ fontWeight: "bold", maxWidth: 220 }}>
              {name}
            </Typography>
          }
        />
        <ListItemText style={{ textAlign: "right" }}>
          {getGroupResultCount(
            connectorStatuses?.connectorBreakdown,
            connectors.map((c) => c.connectorId)
          )}
        </ListItemText>
        <div style={{ width: 46, height: 46 }}>
          <ListItemIcon onClick={() => setExpanded(!expanded)}>
            <IconButton>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </ListItemIcon>
        </div>
      </ListItem>
      <Collapse className={styles.collapseElement} in={expanded}>
        <List style={{ marginLeft: 16 }} disablePadding>
          {connectors.map((conn) => (
            <ConnectorListItem
              {...conn}
              selected={selectedConnectors.includes(conn.connectorId)}
              key={conn.connectorId}
              count={connectorStatuses ? connectorStatuses.connectorBreakdown[conn.connectorId] : ""}
              isAuthorized={() =>
                conn.authenticationType === ConnectorAuthentication.OAuth ||
                conn.authenticationType === ConnectorAuthentication.SharedOAuth ||
                conn.authenticationType === ConnectorAuthentication.AuthToken
                  ? getConnectorAuthStatus(conn.connectorId)
                  : Promise.resolve(true)
              }
              icon={conn.logoDomain}
              isSearching={connectorStatuses?.connectorCompletions[conn.connectorId] === false}
              status={connectorStatuses?.connectorStatuses[conn.connectorId] || ConnectorStatus.Idle}
              onSelect={() => onSelect([conn.connectorId])}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
}

function ConnectorListItem(props: {
  connectorId: string;
  name: string;
  count: number | string;
  icon: string;
  selected: boolean;
  onSelect: () => void;
  isAuthorized: () => Promise<boolean>;
  authenticationType: ConnectorAuthentication | string;
  prototypeId: string;
  logoDomain: string;
  isSearching: boolean;
  status: ConnectorStatus;
}) {
  const {
    connectorId,
    name,
    count,
    onSelect,
    isAuthorized,
    authenticationType,
    prototypeId,
    logoDomain,
    isSearching,
    status,
    selected,
  } = props;
  const [isAuth, setIsAuth] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const styles = useStyles();

  useEffect(() => {
    async function setAuth() {
      setAuthLoading(true);
      setIsAuth(await isAuthorized());
      setAuthLoading(false);
    }

    setAuth();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      onClick={() => {
        if (status === ConnectorStatus.Error) {
          return;
        }

        if (isAuth || authenticationType === ConnectorAuthentication.None) {
          onSelect();
        }
      }}
      className={clsx(styles.connectorListItem, selected && styles.selected)}
    >
      {/* eslint-disable-next-line */}
      {isAuth || authenticationType === ConnectorAuthentication.None ? (
        <>
          <div style={{ width: 24, height: 24 }}>
            {isSearching && (
              <AnalyseCircularProgress useContainer={false} size={24} className={styles.itemLoadingIndicator} />
            )}
          </div>
        </>
      ) : authLoading ? (
        <AnalyseCircularProgress useContainer={false} size={30} className={styles.itemLoadingIndicator} />
      ) : (
        <Tooltip title="This connector is not authenticated. Click here to authenticate.">
          <IconButton onClick={() => login(connectorId)}>
            <Warning />
          </IconButton>
        </Tooltip>
      )}
      <img className={styles.connectorListIcon} src={logo({ prototypeId, logoDomain })} alt="" />
      <Typography className={styles.connectorListTitle} variant="body1">
        {name}
      </Typography>
      <Typography
        color={status === ConnectorStatus.Error ? "error" : "initial"}
        style={{ marginLeft: "auto", width: 20 }}
        variant="body2"
      >
        {status === ConnectorStatus.Error ? "Error" : count}
      </Typography>
    </div>
  );
}

export default function ConnectorGroupList(props: ConnectorListProps): JSX.Element {
  const { onSelectedConnectorsChanged, searchId } = props;
  const styles = useStyles();
  const history = useHistory();

  const { data: allConnectors, isLoading: connectorsLoading } = useQuery("connectors", getConnectors);
  const { data: connectors, isLoading } = useQuery(["connector-groups", allConnectors], getConnectorGroups);
  const [selectedConnectors, setSelectedConnectors] = useState<string[]>([]);

  useEffect(() => {
    setSelectedConnectors(
      allConnectors
        ?.filter(({ connectorGroupId }) => connectorGroupId === connectors?.[0].groupId)
        .map(({ connectorId }) => connectorId) || []
    );
  }, [connectors, allConnectors]);

  const { data: resultCount } = useConnectorStatus(searchId, allConnectors?.map((c) => c.connectorId) || []);

  // filter out connectors from connectorBreakdown that are not in the list of connectors
  function filterConnectors(
    connectorBreakdown: { [key: string]: boolean | number | ConnectorStatus },
    connectorIds: string[]
  ) {
    const filteredBreakdown: { [key: string]: boolean | number | ConnectorStatus } = {};
    Object.keys(connectorBreakdown).forEach((key) => {
      if (connectorIds.some((c) => c === key)) {
        filteredBreakdown[key] = connectorBreakdown[key];
      }
    });
    return filteredBreakdown;
  }

  const filterToSelectedConnectors = (
    selectedConnectors: string[],
    resultCount?: SearchResult
  ): undefined | SearchResult => {
    if (resultCount) {
      return {
        ...resultCount,
        connectorBreakdown: filterConnectors(
          resultCount.connectorBreakdown,
          getAllConnectorsInGroup(selectedConnectors, allConnectors || [])
        ),
        connectorCompletions: filterConnectors(resultCount.connectorCompletions, selectedConnectors),
        connectorStatuses: filterConnectors(resultCount.connectorStatuses, selectedConnectors),
      } as SearchResult;
    }
    return resultCount;
  };

  useEffect(() => {
    onSelectedConnectorsChanged(selectedConnectors, getAllConnectorsInGroup(selectedConnectors, allConnectors || []));
    // eslint-disable-next-line
  }, [selectedConnectors]);

  if (isLoading || connectorsLoading) {
    return (
      <div className={styles.connectorList}>
        <Skeleton height={60} count={6} />
      </div>
    );
  }

  return (
    <div className={styles.connectorListContainer}>
      <List className={styles.connectorList}>
        {connectors?.map((connector) => (
          <ConnectorGroupListItem
            {...connector}
            key={connector.groupId}
            connectorStatuses={filterToSelectedConnectors(selectedConnectors, resultCount)}
            selectedConnectors={selectedConnectors}
            connectors={allConnectors?.filter((c) => c.connectorGroupId === connector.groupId) || []}
            onSelect={(connectorIds) => setSelectedConnectors(connectorIds)}
          />
        ))}
      </List>
      <AiButton
        style={{ border: "0" }}
        amplyfiType="secondary"
        startIcon={<Settings />}
        onClick={() => history.push("/connectors")}
      >
        Source Groups
      </AiButton>
    </div>
  );
}
