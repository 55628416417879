import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

interface EmptyStateProps {
  title: string;
  description?: string;
  image?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      maxHeight: 300,
    },
  })
);

export default function EmptyState(props: EmptyStateProps): JSX.Element {
  const { title, description, image } = props;
  const styles = useStyles();
  return (
    <div style={{ marginTop: 8 }}>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body2">{description}</Typography>
      {image && <img className={styles.image} src={image} alt="" />}
    </div>
  );
}
