import { create } from "zustand";
import {
  FeedViewType,
  PageType,
  defaultFeedViews,
  signalsPage
} from "../../components/Pages/Feed/components/filterMenu/feedFilters";
import { updateUrl } from "../reducers/searchRequest/url-functions";
import { clearAllFilters, clearViewTypeSpecificFilters } from "./useFiltersStore";

interface FeedViewStoreState {
  clearFavourite: () => void;
  isFavourite: boolean;
  setFavorite: (isFavourite: boolean) => void;
  view: PageType;
  setView: (view: PageType) => void;
  blockedSectorId: string[];
  blockedTopicId: string[];

  libraryId: string | null;
  amplyfiEntityId: string | null;

  setLibraryId: (libraryId: string | null) => void;
  setAmplyfiEntityId: (amplyfiEntityId: string | null) => void;
  addBlockedSectorById: (sector: string) => void;
  addBlockedTopicById: (topic: string) => void;
}

const updateFilterParam = (view: PageType) => {
  updateUrl({ filter: view.id === "inbox" && view.type === FeedViewType.Insight ? "insights" : view.id });
};

export const useFeedViewStore = create<FeedViewStoreState>((set, get) => ({
  clearFavourite: () => {
    const { view: currentView } = get();
    const view = defaultFeedViews.find((f) => f.type === currentView.type) as PageType;
    updateFilterParam(view);
    set({ isFavourite: false });
    return set({ view });
  },
  isFavourite: false,
  setFavorite: (isFavourite) => set({ isFavourite }),
  blockedSectorId: [],
  blockedTopicId: [],
  view: signalsPage,
  setView: (view) => {
    const { view: currentView } = get();
    if (currentView.type !== view.type) {
      clearViewTypeSpecificFilters();
    }
    updateFilterParam(view);
    return set({ view });
  },
  amplyfiEntityId: null,
  setAmplyfiEntityId: (amplyfiEntityId) => set({ amplyfiEntityId }),

  libraryId: null,
  setLibraryId: (libraryId) => {
    const { libraryId: currentLibraryId } = get();
    if (libraryId !== currentLibraryId) {
      clearAllFilters();
    }
    return set({ libraryId });
  },
  addBlockedSectorById: (sector) => {
    const { blockedSectorId } = get();
    if (!blockedSectorId.includes(sector)) {
      return set({ blockedSectorId: [...blockedSectorId, sector] });
    }
  },
  addBlockedTopicById: (topic) => {
    const { blockedTopicId } = get();
    if (!blockedTopicId.includes(topic)) {
      return set({ blockedTopicId: [...blockedTopicId, topic] });
    }
  },
}));
