import { routeChange, replaceQuery, updateComponentsFilters, resetFilters } from "../reducers/searchRequest/actions";

import {
  addKeyword,
  addOption,
  addQuery,
  onGlobalDatesChange,
  onSelectPeriod,
  onTimelineChange,
  refineSearch,
  removeOption,
  removeQuery,
  resetFilterOptions,
  resetFiltersOfType,
  setFilters,
  toggleOption,
  viewNewsDoc,
  updateDocumentLibraryId,
} from "../reducers/searchRequest/urlReducer";
import { addSectorSentencesFilter, removeSectorSentencesFilter } from "../reducers/searchResponse/sectorsReducer";
import {
  addSentimentSentencesFilter,
  removeSentimentSentencesFilter,
} from "../reducers/searchResponse/sentimentReducer";

import { onChangeSectorSentencesFilter } from "../reducers/searchResponse/sectorsReducer";
import {
  onAddRiskSentencesFilter,
  onRemoveRiskSentencesFilter,
  onChangeRiskSentencesFilter,
} from "../reducers/searchResponse/riskReducer";

// these actions dont effect the main search query, they're usually applying a filter of some kind to a component
export const maintainFiltersActions = [
  onChangeSectorSentencesFilter.type,
  onChangeRiskSentencesFilter.type,
  removeSectorSentencesFilter.type,
  onAddRiskSentencesFilter.type,
  onRemoveRiskSentencesFilter.type,
  addSectorSentencesFilter.type,
  addSentimentSentencesFilter.type,
  removeSentimentSentencesFilter.type,
  viewNewsDoc.type,
  routeChange.type,
  updateComponentsFilters.type,
  updateDocumentLibraryId.type,
];

export const resetFilterActions = [
  removeQuery.type,
  addQuery.type,
  replaceQuery.type,
  resetFiltersOfType.type,
  onTimelineChange.type,
  addKeyword.type,
  toggleOption.type,
  addOption.type,
  removeOption.type,
  refineSearch.type,
  onSelectPeriod.type,
  updateComponentsFilters.type,
  resetFilters.type,
  resetFilterOptions.type,
  onGlobalDatesChange.type,
  setFilters.type,
];
