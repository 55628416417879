import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { cardDetails } from "../../helpers/cardDetailsHelper";
import { errorMessages } from "../../helpers/errorHelper";
import { exportFunctionsV2 } from "../../http/export";
import { GeoAndJurisdiction, MentionedOrPublished } from "../../models/geo";
import { DocumentType } from "../../models/search";
import { useAnalyseSelector } from "../../store/reducers";
import Card from "../Cards/Card";
import Header from "../Cards/Header";
import { ExportButtonV2 } from "../Widgets/ExportButton";
import GeoMapChart from "./GeoMapChart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      paddingBottom: 0,
    },
    content: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      position: "relative",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

export default function GeoMapCard(): JSX.Element {
  const geoData = useAnalyseSelector((x) => x.searchResponse.geo);
  const { loading, error } = geoData;
  const classes = useStyles();
  const [mentionedOrPublished, setMentionedOrPublished] = useState<MentionedOrPublished>("published");
  const [radioState, setRadioState] = useState<DocumentType>(DocumentType.News);

  const chartData: GeoAndJurisdiction = {
    news: {
      mentionedGeographies: geoData.newsMentionedGeographies,
      publishedGeographies: geoData.newsPublishedGeographies,
    },
    academic: geoData.academicPublishedGeographies,
    jurisdictions: geoData.jurisdictions,
  };

  useEffect(() => {
    if (geoData.newsMentionedGeographies.length > 0 || geoData.newsPublishedGeographies.length > 0) {
      setRadioState(DocumentType.News);
    } else if (geoData.academicPublishedGeographies.length > 0) {
      setRadioState(DocumentType.Academic);
    } else {
      setRadioState(DocumentType.Patents);
    }
  }, [geoData]);

  return (
    <Card
      feedbackComponentTitle={cardDetails.geography.title}
      loading={loading}
      title={<Header title={cardDetails.geography.title} />}
      infoContent={cardDetails.geography.readMore}
      isError={error}
      error={errorMessages.geography}
      rhsContent={
        <ExportButtonV2 getExport={exportFunctionsV2.geo} override={{ systemFilters: { geographyCount: 250 } }} />
      }
      size="large"
      contentClasses={clsx({ [classes.content]: !error })}
      content={
        <GeoMapChart
          data={chartData}
          geoType={mentionedOrPublished}
          setGeoType={setMentionedOrPublished}
          radioState={radioState}
          setRadioState={setRadioState}
        />
      }
      dataTestId={cardDetails.geography.dataTestId}
    />
  );
}
