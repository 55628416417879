import axios from "axios";
import { Entity } from "../models/entity";
import { LibrariesType } from "../models/queryParams";
import { SearchRequestV2 } from "../models/search";
import { SentimentResponse, SentimentSentencesResponse } from "../models/sentiment";
import { getAnalysisV2Url } from "./general";

export async function searchSentimentSentences(
  searchRequest: SearchRequestV2,
  filters: Entity[] = [],
  docType: LibrariesType[] | null = null
): Promise<SentimentSentencesResponse> {
  const searchResponse = await axios.post<SentimentSentencesResponse>(getAnalysisV2Url("search/sentiment/mentions", docType), {
    ...searchRequest,
    systemFilters: {
      ...searchRequest.systemFilters,
      mentionsKeywordFilters: filters,
      mentionsCount: 50,
    },
  });
  return searchResponse.data;
}

export async function searchSentiment(
  searchRequest: SearchRequestV2,
  docType: LibrariesType[] | null = null
): Promise<SentimentResponse> {
  const searchResponse = await axios.post<SentimentResponse>(getAnalysisV2Url("search/sentiment", docType), searchRequest);
  return searchResponse.data;
}
