import { useEffect, useState } from "react";
import useReaderStore from "../../../store/hooks/useReaderStore";

export default function useParentWidth(element: React.RefObject<HTMLElement>, traverseParents = 1) {
  const [parentWidth, setParentWidth] = useState<number | null>(null);
  const selectedPanel = useReaderStore((state) => state.selectedPanel);

  function calculateWidth() {
    if (element.current) {
      // get the parent that is n levels up
      let parent = element.current.parentElement;
      for (let i = 0; i < traverseParents; i++) {
        if (parent) {
          parent = parent.parentElement;
        }
      }
      if (parent) {
        const width = parent.getBoundingClientRect().width;
        if (width !== parentWidth) {
          setParentWidth(width);
        }
      }
    }
  }

  useEffect(() => {
    calculateWidth();
    window.addEventListener("resize", calculateWidth);
    return () => window.removeEventListener("resize", calculateWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  useEffect(() => {
    setTimeout(() => {
      calculateWidth();
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPanel]);

  return { parentWidth: parentWidth || 0 };
}
