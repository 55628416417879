import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { LibraryItemSettings } from "../../models/search/documentLibrary";
import {
  AlertHour,
  convertAlertHourToAlertPeriod,
  convertAlertHourToLocale,
  convertAlertHourToUtc,
  defaultPeriodAlertHour,
  friendlyAlertHour,
  selectAlertPeriodOptions,
  selectDayOptions,
  selectExactAlertHourOptions,
} from "../../components/Pages/Feed/helpers/alertFrequency";
import { MenuItemProps } from "@material-ui/core";

type Field<T> = {
  disabled: boolean;
  value: T;
};

interface Options {
  children?: string;
  value: MenuItemProps["value"];
}

interface FieldOptions {
  options: Options[];
}

interface FormState {
  emailDayUTC: Field<LibraryItemSettings["emailDayUTC"]> & FieldOptions;
  emailHourUTC: Field<LibraryItemSettings["emailHourUTC"]> & FieldOptions;
  sendEmails: Field<LibraryItemSettings["sendEmails"]>;
  sendEmptyEmails: Field<LibraryItemSettings["sendEmptyEmails"]>;
}

interface NotificationState {
  form: FormState;
  hasChanged: boolean;
  state: LibraryItemSettings;
}

type Setter<S, T extends keyof S> = (value: S[T]) => S;

interface SetNotificationState {
  emailDayUTC: Setter<LibraryItemSettings, "emailDayUTC">;
  emailHourUTC: Setter<LibraryItemSettings, "emailHourUTC">;
  sendEmails: Setter<LibraryItemSettings, "sendEmails">;
  sendEmptyEmails: Setter<LibraryItemSettings, "sendEmptyEmails">;
}

const DAILY_OPTION = "Daily";

export function useNotificationState<T extends LibraryItemSettings>(
  data?: T | null
): [NotificationState, SetNotificationState] {
  const { intelligenceAlertFrequencyExactHours } = useFlags();
  const [hasChanged, setHasChanged] = useState(false);
  const [state, sState] = useState<LibraryItemSettings>({
    emailDayUTC: data?.emailDayUTC || null,
    emailHourUTC: data?.emailHourUTC || convertAlertHourToUtc(selectAlertPeriodOptions[0]),
    sendEmails: data?.sendEmails || false,
    sendEmptyEmails: data?.sendEmptyEmails || false,
  });
  const setState = (state: LibraryItemSettings): LibraryItemSettings => {
    sState(state);
    return state;
  };
  const [remoteState, setRemoteState] = useState<LibraryItemSettings>(state);

  const selectedAlertHour: (alertHour: AlertHour) => AlertHour = intelligenceAlertFrequencyExactHours
    ? (alertHour) => convertAlertHourToLocale(alertHour)
    : (alertHour) => defaultPeriodAlertHour(convertAlertHourToAlertPeriod(convertAlertHourToLocale(alertHour)));

  const emailHourOptions = intelligenceAlertFrequencyExactHours
    ? selectExactAlertHourOptions.map((value) => ({ value, children: friendlyAlertHour(value) }))
    : selectAlertPeriodOptions.map((value) => ({ value, children: convertAlertHourToAlertPeriod(value) }));

  const transformToState = ({ emailDayUTC, emailHourUTC, sendEmails, sendEmptyEmails }: T): LibraryItemSettings => ({
    emailDayUTC,
    emailHourUTC,
    sendEmails,
    sendEmptyEmails,
  });

  const isSameAsRemote = ({ emailDayUTC, emailHourUTC, sendEmails, sendEmptyEmails }: LibraryItemSettings): boolean =>
    emailDayUTC === remoteState.emailDayUTC &&
    emailHourUTC === remoteState.emailHourUTC &&
    sendEmails === remoteState.sendEmails &&
    sendEmptyEmails === remoteState.sendEmptyEmails;

  useEffect(() => {
    if (data) {
      const s = transformToState(data);
      setRemoteState(s);
      setState(s);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setHasChanged(!isSameAsRemote(state));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const form: FormState = {
    emailDayUTC: {
      disabled: !state.sendEmails,
      options: [{ value: DAILY_OPTION, children: "Daily" }, ...selectDayOptions.map((value) => ({ value }))],
      value: state.emailDayUTC || DAILY_OPTION,
    },
    emailHourUTC: {
      disabled: !state.sendEmails,
      options: emailHourOptions,
      value: selectedAlertHour(state.emailHourUTC),
    },
    sendEmails: {
      disabled: false,
      value: state.sendEmails,
    },
    sendEmptyEmails: {
      disabled: !state.sendEmails,
      value: state.sendEmptyEmails,
    },
  };

  const setters: SetNotificationState = {
    emailDayUTC: (emailDayUTC) =>
      setState({
        ...state,
        emailDayUTC: emailDayUTC === DAILY_OPTION ? null : emailDayUTC,
      }),
    emailHourUTC: (emailHourUTC) =>
      setState({
        ...state,
        emailHourUTC: convertAlertHourToUtc(emailHourUTC as AlertHour),
      }),
    sendEmails: (sendEmails) => setState({ ...state, sendEmails }),
    sendEmptyEmails: (sendEmptyEmails) => setState({ ...state, sendEmptyEmails }),
  };

  return [{ form, hasChanged, state }, setters];
}
