import AiButton from "@amplyfi/ui-components/components/Button";
import { LinkBlueSky } from "@amplyfi/ui-components/theme/colors";
import { makeStyles, Typography } from "@material-ui/core";
import { Sync } from "@material-ui/icons";
import SimpleDialog, { AbstractDialogProps } from "../../../Widgets/Dialog/SimpleDialog";

interface ExportCompleteProps extends AbstractDialogProps {
  documentCount?: number;
}

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 400,
    minHeight: 400,
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

export default function ExportCompleteDialog(props: ExportCompleteProps) {
  const { onClose, ...rest } = props;
  const classes = useStyles();
  return (
    <SimpleDialog
      {...rest}
      onClose={onClose}
      title=""
      primary={null}
      secondary={null}
      content={
        <div className={classes.modalContent}>
          <Sync style={{ fontSize: 100 }} htmlColor={LinkBlueSky} />
          <Typography className={classes.marginTop} align="center" variant="h3">
            We are working on it!
          </Typography>
          <Typography className={classes.marginTop} align="center" variant="body2">
            We will email you when your documents are analysed
          </Typography>
          <AiButton onClick={onClose} className={classes.marginTop}>
            Back to Search
          </AiButton>
          <Typography className={classes.marginTop} align="center" variant="body2" style={{ fontStyle: "italic" }}>
            If you have any questions or issues, please <a href="mailto:support@amplyfi.com">contact support</a>
          </Typography>
        </div>
      }
    />
  );
}
