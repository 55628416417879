import React from "react";
import { Redirect } from "react-router-dom";
import { CanUserPermissionCheck, getPermissions, userPermission } from "../../helpers/user";
import { AuthUser } from "../../models/user";
import { useAuth0 } from "../Auth0/AuthWrapper";
import { canUserReadAnalysis, feature as analysisFeature } from "../Pages/Analyse/permissions";
import { canUserReadFeed, feature as feedFeature } from "../Pages/Feed/permissions";
import { canUserReadResearch, feature as researchFeature } from "../Pages/DeepKnowledge/permissions";

export const canUserReadCoreFeatures: CanUserPermissionCheck = (user) =>
  canUserReadResearch(user) || canUserReadAnalysis(user) || canUserReadFeed(user);

function getRedirect(user?: AuthUser): JSX.Element {
  const permissions = getPermissions(user);
  const redirectOrder: [string, JSX.Element][] = [
    [userPermission(researchFeature).read(), <Redirect to="research" />],
    [userPermission(analysisFeature).read(), <Redirect to="analyse" />],
    [userPermission(feedFeature).read(), <Redirect to="feed" />],
  ];
  const s = redirectOrder.reduce<JSX.Element | null>((acc, arr) => {
    const [permission, element] = arr;
    if (permissions.includes(permission)) {
      return element;
    }
    return acc || null;
  }, null);

  return s || <Redirect to="/forbidden" />;
}

export default function RedirectToApp(): JSX.Element {
  const { user } = useAuth0();

  if (!canUserReadCoreFeatures(user)) {
    return <Redirect to="" />;
  }

  return getRedirect(user);
}
