import { useAuth0 } from "../components/Auth0/AuthWrapper";
import { getUserId } from "../helpers/user";
import { LibraryItem } from "../models/search/documentLibrary";
import useLibrary from "./useLibrary";

export enum FeedUserType {
  Admin = "admin",
  Editor = "editor",
  Viewer = "viewer",
}

const getFeedUserTypeForUserId = (userId: string, library?: LibraryItem | null): FeedUserType => {
  if (userId && library) {
    if (!!library.adminUserIds?.includes(userId)) {
      return FeedUserType.Admin;
    }
    if (library.editorUserIds.includes(userId)) {
      return FeedUserType.Editor;
    }
  }
  return FeedUserType.Viewer;
};

export const useFeedUserType = (libraryId: string): FeedUserType => {
  const { user } = useAuth0();
  const userId = getUserId(user);
  const { data } = useLibrary(libraryId);

  return getFeedUserTypeForUserId(userId, data);
};

export const userTypeCanChangeFeed = (userType: FeedUserType): boolean =>
  userType === FeedUserType.Editor || userType === FeedUserType.Admin;

export const useCanUserChangeFeed = (libraryId: string): boolean => userTypeCanChangeFeed(useFeedUserType(libraryId));
