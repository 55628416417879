import { Box, Chip, List, ListItem, ListItemIcon, ListItemText, Popover, Tooltip, makeStyles } from "@material-ui/core";
import { useRef, useState } from "react";
import useParentWidth from "../../../../../hooks/queries/util/useParentWidth";

const useStyles = makeStyles(() => ({
  cardChip: {
    background: "#f3f3f3",
    color: "#000",
    paddingLeft: 4,
    paddingRight: 4,
    marginLeft: "auto",
    maxWidth: 200,
  },
}));

interface ChipOverflowProps {
  chips: string[];
  disabledChips?: string[];
  overflowChipIcon?: JSX.Element;
  onChipClicked?: (entity: string, isOverflowed: boolean) => void;
  maximumChipsToDisplay?: number;
}

export default function ChipOverflow(props: ChipOverflowProps) {
  const { chips, onChipClicked, disabledChips, overflowChipIcon } = props;
  const [ref, setTargetRef] = useState<HTMLDivElement | null>(null);
  const classes = useStyles();
  const root = useRef(null);
  const { parentWidth } = useParentWidth(root);
  const numberToDisplay = Math.min(
    props.maximumChipsToDisplay || Math.floor(parentWidth / 300),
    Math.floor(parentWidth / 300)
  );

  const sortedChips = chips.sort((a, b) => (!disabledChips?.includes(a) && disabledChips?.includes(b) ? -1 : 1));

  return (
    <div ref={root} onClick={(e) => e.stopPropagation()}>
      <Box display="flex" alignItems="center">
        {sortedChips
          .filter((_, index) => index < numberToDisplay)
          .map((c) => (
            <Box
              key={c}
              onClick={() => {
                onChipClicked?.(c, false);
              }}
            >
              <Tooltip title={c}>
                <Chip
                  className={classes.cardChip}
                  style={{ cursor: !!onChipClicked ? "pointer" : undefined }}
                  label={c}
                />
              </Tooltip>
            </Box>
          ))}
        {chips.length - numberToDisplay > 0 && (
          <Chip
            style={{ marginLeft: 8, cursor: "pointer" }}
            className={classes.cardChip}
            onClick={(e) => {
              setTargetRef(e.currentTarget);
            }}
            label={`+ ${chips.length - numberToDisplay}`}
          />
        )}
        <Popover
          style={{ maxHeight: 400 }}
          onClose={(e) => {
            (e as MouseEvent).stopPropagation?.();
            setTargetRef(null);
          }}
          open={ref !== null}
          anchorEl={ref}
        >
          <List>
            {sortedChips
              .filter((_, i) => i >= numberToDisplay)
              .map((c) => (
                <ListItem
                  disabled={disabledChips?.includes(c)}
                  key={c}
                  style={{ cursor: !disabledChips?.includes(c) && onChipClicked ? "pointer" : undefined }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disabledChips?.includes(c)) {
                      onChipClicked?.(c, true);
                    }
                  }}
                >
                  <ListItemText primary={c} />
                  {overflowChipIcon && !disabledChips?.includes(c) && (
                    <ListItemIcon style={{ marginLeft: 16 }}>{overflowChipIcon}</ListItemIcon>
                  )}
                </ListItem>
              ))}
          </List>
        </Popover>
      </Box>
    </div>
  );
}
