import { createStyles, makeStyles, Typography, Theme, FormControl, Select, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import { UNASSIGNED_VALUE } from "../helpers/query";
import { canUserCreateMultilingualHarvest } from "../permissions";
import { HarvestConfig } from "../models";
import { LibraryItem } from "../../../../models/search/documentLibrary";
import { AccountGroup } from "../../../../models/account";
import { LibraryEntity } from "../../../../http/harvesterCollections";

interface HarvestDescriptionProps {
  language: string;
  organisation: AccountGroup;
  library: LibraryItem;
  setLanguage: (language: string) => void;
  harvestConfig: HarvestConfig;
  entity: LibraryEntity | null;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    label: {
      paddingBottom: spacing(1),
    },
    select: {
      marginLeft: spacing(1),
    },
    column: {
      width: "50%",
    },
    columnRight: {
      textAlign: "right",
    },
    section: {
      display: "flex",
      flexDirection: "row",
      marginBottom: spacing(2),
    },
    value: {
      fontWeight: "bold",
    },
  })
);

const Value = ({ fallback = "Unknown", value = fallback }: { fallback?: string; value?: string }): JSX.Element => {
  const classes = useStyles();
  return <span className={classes.value}>{value || fallback}</span>;
};

export const HarvestDescription = ({
  language,
  organisation: { name: organisationName },
  library: { description, name: libraryName },
  entity,
  setLanguage,
  harvestConfig: {
    name: harvestSourceName,
    oneOffConfiguration: { languages },
  },
}: HarvestDescriptionProps): JSX.Element => {
  const classes = useStyles();
  const { user } = useAuth0();
  const canCreateMultilingualHarvest = canUserCreateMultilingualHarvest(user);

  return (
    <section className={classes.section}>
      <div className={classes.column}>
        <Typography>
          Organisation: <Value value={organisationName} />
        </Typography>
        <Typography>
          Library: <Value value={libraryName} />
        </Typography>
        <Typography>
          Entity: <Value value={entity?.name} fallback="n/a" />
        </Typography>
        <Typography>
          Description: <Value value={description} fallback="n/a" />
        </Typography>
        <Typography>
          Source: <Value value={harvestSourceName} />
        </Typography>
      </div>
      {!!languages?.length && (
        <div className={clsx(classes.column, classes.columnRight)}>
          <FormControl fullWidth>
            <div className={classes.label}>
              <Typography style={{ display: "inline" }}>Harvest Language:</Typography>
              <Select
                disabled={!canCreateMultilingualHarvest}
                className={classes.select}
                value={language}
                onChange={({ target: { value } }) => setLanguage(value as string)}
              >
                <MenuItem value={UNASSIGNED_VALUE}>No language</MenuItem>
                {languages.map((lang) => (
                  <MenuItem key={lang} value={lang}>
                    {lang.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </FormControl>
        </div>
      )}
    </section>
  );
};
