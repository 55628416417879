import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import clsx from "clsx";
import React, { ReactNode } from "react";
import LoadingAdornment from "./Misc/LoadingAdornment";

interface AutocompleteSearchTextFieldProps {
  textFieldClasses?: string;
  inputClasses?: string;
  inputBaseClasses?: string;
  startAdornment?: ReactNode | null;
  endAdornment?: ReactNode | null;
  placeholder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
  loading?: boolean;
  dataTestId?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      width: "100%",
    },
    textField: {
      display: "flex",
      flexGrow: 1,
    },
    input: {},
    inputBase: {},
    errorMessage: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      textAligh: "center",
      transform: "translateY(100%)",
      paddingTop: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.error.main,
    },
  })
);

export default function AutocompleteSearchTextField(props: AutocompleteSearchTextFieldProps): JSX.Element {
  const {
    placeholder,
    textFieldClasses,
    params,
    inputClasses,
    inputBaseClasses,
    startAdornment,
    endAdornment,
    loading = false,
    dataTestId,
  } = props;
  const classes = useStyles();

  return (
    <TextField
      {...params}
      className={clsx(classes.textField, textFieldClasses)}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        className: clsx(classes.input, inputClasses),
        classes: { root: clsx(classes.inputBase, inputBaseClasses) },
        startAdornment: startAdornment,
        endAdornment: typeof endAdornment !== "undefined" ? endAdornment : <LoadingAdornment loading={loading} />,
        "data-testid": dataTestId,
      }}
    />
  );
}
