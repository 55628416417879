import { Slide, useScrollTrigger } from "@material-ui/core";
import useAlertListState from "../../../../../hooks/useAlertListState";
import useScreenSize from "../../../../../hooks/useScreenSize";
import useInsightListStore from "../../../../../store/hooks/useInsightListStore";
import useReaderStore from "../../../../../store/hooks/useReaderStore";

export default function HideOnFeedScroll(props: {
  children: JSX.Element;
  direction: "left" | "right" | "up" | "down" | undefined;
}) {
  const { children, direction } = props;
  const [listState] = useAlertListState();
  const insightListScroller = useInsightListStore((state) => state.listScroller);
  const elem = listState.list ? (document.getElementById(listState.list?.props.id as string) as Node) : undefined;
  const trigger = useScrollTrigger({
    target: insightListScroller || elem || undefined,
  });
  const { selectedPanel } = useReaderStore((state) => state);

  const screenSize = useScreenSize();

  return (
    <Slide
      ref={(r: HTMLElement) => {
        const hiddenContentHeight = useInsightListStore.getState().hiddenContentHeight;
        const setHiddenContentHeight = useInsightListStore.getState().setHiddenContentHeight;
        if (screenSize === "desktop") {
          setHiddenContentHeight(0);
          return;
        }
        if (r && hiddenContentHeight < r.clientHeight) {
          setHiddenContentHeight(r.clientHeight);
        }
      }}
      appear={false}
      direction={direction}
      in={screenSize === "desktop" ? true : selectedPanel === null && !trigger}
      unmountOnExit
      mountOnEnter
    >
      {children}
    </Slide>
  );
}
