import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Snack, SnackFrequencyType } from "../../../models/snack";

export interface SnackState {
  message: null | Snack;
}

const initialState: SnackState = {
  message: null,
};

export const DEFAULT_AUTO_HIDE_DURATION = 3000;

const searchSlice = createSlice({
  initialState,
  name: "snack",
  reducers: {
    setSnack: (state: SnackState, { payload }: PayloadAction<Snack>) => {
      const { autoHideDuration = DEFAULT_AUTO_HIDE_DURATION, type = SnackFrequencyType.Every, body = "", ...snack } = payload;
      const snackKey = `snack.${snack.title}`;
      const message = {
        ...snack,
        autoHideDuration,
        body,
        type,
      };
      if (type === SnackFrequencyType.Every) {
        state.message = message;
      } else if (type === SnackFrequencyType.OncePerSession && sessionStorage.getItem(snackKey) !== "true") {
        state.message = message;
        sessionStorage.setItem(snackKey, "true");
      }
    },
    clearSnack: (state: SnackState, { payload }: PayloadAction<Snack | null>) => {
      const isCurrentSnack =
        payload?.title === state.message?.title && payload?.body === state.message?.body;
      if (isCurrentSnack) {
        state.message = null;
      }
    },
  },
});

export const { setSnack, clearSnack } = searchSlice.actions;

export default searchSlice.reducer;
