import AiChip from "@amplyfi/ui-components/components/Chip";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { FormControl, FormGroup, FormHelperText, TextField } from "@material-ui/core";
import { useState, KeyboardEventHandler, useEffect } from "react";
import { FieldHandlerOptions, FieldHandlerValidatorOptions, Handler } from "../../../helpers/fieldHandlers";
import { useFieldStyles } from "./styles";
import { invalidMessageFn, requiredValidationMessage } from "./helper";

interface MultiStringHandlerOptions extends FieldHandlerOptions, FieldHandlerValidatorOptions {
  singular: string;
}

export const multiStringField: (props: MultiStringHandlerOptions) => Handler<string[]> =
  ({ singular, label, required = false, validators = [] }) =>
  (values = [], name, onChange, { disabled, error, validation }) => {
    const classes = useFieldStyles();
    const [newValue, setNewValue] = useState("");

    const invalidMessage = invalidMessageFn(validators, required);

    const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = ({ key, target }) => {
      const { value = "" } = target as HTMLInputElement;
      const trimmedValue = value.trim();
      const currentValueInvalidMessage = invalidMessage(value);
      const overallValuesInvalidMessage = required && !values.length ? requiredValidationMessage : undefined;

      if (currentValueInvalidMessage !== undefined) {
        onChange(values, currentValueInvalidMessage);
        return;
      }
      onChange(values, overallValuesInvalidMessage);

      if (key === "Enter" && trimmedValue) {
        const valuesWithDupelicatesRemoved = values.filter(
          (v) => v.trim().toLowerCase() !== trimmedValue.toLowerCase()
        );
        onChange([...valuesWithDupelicatesRemoved, trimmedValue]);
        setNewValue("");
      }
    };

    const handleChange = (vals: string[]) => {
      const invalid = required && !vals.length ? [invalidMessage("")] : vals.map(invalidMessage);
      onChange(vals, invalid?.[0]);
    };

    useEffect(() => {
      handleChange(values);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <FormControl className={classes.control} error={!!error || validation !== undefined}>
        <FormGroup>
          <TextField
            fullWidth
            data-testid={testId("entity-knowledge-base", "edit", name)}
            disabled={disabled}
            variant="outlined"
            placeholder={`Add new ${singular} and press enter`}
            label={label}
            onKeyUp={handleKeyUp}
            onBlur={() => handleChange(values)}
            InputLabelProps={{ shrink: true }}
            onChange={({ target: { value } }) => setNewValue(value)}
            value={newValue}
            error={!!error || validation !== undefined}
            aria-invalid={validation !== undefined}
            aria-required={required}
          />
          {(error || validation) && <FormHelperText className={classes.error}>{error || validation}</FormHelperText>}
          {values.length ? (
            <div className={classes.chips}>
              {values.map((v) => (
                <AiChip
                  chipClasses={classes.chip}
                  data-testid={testId("entity-knowledge-base", name, v)}
                  key={`entity-${name}-chip-${v}`}
                  color={disabled ? undefined : "secondary"}
                  label={v}
                  size="small"
                  onDelete={disabled ? undefined : () => handleChange(values.filter((val) => val !== v))}
                />
              ))}
            </div>
          ) : null}
        </FormGroup>
      </FormControl>
    );
  };
