import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Melrose } from "@amplyfi/ui-components/theme/colors";
import { EntityGroupType } from "../store/reducers/searchResponse/relatedDocumentViewerReducer";

export const getDocumentColourRDV = (type: EntityGroupType): string => {
  const colourMap: Record<EntityGroupType, string> = {
    Organisation: "#DB7CFD",
    Country: "#86C7E0",
    Person: Melrose,
  };
  return colourMap[type];
};

export const highlightStyles = (theme: Theme): CSSProperties => ({
  borderBottomWidth: 3,
  borderBottomStyle: "solid",
  cursor: "pointer",
});
