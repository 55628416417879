import { SearchResponse } from "../models/search";
import { useAnalyseSelector } from "../store/reducers";
import { hasAnyQueriesSelector } from "../store/reducers/searchRequest/urlReducer";
import { addInMissingDates } from "../store/reducers/searchResponse/overviewReducer";
import useLandingTimeline, { DocumentTimelineOptions } from "./useLandingTimeline";

interface DocumentTimelineResponse {
  isLoading: boolean;
  documentsTimeline?: SearchResponse;
  isError: boolean;
}

export default function useDocumentTimeline(options?: DocumentTimelineOptions): DocumentTimelineResponse {
  const { parsed: url } = useAnalyseSelector((x) => x.searchRequest.url);
  const hasAnyQueries = useAnalyseSelector(hasAnyQueriesSelector);

  const {
    data: documentsTimeline,
    isLoading,
    isError,
  } = useLandingTimeline("timeline-news", url, hasAnyQueries, options);
  const uniqueDates = getDocumentsUniqueDates(documentsTimeline);

  if (documentsTimeline) {
    documentsTimeline.timeline = addInMissingDates(uniqueDates, documentsTimeline.timeline);
    documentsTimeline.estimateTimeline = addInMissingDates(uniqueDates, documentsTimeline.estimateTimeline);
  }

  return {
    isLoading,
    documentsTimeline,
    isError,
  };
}

const getDocumentsUniqueDates = (documentsTimeline?: SearchResponse): string[] => {
  return [...new Set([...(documentsTimeline?.timeline ?? [])].map((x) => x.startDate))];
};
