import {
  BabyBlue,
  BrightPink,
  Chardonnay,
  FlameOrange,
  MagicMint,
  Teal,
  WaxFlower,
  TorchRed,
  BlueRibbon,
} from "@amplyfi/ui-components/theme/colors";

export const chartColours = [
  BabyBlue,
  Chardonnay,
  BrightPink,
  BlueRibbon[300],
  MagicMint,
  FlameOrange[200],
  WaxFlower[200],
  Teal[400],
  TorchRed[100],
  WaxFlower[500],
];
