import { useEffect, useState } from "react";
import { useFeedViewStore } from "../store/hooks/useFeedViewStore";
import useFeed, { useFeedCounts, useLatestFeedDocument, useTotalFeedCounts } from "./useFeed";
import useAlertListState from "./useAlertListState";
import useInsightListStore from "../store/hooks/useInsightListStore";

export default function useFeedUpdates() {
    const { libraryId, amplyfiEntityId } = useFeedViewStore();
    const [firstDocument, setFirstDocument] = useState<string | null>(null);
    const [lastUpdate, setLastUpdate] = useState<number>(0);
    const [hasNewData, setHasNewData] = useState<boolean>(false);
    const { data } = useLatestFeedDocument(libraryId, amplyfiEntityId);
    const { refetch } = useFeed(libraryId, amplyfiEntityId);
    const { refetch: refetchCounts } = useFeedCounts(libraryId, amplyfiEntityId);
    const { refetch: refetchTotalCounts } = useTotalFeedCounts(libraryId, amplyfiEntityId);
    const [alertList] = useAlertListState();
    const insightList = useInsightListStore(state => state.list);

    useEffect(() => {
        if (data?.documents) {
            if (firstDocument && data.documents[0] && data.documents[0].documentId !== firstDocument) {
                setHasNewData(true);
            }

            if (data.documents[0]) {
                setFirstDocument(data?.documents[0].documentId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.documents]);

    function reset() {
        if (data?.documents) {
            setLastUpdate(Date.now());
            setHasNewData(false);
            if (data.documents[0]) {
                setFirstDocument(data.documents[0].documentId);
            } else {
                setFirstDocument(null);
            }
            alertList.insightList?.scrollToIndex(0);
            alertList.list?.scrollToPosition(0);
            insightList?.scrollToIndex(0);
            refetch();
            refetchCounts();
            refetchTotalCounts();
        }
    }

    useEffect(() => {
        setFirstDocument(null);
        setHasNewData(false);
        setLastUpdate(0);
    }, [libraryId, amplyfiEntityId])

    return {
        // is true when the last update was more than 3 minute ago
        hasNewData: hasNewData && ((Date.now() - lastUpdate > (60000 * 3)) || lastUpdate === 0),
        reset
    }
}