import { call, CallEffect, put, PutEffect, select, SelectEffect } from "redux-saga/effects";
import { logError } from "../../../helpers/logger";
import { searchSentimentSentences } from "../../../http/sentiment";
import { Entity } from "../../../models/entity";
import { SearchRequestV2 } from "../../../models/search";
import { SentimentSentencesResponse } from "../../../models/sentiment";
import { AppState } from "../../reducers";
import { getParsedQueryParams } from "../../reducers/searchRequest/url-functions";
import {
  onSentimentSentencesError,
  onSentimentSentencesLoading,
  onSentimentSentencesSuccess,
} from "../../reducers/searchResponse/sentimentReducer";

export function* fetchSentimentSentences(
  searchRequest: SearchRequestV2
): Generator<
  PutEffect | SelectEffect | CallEffect<SentimentSentencesResponse> | PutEffect,
  void,
  Entity[] & SentimentSentencesResponse
> {
  try {
    yield put(onSentimentSentencesLoading());
    const sentimentFilters: Entity[] = yield select(
      (state: AppState) => state.searchResponse.sentiment.sentimentSentencesFilters
    );
    const { libraries } = getParsedQueryParams();
    const sentiment: SentimentSentencesResponse = yield call(
      searchSentimentSentences,
      searchRequest,
      sentimentFilters,
      libraries
    );
    yield put(onSentimentSentencesSuccess(sentiment));
  } catch (err) {
    logError(err);
    yield put(onSentimentSentencesError());
  }
}
