import { pluraliseList, toTitleCase } from "../../../../helpers/textHelper";
import { Snack } from "../../../../models/snack";
import { FeedViewType } from "../components/filterMenu/feedFilters";

export const requestNewSuccess = (): Snack => ({
  title: "Thanks for your submission",
  body: "One of our team will check over this",
});

export const alertAdded = (name: string): Snack => ({ title: "Signal Type added", body: `${name} added` });

export const alertDeleted = (name: string): Snack => ({ title: "Signal Type deleted", body: `${name} deleted` });

export const alertRemoved = (name: string): Snack => ({ title: "Signal Type removed", body: `${name} removed` });

export const alertSaved = (name: string): Snack => ({ title: "Signal Type saved", body: `${name} saved` });

export const interestAdded = (name: string): Snack => ({ title: "Interest added", body: `${name} added` });

export const interestRemoved = (name: string): Snack => ({ title: "Interest removed", body: `${name} removed` });

export const sourceAdded = (name: string): Snack => ({ title: "Source added", body: `${name} added` });

export const sourceRemoved = (name: string): Snack => ({ title: "Source removed", body: `${name} removed` });

export const sourceDeleted = (name: string): Snack => ({ title: "Source deleted", body: `${name} deleted` });

export const sourceSaved = (name: string): Snack => ({ title: "Sources saved", body: `${name} saved` });

export const salesPitchDeleted = (name: string): Snack => ({
  title: "Value Proposition deleted",
  body: `${name} deleted`,
});
export const salesPitchDeleteFailed = (name: string): Snack => ({
  title: "Unable to delete Value Proposition",
  body: `${name} was not deleted`,
});

export const salesPitchUpdated = (name: string): Snack => ({
  title: "Value Proposition updated",
  body: `${name} updated`,
});
export const salesPitchUpdateFailed = (name: string): Snack => ({
  title: "Unable to update Value Proposition",
  body: `${name} was not updated`,
});

export const salesPitchCreated = (name: string): Snack => ({
  title: "New Value Proposition created",
  body: `${name} created`,
});
export const salesPitchCreateFailed = (name: string): Snack => ({
  title: "Unable to create Value Proposition",
  body: `${name} was not created`,
});

export const addUserTagFailed = (type: FeedViewType, names: string[]): Snack => ({
  title: `Unable to update ${type} user tags`,
  body: `${names.join(", ")} ${pluraliseList(names.length, { includeCount: false })} not added`,
});
export const addUserTagSucceeded = (type: FeedViewType, names: string[]): Snack => ({
  title: `${toTitleCase(type)} user tags updated`,
  body: `${names.join(", ")} ${pluraliseList(names.length, { includeCount: false })} added`,
});

export const removeUserTagFailed = (type: FeedViewType, names: string[]): Snack => ({
  title: `Unable to update ${type} user tags`,
  body: `${names.join(", ")} ${pluraliseList(names.length, { includeCount: false })} not removed`,
});
export const removeUserTagSucceeded = (type: FeedViewType, names: string[]): Snack => ({
  title: `${toTitleCase(type)} user tags updated`,
  body: `${names.join(", ")} ${pluraliseList(names.length, { includeCount: false })} removed`,
});
