import { TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getOrgId, getOrganisation, getUserId } from "../../../../helpers/user";
import useUser from "../../../../hooks/useUser";
import { updateUserById } from "../../../../http/accounts";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import AbstractSettingsPage from "../AbstractSettingsPage";
import { SimpleUserSection, UserSectionCard } from "../UserSection";

export default function AccountDetails() {
  const { user } = useAuth0();
  const organisationId = getOrgId(user);
  const userId = getUserId(user);
  const {
    data: fetchedUser,
    isLoading: isLoadingUser,
    refetch: refetchUser,
    isFetching: isFetchingUser,
  } = useUser(organisationId, userId);
  const [username, setUsername] = useState(fetchedUser?.name || "");

  async function saveUser() {
    if (!user) {
      return;
    }
    await updateUserById(userId, { name: username });
    refetchUser();
  }

  useEffect(() => {
    if (fetchedUser) {
      setUsername(fetchedUser.name);
    }
  }, [fetchedUser]);

  return (
    <AbstractSettingsPage title="Account Details">
      <UserSectionCard
        title="Account Details"
        loading={fetchedUser === undefined || isLoadingUser}
        confirmButtonDisabled={
          isFetchingUser || username.length === 0 || (fetchedUser ? fetchedUser.name === username : true)
        }
        confirmButtonText="Save"
        onConfirm={async () => await saveUser()}
      >
        <SimpleUserSection title="Name">
          <TextField fullWidth onChange={(e) => setUsername(e.target.value)} value={username} />
        </SimpleUserSection>
        <SimpleUserSection title="Email">
          <Typography variant="body2">{user?.email}</Typography>
        </SimpleUserSection>
        <SimpleUserSection title="Organisation">
          <Typography variant="body2">{getOrganisation(user)?.name}</Typography>
        </SimpleUserSection>
      </UserSectionCard>
    </AbstractSettingsPage>
  );
}
