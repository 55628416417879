import moment from "moment";

export type TimeIntervalType = "Year" | "Quarter" | "Month" | "Week" | "Day";

export const gridLines: Record<TimeIntervalType, number> = {
  Day: 10,
  Month: 3,
  Quarter: 1,
  Week: 3,
  Year: 1,
};

export const timeIntervalTypes: { id: TimeIntervalType; label: string; momentDiffUnit: moment.unitOfTime.Diff }[] = [
  {
    label: "Years",
    id: "Year",
    momentDiffUnit: "years",
  },
  {
    label: "Quarters",
    id: "Quarter",
    momentDiffUnit: "quarters",
  },
  {
    label: "Months",
    id: "Month",
    momentDiffUnit: "months",
  },
  {
    label: "Weeks",
    id: "Week",
    momentDiffUnit: "weeks",
  },
  {
    label: "Days",
    id: "Day",
    momentDiffUnit: "days",
  },
];

export enum Days {
  Today = "today",
}
