import { YellowOrange } from "@amplyfi/ui-components/theme/colors";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import EntityTooltip from "../components/Widgets/Entity/EntityTooltip";
import { Entity } from "../models/entity";
import { TooltipType } from "../models/search";
import { Sentence, SentenceMentions } from "../models/sentence";

export function formatList(sentences: Sentence[]): Sentence[] {
  sentences.forEach((sentence) => {
    let newText = sentence.text.trim();
    while (newText[0] === ".") {
      newText = newText.slice(1);
    }

    sentence.text = newText;
    sentence.mentionedEntities.forEach((entity) => {
      entity.mentionId = makeid(5);
    });
  });

  return sentences;
}

const getHighlightedText = (sentence: Sentence): ReturnType<typeof ReactHtmlParser> => {
  const { mentionedEntities: mentions } = sentence;
  const text = sentence.text.slice(0, 300);
  let highlightedText = "";

  const startingPositions = mentions.flatMap((x) => x.startingPosition);

  const endingPositions = mentions.flatMap((x) => x.endingPosition);

  for (let i = 0; i < text.length; i++) {
    if (startingPositions.includes(i)) {
      const mention = mentions.find((x) => x.startingPosition === i) as SentenceMentions;

      highlightedText +=
        `<mark id="${mention.mentionId}" data-entity-id="${mention.id}" name="${mention.name}" data-identifier="${
          mention.id
        }-${mention.startingPosition}-${mention.endingPosition}" data-mark="markedText" type="${
          mention.entityType ?? null
        }" style="background-color:${YellowOrange[100]}; cursor:pointer;">` + text.charAt(i);
    } else if (endingPositions.includes(i + 1)) {
      highlightedText += text.charAt(i) + "</mark>";
    } else {
      highlightedText += text.charAt(i);
    }
  }

  return ReactHtmlParser(highlightedText);
};
interface SentenceHighlighterProps {
  sentence: Sentence;
  className?: string;
}

const useMarkClickEvent = function (props: SentenceHighlighterProps): {
  selectedData?: Entity;
  setSelectedData: Dispatch<SetStateAction<Entity | undefined>>;
  tooltipIdentifier?: string;
} {
  const { sentence } = props;
  const [selectedData, setSelectedData] = useState<Entity>();
  const [tooltipIdentifier, setTooltipIdentifier] = useState<string>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clickHandler = (e: any) => {
      const type = e.target.getAttribute("type");
      setSelectedData({
        id: e.target.getAttribute("data-entity-id"),
        name: e.target.getAttribute("name"),
        type: type === "null" ? null : type,
      });
      setTooltipIdentifier(e.target.getAttribute("data-identifier"));
    };
    sentence.mentionedEntities.forEach((mention) => {
      if (mention.mentionId) {
        const mentionEl = document.getElementById(mention.mentionId);
        if (mentionEl) {
          mentionEl.addEventListener("click", clickHandler);
        }
      }
    });

    return () => {
      sentence.mentionedEntities.forEach((mention) => {
        if (mention.mentionId) {
          const mentionEl = document.getElementById(mention.mentionId);
          if (mentionEl) {
            mentionEl.removeEventListener("click", clickHandler);
          }
        }
      });
    };
  }, [sentence]);

  return { selectedData, setSelectedData, tooltipIdentifier };
};

const useTooltipstyles = makeStyles(() =>
  createStyles({
    tooltip: {
      display: "inline",
    },
  })
);

export const useTooltipOnHighlightedText = (props: SentenceHighlighterProps): JSX.Element[] => {
  const { sentence, className } = props;
  const highlightedText = getHighlightedText(sentence);
  const classes = useTooltipstyles();
  const { selectedData, setSelectedData, tooltipIdentifier } = useMarkClickEvent({ sentence });

  const listOfElements = highlightedText.map((item, i) => {
    if (item.type === "mark") {
      return (
        <EntityTooltip
          entity={tooltipIdentifier === item.props["data-identifier"] ? selectedData : undefined}
          onClose={() => {
            if (tooltipIdentifier === item.props["data-identifier"]) {
              setSelectedData(undefined);
            }
          }}
          tooltipType={!selectedData?.type ? TooltipType.KeyPhrase : undefined}
          className={classes.tooltip}
        >
          {item}
        </EntityTooltip>
      );
    } else {
      return (
        <Typography className={className} variant="body2">
          {item}
        </Typography>
      );
    }
  });
  return listOfElements;
};

function makeid(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function camelToTitleCase(str: string): string {
  const text = str;
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
