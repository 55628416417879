import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import Legend from "@amplyfi/ui-components/components/Legends/index";
import { MidLightBlue } from "@amplyfi/ui-components/theme/colors";
import { createStyles, Theme } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { link, textEllipses } from "../../../css/mixins";
import { ActiveOption, ChartCustomizationResult } from "../../../models/chart-customizations";
import { AxisType } from "../../../models/customization/customization";
import { EntityType } from "../../../models/entity";
import { useAnalyseSelector } from "../../../store/reducers";
import { entityTypeMapColor } from "./helpers/entityTypeMapColor";

interface CustomizationAvailableOptionsProps {
  options: ChartCustomizationResult[];
  selectedSecondaryOptions: ActiveOption[];
  axis: AxisType;
  selectedOptions: ActiveOption[];
  setSelectedOptions: (args: ActiveOption[]) => void;
  setSelectedSecondaryOptions: (args: ActiveOption[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    result: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(1),
    },
    resultsText: {
      width: 200,
      ...link(theme),
      ...textEllipses,
      cursor: "unset",
    },
    addResult: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(2),
    },
    addIcon: {
      color: MidLightBlue,
      marginRight: theme.spacing(0.5),
      width: theme.spacing(2.4),
      height: theme.spacing(2.4),
    },
    addText: {
      ...link(theme),
      whiteSpace: "nowrap",
    },
    list: {
      padding: theme.spacing(0),
    },
  })
);

export default function CustomizationAvailableOptions(props: CustomizationAvailableOptionsProps): JSX.Element {
  const { options, selectedSecondaryOptions, axis, selectedOptions, setSelectedOptions, setSelectedSecondaryOptions } =
    props;
  const classes = useStyles();
  const { entitiesSearchLoading, keyPhrasesLoading } = useAnalyseSelector((x) => x.customization);
  const isLoading = entitiesSearchLoading || keyPhrasesLoading;

  return (
    <ul className={classes.list}>
      {isLoading ? (
        <AiCircularProgress size={40} />
      ) : (
        options.map((result, i) => {
          const resultIsSelectedPrimary = selectedOptions.some(
            (x) => x.id === result.id && x.name === result.name && x.type === result.type
          );
          const resultIsSelectedSecondary = selectedSecondaryOptions?.some(
            (x) => x.id === result.id && x.name === result.name && x.type === result.type
          );
          const showAction =
            (axis === AxisType.Primary && !resultIsSelectedPrimary) ||
            (axis === AxisType.Secondary && !resultIsSelectedSecondary);
          return (
            <li className={classes.result} key={result.type + result.id + result.name}>
              <Legend
                label={result.name}
                disabled={!showAction}
                actionIcon={showAction ? <AddCircleIcon className={classes.addIcon} /> : undefined}
                onActionClick={() => {
                  if (axis === AxisType.Primary && !resultIsSelectedPrimary) {
                    setSelectedOptions([...selectedOptions, result]);
                  } else if (axis === AxisType.Secondary && !resultIsSelectedSecondary) {
                    setSelectedSecondaryOptions([...selectedSecondaryOptions, result]);
                  }
                }}
                color={entityTypeMapColor[result.type ?? EntityType.None]}
                fixedWidth={false}
              />
            </li>
          );
        })
      )}
    </ul>
  );
}
