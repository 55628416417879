import { Card, CardContent, Typography } from "@material-ui/core";
import { EmojiObjectsOutlined } from "@material-ui/icons";
import useInsight from "../../../../../../hooks/queries/insights/useInsight";
import useReaderStore from "../../../../../../store/hooks/useReaderStore";
import OverviewSection from "./OverviewSection";

export default function Analysis() {
  const { selectedDocumentId } = useReaderStore((state) => state);
  const { data } = useInsight(selectedDocumentId as string);

  return data?.insights[0].reasoning ? (
    <OverviewSection icon={<EmojiObjectsOutlined />} title="Analysis">
      <Card elevation={0} style={{ background: "#F7FAFC", borderRadius: 6 }}>
        <CardContent>
          <Typography style={{ lineHeight: 1.5, fontSize: 15, fontWeight: 400, color: "#004FD0" }} variant="body2">
            {data.insights[0].reasoning}
          </Typography>
        </CardContent>
      </Card>
    </OverviewSection>
  ) : (
    <></>
  );
}
