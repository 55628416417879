import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { createStyles, makeStyles, FormGroup, Paper, Theme, Typography, Switch, Box } from "@material-ui/core";
import React, { useState } from "react";
import { useQuery } from "react-query";
import Header from "../../../Widgets/Header/Header";
import { useAmplyfiEntities, useUnverifiedAmplyfiEntities } from "../../../../hooks/useAmplyfiEntities";
import { EntityListItem } from "../components/EntityListItem";
import useDebounce from "../../../../hooks/useDebounce";
import { getOrganisations } from "../../../../http/accounts";
import { getOrganisationName } from "../../../../helpers/organisation";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { AutocompleteType } from "../../../../models/autocomplete";
import EntityKnowledgeBaseSearch from "../../Feed/Configuration/Components/EntityKnowledgeBaseSearch";

interface SearchEntitiesCardProps {
  amplyfiEntityId?: string;
}

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) =>
  createStyles({
    action: {
      minWidth: 200,
      padding: `${spacing(1)}px ${spacing(3)}px`,
    },
    content: {
      height: "70vh",
      paddingBottom: spacing(4),
    },
    form: {
      padding: spacing(2),
      paddingTop: 0,
    },
    list: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    results: {
      overflow: "auto",
      paddingInline: spacing(2),
    },
    search: {
      marginBottom: spacing(4),
    },
    title: {
      padding: spacing(2),
      width: "100%",
    },
    searchInput: {
      flexWrap: "nowrap",

      "& .MuiInputBase-root": {
        alignItems: "center",
      },
      '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
        {
          paddingRight: 0,
        },
    },
  })
);

const CACHE_REFRESH_TIME = 4 * 60 * 60 * 1000; // 4 hours

export function SearchEntitiesCard({ amplyfiEntityId = "" }: SearchEntitiesCardProps): JSX.Element {
  const classes = useStyles();
  const [search, setSearch] = useState(amplyfiEntityId);
  const debouncedValue = useDebounce(search);
  const searchOptions = { name: debouncedValue, id: debouncedValue };
  const { data: { entities: searchedEntities = null } = {}, isLoading } = useAmplyfiEntities(searchOptions);
  const {
    data: { paginationToken, entities: unverifiedEntities = null } = {},
    isLoading: isLoadingUnverified,
    fetchNextPage,
    isFetching,
  } = useUnverifiedAmplyfiEntities();
  const { data: organisations = [] } = useQuery(
    ["all-organisations"],
    async () => (await getOrganisations()).organisations,
    { cacheTime: CACHE_REFRESH_TIME }
  );
  const isSearch = () => !!search;
  const entities = isSearch() ? searchedEntities : unverifiedEntities;

  const hasNextPage = !!paginationToken;

  const [infiniteRef] = useInfiniteScroll({
    hasNextPage,
    loading: isLoadingUnverified || isFetching,
    onLoadMore: () => {
      if (!isLoadingUnverified && !isFetching) {
        fetchNextPage();
      }
    },
    disabled: !hasNextPage || isLoadingUnverified || isFetching,
  });

  const [isOrg, setIsOrg] = useState(true);

  const handleChange = () => {
    setIsOrg(!isOrg);
  };

  return (
    <>
      <Header title="Entities" />
      <div className={classes.content}>
        <Paper className={classes.search}>
          <Typography variant="h3" className={classes.title}>
            Search Entity by
          </Typography>
          <FormGroup className={classes.form}>
            <Switch color="default" checked={!isOrg} onChange={handleChange} />{" "}
            <Box style={{ display: "flex", flexDirection: "row", marginBottom: 12 }}>
              <Typography color={isOrg ? "primary" : "textSecondary"}>Organisation</Typography>
              <Typography style={{ marginInline: 4 }}> / </Typography>
              <Typography color={!isOrg ? "primary" : "textSecondary"}>Technology</Typography>
            </Box>
            <Box className={classes.searchInput}>
              <EntityKnowledgeBaseSearch
                key={isOrg ? "Organisation" : "Technology"}
                autoFocus
                autoselect
                singleSelect
                onChange={(e) => setSearch(e[0]?.amplyfiEntityId || "")}
                type={isOrg ? AutocompleteType.Organisation : AutocompleteType.Technology}
              />
            </Box>
          </FormGroup>
        </Paper>

        <Paper className={classes.list}>
          <Typography variant="h3" className={classes.title}>
            {isSearch() ? "Search results" : "Unverified entities"}
          </Typography>
          <div className={classes.results}>
            {(isSearch() && isLoading) || (!isSearch() && isLoadingUnverified) || entities === null ? (
              <AiCircularProgress size={30} />
            ) : (
              <>
                {entities.length ? (
                  <>
                    {entities.map((entity) => (
                      <EntityListItem
                        key={entity.id}
                        entity={entity}
                        organisationName={getOrganisationName(entity.createdByOrganisation, organisations)}
                      />
                    ))}
                    {hasNextPage && (
                      <div
                        style={{
                          height: 100,
                          width: "100%",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        ref={infiniteRef}
                      >
                        <AiCircularProgress useContainer={false} size={32} />
                      </div>
                    )}
                  </>
                ) : (
                  <p>No entities found</p>
                )}
              </>
            )}
          </div>
        </Paper>
      </div>
    </>
  );
}
