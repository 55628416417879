import { useEffect, useState } from "react";
import useAuthenticatedSocket from "./useAuthenticatedSocket";
import { analyticsEvent } from "../../../helpers/analytics";

export type AmplyfiSocketResponseTypes =
  | "question"
  | "research"
  | "summary"
  | "question-about-documents"
  | "sales-pitch-email"
  | "investigation-questions"
  | "historyId"
  | "request-id";

export interface MessageSource {
  id: string;
  title: string;
  url: string;
  source: string;
}

export interface AmplyfiSocketMessage {
  complete?: boolean;
  type: AmplyfiSocketResponseTypes;
  message: string;
  sources?: MessageSource[];
}

export enum SocketStatus {
  Idle,
  Busy,
  Done,
}

export default function useAmplyfiSocketParser(messageTypes: AmplyfiSocketResponseTypes[]) {
  const [status, setStatus] = useState<SocketStatus>(SocketStatus.Idle);
  const [lastJsonMessage, setLastMessage] = useState<AmplyfiSocketMessage | null>(null);

  const socket = useAuthenticatedSocket(process.env.REACT_APP_WEBSOCKET_API as string, {
    filter: (msg) => messageTypes.includes((JSON.parse(msg.data) as AmplyfiSocketMessage).type),
    onMessage: (msg) => {
      const { message, type } = JSON.parse(msg.data) as AmplyfiSocketMessage;
      if (type === "request-id" && !!message) {
        // eslint-disable-next-line no-console
        console.info(`Connected to socket: ${message}`);
        analyticsEvent("WebSocket", "PegasusConnected", message);
      }
    },
  });

  useEffect(() => {
    if (socket.lastJsonMessage) {
      const { complete = false, message }: AmplyfiSocketMessage = socket.lastJsonMessage;
      if (complete || message === "\u0003") {
        setStatus(SocketStatus.Done);
      } else {
        setLastMessage(socket.lastJsonMessage);
      }
    }
  }, [socket.lastJsonMessage]);

  return { socket: { ...socket, lastJsonMessage }, status, setStatus };
}
