import axios from "axios";
import { PageType } from "../components/Pages/Feed/components/filterMenu/feedFilters";
import { getStartOfPastDayUtc } from "../helpers/dateHelpers";
import { CombinedAlert, getAlertType, getAlertTypes, getAlertTypesForLibrary } from "../http/alert-types";
import { useFeedViewStore } from "../store/hooks/useFeedViewStore";
import { useFiltersStore } from "../store/hooks/useFiltersStore";
import useSearchStore from "../store/hooks/useSearchStore";
import useAPI from "./queries/api/useAPI";
import { getSortingOptions } from "./useFeed";
import useOrgId from "./useOrgId";
import { UseQueryResult, useQuery } from "./useQuery";

export const alertTypesKey = "alert-types";
export const alertTypeKey = "alert-type";
export const alertTypesCountKey = "alert-type-counts";
export const alertTypesForLibraryKey = "library-alert-types";
export const sourcesCountKey = "sources-count";
export const topicsCountKey = "topics-count";

export function useAlertTypes() {
  const orgId = useOrgId();
  return useQuery([alertTypesKey], () => getAlertTypes(orgId));
}

export interface TypeCount {
  count: number;
  name: string;
}

export function useAlertTypesCount({ id: feedType }: PageType, amplyfiEntityId?: string) {
  const url = useAPI("v3", "alerts-feed/alert-types-count");
  const libraryId = useFeedViewStore(({ libraryId }) => libraryId);
  const { type } = useFeedViewStore(({ view }) => view);
  const searchTerm = useSearchStore(({ search }) => search);
  const { events, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics } = useFiltersStore(
    ({ events, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics }) => ({
      events,
      selectedSort,
      selectedSortInsights,
      strength,
      timeFilter,
      userTags,
      sectors,
      sources,
      topics,
    })
  );
  const { sortBy, sortOrder } = getSortingOptions(type, selectedSort, selectedSortInsights);
  return useQuery(
    [
      topicsCountKey,
      sourcesCountKey,
      alertTypesCountKey,
      feedType,
      amplyfiEntityId,
      libraryId,
      searchTerm,
      userTags,
      strength,
      events,
      sectors,
      sources,
      timeFilter,
      selectedSort,
    ],
    async () => {
      const { data } = await axios.get<TypeCount[]>(url, {
        params: {
          feedType,
          sortBy,
          sortOrder,
          userTags,
          entityId: amplyfiEntityId,
          events,
          libraryId: libraryId ?? undefined,
          searchTerm: searchTerm ?? undefined,
          startDate: getStartOfPastDayUtc(timeFilter),
          strength,
          sectors,
          sources,
          topics,
        },
      });
      return data;
    }
  );
}

export function useAlertType(id?: string | null) {
  const orgId = useOrgId();
  return useQuery([alertTypeKey, id], () => (id ? getAlertType(orgId, id) : null));
}

export function useAlertTypesForLibrary(libraryId: string): UseQueryResult<CombinedAlert[], Error> {
  const { data } = useAlertTypes();
  return useQuery([alertTypesForLibraryKey, libraryId, data], async () => {
    const response = await getAlertTypesForLibrary(libraryId);
    const d =
      response
        ?.map((d) => {
          const r = data?.find((r) => r.alertTypeId === d.alertTypeId);
          return { ...r, ...d };
        })
        .filter((d) => data?.find((r) => r.alertTypeId === d.alertTypeId)) || [];
    return d;
  });
}

export function useAlertsForAlertType(libraryId: string, alertTypeId: string) {
  const { data } = useAlertTypesForLibrary(libraryId);
  return useQuery(["alerts-for-alert-type", alertTypeId, data], () =>
    data?.filter((d) => d.alertTypeId === alertTypeId)
  );
}

export function useSourcesTypesCount({ id: feedType }: PageType, amplyfiEntityId?: string) {
  const url = useAPI("v3", "alerts-feed/sources-count");
  const libraryId = useFeedViewStore(({ libraryId }) => libraryId);
  const { type } = useFeedViewStore(({ view }) => view);
  const searchTerm = useSearchStore(({ search }) => search);
  const { events, signalTypes: alertTypes, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics } = useFiltersStore(
    ({ events, signalTypes, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics }) => ({
      events,
      signalTypes,
      selectedSort,
      selectedSortInsights,
      strength,
      timeFilter,
      userTags,
      sectors,
      sources,
      topics,
    })
  );
  const { sortBy, sortOrder } = getSortingOptions(type, selectedSort, selectedSortInsights);
  return useQuery(
    [
      sourcesCountKey,
      feedType,
      alertTypes,
      amplyfiEntityId,
      libraryId,
      searchTerm,
      userTags,
      strength,
      events,
      timeFilter,
      selectedSort,
      sectors,
      sources,
      topics,
    ],
    async () => {
      const { data } = await axios.get<TypeCount[]>(url, {
        params: {
          feedType,
          alertTypes,
          sortBy,
          sortOrder,
          userTags,
          sectors,
          sources,
          topics,
          entityId: amplyfiEntityId,
          events,
          libraryId: libraryId ?? undefined,
          searchTerm: searchTerm ?? undefined,
          startDate: getStartOfPastDayUtc(timeFilter),
          strength,
        },
      });
      return data;
    }
  );
}

export function useTopicsTypesCount({ id: feedType }: PageType, amplyfiEntityId?: string) {
  const url = useAPI("v3", "alerts-feed/topics-count");
  const libraryId = useFeedViewStore(({ libraryId }) => libraryId);
  const { type } = useFeedViewStore(({ view }) => view);
  const searchTerm = useSearchStore(({ search }) => search);
  const { events, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics } = useFiltersStore(
    ({ events, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics }) => ({
      events,
      selectedSort,
      selectedSortInsights,
      strength,
      timeFilter,
      userTags,
      sectors,
      sources,
      topics,
    })
  );
  const { sortBy, sortOrder } = getSortingOptions(type, selectedSort, selectedSortInsights);
  return useQuery(
    [
      topicsCountKey,
      feedType,
      amplyfiEntityId,
      libraryId,
      searchTerm,
      userTags,
      strength,
      events,
      timeFilter,
      selectedSort,
      sectors,
      sources,
      topics,
    ],
    async () => {
      const { data } = await axios.get<TypeCount[]>(url, {
        params: {
          feedType,
          sortBy,
          sortOrder,
          userTags,
          sectors,
          sources,
          topics,
          entityId: amplyfiEntityId,
          events,
          libraryId: libraryId ?? undefined,
          searchTerm: searchTerm ?? undefined,
          startDate: getStartOfPastDayUtc(timeFilter),
          strength,
        },
      });
      return data;
    }
  );
}
