import { Moment } from "moment";
import { useQuery, UseQueryResult } from "./useQuery";
import { TimeIntervalType } from "../helpers/timeIntervals";
import { convertSearchStateToSearchRequestV2 } from "../helpers/search";
import { searchSentiment } from "../http/sentiment";
import { SearchRequestV2 } from "../models/search";
import { SentimentSectorBarEntry } from "../models/sector";
import { SentimentHistogramEntry, SentimentRegionEntry, SentimentTimelinePoint } from "../models/sentiment";
import { useAnalyseSelector } from "../store/reducers";

export default function useSentiment(
  interval?: TimeIntervalType,
  startDate?: Moment | null,
  endDate?: Moment | null
): UseQueryResult<
  {
    sectorSentiments: SentimentSectorBarEntry[];
    sentimentHistogram: (
      | SentimentHistogramEntry
      | {
          documentCount: null;
          sentiment: number;
        }
    )[];
    regionSentiments: SentimentRegionEntry[];
    sentimentTimeline: SentimentTimelinePoint[];
  },
  unknown
> {
  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const convertedurl = convertSearchStateToSearchRequestV2(url);
  const searchRequest: SearchRequestV2 = {
    ...convertedurl,
    systemFilters: {
      ...convertedurl.systemFilters,
      dateInterval: interval,
    },
    userFilters: {
      ...convertedurl.userFilters,
      startDate: startDate?.toISOString() ?? convertedurl.userFilters.startDate,
      endDate: endDate?.endOf("day").toISOString() ?? convertedurl.userFilters.endDate,
    },
  };
  return useQuery(["sentiment", searchRequest, url.libraries], async () => {
    const response = await searchSentiment(searchRequest, url.libraries);
    const { sectorSentiments, sentimentHistogram, sentimentOverTime, regionSentiments } = response;
    const mappedSentiment = [];
    for (let i = -10; i <= 10; i++) {
      const sentimentToTest = i / 10;
      const apiValue = sentimentHistogram.find((x) => x.sentiment === sentimentToTest);
      if (!apiValue) {
        mappedSentiment.push({
          documentCount: null,
          sentiment: sentimentToTest,
        });
      } else {
        mappedSentiment.push(apiValue);
      }
    }
    return {
      sectorSentiments: sectorSentiments
        .map<SentimentSectorBarEntry>((sector) => ({
          count: sector.documentCount,
          id: sector.sector,
          label: sector.sector,
          sentiment: sector.sentiment,
          subsectors: [],
        }))
        .sort((a, b) => b.count - a.count),
      // this is so chartJS will
      // 1) display a minimum bar length
      // 2) not display the bar for 0s
      sentimentHistogram: mappedSentiment.map((x) => (x.documentCount === 0 ? { ...x, documentCount: null } : x)),
      regionSentiments,
      sentimentTimeline: sentimentOverTime,
    };
  });
}
