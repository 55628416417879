import { MessageFrom } from "../sockets/types";
import { AmplyfiSocketMessage } from "../sockets/useAmplyfiSocketParser";
import { Message } from "./useQAQuery";

interface ParserProps {
  message: AmplyfiSocketMessage;
  messages: Message[];
}

export default function parseQAMessage(props: ParserProps) {
  return JSONParser(props);
}

function handleErrors(props: ParserProps): string | undefined {
  const { message, messages } = props;
  const lastMessageIsUser = messages.length > 0 && messages[messages.length - 1].from === MessageFrom.User;
  if (!message.message) {
    return undefined;
  }
  if (message.message.toLowerCase() === "internal server error") {
    if (lastMessageIsUser) {
      return "Sorry, I can't answer that right now.";
    } else {
      return undefined;
    }
  }
  return undefined;
}

// In the future, I expect the API to potentially handle other messages as JSON
function JSONParser(props: ParserProps): AmplyfiSocketMessage {
  const errorMessage = handleErrors(props);
  return errorMessage
    ? { message: errorMessage, type: props.message.type, sources: [] }
    : { ...props.message, sources: props.message.sources || [] };
}

export function parseTextMessage(messages: Message[], messageData: AmplyfiSocketMessage) {
  const { message, sources } = parseQAMessage({ message: messageData, messages });
  const lastMessageIsSystem = messages.length > 0 && messages[messages.length - 1].from === MessageFrom.System;

  const newMessages = lastMessageIsSystem
    ? messages.slice(0, messages.length - 1).concat([
        {
          from: MessageFrom.System,
          type: "question",
          text: messages[messages.length - 1].text + (message || ""),
          sources,
          isFinished: false,
          suggestions: [],
        },
      ])
    : messages.concat([
        {
          from: MessageFrom.System,
          text: message || "",
          sources,
          type: "question",
          isFinished: false,
          suggestions: [],
        },
      ]);

  return newMessages;
}

export function parseSuggestionMessage(messages: Message[], messageData: AmplyfiSocketMessage): Message[] {
  const { message } = parseQAMessage({ messages, message: messageData });

  if (messages[messages.length - 1].type !== "investigation-questions") {
    return [
      ...messages,
      {
        from: MessageFrom.System,
        text: "",
        type: "investigation-questions",
        isFinished: false,
        suggestions: [message],
      },
    ];
  } else {
    if (message.endsWith("||")) {
      return messages.slice(0, messages.length - 1).concat([
        {
          from: MessageFrom.System,
          text: "",
          type: "investigation-questions",
          isFinished: false,
          suggestions: [...messages[messages.length - 1].suggestions, ""],
        },
      ]);
    } else {
      // concat the message to the last message's last suggestion's text
      const lastMessage = messages[messages.length - 1];
      const lastSuggestion = lastMessage.suggestions[lastMessage.suggestions.length - 1];
      const newSuggestion = lastSuggestion + message;
      const newSuggestions = lastMessage.suggestions
        .slice(0, lastMessage.suggestions.length - 1)
        .concat([newSuggestion]);
      return messages.slice(0, messages.length - 1).concat([
        {
          from: MessageFrom.System,
          text: "",
          type: "investigation-questions",
          isFinished: false,
          suggestions: newSuggestions,
        },
      ]);
    }
  }
}

export function parseMessage(messages: Message[], messageData: AmplyfiSocketMessage) {
  if (messageData.type === "question" || messageData.type === "question-about-documents") {
    return parseTextMessage(messages, messageData);
  } else if (messageData.type === "investigation-questions") {
    return parseSuggestionMessage(messages, messageData);
  }
  return messages;
}
