import axios from "axios";
import { ClusterSearch } from "../components/Widgets/TopicWheel/types";

const clusterUrl = process.env.REACT_APP_CLUSTERING_API;

export async function getClusterWithConnectorsFilter(
  searchId: string,
  connectorIds: string[],
  maxClusterDepth: number,
  minClusterSize: number
): Promise<{ clusters: ClusterSearch[] }> {
  const { data } = await axios.post<{ clusters: ClusterSearch[] }>(
    `${clusterUrl}/${searchId}/${maxClusterDepth}/${minClusterSize}`,
    { connectorIds }
  );
  return data;
}
