import { Box, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { MouseEventHandler, useState } from "react";
import { getOrgId, getUserId } from "../../../helpers/user";
import { useLibraryEntities } from "../../../hooks/useCollectionConfigs";
import useDocumentLibraries from "../../../hooks/useDocumentLibraries";
import useOrgId from "../../../hooks/useOrgId";
import { LibraryEntity } from "../../../http/harvesterCollections";
import { addEntityToLibrary } from "../../../http/libraries";
import { AutocompleteType } from "../../../models/autocomplete";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import SimpleDialog, { AbstractDialogProps } from "../../Widgets/Dialog/SimpleDialog";
import InterestSearch from "./Configuration/Components/InterestSearch";
import SelectField from "./Configuration/Components/SelectField";
import { useCanUserChangeFeed } from "../../../hooks/useFeedUserType";
import { Warning } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  interestSearchContainer: {
    "& > *": {
      maxWidth: "100%",
    },
  },
}));

interface MonitorNewOrganisationDialogProps extends AbstractDialogProps {
  defaultFeedId: string;
  entityName: string;
}

export default function MonitorNewOrganisationDialog(props: MonitorNewOrganisationDialogProps) {
  const { defaultFeedId, entityName, onConfirm } = props;
  const orgId = useOrgId();
  const { user } = useAuth0();
  const { data = [], refetch } = useDocumentLibraries(getOrgId(user));
  const userFeeds = data.filter(
    ({ dashboards, subscribedUserIds }) => subscribedUserIds.includes(getUserId(user)) && dashboards.length > 0
  );
  const classes = useStyles();
  const [selectedEntity, setSelectedEntity] = useState<LibraryEntity | null>(null);
  const [selectedFeed, setSelectedFeed] = useState<string | null>(defaultFeedId || null);
  const { data: feedEntities = [], refetch: refetchLibraryEntities } = useLibraryEntities(selectedFeed || "");
  const canUserChangeFeed = useCanUserChangeFeed(selectedFeed || "");

  const save: MouseEventHandler = async (e) => {
    e.persist();
    const feed = userFeeds.find((d) => d.id === selectedFeed);
    if (selectedEntity && selectedFeed && feed) {
      if (!feedEntities.find((d) => d.amplyfiEntityId === selectedEntity.amplyfiEntityId)) {
        await addEntityToLibrary(orgId, selectedFeed, selectedEntity.amplyfiEntityId);
        await refetch();
        await refetchLibraryEntities();
      }
      onConfirm?.(e);
    }
  };

  return (
    <SimpleDialog
      {...props}
      title="Monitor an Organisation"
      onConfirm={selectedEntity && selectedFeed && canUserChangeFeed ? save : undefined}
      content={
        <Box>
          <Typography variant="body2">Organisation</Typography>
          <Box display="flex" className={classes.interestSearchContainer}>
            <InterestSearch
              autoFocus
              autoselect
              singleSelect
              onChange={(e) => setSelectedEntity(e[0])}
              defaultText={entityName}
              type={AutocompleteType.Organisation}
            />
          </Box>
          <SelectField
            inDialog
            onChange={(e) => setSelectedFeed(e.target.value as string)}
            defaultValue={selectedFeed}
            fullWidth
            label="Select Feed"
          >
            {userFeeds.map((d) => (
              <MenuItem value={d.id}>{d.name}</MenuItem>
            ))}
          </SelectField>
          <Box display="flex" alignItems="center" marginY={1}>
            {!canUserChangeFeed ? (
              <>
                <Warning style={{ fontSize: 20, marginRight: 4 }} />
                <Typography variant="h6">You do not have permission to change this feed</Typography>
              </>
            ) : (
              ""
            )}
          </Box>
        </Box>
      }
    />
  );
}
