import axios, { AxiosInstance } from "axios";
import { AccountGroup } from "../models/account";
import { OrganisationList } from "../models/organisation";

export const accountClient: AxiosInstance = axios.create({ baseURL: process.env.REACT_APP_ACCOUNTS_API_V2 });

interface OrganisationsResponse {
  count: number;
  organisations: AccountGroup[];
}

export async function getOrganisations(): Promise<OrganisationsResponse> {
  const {
    data: { organisations, total_items },
  } = await accountClient.get<OrganisationList>("/organisations/");

  const response: OrganisationsResponse = {
    count: total_items,
    organisations: organisations.map(({ organisation_id, ...org }) => ({
      ...org,
      id: organisation_id,
      members: null,
      subgroups: null,
    })),
  };
  return response;
}

export interface TermsMetadata {
  dateAccepted: string;
  version: string;
}

interface UserResponse {
  user_metadata: { terms?: TermsMetadata };
}

export async function getTermsVersion(): Promise<{ version: string }> {
  const { data } = await accountClient.get<{ version: string }>("/terms/currentVersion");
  return data;
}

export async function getCurrentUser(userId: string): Promise<UserResponse> {
  const { data } = await accountClient.get<UserResponse>(`/users/${encodeURI(userId)}/`);
  return data;
}

export async function getTerms(): Promise<string> {
  const { data } = await accountClient.get("/terms/");
  return data;
}

export interface UserPatchData {
  name?: string;
  password?: string;
  blocked?: boolean;
  user_metadata?: { terms?: TermsMetadata };
}

interface UserEntry {
  name: string;
  email: string;
  userId: string;
  picture: string;
  lastLogin: string;
  loginsCount: number;
  blocked: boolean;
}

const patchReplace = (name: string, value: string | boolean | UserPatchData["user_metadata"]) => ({
  path: `/${name}`,
  value,
  op: "replace",
});

export async function updateUserById(userId: string, data: UserPatchData) {
  const { name, password, blocked, user_metadata } = data;
  const body = [
    ...(name !== undefined ? [patchReplace("name", name)] : []),
    ...(password !== undefined ? [patchReplace("password", password)] : []),
    ...(blocked !== undefined ? [patchReplace("blocked", blocked)] : []),
    ...(user_metadata !== undefined ? [patchReplace("user_metadata", user_metadata)] : []),
  ];
  return await accountClient.patch<UserEntry>(`/users/${encodeURI(userId)}/`, body);
}
