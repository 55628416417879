import { createContext, useState } from "react";
import { BaseWizardState } from "../components/Widgets/Wizard/Wizard";

interface WizardState extends BaseWizardState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface WizardProviderProps {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WizardContext = createContext<[WizardState, (wizardId: string, state: any) => void]>([
  { initialPage: 0, nextDisabled: false },
  () => void 0,
]);

export function WizardProvider(props: WizardProviderProps) {
  const [state, setState] = useState<WizardState>({ initialPage: 0, nextDisabled: false });

  function updateState<T>(wizardId: string, state: T) {
    setState((prevState) => ({ ...prevState, [wizardId]: state }));
  }

  return <WizardContext.Provider value={[state, updateState]}>{props.children}</WizardContext.Provider>;
}
