import {
  arc as d3arc,
  partition as d3partition,
  hierarchy,
  scaleOrdinal,
  BaseType,
  HierarchyRectangularNode,
  ValueFn,
  Arc,
  ScaleOrdinal,
} from "d3";
import { Cluster } from "./types";

type DataType<T> = HierarchyRectangularNode<T>;

export type ClickHandler<T> = ValueFn<BaseType | SVGPathElement, AugmentedHierarchy<T>, void>;

type Hierarchy<T> = DataType<T> & {
  value: number;
};

type AugmentedHierarchy<T> = Hierarchy<T> & {
  current: DataType<T>;
  parent: DataType<T>;
};

export interface Flare {
  children?: Datum[];
  name: string;
}

export interface Datum {
  originalValue: number;
  children?: Datum[];
  clusterId: string;
  index: number;
  name: string;
  parentClusterId: string | null;
  value: number;
  resultIds: string[];
}

const COLOURS = ["#FAA2C1", "#339AF0", "#63E6BE", "#FFEc99", "#FFC078", "#868E96"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arc = <T,>(radius: number): Arc<any, DataType<T>> => {
  return d3arc<DataType<T>>()
    .startAngle(({ x0 }) => x0)
    .endAngle(({ x1 }) => x1)
    .padAngle(({ x0, x1 }) => Math.min((x1 - x0) / 4, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius(({ y0 }) => y0 * radius)
    .outerRadius(({ y0, y1 }) => Math.max(y0 * radius, y1 * radius - 1));
};

const color: ScaleOrdinal<string, string> = scaleOrdinal(COLOURS);

export const partition = (data: Flare): AugmentedHierarchy<Datum> => {
  const root = (hierarchy(data) as Hierarchy<Datum>).count().sort((a, b) => b.value - a.value);

  return d3partition<Datum>().size([2 * Math.PI, root.height + 1])(root) as AugmentedHierarchy<Datum>;
};

export const arcVisible = ({ x0, x1, y0, y1 }: DataType<unknown>): boolean => y1 <= 3 && y0 >= 1 && x1 > x0;

export const labelTransform =
  (radius: number) =>
  ({ x0, x1, y0, y1 }: DataType<unknown>): string => {
    const x = (((x0 + x1) / 2) * 180) / Math.PI;
    const y = ((y0 + y1) / 2) * radius;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  };

const MAX_SIBLINGS = 6;

const transformClusters = (clusters: Cluster[] = [], parentClusterId: string | null = null): Datum[] => {
  // Remove the more/less node. It will always be the last one
  if (clusters[clusters.length - 1]?.isMoreLess) {
    clusters.pop();
  }

  const trimmedClusters = clusters.sort(({ count: c1 }, { count: c2 }) => c2 - c1).slice(0, MAX_SIBLINGS);

  return trimmedClusters.map(({ children, clusterId, count: value, name, resultIds }, i) => ({
    clusterId,
    name,
    parentClusterId,
    value,
    children: transformClusters(children, clusterId),
    originalValue: value,
    resultIds,
    index: i + 1,
  }));
};

export const flare = ({ children }: Cluster): Flare => ({
  children: transformClusters(children),
  name: "",
});

export const title = (d: AugmentedHierarchy<Datum>): string => {
  const [
    {
      data: { name },
    },
  ] = d.ancestors();
  return name;
};

export const fillColor = (d: AugmentedHierarchy<Datum>): string => {
  while (d.depth > 1) {
    d = d.parent;
  }
  return color(d.data.name);
};

export const opacity = (d: AugmentedHierarchy<Datum>): number => {
  const { children, parent, value } = d;
  const step = 0.1;
  if (children?.length) {
    return 1;
  }
  const uniqueValues = [...new Set(parent.children?.map((c) => c.value))];
  return 1 - (uniqueValues.indexOf(value) + 2) * step;
};

export const testId =
  (preFix = "", postFix = "") =>
  ({ data, parent }: AugmentedHierarchy<Datum>): string =>
    [
      ...(preFix ? [preFix] : []),
      ...(parent?.data.index ? [parent.data.index] : []),
      data.index,
      ...(postFix ? [postFix] : []),
    ].join("-");
