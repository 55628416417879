import moment from "moment";
import React, { useEffect, useState } from "react";
import { cardDetails } from "../../helpers/cardDetailsHelper";
import { dateGranularity } from "../../helpers/dateHelpers";
import sentimentDataToLineChartData from "../../helpers/sentimentDataToLineChartData";
import useSentiment from "../../hooks/useSentiment";
import { useAnalyseSelector } from "../../store/reducers";
import { DEFAULT_END, DEFAULT_INTERVAL, DEFAULT_START } from "../../store/reducers/searchRequest/url-functions";
import SentimentLineChart from "../Charts/SentimentLineChart";
import { useAnalysisComponentState } from "../../hooks/useAnalysisComponentState";
import { testId } from "@amplyfi/ui-components/testHelpers";
import AnalysisComponent from "@amplyfi/ui-components/components/AnalysisComponent";
import FeedbackModal from "../Widgets/Feedback/FeedbackModal";
import { getExportV2 } from "../../helpers/exportDataHelper";
import ExportModalContent from "@amplyfi/ui-components/components/ExportContent";
import TimelineDialog from "../Widgets/TimelineDialog";
import { useTimelineFilterLabel } from "../../hooks/useTimelineFilterLabel";
import VisualisationSettingsContent from "../Widgets/VisualisationSettingsContent";

export default function SentimentOverTimeCard(): JSX.Element {
  const { lowestInterval, interval, parsed: url } = useAnalyseSelector((x) => x.searchRequest.url);
  const [sentimentEndDate, setSentimentEndDate] = useState<moment.Moment | null>(
    url.endDate ? moment.utc(url.endDate) : DEFAULT_END.clone()
  );
  const [sentimentStartDate, setSentimentStartDate] = useState<moment.Moment | null>(
    url.startDate ? moment.utc(url.startDate) : DEFAULT_START.clone() ?? null
  );
  const { smoothing } = useAnalyseSelector((x) => x.searchResponse.documentTimeline);
  const { data: sentimentResponse, isLoading, isError } = useSentiment(interval, sentimentStartDate, sentimentEndDate);
  const sentimentTimeline = sentimentResponse?.sentimentTimeline ?? [];
  const timelineFilterLabel = useTimelineFilterLabel();

  useEffect(() => {
    setSentimentStartDate(url.startDate ? dateGranularity(moment.utc(url.startDate)) : null);
  }, [url.startDate]);

  useEffect(() => {
    setSentimentEndDate(moment.utc(url.endDate).endOf("day"));
  }, [url.endDate]);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const analysisState = useAnalysisComponentState(
    isError,
    isLoading,
    sentimentTimeline.length === 0,
    setShowFeedbackModal
  );

  return (
    <>
      <AnalysisComponent
        id="sentiment-over-time"
        data-testid={testId("sentiment-over-time-graph")}
        infoTooltipContent={cardDetails.sentimentOverTime.readMore}
        onFeedbackClick={() => setShowFeedbackModal(true)}
        title={cardDetails.sentimentOverTime.title}
        exportContent={
          <ExportModalContent
            data-testid={testId("sentiment-over-time-graph-export")}
            componentName={cardDetails.sentimentOverTime.title}
            handleClose={() => void 0}
            handleExport={({ fileName }: { fileName: string }) => {
              getExportV2(fileName, "sentimentTimeline", {
                systemFilters: {
                  dateInterval: interval,
                },
              });
            }}
          />
        }
        timeContent={<TimelineDialog includeUndatedDocuments={url.includeUndatedDocuments} />}
        analysisState={analysisState?.state}
        onPrimaryClick={analysisState?.primaryAction}
        onSecondaryClick={analysisState?.secondaryAction}
        timelineFilterLabel={timelineFilterLabel}
        vizualisationContent={
          <VisualisationSettingsContent
            data-testid="sentiment-timeline"
            lowestInterval={lowestInterval}
            timeInterval={interval}
          />
        }
      >
        <div>
          <SentimentLineChart
            sentiment={sentimentDataToLineChartData(sentimentTimeline)}
            interval={interval ?? DEFAULT_INTERVAL}
            smoothing={smoothing}
          />
          <FeedbackModal
            open={showFeedbackModal}
            onClose={(_e, _reason) => setShowFeedbackModal(false)}
            showCloseIcon={false}
            componentName={cardDetails.sentimentOverTime.title}
          />
        </div>
      </AnalysisComponent>
    </>
  );
}
