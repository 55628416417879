import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Box, Typography, makeStyles } from "@material-ui/core";

interface OverviewSectionProps {
  title: string;
  icon: JSX.Element;
  children?: JSX.Element;
  isLoading?: boolean;
  minHeight?: number;
}

const useStyles = makeStyles(() => ({
  iconContainer: {
    "& svg": {
      color: "#004FD0",
    },
  },
}));

export default function OverviewSection(props: OverviewSectionProps) {
  const { title, icon, children, isLoading, minHeight } = props;
  const classes = useStyles();
  return (
    <Box minHeight={minHeight} padding={2}>
      <Box className={classes.iconContainer} marginBottom={1} display="flex" alignItems="center">
        {icon}
        <Typography style={{ marginLeft: 8 }} variant="h4">
          {title}
        </Typography>
      </Box>
      {isLoading && <AiCircularProgress />}
      {!isLoading && children}
    </Box>
  );
}
