import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    "& > *": {
      flex: "1 1 auto",
    },
    "& > *:last-child": {
      [theme.breakpoints.down("md")]: {
        width: 350,
      },
      [theme.breakpoints.down("sm")]: {
        width: 150,
      },
    },
    "& > *:first-child": {
      width: 300,
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        width: 200,
      },
      [theme.breakpoints.down("sm")]: {
        width: 170,
        flexGrow: 0,
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      "& > *": {
        display: "block",
        marginTop: theme.spacing(2),
        width: "100% !important",
      },
    },
  },
  compact: {
    display: "block",
    "& > *": {
      display: "block",
      marginTop: theme.spacing(0),
      width: "100% !important",
    },
  },
}));

interface DualPaneProps {
  compact?: boolean;
  children: [JSX.Element | null | undefined, JSX.Element | null | undefined];
}

export default function DualPane(props: DualPaneProps) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, props.compact && classes.compact)}>
      {props.children[0] && <div>{props.children[0]}</div>}
      {props.children[1] && <div>{props.children[1]}</div>}
    </div>
  );
}
