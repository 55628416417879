import axios from "axios";
import { Analysis } from "../models/analysis";

const SAVED_ANALYSIS_URL = process.env.REACT_APP_SAVED_ANALYSES_API as string;

export async function getAnalyses(): Promise<Analysis[]> {
  const { data } = await axios.get<Analysis[]>(SAVED_ANALYSIS_URL);
  return data;
}

export async function getAnalysis(id: string): Promise<Analysis[]> {
  const { data } = await axios.get<Analysis[]>(`${SAVED_ANALYSIS_URL}/${id}`);
  return data;
}

export async function createAnalysis(name: string, content: string): Promise<Analysis> {
  const { data } = await axios.post<Analysis>(SAVED_ANALYSIS_URL, { name, content });
  return data;
}

export async function updateContent(id: string, content: string): Promise<Analysis> {
  const { data } = await axios.patch<Analysis>(`${SAVED_ANALYSIS_URL}/${id}`, { content });
  return data;
}

export async function renameAnalysis(id: string, name: string): Promise<Analysis> {
  const { data } = await axios.patch<Analysis>(`${SAVED_ANALYSIS_URL}/${id}`, { name });
  return data;
}

export async function deleteAnalysis(id: string): Promise<Analysis> {
  const { data } = await axios.delete<Analysis>(`${SAVED_ANALYSIS_URL}/${id}`);
  return data;
}
