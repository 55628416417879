import { User } from "@auth0/auth0-react";
import { Domains, AuthUser, Organisation, AmplyfiUser } from "../models/user";

const amplyfiDomain: Domains = "https://amplyfi.com";

export const getUserId = (user?: User): string => user?.sub || "";

type GetOrganisationFnOverload = {
  (user: User): Organisation;
  (user?: User): Organisation | undefined;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOrganisation: GetOrganisationFnOverload = (user: any): any =>
  user?.[amplyfiDomain]?.membership?.organisation;

export const getGroups = (user?: AuthUser): Organisation[] => user?.[amplyfiDomain]?.membership?.groups || [];

const getOrganisationId = (user?: User): string => getOrganisation(user)?.id || "";

export const getPermissions = (user?: AuthUser): string[] => user?.[amplyfiDomain]?.permissions || [];

export { getOrganisationId as getOrgId };

export const getUserPicture = (userId: string, users: AmplyfiUser[]): undefined | string =>
  users.find((u) => u.userId === userId)?.picture;

export const getUserName = (userId: string, users: AmplyfiUser[]): string =>
  users.find((u) => u.userId === userId)?.name || "Former user";

// Permissions

type UserPermissionGenerator = (subFeature?: string) => string;
type PermissionAction = "account" | "read" | "create" | "update" | "delete";

export type CanUserPermissionCheck = (user?: AuthUser) => boolean;

const userPermissionGenerator = (action: PermissionAction, feature: string, subFeature?: string): string =>
  [action, feature, ...(subFeature ? [subFeature] : [])].join(":");

export const userPermission = (feature: string): Record<PermissionAction, UserPermissionGenerator> => ({
  account: (subFeature) => userPermissionGenerator("account", feature, subFeature),
  create: (subFeature) => userPermissionGenerator("create", feature, subFeature),
  delete: (subFeature) => userPermissionGenerator("delete", feature, subFeature),
  read: (subFeature) => userPermissionGenerator("read", feature, subFeature),
  update: (subFeature) => userPermissionGenerator("update", feature, subFeature),
});

export const canUserCreateLibrary: CanUserPermissionCheck = (user) =>
  getPermissions(user).includes(userPermission("library").create());

// Security

const auth0IdentityProvider = "auth0";

export const hasAuth0Identity = (identities: AmplyfiUser["identities"] = []): boolean =>
  identities.some(({ provider }) => provider === auth0IdentityProvider);
