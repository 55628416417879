import { ChartCustomizationType } from "./chart-customizations";
import { QueryType } from "./query";
import { SentenceFilterResponse } from "./sentence";

export enum EntityType {
  Country = "Country",
  KeyPhrase = "KeyPhrase",
  Organisation = "Organisation",
  Person = "Person",
  Settlement = "Settlement",
  None = "None",
}

export interface EntityPrevalence {
  name: string;
  id: string;
  prevalence: number;
}

interface HeatMapTopic {
  id: string;
  name: string;
  type: ChartCustomizationType;
}

export interface HeatMapEntry {
  heatmapPrimary: HeatMapTopic;
  heatmapSecondary: HeatMapTopic;
  score: number;
  hex?: string;
}

export enum ExcludeOrInclude {
  Exclude = "Exclude",
  Include = "Include",
}

export interface NamedItem {
  id: string;
  name: string;
}

export interface Keyword extends NamedItem {
  type?: EntityType;
}

export interface Entity extends Keyword {
  wikiDataId?: string;
  excludeOrInclude?: ExcludeOrInclude;
  sentencesTypes?: keyof SentenceFilterResponse;
  queryType?: QueryType;
  index?: number;
}

export interface PositionedEntity extends Keyword {
  prevalence: number;
  position?: number;
}

export function formatEntity(entity: Entity): string {
  return `${entity.name} (${entity.type ?? "Free Text"})`;
}
