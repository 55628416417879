import { FormControl, MenuItem, Select, Switch } from "@material-ui/core";
import { getOrgId, getUserId } from "../../../../helpers/user";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import AbstractSettingsPage from "../AbstractSettingsPage";
import { SimpleUserSection, UserSectionCard } from "../UserSection";
import useUserNotifications from "../../../../hooks/useUserNotifications";
import { LibraryItemSettings } from "../../../../models/search/documentLibrary";
import { useNotificationState } from "../../../../hooks/form/useNotificationState";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { AlertHour } from "../../Feed/helpers/alertFrequency";
import { updateUserNotifications } from "../../../../http/user-notifications";

export default function DigestEmails() {
  const { user } = useAuth0();
  const organisationId = getOrgId(user);
  const userId = getUserId(user);

  const {
    data: fetchedNotifications,
    isLoading: isLoadingNotifications,
    refetch: refetechNotifications,
    isRefetching: isRefetchingNotifications,
  } = useUserNotifications();
  const [{ form, hasChanged, state }, setState] = useNotificationState<LibraryItemSettings>(fetchedNotifications);

  async function saveNotifications(): Promise<void> {
    await updateUserNotifications(organisationId, userId, state);
    refetechNotifications();
  }

  return (
    <>
      <AbstractSettingsPage title="Digest Emails">
        <UserSectionCard
          title="Manage Notifications"
          loading={!fetchedNotifications || isLoadingNotifications || isRefetchingNotifications}
          confirmButtonDisabled={!hasChanged}
          confirmButtonText="Save"
          onConfirm={async () => await saveNotifications()}
        >
          <SimpleUserSection title="Send personal digest emails for new signals">
            <div>
              <Switch
                checked={form.sendEmails.value}
                data-testid={testId("user-settings", "send-emails")}
                disabled={form.sendEmails.disabled}
                onChange={(_, sendEmails) => setState.sendEmails(sendEmails)}
              />
            </div>
          </SimpleUserSection>
          <SimpleUserSection title="Time of day">
            <FormControl>
              <Select
                data-testid={testId("user-settings", "email-hour")}
                disabled={form.emailHourUTC.disabled}
                value={form.emailHourUTC.value}
                onChange={({ target: { value } }) => setState.emailHourUTC(value as AlertHour)}
                fullWidth
              >
                {form.emailHourUTC.options.map(({ value, children = value }) => (
                  <MenuItem value={value} key={`${value}`}>
                    {children}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </SimpleUserSection>
          <SimpleUserSection title="Day of delivery">
            <FormControl>
              <Select
                data-testid={testId("user-settings", "email-hour")}
                disabled={form.emailDayUTC.disabled}
                value={form.emailDayUTC.value}
                onChange={({ target: { value } }) => setState.emailDayUTC(value as string)}
                fullWidth
              >
                {form.emailDayUTC.options.map(({ value, children = value }) => (
                  <MenuItem value={value} key={`${value}`}>
                    {children}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </SimpleUserSection>
          <SimpleUserSection title="Send digest emails when there are no new signals">
            <div>
              <Switch
                checked={form.sendEmptyEmails.value}
                data-testid={testId("library-settings", "send-empty-emails")}
                disabled={form.sendEmptyEmails.disabled}
                onChange={(_, sendEmptyEmails) => setState.sendEmptyEmails(sendEmptyEmails)}
              />
            </div>
          </SimpleUserSection>
        </UserSectionCard>
      </AbstractSettingsPage>
    </>
  );
}
