import { LDProvider } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";
import { getOrganisation, getUserId } from "../../helpers/user";
import { useAuth0 } from "@auth0/auth0-react";
import { featureFlagUsed } from "../../helpers/analytics";
import { AuthUser } from "../../models/user";

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
  component: React.ElementType;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrivateRoute = ({ component: Component, path, ...rest }: PrivateRouteProps) => {
  const { isLoading: loading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.href },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const render = (props: any) => (isAuthenticated === true ? <Component {...props} /> : null);
  const org = getOrganisation(user as AuthUser);

  return isAuthenticated && user && org ? (
    <LDProvider
      context={{
        kind: "multi",
        organization: {
          anonymous: false,
          key: org.id,
          kind: "organization",
          name: org.name,
        },
        user: {
          anonymous: false,
          key: getUserId(user as AuthUser),
          kind: "user",
          organisationId: org.id,
          organisationName: org.name,
        },
      }}
      clientSideID={process.env.REACT_APP_LAUNCHDARKLY_CLIENTID as string}
      options={{
        inspectors: [
          {
            type: "flag-used",
            name: "dd-inspector",
            method: featureFlagUsed,
          },
        ],
      }}
    >
      <Route {...rest} path={path} render={render} />
    </LDProvider>
  ) : null;
};

export default PrivateRoute;
