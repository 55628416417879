import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import { MidLightGrey } from "@amplyfi/ui-components/theme/colors";
import clsx from "clsx";
import React from "react";
import AnalyseTooltip from "@amplyfi/ui-components/components/Tooltip";
import { Variant } from "@material-ui/core/styles/createTypography";

interface ExpandableHeaderProps {
  title: string;
  tooltipText?: string;
  titleVariant?: Variant;
  titleClasses?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      position: "relative",
      cursor: "pointer",
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    info: {
      marginLeft: theme.spacing(1),
      color: MidLightGrey,
    },
  })
);

export default function Header(props: ExpandableHeaderProps): JSX.Element {
  const { title, tooltipText, titleVariant = "h3", titleClasses } = props;
  const classes = useStyles();
  const TypographyTitle = (
    <Typography component="h2" variant={titleVariant} className={titleClasses}>
      {title}
    </Typography>
  );

  return (
    <header className={classes.container}>
      <span className={classes.title}>
        {TypographyTitle}
        {tooltipText && (
          <AnalyseTooltip title={tooltipText}>
            <InfoIcon className={clsx(classes.info, classes.icon)} />
          </AnalyseTooltip>
        )}
      </span>
    </header>
  );
}
