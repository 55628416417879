export const zIndex: Record<string, number> = {
  datePicker: 1800,
  documentViewerFeedback: 1800,
  filterHeader: 1600,
  filterHeaderWithDocumentViewer: 1300,
  keyboardDatePicker: 9999,
  modalAndMenu: 1700,
  selectMenu: 2000,
  snacker: 1900,
  filterDrawer: 1300,
  reader: 1200,
};
