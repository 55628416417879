import { Add } from "@material-ui/icons";
import React from "react";
import { distinct } from "../../../../../helpers/arrayHelpers";
import { parseWikidata } from "../../../../../helpers/wikidataHelper";
import { useLibraryEntities } from "../../../../../hooks/useCollectionConfigs";
import { useFeedDocument } from "../../../../../hooks/useFeed";
import { MonitoringSentence } from "../../../../../models/monitoring";
import MonitorNewOrganisationDialog from "../../MonitorNewOrganisationDialog";
import ChipOverflow from "./ChipOverflow";
import DotSeparator from "./DotSeparator";
import { useCanUserChangeFeed } from "../../../../../hooks/useFeedUserType";

interface RelatedEntitiesProps {
  documentId: string;
  libraryId: string;
  sentence?: MonitoringSentence;
}

function reduceRelatedEntitiesInSentences(sentences: MonitoringSentence[]): string[] {
  return sentences.reduce<string[]>((acc, curr) => {
    curr.relatedEntities.forEach((entity) => {
      if (entity) {
        acc.push(parseWikidata(entity));
      }
    });
    return acc;
  }, []);
}

function reduceEntityHighlightsInSentences(sentences: MonitoringSentence[]): string[] {
  return sentences.reduce<string[]>((acc, { entityHighlights = [] }) => {
    entityHighlights.forEach((entity) => {
      if (entity) {
        acc.push(parseWikidata(entity));
      }
    });
    return acc;
  }, []);
}

export default function RelatedEntities(props: RelatedEntitiesProps) {
  const { documentId, libraryId, sentence } = props;
  const { data, refetch } = useFeedDocument(libraryId, documentId);
  const [selectedRelatedEntity, setSelectedRelatedEntity] = React.useState<string | null>(null);
  const { data: libraryEntities } = useLibraryEntities(libraryId);
  const canUserChangeFeed = useCanUserChangeFeed(libraryId);
  const entityHighlights = reduceEntityHighlightsInSentences(sentence ? [sentence] : data?.sentences || []);
  const entities = distinct(reduceRelatedEntitiesInSentences(sentence ? [sentence] : data?.sentences || []));

  if (entities.length === 0) {
    return null;
  }

  return (
    <>
      <ChipOverflow
        overflowChipIcon={canUserChangeFeed ? <Add /> : undefined}
        onChipClicked={canUserChangeFeed ? setSelectedRelatedEntity : undefined}
        chips={entities}
        maximumChipsToDisplay={1}
        disabledChips={[...entityHighlights, ...(libraryEntities?.map((e) => e.name) || [])]}
      />
      {selectedRelatedEntity && (
        <div onClick={(e) => e.stopPropagation()}>
          <MonitorNewOrganisationDialog
            defaultFeedId={libraryId}
            entityName={selectedRelatedEntity}
            onConfirm={() => {
              setSelectedRelatedEntity(null);
              refetch();
            }}
            onClose={() => setSelectedRelatedEntity(null)}
          />
        </div>
      )}
      <DotSeparator />
    </>
  );
}
