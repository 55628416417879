import { List } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { Sentence } from "../../../models/sentence";
import SentenceListItem from "./SentenceListItem";

interface SentenceListProps {
  sentences: Sentence[];
}

const useListStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      "& .MuiListItem-root": {
        flexDirection: "column",
        alignItems: "flex-start",
        flex: "1 0 auto",
      },
    },
  })
);

export default function SentenceList(props: SentenceListProps): JSX.Element {
  const { sentences } = props;
  const classes = useListStyles();

  return (
    <List className={classes.list} data-testid="sentences-list">
      {sentences.map((sentence, i) => (
        <SentenceListItem sentence={sentence} key={`${i}-${sentence.documentId}-${sentence.text}`} />
      ))}
    </List>
  );
}
