import { call, CallEffect, put, PutEffect } from "redux-saga/effects";
import { logError } from "../../../helpers/logger";
import { getGeography, getGeographyMetrics } from "../../../http/geo";
import { GeoMetricsResponse, GeoResponse } from "../../../models/geo";
import { DocumentType, SearchRequestV2 } from "../../../models/search";
import { getParsedQueryParams } from "../../reducers/searchRequest/url-functions";
import {
  onGeoError,
  onGeoLoading,
  onGeoMetricsError,
  onGeoMetricsLoading,
  onGeoMetricsSuccess,
  onGeoSuccess,
} from "../../reducers/searchResponse/geoReducer";
import { callIf, shouldIncludeDocumentType } from "../utility";

export function* fetchGeography(
  request: SearchRequestV2
): Generator<void | PutEffect | Generator<CallEffect> | PutEffect, void, GeoResponse> {
  try {
    yield put(onGeoLoading());
    shouldIncludeDocumentType(DocumentType.Academic);
    const { libraries } = getParsedQueryParams();
    // removing types to get specifically each type response and not merged together.
    const emptyResponse: GeoResponse = { mentionedGeographies: [], publishedGeographies: [] };
    const newsGeoResponse: GeoResponse = yield callIf(
      shouldIncludeDocumentType(DocumentType.News),
      getGeography,
      request,
      libraries
    );

    yield put(
      onGeoSuccess({
        news: newsGeoResponse || emptyResponse,
        academic: [],
        jurisdictions: [],
      })
    );
  } catch (err) {
    logError(err);
    yield put(onGeoError());
  }
}

export function* fetchGeographyMetricsGrid(
  request: SearchRequestV2
): Generator<PutEffect | CallEffect<GeoMetricsResponse> | PutEffect, void, GeoMetricsResponse> {
  try {
    yield put(onGeoMetricsLoading());
    const { libraries } = getParsedQueryParams();
    const geoMetrics: GeoMetricsResponse = yield call(getGeographyMetrics, request, libraries);
    yield put(onGeoMetricsSuccess(geoMetrics));
  } catch (err) {
    logError(err);
    yield put(onGeoMetricsError());
  }
}
