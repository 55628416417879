import { createStyles, makeStyles, FormControl, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import { HarvestSourceOption } from "../models";
import { useEffect } from "react";

interface SelectSourceProps {
  disabled: boolean;
  harvestSourceId: string;
  harvestSourceOptions: HarvestSourceOption[];
  setHarvestSourceId: (harvestSourceId: string) => void;
  setShowEntityDropdown: (showEntityDropdown: boolean) => void;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    item: {
      alignItems: "baseline",
      display: "flex",
    },
    select: {
      width: "100%",
    },
    control: {
      marginBottom: spacing(1),
      marginTop: spacing(1),
    },
    label: {
      paddingBottom: spacing(1),
    },
    placeholder: {
      fontStyle: "italic",
    },
  })
);

const SOURCE_PLACEHOLDER = "Select a source…";

export const SelectSource = ({
  disabled,
  harvestSourceId,
  harvestSourceOptions,
  setHarvestSourceId,
  setShowEntityDropdown,
}: SelectSourceProps): JSX.Element => {
  const classes = useStyles();

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    setHarvestSourceId(newValue);
    setShowEntityDropdown(newValue === "pdfUrl");
  };

  useEffect(() => {
    const requiresEntityDropdown = harvestSourceId === "pdfUrl";
    setShowEntityDropdown(requiresEntityDropdown);
  }, [harvestSourceId, setShowEntityDropdown]);

  return (
    <FormControl fullWidth className={classes.control}>
      <Typography variant="h4" className={classes.label}>
        Harvest Source
      </Typography>
      <Select disabled={disabled} className={classes.select} onChange={handleSelectChange} value={harvestSourceId}>
        <MenuItem disabled value="" className={classes.placeholder}>
          {SOURCE_PLACEHOLDER}
        </MenuItem>
        {harvestSourceOptions
          .sort(({ name: a }, { name: b }) => a.localeCompare(b))
          .map(({ id, name }) => (
            <MenuItem key={id} value={id} className={classes.item}>
              {name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
