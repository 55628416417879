import AiButton from "@amplyfi/ui-components/components/Button";
import { useState } from "react";
import { createHarvest, getHarvestUrl, unauthenticatedClient } from "../../../../http/harvest";
import { AccountGroup } from "../../../../models/account";
import { LibraryItem } from "../../../../models/search/documentLibrary";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import SimpleDialog from "../../../Widgets/Dialog/SimpleDialog";
import { SelectLibrary } from "../CreateHarvest/SelectLibrary";
import { SelectOrganisation } from "../CreateHarvest/SelectOrganisation";
import { MAX_HARVEST_DOCUMENTS } from "../helpers/queryValidation";
import { canUserCreateHarvest } from "../permissions";
import SelectFile from "./SelectFile";
import { LibraryEntity } from "../../../../http/harvesterCollections";

export default function LENSUpload(): JSX.Element {
  const [open, setOpen] = useState(false);
  const [selectedLibrary, setSelectedLibrary] = useState<LibraryItem | null>(null);
  const [selectedEntity] = useState<LibraryEntity | null>(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState<AccountGroup | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { user } = useAuth0();

  function onClose() {
    setOpen(false);
  }

  async function onConfirm() {
    const details = await getHarvestUrl();
    if (selectedFile && selectedLibrary && selectedOrganisation) {
      const formData = new FormData();
      Object.keys(details.formData.fields).forEach((key) => {
        formData.append(key, details.formData.fields[key as keyof typeof details.formData.fields]);
      });

      formData.append("file", selectedFile);

      try {
        await unauthenticatedClient.post(details.formData.url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } catch (e) {
        alert("Error uploading JSON. Please try again.");
      } finally {
        await createHarvest(
          selectedOrganisation,
          selectedLibrary,
          selectedEntity,
          details.location,
          "lensS3",
          -1,
          MAX_HARVEST_DOCUMENTS,
          true,
          false
        );
      }
    }
    setOpen(false);
  }

  return (
    <>
      <AiButton
        disabled={!canUserCreateHarvest(user)}
        style={{ minWidth: 200, padding: "8px 24px" }}
        onClick={() => setOpen(true)}
      >
        Upload LENS data
      </AiButton>
      {open && (
        <SimpleDialog
          maxWidth="md"
          title="Upload LENS data"
          content={
            <div style={{ minWidth: 600 }}>
              <SelectOrganisation organisation={selectedOrganisation} setOrganisation={setSelectedOrganisation} />
              <SelectLibrary
                organisationId={selectedOrganisation?.id}
                libraryId={selectedLibrary?.id}
                setLibrary={setSelectedLibrary}
              />
              <SelectFile file={selectedFile} accept=".jsonl,.json" onChange={setSelectedFile} />
            </div>
          }
          onConfirm={selectedLibrary && selectedFile && selectedOrganisation ? onConfirm : undefined}
          onClose={onClose}
        />
      )}
    </>
  );
}
