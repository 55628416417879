import Link from "@material-ui/core/Link";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { link } from "../css/mixins";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      ...link(theme),
    },
  })
);

const RouterLinkWithProxiedQueryParams = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
  return <RouterLink {...props} ref={ref} to={{ pathname: props.to, search: window.location.search }} />;
});

const RouterLinkWithParams = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
  <RouterLink ref={ref} {...props} />
));

type RouterLinkProps = React.ComponentProps<typeof RouterLink> & { to: string };

export function AmplyfiLinkWithQueryParams(props: React.ComponentProps<typeof Link> & RouterLinkProps): JSX.Element {
  const className = props.className;
  const classes = useStyles();

  return (
    <Link {...props} component={RouterLinkWithProxiedQueryParams} className={clsx(classes.link, className)}>
      {props.children}
    </Link>
  );
}

export const AmplyfiLink = React.forwardRef(
  (
    props: React.ComponentPropsWithoutRef<typeof Link> & RouterLinkProps,
    ref: React.Ref<HTMLAnchorElement>
  ): JSX.Element => {
    const className = props.className;
    const classes = useStyles();

    return (
      <Link {...props} component={RouterLinkWithParams} className={className ? className : classes.link} ref={ref}>
        {props.children}
      </Link>
    );
  }
);
