import { updateAmplyfiEntity, ValidationError } from "../../../../http/amplyfiEntities";
import { AmplyfiUnknownEntity } from "../models";

export const isEntityDeleted = ({ deleted }: AmplyfiUnknownEntity): boolean => !!deleted;

export const isEntityVerified = ({ verified }: AmplyfiUnknownEntity): boolean => verified !== false;

export const isEntityUsed = ({ usedInLibraries = [] }: AmplyfiUnknownEntity): boolean => usedInLibraries.length > 0;

const removeValue = (dupe: string, values: string[] = []): string[] =>
  values.filter((item) => item.trim().toLowerCase() !== dupe.trim().toLowerCase());

export const saveEntity = async <T extends AmplyfiUnknownEntity>(entity: T): Promise<ValidationError[]> => {
  const { errors } = await updateAmplyfiEntity<T>({ ...entity, aliases: removeValue(entity.name, entity.aliases) });
  return errors;
};
