import { CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import BusinessIcon from "@material-ui/icons/Business";
import LocationIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import { LightGrey, PickledBluewood, White } from "@amplyfi/ui-components/theme/colors";
import { EntityType } from "../../models/entity";
import clsx from "clsx";
import { getEntityLogo } from "../../helpers/imageHelpers";

interface EntityProfileLogoProps {
  id: string;
  entityType: EntityType;
  isPatentAcademic?: boolean;
  className?: string;
  useOuterContainer?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: EntityProfileLogoProps) => ({
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      border: `1px solid ${LightGrey}`,
      overflow: "hidden",
      background: White,
    }),
    containerSize: {
      height: 70,
      width: 70,
    },
    imageContainer: {
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      width: "100%",
      height: "100%",
    },
    icon: {
      color: PickledBluewood[300],
    },
    spinner: {
      position: "absolute",
    },
  })
);

const EntityProfileLogo = (props: EntityProfileLogoProps): JSX.Element => {
  const { id, entityType, className, useOuterContainer = true } = props;
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);
  const classes = useStyles(props);
  const isCompany = entityType === EntityType.Organisation;
  const getPlaceholder = (entityType: string) => {
    if (entityType === EntityType.Organisation) {
      return <BusinessIcon className={classes.icon} />;
    } else if (entityType === EntityType.Country) {
      return <LocationIcon className={classes.icon} />;
    } else if (entityType === EntityType.Person) {
      return <PersonIcon className={classes.icon} />;
    } else {
      return <BusinessIcon className={classes.icon} />;
    }
  };
  const placeholderLogo = imageError || !id ? getPlaceholder(entityType) : null;

  return (
    <div
      className={clsx(classes.containerSize, useOuterContainer && classes.container, className && className)}
      style={{ alignItems: entityType === "Person" && imageLoaded ? "flex-start" : "center" }}
    >
      <div
        className={clsx(classes.imageContainer)}
        style={{
          backgroundSize: isCompany ? "contain" : "cover",
        }}
      >
        {!imageLoaded && !imageError && id ? (
          <CircularProgress size={20} className={classes.spinner} />
        ) : (
          placeholderLogo
        )}

        {!imageError && id && (
          <img
            className={clsx(classes.image, "carousel-cell-image")}
            src={getEntityLogo(id)}
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageError(true)}
            alt="logo"
            style={{ objectFit: isCompany ? "contain" : "cover" }}
          />
        )}
      </div>
    </div>
  );
};

export default EntityProfileLogo;
