import { UsersResponse, getUsers } from "../http/user-management";
import { useQuery } from "./useQuery";
import { useAuth0 } from "../components/Auth0/AuthWrapper";
import { getOrganisation } from "../helpers/user";
import { useQueryClient } from "react-query";

export default function useUsers(query?: string) {
  const { user } = useAuth0();
  const organisation = getOrganisation(user);
  const orgId = organisation?.id as string;
  const queryClient = useQueryClient();

  return useQuery(
    ["users-account", orgId, query],
    async () => {
      if (query !== undefined && query.length === 0) {
        return { users: [], count: 0 };
      }
      const size = 25;
      let page = 1;
      const response: UsersResponse = await getUsers(orgId, page, query, size);
      while (page < response.total_pages) {
        page += 1;
        const { users } = await getUsers(orgId, page, query, size);
        response.users = [...response.users, ...users];
      }
      response.users.forEach((user) => {
        queryClient.setQueryData(["user-account", user.userId], () => user);
      });
      return response;
    },
    {
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60 * 5,
      refetchInterval: false,
    }
  );
}
