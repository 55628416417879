import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Fade,
  Theme,
  Typography,
  lighten,
  makeStyles,
} from "@material-ui/core";
import {
  Block as BlockIcon,
  Category as ContentTypeIcon,
  Delete as DeleteIcon,
  EmojiObjectsOutlined,
  Favorite,
  FavoriteBorder,
  FileCopyOutlined,
  Launch as LaunchIcon,
  LiveHelpOutlined,
  MailOutline,
  DataUsage as SectorIcon,
  LocalOfferOutlined as TagIcon,
  Chat as TopicIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import { capitalize } from "lodash";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AmplyfiInsightLogo from "../../../../../assets/AI-logo-dark.png";
import { ReactComponent as SignalIcon } from "../../../../../assets/signal.svg";
import { analyticsEvent } from "../../../../../helpers/analytics";
import { EventAction } from "../../../../../helpers/analytics.types";
import { distinct } from "../../../../../helpers/arrayHelpers";
import { dateFormat } from "../../../../../helpers/dateHelpers";
import { getAmplyfiEntityLogo, getDocumentLogo, getWebsiteLogo } from "../../../../../helpers/imageHelpers";
import { pluraliseSignals, toTitleCase } from "../../../../../helpers/textHelper";
import { useAlertsFeedbackState, useInsightsFeedbackState } from "../../../../../hooks/mutations/feed/useFeedbackState";
import { Insight, InsightDocument } from "../../../../../hooks/queries/insights/useInsights";
import useAIHistory from "../../../../../hooks/useAIHistory";
import { useLibraryEntities } from "../../../../../hooks/useCollectionConfigs";
import { useDocumentLibraryFromList } from "../../../../../hooks/useDocumentLibraries";
import { UserResponseFeedbackState } from "../../../../../http/feed";
import { LibraryFilter } from "../../../../../http/libraryFilters";
import {
  AlertType,
  MatchedAlert,
  MonitoringDocument,
  MonitoringFeedbackState,
  MonitoringSentence,
} from "../../../../../models/monitoring";
import { useFeedViewStore } from "../../../../../store/hooks/useFeedViewStore";
import useQuestionAnswerStore from "../../../../../store/hooks/useQuestionAnswerStore";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import useSalesPitchEmailStore from "../../../../../store/hooks/useSalesPitchEmailStore";
import useSearchStore from "../../../../../store/hooks/useSearchStore";
import { setSnack } from "../../../../../store/reducers/ui/snackReducer";
import ItemHeaderActions from "../../ItemHeaderActions";
import { BlockSectors } from "../../components/BlockSectors";
import { BlockSource } from "../../components/BlockSource";
import { BlockTopics } from "../../components/BlockTopics";
import { BlockTypeTags } from "../../components/BlockTypeTags";
import AiImage from "../../components/Image";
import { TextRenderer } from "../../components/TextRenderer";
import { UserTags } from "../../components/UserTags";
import { FeedViewType, PageType, insightsPage } from "../../components/filterMenu/feedFilters";
import ChipOverflow from "../Components/ChipOverflow";
import RelatedEntities from "../Components/RelatedEntities";
import { getInsightType } from "./utils";

const useStyles = makeStyles<Theme, { compact: boolean }>(({ spacing }) => ({
  actionCard: {
    marginBottom: spacing(2),
  },
  actionCardIsClickable: {
    cursor: "pointer",
  },
  selectedCard: {
    borderLeft: "4px solid #004FD0",
  },
  cardHeader: {
    display: "block",
    paddingBottom: 0,
  },
  cardContent: {},
  itemImage: {
    height: 100,
    width: 100,
    objectFit: "cover",
    borderRadius: 4,
    marginLeft: "auto",
    marginRight: spacing(1),
    marginTop: spacing(2),
  },
  favouritedAlert: {
    color: "red",
    "button:hover:enabled > &": {
      color: lighten("#FF0000", 0.5),
    },
  },
  insightEntityImage: {
    maxWidth: 32,
    maxHeight: 32,
  },
  logoImage: {
    minWidth: 32,
    maxWidth: 32,
  },
  cardChip: {
    background: "#F3F3F3",
    color: "#000",
    paddingLeft: 4,
    paddingRight: 4,
    marginLeft: "auto",
  },
}));

function getEventAction(response: MonitoringFeedbackState): string {
  switch (response) {
    case "Discarded":
      return "Discarded";
    case "Favourites":
      return "Favourited";
    case "Inbox":
    default:
      return `returned to ${insightsPage.label}`;
  }
}

interface FeedCardProps {
  compact?: boolean;
  isWithinReader?: boolean;
  showReasoning?: boolean;
  feedDocument: InsightDocument | MonitoringDocument;
  view: PageType;
  type: FeedViewType;
}

export function InsightCardHeader({ compact = false, feedDocument, isWithinReader, view, type }: FeedCardProps) {
  const dispatch = useDispatch();
  const { pushDocument } = useAIHistory();
  const { search } = useSearchStore((state) => ({ search: state.search }));
  const { setLibraryId } = useFeedViewStore();
  const { setDocumentIds } = useQuestionAnswerStore.getState();
  const { setSelectedTab } = useReaderStore();
  const classes = useStyles({ compact });
  const library = useDocumentLibraryFromList(feedDocument.libraryId);
  const { mutate: insightFeedbackState } = useInsightsFeedbackState(feedDocument.libraryId);
  const { mutate: alertFeedbackState } = useAlertsFeedbackState(feedDocument.libraryId);
  const [showBlockSourceDialog, setShowBlockSourceDialog] = useState(false);
  const [showBlockSectorsDialog, setShowBlockSectorsDialog] = useState(false);
  const [showBlockTopicsDialog, setShowBlockTopicsDialog] = useState(false);
  const [showBlockTypeTagDialog, setShowBlockTypeTagDialog] = useState(false);
  const [showUserTagDialog, setShowUserTagDialog] = useState(false);
  const history = useAIHistory();

  const amplyfiEntityId =
    type === FeedViewType.Insight
      ? (feedDocument as InsightDocument).amplyfiEntityId
      : (feedDocument as MonitoringDocument).amplyfiEntityIds[0];

  async function handleUserResponseSelect(resp: MonitoringFeedbackState) {
    const func = type === FeedViewType.Insight ? insightFeedbackState : alertFeedbackState;
    await func({
      amplyfiEntityId,
      currentFilter: view,
      documentId: feedDocument.documentId,
      feedContent: null,
      response: resp.toLowerCase() as UserResponseFeedbackState,
    });

    if (resp === "Discarded") {
      pushDocument(null);
    }

    analyticsEvent("Feed", capitalize(type) as EventAction, [feedDocument.documentId, getEventAction(resp)]);
    dispatch(setSnack({ title: `${capitalize(type)} ${getEventAction(resp).toLowerCase()}` }));
  }

  const insight: MonitoringDocument | Insight =
    type === FeedViewType.Insight
      ? (feedDocument as InsightDocument).insights[0]
      : (feedDocument as MonitoringDocument);
  const documentTopics: LibraryFilter[] =
    type === FeedViewType.Signal ? (insight as MonitoringDocument).documentTopics || [] : [];
  const documentSectors: LibraryFilter[] =
    type === FeedViewType.Signal ? (insight as MonitoringDocument).documentSectors || [] : [];
  const documentTypeTags: LibraryFilter[] =
    type === FeedViewType.Signal ? (insight as MonitoringDocument).documentTypeTags || [] : [];

  return (
    <>
      <CardHeader
        className={classes.cardHeader}
        disableTypography
        title={
          <ItemHeaderActions
            actions={[
              {
                dataTestId: "insight-date",
                title: dateFormat(feedDocument.date as string, { outputFormat: "DD MMM YYYY" }),
                separator: true,
              },
              {
                dataTestId: "insight-feed",
                title: library?.name || "",
                align: "left",
              },
              {
                dataTestId: "insight-email",
                title: "Generate Email",
                icon: <MailOutline color="primary" />,
                visible: type === FeedViewType.Insight,
                onClick: () => {
                  useSalesPitchEmailStore.getState().setInsightDocument(feedDocument as InsightDocument);
                },
              },
              {
                dataTestId: "investigate-insight",
                title: "Investigate",
                icon: <LiveHelpOutlined color="primary" />,
                onClick: () => {
                  history.pushDocument(feedDocument.documentId);
                  setLibraryId(library?.id || "");
                  if (type === FeedViewType.Insight) {
                    setDocumentIds((insight as Insight).sources.map((s) => s.documentId));
                  } else {
                    setDocumentIds([(insight as MonitoringDocument).documentId]);
                  }
                  setSelectedTab({ id: "investigation" });
                },
              },
              {
                dataTestId: "copy-insight",
                title: "Copy",
                icon: <FileCopyOutlined color="primary" />,
                visible: type === FeedViewType.Insight,
                onClick: () => {
                  if (type === FeedViewType.Insight) {
                    const ins = insight as Insight;
                    const text = [
                      ins.text,
                      ...(ins.reasoning ? [ins.reasoning] : []),
                      `Sources: \n${ins.sources.map((s) => s.url).join("\n")}`,
                    ].join("\n\n");
                    navigator.clipboard.writeText(text);
                  }
                },
              },
              {
                dataTestId: "favourite",
                icon:
                  feedDocument.feedbackState === "Favourites" ? (
                    <Favorite
                      fontSize="small"
                      className={clsx(feedDocument.feedbackState === "Favourites" && classes.favouritedAlert)}
                    />
                  ) : (
                    <FavoriteBorder htmlColor="#000" fontSize="small" />
                  ),
                onClick: () =>
                  handleUserResponseSelect(feedDocument.feedbackState === "Favourites" ? "Inbox" : "Favourites"),
                title: feedDocument.feedbackState === "Favourites" ? "Unfavourite" : "Favourite",
              },
            ]}
            libraryId={feedDocument.libraryId}
            menuActions={[
              {
                dataTestId: "open-source",
                divider: true,
                icon: <LaunchIcon color="action" fontSize="inherit" />,
                onClick: () => window.open((insight as MonitoringDocument).url, "_blank"),
                visible: type === FeedViewType.Signal,
                title: "Open in a new tab",
              },
              {
                icon: <TagIcon fontSize="inherit" />,
                dataTestId: "tag-card",
                onClick: () => setShowUserTagDialog(true),
                title: `Tag this ${type}`,
              },
              {
                dataTestId: "block-source",
                icon: <BlockIcon fontSize="inherit" />,
                onClick: () => setShowBlockSourceDialog(true),
                visible: type === FeedViewType.Signal,
                isEditorAction: true,
                title: (
                  <>
                    Don’t show content from <strong>{(insight as MonitoringDocument).source}</strong>
                  </>
                ),
              },
              ...(!!documentSectors?.length
                ? [
                  {
                    dataTestId: "block-sectors",
                    description: `${documentSectors
                      .map(({ name }) => name)
                      .slice(0, 3)
                      .join(", ")}${documentSectors.length > 3 ? "…" : ""}`,
                    icon: <SectorIcon fontSize="inherit" />,
                    onClick: () => setShowBlockSectorsDialog(true),
                    title: "Not interested in a sector",
                    isEditorAction: true,
                  },
                ]
                : []),
              ...(!!documentTopics?.length
                ? [
                  {
                    dataTestId: "block-topics",
                    description: `${documentTopics
                      .map(({ name }) => name)
                      .slice(0, 3)
                      .join(", ")}${documentTopics.length > 3 ? "…" : ""}`,
                    icon: <TopicIcon fontSize="inherit" />,
                    onClick: () => setShowBlockTopicsDialog(true),
                    title: "Not interested in a topic",
                    isEditorAction: true,
                  },
                ]
                : []),
              ...(!!documentTypeTags?.length
                ? [
                  {
                    dataTestId: "block-content-type",
                    description: `${documentTypeTags
                      .map(({ name }) => name)
                      .slice(0, 3)
                      .join(", ")}${documentTypeTags.length > 3 ? "…" : ""}`,
                    icon: <ContentTypeIcon fontSize="inherit" />,
                    onClick: () => setShowBlockTypeTagDialog(true),
                    title: "Not interested in a content type",
                    isEditorAction: true,
                  },
                ]
                : []),
              {
                dataTestId: "discard",
                icon: <DeleteIcon color="action" fontSize="inherit" />,
                onClick: () => handleUserResponseSelect("Discarded"),
                title: "Discard",
              },
            ]}
          />
        }
        action={
          <Box display="flex">
            <Box marginRight={2}>
              <TextRenderer
                style={{ marginTop: 16, cursor: isWithinReader ? undefined : "pointer" }}
                variant="h3"
                match={search}
              >
                {(insight as Insight).text || feedDocument.title}
              </TextRenderer>
              {type === FeedViewType.Signal && !isWithinReader && (
                <Box marginTop={2} borderLeft="2px solid #B9B9B9" paddingRight={2}>
                  <TextRenderer
                    style={{ marginLeft: 16, fontWeight: 400, fontSize: 15 }}
                    variant="body2"
                    match={search}
                  >
                    {(feedDocument as MonitoringDocument).sentences.filter((s) =>
                      search ? s.text.toLowerCase().includes(search.toLowerCase()) : true
                    )[0]?.text || (feedDocument as MonitoringDocument).sentences[0].text}
                  </TextRenderer>
                </Box>
              )}
            </Box>
            {type === FeedViewType.Signal && (
              <AiImage
                ErrorImage={<></>}
                className={classes.itemImage}
                src={getDocumentLogo(feedDocument.documentId, true)}
                alt={feedDocument.title}
              />
            )}
          </Box>
        }
      />
      {showBlockSourceDialog && (
        <BlockSource
          document={feedDocument as MonitoringDocument}
          libraryId={feedDocument.libraryId}
          onClose={(e, changed) => {
            e.stopPropagation();
            setShowBlockSourceDialog(false);
            if (changed) {
              handleUserResponseSelect("Discarded");
            }
          }}
        />
      )}
      {showBlockSectorsDialog && (
        <BlockSectors
          document={feedDocument as MonitoringDocument}
          onClose={(e, changed) => {
            setShowBlockSectorsDialog(false);
            if (changed) {
              handleUserResponseSelect("Discarded");
            }
          }}
        />
      )}
      {showBlockTopicsDialog && (
        <BlockTopics
          document={feedDocument as MonitoringDocument}
          libraryId={feedDocument.libraryId}
          onClose={(e, changed) => {
            e.stopPropagation();
            setShowBlockTopicsDialog(false);
            if (changed) {
              handleUserResponseSelect("Discarded");
            }
          }}
        />
      )}
      {showBlockTypeTagDialog && (
        <BlockTypeTags
          document={feedDocument as MonitoringDocument}
          libraryId={feedDocument.libraryId}
          onClose={(e, changed) => {
            e.stopPropagation();
            setShowBlockTypeTagDialog(false);
            if (changed) {
              handleUserResponseSelect("Discarded");
            }
          }}
        />
      )}
      {showUserTagDialog && (
        <UserTags
          document={feedDocument}
          onClose={(e) => {
            e.stopPropagation();
            setShowUserTagDialog(false);
          }}
        />
      )}
    </>
  );
}

function allAlertTypesFromSentences(sentences: MonitoringSentence[]): (AlertType | string)[] {
  const alertTypes = distinct(sentences.map(({ alertTypes }) => alertTypes).flat());
  const matchedAlerts = sentences
    .map(({ matchedAlerts = [] }) => matchedAlerts)
    .flat()
    .filter(({ topic }) => !!topic) as (MatchedAlert & { topic: string })[];

  const matchedAlertTypes = alertTypes
    .map((alertType) => {
      const matchingAlerts = matchedAlerts.filter(({ alertTypeName }) => alertTypeName === alertType);
      return matchingAlerts.length
        ? matchingAlerts.map(({ topic }) => `${alertType} | ${toTitleCase(topic)}`)
        : [alertType];
    })
    .flat();

  return matchedAlertTypes;
}

export default function FeedCard({
  compact = false,
  showReasoning = true,
  feedDocument,
  isWithinReader = false,
  view,
  type,
}: FeedCardProps) {
  const classes = useStyles({ compact });
  const insight = type === FeedViewType.Insight ? (feedDocument as InsightDocument).insights[0] : feedDocument;
  const { data: feedEntities = [] } = useLibraryEntities(feedDocument.libraryId || "");
  const amplyfiEntityId =
    type === FeedViewType.Insight
      ? (feedDocument as InsightDocument).amplyfiEntityId
      : (feedDocument as MonitoringDocument).amplyfiEntityIds[0];

  const { selectedDocumentId, isCardSelected, setIsCardSelected } = useReaderStore();
  const ref = useRef(null);
  const history = useAIHistory();
  const { search } = useSearchStore();

  return (
    <Fade in timeout={300}>
      <Card
        onClick={
          isWithinReader
            ? undefined
            : () => {
              history.pushDocument(feedDocument.documentId);
              setIsCardSelected(true);
            }
        }
        ref={ref}
        elevation={compact || isWithinReader ? 0 : undefined}
        className={clsx(
          classes.actionCard,
          !isWithinReader && classes.actionCardIsClickable,
          isCardSelected && selectedDocumentId === feedDocument.documentId && showReasoning && classes.selectedCard
        )}
      >
        {!compact && feedDocument && (
          <InsightCardHeader type={type} view={view} feedDocument={feedDocument} isWithinReader={isWithinReader} />
        )}
        <CardContent className={classes.cardContent}>
          {type === FeedViewType.Insight && showReasoning && (insight as Insight).reasoning && (
            <Box padding={2} borderRadius={4} bgcolor="#F7FAFC" display="flex">
              <EmojiObjectsOutlined style={{ fontSize: 32 }} htmlColor="#004FD0" />
              <TextRenderer match={search} style={{ marginLeft: 8, color: "#004FD0" }} variant="body2">
                {(insight as Insight).reasoning as string}
              </TextRenderer>
            </Box>
          )}
          <Box display="flex" alignItems="center" marginTop={2}>
            {type === "insight" && (
              <>
                <Chip
                  style={{ background: "#F3F3F3", color: "#000", paddingLeft: 4, paddingRight: 4 }}
                  icon={getInsightType((insight as Insight).type).icon}
                  label={getInsightType((insight as Insight).type).title}
                />

                <Chip
                  onClick={() => history.pushDocument(feedDocument.documentId)}
                  className={classes.cardChip}
                  icon={<SignalIcon fill="#616161" />}
                  label={`${pluraliseSignals((insight as Insight).sources.length)}`}
                />
              </>
            )}
            {type === FeedViewType.Signal && (
              <>
                <RelatedEntities documentId={feedDocument.documentId} libraryId={feedDocument.libraryId} />
                <ChipOverflow
                  chips={allAlertTypesFromSentences((feedDocument as MonitoringDocument).sentences)}
                  maximumChipsToDisplay={1}
                />
              </>
            )}
          </Box>
          <Box marginTop={2}>
            <Box display="flex" alignItems="center">
              <AiImage
                displayErrorAlt
                className={classes.insightEntityImage}
                src={getAmplyfiEntityLogo(amplyfiEntityId)}
                alt={feedEntities.find((e) => e.amplyfiEntityId === amplyfiEntityId)?.name || ""}
              />
              <Typography style={{ marginLeft: 8 }} variant="h4">
                {feedEntities.find((e) => e.amplyfiEntityId === amplyfiEntityId)?.name || ""}
              </Typography>
              <Box
                style={type === FeedViewType.Signal && !!feedDocument.url ? { cursor: "pointer" } : undefined}
                flex="0 0 auto"
                marginLeft="auto"
                display="inline-flex"
                alignItems="center"
                onClick={
                  type === FeedViewType.Signal && !!feedDocument.url
                    ? (e) => {
                      e.stopPropagation();
                      window.open(feedDocument.url, "_blank");
                    }
                    : undefined
                }
              >
                <Typography
                  style={{
                    marginLeft: 8,
                    marginRight: 16,
                    maxWidth: 250,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "pre",
                  }}
                  variant="h4"
                >
                  {feedDocument.source}
                </Typography>

                <AiImage
                  displayErrorAlt={view.type !== FeedViewType.Insight}
                  className={clsx(classes.insightEntityImage, classes.logoImage)}
                  src={feedDocument.url ? getWebsiteLogo(new URL(feedDocument.url).host) : undefined}
                  alt={feedDocument.source}
                  ErrorImage={view.type === FeedViewType.Insight ? AmplyfiInsightLogo : undefined}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Fade>
  );
}
