import AnalysisComponent from "@amplyfi/ui-components/components/AnalysisComponent";
import ExportModalContent from "@amplyfi/ui-components/components/ExportContent";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, SortingRule, useBlockLayout, usePagination, useSortBy, useTable } from "react-table";
import { cardDetails } from "../../helpers/cardDetailsHelper";
import { convertSearchStateToSearchRequestV3 } from "../../helpers/search";
import { getHeaders } from "../../helpers/tableHelper";
import useAllElements from "../../hooks/useAllElements";
import { useAnalysisComponentState } from "../../hooks/useAnalysisComponentState";
import { useTimelineFilterLabel } from "../../hooks/useTimelineFilterLabel";
import { exportAllElementsData } from "../../http/element";
import { ColumnItem, ElementData, ElementType } from "../../models/element";
import { ParsedQueryParams } from "../../models/queryParams";
import { SortOrder } from "../../models/search";
import { useAnalyseSelector } from "../../store/reducers";
import { setSnack } from "../../store/reducers/ui/snackReducer";
import FeedbackModal from "../Widgets/Feedback/FeedbackModal";
import ColumnEditor from "../Widgets/Table/ColumnEditor";
import Table, { DEFAULT_PAGE_SIZE, ExtendedTableInstance } from "../Widgets/Table/Table";
import TimelineDialog from "../Widgets/TimelineDialog";

const allEntityTypes: { name: string; value: ElementType }[] = [
  {
    name: "Organisations",
    value: "organisation",
  },
  {
    name: "People",
    value: "person",
  },
  {
    name: "Locations",
    value: "country",
  },
  {
    name: "Keyphrases",
    value: "keyphrase",
  },
];

function getEntityTypes(selectedEntityTypes: ElementType): ElementType[] {
  return selectedEntityTypes === "country" ? [selectedEntityTypes, "settlement"] : [selectedEntityTypes];
}

export default function ComparisonCard() {
  const url: ParsedQueryParams = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const [sort, setSortBy] = useState<SortingRule<ElementData>>({ id: "documentCount", desc: true });
  const [page, setPage] = useState(1);
  const [searchTerm] = useState("");
  const [selectedColumnIds, setSelectedColumnIds] = useState<string[]>([]);
  const [selectedEntityTypes, setSelectedEntityTypes] = useState<ElementType>("organisation");
  const [pinnedEntities, setPinnedEntities] = useState<Row<ElementData>[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isFullscreen, setFullscreen] = useState(false);
  const dispatch = useDispatch();

  const { data: elementHeaders } = useAllElements({ id: "documentCount", desc: true }, 1, "", [], ["organisation"]);
  const {
    data: elementsResponse,
    isLoading,
    isError,
  } = useAllElements(sort, page, searchTerm, selectedColumnIds, getEntityTypes(selectedEntityTypes));

  const timelineFilterLabel = useTimelineFilterLabel();
  const analysisState = useAnalysisComponentState(
    isError,
    isLoading,
    elementsResponse?.elements.length === 0,
    setShowModal
  );

  const data: ElementData[] = useMemo(() => (elementsResponse ? elementsResponse.elements : []), [elementsResponse]);

  const columns: ColumnItem[] = useMemo(() => {
    return getHeaders(elementHeaders?.elements || []);
  }, [elementHeaders]);

  const tableProps = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: elementsResponse?.totalPageCount || -1,
      disableSortRemove: true,
      autoResetHiddenColumns: false,
      initialState: { sortBy: [sort], pageSize: DEFAULT_PAGE_SIZE },
      getRowId: (row) => row.id,
    },
    useBlockLayout,
    useSortBy,
    usePagination
  ) as ExtendedTableInstance<ElementData>;

  const {
    state: { sortBy, pageIndex },
    allColumns,
    visibleColumns,
    setHiddenColumns,
  } = tableProps;

  useEffect(() => {
    setSortBy(sortBy[0]);
  }, [sortBy]);

  useEffect(() => {
    setPage(pageIndex + 1);
  }, [pageIndex]);

  useEffect(() => {
    setSelectedColumnIds(visibleColumns.map((column) => column.id));
  }, [visibleColumns]);

  async function exportCsv(fileName: string) {
    const request = convertSearchStateToSearchRequestV3(url);
    request.systemFilters = {
      ...request.systemFilters,
      exportFileName: `${fileName}.csv`,
      sortOrder: sort.desc ? SortOrder.Desc : SortOrder.Asc,
      sortProperty: sort.id,
      page,
      searchTerm: searchTerm.length > 0 ? searchTerm : undefined,
      properties: selectedColumnIds.length === 0 ? undefined : selectedColumnIds.filter((a) => a !== "name"),
      size: elementsResponse?.totalElementCount || 100,
      elementTypes: getEntityTypes(selectedEntityTypes),
    };
    try {
      dispatch(
        setSnack({
          body: "We're generating your export. This could take a while.",
          title: "Generating Report",
        })
      );
      await exportAllElementsData(request, url.libraries);
    } catch (e) {
      setTimeout(() => {
        dispatch(
          setSnack({
            body: "There was a problem generating the report. Please try again, or contact support@amplyfi.com.",
            title: "Error",
            autoHideDuration: 5000,
          })
        );
      }, 2000);
    }
  }

  return (
    <>
      <AnalysisComponent
        analysisState={analysisState.state}
        vizualisationContent={undefined}
        onPrimaryClick={analysisState.primaryAction}
        onSecondaryClick={analysisState.secondaryAction}
        infoTooltipContent={cardDetails.comparison.readMore}
        onFullScreenChange={(_isFullscreen) => setFullscreen(_isFullscreen)}
        exportContent={
          <ExportModalContent
            data-testid={testId(`${cardDetails.comparison.title}s-export`)}
            componentName={cardDetails.comparison.title}
            handleClose={() => void 0}
            handleExport={({ fileName }: { fileName: string }) => {
              exportCsv(fileName);
            }}
          />
        }
        onFeedbackClick={() => {
          setShowModal(true);
        }}
        modifyDataContent={
          <ColumnEditor
            allColumns={allColumns.map((column) => ({ Header: column.Header as string, accessor: column.id }))}
            selectedColumns={visibleColumns.map((column) => ({
              Header: column.Header as string,
              accessor: column.id,
            }))}
            onClose={() => void 0}
            onColumnsSelected={(hiddenColumnIds: string[]) => setHiddenColumns(hiddenColumnIds)}
          />
        }
        title="Comparison"
        timeContent={<TimelineDialog includeUndatedDocuments={url.includeUndatedDocuments} />}
        timelineFilterLabel={timelineFilterLabel}
      >
        <>
          <RadioGroup
            style={{ display: "flex", flexDirection: "row" }}
            onChange={(e) => setSelectedEntityTypes(e.target.value as ElementType)}
            defaultValue={selectedEntityTypes}
          >
            {allEntityTypes.map((e) => (
              <FormControlLabel
                checked={selectedEntityTypes === e.value}
                control={<Radio />}
                value={e.value}
                label={e.name}
              />
            ))}
          </RadioGroup>
          <Table
            page={page}
            pageCount={elementsResponse?.totalPageCount || 0}
            tableProps={tableProps}
            isFullscreen={isFullscreen}
            pinnedEntities={pinnedEntities}
            setPinnedEntities={setPinnedEntities}
          />
        </>
      </AnalysisComponent>
      <FeedbackModal
        open={showModal}
        onClose={(_e, _reason) => setShowModal(false)}
        showCloseIcon={false}
        componentName={cardDetails.comparison.title}
      />
    </>
  );
}
