import { call, CallEffect, put, PutEffect, select, SelectEffect } from "redux-saga/effects";
import { logError } from "../../../helpers/logger";
import { searchSectorSentences } from "../../../http/sectors";
import { Entity } from "../../../models/entity";
import { SearchRequestV2 } from "../../../models/search";
import { SentenceResponse } from "../../../models/sentence";
import { AppState } from "../../reducers";
import { getParsedQueryParams } from "../../reducers/searchRequest/url-functions";
import {
  onSectorSentencesError,
  onSectorSentencesLoading,
  onSectorSentencesSuccess,
} from "../../reducers/searchResponse/sectorsReducer";
import { toTitleCase } from "../../../helpers/textHelper";

export function* fetchSectorSentences(
  searchRequest: SearchRequestV2
): Generator<
  SelectEffect | PutEffect | CallEffect<SentenceResponse> | PutEffect,
  void,
  Entity[] & SentenceResponse & string[]
> {
  try {
    const sectorEntityFilters: Entity[] = yield select((state: AppState) => state.searchResponse.sectors.filters);
    const sectorSentences: string[] = yield select(
      (state: AppState) => state.searchResponse.sectors.selectedSectorFilters
    );
    const request = {
      ...searchRequest,
      systemFilters: {
        ...searchRequest.systemFilters,
        mentionsKeywordFilters: sectorEntityFilters,
        mentionsCount: 100,
        propertyMentionsFilters: sectorSentences.map(toTitleCase),
      },
    };
    yield put(onSectorSentencesLoading());
    const { libraries } = getParsedQueryParams();
    const sentences: SentenceResponse = yield call(searchSectorSentences, request, libraries);
    yield put(onSectorSentencesSuccess(sentences));
  } catch (err) {
    logError(err);
    yield put(onSectorSentencesError());
  }
}
