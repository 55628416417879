import axios from "axios";

const apiUrl = `${process.env.REACT_APP_ANALYSE_API}/v5`;
const apiUrlv2 = `${process.env.REACT_APP_ANALYSE_API}/v2`;

export interface LibraryFilter {
  id: string;
  name: string;
}

export type LibraryFilterAutocompleteResponse = LibraryFilter[];

export async function searchLibrarySectorFilters(search: string): Promise<LibraryFilterAutocompleteResponse> {
  const { data } = await axios.get<LibraryFilterAutocompleteResponse>(
    `${apiUrl}/sectors-autocomplete/${encodeURIComponent(search)}`
  );
  return data;
}

export async function searchLibraryLocationFilters(search: string): Promise<LibraryFilterAutocompleteResponse> {
  const { data } = await axios.get<LibraryFilterAutocompleteResponse>(
    `${apiUrl}/location-autocomplete/${encodeURIComponent(search)}`
  );
  return data;
}

export async function searchPublishLocationsFilters(search: string): Promise<LibraryFilterAutocompleteResponse> {
  const { data } = await axios.get<LibraryFilterAutocompleteResponse>(
    `${apiUrlv2}/autocomplete/publishLocations/${encodeURIComponent(search)}`
  );
  return data;
}
