import axios, { AxiosInstance } from "axios";

const foragerUrl = process.env.REACT_APP_FORAGER_URL;

export enum ConnectorAuthentication {
  None = "None",
  OAuth = "OAuth",
  AuthToken = "AuthToken",
  SharedOAuth = "SharedOAuth",
}

export enum ConnectorVisibility {
  All,
  User = "User",
  Organisation = "Organisation",
}

export enum ConnectorSortBy {
  Date = "date",
  Relevancy = "relevance",
}

export enum ConnectorSortOrder {
  Descending = "descending",
  Ascending = "ascending",
}
export const foragerClient: AxiosInstance = axios.create({
  baseURL: foragerUrl,
});

export interface ConnectorGroup {
  name: string;
  groupId: string;
  dateAdded: number;
  lastModified: number;
  scope: ConnectorVisibility;
  connectors: Connector[];
}

export interface Connector extends Prototype {
  connectorId: string;
  canBeHarvested: boolean;
  scope: ConnectorVisibility | string;
  connectorGroupId: string | null;
  linkId: string;
  languageCode: string;
  oAuthConfiguration?: {
    clientId: string;
    clientSecret: string;
  } | null;
}

export interface ConnectorAttribute extends PrototypeConnectorSetting {
  value?: string;
}

export interface Prototype {
  attributes: PrototypeConnectorSetting[];
  connectorSettingConfigurations?: PrototypeConnectorSetting[];
  description: string;
  name: string;
  logoDomain: string;
  prototypeId: string;
  authenticationType: ConnectorAuthentication | string;
  support?: string;
}

interface PrototypeOption {
  name: string;
  value: string;
}

export interface PrototypeConnectorSetting {
  name: string;
  label: string;
  key?: string;
  isSuperuserOnly?: boolean;
  validationRegex: string | null;
  value?: string;
  isSecureValue?: boolean;
  options: PrototypeOption[];
  type: string;
}

export enum ConnectorStatus {
  Idle = "idle",
  NoAuth = "no-auth",
  InProgress = "in-progress",
  Completed = "completed",
  Error = "errored",
}

export interface SearchResult {
  connectorBreakdown: { [key: string]: number };
  connectorCompletions: { [key: string]: boolean };
  connectorStatuses: { [key: string]: ConnectorStatus };
  itemsProcessed: number;
  results: SearchItem[];
  searchId: string;
  timestamp: number;
  totalResults: number;
  pageNumber: number;
}

interface SearchItem {
  author: string;
  connectorId: string;
  connectorName: string;
  date: string;
  isPeerReviewed: boolean;
  prototypeId: string;
  retrievedDate: string;
  summary: string;
  timestamp: number;
  title: string;
  url: string;
  displayUrl: string;
}

export const customLogoIds = ["site-search"];

export async function getPrototypes(): Promise<Prototype[]> {
  const { data } = await foragerClient.get<Prototype[]>(`${foragerUrl}/v1/prototypes`);
  return data;
}

export async function getPrototype(prototypeId: string): Promise<Prototype> {
  const { data } = await foragerClient.get<Prototype>(`${foragerUrl}/v1/prototypes/${prototypeId}`);
  return {
    ...data,
    attributes: data.connectorSettingConfigurations
      ? data.connectorSettingConfigurations.map((conn) => {
          return {
            ...conn,
            name: conn.key || conn.name,
          };
        })
      : data.attributes,
  };
}

export async function addConnector(connector: Prototype | Connector): Promise<Connector> {
  const { data } = await foragerClient.post<Connector>(`${foragerUrl}/v1/connectors`, {
    ...connector,
    connectorId: null,
  });
  return data;
}

export async function updateConnector(connector: Connector): Promise<Connector> {
  const { data } = await foragerClient.put<Connector>(
    `${foragerUrl}/v1/connectors/${connector.connectorId}`,
    connector
  );
  return data;
}

export async function getConnectors(): Promise<Connector[]> {
  const { data } = await foragerClient.get<Connector[]>(`${foragerUrl}/v1/connectors`);
  return data;
}

// This returns a 500 when failing and a 204 when succeeding - with no response body for either
export async function getConnectorAuthStatus(connectorId: string): Promise<boolean> {
  try {
    await foragerClient.post<unknown>(`${foragerUrl}/v1/security/${connectorId}/authorise`);
    return true;
  } catch {
    return false;
  }
}

export async function deleteConnector(connectorId: string): Promise<void> {
  await foragerClient.delete<Connector>(`${foragerUrl}/v1/connectors/${connectorId}`);
  return;
}

export async function searchConnectorsV2(
  searchTerm: string,
  connectorIds: string[],
  translateQuery: boolean,
  maxResults: number
): Promise<{ searchId: string }> {
  const { data } = await foragerClient.post<{ searchId: string }>(`${foragerUrl}/v1/search`, {
    parameters: {
      searchTerm,
      translateQuery,
    },
    connectors: connectorIds.map((id) => ({
      id,
      maxResults,
    })),
  });

  return data;
}

export async function extendSearch(
  searchTerm: string,
  searchId: string,
  connectorIds: string[],
  maxResults: number
): Promise<{ searchId: string }> {
  const { data } = await foragerClient.post<{ searchId: string }>(`${foragerUrl}/v1/search/${searchId}`, {
    parameters: {
      searchTerm,
    },
    connectors: connectorIds.map((id) => ({
      id,
      maxResults,
    })),
  });
  return data;
}

export async function getSearchResults(
  searchId: string,
  connectorIds: string[],
  selectedResults: string[],
  timestamp = 0,
  sortBy: ConnectorSortBy = ConnectorSortBy.Relevancy,
  sortOrder: ConnectorSortOrder = ConnectorSortOrder.Descending,
  page: number,
  size: number
): Promise<SearchResult> {
  const { data } = await foragerClient.post<SearchResult>(
    `${foragerUrl}/v1/results?searchId=${searchId}&timestamp=${timestamp}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&size=${size}`,
    { connectorIds, recordIds: selectedResults }
  );

  return data;
}

export async function getHarvestUrl(searchId: string): Promise<{ location: string; resultCount: number }> {
  const { data } = await foragerClient.post<{ location: string; resultCount: number }>(
    `${foragerUrl}/v1/results/harvest?searchId=${searchId}`
  );
  return data;
}

export async function createConnectorGroup(name: string, scope: ConnectorVisibility): Promise<ConnectorGroup> {
  const { data } = await foragerClient.post<ConnectorGroup>(`${foragerUrl}/v1/connector-groups`, {
    name,
    scope,
  });
  return data;
}

export async function getConnectorGroups(): Promise<ConnectorGroup[]> {
  const { data } = await foragerClient.get<ConnectorGroup[]>(`${foragerUrl}/v1/connector-groups`);
  return data.map((d) => ({
    ...d,
    connectors: d.connectors || [],
  }));
}

export async function editConnectorGroup(
  id: string,
  name: string,
  scope: ConnectorVisibility
): Promise<ConnectorGroup> {
  const { data } = await foragerClient.put<ConnectorGroup>(`${foragerUrl}/v1/connector-groups/${id}`, {
    name,
    scope,
  });
  return data;
}

export async function deleteConnectorGroup(id: string): Promise<ConnectorGroup> {
  const { data } = await foragerClient.delete<ConnectorGroup>(`${foragerUrl}/v1/connector-groups/${id}`);
  return data;
}
