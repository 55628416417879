import { UseQueryOptions, UseQueryResult } from "react-query";
import { getAlertTypeUsage, getLibrariesBySourceConfig } from "../http/libraries";
import { getDocumentLibrary } from "../http/search/documentLibrary";
import useOrgId from "./useOrgId";
import { useQuery } from "./useQuery";
import { LibraryItem } from "../models/search/documentLibrary";

export default function useLibrary(
  libraryId: string | undefined | null,
  { refetchInterval = false, ...options }: UseQueryOptions<LibraryItem | null> = {}
): UseQueryResult<LibraryItem | null, unknown> {
  const orgId = useOrgId();
  return useQuery(
    ["document-library", libraryId],
    async () => (libraryId ? getDocumentLibrary(orgId, libraryId) : null),
    { refetchInterval, ...options }
  );
}

export const libraryAlertTypeUsageKey = "alert-type-usage";

export function useLibraryAlertTypeUsage(id?: string | null) {
  const orgId = useOrgId();
  return useQuery([libraryAlertTypeUsageKey, id], () => (id ? getAlertTypeUsage(orgId, id) : null));
}

export const libraryByConfigIdKey = "library-by-collection-config";

export function useLibraryByConfigId(id?: string | null) {
  const orgId = useOrgId();
  return useQuery([libraryByConfigIdKey, id], () => (id ? getLibrariesBySourceConfig(orgId, id) : undefined));
}
