import { LinkBlueSky, Silver } from "@amplyfi/ui-components/theme/colors";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router";
import { link } from "../../../css/mixins";
import useCountApi from "../../../hooks/useCountApi";
import { useAnalyseSelector } from "../../../store/reducers";
import { routeChange } from "../../../store/reducers/searchRequest/actions";
import { onSetOpenCloseDrawer, setShowList } from "../../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import DocumentTimelineCard from "../../Cards/DocumentTimelineCard";
import RelatedDocumentViewer from "../../RelatedDocumentViewer/RelatedDocumentViewer";
import NoDocumentsFound from "../../Widgets/Document/NoDocumentsFound";
import FilterDrawer from "../../Widgets/Filters/FilterDrawer";
import NoLibrarySelected from "../../Widgets/NoLibrarySelected/NoLibrarySelected";
import { CONTAINER_WIDTH, usePageStyles } from "../useLandingStyles";
import ErrorBoundary from "./ErrorBoundary";
import Overview from "./Overview";
import TopNav from "./TopNav";
import useReports from "../../../hooks/useReports";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridCard: {
      display: "flex",
    },
    main: {
      display: "flex",
      paddingBottom: theme.spacing(),
      overflow: "hidden",
    },
    topContainer: {
      display: "flex",
      flex: "0 1 auto",
      flexDirection: "column",
      height: "100%",
      [theme.breakpoints.down(1825)]: {
        padding: `0 ${theme.spacing(10)}px 0 ${theme.spacing(10) + 230}px`,
      },
      paddingLeft: 250,
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      height: 52,
      maxWidth: CONTAINER_WIDTH,
      marginLeft: "auto",
      marginRight: "auto",
    },
    mainBody: {
      maxWidth: CONTAINER_WIDTH,
      margin: "0 auto",
      marginBottom: theme.spacing(10),
    },
    searchItems: {
      display: "flex",
      paddingLeft: "0 !important",
    },
    link: {
      ...link,
    },
    progressIndicator: {
      position: "absolute",
      bottom: 1,
      width: "100%",
      background: "transparent",
      "& .MuiLinearProgress-barColorPrimary": {
        background: LinkBlueSky,
      },
    },
    headerSpacing: {
      marginTop: theme.spacing(3),
    },
    appBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    tabsStyle: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    backButtonContainer: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
    adminHeader: {
      display: "flex",
      maxHeight: 52,
    },
    strapline: {
      fontWeight: "bold",
      color: "#91979d",
      paddingRight: theme.spacing(2),
      borderRight: `1px solid ${Silver}`,
      alignSelf: "center",
    },
    border: {
      borderRight: `1px solid ${Silver}`,
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    topMainContainer: {
      [theme.breakpoints.down(1825)]: {
        padding: `0 ${theme.spacing(10)}px 0 ${theme.spacing(10) + 230}px !important`,
      },
    },
  })
);

export default function Home(): JSX.Element {
  const classes = useStyles();
  const pageClasses = usePageStyles();
  const { reports } = useReports();
  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { libraries } = url;
  const { isFetched, isError } = useCountApi(url);
  const dispatch = useDispatch();
  const noDocumentsFound = isError && isFetched;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const unregister = history.listen((location) => {
      if (location.pathname.includes("/analyse")) {
        dispatch(routeChange());
      }
    });

    if (location.pathname.includes("/analyse")) {
      dispatch(routeChange());
    }

    return () => unregister();
  }, [history, dispatch, location.pathname]);

  function handleRDVClose() {
    dispatch(onSetOpenCloseDrawer(false));
    dispatch(setShowList(true));
  }

  return (
    <Container maxWidth="xl" className={clsx(classes.topContainer, classes.topMainContainer)}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.topBar}>
            <div style={{ display: "flex", width: "100%" }}>
              <Route path="/">
                <div className={pageClasses.topBarLogo} style={{ width: "100%" }}>
                  <Route path="/analyse">
                    <TopNav />
                  </Route>
                </div>
              </Route>
            </div>
            <div className={classes.adminHeader}></div>
          </div>
          <div className={classes.mainBody}>
            <Switch>
              <Route path="/analyse">
                {!isError ? (
                  <>
                    <RelatedDocumentViewer onClose={handleRDVClose} />
                    <FilterDrawer />
                    <div className={classes.main}>
                      <Grid style={{ overflow: "auto" }} container spacing={2}>
                        <Grid item xs={12}>
                          <Switch>
                            {reports.map(({ component, to }) => (
                              <Route
                                key={to}
                                path={to}
                                exact
                                render={() =>
                                  libraries.length === 0 ? (
                                    <NoLibrarySelected />
                                  ) : (
                                    <>
                                      <DocumentTimelineCard />
                                      <ErrorBoundary>{component}</ErrorBoundary>
                                    </>
                                  )
                                }
                              />
                            ))}
                            <Route>
                              {libraries.length > 0 && <DocumentTimelineCard />}
                              <Overview />
                            </Route>
                          </Switch>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : null}
                {noDocumentsFound && <NoDocumentsFound />}
              </Route>
            </Switch>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
