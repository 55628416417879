import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

interface ReportTemplateProps {
  title: string;
  description: string;
  readMore?: string;
  children: JSX.Element;
  backToQuery?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    titleContainer: {
      textAlign: "center",
      margin: theme.spacing(4, 0),
    },
    title: {
      marginBottom: theme.spacing(2.5),
    },
    desc: {},
    actionsContainer: {
      cursor: "pointer",
    },
    backText: {
      color: theme.palette.links.primary,
    },
    descContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
    readMore: {
      fontWeight: "normal",
    },
  })
);

export default function ReportTemplate(props: ReportTemplateProps): JSX.Element {
  const { children, description, title, readMore } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.descContainer}>
          <Typography variant="h4" className={classes.desc}>
            {description}
          </Typography>
          {readMore && (
            <Typography variant="h4" className={classes.readMore}>
              {readMore}
            </Typography>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}
