import moment from "moment";
import { ParsedQueryParams } from "../models/queryParams";
import { DEFAULT_INTERVAL } from "../store/reducers/searchRequest/url-functions";
import { Days, timeIntervalTypes, TimeIntervalType } from "./timeIntervals";

const MAXIMUM_TIME_UNITS_WITHIN_DATE_RANGE = 60;

const timeIntervalOrder = timeIntervalTypes.reverse();

export const intervalUpdate = ({ endDate, startDate }: ParsedQueryParams): TimeIntervalType => {
  const start = moment.utc(startDate);
  const end = moment.utc(endDate === Days.Today ? undefined : endDate);

  let diff: number;
  let index = -1;
  let interval: TimeIntervalType;

  if (!startDate || !endDate) {
    // When user has selected a "custom" period (eg. "All" documents)
    // we should really update both start & end dates within Url reducer
    // As we have not done this - we are forced to adopt a sane default here
    return DEFAULT_INTERVAL;
  }

  do {
    index = index + 1;
    const { id, momentDiffUnit } = timeIntervalOrder[index];
    interval = id;
    diff = Math.abs(end.diff(start, momentDiffUnit));
  } while (diff > MAXIMUM_TIME_UNITS_WITHIN_DATE_RANGE && index < timeIntervalOrder.length);

  return interval;
};
