import { Datum, ResponsiveLine, SliceTooltipProps } from "@nivo/line";
import { ChartPoint } from "chart.js";
import moment from "moment";
import React from "react";
import { TimeIntervalType } from "../../helpers/timeIntervals";
import { getNivoTimeUnitFromTimeInterval, getNivoTimeUnitTicks } from "../../helpers/nivo";
import { chartColours } from "./helpers/chartColours";
import { SmoothingType } from "../../store/reducers/searchResponse/documentTimelineReducer";

interface SentimentLineChartProps {
  sentiment: ChartPoint[];
  height?: number | string;
  interval: TimeIntervalType;
  minimalDisplay?: boolean;
  smoothing: SmoothingType;
}

export default function SentimentLineChart({
  sentiment,
  height = 250,
  interval,
  smoothing,
}: SentimentLineChartProps): JSX.Element {
  const colours = chartColours;
  const datasets = [
    {
      id: "Sentiment",
      data: sentiment.map<Datum>(({ x, y }) => ({
        x: (x as string).slice(0, 10),
        y: y as number,
      })),
    },
  ];

  function getSliceTooltip(tooltipData: React.PropsWithChildren<SliceTooltipProps>) {
    const points = tooltipData.slice.points.map(({ data }, i) => <li key={`${i}`}>Sentiment: {data.yFormatted}</li>);

    return (
      <div
        style={{
          background: "white",
          boxShadow:
            "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
          padding: 8,
        }}
      >
        <span style={{ display: "block" }}>
          <strong>Date: </strong> {moment(tooltipData.slice.points[0].data.x).format("YYYY-MM-DD")}
        </span>
        <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>{points}</ul>
      </div>
    );
  }

  return (
    <>
      <div style={{ height: height }}>
        <ResponsiveLine
          margin={{ top: 25, bottom: 90, left: 65, right: 65 }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: true,
            precision: "day",
          }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            reverse: false,
            stacked: false,
            clamp: true,
            nice: true,
          }}
          enableGridX={false}
          axisLeft={{
            tickValues: 1,
          }}
          axisBottom={{
            legendPosition: "end",
            tickPadding: 5,
            tickRotation: -45,
            format: getNivoTimeUnitFromTimeInterval(interval),
            tickValues: getNivoTimeUnitTicks(interval),
          }}
          curve={smoothing}
          colors={colours}
          useMesh={true}
          data={datasets}
          enableSlices="x"
          sliceTooltip={getSliceTooltip}
          markers={[
            {
              axis: "y",
              value: 0,
              lineStyle: { stroke: "#36454F", strokeWidth: 2 },
            },
          ]}
        />
      </div>
    </>
  );
}
