import React from "react";
import DocumentDrawer from "./DocumentDrawer";

export interface RelatedDocumentViewerProps {
  onClose: () => void;
}

export default function RelatedDocumentViewer(props: RelatedDocumentViewerProps): JSX.Element | null {
  return <DocumentDrawer {...props} />;
}
