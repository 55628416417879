import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { Entity, EntityType } from "../../../models/entity";
import { GeoItem } from "../../../models/geo";
import EntityTooltip from "../Entity/EntityTooltip";
import { ReactComponent as CheckboxChecked } from "../../../assets/checkbox-tick.svg";
import { ReactComponent as CheckboxUnchecked } from "../../../assets/checkbox.svg";
import { LightGrey } from "@amplyfi/ui-components/theme/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipContainer: {
      width: "100%",
    },
    listItemContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    hideIcon: {
      color: LightGrey,
      marginRight: theme.spacing(),
      marginLeft: theme.spacing(1),
      cursor: "pointer",
      width: 15,
      height: 15,
    },
    lhsText: {
      display: "flex",
      alignItems: "center",
    },
    text: {
      fontWeight: "bold",
    },
    countryName: {
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.links.primary,
      },
    },
  })
);

export default function CountryListItem(props: {
  country: GeoItem;
  entityTooltipProps: Partial<React.ComponentProps<typeof EntityTooltip>>;
  index: number;
  hiddenCountries: Record<string, boolean>;
  onHideCountry: (id: string) => void;
  onShowCountry: (id: string) => void;
  selectedGeoType?: EntityType;
  onClose: () => void;
}): JSX.Element {
  const {
    country,
    entityTooltipProps,
    index,
    hiddenCountries,
    onHideCountry,
    onShowCountry,
    selectedGeoType,
    onClose,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState<Entity>();

  const GENERIC_COUNTRY_NAMES = ["WO", "WIPO", "WO - WIPO", "WO-WIPO"];

  return (
    <EntityTooltip
      entity={open}
      onClose={() => setOpen(undefined)}
      {...entityTooltipProps}
      className={classes.tooltipContainer}
    >
      <div className={classes.listItemContainer}>
        <div className={classes.lhsText}>
          <Typography
            className={clsx(classes.text, classes.countryName)}
            onClick={(_) => {
              onClose();
              setOpen({
                name: country.countryName,
                id: country.id,
                type: selectedGeoType,
              });
            }}
          >
            {index + 1}. {country.countryName}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" className={classes.text}>
            {country.documentCount.toLocaleString()}
          </Typography>
          {!GENERIC_COUNTRY_NAMES.includes(country.countryName) &&
            (hiddenCountries[country.id] ? (
              <CheckboxUnchecked className={classes.hideIcon} onClick={() => onShowCountry(country.id)} />
            ) : (
              <CheckboxChecked className={classes.hideIcon} onClick={() => onHideCountry(country.id)} />
            ))}
        </div>
      </div>
    </EntityTooltip>
  );
}
