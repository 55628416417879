import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Typography } from "@material-ui/core";
import React from "react";
import useCountApi from "../../../hooks/useCountApi";
import { ParsedQueryParams } from "../../../models/queryParams";

interface DocCountLabelProps {
  urlState: ParsedQueryParams;
}

export default function DocCountLabel(props: DocCountLabelProps): JSX.Element {
  const { urlState } = props;
  const { data: docCount = 0, isLoading: countLoading } = useCountApi(urlState);

  return (
    <>
      {countLoading ? (
        <AiCircularProgress size={16} useContainer={false} />
      ) : (
        <Typography variant="button">{docCount.toLocaleString()} Docs</Typography>
      )}
    </>
  );
}
