import { FormControlLabel, makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, spacing }) => ({
  label: {
    margin: 0,
    "& .MuiFormControlLabel-label": {
      marginBottom: spacing(1),
      marginRight: "auto",
    },
  },
  field: {
    "& .MuiInputBase-root": {
      border: "1px solid #AAA",
    },
  },
  fullWidth: {
    width: "100%",
  },
  error: {
    "& .Mui-error": {
      borderColor: palette.error.main,
    },
  },
  multiline: {
    "& .MuiInputBase-root": {
      height: "auto",
      padding: spacing(1),
      minHeight: 40,
    },
  },
}));

interface TextEntryFieldProps {
  wrapperStyle?: React.CSSProperties;
}

export default function TextEntryField(props: TextFieldProps & TextEntryFieldProps) {
  const { label, ...rest } = props;
  const classes = useStyles();

  return (
    <FormControlLabel
      style={props.wrapperStyle}
      className={clsx(classes.label, props.fullWidth && classes.fullWidth, props.multiline && classes.multiline)}
      labelPlacement="top"
      label={label}
      control={<TextField {...rest} className={clsx(rest.className, classes.field, props.error && classes.error)} />}
    ></FormControlLabel>
  );
}
