import { combineReducers } from "@reduxjs/toolkit";
import { shallowEqual, TypedUseSelectorHook, useSelector } from "react-redux";
import customizationReducer from "./customization/customizationReducer";
import searchRequestReducer from "./searchRequest";
import searchResponseReducer from "./searchResponse";
import snackReducer from "./ui/snackReducer";

const rootReducer = combineReducers({
  searchRequest: searchRequestReducer,
  searchResponse: searchResponseReducer,
  snack: snackReducer,
  customization: customizationReducer,
});

export default rootReducer;

export type AppState = ReturnType<typeof rootReducer>;

export const useAnalyseSelector: TypedUseSelectorHook<AppState> = (selector) => {
  return useSelector(selector, shallowEqual);
};
