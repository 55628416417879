import AiButton from "@amplyfi/ui-components/components/Button";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ReactComponent as EmptyHarvestIcon } from "../../../../assets/empty-harvest.svg";
import useHarvestConfig from "../../../../hooks/useHarvestConfig";
import { Harvest } from "../../../../models/harvest";
import SimpleDialog from "../../../Widgets/Dialog/SimpleDialog";
import { getHarvestConfig } from "../helpers/harvest";
import { HARVEST_ID_PARAM, ORGANISATION_ID_PARAM } from "../helpers/queryParams";
import { HarvestDetail } from "./HarvestDetail";
import { HarvestItem } from "./HarvestItem";
import { columnHeaderClassName, COLUMNS, useHavestTableStyles } from "./harvestTable";

interface HarvestsProps {
  harvests: Harvest[];
  onCreate?: () => void;
}

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    header: {
      fontSize: 9,
      letterSpacing: "0.03em",
      textTransform: "uppercase",
    },
    list: {
      alignSelf: "flex-start",
      listStyle: "none",
      margin: spacing(2),
      padding: 0,
      width: "100%",
      "& > li": {
        border: "1px solid #c0c0c0",
        borderWidth: "1px 0 0",
        display: "flex",
        justifyContent: "space-between",
        "&:first-child": {
          borderWidth: "0 0 2px",
        },
      },
    },
    noResults: {
      alignSelf: "center",
      color: palette.text.disabled,
      textAlign: "center",
    },
  })
);

const sortHarvests = ({ createdAt: a }: Harvest, { createdAt: b }: Harvest) => {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
};

export const Harvests = ({ harvests, onCreate }: HarvestsProps): JSX.Element => {
  const classes = useStyles();
  const { search } = useLocation();
  const { replace } = useHistory();
  const tableClasses = useHavestTableStyles();
  const { data: harvestConfigs } = useHarvestConfig();

  const [query, setQuery] = useState<URLSearchParams>(new URLSearchParams(search));

  const clearHarvestQuery = () => {
    if (query.has(HARVEST_ID_PARAM)) {
      query.delete(HARVEST_ID_PARAM);
      query.delete(ORGANISATION_ID_PARAM);
      replace({
        search: query.toString(),
      });
    }
  };

  useEffect(() => {
    setQuery(new URLSearchParams(search));
  }, [search]);

  return (
    <>
      {query.has(HARVEST_ID_PARAM) &&
        <SimpleDialog
          title="Harvest Breakdown"
          primary={null}
          secondary={null}
          hideTitle
          content={<HarvestDetail
            harvestId={query.get(HARVEST_ID_PARAM) as string}
            organisationId={query.get(ORGANISATION_ID_PARAM) as string}
          />}
          data-testid={testId("harvest", "harvest-breakdown")}
          onClose={() => clearHarvestQuery()}
        />
      }
      {harvests.length ? (
        <ul className={classes.list}>
          <li className={classes.header}>
            {COLUMNS.map(({ header }, i) => (
              <div
                key={header?.toString()}
                className={clsx(tableClasses.column, tableClasses[columnHeaderClassName(i)])}
              >
                {header}
              </div>
            ))}
          </li>
          {harvests.sort(sortHarvests).map((harvest, i) => (
            <HarvestItem
              harvest={harvest}
              harvestConfig={getHarvestConfig(harvest.source, harvestConfigs)}
              key={`harvest-${i}`}
            />
          ))}
        </ul>
      ) : (
        <div className={classes.noResults} data-testid={testId("harvest", "no-harvests")}>
          <EmptyHarvestIcon />
          <p>You have no queries in your harvest list</p>
          <p>Start harvesting documents into your library</p>
          <p>
            <AiButton data-testid={testId("harvest", "harvests", "create")} onClick={onCreate} disabled={!onCreate}>
              Create New Harvest
            </AiButton>
          </p>
        </div>
      )}
    </>
  );
};
