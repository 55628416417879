import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Entity } from "../../../models/entity";
import { resetState } from "../searchRequest/actions";

interface RiskState {
  reset: boolean;
  sentenceFilters: string[];
  sentenceEntityFilters: Entity[];
  selectedSentenceFilters: string[];
}

const initialState: RiskState = {
  reset: false,
  sentenceFilters: [],
  sentenceEntityFilters: [],
  selectedSentenceFilters: [],
};

const riskSlice = createSlice({
  name: "risk",
  initialState,
  reducers: {
    onAddRiskSentencesFilter(state: RiskState, action: PayloadAction<Entity>) {
      state.sentenceEntityFilters.push(action.payload);
    },
    onRemoveRiskSentencesFilter(state: RiskState, action: PayloadAction<number>) {
      const filterIndex = action.payload;
      if (filterIndex > -1) {
        state.sentenceEntityFilters.splice(filterIndex, 1);
      }
    },
    onChangeRiskSentencesFilter(state: RiskState, action: PayloadAction<string>) {
      if (state.selectedSentenceFilters.includes(action.payload)) {
        state.selectedSentenceFilters = state.selectedSentenceFilters.filter((x) => x !== action.payload);
      } else {
        state.selectedSentenceFilters.push(action.payload);
      }
    },
  },
  extraReducers: {
    [resetState.type]: function () {
      return initialState;
    },
  },
});

export const { onAddRiskSentencesFilter, onChangeRiskSentencesFilter, onRemoveRiskSentencesFilter } = riskSlice.actions;

export default riskSlice.reducer;
