import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

interface UseDeleteProps<TResponseType> {
    url: string;
    key: string;
    onDataChange: (oldData: TResponseType, variables: string) => TResponseType;
}

export default function useDelete<TResponseType>(props: UseDeleteProps<TResponseType>) {
    const queryClient = useQueryClient();
    return useMutation<TResponseType, unknown, string, unknown>((id: string) => {
        return axios.delete(`${props.url}/${id}`);
    }, {
        onMutate(variables) {
            queryClient.setQueriesData<TResponseType | undefined>([props.key], ((oldData: TResponseType | undefined) => {
                if (!oldData) {
                    return undefined;
                }
                return props.onDataChange(oldData, variables);
            }))
        }
    });
}