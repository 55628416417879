import { useQuery, UseQueryResult } from "react-query";
import { SearchResult } from "../http/connectors";
import { searchResults } from "../http/queries/connectors";
import { hasNewResults } from "../helpers/connectors";

export default function useConnectorStatus(
  searchId: string,
  selectedConnectors: string[]
): UseQueryResult<SearchResult> {
  const res: UseQueryResult<SearchResult> = useQuery(
    ["getSearchStatus", searchId, selectedConnectors],
    async () => {
      const results = await searchResults(searchId, selectedConnectors, [], 0);

      if (results === undefined) {
        return undefined;
      }
      return results;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchInterval: (data: SearchResult | undefined) => {
        if (!data) {
          return 2000;
        }
        return hasNewResults(data) ? 2000 : false;
      },
    }
  );
  return res;
}
