import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Green, Red } from "@amplyfi/ui-components/theme/colors";
import React from "react";
import { useDispatch } from "react-redux";
import { link } from "../../css/mixins";
import { EntityType } from "../../models/entity";
import { useAnalyseSelector } from "../../store/reducers";
import { addKeyword } from "../../store/reducers/searchRequest/urlReducer";
import {
  addSentimentSentencesFilter,
  removeSentimentSentencesFilter,
} from "../../store/reducers/searchResponse/sentimentReducer";
import PaginatedSentenceList from "../Widgets/Misc/PaginatedSentenceList";
import EntityList from "../Widgets/Search/EntityList";
import SentimentMark from "../Widgets/Sentiment/SentimentMark";
import Card from "./Card";
import Header from "./Header";
import { cardDetails } from "../../helpers/cardDetailsHelper";
import { errorMessages } from "../../helpers/errorHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    markContent: {
      padding: theme.spacing(),
    },
    link: {
      ...link(theme),
    },
    list: {
      "& .MuiListItem-root": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    title: {
      width: "100%",
      color: theme.palette.links.primary,
      "& > *": {
        color: theme.palette.links.primary,
      },
    },
  })
);

export default function SentimentSentencesCard(): JSX.Element | null {
  const classes = useStyles();
  const sentimentSentenceFilters = useAnalyseSelector((x) => x.searchResponse.sentiment.sentimentSentencesFilters);
  const sentimentSentences = useAnalyseSelector((x) => x.searchResponse.sentiment.sentiment);
  const loading = useAnalyseSelector((x) => x.searchResponse.sentiment.sentencesLoading);
  const isError = useAnalyseSelector((x) => x.searchResponse.sentiment.sentencesError);
  const dispatch = useDispatch();
  const noData =
    sentimentSentences &&
    sentimentSentences.negative.total === 0 &&
    sentimentSentences.positive.total === 0 &&
    !loading;
  const title = <Header title={cardDetails.sentimentSentences.title} />;

  return (
    <Card
      id={cardDetails.sentimentSentences.dataTestId}
      feedbackComponentTitle={cardDetails.sentimentSentences.title}
      infoContent={cardDetails.sentimentSentences.readMore}
      dataTestId={cardDetails.sentimentSentences.dataTestId}
      title={title}
      loading={loading}
      isError={isError}
      error={errorMessages.sentimentSentences}
      size="large"
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EntityList
              inlineAdder
              onRemove={(index, entity) => {
                dispatch(removeSentimentSentencesFilter(index));
              }}
              onAdd={(entity) => {
                if (!noData || sentimentSentenceFilters.length > 0) {
                  dispatch(addSentimentSentencesFilter(entity));
                } else {
                  dispatch(addKeyword({ entity }));
                }
              }}
              singleTypeSelector={noData && sentimentSentenceFilters.length === 0 ? EntityType.Organisation : undefined}
              fullWidth
              joinedBy="and"
              entities={sentimentSentenceFilters}
              placeholderText={!noData || sentimentSentenceFilters.length > 0 ? "Add query.." : "Search Companies"}
            />
          </Grid>
          {noData ? (
            <Typography>Search for a company to see the sentiment connected to a company</Typography>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Card
                  isError={false}
                  contentClasses={classes.markContent}
                  content={
                    <SentimentMark colour={Green} label={`${sentimentSentences.positive.total} Positive mentions`} />
                  }
                />
                <Grid item xs={12}>
                  <PaginatedSentenceList sentences={sentimentSentences.positive.sentences} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  isError={false}
                  contentClasses={classes.markContent}
                  content={
                    <SentimentMark colour={Red} label={`${sentimentSentences.negative.total} Negative mentions`} />
                  }
                />
                <Grid item xs={12}>
                  <PaginatedSentenceList sentences={sentimentSentences.negative.sentences} />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      }
    />
  );
}
