import { combineReducers } from "@reduxjs/toolkit";
import companyProfileReducer from "./companyProfileReducer";
import documentTimelineReducer from "./documentTimelineReducer";
import geoReducer from "./geoReducer";
import overviewReducer from "./overviewReducer";
import relatedDocumentViewerReducer from "./relatedDocumentViewerReducer";
import riskReducer from "./riskReducer";
import sectorsReducer from "./sectorsReducer";
import sentimentReducer from "./sentimentReducer";

export default combineReducers({
  overview: overviewReducer,
  relatedDocumentViewer: relatedDocumentViewerReducer,
  documentTimeline: documentTimelineReducer,
  sentiment: sentimentReducer,
  sectors: sectorsReducer,
  geo: geoReducer,
  risk: riskReducer,
  companyProfile: companyProfileReducer,
});
