import { booleanField } from "../components/entity/field/BooleanField";
import { numberField } from "../components/entity/field/NumberField";
import { stringField } from "../components/entity/field/StringField";
import { multiStringField } from "../components/entity/field/MultiStringField";
import { AmplyfiEditableFields, AmplyfiAllEditableFields, AmplyfiUnknownEntity } from "../models";

interface HandlerOptions {
  disabled?: boolean;
  error?: string;
  validation?: string;
}

export interface FieldHandlerOptions {
  label: string;
}

export interface Validator {
  matches: RegExp;
  message: string;
  negate?: boolean;
}

export interface FieldHandlerValidatorOptions {
  required?: boolean;
  validators?: Validator[];
}

export type HandlerOnChange<T> = (value: T, invalidMessage?: string) => void;

export type Handler<T = unknown> = (
  value: T,
  name: keyof AmplyfiAllEditableFields,
  onChange: HandlerOnChange<T>,
  options: HandlerOptions
) => JSX.Element;

type UnknownHandler = Handler<boolean> | Handler<number> | Handler<string> | Handler<string[]>;

const fieldHandlers: Record<keyof AmplyfiAllEditableFields, UnknownHandler> = {
  aliases: multiStringField({ label: "Aliases", singular: "alias" }),
  description: stringField({ label: "Description" }),
  employeeCount: numberField({
    label: "Employee count",
  }),
  founded: stringField({
    label: "Founded date",
    validators: [{ matches: /^\d{4}(-\d{2}(-\d{2})?)?$/, negate: true, message: "Must be a date in 2023-10-26" }],
  }),
  industries: multiStringField({
    label: "Industries",
    singular: "industry",
  }),
  linkedin: stringField({ label: "LinkedIn identifier", placeholder: "LinkedIn identifier", required: true }),
  socialFollowerCount: numberField({ label: "Social follower count" }),
  subTechnologies: multiStringField({
    label: "Sub-technologies",
    singular: "sub-technology",
  }),
  technologyType: stringField({ label: "Technology type" }),
  verified: booleanField({ label: "Verified entity" }),
  websites: multiStringField({
    label: "Websites",
    singular: "website",
    required: true,
  }),
  wikidataId: stringField({
    label: "WikiData identifier",
  }),
};

export const fieldHandler = <T extends AmplyfiUnknownEntity>(field: keyof AmplyfiEditableFields<T>): UnknownHandler =>
  fieldHandlers[field];

export const sanitiseFields = <T extends AmplyfiUnknownEntity>(fields: AmplyfiEditableFields<T>): T =>
  Object.keys(fields)
    .filter((key) => Object.keys(fieldHandlers).includes(key))
    .reduce((obj2, key) => {
      obj2[key as keyof T] = fields[key as keyof typeof fields];
      return obj2;
    }, {} as T);

export const sortFields = (a: string, b: string): number => {
  if (a === "verified") {
    return 1;
  }
  if (b === "verified") {
    return -1;
  }
  return a.localeCompare(b);
};
