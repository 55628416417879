import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SmoothingType {
  "Linear" = "linear",
  "Basis" = "basis",
  "Cardinal" = "cardinal",
  "CatmullRom" = "catmullRom",
  "Natural" = "natural",
  "Step" = "step",
}

export interface DocumentTimelineState {
  skewCorrection: boolean;
  smoothing: SmoothingType;
}

const initialState: DocumentTimelineState = {
  skewCorrection: false,
  smoothing: SmoothingType.Linear,
};

const documentTimelineSlice = createSlice({
  name: "documentTimeline",
  initialState,
  reducers: {
    setSkewCorrection(
      state: DocumentTimelineState,
      { payload }: PayloadAction<DocumentTimelineState["skewCorrection"]>
    ) {
      state.skewCorrection = payload;
    },
    setSmoothing(state: DocumentTimelineState, { payload }: PayloadAction<DocumentTimelineState["smoothing"]>) {
      state.smoothing = payload;
    },
  },
});

export const { setSkewCorrection, setSmoothing } = documentTimelineSlice.actions;

export default documentTimelineSlice.reducer;
