import AnalyseCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import "@amplyfi/ui-components/theme/augmentPalette";
import lightTheme from "@amplyfi/ui-components/theme/light-theme";
import { useAuth0 } from "@auth0/auth0-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core/styles";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { AlertListStateProvider } from "../contexts/AlertListContext";
import { WizardProvider } from "../contexts/WizardContext";
import { Days } from "../helpers/timeIntervals";
import { useAnalyseSelector } from "../store/reducers";
import { onGlobalDatesChange } from "../store/reducers/searchRequest/urlReducer";
import AuthWrapper from "./Auth0/AuthWrapper";
import PrivateRoute from "./Auth0/PrivateRoute";
import HomepageWrapper from "./Pages/Analyse/HomepageWrapper";
import Snacker from "./Widgets/Snacker";

export const closedPanelWidth = 70;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    container: {
      transition: "padding-left 225ms cubic-bezier(0.2, 0, 0, 1) 0s",
      height: "100%",
      display: "flex",
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(10),
      },
    },
  })
);

const msUntilMidnight = (): number => {
  const midnight = new Date();
  midnight.setHours(24, 0, 0, 0);
  return midnight.getTime() - new Date().getTime();
};

function App(): JSX.Element {
  const { isLoading: loading } = useAuth0();
  const theme = lightTheme;
  const classes = useStyles();
  const { startDate, endDate } = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const dispatch = useDispatch();

  const timerId = React.useRef<number>();

  const setTimeoutEndDate = React.useCallback(
    () =>
      window.setTimeout(() => {
        if (endDate === Days.Today) {
          dispatch(
            onGlobalDatesChange({
              dates: [startDate?.valueOf(), moment().valueOf()],
            })
          );
          dispatch(
            onGlobalDatesChange({
              dates: [startDate?.valueOf(), undefined],
              isEndDateToday: true,
            })
          );
          timerId.current = setTimeoutEndDate();
        } else {
          window.clearTimeout(timerId.current);
        }
      }, msUntilMidnight()),
    [dispatch, endDate, startDate]
  );

  useEffect(() => {
    clearTimeout(timerId.current);
    if (endDate === Days.Today) {
      setTimeoutEndDate();
    }

    return () => {
      clearTimeout(timerId.current);
    };
  }, [endDate, setTimeoutEndDate]);

  // on window load, hide the address bar
  useEffect(() => {
    window.addEventListener("load", () => {
      setTimeout(() => {
        window.scrollTo(0, 1);
      }, 10);
    });
  }, []);

  return (
    <SkeletonTheme color={theme.palette.componentBackground.main} highlightColor={theme.palette.accent.main}>
      <div className={classes.body}>
        <AlertListStateProvider>
          <WizardProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <div className={clsx(classes.container)}>
                <AuthWrapper>
                  <PrivateRoute component={HomepageWrapper} />
                </AuthWrapper>
                {loading && <AnalyseCircularProgress />}
              </div>
              <Snacker />
            </ThemeProvider>
          </WizardProvider>
        </AlertListStateProvider>
      </div>
    </SkeletonTheme>
  );
}

export default App;
