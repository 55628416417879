import { useEffect, useState } from "react";
import { convertSearchStateToSearchRequestV2 } from "../../helpers/search";
import { TimeIntervalType } from "../../helpers/timeIntervals";
import { ActiveOption } from "../../models/chart-customizations";
import { LibrariesType } from "../../models/queryParams";
import { SearchRequestV2 } from "../../models/search";
import { useAnalyseSelector } from "../../store/reducers";
import { useQuery } from "../useQuery";

interface CustomizationOptions {
  customizations: ActiveOption[];
  secondaryCustomizations: ActiveOption[];
}

export function useCustomizationV2<T>(
  timeInterval: TimeIntervalType,
  getCustomizations: (req: SearchRequestV2, libraries: LibrariesType[], customizations?: ActiveOption[]) => Promise<T>,
  graphKey: string
): {
  onSelectCustomization: (options: ActiveOption[], secondaryOptions: ActiveOption[]) => Promise<void>;
  onReset: () => T | undefined;
  customizationOptions: CustomizationOptions | null;
  results?: T;
  isError: boolean;
  isLoading: boolean;
} {
  const [finalCustomizationOptions, setFinalCustomizationOptions] = useState<CustomizationOptions | null>(null);
  const [results, setResults] = useState<T>();
  const parsedUrl = useAnalyseSelector((x) => x.searchRequest.url.parsed);

  // Retrives the original set of customisation options used on Reset
  const {
    isLoading: originalLoading,
    data: originalData,
    isError: originalError,
  } = useQuery([graphKey, parsedUrl, timeInterval], () => {
    const request = convertSearchStateToSearchRequestV2(parsedUrl);

    return getCustomizations(
      {
        ...request,
        systemFilters: {
          ...request.systemFilters,
          dateInterval: timeInterval,
        },
      },
      parsedUrl.libraries,
      finalCustomizationOptions?.customizations
    );
  });

  // Fetching new customised results based on user's selection for Primary and Secondary axis
  const { data, isFetched, isError, isLoading } = useQuery([graphKey, parsedUrl, finalCustomizationOptions], () => {
    const request = convertSearchStateToSearchRequestV2(parsedUrl);
    return getCustomizations(
      {
        ...request,
        systemFilters: {
          ...request.systemFilters,
          customizations: finalCustomizationOptions?.customizations || [],
          secondaryCustomizations: finalCustomizationOptions?.secondaryCustomizations || [],
        },
      },
      parsedUrl.libraries,
      finalCustomizationOptions?.customizations
    );
  });

  useEffect(() => {
    if (data && isFetched && !isError) {
      setResults(data);
    }
  }, [data, isError, isFetched]);

  async function onSelectCustomization(options: ActiveOption[], secondaryOptions: ActiveOption[]) {
    setFinalCustomizationOptions({ customizations: options, secondaryCustomizations: secondaryOptions });
  }

  // We want to return the original list on Reset but not yet set the state.
  // This should only be applied when the user clicks apply in <CustomizationModalContent/>
  function onReset(): T | undefined {
    return originalData;
  }

  return {
    onSelectCustomization,
    onReset,
    results,
    customizationOptions: finalCustomizationOptions,
    isError: isError || originalError,
    isLoading: isLoading || originalLoading,
  };
}
