import { SortingRule } from "react-table";
import { DEFAULT_PAGE_SIZE } from "../components/Widgets/Table/Table";
import { convertSearchStateToSearchRequestV3 } from "../helpers/search";
import { getAllElementsData } from "../http/element";
import { ElementData, ElementType } from "../models/element";
import { SortOrder } from "../models/search";
import { useAnalyseSelector } from "../store/reducers";
import { useQuery } from "./useQuery";

export default function useAllElements(
  sort: SortingRule<ElementData>,
  page: number,
  searchTerm: string,
  selectedColumnIds: string[],
  selectedEntityTypes: ElementType[]
) {
  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  return useQuery(["global-element-data", sort, page, searchTerm, url, selectedColumnIds, selectedEntityTypes], () => {
    const request = convertSearchStateToSearchRequestV3(url);
    request.systemFilters = {
      ...request.systemFilters,
      sortOrder: sort.desc ? SortOrder.Desc : SortOrder.Asc,
      sortProperty: sort.id,
      page,
      searchTerm: searchTerm.length > 0 ? searchTerm : undefined,
      properties: selectedColumnIds.length === 0 ? undefined : selectedColumnIds.filter((a) => a !== "name"),
      size: DEFAULT_PAGE_SIZE,
      elementTypes: selectedEntityTypes,
    };
    return getAllElementsData(request, url.libraries);
  });
}
