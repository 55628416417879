import axios from "axios";
import { Report } from "../components/Pages/Feed/components/reader/create_report/CreateReportWizard";

const apiUrl = `${process.env.REACT_APP_ANALYSE_API}/v5`;

export async function getEntityReports(libraryId: string, amplyfiEntityId: string) {
  const { data } = await axios.get<Report[]>(
    `${apiUrl}/libraries/${libraryId}/amplyfi-entities/${amplyfiEntityId}/report`
  );
  return data;
}

export async function getLibraryReports(libraryId: string) {
  const { data } = await axios.get<Report[]>(`${apiUrl}/libraries/${libraryId}/report`);
  return data;
}

export async function getSubscribedLibraryReports() {
  const { data } = await axios.get<Report[]>(`${apiUrl}/libraries/SUBSCRIBED/report`);
  return data;
}

export async function getReport(libraryId: string, amplyfiEntityId: string | null, reportId: string, format: string) {
  const entitySegment = amplyfiEntityId ? `amplyfi-entities/${amplyfiEntityId}/` : "";
  const { data } = await axios.get<Report>(`${apiUrl}/libraries/${libraryId}/${entitySegment}report/${reportId}`, {
    params: { format },
  });
  return data;
}
