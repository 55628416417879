import { Box, Card, CardContent, List, makeStyles } from "@material-ui/core";
import { NotificationsActiveOutlined } from "@material-ui/icons";
import { distinct } from "../../../../../../helpers/arrayHelpers";
import { useFeedDocument } from "../../../../../../hooks/useFeed";
import useReaderStore from "../../../../../../store/hooks/useReaderStore";
import useSearchStore from "../../../../../../store/hooks/useSearchStore";
import ChipOverflow from "../../../Configuration/Components/ChipOverflow";
import RelatedEntities from "../../../Configuration/Components/RelatedEntities";
import { TextRenderer } from "../../TextRenderer";
import OverviewSection from "./OverviewSection";

const useStyles = makeStyles(({ spacing }) => ({
  sentenceActions: {
    alignItems: "start",
    marginLeft: spacing(2),
    "& > button": {
      backgroundColor: "#f3f3f3",
      "&:hover:enabled": {
        backgroundColor: "rgb(206, 206, 206)",
      },
    },
  },
  card: {
    background: "#f7fafc",
    borderRadius: spacing(0.75),
    marginBottom: spacing(1),
  },
  columns: {
    display: "flex",
    width: "100%",
  },
  columnSentence: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: 1,
  },
  sentence: {
    fontSize: 15,
    fontWeight: 400,
  },
}));

export default function Highlights() {
  const classes = useStyles();
  const { selectedDocumentId, selectedLibraryId } = useReaderStore();
  const { data } = useFeedDocument(selectedLibraryId, selectedDocumentId as string);
  const { search } = useSearchStore((state) => ({ search: state.search }));

  return (
    <OverviewSection icon={<NotificationsActiveOutlined />} title="Highlights">
      <List>
        {data?.sentences.map((sentence) => (
          <Card elevation={0} className={classes.card}>
            <CardContent>
              <Box className={classes.columns}>
                <Box className={classes.columnSentence}>
                  <TextRenderer match={search} className={classes.sentence} variant="body1">
                    {sentence.text}
                  </TextRenderer>
                  <Box marginTop={2} display="flex" alignItems="center">
                    <RelatedEntities sentence={sentence} documentId={data.documentId} libraryId={data.libraryId} />
                    <ChipOverflow chips={distinct(sentence.alertTypes)} maximumChipsToDisplay={2} />
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </List>
    </OverviewSection>
  );
}
