import React from "react";
import { scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { min, max } from "d3-array";
import {
  ElementData,
  ColumnItem,
  hiddenColumns,
  defaultColumnOrder,
  columnMetaData,
  defaultOptions,
} from "../models/element";
import { camelToTitleCase } from "./sentence";
import { Group } from "@visx/group";

export function getCellColor(value: number): string | undefined {
  if (typeof value !== "number") {
    throw new Error("tableHelper - getCellColor(): The value must be a number");
  }

  if (value === 0) {
    return undefined;
  }
  return value > 0 ? "green" : "red";
}

// Generates table headers from data and applies overrides to specified columns
export function getHeaders(elements: ElementData[] | undefined): ColumnItem[] {
  if (!elements || elements.length === 0) {
    return [];
  }
  const headers = Object.keys(elements[0]);
  return headers
    .filter((header) => !hiddenColumns.includes(header))
    .sort((a, b) => {
      if (defaultColumnOrder.includes(a) && !defaultColumnOrder.includes(b)) {
        return -1;
      }

      if (!defaultColumnOrder.includes(a) && defaultColumnOrder.includes(b)) {
        return 1;
      }

      return defaultColumnOrder.indexOf(a) - defaultColumnOrder.indexOf(b);
    })
    .map((header) => {
      const headerConfig = columnMetaData.find((meta) => meta.accessor === header);
      return {
        ...defaultOptions,
        ...headerConfig,
        Header: headerConfig?.Header || camelToTitleCase(header),
        accessor: header,
      };
    });
}

const chartWidth = 120;
const chartHeight = 60;

export function getChartForCell(values: number[]): JSX.Element {
  const xScale = scaleLinear<number>({
    domain: [0, values.length],
    range: [0, chartWidth],
  });

  const yScale = scaleLinear<number>({
    domain: [min(values) as number, max(values) as number],
    range: [chartHeight - 8, 8],
  });

  return (
    <svg width={chartWidth} height={chartHeight}>
      <Group>
        <LinePath
          data={values}
          x={(_, index) => xScale(index)}
          y={(d) => yScale(d)}
          stroke={values[0] < values[values.length - 1] ? "green" : "red"}
          strokeWidth={2}
          shapeRendering="geometricPrecision"
        />
      </Group>
    </svg>
  );
}
