import { TimeIntervalType } from "../helpers/timeIntervals";
import { RelatedDocumentFilters } from "../hooks/useRelatedDocumentFilters";
import { DocumentListItem, SortType } from "./document";
import { ElementType } from "./element";
import { Entity, EntityPrevalence, EntityType, ExcludeOrInclude, Keyword, NamedItem } from "./entity";
import { KeyPhrase, TimelinePoint } from "./keyPhrase";

export interface SearchResponse extends ConnectionsResponse {
  timeline: TimelinePoint[];
  topics: KeyPhrase[];
  documents: DocumentListItem[];
  documentCount: number;
  estimateTimeline: TimelinePoint[];
}

interface ConnectionsResponse {
  keyPhrases: KeyPhrase[];
  countries: EntityPrevalence[];
  people: EntityPrevalence[];
  organisations: EntityPrevalence[];
}

export enum DocumentType {
  News = "News",
  Patents = "Patents",
  Academic = "Academic",
}

export enum SortOrder {
  Desc = "descending",
  Asc = "ascending",
}

interface SearchRequestQuery {
  allSearchTerms: Keyword[] | undefined;
  anySearchTerms: Keyword[] | undefined;
  exclusionSearchTerms: Keyword[] | undefined;
  luceneQuery?: string;
  withinSentence?: boolean;
}

export interface SearchRequestUserFilters {
  endDate?: string;
  keywords: Keyword[] | undefined;
  keyPhrases: NamedItem[] | undefined;
  news?: NewsFiltersV2;
  startDate?: string;
  includeUndatedDocuments?: boolean;
  libraryIds: string[];
}

interface Tooltip {
  id: string;
  name: string;
  type?: EntityType;
  toolTipType?: TooltipType;
  secondaryId?: string;
  secondaryName?: string;
  secondaryType?: EntityType;
}

interface SearchRequestSystemFilters {
  customizations?: Keyword[];
  dateInterval?: TimeIntervalType;
  documentsCount?: number;
  documentsFilters?: RelatedDocumentFilters;
  entitiesCount?: number;
  exportFileName?: string;
  geographyCount?: number;
  keyPhraseCount?: number;
  mentionsCount?: number;
  mentionsKeywordFilters?: Entity[];
  propertyMentionsFilters?: string[];
  publishedDateSortOrder?: SortType;
  secondaryCustomizations?: Keyword[];
  size?: number;
  tooltip?: Tooltip;
}

interface SearchRequestSystemFiltersV3 {
  customizations?: Keyword[];
  dateInterval?: TimeIntervalType;
  documentsCount?: number;
  documentsFilters?: RelatedDocumentFilters;
  elementTypes?: ElementType[];
  entitiesCount?: number;
  exportFileName?: string;
  geographyCount?: number;
  keyPhraseCount?: number;
  mentionsCount?: number;
  mentionsKeywordFilters?: Entity[];
  propertyMentionsFilters?: string[];
  publishedDateSortOrder?: SortType;
  secondaryCustomizations?: Keyword[];
  size?: number;
  tooltip?: Tooltip;

  // these are for the /v3/elements endpoint
  sortOrder?: SortOrder;
  sortProperty?: string;
  properties?: string[];
  page?: number;
  searchTerm?: string;
}

export interface SearchRequestV2 {
  query: SearchRequestQuery;
  userFilters: SearchRequestUserFilters;
  systemFilters: SearchRequestSystemFilters;
}

export interface SearchRequestV3 {
  query: SearchRequestQuery;
  userFilters: SearchRequestUserFilters;
  systemFilters: SearchRequestSystemFiltersV3;
  useElasticSampler?: boolean;
}

export type FiltersTypeProps = {
  filterTerm: string;
  excludeOrInclude?: ExcludeOrInclude;
};

interface NewsFiltersV2 {
  activeInSectors: FiltersTypeProps[] | undefined;
  adverseMediaCategories: FiltersTypeProps[] | undefined;
  mentionedLocation: FiltersTypeProps[] | undefined;
  publishedLocations: FiltersTypeProps[] | undefined;
  risks: FiltersTypeProps[] | undefined;
  sectors: FiltersTypeProps[] | undefined;
  sources: FiltersTypeProps[] | undefined;
  subSectors: FiltersTypeProps[] | undefined;
}

export enum TooltipType {
  Sector = "Sector",
  SuperSector = "SuperSector",
  Geo = "Geo",
  ActiveIn = "ActiveIn",
  KeyPhrase = "KeyPhrase",
  AdverseMedia = "AdverseMedia",
  Heatmap = "Heatmap",
}

export type SearchType = "FreeText" | EntityType.Organisation | EntityType.Person | EntityType.Country;

export interface AutocompleteResponse {
  id: string;
  name: string;
  sectors: string[];
  superSectors: { sector: string; subSectors: string[] }[];
  wikiDataId?: string;
  type?: EntityType;
}
