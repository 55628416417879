import AiButton from "@amplyfi/ui-components/components/Button";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { Box, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { Chat as TopicIcon, Add as AddIcon, Done, Warning } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOrgId } from "../../../../helpers/user";
import useAIHistory from "../../../../hooks/useAIHistory";
import useLibrary from "../../../../hooks/useLibrary";
import { blockLibraryTopic } from "../../../../http/search/documentLibrary";
import { MonitoringDocument } from "../../../../models/monitoring";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import { goToLibrarySettingsTab } from "../Routing";
import { LibraryFilter } from "../../../../http/libraryFilters";
import SimpleDialog from "../../../Widgets/Dialog/SimpleDialog";
import DynamicComponent from "../Configuration/Components/DynamicComponent";
import { useFeedHistoricFilterCounts } from "../../../../hooks/useFeedHistoricFilterCounts";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { analyticsEvent } from "../../../../helpers/analytics";
import useDialogStore from "../../../../store/hooks/useDialogStore";
import useFeedUpdates from "../../../../hooks/useFeedUpdates";
import { blueShades } from "../../../Charts/helpers/colourScaleHexes";

interface BlockTopicsProps {
  "data-testId"?: string;
  document?: MonitoringDocument;
  libraryId: string | null;
  onClose: (e: React.MouseEvent<Element, MouseEvent>, changed: boolean) => void;
}

interface BlockTopicsContentProps {
  document: MonitoringDocument;
  handleBlockTopic: (documentId: string, topics: LibraryFilter) => Promise<void>;
  isBusy: boolean;
  libraryId: string | null;
  onClose: (e: React.MouseEvent<Element, MouseEvent>, changed: boolean) => void;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  icon: {
    marginRight: spacing(1),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  subTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: spacing(3),
    gap: spacing(1),
  },
  content: {
    marginBottom: spacing(2),
  },
  list: {
    display: "flex",
    listStyle: "none",
    maxHeight: 200,
    overflow: "auto",
    padding: 0,
    flexWrap: "wrap",
    gap: spacing(1),
    marginBlock: spacing(2),
  },
}));

const INCREMENT_ITEMS = 5;
const HISTORIC_FILTER_COUNT_DAYS = 7;
const unifyTopic = ({ id }: LibraryFilter): string => id;

interface TopicProps {
  libraryId: string;
  topic: LibraryFilter;
  disabled: boolean;
  onClick: () => void;
  isSelected?: boolean;
}

function Topic(props: TopicProps) {
  const { topic, disabled, onClick, libraryId, isSelected } = props;
  const { data: historicCounts, isLoading } = useFeedHistoricFilterCounts(
    libraryId,
    "documentTopicId",
    [unifyTopic(topic)],
    HISTORIC_FILTER_COUNT_DAYS
  );
  return (
    <AiButton
      disabled={disabled}
      onClick={onClick}
      style={isSelected ? { opacity: 0.5 } : {}}
    >
      {isSelected && <Done style={{ marginRight: 8, fontSize: 18 }} />}

      {topic.name}
      <Box style={{ marginLeft: 8 }}>{isLoading && <AiCircularProgress size={12} />}</Box>
      {!!historicCounts?.[topic.id] && ` (${historicCounts[topic.id].documentCount})`}
    </AiButton>
  );
}

const BlockTopicsContent = ({
  document: { documentId, documentTopics = [], libraryId },
  handleBlockTopic,
  onClose,
}: BlockTopicsContentProps) => {
  const classes = useStyles();
  const history = useAIHistory();
  const [noItems, setNoItems] = useState<number>(INCREMENT_ITEMS);
  const { data: library } = useLibrary(libraryId);
  const [blockedTopics, setBlockedTopics] = useState<LibraryFilter["id"][]>(
    library?.blockedDocumentTopics.map(({ id }) => id) || []
  );
  const [selectedTopics, setSelectedTopics] = useState<LibraryFilter["id"][]>([]);
  const removeDialog = useDialogStore((state) => state.removeDialog);
  const { reset } = useFeedUpdates();

  const handleConfirm = async () => {
    for (const topicId of selectedTopics) {
      const topic = documentTopics.find(s => unifyTopic(s) === topicId);
      if (topic) {
        await handleBlockTopic(documentId, topic);
      }
    }
    removeDialog();
    reset();
  };

  useEffect(() => {
    if (library?.blockedDocumentTopics) {
      setBlockedTopics(library.blockedDocumentTopics.map(({ id }) => id));
    }
  }, [library]);

  return (
    <SimpleDialog
      title={
        <Typography variant="h2" className={classes.title}>
          <TopicIcon className={classes.icon} /> Block Topics
        </Typography>
      }
      data-testid={testId("document", "block-topic")}
      onClose={(e) => onClose(e, false)}
      content={
        <DynamicComponent>
          <>
            <Box className={classes.content}>
              <Box className={classes.subTitle}>
                <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <Warning />
                  <Typography variant="h4">Blocking these topics will remove past signals, as well as preventing future ones.</Typography>
                </Box>
                <Typography variant="h4"> Please Note: <Typography component="span" style={{ fontStyle: "italic" }}>Unblocking a topic will not return previously removed signals.</Typography></Typography>
              </Box>
              <Box className={classes.list}>
                {documentTopics.slice(0, noItems).map((topic, i) => {
                  return (
                    <Box key={topic.id}>
                      <Topic
                        libraryId={libraryId}
                        topic={topic}
                        disabled={blockedTopics.includes(unifyTopic(topic))}
                        isSelected={selectedTopics.includes(unifyTopic(topic))}
                        onClick={() => {
                          setSelectedTopics(prevTopics => {
                            if (prevTopics.includes(unifyTopic(topic))) {
                              return prevTopics.filter(s => s !== unifyTopic(topic));
                            } else {
                              return [...prevTopics, unifyTopic(topic)];
                            }
                          });
                        }}
                      />
                    </Box>
                  );
                })}
                {noItems < documentTopics.length && (
                  <Typography style={{ marginTop: 0, textAlign: "right" }}>
                    <AiButton
                      amplyfiType="secondary"
                      disabled={noItems >= documentTopics.length}
                      onClick={() => setNoItems(Math.min(noItems + INCREMENT_ITEMS, documentTopics.length))}
                    >
                      <AddIcon />See more
                    </AiButton>
                  </Typography>
                )}
              </Box>
              <Box style={{ marginBlock: 8 }}>

                <Typography variant="body2">
                  Counts show the number of signals received in the last week containing that topic.
                </Typography>
                {libraryId && (
                  <Typography style={{ marginTop: 16 }} variant="body2">
                    Review your blocked topics within{" "}
                    <Link
                      style={{ textDecoration: "none", color: blueShades[3] }}
                      href={goToLibrarySettingsTab(libraryId, "filters")}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        history.pushFeedSettings(libraryId, "filters");
                        removeDialog();
                      }}
                    >
                      Interest filter settings
                    </Link>
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        </DynamicComponent>
      }
      onConfirm={handleConfirm}
      primary="Confirm"
      secondary="Cancel"
    />
  );
};

export const BlockTopics = ({ document, libraryId, onClose }: BlockTopicsProps): JSX.Element => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const { refetch } = useLibrary(libraryId);
  const removeDialog = useDialogStore((state) => state.removeDialog);

  const handleBlockTopic = async (documentId: string, topic: LibraryFilter) => {
    setIsBusy(true);
    setHasChanged(true);
    const body = await blockLibraryTopic(getOrgId(user), libraryId, documentId, topic);
    if (!body) {
      analyticsEvent("Feed", "FilterTopicBlocked", topic.id);
    }
    refetch();
    dispatch(
      setSnack({
        body: body || `${topic.name} topic has now been blocked within this library`,
        title: body ? "Failed" : "Success",
      })
    );
  };

  useEffect(() => {
    if (!document) {
      setIsBusy(false);
    }
  }, [document]);

  return document ? (
    <BlockTopicsContent
      document={document}
      handleBlockTopic={handleBlockTopic}
      isBusy={isBusy}
      libraryId={libraryId}
      onClose={(e) => {
        onClose(e, hasChanged);
        removeDialog();
      }}
    />
  ) : (
    <></>
  );
};
