import { TimePeriod } from "../../models/chart";

export type TimeSelectorType = TimePeriod | "custom";

export const timePeriodLabels: { key: TimeSelectorType; label: string }[] = [
  { key: "3months", label: "3m" },
  { key: "6months", label: "6m" },
  { key: "1year", label: "1y" },
  { key: "3years", label: "3y" },
  { key: "5years", label: "5y" },
  { key: "custom", label: "Custom" },
];
