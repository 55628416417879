import axios from "axios";
import { Entity } from "../models/entity";
import { LibrariesType } from "../models/queryParams";
import { SearchRequestV2, TooltipType } from "../models/search";
import { getAnalysisV2Url } from "./general";

export interface Tooltip {
  name: string;
  documentsCount: number;
  mentionsCount: number;
  description: string;
  sentiment: number | null;
  sentimentOverTime: SentimentOverTimeEntry[];
}

interface SentimentOverTimeEntry {
  date: string;
  averageSentiment: number;
}

export async function getTooltip(
  searchRequest: SearchRequestV2,
  entity: Entity,
  tooltipType?: TooltipType,
  docTypes: LibrariesType[] | null = null
): Promise<Tooltip> {
  searchRequest.systemFilters.tooltip = {
    id: entity.id,
    name: entity.name,
    type: entity.type,
    toolTipType: tooltipType,
  };
  const searchResponse = await axios.post<Tooltip>(getAnalysisV2Url("search/tooltip", docTypes), searchRequest);

  return searchResponse.data;
}
