import { ClickAwayListener, FormControlLabel, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FilterType } from "../../models/filter";
import { GeoAndJurisdiction, GeoItem, MentionedOrPublished } from "../../models/geo";
import EntityTooltip from "../Widgets/Entity/EntityTooltip";
import { CountryList } from "../Widgets/Geography/CountryList";
import AnalyseSwitch from "@amplyfi/ui-components/components/Switch";
import { Entity, EntityType } from "../../models/entity";
import AnalyseRadio from "@amplyfi/ui-components/components/Radio";
import { DocumentType, TooltipType } from "../../models/search";
import mapboxgl from "mapbox-gl";
import ColourScale from "../Widgets/Trends/ColourScale";
import { CARD_PADDING } from "../../css/useCardHeaderStyling";
import { tooltipStyling } from "../../css/mixins";

interface GeoMapChartProps {
  data: GeoAndJurisdiction;
  geoType: MentionedOrPublished;
  setGeoType: (arg: MentionedOrPublished) => void;
  radioState: DocumentType;
  setRadioState: (arg: DocumentType) => void;
}

const useStyles = makeStyles((theme) => ({
  svgContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  country: {},
  optionsContainer: {
    position: "absolute",
    top: 0,
    right: 50,
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  mapContainer: {
    overflow: "hidden",
    position: "relative",
    height: "41vh",
    minHeight: "300px",
    minWidth: "fit-content",
    textAlign: "center",

    "& > svg": {
      // possibly look into doing this through d3 itself?
      transition: "transform ease-out 300ms",
    },
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: `${CARD_PADDING}px ${CARD_PADDING}px 0`,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  toggleText: {
    color: theme.palette.links.primary,
    alignSelf: "center",
  },
  mapboxContainer: {
    height: 700,
  },
  infoContainer: {
    position: "absolute",
    top: theme.spacing(3),
    left: theme.spacing(3),
    backgroundColor: theme.palette.componentBackground.main,
    padding: theme.spacing(1.5),
    borderRadius: 10,
    border: `1px solid ${theme.palette.borders.main}`,
  },
  popper: {
    // Important styling to overwite inline style from mapboxgl.Popup
    position: "unset!important" as "unset",
    transform: "unset!important",
    "& .MuiTooltip-tooltip": {
      padding: 0,
      margin: 0,
      backgroundColor: "unset",
    },
  },
  mapboxPopup: {
    "& .mapboxgl-popup-content": {
      ...tooltipStyling(theme),
      padding: 0,
    },
  },
}));

export default function GeoMapChart(props: GeoMapChartProps): JSX.Element | null {
  const { data, geoType, setGeoType, radioState, setRadioState } = props;
  const mapContainer = useRef(null);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const mapLayerName = "countriesLayer";
  const mapLayerCountryBorders = "country-borders";
  const mapSourceName = "country-boundaries";
  const mapCountryBoundaries = "country_boundaries";
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedGeo, setSelectedGeo] = useState<Entity>();
  const [mapState, setMap] = useState<mapboxgl.Map | null>(null);
  const { news, academic, jurisdictions } = data;
  const [hiddenCountries, setHiddenCountries] = useState<Record<string, boolean>>({});
  const mappedCountries = {
    [DocumentType.News]: news,
    [DocumentType.Patents]: jurisdictions,
    [DocumentType.Academic]: academic,
  };
  const switchGeographies = (newsRadioState: DocumentType.News) => {
    const { mentionedGeographies, publishedGeographies } = mappedCountries[newsRadioState];
    return geoType === "mentioned" ? mentionedGeographies : publishedGeographies;
  };
  const countriesData: GeoItem[] =
    radioState === DocumentType.News ? switchGeographies(radioState) : mappedCountries[radioState];
  const newsExists = news.mentionedGeographies.length > 0 || news.publishedGeographies.length > 0;

  const handleMapStyling = useCallback(
    (map: mapboxgl.Map) => {
      const filteredFromDupesCountries = countriesData.filter(
        (v, i, a) => a.findIndex((t) => t.countryName === v.countryName) === i
      );

      const countryList = filteredFromDupesCountries
        .filter((x) => {
          const hiddenCountiesNames = Object.keys(hiddenCountries);
          if (!!hiddenCountiesNames.includes(x.countryName)) {
            return !hiddenCountries[x.countryName];
          } else if (!!hiddenCountiesNames.includes(x.id)) {
            return !hiddenCountries[x.id];
          } else {
            return true;
          }
        })
        .map((x) => x.countryName);

      if (!!map.getStyle()?.layers) {
        const countriesLayer = map.getStyle().layers?.find((x) => x.id === mapLayerName);
        if (countriesLayer) {
          map.removeLayer(mapLayerName);
        }
        const countriesBordersLayer = map.getStyle().layers?.find((x) => x.id === mapLayerCountryBorders);
        if (countriesBordersLayer) {
          map.removeLayer(mapLayerCountryBorders);
        }
      }

      if (filteredFromDupesCountries.length > 0) {
        map.addLayer(
          {
            id: mapLayerName,
            source: mapSourceName,
            "source-layer": mapCountryBoundaries,
            type: "fill",
            paint: {
              "fill-opacity": {
                default: 0,
                property: "name_en",
                type: "categorical",
                stops: filteredFromDupesCountries.map((x) => [x.countryName, 1]),
              },
              "fill-color": {
                property: "name_en",
                type: "categorical",
                stops: filteredFromDupesCountries.map((x) => [x.countryName, x.hex]),
              },
            },
          },
          "country-label"
        );

        map.addLayer({
          id: mapLayerCountryBorders,
          type: "line",
          source: mapSourceName,
          "source-layer": mapCountryBoundaries,
          layout: {},
          paint: {
            "line-color": "#021856",
            "line-width": 0.5,
            // feature-state determines the line opacity
            "line-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0],
          },
        });

        const disputedFilter = ["==", ["get", "disputed"], "false"];
        const worldviewFilter = ["in", ["get", "worldview"], ["literal", ["all", "US"]]];
        const globalFilter = ["any", worldviewFilter, disputedFilter];

        if (!countryList.includes("WO") && map.getLayer(mapLayerName)) {
          map.setFilter(mapLayerName, [
            "any",
            ["all", ["in", ["get", "name_en"], ["literal", countryList]], globalFilter],
            ["all", ["in", ["get", "iso_3166_1"], ["literal", countryList]], globalFilter],
            ["all", ["in", ["get", "iso_3166_1_alpha_3"], ["literal", countryList]], globalFilter],
            countryList.includes("EP") && ["all", ["in", ["get", "region"], "Europe"], globalFilter],
          ]);
        }

        let hoveredStateId: string | number | undefined = undefined;

        map.on("mouseover", mapLayerName, function (e) {
          map.getCanvas().style.cursor = "pointer";
          if (e.features && e.features?.length > 0) {
            if (!!hoveredStateId) {
              map.setFeatureState(
                { id: hoveredStateId, source: mapSourceName, sourceLayer: mapCountryBoundaries },
                { hover: false }
              );
            }
            // Id is needed here. If no idea found then the hover won't happen as the Id won't correspond to a country
            hoveredStateId = e.features[0].id ?? "noIdFound";
            map.setFeatureState(
              { source: mapSourceName, sourceLayer: mapCountryBoundaries, id: hoveredStateId },
              { hover: true }
            );
          }
        });

        map.on("mouseleave", mapLayerName, function () {
          map.getCanvas().style.cursor = "";
          if (!!hoveredStateId) {
            map.setFeatureState(
              { source: mapSourceName, sourceLayer: mapCountryBoundaries, id: hoveredStateId },
              { hover: false }
            );
          }
          hoveredStateId = undefined;
        });

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          maxWidth: "100%",
        });

        map.on("click", function (e) {
          popup.remove();
          const coordinates = e.lngLat;
          const features = map.queryRenderedFeatures(e.point);
          const selectedCountryName = features.find((f) => f.properties?.name_en)?.properties?.name_en;
          const countryClicked = countriesData.find((x) => x.countryName === selectedCountryName);

          if (!!popupRef.current && countryClicked) {
            popup.setLngLat(coordinates).setDOMContent(popupRef.current).addTo(map).addClassName(classes.mapboxPopup);
          } else {
            setSelectedGeo(undefined);
          }
        });

        map.on("ClearPopup", () => {
          if (popup.isOpen()) {
            popup.remove();
          }
        });
      }
      setMap(map);
    },
    [countriesData, hiddenCountries, classes.mapboxPopup]
  );

  const handleLoadMap = useCallback((map: mapboxgl.Map) => {
    map.addSource(mapSourceName, {
      type: "vector",
      url: "mapbox://mapbox.country-boundaries-v1",
    });
    map.resize();
    setMap(map);
  }, []);

  const handleCountryClick = useCallback(
    (
      e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      } & mapboxgl.EventData
    ) => {
      if (e.features) {
        const properties = e.features[0].properties;
        const countryName = properties?.name_en;
        const countryClicked = countriesData.find((x) => x.countryName === countryName);
        if (countryClicked) {
          setSelectedGeo({
            name: countryName,
            id: countryClicked?.id ?? countryName,
            type: EntityType.Country,
          });
        }
      }
    },
    [countriesData]
  );

  const mapResizing = (map: mapboxgl.Map) => {
    map.getCanvas().style.width = "100%";
    setMap(map);
  };

  useEffect(() => {
    setMap(null);
    const newMap = new mapboxgl.Map({
      container: mapContainer.current ?? "",
      style: "mapbox://styles/amplyfi-mapbox-123/ckmopczvm1s3q17p5z5giouuc",
      center: [30.15620315489178, 30.001990428785813],
      zoom: 1.5,
      attributionControl: false,
      minZoom: 1.5,
    });
    const listener = () => mapResizing(newMap);

    newMap.on("load", () => handleLoadMap(newMap));
    window.addEventListener("storage", listener);

    return () => {
      newMap.remove();
      window.removeEventListener("storage", listener);
    };
  }, [handleLoadMap, radioState]);

  useEffect(() => {
    mapState?.fire("ClearPopup");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoType]);

  useEffect(() => {
    if (mapState !== null) {
      handleMapStyling(mapState);
    }
  }, [mapState, handleMapStyling]);

  useEffect(() => {
    if (mapState !== null) {
      mapState.on("click", mapLayerName, handleCountryClick);
    }
    return () => {
      if (mapState !== null) mapState.off("click", mapLayerName, handleCountryClick);
    };
  }, [mapState, handleCountryClick]);

  useEffect(() => {
    setHiddenCountries({});
  }, [countriesData]);

  if (!news.mentionedGeographies) {
    return null;
  }
  const getMapGraphBox = () => (
    <div className={classes.cardTitle}>
      <div style={{ display: "flex" }}>
        {newsExists && (
          <FormControlLabel
            value={DocumentType.News}
            control={
              <AnalyseRadio
                data-testid="news-radio"
                checked={radioState === DocumentType.News}
                onChange={(e, checked) => {
                  if (checked) {
                    setRadioState(e.target.value as DocumentType);
                  }
                }}
              />
            }
            label={<Typography variant="body2">News</Typography>}
          />
        )}
        {jurisdictions.length > 0 && (
          <FormControlLabel
            value={DocumentType.Patents}
            control={
              <AnalyseRadio
                data-testid="patent-radio"
                checked={radioState === DocumentType.Patents}
                onChange={(e, checked) => {
                  if (checked) {
                    setRadioState(e.target.value as DocumentType);
                  }
                }}
              />
            }
            label={<Typography variant="body2">Patents</Typography>}
          />
        )}
        {academic.length > 0 && (
          <FormControlLabel
            value={DocumentType.Academic}
            control={
              <AnalyseRadio
                data-testid="academic-radio"
                checked={radioState === DocumentType.Academic}
                onChange={(e, checked) => {
                  if (checked) {
                    setRadioState(e.target.value as DocumentType);
                  }
                }}
              />
            }
            label={<Typography variant="body2">Academic</Typography>}
          />
        )}
      </div>
      <div className={classes.infoContainer}>
        <ColourScale />
        {radioState === DocumentType.News && (
          <AnalyseSwitch
            unselectedLabel="Mentioned"
            selectedLabel="Published"
            selected={geoType === "published"}
            toggleSelected={() => {
              setSelectedGeo(undefined);
              if (geoType === "mentioned") {
                setGeoType("published");
              } else {
                setGeoType("mentioned");
              }
            }}
          />
        )}
        {radioState === DocumentType.Patents && (
          <Typography variant="h4" className={classes.toggleText}>
            Jurisdictions
          </Typography>
        )}
        {radioState === DocumentType.Academic && (
          <Typography variant="h4" className={classes.toggleText}>
            Published Locations
          </Typography>
        )}
      </div>
    </div>
  );

  const entityTooltipProps: Partial<React.ComponentProps<typeof EntityTooltip>> = {
    tooltipType: radioState === DocumentType.News && geoType === "mentioned" ? undefined : TooltipType.Geo,
    documentType: radioState,
    filterType: getFilterType(),
  };

  const selectedGeoType: EntityType | undefined =
    radioState === DocumentType.News && geoType === "mentioned" ? EntityType.Country : undefined;

  const getTooltipWithChildren = (el: JSX.Element) => {
    return (
      <EntityTooltip
        style={{ backgroundColor: "red" }}
        classes={{ popper: classes.popper }}
        PopperProps={{
          disablePortal: true,
          modifiers: {
            inner: {
              enable: true,
            },
          },
        }}
        arrow={false}
        entity={
          selectedGeo
            ? {
                id: selectedGeo.id,
                name: selectedGeo.name,
                //Pass type Country only for Mentioned Locations News
                type: selectedGeoType,
              }
            : undefined
        }
        {...entityTooltipProps}
        onClose={() => null}
      >
        {el}
      </EntityTooltip>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => mapState?.fire("ClearPopup")}>
      <div>
        <div ref={popupRef} style={{ height: "100%" }}>
          {getTooltipWithChildren(<div></div>)}
        </div>
        <div ref={mapContainer} className={classes.mapboxContainer} style={{ width: "100%" }}></div>
        {getMapGraphBox()}
        <div className={classes.svgContainer} data-map="map-geo-container">
          <CountryList
            onShowCountry={(id) => {
              setHiddenCountries({ ...hiddenCountries, [id]: false });
            }}
            onHideCountry={(id) => {
              setHiddenCountries({ ...hiddenCountries, [id]: true });
            }}
            hiddenCountries={hiddenCountries}
            countries={countriesData}
            entityTooltipProps={{ ...entityTooltipProps }}
            selectedGeoType={selectedGeoType}
            onClose={() => mapState?.fire("ClearPopup")}
          />
        </div>
      </div>
    </ClickAwayListener>
  );

  function getFilterType() {
    if (radioState === DocumentType.News) {
      return geoType === "mentioned" ? FilterType.Locations : FilterType.PublishLocations;
    } else if (radioState === DocumentType.Academic) {
      return FilterType.AcademicPublishLocations;
    } else if (radioState === DocumentType.Patents) {
      return FilterType.PatentJurisdictions;
    }
  }
}
