import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import AiImage from "../../components/Image";
import Stack from "../../components/stack/Stack";
import { Close } from "@material-ui/icons";

interface ListItemChipProps {
    label: string;
    image?: string;
    handleDelete: () => void;
}

const useStyles = makeStyles(() => ({
    image: {
        width: 30,
        height: 30,
        objectFit: "contain",
    }
}));

export default function ListItemChip(props: ListItemChipProps) {
    const classes = useStyles();
    return <Box padding={1} display="inline-flex" alignItems="center" bgcolor="#FFF" borderRadius={3}>
        <Stack spacing={1} direction="horizontal">
            {props.image && <AiImage displayErrorAlt className={classes.image} src={props.image} alt={props.label} />}
            <Typography style={{ fontWeight: 600 }} variant="body1">{props.label}</Typography>
            <IconButton size="small" style={{ padding: 8 }} color="primary">
                <Close onClick={props.handleDelete} style={{ fontSize: 12 }} />
            </IconButton>
        </Stack>
    </Box>
}