import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Box, darken, makeStyles, Typography } from "@material-ui/core";

interface DynamicComponentProps {
  children?: React.ReactNode;
  emptyState?: JSX.Element | string;
  emptyStateIcon?: JSX.Element;
  isLoading?: boolean;
  center?: boolean;
}

interface StyleProps {
  backgroundColor?: string;
  center?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {},
  content: {},
  emptyState: (props: StyleProps) => ({
    textAlign: "center",
    padding: theme.spacing(2),
    background: props.backgroundColor ? props.backgroundColor : darken(theme.palette.background.default, 0.08),
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    display: props.center ? "flex" : undefined,
    justifyContent: props.center ? "center" : undefined,
    alignItems: props.center ? "center" : undefined,
    width: "100%",
    flex: "1 1 auto",
  }),
  loadingContainer: (props: StyleProps) => ({
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flex: "1 1 auto",
  }),
}));

interface EmptyStateProps {
  emptyState: JSX.Element | string;
  emptyStateIcon?: JSX.Element;
}

function EmptyState(props: EmptyStateProps): JSX.Element {
  const { emptyState, emptyStateIcon } = props;
  return typeof emptyState === "string" ? (
    <BasicEmptyState title={emptyState} body="" icon={emptyStateIcon} />
  ) : (
    emptyState
  );
}

export function BasicEmptyState(props: {
  title: string;
  body?: string | string[];
  icon?: JSX.Element;
  actionButton?: JSX.Element;
  backgroundColor?: string;
}) {
  const { title, icon, body, actionButton } = props;
  const classes = useStyles({ ...props, center: true });

  return (
    <Box className={classes.emptyState}>
      <div style={{ width: "100%" }}>
        {icon}
        <Typography variant="h4">{title}</Typography>
        {!!body && (
          <>
            {(typeof body === "string" ? [body] : body).map((b) => (
              <Typography key={b} variant="body2">
                {b}
              </Typography>
            ))}
          </>
        )}
        {actionButton && <Box marginTop={2}>{actionButton}</Box>}
      </div>
    </Box>
  );
}

export default function DynamicComponent(props: DynamicComponentProps): JSX.Element {
  const { children, emptyState, emptyStateIcon, isLoading, center } = props;

  const classes = useStyles({ center });
  return isLoading ? (
    <Box className={classes.loadingContainer}>
      <AiCircularProgress />
    </Box>
  ) : (
    <>
      {emptyState && <EmptyState emptyStateIcon={emptyStateIcon} emptyState={emptyState} />}
      {!emptyState && children}
    </>
  );
}
