import React, { useEffect, useState } from "react";
import AiButton from "@amplyfi/ui-components/components/Button";
import {
  Box,
  Card,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  MobileStepper,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Close } from "@material-ui/icons";

interface WizardProps {
  title: string;
  children: JSX.Element[];
  steps: string[];
  hideNextLabel?: boolean;
  onPageChanged?: (page: number) => void;
  onClose: () => void;
  onFinish: () => void;
  nextDisabled?: boolean;
  initialPage?: number;
  size?: "default" | "large";
  tagLine?: string;
  onPrevious?: () => void;
  onNext?: () => void;
}

export interface BaseWizardState {
  initialPage: number;
  nextDisabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        "& .MuiDialog-paper": {
          margin: 0,
          width: "100%",
          maxWidth: "100%",
          maxHeight: "100%",
          height: "100%",
          padding: 0,
        },
      },
    },
    dialogTitle: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    container: (props: WizardProps) => ({
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        flex: "1 1 auto",
      },
    }),
    stepperContainer: {
      flex: "0 0 200px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    content: {
      display: "flex",
      marginTop: theme.spacing(1),
      overflowY: "auto",
      minWidth: 800,
      maxWidth: 1200,
      paddingBottom: theme.spacing(2),

      "& > *": {
        marginTop: theme.spacing(2),
      },

      [theme.breakpoints.down("xl")]: {
        width: 900,
        height: 550,
      },

      [theme.breakpoints.down("sm")]: {
        flex: "1 1 auto",
        height: "100%",
        maxWidth: "100%",
        minWidth: "100%",
      },
    },
    wizardPage: {
      display: "flex",
      flex: "1 1 auto",
    },
    hidden: {
      display: "none",
    },
    mobileStepper: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    footer: {
      display: "flex",
      padding: theme.spacing(2),
      justifyContent: "space-between",
      width: "100%",
      flex: "1 1 auto",
      [theme.breakpoints.down("sm")]: {
        "& > *": {
          width: "50%",
        },
        "& > *:first-child": {
          marginRight: theme.spacing(1),
        },
      },
    },
  })
);

export default function Wizard(props: WizardProps): JSX.Element {
  const {
    title,
    steps,
    hideNextLabel,
    children,
    onFinish,
    onClose,
    nextDisabled = false,
    onPageChanged,
    initialPage = 0,
    tagLine,
    onPrevious,
    onNext,
  } = props;
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const [finishing, setFinishing] = useState<boolean>(false);
  const styles = useStyles(props);

  async function finish() {
    setFinishing(true);
    onNext?.();
    await onFinish();
    setFinishing(false);
  }

  useEffect(() => {
    if (initialPage) {
      setCurrentPage(initialPage);
    }
  }, [initialPage]);

  useEffect(() => {
    if (onPageChanged) {
      onPageChanged(currentPage);
    }
  }, [currentPage, onPageChanged]);

  return (
    <Dialog className={styles.dialog} disableBackdropClick maxWidth={false} open onClose={onClose}>
      <Typography className={styles.dialogTitle} variant="h3">
        {title}
      </Typography>
      <Card className={styles.container}>
        <div className={styles.content}>
          <aside className={styles.stepperContainer}>
            <Stepper orientation="vertical" activeStep={currentPage}>
              {steps
                .filter((s, i) => (hideNextLabel ? currentPage >= i : true))
                .map((s, i) => (
                  <Step key={s}>
                    <StepLabel>{s}</StepLabel>
                  </Step>
                ))}
            </Stepper>
          </aside>
          {children
            .filter((item, i) => i === currentPage)
            .map((child, i) => (
              <div key={i} className={clsx(styles.wizardPage)}>
                {child}
              </div>
            ))}
        </div>
        <Box className={styles.mobileStepper} display="flex" alignItems="center" justifyContent="center">
          <MobileStepper
            activeStep={currentPage}
            variant="dots"
            steps={steps.length}
            backButton={null}
            nextButton={null}
            position="static"
          />
        </Box>
        <footer className={styles.footer}>
          <AiButton
            disabled={currentPage === 0}
            onClick={() => {
              onPrevious?.();
              setCurrentPage(currentPage - 1);
            }}
          >
            Back
          </AiButton>
          {tagLine && (
            <Typography style={{ marginLeft: "auto", marginRight: 16, alignSelf: "center" }} variant="h4">
              {tagLine}
            </Typography>
          )}
          <AiButton
            disabled={nextDisabled || finishing}
            onClick={() => {
              if (currentPage === steps.length - 1) {
                finish();
              } else {
                onNext?.();
                setCurrentPage(currentPage + 1);
              }
            }}
          >
            {currentPage === steps.length - 1 ? "Done" : "Next"}
          </AiButton>
        </footer>
      </Card>
      <IconButton style={{ position: "absolute", top: 8, right: 8 }} onClick={onClose}>
        <Close />
      </IconButton>
    </Dialog>
  );
}
