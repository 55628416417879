import { useAuth0 } from "../../../../components/Auth0/AuthWrapper";
import useAPI from "../../../queries/api/useAPI";
import { Comment, CommentsResponse, ResourceType, commentKey } from "../../../queries/comments/useComments";
import useCreate from "../../helpers/useCreate";
import useUpdateCommentCount from "./useUpdateCommentCount";

export default function useAddComment(resourceType: ResourceType, resourceId: string) {
    const api = useAPI("v5", `${resourceType}/${resourceId}/${commentKey}`, { useOrganisation: false, useLibrary: true });
    const { user } = useAuth0();
    const { mutateAsync } = useUpdateCommentCount(resourceId);
    return useCreate<CommentsResponse, Comment>({
        url: api,
        key: commentKey,
        onError: {
            message: "There was an error adding your comment. Please try again."
        },
        initialData: (item) => {
            return {
                ...(item as Comment),
                commentId: "temporary-id",
                userId: user.sub as string,
                dateCreated: new Date().toISOString(),
            }
        },
        onDataChange: (old, newData) => {
            const oldData = old || { comments: [] };
            const currentComment = oldData.comments.find((c) => c.commentId === newData.commentId || c.commentId === "temporary-id");
            if (currentComment) {
                return {
                    ...oldData,
                    // merge the new data with the old data
                    comments: oldData.comments.map((oldComment) =>
                        oldComment.commentId === newData.commentId || oldComment.commentId === "temporary-id" ?
                            { ...oldComment, ...newData }
                            :
                            oldComment
                    )
                }
            } else {
                mutateAsync(1);
                return {
                    ...oldData,
                    comments: [...oldData.comments, newData]
                }
            }
        },
    })
};