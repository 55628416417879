import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import TextField from "@amplyfi/ui-components/components/TextField";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { Chip, Theme, Typography, makeStyles } from "@material-ui/core";
import { LocalOfferOutlined as TagIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { MouseEventHandler, useEffect, useState } from "react";
import { FeedDocument } from "../../../../models/monitoring";
import SimpleDialog from "../../../Widgets/Dialog/SimpleDialog";
import DynamicComponent from "../Configuration/Components/DynamicComponent";
import { useRefreshListings, useSetUserTag, useUserTags } from "../../../../hooks/mutations/feed/useUserTags";
import { zIndex } from "../../../../helpers/componentsZIndex";
import useFeedDoc from "../../../../hooks/useFeedDocument";
import { useFeedViewStore } from "../../../../store/hooks/useFeedViewStore";
import { distinct } from "../../../../helpers/arrayHelpers";
import useDialogStore from "../../../../store/hooks/useDialogStore";

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  autocomplete: {
    zIndex: zIndex.modalAndMenu,
    "& .MuiInputBase-root": {
      height: "fit-content",
      minHeight: spacing(5),
    },
  },
  autocompleteInvalid: {
    "& .MuiInputBase-root": {
      border: `1px solid ${palette.error.main}`,
    },
  },
  icon: {
    marginRight: spacing(1),
  },
  input: {
    borderRadius: spacing(0.75),
    minHeight: spacing(5),
    "& .MuiInputBase-root": { paddingTop: 0 },
  },
  list: {
    display: "flex",
    listStyle: "none",
    maxHeight: 200,
    overflow: "auto",
    padding: 0,
    flexWrap: "wrap",
  },
  section: {
    marginBottom: spacing(4),
  },
  title: {
    alignItems: "center",
    display: "flex",
  },
}));

interface UserTagsProps {
  document: FeedDocument;
  onClose?: MouseEventHandler;
}

interface UserTag {
  label: string;
  value: string;
}

const newTagText = (value: string): string => `${value} (New tag)`;
const consumerFeedDocumentUserTags = (userTags: string[] = []): string[] => distinct(userTags) || [];

export function UserTagsContent({ document: { libraryId, documentId }, onClose }: UserTagsProps): JSX.Element {
  const {
    data: feedDocument,
    isLoading: isLoadingFeedDocument,
    refetch: refetchFeedDocument,
  } = useFeedDoc(libraryId, documentId);
  const classes = useStyles();
  const { amplyfiEntityId } = useFeedViewStore();
  const defaultTags = consumerFeedDocumentUserTags(feedDocument?.userTags);
  const [shadowTags, setShadowTags] = useState(defaultTags);
  const [shadowValue, setShadowValue] = useState("");
  const { data = [], isLoading: isLoadingAllTags } = useUserTags();
  const refreshListing = useRefreshListings(documentId);
  const allTags = data.map(({ name }) => name);
  const {
    add: { mutate: addTag, isLoading: isAddingTags },
    remove: { mutate: removeTag, isLoading: isRemovingTags },
  } = useSetUserTag(libraryId, documentId, amplyfiEntityId);

  const handleAddTags = (tags: string[]) => {
    if (!!tags.length) {
      addTag(tags, {
        onError: () => refetchFeedDocument(),
        onSuccess: (_, _tags) => setShadowTags([...shadowTags, ..._tags]),
      });
    }
  };

  useEffect(
    () => refreshListing,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      setShadowTags(consumerFeedDocumentUserTags(feedDocument?.userTags));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feedDocument]
  );

  const handleRemoveTags = (tags: string[]) => {
    if (!!tags.length) {
      removeTag(tags, {
        onError: () => refetchFeedDocument(),
        onSuccess: (_, _tags) => setShadowTags(shadowTags.filter((t) => !_tags.includes(t))),
      });
    }
  };

  // requires tags to be alphanumberic, dash and space
  const isValidTagName = (tagName: string): boolean => /^[a-zA-Z0-9- ]+$/.test(tagName);
  return <DynamicComponent>
    <section
      className={classes.section}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
      }}
    >
      {isLoadingFeedDocument || isLoadingAllTags ? (
        <AiCircularProgress size={40} />
      ) : (
        <>
          <p>Tag names can include letters, numbers, dashes or spaces.</p>
          <Autocomplete<UserTag, true, true>
            multiple
            disablePortal
            disableClearable
            disabled={isAddingTags || isRemovingTags}
            getOptionSelected={(opt, v) => opt.value === v.value}
            onInputChange={(_, newInputValue, reason) => {
              setShadowValue(reason === "reset" ? "" : newInputValue);
            }}
            onChange={(_, newSelection) => {
              handleAddTags(
                newSelection.filter((opt) => !shadowTags.includes(opt.value)).map((opt) => opt.value)
              );
              handleRemoveTags(shadowTags.filter((opt) => !newSelection.some((s) => s.value === opt)));
            }}
            defaultValue={defaultTags.map((t) => ({ label: t, value: t }))}
            getOptionLabel={(item) => item.label}
            loading={isLoadingFeedDocument || isLoadingAllTags}
            className={clsx(
              classes.autocomplete,
              !!shadowValue && !isValidTagName(shadowValue) && classes.autocompleteInvalid
            )}
            id="user-tags-autocomplete"
            noOptionsText="No matches found"
            filterOptions={(x) =>
              x
                .filter(({ value }) => !shadowTags.includes(value))
                .filter((ts) => !shadowValue || ts.value.toLowerCase().includes(shadowValue.toLowerCase()))
            }
            options={[
              ...(!!shadowValue &&
                isValidTagName(shadowValue) &&
                !allTags.some((t) => t.toLowerCase() === shadowValue.toLowerCase())
                ? [{ value: shadowValue, label: newTagText(shadowValue) }]
                : []),
              ...allTags.sort().map((opt) => ({ value: opt, label: opt })),
            ]}
            renderInput={({ inputProps, ...params }) => (
              <TextField
                autoFocus
                placeholder="Select tag"
                {...params}
                inputProps={{ ...inputProps, maxLength: 20 }}
                className={classes.input}
                onClear={() => undefined}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={option.value} color="primary" label={option.value} {...getTagProps({ index })} />
              ))
            }
          />
        </>
      )}
    </section>
  </DynamicComponent>
}

export function UserTags(props: UserTagsProps): JSX.Element {
  const classes = useStyles();
  const removeDialog = useDialogStore((state) => state.removeDialog);

  return (
    <SimpleDialog
      title={
        <Typography variant="h2" className={classes.title}>
          <TagIcon className={classes.icon} /> User Tags
        </Typography>
      }
      data-testid={testId("document", "user-tags")}
      onClose={(e) => {
        props.onClose?.(e)
        removeDialog();
      }}
      content={<UserTagsContent {...props} />}
      confirmIsAsync={false}
      primary={null}
      secondary={null}
    />
  );
}
