import AiButton from "@amplyfi/ui-components/components/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { renameAnalysis } from "../../../http/saved-analyses";
import { Analysis } from "../../../models/analysis";
import { updateSelectedAnalysis } from "../../../store/reducers/searchRequest/urlReducer";

interface SavedAnalysisRenameDialogProps {
  analysis: Analysis;
  onClose: () => void;
  onSave: () => void;
}

export default function SavedAnalysisRenameDialog(props: SavedAnalysisRenameDialogProps): JSX.Element {
  const { analysis, onClose, onSave } = props;
  const [analysisName, setAnalysisName] = useState<string>(analysis.name);
  const dispatch = useDispatch();

  async function confirmRename() {
    await renameAnalysis(analysis.id, analysisName);
    dispatch(
      updateSelectedAnalysis({
        ...analysis,
        name: analysisName,
      })
    );
    onSave();
  }

  return (
    <Dialog
      open
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h2">Edit Name</Typography>
      </DialogTitle>
      <DialogContent style={{ minWidth: 600 }}>
        <TextField
          fullWidth
          label="Analysis Name"
          value={analysisName}
          onChange={(e) => {
            setAnalysisName(e.target.value);
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          placeholder={"E.g. Autonomous vehicles query"}
          data-testid="rename_analysis_text_field"
        />
      </DialogContent>
      <DialogActions>
        <AiButton amplyfiType="secondary" onClick={() => onClose()}>
          Cancel
        </AiButton>
        <AiButton onClick={() => confirmRename()}>Save</AiButton>
      </DialogActions>
    </Dialog>
  );
}
