import { Checkbox } from "@material-ui/core";
import React from "react";
import { ReactComponent as CheckboxDash } from "../../assets/checkbox-dash.svg";
import { ReactComponent as CheckboxChecked } from "../../assets/checkbox-tick.svg";
import { ReactComponent as CheckboxUnchecked } from "../../assets/checkbox.svg";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import { BlueRibbon } from "@amplyfi/ui-components/theme/colors";

interface AmplyfiCheckboxProps {
  filled?: boolean;
  smallHeight?: boolean;
}

export default function AmplyfiCheckbox(props: AmplyfiCheckboxProps & React.ComponentProps<typeof Checkbox>): JSX.Element {
  const { filled = false, smallHeight = false, ...rest } = props;
  const iconHeight = smallHeight ? 15 : 20;

  return (
    <Checkbox
      {...rest}
      checkedIcon={
        filled ? (
          <CheckBoxRoundedIcon style={{ height: iconHeight, color: BlueRibbon[600] }} />
        ) : (
          <CheckboxChecked style={{ height: iconHeight }} />
        )
      }
      icon={<CheckboxUnchecked style={{ height: iconHeight, opacity: 0.5 }} />}
      indeterminateIcon={<CheckboxDash style={{ height: iconHeight }} />}
    />
  );
}
