import { Box, Card, CardContent, CardHeader, Divider, List, Portal, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useState } from "react";
import useAddComment from "../../../../../hooks/mutations/feed/comments/useAddComment";
import useDeleteComment from "../../../../../hooks/mutations/feed/comments/useDeleteComment";
import useComments, { Comment } from "../../../../../hooks/queries/comments/useComments";
import useOrgId from "../../../../../hooks/useOrgId";
import useUser from "../../../../../hooks/useUser";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import { useAuth0 } from "../../../../Auth0/AuthWrapper";
import DotSeparator from "../../Configuration/Components/DotSeparator";
import { BasicEmptyState } from "../../Configuration/Components/DynamicComponent";
import ActionHeader from "../../Configuration/Insights/ActionHeader";
import CustomTextArea from "../customTextArea/CustomTextArea";
import Stack from "../stack/Stack";
import moment from "moment";

const useStyles = makeStyles(({ spacing, palette }) => ({
    panel: {
        display: "grid",
        gridTemplateRows: "min-content 88px",
        gridTemplateColumns: "100%",
        backgroundColor: "white",
        flex: "1 1 auto",
    },
    textEntryContainer: {
        background: "white",
        width: "100%",
        padding: spacing(2),
    },
    textEntryField: {
        marginBottom: spacing(1),
        "& .MuiInputBase-input": {
            height: "88px !important",
            overflowY: "auto !important"
        },
        "& .MuiInputBase-root": {
            borderRadius: 4,
            paddingLeft: spacing(2),
            background: "white",
            overflowY: "auto",
            "& button:hover:enabled": {
                background: "none",
                color: "#004fd0",
                "& svg": {
                    color: "#004fd0",
                },
            },
            "& svg": {
                color: "#004fd0",
                fontSize: 16,
            },
        },
    },
    list: {
        minHeight: "65vh",
        contentVisibility: "auto"
    },
    userImage: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        objectFit: "contain",
    },
    userTag: {
        borderRadius: 8,
        background: palette.secondary.main,
        padding: spacing(0.3),
        fontSize: 11,
        fontWeight: "bold",
        color: "white"
    }
}));

interface TaggedUserProps {
    id: string
}

function TaggedUser(props: TaggedUserProps) {
    const { data } = useUser(useOrgId(), props.id);
    const classes = useStyles();
    return <span className={classes.userTag}>@{data?.name}</span>
}

function parseMessage(message: string): JSX.Element[] {
    // replace all the {{ }} with the user name
    const regex = /{{(.*?)}}/g;
    let m;
    let lastIndex = 0;
    const elements: JSX.Element[] = [];
    while ((m = regex.exec(message)) !== null) {
        const [fullMatch, userId] = m;
        const before = message.slice(lastIndex, m.index);
        elements.push(<span>{before}</span>);
        elements.push(<TaggedUser id={userId} />);
        lastIndex = m.index + fullMatch.length;
    }
    if (message) {
        elements.push(<span>{message.slice(lastIndex)}</span>);
    }
    return elements;
}

function UserComment(comment: Comment) {
    const orgId = useOrgId();
    const { data } = useUser(orgId, comment.userId);
    const { user } = useAuth0();
    const { selectedDocumentId } = useReaderStore();
    const { mutateAsync: deleteComment } = useDeleteComment("signal", selectedDocumentId as string);

    function handleDelete(commentId: string) {
        deleteComment(commentId);
    }

    return <Card style={{ opacity: comment.commentId === "temporary-id" ? 0.6 : 1, contentVisibility: "auto" }} elevation={0}>
        <CardHeader style={{ paddingBottom: 0 }} disableTypography title={
            <Stack direction="horizontal" spacing={0}>
                <Stack direction="horizontal" spacing={1}>
                    <Typography color="textSecondary" variant="body2">{data?.name || ""}</Typography>
                </Stack>
                <DotSeparator />
                <Tooltip title={moment.utc(comment.created).local().format("DD/MM/YYYY - HH:mm:ss")}>
                    <Typography color="textSecondary" variant="body2">
                        {moment.utc(comment.created).local().format("DD/MM/YYYY")}
                    </Typography>
                </Tooltip>
            </Stack>}
            action={
                comment.userId === user.sub ? <ActionHeader actions={[
                    {
                        icon: <Delete />,
                        title: "Delete",
                        forceMenuItem: true,
                        onClick() {
                            handleDelete(comment.commentId as string);
                        },
                    }
                ]} /> : null
            } />
        <CardContent style={{ paddingTop: 8, paddingBottom: 32 }}>
            <Typography style={{ fontSize: 16, whiteSpace: "break-spaces" }} variant="body2">{parseMessage(comment.comment)}</Typography>
        </CardContent>
        <Divider style={{ background: "rgba(0, 0, 0, 0.08)" }} />
    </Card>
}

export default function CommentsTab() {
    const { selectedDocumentId } = useReaderStore();
    const { data: comments = { comments: [] } } = useComments("signal", selectedDocumentId as string);
    const { mutateAsync: addComment } = useAddComment("signal", selectedDocumentId as string);
    const [newCommentText, setNewCommentText] = useState("");
    const { selectedTab } = useReaderStore();
    const classes = useStyles();

    if (selectedTab.id !== "comments") {
        return null;
    }

    function handleSend() {
        addComment({ comment: newCommentText });
        setNewCommentText("");
    }

    return <Stack className={classes.panel} spacing={1}>
        <List className={classes.list}>
            {
                comments?.comments.length === 0 && <BasicEmptyState title="There are no comments for this signal" />
            }
            {comments?.comments.map((comment) =>
                <UserComment key={comment.commentId} {...comment} />
            )}
        </List>
        <Portal container={() => document.getElementById("footer-container")}>
            <Box className={classes.textEntryContainer}>
                <CustomTextArea onSubmit={handleSend} value={newCommentText} onTextChange={setNewCommentText} />
            </Box>
        </Portal>
    </Stack>
}