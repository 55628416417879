import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { BabyBlue } from "@amplyfi/ui-components/theme/colors";
import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ErrorImageFallback } from "../../../../assets/error-image.svg";

export interface ImageProps {
  className?: string;
  src?: string;
  alt: string;
  ErrorImage?: string | JSX.Element;
  displayErrorAlt?: boolean;
  loadingDelay?: number;
}

const useStyles = makeStyles((theme) => ({
  image: {
    position: "fixed",
    left: -9999,
    opacity: 0,
  },
  imageLoaded: {
    position: "static",
    left: 0,
    transition: "opacity 0.4s",
    opacity: 1,
  },
  errorSvg: {
    width: 28,
    fill: "#606060",
  },
  errorAlt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: BabyBlue,
    width: 30,
    height: 30,
    padding: 8,
    borderRadius: "50%",
  },
}));

const hashString = (str: string): number =>
  str
    .split("")
    .map((char: string) => char.charCodeAt(0))
    .reduce((a, b) => a + b, 0);

const colors: string[] = [
  "#8dd3c7",
  "#ffffb3",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
];
const stringToColor = (str: string): string => colors[hashString(str) % colors.length];

const getInitials = (str: string) => {
  // eslint-disable-next-line no-useless-escape
  const words = str.split(/[\s|\-|\/]/g).filter((word) => word.match(/^[a-zA-Z0-9']+$/));
  const capitalizedWords = words.filter((word) => word[0] === word[0].toUpperCase());
  const firstTwoCapitalizedWords = capitalizedWords.slice(0, 2);
  const firstTwoWords = words.slice(0, 2);
  const firstTwoCharacters = firstTwoCapitalizedWords.length > 0 ? firstTwoCapitalizedWords : firstTwoWords;
  return firstTwoCharacters
    .map(([char]) => char)
    .join("")
    .toUpperCase();
};

function DisplayImage(props: ImageProps & { imageLoading: boolean; imageError: boolean }): JSX.Element {
  const classes = useStyles();
  const { className, src, alt, ErrorImage, displayErrorAlt, imageError, imageLoading } = props;
  if (displayErrorAlt && (imageError || !src)) {
    return (
      <span>
        <Tooltip title={alt}>
          <div
            style={{ background: stringToColor(alt) }}
            className={clsx(className, classes.image, { [classes.imageLoaded]: !imageLoading }, classes.errorAlt)}
          >
            <Typography variant="h4">{getInitials(alt)}</Typography>
          </div>
        </Tooltip>
      </span>
    );
  } else {
    return (
      <Tooltip title={alt}>
        <>
          {imageError && typeof ErrorImage === "string" && (
            <img
              className={clsx(className, classes.image, { [classes.imageLoaded]: !imageLoading })}
              src={ErrorImage}
              alt={alt}
            />
          )}
          {imageError && typeof ErrorImage !== "string" && ErrorImage !== undefined && ErrorImage}
          {!imageError && (
            <img
              className={clsx(className, classes.image, { [classes.imageLoaded]: !imageLoading })}
              src={src}
              alt={alt}
            />
          )}
        </>
      </Tooltip>
    );
  }
}

// This handles image failing to load and falling back to a placeholder image.
// It also handles the case where the image is loading and the placeholder is not shown.
export default function AiImage(props: ImageProps) {
  const { src, displayErrorAlt, ErrorImage, loadingDelay = 0 } = props;
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const classes = useStyles();
  const onLoadTimeout = useRef<number>();

  useEffect(() => {
    setImageLoading(true);
    setImageError(false);

    // This caches the image so that it doesn't have to be reloaded below.
    // The HTML event bindings are not consistent, so we need to force this with the JS element
    if (src) {
      onLoadTimeout.current = window.setTimeout(() => {
        const im = new Image();
        im.onerror = () => {
          setImageLoading(false);
          setImageError(true);
        };
        im.onload = () => setImageLoading(false);
        im.referrerPolicy = "no-referrer";
        im.src = src;
      }, loadingDelay);
    } else {
      setImageLoading(false);
      setImageError(true);
    }
    return () => {
      window.clearTimeout(onLoadTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <>
      {imageLoading && (
        <Box className={props.className}>
          <AiCircularProgress size={20} />
        </Box>
      )}
      {imageError && !ErrorImage && !displayErrorAlt ? (
        <ErrorImageFallback className={classes.errorSvg} />
      ) : (
        <DisplayImage {...props} imageError={imageError} imageLoading={imageLoading} />
      )}
    </>
  );
}
