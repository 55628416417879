import React from "react";
import { ReactComponent as OverviewIcon } from "../../assets/overview.svg";
import ComparisonReport from "../Pages/Analyse/ComparisonReport";
import OverviewReport from "../Pages/Analyse/Overview";

export interface AnalyseRoute {
  title: string;
  description: string;
  buttonText: string;
  page: string;
  infoText: string;
  component: JSX.Element;
  to: string;
  icon: JSX.Element;
  requiresEntities?: boolean;
  requiresOrganisations?: boolean;
  featureFlag?: string;
  dataTestId?: string;
}

export const reports: AnalyseRoute[] = [
  {
    title: "Overview Report",
    description: "An overview of the documents returned by your search",
    buttonText: "View analysis",
    infoText:
      "Explore the key phrases, connected entities, and published/mentioned locations of the documents returned by your query.",
    page: "TopicOverview",
    component: <OverviewReport />,
    to: "/analyse/overview",
    icon: <OverviewIcon />,
    dataTestId: "overview-report-tile",
  },
  {
    title: "Comparison Report",
    description: "A comparison of the documents returned by your search",
    buttonText: "View analysis",
    infoText: "Explore the differences between entities in your libraries.",
    page: "TopicOverview",
    component: <ComparisonReport />,
    to: "/analyse/comparison",
    icon: <OverviewIcon />,
    dataTestId: "comparion-tile",
    featureFlag: "analysisEasilyCompareChanges",
  },
];
