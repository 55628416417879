import { AnalyticsPlugin } from "analytics";
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import { LDInspectionFlagUsedHandler } from "launchdarkly-js-client-sdk";
import { IndentifyPayload, TrackPayload } from "../analytics.types";

interface Config extends RumInitConfiguration {
  enabled?: boolean;
}

export const name = "datadog-rum";

let DATADOG_RUM_READY = false;

export type CustomMethods = {
  featureFlagUsed: (...args: Parameters<LDInspectionFlagUsedHandler["method"]>) => void;
};

export default function plugin(config: Config): AnalyticsPlugin {
  return {
    config,
    name,
    initialize({ config }: { config: Config }) {
      datadogRum.onReady(() => {
        DATADOG_RUM_READY = true;
      });
      datadogRum.init(config);
      datadogRum.startSessionReplayRecording();
    },
    identify({
      payload: {
        traits: { organisationId, organisationName },
        userId,
      },
    }: {
      payload: IndentifyPayload;
    }) {
      datadogRum.setUser({id: userId});
      datadogRum.addRumGlobalContext("userId", userId);
      datadogRum.addRumGlobalContext("organisationId", organisationId);
      datadogRum.addRumGlobalContext("organisationName", organisationName);
    },
    loaded() {
      return !!DATADOG_RUM_READY;
    },
    methods: {
      featureFlagUsed: (...[flagKey, { value }]: Parameters<LDInspectionFlagUsedHandler["method"]>): void => {
        datadogRum.addFeatureFlagEvaluation(flagKey, value);
      },
    },
    track({ payload }: { payload: TrackPayload }) {
      const { properties: { action, category, label } } = payload;
      datadogRum.addAction(action, { 
        "category": category,
        "label": label 
      });
    },
  };
}
