import {
  all,
  AllEffect,
  call,
  CallEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
} from "redux-saga/effects";
import { logError } from "../../helpers/logger";
import { searchFilters } from "../../http/general";
import { FilterResponse } from "../../models/filter";
import { SearchRequestV2 } from "../../models/search";
import geographyFilters from "../../helpers/geographyFilters";
import { AppState } from "../reducers";
import { resetState } from "../reducers/searchRequest/actions";
import { onFilterLoading, onFiltersChange } from "../reducers/searchRequest/filterReducer";
import { fetchGeography, fetchGeographyMetricsGrid } from "./apis/geo";
import { fetchSectorSentences } from "./apis/sector";
import { fetchSentimentSentences } from "./apis/sentiment";
import { getParsedQueryParams } from "../reducers/searchRequest/url-functions";

export function* callAnalyseApi(
  searchRequest: SearchRequestV2,
  resetFilters: boolean
): Generator<SelectEffect | PutEffect | ForkEffect | AllEffect<CallEffect<unknown>>, void, boolean> {
  const path = window.location.pathname.toLowerCase();
  if (path === "/analyse") {
    return;
  }
  const hasFilters: boolean = yield select((state: AppState) => state.searchRequest.filter.initialFilters.length > 0);

  if (resetFilters || !hasFilters) {
    yield put(resetState());
    yield fork(fetchFilters, searchRequest);
  }

  if (
    path === "/analyse/organisation" ||
    path === "/analyse/sectors" ||
    path === "/analyse/media" ||
    path === "/analyse/overview"
  ) {
    yield all([call(fetchSentimentSentences, searchRequest)]);
  }
  if (path === "/analyse/geography" || path === "/analyse/overview") {
    yield all([
      call(fetchGeographyMetricsGrid, geographyFilters(searchRequest)),
      call(fetchGeography, geographyFilters(searchRequest, 250)),
    ]);
  } else if (path === "/analyse/topicoverview") {
    yield all([call(fetchGeography, geographyFilters(searchRequest, 250))]);
  } else if (path === "/analyse/sectors") {
    yield all([call(fetchSectorSentences, searchRequest)]);
  }
}

function* fetchFilters(request: SearchRequestV2): Generator<PutEffect | CallEffect | PutEffect, void, FilterResponse> {
  try {
    const filterRequest: SearchRequestV2 = {
      ...request,
      userFilters: { ...request.userFilters, news: undefined },
    };
    yield put(onFilterLoading());
    const { libraries } = getParsedQueryParams();
    const filters: FilterResponse = yield call(searchFilters, filterRequest, libraries);
    yield put(onFiltersChange(filters));
  } catch (err) {
    logError(err);
  }
}
