import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Box, List, makeStyles, MenuItem, Popover, Select, Typography } from "@material-ui/core";
import { Business, Person } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { zIndex } from "../../../../../helpers/componentsZIndex";
import { getAmplyfiEntityLogo } from "../../../../../helpers/imageHelpers";
import { useLibraryEntities } from "../../../../../hooks/useCollectionConfigs";
import useEntitySuggestions from "../../../../../hooks/useEntitySuggestions";
import { requestNewOrganisation, requestNewTechnology } from "../../../../../http/autocomplete-request";
import { LibraryEntity } from "../../../../../http/harvesterCollections";
import { AutocompleteType } from "../../../../../models/autocomplete";
import { setSnack } from "../../../../../store/reducers/ui/snackReducer";
import AddNewOrganisationDialog from "../../components/AddNewOrganisationDialog";
import AddNewTechnologyDialog from "../../components/AddNewTechnologyDialog";
import RequestNewEntity from "../../components/RequestNew";
import EditableListItem from "../../components/EditableListItem";
import { interestAdded, interestRemoved, requestNewSuccess } from "../../helpers/snack";
import ResponsiveContent from "./ResponsiveContent";
import TextEntryField from "./TextEntryField";
import { BasicEmptyState } from "./DynamicComponent";
import { AutocompleteResponse } from "../../../../../http/autocomplete";
import { analyticsEvent } from "../../../../../helpers/analytics";

interface InterestSearchProps {
  libraryId?: string;
  type?: AutocompleteType;
  onChange?: (selected: LibraryEntity[]) => void;
  onAdd?: (entity: LibraryEntity, type: AutocompleteType) => void;
  onRemove?: (entity: LibraryEntity) => void;
  defaultText?: string;
  autoFocus?: boolean;
  singleSelect?: boolean;
  defaultEntities?: LibraryEntity[];
  autoselect?: boolean;
  readOnly?: boolean;
}

const SELECT_WIDTH = 162;

const useStyles = makeStyles((theme) => ({
  input: (props: InterestSearchProps) => ({
    paddingLeft: props.type ? 0 : SELECT_WIDTH + 20,

    "& .MuiInputBase-root": {
      borderTopLeftRadius: props.type || 0,
      borderBottomLeftRadius: props.type || 0,
      borderLeft: props.type || 0,
    },
  }),
  iconContainer: {
    background: "white",
    left: 0,
    top: 4,
    bottom: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    marginTop: 3,
    border: "1px solid #dedede",
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  select: {
    border: 0,
    background: "white",
    borderRadius: 0,
    marginTop: 1,
    height: 18,
    width: SELECT_WIDTH - 20,
  },
}));

function getDescription(item: AutocompleteResponse | LibraryEntity): string | string[] {
  if (item.websites.length) {
    const desc = `${item.websites[0]}`;
    return item.description ? [desc, item.description] : desc;
  }

  if (item.industries.length) {
    return item.industries
      .map((industry) => `${industry.charAt(0).toUpperCase()}${industry.slice(1, industry.length)}`)
      .join(", ");
  }

  return item.description || "";
}

export default function InterestSearch(props: InterestSearchProps) {
  const {
    libraryId,
    type,
    defaultEntities,
    onChange,
    onAdd,
    onRemove,
    defaultText,
    autoFocus,
    singleSelect,
    autoselect,
    readOnly = false,
  } = props;
  const { data, refetch } = useLibraryEntities(libraryId);
  const [selectedType, setSelectedType] = useState<AutocompleteType>(type || AutocompleteType.Organisation);

  const [text, setText] = useState(defaultText || "");
  const [createNew, toggleCreateNew] = useState(false);
  const [selected, setSelected] = useState<LibraryEntity[]>(defaultEntities || []);
  const { data: suggestions, isLoading } = useEntitySuggestions(text, selectedType);
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const [autoselectSuccess, setAutoselectSuccess] = useState(false);

  useEffect(() => {
    if (libraryId && data) {
      setSelected(data);
    }
  }, [data, libraryId]);

  useEffect(() => {
    if (autoselect && suggestions && text === defaultText) {
      const newSelected = suggestions.find((s) => s.name.toLowerCase() === text.toLowerCase());
      if (newSelected) {
        setSelected([{ ...newSelected, amplyfiEntityId: newSelected.id }]);
        setAutoselectSuccess(true);
        setRef(null);
        onChange?.([{ ...newSelected, amplyfiEntityId: newSelected.id }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions, autoselect, text]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  function addInterest(interest: LibraryEntity) {
    const newSelected = singleSelect ? [interest] : [...selected, interest];
    setSelected(newSelected);
    analyticsEvent("Feed", "SetupAddInterest", interest.amplyfiEntityId);
    setText("");
    setRef(null);
    dispatch(setSnack(interestAdded(interest.name)));
    onChange?.(newSelected);
    onAdd?.(interest, selectedType);
  }

  function unAutoSelectInterest(interest: LibraryEntity) {
    const newSelected = selected.filter((s) => s.amplyfiEntityId !== interest.amplyfiEntityId);
    setSelected(newSelected);
    setAutoselectSuccess(false);
    onChange?.([]);
  }

  function removeInterest(interest: LibraryEntity) {
    const newSelected = selected.filter((s) => s.amplyfiEntityId !== interest.amplyfiEntityId);
    setSelected(newSelected);
    analyticsEvent("Feed", "SetupRemoveInterest", interest.amplyfiEntityId);
    dispatch(setSnack(interestRemoved(interest.name)));
    onChange?.(newSelected);
    onRemove?.(interest);
  }

  const handleSave = async (name: string, website: string, altNames: string[]) => {
    const result =
      selectedType === AutocompleteType.Organisation
        ? await requestNewOrganisation(name, website, altNames)
        : await requestNewTechnology(name, website, altNames);

    addInterest({ ...result, amplyfiEntityId: result.id } as LibraryEntity);
    toggleCreateNew(false);
    dispatch(setSnack(requestNewSuccess()));
  };

  const newDialogOptions = { onClose: () => toggleCreateNew(false), onSave: handleSave };

  const placeholderText = (type: AutocompleteType) => {
    if (type === AutocompleteType.Organisation) {
      return "Search for an organisation";
    } else if (type === AutocompleteType.Technology) {
      return "Search for a topic";
    } else {
      return "Search for an interest";
    }
  };

  return (
    <ResponsiveContent>
      <Box marginTop={2}>
        {!readOnly && (
          <Box position="relative" display="flex" alignItems="center">
            {(!autoselect || autoselectSuccess === false) && (
              <TextEntryField
                autoFocus={autoFocus}
                className={classes.input}
                label=""
                fullWidth
                value={text}
                onChange={({ target: { value } }) => setText(value)}
                onFocus={({ target }) => {
                  setRef(target as HTMLInputElement);
                }}
                placeholder={placeholderText(selectedType)}
              />
            )}
            {!type && (
              <Box className={classes.iconContainer}>
                {selectedType === AutocompleteType.Organisation ? <Business /> : <Person />}
                <Select
                  className={classes.select}
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value as AutocompleteType)}
                >
                  <MenuItem value={AutocompleteType.Organisation}>Organisation</MenuItem>
                  <MenuItem value={AutocompleteType.Technology}>Topic</MenuItem>
                </Select>
              </Box>
            )}
          </Box>
        )}
        {text.length > 0 && !!ref && (
          <Popover
            style={{ zIndex: zIndex.selectMenu }}
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
            anchorEl={ref}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={() => {
              setRef(null);
            }}
            open
          >
            {isLoading && (
              <Box minWidth={200} minHeight={48} padding={2}>
                <AiCircularProgress useContainer size={24} />
              </Box>
            )}
            {suggestions && suggestions.length === 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding={2}
                maxWidth={window.outerWidth - 32}
              >
                <Typography style={{ width: "100%", wordWrap: "break-word" }} variant="body2">
                  No matches found for <strong>{text}</strong>
                </Typography>
              </Box>
            )}
            {suggestions && (
              <List style={{ maxHeight: 220, overflow: "auto" }}>
                {suggestions
                  .filter((s) => !selected.find((sel) => sel.amplyfiEntityId === s.id))
                  .map((s) => (
                    <EditableListItem
                      key={s.id}
                      title={s.name}
                      description={getDescription(s)}
                      image={getAmplyfiEntityLogo(s.id)}
                      displayErrorAlt
                      onClick={() =>
                        addInterest({
                          amplyfiEntityId: s.id,
                          name: s.name,
                          description: s.description,
                          websites: s.websites,
                          industries: s.industries,
                        })
                      }
                      disabled={readOnly}
                    />
                  ))}
                <RequestNewEntity
                  requestNew={() => toggleCreateNew(true)}
                  text={`Add ${text ? `"${text}" as a` : "a"} new ${
                    selectedType === AutocompleteType.Organisation ? "organisation" : "topic"
                  }?`}
                />
              </List>
            )}
          </Popover>
        )}

        {selected.length === 0 && (
          <BasicEmptyState
            title={`No ${selectedType === AutocompleteType.Organisation ? "organisations" : "topics"} selected`}
            body={
              readOnly
                ? undefined
                : `Try adding ${selectedType === AutocompleteType.Organisation ? "an organisation" : "a topic"}`
            }
          />
        )}
        <List>
          {selected.map((ent) => (
            <EditableListItem
              disabled={readOnly}
              key={ent.amplyfiEntityId}
              title={ent.name}
              description={getDescription(ent)}
              image={getAmplyfiEntityLogo(ent.amplyfiEntityId)}
              displayErrorAlt
              onDelete={!autoselect || !autoselectSuccess ? () => removeInterest(ent) : () => unAutoSelectInterest(ent)}
            />
          ))}
        </List>
        {createNew &&
          (selectedType === AutocompleteType.Organisation ? (
            <AddNewOrganisationDialog {...newDialogOptions} />
          ) : (
            <AddNewTechnologyDialog {...newDialogOptions} />
          ))}
      </Box>
    </ResponsiveContent>
  );
}
