import { useState } from "react";
import { addConnector, Connector, ConnectorGroup, Prototype, updateConnector } from "../../../http/connectors";
import Wizard from "../../Widgets/Wizard/Wizard";
import ChooseConnectorPage from "./ConnectorWizard/ChooseConnectorPage";
import ConnectorConfigurationPage from "./ConnectorWizard/ConnectorConfigurationPage";
import SummaryPage from "./ConnectorWizard/SummaryPage";

interface ConnectorWizardProps {
  initialConnector?: Connector;
  initialGroup?: ConnectorGroup;
  onClose: () => void;
}

export default function ConnectorWizard(props: ConnectorWizardProps): JSX.Element {
  const { initialConnector, initialGroup, onClose } = props;
  const [selectedPrototype, setSelectedPrototype] = useState<Connector | Prototype | undefined>(
    initialConnector || undefined
  );
  const [nextDisabled, setNextDisabled] = useState(false);

  async function createConnector() {
    if (selectedPrototype)
      await addConnector({
        ...selectedPrototype,
        connectorGroupId: (selectedPrototype as Connector).connectorGroupId || initialGroup?.groupId || null,
        attributes: selectedPrototype.attributes.filter((attr) => attr.value && attr.value.length > 0),
      });
    onClose();
  }

  async function modifyConnector() {
    if (selectedPrototype)
      await updateConnector({
        ...(selectedPrototype as Connector),
        attributes: selectedPrototype.attributes.filter((attr) => attr.value && attr.value.length > 0),
      });
    onClose();
  }

  const pages: JSX.Element[] = [
    ...(!initialConnector
      ? [
          <ChooseConnectorPage
            key="connector-selection"
            onCanProgressChanged={(canProgress) => setNextDisabled(!canProgress)}
            onSelected={(proto) => setSelectedPrototype(proto)}
          />,
        ]
      : []),
    <ConnectorConfigurationPage
      key="connector-configuration"
      {...(selectedPrototype as Connector)}
      onUpdated={(proto) => setSelectedPrototype(proto)}
      onCanProgressChanged={(canProgress) => setNextDisabled(!canProgress)}
    />,
    <SummaryPage key="connector-summary" connector={selectedPrototype as Connector} />,
  ];

  return (
    <Wizard
      title="Add Source"
      nextDisabled={nextDisabled}
      onClose={() => onClose()}
      onFinish={() => (initialConnector ? modifyConnector() : createConnector())}
      steps={initialConnector === undefined ? ["Source", "Details", "Summary"] : ["Details", "Summary"]}
    >
      {pages}
    </Wizard>
  );
}
