import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Export } from "@amplyfi/ui-components/icons/Export";
import {
  Avatar,
  Button,
  Container,
  createStyles,
  FormControlLabel,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import { analyticsEvent } from "../../../helpers/analytics";
import { areConnectorsLoading } from "../../../helpers/connectors";
import { AiDate } from "../../../helpers/dateHelpers";
import { getWebsiteLogo } from "../../../helpers/imageHelpers";
import useCluster from "../../../hooks/useCluster";
import useConnectorResults from "../../../hooks/useConnectorResults";
import useConnectorStatus from "../../../hooks/useConnectorStatus";
import useDebounce from "../../../hooks/useDebounce";
import {
  ConnectorSortBy,
  ConnectorSortOrder,
  customLogoIds,
  extendSearch,
  getConnectors,
  searchConnectorsV2,
} from "../../../http/connectors";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import ConnectorGroupList from "../../Widgets/Connectors/ConnectorGroupList";
import BasicSearch from "../../Widgets/Search/BasicSearch";
import { Datum } from "../../Widgets/TopicWheel/sunburstHelpers";
import TopicWheel from "../../Widgets/TopicWheel/TopicWheel";
import { canUserReadAnalysis } from "../Analyse/permissions";
import { canUserCreateHarvest } from "../Harvest/permissions";
import ExportCompleteDialog from "./ExportToLibrary/ExportCompleteDialog";
import ExportToLibraryDialog from "./ExportToLibrary/ExportToLibraryDialog";
import LoadMoreDialog from "./LoadMoreDialog/LoadMoreDialog";

const MIN_RESULTS_FOR_WHEEL = 100;
const MAX_RESULTS_PER_CONNECTOR = 50;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    container: {
      paddingTop: theme.spacing(1),
      display: "flex",
      maxWidth: "100%",
      height: "calc(100% - 80px)",
    },
    description: {
      fontSize: 14,
      lineHeight: 1.58,
      "& em": {
        fontWeight: 600,
        fontStyle: "normal",
      },
    },
    header: {
      flex: "1 1 auto",
      height: 70,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      gridColumnStart: 1,
      gridColumnEnd: 5,
    },
    textField: {
      width: 600,
    },
    resultList: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      minWidth: 500,
      maxWidth: "100%",
      flex: "1 1 800px",
      marginTop: theme.spacing(2),
    },
    resultListContent: {
      flex: "1 1 auto",
      overflowY: "auto",
      overflowX: "hidden",
      width: "100%",
      padding: theme.spacing(2),
      borderLeft: "1px solid #E8E8E8",
      background: "#FFF",
      borderRadius: 8,
    },
    topicContainer: {
      marginLeft: theme.spacing(1),
      alignSelf: "flex-start",
      minWidth: 400,
      maxWidth: 600,
      flex: "1 1 600px",
      marginTop: theme.spacing(2),
    },
    resultListItem: {
      display: "flex",
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(1),
      alignItems: "center",

      "&:hover": {
        cursor: "pointer",
      },
    },
    resultListIcon: {
      height: 20,
      width: 20,
      objectFit: "contain",
      marginRight: theme.spacing(1),
    },
    resultListText: {
      marginTop: theme.spacing(1),
    },
    listActions: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      position: "sticky",
      top: 0,
      background: theme.palette.appBackground.main,
      width: "100%",
      zIndex: 1000,
    },
    alignLeft: {
      marginRight: "auto",
    },
    alignRight: {
      marginLeft: "auto",
    },
    sortButton: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(1),
    },
  })
);

function ResultListItem(props: {
  title?: string;
  icon?: string;
  date?: AiDate;
  description?: string;
  url?: string;
  displayUrl?: string;
  connectorName?: string;
  prototypeId: string;
  logoDomain?: string;
}) {
  const { title, date, description, url, displayUrl, prototypeId, logoDomain } = props;
  const styles = useStyles();
  const theme = useTheme();

  function onItemClicked(_url?: string) {
    if (!url) {
      return;
    }

    analyticsEvent("DeepKnowledge", "LinkClicked", "");
    window.open(url, "_blank");
  }

  const logo = customLogoIds.includes(prototypeId)
    ? getWebsiteLogo(logoDomain)
    : `${process.env.REACT_APP_PROTOTYPE_LOGO_URL}/prototype/${prototypeId}/logo.png`;
  return (
    <div className={styles.resultListItem} onClick={() => onItemClicked(url)}>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {prototypeId && <Avatar className={styles.resultListIcon} src={logo} alt="" />}
          <Typography style={{ display: "inline-block", fontWeight: "normal" }} variant="h6">
            {displayUrl || url}
          </Typography>
        </div>
        <Typography
          style={{ color: theme.palette.secondary.main, display: "inline-block", fontSize: 20 }}
          className={styles.resultListText}
          variant="h3"
        >
          {title ? (
            /* eslint-disable-next-line */
            title.replace(/\<\e\m\>/g, "").replace(/\<\/\e\m\>/g, "")
          ) : (
            <>
              <Skeleton width={200} height={20} />
              <Skeleton width={400} count={2} height={20} />
            </>
          )}
        </Typography>
        <div>
          <Typography
            variant="body2"
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description as string }}
          />
        </div>
        <Typography
          style={{
            marginTop: 8,
            fontSize: 14,
            textTransform: "none",
            color: "#bbbbbb",
            letterSpacing: 0,
            fontWeight: "normal",
          }}
          variant="h5"
        >
          {date?.isValid() ? date.format("DD MMM YYYY").toString() : ""}
        </Typography>
      </div>
    </div>
  );
}

function showLoadMoreDialog(): boolean {
  return localStorage.getItem("loadMoreDialogSaved") !== "true";
}

export default function Search(): JSX.Element {
  const { user } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const styles = useStyles();
  const urlParams = new URLSearchParams(location.search);
  const initialSearch = urlParams.has("query") ? urlParams.get("query")?.toString() : "";
  const [searchTerm, setSearchTerm] = useState<string>(initialSearch || "");
  const debouncedSearch = useDebounce<string>(searchTerm, 1);
  const [selectedConnectors, setSelectedConnectors] = useState<string[]>([]);
  const [connectorsInSelectedGroup, setConnectorsInSelectedGroup] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<ConnectorSortBy>(ConnectorSortBy.Relevancy);
  const [sortOrder, setOrder] = useState<ConnectorSortOrder>(ConnectorSortOrder.Descending);
  const [page, setPage] = useState(0);
  const [translateQuery, setQueryTranslation] = useState(true);
  const [crumbs, setCrumbs] = useState<Datum[]>([]);
  const [showExportDialog, toggleExportDialog] = useState(false);
  const [showExportComplete, toggleExportCompleteDialog] = useState(false);
  const [showLoadDialog, setShowLoadDialog] = useState(false);

  const { data: connectors } = useQuery("connectors", getConnectors);

  const { data: searchId, isLoading: searchLoading } = useQuery(
    ["searchConnectors", debouncedSearch, connectors, translateQuery, connectorsInSelectedGroup],
    () => {
      if (searchTerm.length && selectedConnectors.length) {
        return searchConnectorsV2(searchTerm, connectorsInSelectedGroup, translateQuery, MAX_RESULTS_PER_CONNECTOR);
      }
      return undefined;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );

  const {
    data: results,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    refetch: refetchResults,
  } = useConnectorResults(
    searchId?.searchId || "",
    selectedConnectors,
    crumbs.length > 0 ? crumbs[crumbs.length - 1].resultIds : [],
    false,
    sortBy,
    sortOrder,
    page,
    25
  );

  const { data: resultCount, refetch: refetchConnectorStatus } = useConnectorStatus(
    searchId?.searchId || "",
    connectors?.map((c) => c.connectorId) || []
  );

  const { refetch: refetchTopicWheel } = useCluster(
    searchId?.searchId || undefined,
    2,
    1,
    selectedConnectors,
    resultCount && areConnectorsLoading(resultCount)
      ? resultCount.itemsProcessed < MIN_RESULTS_FOR_WHEEL
      : isLoading || searchLoading
  );

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading || searchLoading || isFetching,
    hasNextPage: hasNextPage === undefined ? false : hasNextPage,
    onLoadMore: () => {
      if (!isLoading && !isFetching) {
        setPage(page + 1);
        fetchNextPage();
      }
    },
    disabled: !hasNextPage || isLoading || isFetching,
  });

  if (localStorage.getItem("knowledgeOnboarded") !== "true") {
    history.replace("/research/landing");
  }

  function onExportToLibrary() {
    toggleExportDialog(false);
    toggleExportCompleteDialog(true);
  }

  function onSubmit(text: string) {
    analyticsEvent("DeepKnowledge", "SearchSubmitted", "");
    history.push(`/research?query=${encodeURIComponent(text)}`);
    setSearchTerm(text);
  }

  const matches = useMediaQuery("(min-width: 1600px)");

  return (
    <div className={styles.root}>
      <Paper className={styles.header} elevation={0}>
        <BasicSearch
          defaultValue={initialSearch}
          className={styles.textField}
          onTextSubmit={onSubmit}
          placeholder="Search…"
          disabledButton={!selectedConnectors.length}
        />
        <FormControlLabel
          color="white"
          style={{ marginLeft: 16, color: "white" }}
          control={<Switch defaultChecked onChange={(e) => setQueryTranslation(e.target.checked)} />}
          label="Query translation"
        />
      </Paper>
      <Container className={styles.container} maxWidth="lg">
        <ConnectorGroupList
          searchId={searchId?.searchId || ""}
          onSelectedConnectorsChanged={(selectedConnectors, allConnectorsInGroup) => {
            setPage(0);
            setSelectedConnectors(selectedConnectors);
            setConnectorsInSelectedGroup(allConnectorsInGroup);
          }}
        />

        <div className={styles.resultList}>
          <div className={styles.listActions}>
            {!isLoading && searchTerm.length > 0 && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Select onChange={(e) => setSortBy(e.target.value as ConnectorSortBy)} defaultValue={sortBy}>
                    <MenuItem value="relevance">Relevance</MenuItem>
                    <MenuItem value="date">Date</MenuItem>
                  </Select>
                  {sortBy === ConnectorSortBy.Date && (
                    <div className={clsx(styles.sortButton, styles.alignLeft)}>
                      <Typography variant="body2">Sort Direction</Typography>
                      <IconButton
                        onClick={() =>
                          setOrder(
                            sortOrder === ConnectorSortOrder.Ascending
                              ? ConnectorSortOrder.Descending
                              : ConnectorSortOrder.Ascending
                          )
                        }
                      >
                        {sortOrder === ConnectorSortOrder.Ascending ? <ArrowUpward /> : <ArrowDownward />}
                      </IconButton>
                    </div>
                  )}
                </div>
                <div
                  className={styles.alignRight}
                >
                  {canUserCreateHarvest(user) && canUserReadAnalysis(user) && (
                    <Button
                      style={{ marginRight: 8 }}
                      disabled={resultCount ? resultCount.results.length === 0 : true}
                      onClick={() => toggleExportDialog(true)}
                      color="secondary"
                      endIcon={<Export />}
                    >
                      Analyse Documents
                    </Button>
                  )}
                  <Button
                    disabled={isLoading || searchLoading || areConnectorsLoading(resultCount) || searchTerm.length === 0}
                    onClick={async () => {
                      if (showLoadMoreDialog()) {
                        setShowLoadDialog(true);
                        return;
                      }
                      await extendSearch(
                        searchTerm as string,
                        searchId?.searchId as string,
                        selectedConnectors,
                        MAX_RESULTS_PER_CONNECTOR
                      );
                      await refetchConnectorStatus();
                      setTimeout(async () => {
                        setPage(0);
                        await refetchResults();
                        await refetchTopicWheel();
                      }, 2000);
                    }}
                    variant="outlined"
                    color="secondary"
                    style={{ padding: "8px 16px" }}
                  >
                    Load more docs
                  </Button>
                </div>
              </>
            )}
          </div>
          <div
            style={{ background: searchTerm.length === 0 ? "transparent" : "#FFF" }}
            className={styles.resultListContent}
          >
            {(isLoading || searchLoading) && searchTerm.length > 0
              ? new Array(6).fill(1).map((_, i) => <ResultListItem prototypeId="" key={i} />)
              : results?.results.map((r, index) => (
                <ResultListItem
                  key={r.url + index}
                  title={r.title}
                  description={r.summary}
                  icon={getWebsiteLogo(r.url)}
                  date={moment(r.date)}
                  url={r.url}
                  displayUrl={r.displayUrl}
                  connectorName={r.connectorName}
                  prototypeId={r.prototypeId}
                  logoDomain={r.url}
                />
              ))}
            {hasNextPage && (
              <div
                style={{
                  height: 100,
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                ref={infiniteRef}
              >
                <AiCircularProgress useContainer={false} size={32} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.topicContainer}>
          {searchId && (
            <TopicWheel
              width={matches ? 600 : 500}
              connectorIds={selectedConnectors}
              searchId={searchId.searchId}
              isLoading={
                resultCount && areConnectorsLoading(resultCount)
                  ? resultCount.itemsProcessed < MIN_RESULTS_FOR_WHEEL
                  : isLoading || searchLoading
              }
              searchTerm={searchTerm}
              crumbs={crumbs}
              popToCrumb={(index) => {
                setCrumbs(crumbs.filter((c, i) => i < index));
              }}
              onItemClicked={(item) => setCrumbs([...crumbs, item])}
            />
          )}
        </div>
        {showExportDialog && searchId && (
          <ExportToLibraryDialog
            documentCount={resultCount?.itemsProcessed || 0}
            selectedConnectors={selectedConnectors}
            onConfirm={onExportToLibrary}
            onClose={() => toggleExportDialog(false)}
            searchId={searchId.searchId}
          />
        )}
        {showExportComplete && <ExportCompleteDialog onClose={() => toggleExportCompleteDialog(false)} />}
        {showLoadDialog && (
          <LoadMoreDialog
            onCancel={() => {
              setShowLoadDialog(false);
            }}
            onConfirm={async () => {
              setShowLoadDialog(false);
              await extendSearch(
                searchTerm as string,
                searchId?.searchId as string,
                selectedConnectors,
                MAX_RESULTS_PER_CONNECTOR
              );
              await refetchConnectorStatus();
              setTimeout(async () => {
                setPage(0);
                await refetchResults();
                await refetchTopicWheel();
              }, 2000);
            }}
          />
        )}
      </Container>
    </div>
  );
}
