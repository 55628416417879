import {
  onKeyPhrasesSearchError,
  onKeyPhrasesSearchLoading,
  onKeyPhrasesSearchSuccess,
  resetKeyPhrasesSearch,
} from "../reducers/searchResponse/overviewReducer";
import { getKeyPhrasesSearchSaga } from "./generate-search-sagas";

export const debouncedKeywordSearch = getKeyPhrasesSearchSaga(
  onKeyPhrasesSearchLoading,
  onKeyPhrasesSearchError,
  onKeyPhrasesSearchSuccess,
  resetKeyPhrasesSearch
);
