import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { MultiValue } from "react-select/dist/declarations/src";
import { analyticsEvent } from "../../../helpers/analytics";
import { addUsersToGroup, getUsers } from "../../../http/user-management";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import { getOrganisation } from "../../../helpers/user";

interface SelectOptions {
  value: string;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: "red",
      marginTop: theme.spacing(8),
    },
    dialogContent: {
      paddingBottom: theme.spacing(25),
    },
    icon: {
      margin: theme.spacing(1),
    },
    paper: {
      overflow: "visible",
    },
  })
);

interface AddUsersToGroupDialogProps {
  groupId: string;
  reloadUsersAndGroups: () => void;
  onClose: () => void;
  organisationId: string;
}

export default function AddUsersToGroupDialog(props: AddUsersToGroupDialogProps) {
  const classes = useStyles();
  const { user } = useAuth0();
  const organisation = getOrganisation(user);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<SelectOptions[]>([]);
  const { groupId, organisationId, reloadUsersAndGroups, onClose } = props;

  function handleClose() {
    setSelectedUsers([]);
    onClose();
  }

  async function addToGroup() {
    setLoading(true);
    try {
      await addUsersToGroup(
        organisationId,
        groupId,
        selectedUsers.map((x) => x.value)
      );
      analyticsEvent("Account", "UsersAddedToGroup", [groupId, ...selectedUsers.map((u) => u.value)]);
    } catch (err) {
      setLoading(false);
      return;
    }
    setLoading(false);
    setSnackbarOpen(true);
    handleClose();
    setTimeout(() => {
      reloadUsersAndGroups();
    }, 1000);
  }

  async function handleSearchUsers(searchTerm: string) {
    if (searchTerm.length < 3) {
      searchTerm = "";
    }

    const userResponse = await getUsers(organisation?.id as string, 1, searchTerm, undefined);
    return userResponse.users.map<SelectOptions>((user) => {
      return {
        value: user.userId,
        label: `${user.name} (${user.email})`,
      };
    });
  }

  function handleOnSelectChange(selectedOptions: MultiValue<SelectOptions>) {
    setSelectedUsers([...selectedOptions]);
  }

  return (
    <div id="add-users-to-group-dialog">
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open
        onClose={handleClose}
        aria-labelledby="form-dialog-title-add-users-to-group"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle disableTypography id="form-dialog-title-add-users-to-group">
          <Typography variant="h2">Add users to group</Typography>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.paper,
          }}
        >
          <AsyncSelect
            id="search-users"
            isMulti
            autoFocus
            loadOptions={handleSearchUsers}
            defaultOptions
            value={selectedUsers}
            closeMenuOnScroll={false}
            closeMenuOnSelect={true}
            onChange={handleOnSelectChange}
            placeholder="Search... (3 chars min)"
          />
        </DialogContent>
        <DialogActions style={{ paddingRight: 16 }}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={loading} onClick={() => addToGroup()} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">Users successfully added to group</span>}
      />
    </div>
  );
}
