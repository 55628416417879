import { WarningOutlined as WarningIcon } from "@material-ui/icons";
import React from "react";
import { Validation } from "./Validation";

interface QueryTimedOutProps {
  email: string;
}

export const QueryTimedOut = ({ email }: QueryTimedOutProps): JSX.Element => (
  <Validation
    icon={<WarningIcon color="error" />}
    title="Your query has timed out"
    messages={[
      "Your query is probably too complicated. Please try again with a simpler query.",
      <>
        For help contact -{" "}
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          {email}
        </a>
      </>,
    ]}
  />
);
