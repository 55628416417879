import { create } from "zustand";
import { CreateFeedSectionListItem } from "../../components/Pages/Feed/Configuration/Create_New/CreateFeedSection";
import { AlertConfig } from "../../http/alert-types";

interface SignalListItem extends CreateFeedSectionListItem {
    feeds: string[];
}

interface CreateFeedStoreState {
    name: string;
    interests: CreateFeedSectionListItem[];
    signals: SignalListItem[];
    sources: CreateFeedSectionListItem[];

    mentionedLocations: CreateFeedSectionListItem[];
    publishedLocations: CreateFeedSectionListItem[];
    sectors: CreateFeedSectionListItem[];
    enablePublishDate?: boolean;

    alertsToAdd: AlertConfig[];

    page: number;
    step: number;

    isCurrentPageValid: boolean;
    setIsCurrentPageValid: (isValid: boolean) => void;

    setName: (name: string) => void;
    setInterests: (interests: CreateFeedSectionListItem[]) => void;
    setSignals: (signals: SignalListItem[]) => void;
    setSources: (sources: CreateFeedSectionListItem[]) => void;

    setAlertsToAdd: (alertsToAdd: AlertConfig[]) => void;

    setPage: (page: number) => void;
    setStep: (step: number) => void;

    toggleInterest: (interest: CreateFeedSectionListItem) => void;

    toggleSignal: (signal: SignalListItem) => void;

    addSources: (sources: CreateFeedSectionListItem[]) => void;
    toggleSource: (source: CreateFeedSectionListItem) => void;

    toggleMentionedLocation: (location: CreateFeedSectionListItem) => void;

    togglePublishedLocation: (location: CreateFeedSectionListItem) => void;

    toggleSector: (sector: CreateFeedSectionListItem) => void;

    toggleEnablePublishDate: () => void;

    nextPage: () => void;
    prevPage: () => void;

    nextStep: () => void;
    prevStep: () => void;

    reset: () => void;
}

function toggleItem<T extends CreateFeedSectionListItem>(items: T[], item: T): T[] {
    if (items.find(i => item.id === i.id) !== undefined) {
        return items.filter(i => i.id !== item.id);
    } else {
        return [...items, item];
    }
}

export const useCreateFeedStore = create<CreateFeedStoreState>((set, get) => ({
    name: "",
    interests: [],
    signals: [],
    sources: [],
    page: 0,
    step: 0,
    mentionedLocations: [],
    publishedLocations: [],
    sectors: [],
    alertsToAdd: [],
    enablePublishDate: true,

    isCurrentPageValid: false,
    setIsCurrentPageValid: (isValid: boolean) => set({ isCurrentPageValid: isValid }),

    setName: (name: string) => set({ name }),
    setInterests: (interests: CreateFeedSectionListItem[]) => set({ interests }),
    setSignals: (signals: SignalListItem[]) => set({ signals }),
    setSources: (sources: CreateFeedSectionListItem[]) => set({ sources }),

    setAlertsToAdd: (alertsToAdd: AlertConfig[]) => set({ alertsToAdd }),

    setPage: (page: number) => set({ page, step: 0 }),
    setStep: (step: number) => set({ step }),

    toggleInterest: (interest: CreateFeedSectionListItem) => set((state) => ({ interests: toggleItem(state.interests, interest) })),

    toggleSignal: (signal: SignalListItem) => set((state) => ({ signals: toggleItem(state.signals, signal) })),

    addSources: (sources: CreateFeedSectionListItem[]) => set((state) => ({ sources: [...state.sources, ...sources] })),
    toggleSource: (source: CreateFeedSectionListItem) => set((state) => ({ sources: toggleItem(state.sources, source) })),

    toggleMentionedLocation: (location: CreateFeedSectionListItem) => set((state) => ({ mentionedLocations: toggleItem(state.mentionedLocations, location) })),

    togglePublishedLocation: (location: CreateFeedSectionListItem) => set((state) => ({ publishedLocations: toggleItem(state.publishedLocations, location) })),

    toggleSector: (sector: CreateFeedSectionListItem) => set((state) => ({ sectors: toggleItem(state.sectors, sector) })),

    toggleEnablePublishDate: () => set((state) => ({ enablePublishDate: !state.enablePublishDate })),

    nextPage: () => set((state) => ({ page: state.page + 1, step: 0 })),
    prevPage: () => set((state) => ({ page: state.page - 1, step: 0 })),
    nextStep: () => set((state) => ({ step: state.step + 1 })),
    prevStep: () => set((state) => ({ step: state.step - 1 })),

    reset: () => set(() => ({
        name: "",
        interests: [],
        signals: [],
        sources: [],
        page: 0,
        step: 0,
        mentionedLocations: [],
        publishedLocations: [],
        sectors: [],
        alertsToAdd: []
    }))
}));
