import { testId } from "@amplyfi/ui-components/testHelpers";
import AiButton from "@amplyfi/ui-components/components/Button";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { createStyles, makeStyles, Paper, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CreateHarvestModal } from "../Modals/CreateHarvestModal";
import { analyticsEvent } from "../../../../helpers/analytics";
import { Harvests } from "../ViewHarvest/Harvests";
import { Harvest } from "../../../../models/harvest";
import { CreateLibraryModal } from "../Modals/CreateLibraryModal";
import Header from "../../../Widgets/Header/Header";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import { getHarvests, getOrganisationHarvests } from "../../../../http/harvest";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";
import { SelectOrganisation } from "../CreateHarvest/SelectOrganisation";
import { AccountGroup } from "../../../../models/account";
import { getOrganisations } from "../../../../http/accounts";
import { useQuery } from "react-query";
import LENSUpload from "../LENSUpload/LENSUpload";
import {
  canUserCreateHarvest,
  canUserCreateLibrary,
  canUserReadHarvest,
  canUserReadOrganisations,
} from "../permissions";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    action: {
      minWidth: 200,
      padding: `${spacing(1)}px ${spacing(3)}px`,
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 400,
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
    },
    content: {
      marginTop: spacing(2),
    },
  })
);

const DDB_EVENTUAL_CONSISTENCY = 400;
const HARVESTS_POLL = 30000;

export function ViewHarvestsCard(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const canCreateHarvest = canUserCreateHarvest(user);
  const canFilterHarvestsByOrganisation = canUserReadOrganisations(user) && canUserReadHarvest(user);

  const [showHarvestModal, setShowHarvestModal] = useState(false);
  const [showLibraryModal, setShowLibraryModal] = useState(false);
  const [harvests, setHarvests] = useState<Harvest[] | null>(null);
  const [organisation, setOrganisation] = useState<AccountGroup | null>(null);

  const CACHE_REFRESH_TIME = 4 * 60 * 60 * 1000; // 4 hours
  const { data: organisations } = useQuery(
    ["all-organisations"],
    async () => {
      const d = await getOrganisations();
      return d.organisations;
    },
    {
      cacheTime: CACHE_REFRESH_TIME,
    }
  );

  const fetchHarvests = async () => {
    try {
      const harvests = organisation ? await getOrganisationHarvests(organisation.id) : await getHarvests();
      setHarvests(harvests);
    } catch (e) {
      setHarvests([]);
      dispatch(setSnack({ body: "Unable to get harvests for your organisation", title: "Failure" }));
    }
  };

  const handleRefresh = () => {
    setHarvests(null);
  };
  const handleCreateHarvest = () => {
    analyticsEvent("Harvest", "HarvestCreateClicked");
    setShowHarvestModal(true);
  };
  const handleCreateLibrary = () => {
    analyticsEvent("Harvest", "HarvestCreateLibraryClicked");
    setShowLibraryModal(true);
  };
  const fetch = () => {
    window.setTimeout(fetchHarvests, DDB_EVENTUAL_CONSISTENCY);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  useEffect(() => {
    let REFRESH_TIMEOUT: number;

    if (harvests === null) {
      fetch();
    } else if (harvests.length) {
      REFRESH_TIMEOUT = window.setTimeout(fetch, HARVESTS_POLL);
    }

    return () => {
      window.clearTimeout(REFRESH_TIMEOUT);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [harvests, organisation]);

  return (
    <>
      <Header
        actions={[
          <AiButton
            className={classes.action}
            data-testid={testId("harvest", "create-library")}
            disabled={!canUserCreateLibrary(user)}
            key="create-library"
            onClick={handleCreateLibrary}
          >
            Create New Library
          </AiButton>,
          <AiButton
            className={classes.action}
            data-testid={testId("harvest", "create-harvest")}
            disabled={!canCreateHarvest}
            key="create-harvest"
            onClick={handleCreateHarvest}
          >
            Create New Harvest
          </AiButton>,
          <LENSUpload data-testid={testId("harvest", "upload-lens")} key="upload-lens" />,
        ]}
        title="Harvest Queries"
      />
      {canFilterHarvestsByOrganisation && (
        <div className={classes.content}>
          <SelectOrganisation
            organisation={organisation}
            setOrganisation={setOrganisation}
            organisations={organisations}
          />
        </div>
      )}
      <div className={classes.content}>
        <Paper className={classes.container}>
          {harvests === null ? (
            <AiCircularProgress size={30} />
          ) : (
            <Harvests harvests={harvests} onCreate={canCreateHarvest ? handleCreateHarvest : undefined} />
          )}
        </Paper>
      </div>
      <CreateHarvestModal
        onClose={() => setShowHarvestModal(false)}
        open={showHarvestModal}
        refreshListing={handleRefresh}
      />
      <CreateLibraryModal onClose={() => setShowLibraryModal(false)} open={showLibraryModal} />
    </>
  );
}
