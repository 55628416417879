import AiButton from "@amplyfi/ui-components/components/Button";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { TestDataProps } from "@amplyfi/ui-components/types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import { AmplyfiUnknownEntity } from "../../models";

interface ConfirmDeleteProps extends Partial<TestDataProps> {
  id?: AmplyfiUnknownEntity["id"];
  name: AmplyfiUnknownEntity["name"];
  onClose: () => void;
  onConfirm: (id: AmplyfiUnknownEntity["id"]) => void;
}

export const ConfirmDelete = ({
  "data-testid": dataTestId,
  id,
  name,
  onClose,
  onConfirm,
}: ConfirmDeleteProps): JSX.Element => (
  <Dialog maxWidth="sm" data-testid={testId(dataTestId, "confirm-delete")} open={!!id} onClose={onClose}>
    <DialogTitle disableTypography>
      <Typography variant="h2">Confirm delete</Typography>
    </DialogTitle>
    <DialogContent>
      <p>Are you sure you wish to delete {name}.</p>
    </DialogContent>
    <DialogActions style={{ padding: "0 24px 18px 0" }}>
      <AiButton
        data-testid={testId(dataTestId, "confirm-delete", "cancel")}
        style={{ marginLeft: "auto" }}
        amplyfiType="secondary"
        onClick={onClose}
      >
        Cancel
      </AiButton>
      <AiButton
        data-testid={testId(dataTestId, "confirm-delete", "confirm")}
        amplyfiType="negative"
        onClick={() => {
          onClose();
          id && onConfirm(id);
        }}
      >
        Delete
      </AiButton>
    </DialogActions>
  </Dialog>
);
