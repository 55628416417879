import axios from "axios";
import { LibrariesType } from "../models/queryParams";
import { DocumentType, SearchRequestV2 } from "../models/search";
import { getAnalysisV2Url } from "./general";

export async function getDocCount(
  searchRequest: SearchRequestV2,
  docType?: LibrariesType[] | DocumentType[],
  resetDates?: boolean
): Promise<number> {
  if (resetDates) {
    delete searchRequest.userFilters.endDate;
    delete searchRequest.userFilters.startDate;
  }
  const searchResponse = await axios.post<{ documentCount: number }>(getAnalysisV2Url("search/count", docType), searchRequest);

  return searchResponse.data.documentCount;
}
