import { useQueryClient } from "react-query";
import { PagedData } from "../../../../http/feed";
import { MonitoringDocument } from "../../../../models/monitoring";
import { useFeedkey, useFeedDocumentKey } from "../../../useFeed";
import useMutation from "../../../useMutation";

export default function useUpdateCommentCount(resourceId: string) {
    const queryClient = useQueryClient();

    return useMutation((delta: number) => {
        const result1 = queryClient.setQueriesData<PagedData<MonitoringDocument> | undefined>([useFeedkey], (oldData) => {
            if (!oldData) {
                return undefined;
            }
            return {
                ...oldData,
                pages: oldData.pages.map((page) => {
                    if (page.documents.find((doc) => doc.documentId === resourceId)) {
                        return {
                            ...page,
                            documents: page.documents.map((doc) => {
                                if (doc.documentId === resourceId) {
                                    return {
                                        ...doc,
                                        commentsCount: doc.commentsCount + delta
                                    }
                                }
                                return doc;
                            })
                        }
                    }
                    return page;
                })
            }
        });

        const result2 = queryClient.setQueryData<MonitoringDocument | undefined>([useFeedDocumentKey, resourceId], (oldData) => {
            if (!oldData) {
                return undefined;
            }

            return {
                ...oldData,
                commentsCount: oldData.commentsCount + delta
            }
        })
        return Promise.all([result1, result2]);
    }, [])
}