import AiButton from "@amplyfi/ui-components/components/Button";
import {
  Avatar,
  createStyles,
  darken,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Delete } from "@material-ui/icons";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import { analyticsEvent } from "../../../helpers/analytics";
import { getOrganisation } from "../../../helpers/user";
import { deleteGroup, getGroup, getGroupMembers, removeUserFromGroups } from "../../../http/user-management";
import { AmplyfiUser } from "../../../models/user";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import SimpleDialog from "../../Widgets/Dialog/SimpleDialog";
import AddUsersToGroupDialog from "../../Widgets/Settings/AddUserToGroupsModal";
import { canUserAccountDelegatedAdmin } from "./permissions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      padding: theme.spacing(2),
    },
    header: {
      display: "flex",
      alignItems: "center",
    },
    connectorList: {},
    connectorListItem: {
      display: "flex",
      height: 60,
      alignItems: "center",
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),

      "&:hover": {
        background: darken(theme.palette.componentBackground.main, 0.1),
        cursor: "pointer",
      },
    },
    connectorListIcon: {
      height: 36,
      width: 36,
      objectFit: "contain",
    },
    connectorListTitle: {
      marginLeft: theme.spacing(1),
      flex: "1 1 auto",
    },
    row: {
      cursor: "pointer",
    },
  })
);

interface UserListItemProps extends AmplyfiUser {
  groupId: string;
  refetch: () => void;
  organisationId: string;
}

function UserListItem(props: UserListItemProps): JSX.Element {
  const { userId, name, email, picture, groupId, refetch, organisationId } = props;
  const styles = useStyles();
  const history = useHistory();
  const { user } = useAuth0();
  const userIsDelegatedAdmin = canUserAccountDelegatedAdmin(user);
  const [confirmRemove, setConfirmRemove] = useState(false);

  return (
    <TableRow className={styles.row} key={userId}>
      <TableCell onClick={() => history.push(`/manage/users/${userId}`)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar className={styles.connectorListIcon} src={picture} alt="" />
          <Typography className={styles.connectorListTitle} variant="body1">
            {name}
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{email}</Typography>
      </TableCell>
      <TableCell>
        {userIsDelegatedAdmin && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setConfirmRemove(true);
            }}
          >
            <Delete />
          </IconButton>
        )}
      </TableCell>
      {confirmRemove && (
        <SimpleDialog
          title={`Are you sure you want to remove ${name} from this group?`}
          content=""
          onClose={() => setConfirmRemove(false)}
          onConfirm={async () => {
            await removeUserFromGroups(organisationId, userId, [groupId]);
            refetch();
            setConfirmRemove(false);
          }}
        />
      )}
    </TableRow>
  );
}

export default function GroupPage(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const { user: currentUser } = useAuth0();
  const organisation = getOrganisation(currentUser);
  const paths = location.pathname.split("/");
  const groupId = paths[paths.length - 1];
  const { data: group, isLoading } = useQuery(["group", groupId], () => getGroup(organisation?.id as string, groupId));
  const { data, refetch } = useQuery(["group-members", groupId], () =>
    getGroupMembers(organisation?.id as string, groupId, undefined)
  );
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addUsersModal, setAddUsersModal] = useState(false);
  const styles = useStyles();
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 70,
    },
    {
      field: "email",
      headerName: "Email",
      width: 70,
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
    },
  ];

  return (
    <div className={styles.container}>
      <div style={{ display: "flex" }}>
        <IconButton edge="start" style={{ alignSelf: "flex-start" }} onClick={() => history.replace("/manage/groups")}>
          <ArrowBack />
        </IconButton>
        <div style={{ marginLeft: 8 }}>
          <Typography variant="h3">{group?.name || <Skeleton height={20} width={100} />}</Typography>
          <Typography style={{ fontWeight: "normal" }} variant="h4">
            {group?.description || <Skeleton height={16} width={200} />}
          </Typography>
        </div>
        <div style={{ marginLeft: "auto" }}>
          {!isLoading && canUserAccountDelegatedAdmin(currentUser) && (
            <>
              <AiButton size="small" amplyfiType="primary" onClick={() => setAddUsersModal(true)}>
                Add Users
              </AiButton>
              <AiButton
                style={{ marginLeft: 8 }}
                size="small"
                amplyfiType="negative"
                onClick={() => setConfirmDelete(true)}
                disabled={!data || data.count > 0}
              >
                Delete Group
              </AiButton>
            </>
          )}
        </div>
      </div>
      <Table size="small" className={styles.connectorList}>
        <TableHead>
          <TableRow className={styles.row}>
            {columns.map((col) => (
              <TableCell style={{ fontWeight: "bold" }}>{col.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.users.map((user) => (
            <UserListItem refetch={refetch} groupId={groupId} {...user} organisationId={organisation?.id as string} />
          ))}
        </TableBody>
      </Table>

      {confirmDelete && group && organisation && (
        <SimpleDialog
          title={`Are you sure you want to delete "${group.name}"?`}
          content=""
          onClose={() => setConfirmDelete(false)}
          onConfirm={async () => {
            await deleteGroup(group.id, organisation.id);
            analyticsEvent("Account", "GroupDeleted", [organisation.id, groupId]);
            setConfirmDelete(false);
            history.replace("/manage/groups");
          }}
        />
      )}
      {addUsersModal && (
        <AddUsersToGroupDialog
          groupId={groupId}
          organisationId={organisation?.id as string}
          reloadUsersAndGroups={() => {
            return;
          }}
          onClose={() => {
            refetch();
            setAddUsersModal(false);
          }}
        />
      )}
    </div>
  );
}
