import React from "react";
import { logError } from "../../../helpers/logger";
import ErrorCard from "../../Cards/ErrorCard";

// eslint-disable-next-line @typescript-eslint/ban-types
export default class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): {
    hasError: boolean;
} {
    return { hasError: true };
  }

  componentDidCatch(error: unknown): void {
    logError(error);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorCard />;
    }

    return this.props.children;
  }
}
