import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { createStyles, IconButton, makeStyles, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router";
import useScreenSize from "../../../hooks/useScreenSize";

interface AbstractSettingsPageProps {
  title: string;
  children: JSX.Element | JSX.Element[] | null;
  loading?: boolean;
  maxWidth?: number | string;
  actions?: JSX.Element;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    page: ({ maxWidth }: { maxWidth: number | string }) => ({
      paddingTop: theme.spacing(2),
      maxWidth: maxWidth,
      flex: "1 1 auto",
    }),
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    content: {
      marginTop: theme.spacing(2),
    },
    actions: {
      alignSelf: "center",
      marginLeft: "auto",
    },
  })
);

export default function AbstractSettingsPage(props: AbstractSettingsPageProps) {
  const { title, children, loading, maxWidth = 700, actions } = props;
  const styles = useStyles({ maxWidth });
  const history = useHistory();
  const screenSize = useScreenSize();
  return (
    <div className={styles.page}>
      <div className={styles.titleContainer}>
        {screenSize === "mobile" && (
          <IconButton onClick={() => history.replace("/manage")}>
            <ChevronLeft />
          </IconButton>
        )}
        <Typography variant="h2">{title}</Typography>
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
      {loading ? <AiCircularProgress /> : <div className={styles.content}>{children}</div>}
    </div>
  );
}
