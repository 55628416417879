import { useAuth0 } from "../components/Auth0/AuthWrapper";
import { getUserId, getOrgId } from "../helpers/user";
import { getUserNotifications } from "../http/user-notifications";
import { useQuery } from "./useQuery";

export default function useUserNotifications() {
  const { user } = useAuth0();
  const organisationId = getOrgId(user);
  const userId = getUserId(user);

  return useQuery(["user-notifications", userId], () => getUserNotifications(organisationId, userId));
}
