import { makeStyles, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getOrgId, getUserId, hasAuth0Identity } from "../../../../helpers/user";
import useUser from "../../../../hooks/useUser";
import useValidation from "../../../../hooks/useValidation";
import { updateUserById } from "../../../../http/accounts";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import AbstractSettingsPage from "../AbstractSettingsPage";
import { SimpleUserSection, UserSectionCard } from "../UserSection";
import ValidationDisplay from "../ValidationDisplay";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  passwordCard: {
    flex: "0 1 500px",
  },
}));

export default function Security() {
  const { user } = useAuth0();
  const organisationId = getOrgId(user);
  const sub = getUserId(user);
  const styles = useStyles();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const { valid, validationMessages } = useValidation(
    ["minLengthPassword", "lowerCase", "upperCase", "number", "special", "sameAs"],
    [],
    [password, { sameAs: confirmPassword }]
  );

  const { data, isLoading } = useUser(organisationId, sub);
  const dispatch = useDispatch();

  async function saveUser() {
    if (!user) {
      return;
    }
    try {
      await updateUserById(sub, { password: confirmPassword });
      dispatch(setSnack({ body: "Your password has been updated", title: "Password updated", autoHideDuration: 3000 }));
    } catch (e) {
      dispatch(
        setSnack({
          body: "There was a problem updating your password. Please try again.",
          title: "Error Updating password",
          autoHideDuration: 3000,
        })
      );
    }
  }

  return (
    <AbstractSettingsPage maxWidth={1200} title="Security">
      <div className={styles.container}>
        <UserSectionCard
          className={styles.passwordCard}
          title="Password"
          loading={isLoading}
          confirmButtonDisabled={valid === false || isLoading}
          confirmButtonText="Change Password"
          onConfirm={
            !hasAuth0Identity(data?.identities)
              ? undefined
              : async () => {
                  await saveUser();
                }
          }
        >
          {hasAuth0Identity(data?.identities) ? (
            <>
              <SimpleUserSection title="New Password">
                <TextField fullWidth type="password" onChange={(e) => setPassword(e.target.value)} />
              </SimpleUserSection>
              <SimpleUserSection title="Verify New Password" subtitle="Verify your new password">
                <TextField fullWidth type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
              </SimpleUserSection>
            </>
          ) : (
            <div style={{ padding: 32 }}>
              <Typography variant="body2">Your password is managed by an external provider:</Typography>

              <ul>
                {data?.identities
                  .filter((i) => i.provider !== "auth0")
                  .map((i) => (
                    <li>{i.provider}</li>
                  ))}
              </ul>
              <Typography variant="body2"></Typography>
            </div>
          )}
        </UserSectionCard>
        {hasAuth0Identity(data?.identities) && <ValidationDisplay options={validationMessages} />}
      </div>
    </AbstractSettingsPage>
  );
}
