import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import useDocumentSummary from "../../../../hooks/useDocumentSummary";
import { MonitoringDocument } from "../../../../models/monitoring";
import { AbstractTabProps } from "../tabs";
import Tab from "./Tab";

export default function HighlightsTab({ documentId, document, documentMeta }: AbstractTabProps) {
  const id = (document as MonitoringDocument)?.documentId || documentId;
  const { data, isLoading } = useDocumentSummary(id);

  return isLoading ? (
    <AiCircularProgress />
  ) : (
    <Tab
      showCopyMultiple
      bullets={data}
      copyMultiple={{
        handler: documentMeta,
        text: "Copy all",
        tooltipText: "Copy all Highlights and document info",
      }}
      copySingular={{ text: "Copy Highlight", handler: (a) => a, tooltipText: "Copy single Highlight" }}
      noContent="No representative sentences were extracted"
    />
  );
}
