import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { createStyles, Typography, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getHarvest, getDocumentStatuses } from "../../../../http/harvest";
import { HarvestItem, DocumentStatusItem } from "../../../../models/harvest";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";

interface HarvestDetailProps {
  harvestId: string;
  organisationId: string;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    section: {
      marginTop: spacing(2),
    },
  })
);

const Harvest = ({
  harvest,
  documentStatus,
}: {
  harvest: HarvestItem;
  documentStatus: DocumentStatusItem;
}): JSX.Element => {
  const { status, estimatedDocuments, query, organisationName, libraryName, harvestId, documentsHarvested, libraryId, organisationId, source, maxResultsRequested, createdBy, generateSignals, amplyfiEntityId } = harvest;
  const { failed, rejected, inProgress, succeeded, rejectionReasons } = documentStatus;
  const classes = useStyles();
  const keys = rejectionReasons ? Object.keys(rejectionReasons) : [];

  return (
    <>
      <section className={classes.section}>
        <Typography variant="h2">Organisation: {organisationName}</Typography>
        <Typography variant="h2">Library: {libraryName}</Typography>
        <hr />
        <Typography variant="h3">Harvest Details</Typography>
        <p><strong>Batch Id / Harvest Id:</strong> {harvestId}</p>
        <p><strong>Library Id:</strong> {libraryId}</p>
        <p><strong>Organisation Id:</strong> {organisationId}</p>
        <p><strong>Query:</strong> {query}</p>
        <p><strong>Source:</strong> {source}</p>
        <p><strong>Status:</strong> {status}</p>
        <p><strong>Created by:</strong> {createdBy}</p>
        <p><strong>Generate Signals:</strong> {`${generateSignals}`}</p>
        <p><strong>Max Results Requested:</strong> {maxResultsRequested}</p>
        <p><strong>Entity ID:</strong> {amplyfiEntityId}</p>
        <hr />
        <Typography variant="h3">Harvester Status</Typography>
        <p><strong>Estimated Document Count:</strong> {estimatedDocuments}</p>
        <p><strong>Total Documents Harvested:</strong> {succeeded + rejected + failed + inProgress}</p>
        <hr />
        <Typography variant="h3">Pipeline Status</Typography>
        <p><strong>In Progress Documents:</strong> {inProgress}</p>
        <p><strong>Rejected Documents:</strong> {rejected}</p>
        <ul>
          {
            rejectionReasons && keys.map(key => {
              return <li key={key}><strong>{key}:</strong> {rejectionReasons[key]}</li>
            })
          }
        </ul>
        <p><strong>Failed Documents:</strong> {failed}</p>
        <p><strong>Successfully Processed by the Pipeline:</strong> {succeeded}</p>
        <hr />
        <Typography variant="h3">Indexing Status</Typography>
        <p><strong>Documents Indexed:</strong> {documentsHarvested}</p>
      </section>
    </>
  );
};

export const HarvestDetail = ({ harvestId, organisationId }: HarvestDetailProps): JSX.Element => {
  const dispatch = useDispatch();
  const [harvest, setHarvest] = useState<HarvestItem | null>();
  const [documentStatus, setDocumentStatus] = useState<DocumentStatusItem | null>();

  const fetchHarvest = async (organisationId: string, harvestId: string) => {
    try {
      const [harvest, documentStatus] = await Promise.all([
        getHarvest(organisationId, harvestId),
        getDocumentStatuses(harvestId),
      ]);
      setHarvest(harvest);
      setDocumentStatus(documentStatus);
    } catch (e) {
      setHarvest(null);
      dispatch(setSnack({ body: "Unable to get harvest details", title: "Failure" }));
    }
  };

  useEffect(() => {
    if (organisationId && harvestId) {
      fetchHarvest(organisationId, harvestId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [harvestId, organisationId]);

  if (harvest && documentStatus) {
    return <Harvest harvest={harvest} documentStatus={documentStatus} />;
  }

  if (harvest === undefined) {
    return <AiCircularProgress size={30} />;
  }

  return <>Unable to load harvest details</>;
};
