import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { White } from "@amplyfi/ui-components/theme/colors";
import { blueShades } from "../../../components/Charts/helpers/colourScaleHexes";
import { GeoItem, GeoMetricCountry, GeoMetricsResponse, GeoResponse } from "../../../models/geo";
import { resetState } from "../searchRequest/actions";

interface GeoState {
  loading: boolean;
  error: boolean;
  newsPublishedGeographies: GeoItem[];
  newsMentionedGeographies: GeoItem[];
  academicPublishedGeographies: GeoItem[];
  jurisdictions: GeoItem[];
  metricsLoading: boolean;
  metricsError: boolean;
  metrics: GeoMetricCountry[];
}

const initialState: GeoState = {
  loading: false,
  error: false,
  jurisdictions: [],
  newsPublishedGeographies: [],
  newsMentionedGeographies: [],
  academicPublishedGeographies: [],
  metricsError: false,
  metricsLoading: false,
  metrics: [],
};

const geoSlice = createSlice({
  name: "geo",
  initialState,
  reducers: {
    onGeoLoading(state: GeoState) {
      state.loading = true;
      state.error = false;
    },
    onGeoSuccess(
      state: GeoState,
      action: PayloadAction<{ news: GeoResponse; academic: GeoItem[]; jurisdictions: GeoItem[] }>
    ) {
      const { jurisdictions, news, academic } = action.payload;
      state.loading = false;
      state.newsPublishedGeographies = calculateCountryColours(news.publishedGeographies);
      state.newsMentionedGeographies = calculateCountryColours(news.mentionedGeographies);
      state.academicPublishedGeographies = calculateCountryColours(academic);
      state.jurisdictions = calculateCountryColours(jurisdictions);
    },
    onGeoError(state: GeoState) {
      state.loading = false;
      state.error = true;
    },
    onGeoMetricsLoading(state: GeoState) {
      state.metricsLoading = true;
      state.metricsError = false;
    },
    onGeoMetricsSuccess(state: GeoState, action: PayloadAction<GeoMetricsResponse>) {
      state.metricsLoading = false;
      state.metrics = action.payload;
    },
    onGeoMetricsError(state: GeoState) {
      state.metricsError = true;
      state.metricsLoading = false;
    },
  },
  extraReducers: {
    [resetState.type]: function () {
      return initialState;
    },
  },
});

export const { onGeoError, onGeoLoading, onGeoSuccess, onGeoMetricsError, onGeoMetricsLoading, onGeoMetricsSuccess } =
  geoSlice.actions;

export default geoSlice.reducer;

function calculateCountryColours(items: GeoItem[]): GeoItem[] {
  const NUMBER_OF_HEXES = blueShades.length;
  const maxCalculated = Math.max(...items.map((x) => x.documentCount));
  const sortedItem = items.sort((a, b) => a.documentCount - b.documentCount);

  return items.map((x) => {
    const index = sortedItem.indexOf(x);
    const hexIndex = Math.round((index / sortedItem.length) * (NUMBER_OF_HEXES - 1));
    return {
      ...x,
      scaledDocumentCount: x.documentCount / maxCalculated,
      hex: x.documentCount === 0 ? White : blueShades[hexIndex],
    };
  });
}
