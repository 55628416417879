import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChartCustomizationResult, ChartCustomizationType } from "../../../models/chart-customizations";
import { EntityType, PositionedEntity } from "../../../models/entity";
import { PositionedKeyPhrase } from "../../../models/keyPhrase";

interface CustomizationState {
  keyPhrasesLoading: boolean;
  keyPhrasesSearchError: boolean;
  entitiesSearchLoading: boolean;
  entitiesSearchError: boolean;
  chartCustomizationResults: ChartCustomizationResult[] | null;
}

const initialState = {
  keyPhrasesLoading: false,
  keyPhrasesSearchError: false,
  entitiesSearchLoading: false,
  entitiesSearchError: false,
  chartCustomizationResults: null,
} as CustomizationState;

const searchSlice = createSlice({
  name: "customization",
  initialState: initialState,
  reducers: {
    onKeyPhrasesSearchLoading: (state: CustomizationState, action: PayloadAction<string>) => {
      resetState(state);
      state.keyPhrasesLoading = true;
      state.keyPhrasesSearchError = false;
    },
    onKeyPhrasesSearchSuccess: (state: CustomizationState, action: PayloadAction<PositionedKeyPhrase[]>) => {
      state.keyPhrasesLoading = false;
      const chartCustomizationResultWithoutKeyPhrases =
        state.chartCustomizationResults?.filter((x) => x.type !== EntityType.KeyPhrase) ?? [];
      state.chartCustomizationResults = [
        ...action.payload.map<ChartCustomizationResult>((keyPhrase) => ({
          id: keyPhrase.id,
          name: keyPhrase.name,
          prev: keyPhrase.prevalence,
          type: EntityType.KeyPhrase,
        })),
        ...chartCustomizationResultWithoutKeyPhrases,
      ];
    },
    onKeyPhrasesSearchError: (state: CustomizationState) => {
      state.keyPhrasesSearchError = true;
      state.keyPhrasesLoading = false;
      state.chartCustomizationResults =
        state.chartCustomizationResults?.filter((x) => x.type !== EntityType.KeyPhrase) ?? null;
    },
    resetKeyPhrasesSearch: (state: CustomizationState) => {
      state.keyPhrasesSearchError = false;
      state.keyPhrasesLoading = false;
      state.chartCustomizationResults =
        state.chartCustomizationResults?.filter((x) => x.type !== EntityType.KeyPhrase) ?? null;
    },
    onEntitiesSearchLoading: (state: CustomizationState, action: PayloadAction<string>) => {
      resetState(state);
      state.entitiesSearchLoading = true;
      state.entitiesSearchError = false;
    },
    onEntitiesSearchSuccess: (state: CustomizationState, action: PayloadAction<PositionedEntity[]>) => {
      state.entitiesSearchLoading = false;
      const entities = action.payload;
      const chartCustomizationResultWithoutEntities =
        state.chartCustomizationResults?.filter((x) => x.type === EntityType.KeyPhrase) ?? [];

      state.chartCustomizationResults = [
        ...chartCustomizationResultWithoutEntities,
        ...entities.map<ChartCustomizationResult>((x) => ({
          id: x.id,
          prev: x.prevalence,
          name: x.name,
          type: x.type as ChartCustomizationType,
        })),
      ];
    },
    onEntitiesSearchError: (state: CustomizationState) => {
      state.entitiesSearchError = true;
      state.entitiesSearchLoading = false;
      state.chartCustomizationResults =
        state.chartCustomizationResults?.filter((x) => x.type === EntityType.KeyPhrase) ?? null;
    },
    resetEntitiesSearch: (state: CustomizationState) => {
      state.entitiesSearchLoading = false;
      state.entitiesSearchError = false;
      state.chartCustomizationResults =
        state.chartCustomizationResults?.filter((x) => x.type === EntityType.KeyPhrase) ?? null;
    },
  },
});

export const {
  onKeyPhrasesSearchLoading,
  onKeyPhrasesSearchSuccess,
  onKeyPhrasesSearchError,
  onEntitiesSearchLoading,
  onEntitiesSearchSuccess,
  onEntitiesSearchError,
  resetEntitiesSearch,
  resetKeyPhrasesSearch,
} = searchSlice.actions;

export default searchSlice.reducer;

function resetState(state: CustomizationState) {
  state.chartCustomizationResults = null;
}
