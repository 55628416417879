import AiDocumentListItem from "@amplyfi/ui-components/components/DocumentListItem";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { TestDataProps } from "@amplyfi/ui-components/types";
import { useDispatch } from "react-redux";
import { DocumentListItem as DocumentListItemModel } from "../../models/document";
import { useAnalyseSelector } from "../../store/reducers";
import { setSelectedDocument } from "../../store/reducers/searchResponse/relatedDocumentViewerReducer";

interface DocumentListItemProps extends Partial<TestDataProps> {
  document: DocumentListItemModel;
}

export default function DocumentListItem(props: DocumentListItemProps): JSX.Element {
  const { "data-testid": dataTestId, document } = props;
  const { selectedDocumentId } = useAnalyseSelector((x) => x.searchResponse.relatedDocumentViewer);
  const dispatch = useDispatch();
  const isSelectedDoc = document.id === selectedDocumentId || (selectedDocumentId === "" && document.index === 0);

  const handleDocClick = (id: string) => {
    dispatch(setSelectedDocument({ id, type: document.type }));
  };

  return (
    <AiDocumentListItem
      data-testid={testId(dataTestId, `item-${document.index}`)}
      {...document}
      selected={isSelectedDoc}
      handleDocClick={handleDocClick}
    />
  );
}
