import AiIconButton from "@amplyfi/ui-components/components/IconButton";
import { Box, List, ListItem, Portal, TextField, makeStyles } from "@material-ui/core";
import { FileCopy, Send } from "@material-ui/icons";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Message, useLiveQAQuery } from "../../../../../hooks/queries/question_answer/useQAQuery";
import { MessageFrom } from "../../../../../hooks/queries/sockets/types";
import useScreenSize from "../../../../../hooks/useScreenSize";
import useQuestionAnswerStore from "../../../../../store/hooks/useQuestionAnswerStore";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import InformativeButton from "../../Configuration/Insights/InformativeButton";
import AnimatedEllipsis from "../animatedEllipsis/AnimatedEllipsis";
import { ReaderPanel } from "./Reader";
import ChatMessage from "./investigation/ChatMessage";
import Stack from "../stack/Stack";

const useStyles = makeStyles(({ spacing }) => ({
  panel: {
    display: "grid",
    gridTemplateRows: "min-content 200px",
    gridTemplateColumns: "100%",
    backgroundColor: "white",
    flex: "1 1 auto",
  },
  list: {
    minHeight: "60vh"
  },
  message: {
    display: "inline-block",
    maxWidth: "80%",
    width: "auto",
    whiteSpace: "pre-line",
    marginBottom: spacing(1),
    borderRadius: 5,
  },
  loadingMessage: {
    "& span:after": {
      fontSize: 20,
    },
  },
  userMessage: {
    marginLeft: "auto",
    textAlign: "right",
    background: "#f0f3f6",
  },
  systemMessage: {
    background: "#e6f5f8",
    minWidth: 68,
  },
  textEntryContainer: {
    background: "white",
    width: "100%",
    padding: spacing(2),
  },
  textEntry: {
    marginBottom: spacing(1),
    "& .MuiInputBase-root": {
      borderRadius: spacing(2.5),
      paddingLeft: spacing(2),
      "& button:hover:enabled": {
        background: "none",
        color: "#004fd0",
        "& svg": {
          color: "#004fd0",
        },
      },
      "& svg": {
        color: "#004fd0",
        fontSize: 16,
      },
    },
  },
  chatContainer: {
    paddingBottom: spacing(10),
  },
}));

interface InvestigationProps {
  libraryId: string;
  amplyfiEntityId?: string;
  firstUserMessage?: string;
}

export default function Investigation(props: InvestigationProps) {
  const classes = useStyles();
  const [text, setText] = useState<string>("");
  const screenSize = useScreenSize();
  const listRef = useRef<HTMLUListElement | null>(null);

  const { selectedTab, setSelectedTab, selectedPanel, setSelectedPanel } = useReaderStore((state) => state);
  const { messages, sendMessage } = useLiveQAQuery(props.libraryId, props.amplyfiEntityId);
  const setDocumentIds = useQuestionAnswerStore(({ setDocumentIds }) => setDocumentIds);

  const suggestions = messages.filter((m) => m.type === "investigation-questions");
  const isUnansweredMessage = ({ text }: Message) => text.startsWith("I'm sorry, ") || text.startsWith("Sorry, ");
  const getLastQuestion = (): undefined | string => {
    const lastQuestions: Message[] = messages.filter(({ from }) => from === MessageFrom.User);
    return lastQuestions[lastQuestions.length - 1]?.text;
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (selectedTab.id === "investigation") {
      if (selectedTab.data) {
        sendMessage(selectedTab.data.suggestedInvestigation);
        setSelectedTab({ ...selectedTab, data: undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (selectedPanel?.id === ReaderPanel.FeedInvestigation) {
      setDocumentIds([]);
      if (selectedPanel?.data) {
        setTimeout(() => {
          if (selectedPanel.data) {
            sendMessage(selectedPanel?.data.question);
          }
        }, 100);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPanel]);

  if (selectedPanel?.id === ReaderPanel.Card && selectedTab.id !== "investigation") {
    return null;
  }

  return (
    <Stack spacing={0} className={classes.panel}>
      <List ref={listRef} className={classes.list}>
        {messages
          .filter((m) => m.type !== "investigation-questions")
          .map((message, i) => (
            <ChatMessage
              key={message.text}
              {...message}
              isUnansweredMessage={isUnansweredMessage(message)}
              showSources={i > 0 && message.isFinished}
              handleUnansweredQuestion={
                isUnansweredMessage(message)
                  ? () => {
                    const lastQuestion = getLastQuestion();
                    if (lastQuestion) {
                      setSelectedPanel({ id: ReaderPanel.FeedInvestigation, data: { question: lastQuestion } });
                    } else {
                    }
                  }
                  : undefined
              }
            />
          ))}
        {messages[messages.length - 1].from === MessageFrom.User && (
          <ListItem className={clsx(classes.message, classes.systemMessage, classes.loadingMessage)}>
            <AnimatedEllipsis size={8} />
          </ListItem>
        )}
        {messages.length > 2 && messages[messages.length - 1].from === MessageFrom.System && (
          <ListItem className={clsx(classes.message, classes.systemMessage)}>
            <InformativeButton
              onClick={() => {
                const text = `${messages.map((m) => m.text).join("\n\n")}`;
                navigator.clipboard.writeText(text);
              }}
              startIcon={<FileCopy />}
              informativeText="Copied"
            >
              Copy Chat
            </InformativeButton>
          </ListItem>
        )}
        {suggestions.length > 0 && (
          <Box paddingTop={3} marginTop="auto">
            {suggestions.map((message, i) => (
              <ChatMessage
                key={message.text}
                onClick={(txt) => {
                  if (messages[messages.length - 1].isFinished) {
                    sendMessage(txt);
                  }
                }}
                {...message}
                showSources={i > 0 && message.isFinished}
              />
            ))}
          </Box>
        )}
      </List>
      <Portal container={() => document.getElementById("footer-container")}>
        <Box className={classes.textEntryContainer}>
          <TextField
            // if the last message was 2 or more seconds ago, allow the user to send a message
            disabled={
              messages[messages.length - 1].isFinished === false ||
              messages[messages.length - 1].from === MessageFrom.User
            }
            fullWidth
            className={classes.textEntry}
            autoFocus={screenSize === "desktop"}
            placeholder="Ask a question"
            value={text}
            onChange={(e) => setText(e.target.value)}
            InputProps={{
              endAdornment: (
                <AiIconButton
                  icon={<Send fill="currentColor" fontSize="inherit" />}
                  onClick={() => {
                    if (text.length) {
                      sendMessage(text);
                      if (listRef.current) {
                        listRef.current.scrollTop = listRef.current.scrollHeight;
                      }
                      setText("");
                    }
                  }}
                />
              ),
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && text.length > 0) {
                sendMessage(text);
                if (listRef.current) {
                  listRef.current.scrollTop = listRef.current.scrollHeight;
                }
                setText("");
                e.currentTarget.blur();
              }
            }}
          />
        </Box>
      </Portal>
    </Stack>
  );
}
