import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const MENU_ITEM_HEIGHT = 70;

export const useLeftPanelStyling = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: (props: { width?: number }) => ({
      backgroundColor: theme.palette.componentBackground.main,
      overflowY: "auto",
      overflowX: "hidden",
      zIndex: 1,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: props.width,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        height: "100%",
      },
    }),
    menuItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: MENU_ITEM_HEIGHT,
      borderBottom: `1px solid ${theme.palette.borders.contrastText}`,
      borderLeft: `3px solid ${theme.palette.componentBackground.main}`,
      padding: 0,
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.palette.appBackground.light,
      flex: "1 1 70px",

      "&.MuiListItem-root": {
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },

      "& svg": {
        fill: theme.palette.appBackground.light,
      },
      "&:hover": {
        color: theme.palette.primary.main,
        "& svg": {
          fill: `${theme.palette.primary.main} !important`,
        },
      },
    },
    selectedMenuItem: {
      backgroundColor: theme.palette.accent.main,
      color: theme.palette.primary.main,

      "& svg": {
        fill: `${theme.palette.primary.main} !important`,
      },
      [theme.breakpoints.down("sm")]: {
        borderTop: `1px solid ${theme.palette.text.primary}`,
      },
      [theme.breakpoints.up("md")]: {
        borderLeft: `3px solid ${theme.palette.text.primary}`,
      },
    },
  })
);
