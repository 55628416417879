export function decimalPlaces(value: number): number {
  if (value !== 0) {
    if (value < 0.01) {
      return 4;
    }
    if (value < 0.1) {
      return 3;
    }
    if (value < 1) {
      return 2;
    }
  }  
  return 1;
}

export function formatToPercentage(data: number): string {
  const percentage = data * 100;
  return `${percentage.toFixed(decimalPlaces(percentage))}%`;
}
