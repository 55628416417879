import { Box, Theme, Typography, capitalize, lighten, makeStyles } from "@material-ui/core";
import { Block, Category, Chat, ChatBubbleOutlineOutlined, DataUsage, Delete, Favorite, FavoriteBorder, FileCopyOutlined, Launch, LiveHelpOutlined, LocalOfferOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../../../../helpers/analytics";
import { EventAction } from "../../../../../helpers/analytics.types";
import { friendlyDateFormat } from "../../../../../helpers/dateHelpers";
import { useAlertsFeedbackState } from "../../../../../hooks/mutations/feed/useFeedbackState";
import useAIHistory from "../../../../../hooks/useAIHistory";
import { useDocumentLibraryFromList } from "../../../../../hooks/useDocumentLibraries";
import { UserResponseFeedbackState } from "../../../../../http/feed";
import { MonitoringDocument, MonitoringFeedbackState } from "../../../../../models/monitoring";
import { useFeedViewStore } from "../../../../../store/hooks/useFeedViewStore";
import useQuestionAnswerStore from "../../../../../store/hooks/useQuestionAnswerStore";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import { setSnack } from "../../../../../store/reducers/ui/snackReducer";
import { BlockSectors } from "../../components/BlockSectors";
import { BlockSource } from "../../components/BlockSource";
import { BlockTopics } from "../../components/BlockTopics";
import { BlockTypeTags } from "../../components/BlockTypeTags";
import { UserTags } from "../../components/UserTags";
import { FeedViewType, insightsPage } from "../../components/filterMenu/feedFilters";
import Stack from "../../components/stack/Stack";
import DotSeparator from "../Components/DotSeparator";
import ActionHeader, { Action } from "./ActionHeader";
import { SignalCardProps } from "./SignalCard";

const useStyles = makeStyles<Theme, { compact: boolean; }>(() => ({
    favouritedAlert: {
        color: "red",
        "button:hover:enabled > &": {
            color: lighten("#FF0000", 0.5),
        },
    },
    headerText: {
        fontSize: 12,
        color: "#606060",
        fontWeight: 400
    }
}));

function getEventAction(response: MonitoringFeedbackState): string {
    switch (response) {
        case "Discarded":
            return "Discarded";
        case "Favourites":
            return "Favourited";
        case "Inbox":
        default:
            return `returned to ${insightsPage.label}`;
    }
}

function copySignalToClipboard(document: MonitoringDocument) {
    const text = `
Title: ${document.title}\n
Highlight: ${document.sentences[0].text}\n
Source: ${document.source}\n
URL: ${document.url}\n
Date: ${friendlyDateFormat(document.date as string, { outputFormat: "DD MMM YYYY", friendlyWithinDays: 0 })}`;
    navigator.clipboard.writeText(text);
}

export function SignalCardHeader(props: SignalCardProps) {
    const { feedDocument } = props;
    const classes = useStyles({ compact: props.compact || false });
    const library = useDocumentLibraryFromList(feedDocument.libraryId);
    const { setLibraryId } = useFeedViewStore();
    const { setDocumentIds } = useQuestionAnswerStore.getState();
    const { setSelectedTab } = useReaderStore();
    const history = useAIHistory();
    const { mutate: alertFeedbackState } = useAlertsFeedbackState(feedDocument.libraryId);
    const view = useFeedViewStore(state => state.view);
    const dispatch = useDispatch();

    async function handleUserResponseSelect(resp: MonitoringFeedbackState) {
        await alertFeedbackState({
            amplyfiEntityId: feedDocument.amplyfiEntityIds[0],
            currentFilter: view,
            documentId: feedDocument.documentId,
            feedContent: null,
            response: resp.toLowerCase() as UserResponseFeedbackState,
        });

        if (resp === "Discarded") {
            history.pushDocument(null);
        }

        analyticsEvent("Feed", capitalize(FeedViewType.Signal) as EventAction, [feedDocument.documentId, getEventAction(resp)]);
        dispatch(setSnack({ title: `${capitalize(FeedViewType.Signal)} ${getEventAction(resp).toLowerCase()}` }));
    }


    const actions: Action[] = [
        {
            icon: <Stack
                width="100%" height="100%"
                padding={1} paddingLeft={2} paddingRight={2} borderRadius={45} bgcolor="#FFF8EF" direction="horizontal" spacing={1} alignItems="center">
                <ChatBubbleOutlineOutlined color="primary" /> <Typography>{feedDocument.commentsCount}</Typography>
            </Stack>,
            title: "Comments",
            featureFlag: "feedSignalComments",
            onClick: () => {
                history.pushDocument(feedDocument.documentId);
                setLibraryId(library?.id || "");
                setDocumentIds([feedDocument.documentId]);
                setSelectedTab({ id: "comments" });
            },
            disableRipple: true
        },
        {
            icon: <LiveHelpOutlined color="primary" />,
            title: "Investigate",
            onClick: () => {
                history.pushDocument(feedDocument.documentId);
                setLibraryId(library?.id || "");
                setDocumentIds([feedDocument.documentId]);
                setSelectedTab({ id: "investigation" });
            }
        },
        {
            dataTestId: "copy-signal",
            icon: <FileCopyOutlined color="primary" />,
            onClick: () => {
                copySignalToClipboard(feedDocument);
                dispatch(setSnack({ title: "Signal copied to clipboard" }));
            },
            title: "Copy",
            forceMenuItem: "mobile"
        },
        {
            icon: feedDocument.feedbackState === "Favourites" ? (
                <Favorite
                    fontSize="small"
                    className={clsx(feedDocument.feedbackState === "Favourites" && classes.favouritedAlert)}
                />
            ) : (
                <FavoriteBorder htmlColor="#000" fontSize="small" />
            ),
            onClick: () => handleUserResponseSelect(feedDocument.feedbackState === "Favourites" ? "Inbox" : "Favourites"),
            title: feedDocument.feedbackState === "Favourites" ? "Unfavourite" : "Favourite",
        },
        {
            dataTestId: "open-source",
            divider: true,
            icon: <Launch color="action" fontSize="inherit" />,
            onClick: () => window.open(feedDocument.url, "_blank"),
            title: "Open in a new tab",
            forceMenuItem: true,
        },
        {
            icon: <LocalOfferOutlined fontSize="inherit" />,
            dataTestId: "tag-card",
            dialog: <UserTags document={feedDocument} />,
            title: "Tag this signal",
            forceMenuItem: true,
        },
        {
            dataTestId: "block-source",
            icon: <Block fontSize="inherit" />,
            dialog: <BlockSource document={feedDocument} libraryId={library?.id || ""} onClose={() => undefined} />,
            forceMenuItem: true,
            title: (
                <>
                    Don't show content from <strong>{feedDocument.source}</strong>
                </>
            ),
        },
        {
            dataTestId: "block-sectors",
            icon: <DataUsage fontSize="inherit" />,
            dialog: <BlockSectors document={feedDocument} onClose={() => undefined} />,
            title: "Not interested in a sector",
            list: feedDocument.documentSectors,
            forceMenuItem: true,
        },
        {
            dataTestId: "block-topics",
            icon: <Chat fontSize="inherit" />,
            dialog: <BlockTopics document={feedDocument} libraryId={library?.id || ""} onClose={() => undefined} />,
            title: "Not interested in a topic",
            list: feedDocument.documentTopics,
            forceMenuItem: true,
        },
        {
            dataTestId: "block-content-type",
            icon: <Category fontSize="inherit" />,
            dialog: <BlockTypeTags document={feedDocument} libraryId={library?.id || ""} onClose={() => undefined} />,
            title: "Not interested in a content type",
            list: feedDocument.documentTypeTags,
            forceMenuItem: true
        },
        {
            dataTestId: "discard",
            icon: <Delete color="action" fontSize="inherit" />,
            onClick: () => handleUserResponseSelect("Discarded"),
            title: "Discard",
            forceMenuItem: true,
        },
    ]

    return <Box paddingLeft={2} paddingRight={2} display="flex" justifyContent="space-between">
        <Stack spacing={0} direction="horizontal">
            <Typography className={classes.headerText} variant="body2">
                {friendlyDateFormat(props.feedDocument.date as string, { outputFormat: "DD MMM YYYY", friendlyWithinDays: 0 })}
            </Typography>
            <DotSeparator />
            <Typography className={classes.headerText} variant="body2">
                {useDocumentLibraryFromList(props.feedDocument.libraryId)?.name}
            </Typography>
        </Stack>
        <ActionHeader actions={actions} />
    </Box>;
}