import { useInfiniteQuery } from "react-query";
import { useAuth0 } from "../components/Auth0/AuthWrapper";
import { getStartOfPastDayUtc } from "../helpers/dateHelpers";
import { getOrgId } from "../helpers/user";
import {
  FeedResponse,
  getAlertFeed,
  getDirectAlertDocument,
  getLibraryFeed,
  getSubscribedLibrariesFeed,
  FeedSortBy,
  SortOrder,
  UserResponseFeedbackState,
} from "../http/feed";
import { useFeedViewStore } from "../store/hooks/useFeedViewStore";
import { EventsFilter, StrengthFilter, useFiltersStore } from "../store/hooks/useFiltersStore";
import useSearchStore from "../store/hooks/useSearchStore";
import { useQuery } from "./useQuery";
import { FeedViewType } from "../components/Pages/Feed/components/filterMenu/feedFilters";

export const useFeedkey = "subscribed-libraries-feed";

export const getSortingOptions = (view: FeedViewType, selectedSort: string, selectedSortInsights: string): { sortBy: FeedSortBy; sortOrder: SortOrder } => {
  let sortField = "published";
  let sortOrder = "descending";

  const contextSort = view === FeedViewType.Signal ? selectedSort : selectedSortInsights;

  switch (contextSort) {
    case "Newest":
      sortField = "published";
      sortOrder = "descending";
      break;
    case "Oldest":
      sortField = "published";
      sortOrder = "ascending";
      break;
    case "Relevance":
      sortField = "relevancy";
      sortOrder = "descending";
      break;
  }

  return { sortBy: sortField as FeedSortBy, sortOrder: sortOrder as SortOrder };
};

export default function useFeed(
  libraryId: string | null,
  amplyfiEntityId: string | null,
  overrides: {
    feedType?: UserResponseFeedbackState;
    sortBy?: FeedSortBy;
    sortOrder?: SortOrder;
    search?: string;
    signalTypes?: string[];
    strength?: StrengthFilter;
    timeFilter?: string;
    userTags?: string[];
    events?: EventsFilter;
    sectors?: string[];
    sources?: string[];
    topics?: string[];
    startDate?: string;
    endDate?: string;
  } = {}
) {
  const { user } = useAuth0();
  const { view, isFavourite } = useFeedViewStore();
  const { id: feedType, type } = view;
  const search = useSearchStore(({ search }) => search);
  const { events, signalTypes, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics } = useFiltersStore(
    ({ events, signalTypes, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics }) => ({
      events,
      signalTypes,
      selectedSort,
      selectedSortInsights,
      strength,
      timeFilter,
      userTags,
      sectors,
      sources,
      topics,
    })
  );
  const startDate = getStartOfPastDayUtc(timeFilter);
  //map the selectedSort to the sort param
  const { sortBy, sortOrder } = getSortingOptions(type, selectedSort, selectedSortInsights);
  const query = useInfiniteQuery(
    [
      useFeedkey,
      libraryId,
      amplyfiEntityId,
      userTags,
      feedType,
      search,
      signalTypes,
      null,
      startDate,
      selectedSort,
      strength,
      events,
      sectors,
      overrides,
      sources,
      topics,
      selectedSort,
      selectedSortInsights,
      isFavourite
    ],
    async ({ pageParam = null }) => {
      if (!libraryId) {
        return getSubscribedLibrariesFeed(
          getOrgId(user),
          isFavourite ? "favourites" : "inbox",
          pageParam,
          overrides.search || search,
          overrides.signalTypes || signalTypes,
          null,
          overrides.userTags || userTags,
          overrides.strength || strength,
          overrides.events || events,
          overrides.sectors || sectors,
          overrides.sources || sources,
          overrides.topics || topics,
          overrides.sortBy || sortBy,
          overrides.sortOrder || sortOrder,
          overrides.startDate || startDate,
          overrides.endDate,
        );
      }
      return amplyfiEntityId
        ? getAlertFeed(
          getOrgId(user),
          libraryId,
          amplyfiEntityId,
          isFavourite ? "favourites" : "inbox",
          pageParam,
          overrides.search || search,
          signalTypes,
          null,
          overrides.userTags || userTags,
          overrides.strength || strength,
          overrides.events || events,
          overrides.sectors || sectors,
          overrides.sources || sources,
          overrides.topics || topics,
          overrides.sortBy || sortBy,
          overrides.sortOrder || sortOrder,
          overrides.startDate || startDate,
          overrides.endDate
        )
        : getLibraryFeed(
          getOrgId(user),
          libraryId,
          isFavourite ? "favourites" : "inbox",
          pageParam,
          overrides.search || search,
          overrides.signalTypes || signalTypes,
          null,
          overrides.userTags || userTags,
          overrides.strength || strength,
          overrides.events || events,
          overrides.sectors || sectors,
          overrides.sources || sources,
          overrides.topics || topics,
          overrides.sortBy || sortBy,
          overrides.sortOrder || sortOrder,
          overrides.startDate || startDate,
          overrides.endDate
        );
    },
    {
      staleTime: -1,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      getNextPageParam: (lastPage) => lastPage?.paginationToken || undefined,
    }
  );
  return {
    ...query,
    data: query.data?.pages.reduce<FeedResponse>(
      (acc, page) => {
        return {
          ...page,
          documents: [...acc.documents, ...page.documents],
          paginationToken: page.paginationToken,
        };
      },
      {
        documentCount: 0,
        inboxDocumentCount: 0,
        discardedDocumentCount: 0,
        favouriteDocumentCount: 0,
        documents: [],
        paginationToken: null,
      }
    ),
  };
}

export const useFeedCountsKey = "subscribed-libraries-feed-counts";
export const useTotalFeedCountsKey = "subscribed-libraries-total-feed-counts";

export function useFeedCounts(libraryId: string | null, amplyfiEntityId: string | null) {
  const { user } = useAuth0();
  const search = useSearchStore(({ search }) => search);
  const { events, signalTypes, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics } = useFiltersStore(
    ({ events, signalTypes, strength, selectedSort, selectedSortInsights, timeFilter, userTags, sectors, sources, topics }) => ({
      events,
      signalTypes,
      selectedSort,
      selectedSortInsights,
      strength,
      timeFilter,
      userTags,
      sectors,
      sources,
      topics,
    })
  );
  const { view, isFavourite } = useFeedViewStore();
  const { type } = view;
  const startDate = getStartOfPastDayUtc(timeFilter);
  const { sortBy, sortOrder } = getSortingOptions(type, selectedSort, selectedSortInsights);
  return useQuery(
    [
      useFeedCountsKey,
      libraryId,
      amplyfiEntityId,
      isFavourite,
      search,
      signalTypes,
      userTags,
      strength,
      events,
      sectors,
      sources,
      topics,
      startDate,
      selectedSort,
    ],
    async () => {
      if (!libraryId) {
        return getSubscribedLibrariesFeed(
          getOrgId(user),
          isFavourite ? "favourites" : "inbox",
          null,
          search,
          signalTypes,
          null,
          userTags,
          strength,
          events,
          sectors,
          sources,
          topics,
          sortBy,
          sortOrder,
          startDate,
        );
      }

      return amplyfiEntityId
        ? getAlertFeed(
          getOrgId(user),
          libraryId,
          amplyfiEntityId,
          isFavourite ? "favourites" : "inbox",
          null,
          search,
          signalTypes,
          null,
          userTags,
          strength,
          events,
          sectors,
          sources,
          topics,
          sortBy,
          sortOrder,
          startDate
        )
        : getLibraryFeed(
          getOrgId(user),
          libraryId,
          isFavourite ? "favourites" : "inbox",
          null,
          search,
          signalTypes,
          null,
          userTags,
          strength,
          events,
          sectors,
          sources,
          topics,
          sortBy,
          sortOrder,
          startDate
        );
    },
    {
      staleTime: -1,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
    }
  );
}

export function useLatestFeedDocument(libraryId: string | null, amplyfiEntityId: string | null) {
  const { user } = useAuth0();
  const search = useSearchStore(({ search }) => search);
  const { isFavourite } = useFeedViewStore();
  const { events, signalTypes, selectedSort, selectedSortInsights, strength, timeFilter, userTags, sectors, sources, topics } = useFiltersStore(
    ({ events, signalTypes, strength, selectedSort, selectedSortInsights, timeFilter, userTags, sectors, sources, topics }) => ({
      events,
      signalTypes,
      selectedSort,
      selectedSortInsights,
      strength,
      timeFilter,
      userTags,
      sectors,
      sources,
      topics,
    })
  );
  const { id, type } = useFeedViewStore(({ view }) => view);
  const startDate = getStartOfPastDayUtc(timeFilter);
  const { sortBy, sortOrder } = getSortingOptions(type, selectedSort, selectedSortInsights);
  return useQuery(
    [
      useFeedCountsKey,
      libraryId,
      amplyfiEntityId,
      id,
      search,
      signalTypes,
      userTags,
      strength,
      events,
      sectors,
      sources,
      topics,
      startDate,
      selectedSort,
      isFavourite
    ],
    async () => {
      if (!libraryId) {
        return getSubscribedLibrariesFeed(
          getOrgId(user),
          isFavourite ? "favourites" : "inbox",
          null,
          search,
          signalTypes,
          null,
          userTags,
          strength,
          events,
          sectors,
          sources,
          topics,
          sortBy,
          sortOrder,
          startDate,
        );
      }

      return amplyfiEntityId
        ? getAlertFeed(
          getOrgId(user),
          libraryId,
          amplyfiEntityId,
          isFavourite ? "favourites" : "inbox",
          null,
          search,
          signalTypes,
          null,
          userTags,
          strength,
          events,
          sectors,
          sources,
          topics,
          sortBy,
          sortOrder,
          startDate,
        )
        : getLibraryFeed(
          getOrgId(user),
          libraryId,
          isFavourite ? "favourites" : "inbox",
          null,
          search,
          signalTypes,
          null,
          userTags,
          strength,
          events,
          sectors,
          sources,
          topics,
          sortBy,
          sortOrder,
          startDate,
        );
    },
    {
      staleTime: 30 * 1000,
      refetchInterval: (30 * 1000) + 1,
    }
  );
}

export function useTotalFeedCounts(
  libraryId: string | null,
  amplyfiEntityId: string | null,
  startDate?: string,
  endDate?: string
) {
  const { user } = useAuth0();
  const id: UserResponseFeedbackState = "inbox";

  return useQuery(
    [useTotalFeedCountsKey, libraryId, amplyfiEntityId, id, startDate, endDate],
    async () => {
      if (!libraryId) {
        return getSubscribedLibrariesFeed(
          getOrgId(user),
          id,
          null,
          null,
          [],
          null,
          [],
          null,
          null,
          [],
          [],
          [],
          "published",
          "descending",
          startDate,
          endDate,
        );
      }

      return amplyfiEntityId
        ? getAlertFeed(
          getOrgId(user),
          libraryId,
          amplyfiEntityId,
          id,
          null,
          null,
          [],
          null,
          [],
          null,
          null,
          [],
          [],
          [],
          "published",
          "descending",
          startDate,
          endDate,
        )
        : getLibraryFeed(
          getOrgId(user),
          libraryId,
          id,
          null,
          null,
          [],
          null,
          [],
          null,
          null,
          [],
          [],
          [],
          "published",
          "descending",
          startDate,
          endDate,
        );
    },
    {
      staleTime: -1,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
    }
  );
}

export const useFeedDocumentKey = "libraries-feed-document";

export function useFeedDocument(libraryId: string | null, documentId: string, disablePreload = false) {
  const { user } = useAuth0();
  return useQuery([useFeedDocumentKey, documentId], async () =>
    getDirectAlertDocument(getOrgId(user), documentId)
    , { enabled: !disablePreload });
}
