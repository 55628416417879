import AiChip from "@amplyfi/ui-components/components/Chip";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Delete, Done, Help, MoreHoriz } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import Image, { ImageProps } from "./Image";

const useStyles = makeStyles<Theme, EditableListItemProps>(({ palette, spacing }) => ({
  listItem: ({ compact, disabled, onChecked, onClick }) => ({
    display: "flex",
    opacity: disabled ? 0.5 : 1,
    paddingTop: compact ? spacing(1) : undefined,
    paddingBottom: compact ? spacing(1) : undefined,
    cursor: (onClick || onChecked) && !disabled ? "pointer" : "default",
    "@media (hover: hover)": {
      "&:hover": {
        backgroundColor: disabled ? "inherit" : "rgba(0, 79, 208, .03)",
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: spacing(4),
    },
    "&.MuiListItem-root.Mui-selected": {
      "& p": { color: "#004fd0" },
      backgroundColor: "rgba(0, 79, 208, .03)",
      "@media (hover: hover)": {
        "&:hover": {
          backgroundColor: "rgba(0, 79, 208, .03)",
        },
      },
    },
  }),
  selectedListItem: {},
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    maxHeight: 80,
  },
  imageContainerCompact: {
    minWidth: 32,
    maxHeight: 32,
    width: 44,
    "& > *": {
      maxHeight: 32,
      width: 32,
    },
  },
  image: {
    maxWidth: "100%",
  },
  endIcon: {
    width: spacing(5.75),
    zIndex: 90,
    alignSelf: "center",
    justifyContent: "flex-end",
  },
  endIconButtonCompact: {
    padding: spacing(0.5),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  count: {
    marginLeft: "auto",
    paddingLeft: spacing(1),
    textAlign: "right",
  },
  countChecked: {
    color: "#004fd0",
    fontWeight: "bold",
  },
}));

interface EditableListItemContextOption {
  label: string;
  onClick: () => void;
}

interface EditableListItemLabel {
  name: string;
  icon?: JSX.Element;
  hoverContent?: string;
}

interface EditableListItemProps extends ListItemProps {
  id?: string;
  title: string;
  count?: React.ReactNode;
  image?: string | JSX.Element;
  imageProps?: Partial<ImageProps>;
  labels?: EditableListItemLabel[] | EditableListItemLabel | string[];
  checked?: boolean;
  onChecked?: (checked: boolean) => void;
  description?: string | string[];
  onDelete?: (value?: string) => void;
  loading?: boolean;
  success?: boolean;
  error?: boolean;
  errorString?: string;
  imageContainerClass?: string;
  displayErrorAlt?: boolean;
  contextOptions?: EditableListItemContextOption[];
  compact?: boolean;
  actions?: JSX.Element[];
  reserveOnDeleteSpace?: boolean;
  tooltip?: string;
}

function showIcon(loading: boolean | undefined, success: boolean | undefined, error: boolean | undefined) {
  return !loading && success === undefined && error === undefined;
}

function ImageElement(props: EditableListItemProps): JSX.Element | null {
  const { image, displayErrorAlt, title, compact, imageProps } = props;
  const classes = useStyles(props);
  if (image === undefined && !displayErrorAlt) {
    return null;
  }

  return (
    <ListItemAvatar className={clsx(compact && classes.imageContainerCompact)}>
      <div className={clsx(classes.imageContainer, props.imageContainerClass, compact)}>
        {React.isValidElement(image) && image ? (
          image
        ) : (
          <Image
            displayErrorAlt={displayErrorAlt}
            className={classes.image}
            alt={title}
            src={image as string}
            {...imageProps}
          />
        )}
      </div>
    </ListItemAvatar>
  );
}

export default function EditableListItem(props: EditableListItemProps) {
  const {
    id,
    title,
    count,
    image,
    description = [],
    onChecked,
    checked,
    labels = [],
    onDelete,
    onClick,
    loading,
    success,
    error,
    errorString,
    imageContainerClass,
    displayErrorAlt,
    contextOptions,
    compact,
    actions,
    disabled = false,
    children,
    reserveOnDeleteSpace = false,
    tooltip,
    ...rest
  } = props;
  const classes = useStyles(props);

  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorTarget, setAnchor] = useState<HTMLButtonElement | null>(null);

  function handleDelete() {
    if (onDelete) {
      onDelete(id);
    }
  }

  const lbl = Array.isArray(labels) ? labels : [labels];

  const handleClick =
    !onClick && !!onChecked
      ? () => {
        onChecked?.(!checked);
      }
      : onClick;

  return (
    <Tooltip title={tooltip || ""}>
      <ListItem
        {...rest}
        onClick={disabled ? undefined : handleClick}
        disabled={disabled}
        classes={{ selected: classes.selectedListItem }}
        className={clsx(classes.listItem, props.className)}
        button={undefined}
      >
        <>
          {onChecked && (
            <ListItemIcon>
              <Checkbox
                disabled={disabled}
                onChange={(e) => onChecked(e.target.checked)}
                edge="start"
                checked={checked}
              />
            </ListItemIcon>
          )}

          <ImageElement {...props} />

          <ListItemText
            style={{ flex: "1 1 auto" }}
            disableTypography
            primary={
              <Typography variant="body1" className={classes.title}>
                {title}
                {count !== undefined && (
                  <span className={clsx(classes.count, checked && classes.countChecked)}>{count}</span>
                )}
              </Typography>
            }
            secondary={
              <>
                {(typeof description === "string" ? [description] : description || []).map((d) => (
                  <Typography
                    style={{ textOverflow: "ellipsis", overflow: "hidden", overflowWrap: "anywhere" }}
                    variant="body2"
                    color="textSecondary"
                    key={d}
                  >
                    {d}
                  </Typography>
                ))}
                {lbl.length > 0 && (
                  <div>
                    {lbl.map((l) => (
                      <AiChip
                        key={typeof l === "string" ? l : l.name}
                        title={typeof l === "string" ? undefined : l.hoverContent}
                        style={{ marginRight: 8 }}
                        icon={typeof l === "string" ? undefined : l.icon}
                        color="secondary"
                        label={typeof l === "string" ? l : l.name}
                      />
                    ))}
                  </div>
                )}
              </>
            }
          />
        </>

        {showIcon(loading, success, error) && !disabled && actions}

        {children}

        {reserveOnDeleteSpace && !onDelete && !disabled && <ListItemIcon className={classes.endIcon} />}
        {onDelete && showIcon(loading, success, error) && !disabled && (
          <ListItemIcon
            className={classes.endIcon}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconButton className={compact ? classes.endIconButtonCompact : ""} onClick={handleDelete}>
              <Delete style={{ zIndex: 90 }} />
            </IconButton>
          </ListItemIcon>
        )}

        {!showIcon(loading, success, error) && (
          <>
            {loading && (
              <ListItemIcon className={classes.endIcon}>
                <AiCircularProgress useContainer={false} size={20} />
              </ListItemIcon>
            )}
            {success && (
              <ListItemIcon className={classes.endIcon}>
                <Done />
              </ListItemIcon>
            )}
            {error && errorString && (
              <ListItemIcon className={classes.endIcon}>
                <Tooltip title={errorString}>
                  <Help />
                </Tooltip>
              </ListItemIcon>
            )}
          </>
        )}
        {!disabled && contextOptions && contextOptions.length > 0 && (
          <>
            <IconButton
              onClick={(e) => {
                setAnchor(e.currentTarget);
                setMenuOpen(true);
              }}
            >
              <MoreHoriz />
            </IconButton>
            <Menu anchorEl={anchorTarget} open={menuOpen} onClose={() => setMenuOpen(false)}>
              {contextOptions.map(({ label, onClick }, i) => (
                <MenuItem
                  key={`option-${label}-${i}`}
                  onClick={() => {
                    onClick();
                    setMenuOpen(false);
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </ListItem>
    </Tooltip>
  );
}
