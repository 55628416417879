import { Action } from "@reduxjs/toolkit";
import { call, ForkEffect, takeLatest } from "redux-saga/effects";
import { convertSearchStateToSearchRequestV2 } from "../../helpers/search";
import { ParsedQueryParams } from "../../models/queryParams";
import { getParsedQueryParams } from "../reducers/searchRequest/url-functions";
import { callAnalyseApi } from "./page-apis";
import { maintainFiltersActions, resetFilterActions } from "./search-saga-actions";

function* analyseSaga(action: Action) {
  document.body.style.cursor = "default";
  const urlState: ParsedQueryParams = getParsedQueryParams();
  const resetFilters = !maintainFiltersActions.includes(action.type);
  const request = convertSearchStateToSearchRequestV2(urlState);

  yield call(callAnalyseApi, request, resetFilters);
}

export default function* takeLatestSearch(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest([...resetFilterActions, ...maintainFiltersActions], analyseSaga);
}
