import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAnalyseSelector } from "../../store/reducers";
import TextSearch from "@amplyfi/ui-components/components/TextSearch/index";
import { PastelPurple } from "@amplyfi/ui-components/theme/colors";
import { zIndex } from "../../helpers/componentsZIndex";
import { DOCUMENT_ID } from "./Document";
import {
  setCurrentSearchStringPosition,
  setLoadingSearchMatches,
  setSearchString,
  setTotalSearchStringMatches,
} from "../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import { useDispatch } from "react-redux";

interface DocumentTextSearchProps {
  reloadOnChange?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      "&>div": {
        borderRadius: "6px 0 0 6px",
      },
    },
    markHighlight: {
      backgroundColor: PastelPurple,
    },
    textSearch: {
      backgroundColor: theme.palette.componentBackground.main,
      width: "100%",
    },
  })
);

const searchResultId = (id: number): string => `${DOCUMENT_ID}__highlighted-text-${id}`;

export default function DocumentTextSearch({ reloadOnChange }: DocumentTextSearchProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    selectedDocumentId,
    totalSearchStringMatches,
    currentSearchStringPosition,
    loadingSearchMatches,
    searchString,
  } = useAnalyseSelector((x) => x.searchResponse.relatedDocumentViewer);
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState("");

  const handleInputEnter = (text: string) => {
    if (text !== searchText) {
      setSearchText(text ?? "");
    }
  };

  const clearVals = () => {
    setSearchText("");
    setValue("");
  };

  const handleClear = () => {
    dispatch(setCurrentSearchStringPosition(undefined));
    dispatch(setLoadingSearchMatches(false));
    clearVals();
  };

  const getElement = (id: number): HTMLElement | null => document.querySelector(`#${searchResultId(id)}`);

  const elementInViewport = (rect: DOMRect, element: Element) => {
    return rect.top >= 0 && rect.bottom <= (element.clientHeight || document.documentElement.clientHeight);
  };

  const handlePrev = () => {
    if (currentSearchStringPosition === undefined) return;
    if (currentSearchStringPosition === 1) {
      dispatch(setCurrentSearchStringPosition(totalSearchStringMatches));
    } else {
      dispatch(setCurrentSearchStringPosition(currentSearchStringPosition - 1));
    }
  };

  const handleNext = () => {
    if (currentSearchStringPosition === undefined) return;
    if (currentSearchStringPosition === totalSearchStringMatches) {
      dispatch(setCurrentSearchStringPosition(1));
    } else {
      dispatch(setCurrentSearchStringPosition(currentSearchStringPosition + 1));
    }
  };

  useEffect(() => {
    dispatch(setSearchString(searchText));
    dispatch(setCurrentSearchStringPosition(undefined));
    dispatch(setTotalSearchStringMatches(0));
    dispatch(setLoadingSearchMatches(true));
  }, [dispatch, searchText]);

  useEffect(() => {
    clearVals();
    dispatch(setCurrentSearchStringPosition(undefined));
    dispatch(setTotalSearchStringMatches(0));
    dispatch(setLoadingSearchMatches(false));
  }, [dispatch, selectedDocumentId]);

  useEffect(() => {
    if (searchString) {
      dispatch(setCurrentSearchStringPosition(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadOnChange, dispatch]);

  useEffect(() => {
    if (currentSearchStringPosition !== undefined && searchString) {
      const mark = getElement(currentSearchStringPosition);
      if (!!mark) {
        const rect = mark.getBoundingClientRect();
        const element = document.querySelector(`[data-testid='${DOCUMENT_ID}']`);
        if (!!element && !elementInViewport(rect, element)) {
          element.scrollTo({ top: mark.offsetTop, behavior: "smooth" });
        }
      }
    }
  }, [currentSearchStringPosition, searchString, totalSearchStringMatches]);

  return (
    <div className={classes.container}>
      <TextSearch
        label="Search by keyword"
        focusedLabel="Type and hit enter"
        onEnter={handleInputEnter}
        currentPosition={currentSearchStringPosition}
        totalCount={totalSearchStringMatches}
        handleNext={handleNext}
        handlePrev={handlePrev}
        className={classes.textSearch}
        loading={loadingSearchMatches}
        onClear={handleClear}
        tooltipZIndex={zIndex.modalAndMenu}
        value={value}
        setValue={setValue}
      />
    </div>
  );
}
