import AiButton from "@amplyfi/ui-components/components/Button";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { HarvestConfig } from "../models";

interface HarvestQueryExamplesProps {
  setQuery: (query: string) => void;
  harvestConfig: HarvestConfig;
}

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    label: {
      display: "flex",
      marginTop: spacing(1),
    },
    list: {
      display: "flex",
      padding: 0,
      "& li": {
        display: "inline-block",
        paddingRight: spacing(2),
        width: 200,
      },
    },
    example: {
      color: "#000",
      display: "block",
      border: "1px solid #c0c0c0",
      boxShadow: `0 0 6px ${palette.borders.dark}`,
      padding: spacing(1),
      "&.MuiButton": {
        color: "inherit",
      },
      "& .MuiButton-label": {
        fontWeight: "normal",
        fontSize: 10,
        textAlign: "left",
      },
    },
  })
);

export const HarvestQueryExamples = ({
  setQuery,
  harvestConfig: {
    oneOffConfiguration: { examples, examplesDescription },
  },
}: HarvestQueryExamplesProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section>
      <Typography variant="h5" className={classes.label}>
        {examplesDescription}:
      </Typography>
      <ol className={classes.list}>
        {examples?.map(({ label, content }) => (
          <li key={content}>
            <AiButton
              amplyfiType="tertiary"
              className={classes.example}
              onClick={() => setQuery(content)}
              type="button"
              variant="outlined"
            >
              {label}
            </AiButton>
          </li>
        ))}
      </ol>
    </section>
  );
};
