import { AccountAmplyfiUser, AccountAmplyfiUserPolicies, AmplyfiUser } from "../models/user";
import { accountClient } from "./accounts";

export interface Group {
  description?: string;
  id: string;
  name: string;
}

export async function createUser(organisationId: string, name: string, email: string): Promise<{ user_id: string }> {
  const { data } = await accountClient.post<{ user_id: string }>(`/organisations/${encodeURI(organisationId)}/users/`, {
    name,
    email,
  });
  return data;
}

export async function deleteUser(organisationId: string, userId: string): Promise<void> {
  const { data } = await accountClient.delete<void>(
    `/organisations/${encodeURI(organisationId)}/users/${encodeURI(userId)}/`
  );
  return data;
}

async function _addUserToGroup(organisationId: string, userId: string, groupId: string): Promise<void> {
  return accountClient.put(
    `/organisations/${encodeURI(organisationId)}/groups/${encodeURI(groupId)}/users/${encodeURI(userId)}/`
  );
}

export async function addUserToGroups(
  organisationId: string,
  userId: string,
  groupIds: string[]
): Promise<AmplyfiUser | void[]> {
  return Promise.all(groupIds.map((groupId) => _addUserToGroup(organisationId, userId, groupId)));
}

async function _removeUserFromGroup(organisationId: string, userId: string, groupId: string): Promise<void> {
  await accountClient.delete(
    `/organisations/${encodeURI(organisationId)}/groups/${encodeURI(groupId)}/users/${encodeURI(userId)}/`
  );
}

export async function removeUserFromGroups(
  organisationId: string,
  userId: string,
  groupIds: string[]
): Promise<(boolean | void)[]> {
  return Promise.all(groupIds.map((groupId) => _removeUserFromGroup(organisationId, userId, groupId)));
}

function transformUsers(users: AccountAmplyfiUser[]): AmplyfiUser[] {
  return users.map(({ user_id, ...u }) => ({ ...u, userId: user_id })) as AmplyfiUser[];
}

export interface UsersResponse {
  users: AmplyfiUser[];
  count: number;
  total_pages: number;
}

export async function getUsers(organisationId: string, page = 1, q = "", size = 25): Promise<UsersResponse> {
  const params = { page, q, size };
  const {
    data: { users, total_items, ...rest },
  } = await accountClient.get<{ users: AccountAmplyfiUser[]; total_items: number; total_pages: number }>(
    `/organisations/${encodeURI(organisationId)}/users/`,
    { params }
  );
  return { users: transformUsers(users), count: total_items, ...rest };
}

export async function getUser(organisationId: string, userId: string | undefined): Promise<AmplyfiUser | undefined> {
  if (!userId) {
    return undefined;
  }
  const {
    data: { user_id, last_login, logins_count, ...data },
  } = await accountClient.get<AmplyfiUser & { user_id: string; last_login: string; logins_count: number }>(
    `/organisations/${encodeURI(organisationId)}/users/${encodeURI(userId)}/`
  );
  return { ...data, userId: user_id, lastLogin: last_login, loginsCount: logins_count };
}

export async function getUserPolicies(
  organisationId: string,
  userId: string | undefined
): Promise<AccountAmplyfiUserPolicies | undefined> {
  if (!userId) {
    return undefined;
  }
  const { data } = await accountClient.get<AccountAmplyfiUserPolicies>(
    `/organisations/${encodeURI(organisationId)}/users/${encodeURI(userId)}/policies/`
  );
  return data;
}

export async function createGroup(organisationId: string, name: string, description: string): Promise<void> {
  const { data } = await accountClient.post(`/organisations/${encodeURI(organisationId)}/groups/`, {
    name,
    description,
  });
  return data;
}

export async function getGroupMembers(
  organisationId: string,
  groupId: string,
  page = 0
): Promise<{ users: AmplyfiUser[]; count: number }> {
  const params = {
    page: page ? page + 1 : undefined,
  };

  const {
    data: { users, total_items, ...rest },
  } = await accountClient.get<{ users: AccountAmplyfiUser[]; total_items: number }>(
    `/organisations/${encodeURI(organisationId)}/groups/${encodeURI(groupId)}/users/`,
    { params }
  );
  return { ...rest, users: transformUsers(users), count: total_items };
}

export async function getNestedGroups(organisationId: string): Promise<Group[]> {
  const {
    data: { groups = [] },
  } = await accountClient.get<{
    total_items: number;
    groups: { group_id: string; name: string; description: string; organisation_id: string }[];
  }>(`/organisations/${encodeURI(organisationId)}/groups/`);

  return groups.map(({ group_id, ...group }) => ({ ...group, id: group_id }));
}

export async function addUsersToGroup(organisationId: string, groupId: string, userIds: string[]): Promise<void> {
  await Promise.all(userIds.map((userId) => _addUserToGroup(organisationId, userId, groupId)));
  return;
}

export async function getGroup(organisationId: string, groupId: string): Promise<Group> {
  const {
    data: { group_id, ...rest },
  } = await accountClient.get<{
    group_id: string;
    name: string;
    description: string;
    organisation_id: string;
  }>(`/organisations/${encodeURI(organisationId)}/groups/${encodeURI(groupId)}/`);
  return { ...rest, id: group_id };
}

export async function deleteGroup(groupId: string, organisationId: string): Promise<void | Group> {
  await accountClient.delete(`/organisations/${encodeURI(organisationId)}/groups/${encodeURI(groupId)}/`);
}
