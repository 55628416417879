import { ActionCreatorWithPayload, ActionCreatorWithoutPayload, PayloadAction } from "@reduxjs/toolkit";
import { DEBOUNCE_DELAY_DEFAULT, DEBOUNCE_DELAY_KEYWORD } from "../../helpers/debounceHelpers";
import { EntitiesSearchResponse, TopicsSearchResponse } from "../../models/filter";
import { EntityType, PositionedEntity } from "../../models/entity";
import { ForkEffect, call, debounce, put, select } from "redux-saga/effects";

import { AppState } from "../reducers";
import { ParsedQueryParams } from "../../models/queryParams";
import { PositionedKeyPhrase } from "../../models/keyPhrase";
import { convertSearchStateToSearchRequestV2 } from "../../helpers/search";
import { logError } from "../../helpers/logger";
import { searchEntitiesV2 } from "../../http/connectedEntities";
import { searchKeyPhrases } from "../../http/connectedKeyPhrases";
import { toSentenceCase } from "../../helpers/textHelper";

export function getKeyPhrasesSearchSaga(
  onLoading: ActionCreatorWithPayload<string>,
  onError: ActionCreatorWithoutPayload<string>,
  onSuccess: ActionCreatorWithPayload<PositionedKeyPhrase[], string>,
  onReset: ActionCreatorWithoutPayload<string>
): () => Generator<ForkEffect<never>, void, unknown> {
  function* searchKeyPhrasesSaga(action: PayloadAction<string | null>) {
    const searchTerm = action.payload;

    if (!searchTerm) {
      yield put(onReset());
    } else {
      try {
        const parsed: ParsedQueryParams = yield select((state: AppState) => state.searchRequest.url.parsed);
        const searchRequest = convertSearchStateToSearchRequestV2(parsed);
        searchRequest.systemFilters = {
          ...searchRequest.systemFilters,
          keyPhraseCount: 1000,
        };
        const topicsWithSearchTerm: TopicsSearchResponse = yield call(searchKeyPhrases, searchRequest, searchTerm);
        yield put(
          onSuccess(topicsWithSearchTerm.matches.map((topic) => ({ ...topic, name: toSentenceCase(topic.name) })))
        );
      } catch (e) {
        logError(e);
        yield put(onError());
      }
    }
  }

  return function* debouncedKeywordSearch() {
    yield debounce(DEBOUNCE_DELAY_KEYWORD, onLoading.type, searchKeyPhrasesSaga);
  };
}

export function getEntitiesSearchSaga(
  onLoading: ActionCreatorWithPayload<string>,
  onError: ActionCreatorWithoutPayload<string>,
  onSuccess: ActionCreatorWithPayload<PositionedEntity[], string>,
  onReset: ActionCreatorWithoutPayload<string>
): () => Generator<ForkEffect<never>, void, unknown> {
  function* searchEntitiesSaga(action: PayloadAction<string | null>) {
    const searchTerm = action.payload;
    if (!searchTerm) {
      yield put(onReset());
    } else {
      try {
        const parsed: ParsedQueryParams = yield select((state: AppState) => state.searchRequest.url.parsed);
        const searchRequest = convertSearchStateToSearchRequestV2(parsed);
        searchRequest.systemFilters.entitiesCount = 1000;
        const topicsWithSearchTerm: EntitiesSearchResponse = yield call(
          searchEntitiesV2,
          searchRequest,
          searchTerm,
          parsed.libraries
        );
        const results = Object.entries(topicsWithSearchTerm).flatMap(([key, value]) => {
          return value.matches.map<PositionedEntity>((x) => ({
            id: x.id,
            name: x.name,
            prevalence: x.prevalence,
            type: key as EntityType,
          }));
        });
        yield put(onSuccess(results));
      } catch (e) {
        logError(e);
        yield put(onError());
      }
    }
  }

  return function* debouncedEntitiesSearch() {
    yield debounce(DEBOUNCE_DELAY_DEFAULT, onLoading.type, searchEntitiesSaga);
  };
}
