import React, { useState } from "react";
import { getCurrentSearchRequestV2 } from "../../helpers/search";
import { LibrariesType } from "../../models/queryParams";
import { SearchRequestV2 } from "../../models/search";
import { getParsedQueryParams } from "../../store/reducers/searchRequest/url-functions";
import ExportBaseButton from "./ExportBaseButton";

export function ExportButtonV2(props: {
  getExport: (searchRequest: SearchRequestV2, librariesTypes: LibrariesType[]) => Promise<void>;
  override?: Partial<SearchRequestV2>;
}): JSX.Element {
  const { getExport, override = {} } = props;
  const [loading, setLoading] = useState(false);
  return (
    <ExportBaseButton
      onClick={() => {
        setLoading(true);
        const request = getCurrentSearchRequestV2();
        const { libraries } = getParsedQueryParams();
        getExport(
          {
            ...request,
            ...override,
          },
          libraries
        ).then(() => {
          setLoading(false);
        });
      }}
      loading={loading}
    />
  );
}
