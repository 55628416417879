import { Datum, Serie } from "@nivo/line";
import { TimePeriod } from "../models/chart";
import { DocumentType } from "../models/search";
import { TimelinePoint } from "../models/keyPhrase";
import { mapDataTypeToColor } from "./mapColorToDataType";
import { TimeIntervalType } from "./timeIntervals";

function mapDocumentLineData(data: TimelinePoint[]): Datum[] {
  return data.map((point) => ({
    x: point.startDate.slice(0, 10),
    y: point.documentCount,
  }));
}

export interface NivoData {
  nivoDatasets: Serie[];
  nivoColours: string[];
}

export function convertTimelineToNivoData(
  docType: DocumentType,
  data: TimelinePoint[],
  skewCorrection: boolean
): NivoData {
  const colour = mapDataTypeToColor[docType];

  if (skewCorrection) {
    return {
      nivoColours: [colour],

      nivoDatasets: [
        {
          id: docType,

          data: mapDocumentLineData(getSkewCorrection(data)),
        },
      ],
    };
  }

  return {
    nivoColours: [colour],
    nivoDatasets: [
      {
        id: docType,
        data: mapDocumentLineData(data),
      },
    ],
  };
}

export interface NivoData {
  nivoDatasets: Serie[];
  nivoColours: string[];
}

function getSkewCorrection(timeline: TimelinePoint[]) {
  return timeline.map((point) => ({
    ...point,
    documentCount: point.corpusSignificance,
  }));
}

export const timePeriodTickValuesMap: Record<TimePeriod, string> = {
  "3months": "every month",
  "6months": "every month",
  "1year": "every 2 months",
  "3years": "every year",
  "5years": "every year",
};

export const timePeriodFormatMap: Record<TimePeriod, string> = {
  "3months": "%Y-%m",
  "6months": "%Y-%m",
  "1year": "%Y-%m",
  "3years": "%Y",
  "5years": "%Y",
};

const nivoUnitMap: Record<TimeIntervalType, string> = {
  Day: "%b %d",
  Month: "%b %Y",
  Quarter: "Q%q %Y",
  Week: "%b %d, %Y",
  Year: "%Y",
};

const nivoTickMap: Record<TimeIntervalType, string> = {
  Day: "every day",
  Month: "every month",
  Quarter: "every 3 months",
  Week: "every week",
  Year: "every year",
};

export function getNivoTimeUnitFromTimeInterval(interval: TimeIntervalType): string {
  return nivoUnitMap[interval];
}

export function getNivoTimeUnitTicks(interval: TimeIntervalType): string {
  return nivoTickMap[interval];
}

export const DOCUMENT_TIMELINE_HEIGHT = 240;
