import { join } from "../../../helpers/url";
import { useFeedViewStore } from "../../../store/hooks/useFeedViewStore";
import useReaderStore from "../../../store/hooks/useReaderStore";
import useOrgId from "../../useOrgId";

interface Options {
  useOrganisation?: boolean;
  useLibrary?: boolean;
  useReaderLibrary?: boolean;
}

export default function useAPI(
  version = "v1",
  endpoint: string,
  { useOrganisation = true, useLibrary = false }: Options = {}
) {
  const orgId = useOrgId();
  const libraryId = useFeedViewStore((state) => state.libraryId);
  const readerLibrary = useReaderStore((state) => state.selectedLibraryId);

  return join(
    `${process.env.REACT_APP_ANALYSE_API}`,
    version,
    ...(useOrganisation ? ["organisations", orgId] : []),
    ...(useLibrary ? ["libraries", (libraryId || readerLibrary) as string] : []),
    endpoint
  );
}
