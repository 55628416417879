import { useEffect } from "react";

interface ValidationOptions {
  within?: string[];
  sameAs?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ValidationFn = (value: string | any[], opts?: ValidationOptions) => boolean;

const validation: { [key: string]: { fn: ValidationFn; message: string } } = {
  minLength: {
    fn: (text) => (text as string).trim().length > 0,
    message: "must be at least 1 character",
  },
  sameAs: {
    fn: (text, { sameAs = "" } = {}) => text === sameAs,
    message: "passwords must match",
  },
  isUnique: {
    fn: (text, { within = [] } = {}) => !within.includes(text as string),
    message: "must be unique",
  },
  listMinLength: {
    fn: (list) => list.length > 0,
    message: "must have at least one item",
  },
  minLengthPassword: {
    fn: (text) => text.length >= 8,
    message: "must be at least 8 characters",
  },
  lowerCase: {
    fn: (text) => /[a-z]/g.test(text as string),
    message: "must contain a lower case letter (a-z)",
  },
  upperCase: {
    fn: (text) => /[A-Z]/g.test(text as string),
    message: "must contain an upper case letter (A-Z)",
  },
  number: {
    fn: (text) => /[0-9]/g.test(text as string),
    message: "must contain a number",
  },
  special: {
    fn: (text) => /[!@#$%^&*(),.?":{}|<>]/g.test(text as string),
    message: "must contain a special character (!@#$ etc)",
  },
  website: {
    // url validation with mandatory protocol
    fn: (text) => /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/g.test(text as string),
    message: "must be a valid website",
  },
  websiteOptionalProtocol: {
    // url validation with optional protocol
    fn: (text) => /^((http(s)?:\/\/)?)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/g.test(text as string),
    message: "must be a valid website",
  },
  email: {
    fn: (text) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(text as string),
    message: "must be a valid email",
  },
};

interface ValidationOptionProps {
  message: string;
  fn: ValidationFn;
  text?: string;
}

export interface ValidationResult {
  valid: boolean;
  message: string;
}

interface ValidationResponse {
  valid: boolean;
  validationMessages: ValidationResult[];
}

export default function useValidation(
  validationOptions: string[],
  customValidation: ValidationOptionProps[],
  validationValues: Parameters<ValidationFn>,
  onValidationChange?: (valid: boolean, validations: ValidationResult[]) => void
): ValidationResponse {
  const validations = [...validationOptions.map((opt) => validation[opt]), ...(customValidation || [])].map((opt) => {
    return {
      valid: opt.fn(...validationValues),
      message: opt.message,
    };
  });

  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(
        validations.every((v) => v.valid),
        validations
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationValues]);

  return {
    valid: validations.every((v) => v.valid),
    validationMessages: validations,
  };
}
