import { getParsedQueryParams } from "../store/reducers/searchRequest/url-functions";
import { Entity } from "../models/entity";
import moment from "moment";

export function getSearchQuery(): string {
  const { any, all, none, startDate, endDate } = getParsedQueryParams();
  const statements = [];
  statements.push("--------------------------Search query metadata--------------------------");
  statements.push(`Search Url: ${window.location.href}`);

  if (any.length > 0) {
    statements.push(`Any of:\n\n${getEntities(any)}`);
  }

  if (all.length > 0) {
    statements.push(`All of:\n\n${getEntities(all)}`);
  }

  if (none.length > 0) {
    statements.push(`None of:\n\n${getEntities(none)}`);
  }

  statements.push(`Date Range: ${moment(startDate).format("DD/MM/YY")} - ${moment(endDate).format("DD/MM/YY")}`);

  return statements.join("\n\n");
}
function getEntities(entities: Entity[]) {
  return entities.map(({ name, type }) => `Name: ${name}\n${type !== undefined ? `Type: ${type}` : ""}`).join("\n\n");
}
