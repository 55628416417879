import { getUser } from "../http/user-management";
import { useQuery } from "./useQuery";

export default function useUser(organisationId: string, userId: string | undefined) {
  return useQuery(["user-account", userId], () => getUser(organisationId, userId), {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
    retry(failureCount, error: { response: { status: number } }) {
      if (error.response.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
  });
}
