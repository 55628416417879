import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  "@keyframes ellipsis": {
    "0%": {
      width: 0,
    },
    "100%": {
      width: 42,
    },
  },
  root: {
    "&:after": {
      overflow: "hidden",
      display: "inline-block",
      verticalAlign: "bottom",
      animation: "$ellipsis steps(4, end) 900ms infinite",
      content: "'...'",
      fontSize: 40,
      width: 0,
    },
  },
}));

interface AnimatedEllipsisProps {
  size?: number;
}

export default function AnimatedEllipsis(props: AnimatedEllipsisProps) {
  const classes = useStyles({ size: props.size });
  return <span className={classes.root}></span>;
}
