import { useContext } from "react";
import { WizardContext } from "../contexts/WizardContext";

export default function useWizardState<T>(wizardId: string, defaultState?: T) {
  const [state, updateState] = useContext(WizardContext);

  if (!state[wizardId] && defaultState) {
    updateState(wizardId, defaultState);
    return [defaultState, (state: T) => updateState(wizardId, state)] as const;
  }

  return [(state[wizardId] || {}) as T, (state: T) => updateState(wizardId, state)] as const;
}
