import axios from "axios";
import { Report } from "../../../components/Pages/Feed/components/reader/create_report/CreateReportWizard";
import useAPI from "../../queries/api/useAPI";
import useMutation from "../../useMutation";

export default function useCreateReport(amplyfiEntityId: string) {
  const api = useAPI("v5", `amplyfi-entities/${amplyfiEntityId}/report`, { useLibrary: true, useOrganisation: false });

  return useMutation<Report>(
    async (report) => {
      const { data } = await axios.post<Report>(api, report);
      return data;
    },
    ["create-reports"]
  );
}
