import { Route } from "react-router-dom";
import { separator } from "../routing";
import EntitiesPage from "./EntitiesPage";

const root = "entity-knowledge-base";

export const goToEntityKnowledgeBase = (): string => ["", root].join(separator);
export const goToEntityKnowledgeBaseEditor = (id: string): string => [goToEntityKnowledgeBase(), id].join(separator);

export enum RouteParam {
  amplyfiEntityId = "amplyfiEntityId",
}

const Routes: JSX.Element[] = [
  <Route exact key={goToEntityKnowledgeBase()} path={goToEntityKnowledgeBase()} component={EntitiesPage} />,
  <Route
    exact
    key={goToEntityKnowledgeBaseEditor(`:${RouteParam.amplyfiEntityId}`)}
    path={goToEntityKnowledgeBaseEditor(`:${RouteParam.amplyfiEntityId}`)}
    component={EntitiesPage}
  />,
];

export default Routes;
