// Returns a distinct list from a complex array by specifying one or more keys.
export function distinct<T>(list: T[], comparatorProperty?: keyof T | (keyof T)[]) {
  // if there is no comparator property, distinct by the entire object
  if (!comparatorProperty) {
    return list.filter((item, index, self) => self.indexOf(item) === index);
  }

  const comparatorProperties = Array.isArray(comparatorProperty) ? comparatorProperty : [comparatorProperty];
  return list.filter(
    (item, index, self) =>
      index === self.findIndex((t: T) => comparatorProperties.every((p: keyof T) => t[p] === item[p]))
  );
}

export const partition = <T>(array: T[], fn: (item: T, index: number, arr: T[]) => boolean): [T[], T[]] =>
  array.reduce<[T[], T[]]>(
    (acc, val, i, array) => {
      acc[fn(val, i, array) ? 0 : 1].push(val);
      return acc;
    },
    [[], []]
  );
