import { useQuery } from "../../useQuery";
import axios from "axios";
import useAPI from "../api/useAPI";
import useSearchStore from "../../../store/hooks/useSearchStore";
import { useInfiniteQuery } from "react-query";
import { FeedResponse } from "../../../http/feed";
import { FeedDocument } from "../../../models/monitoring";
import { useFiltersStore } from "../../../store/hooks/useFiltersStore";
import { useFeedViewStore } from "../../../store/hooks/useFeedViewStore";
import { getStartOfPastDayUtc } from "../../../helpers/dateHelpers";
import { getSortingOptions } from "../../useFeed";
import { FeedViewType } from "../../../components/Pages/Feed/components/filterMenu/feedFilters";

interface InsightSource {
  title: string;
  publishDate: string;
  url: string;
  documentId: string;
}

export interface Insight {
  insightId?: string;
  action: string;
  text: string;
  reasoning: string | null;
  sources: InsightSource[];
  type: string;
}

export interface InsightAction {
  type: string;
  index: number;
  text: string;
  insights: Insight[];
}

export interface InsightDocument extends FeedDocument {
  amplyfiEntityId: string;
  insights: Insight[];
  alertedDate: string;
  date: string;
}

export const useInsightsKey = "insights-feed";

export default function useInsights(libraryId: string | null, amplyfiEntityId: string | null) {
  const api = useAPI("v5", "insights-feed");
  const search = useSearchStore(({ search }) => search);
  const { timeFilter, userTags } = useFiltersStore(({ timeFilter, userTags }) => ({ timeFilter, userTags }));
  const { id: feedType, type } = useFeedViewStore(({ view }) => view);
  const startDate = getStartOfPastDayUtc(timeFilter);
  const { selectedSort, selectedSortInsights } = useFiltersStore(({ selectedSort, selectedSortInsights }) => ({ selectedSort, selectedSortInsights }));
  const { sortBy, sortOrder } = getSortingOptions(type, selectedSort, selectedSortInsights);

  const query = useInfiniteQuery(
    [useInsightsKey, libraryId, amplyfiEntityId, userTags, feedType, search, startDate, sortBy, sortOrder],
    async ({ pageParam: paginationToken }) => {
      const { data } = await axios.get<FeedResponse<InsightDocument>>(api, {
        params: {
          feedType,
          libraryId,
          startDate,
          userTags,
          entityId: amplyfiEntityId,
          sortBy,
          sortOrder,
          paginationToken: paginationToken ? decodeURIComponent(paginationToken) : undefined,
          searchTerm: search ?? undefined,
        },
      });
      return data;
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      getNextPageParam: (lastPage) => lastPage?.paginationToken || undefined,
    }
  );
  return {
    ...query,
    data: query.data?.pages.reduce<FeedResponse<InsightDocument>>(
      (acc, page) => ({
        ...page,
        documents: [...acc.documents, ...page.documents],
        paginationToken: page.paginationToken,
      }),
      {
        discardedDocumentCount: 0,
        documentCount: 0,
        documents: [],
        favouriteDocumentCount: 0,
        inboxDocumentCount: 0,
        paginationToken: null,
      }
    ),
  };
}

export const useInsightCountsKey = "insights-feed-count";

export function useInsightCounts(libraryId: string | null, amplyfiEntityId: string | null) {
  const api = useAPI("v5", "insights-feed");
  const searchTerm = useSearchStore(({ search }) => search);
  const { timeFilter, userTags } = useFiltersStore(({ timeFilter, userTags }) => ({ timeFilter, userTags }));
  const { id: feedType, type } = useFeedViewStore(({ view }) => view);
  const startDate = getStartOfPastDayUtc(timeFilter);
  const { selectedSort, selectedSortInsights } = useFiltersStore(({ selectedSort, selectedSortInsights }) => ({ selectedSort, selectedSortInsights }));
  const { sortBy, sortOrder } = getSortingOptions(type, selectedSort, selectedSortInsights);

  return useQuery(
    [useInsightCountsKey, libraryId, amplyfiEntityId, feedType, searchTerm, startDate, sortBy, sortOrder],
    async () => {
      if (type === FeedViewType.Insight) {
        const { data } = await axios.get<FeedResponse<InsightDocument>>(api, {
          params: {
            feedType,
            libraryId,
            startDate,
            userTags,
            entityId: amplyfiEntityId,
            searchTerm: searchTerm ?? undefined,
            sortBy,
            sortOrder,
          },
        });
        return data;
      }
    }
  );
}
