import moment from "moment";
import { useState, useEffect } from "react";
import { TimeSelectorType } from "../components/Charts/TimeSelector";
import { timelineFilterLabels } from "../components/Widgets/TimelineDialog";
import { AiDate } from "../helpers/dateHelpers";
import { Days } from "../helpers/timeIntervals";
import { SortTypes } from "../models/document";
import { useAnalyseSelector } from "../store/reducers";
import { DEFAULT_DISPLAY_END } from "../store/reducers/searchRequest/url-functions";
import useRelatedDocumentList from "./useRelatedDocumentList";

const labelDateFormat = "D MMM YYYY";

const getTimelineFilterLabels = (period: TimeSelectorType | undefined, startDate: AiDate, endDate: AiDate): string => {
  let timeLineLabel = timelineFilterLabels.custom;

  if (period) {
    timeLineLabel = timelineFilterLabels[period];
  }

  if (!period && startDate && endDate) {
    timeLineLabel = `${startDate.format(labelDateFormat)} - ${endDate.format(labelDateFormat)}`;
  }
  return timeLineLabel;
};

export function useTimelineFilterLabel(): string {
  const [timelineFilterLabel, setTimelineFilterLabel] = useState(timelineFilterLabels.custom);
  const { startDate, endDate, period } = useAnalyseSelector((x) => x.searchRequest.url.parsed);

  const { data } = useRelatedDocumentList("All", SortTypes.Ascending, false, true, 1, false);
  const earliestDate = data?.All?.documents?.length ? moment(data.All.documents[0].date) : null;

  useEffect(() => {
    if (period) {
      setTimelineFilterLabel(timelineFilterLabels[period as TimeSelectorType]);
    } else {
      if (!startDate && !endDate) {
        setTimelineFilterLabel(timelineFilterLabels.custom);
      } else {
        const finalStartDate = !startDate && earliestDate ? earliestDate : null;
        setTimelineFilterLabel(
          getTimelineFilterLabels(
            period as TimeSelectorType,
            moment(finalStartDate ?? startDate),
            endDate === Days.Today ? DEFAULT_DISPLAY_END : moment.utc(endDate)
          )
        );
      }
    }
  }, [period, startDate, endDate, setTimelineFilterLabel, earliestDate]);

  return timelineFilterLabel;
}
