import { logError } from "../helpers/logger";
import { updateContent } from "../http/saved-analyses";

export async function saveContent(analysisId: string): Promise<void> {
  const content = window.location.search.slice(1);
  try {
    await updateContent(analysisId, content);
  } catch (e) {
    logError(`Error updating saved analysis ${e}`);
  }
  return;
}
