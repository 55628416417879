import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import { customizationEntitiesSearch, customizationKeyPhrasesSearch } from "./customization-search-sagas";
import SearchSaga from "./search-saga";
import { debouncedKeywordSearch } from "./topic-search-saga";

export default function* root(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
  yield all([
    fork(SearchSaga),
    fork(debouncedKeywordSearch),
    fork(customizationEntitiesSearch),
    fork(customizationKeyPhrasesSearch),
  ]);
}
