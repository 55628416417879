import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { clearFilters } from "../../../store/reducers/searchRequest/actions";
import { toggleDrawerOpen } from "../../../store/reducers/searchRequest/filterReducer";
import {
  clearAllFilters,
  clearDocumentLibraryId,
  removeSelectedAnalysis,
  resetFilterOptions,
  resetQuery,
} from "../../../store/reducers/searchRequest/urlReducer";
import Header from "../../Widgets/Header/Header";
import CreateAnalysisDialog from "../../Widgets/SavedAnalysis/CreateAnalysisDialog";
import SavedAnalysisTable from "../../Widgets/SavedAnalysis/SavedAnalysisTable";
import { CONTAINER_WIDTH } from "../useLandingStyles";
import { clearSelectedDocument } from "../../../store/reducers/searchResponse/relatedDocumentViewerReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_WIDTH,
      margin: "0 auto",
    },
    content: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function AnalysisAndLibrariesPage(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [createAnalysisOpen, setCreateAnalysisOpen] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    dispatch(resetQuery());
    dispatch(removeSelectedAnalysis());
  }, [dispatch]);

  return (
    <Container maxWidth="xl">
      <div className={classes.container}>
        <Header
          title="Analyses"
          buttonText="Create Analysis"
          onButtonClicked={() => {
            history.push({
              pathname: "/analyse/overview",
              search: window.location.search,
            });
            dispatch(clearFilters());
            dispatch(clearAllFilters());
            dispatch(clearDocumentLibraryId());
            dispatch(clearSelectedDocument());
            dispatch(resetFilterOptions());
            dispatch(toggleDrawerOpen(true));
          }}
        />
        <div className={classes.content}>
          <SavedAnalysisTable />
        </div>
        {createAnalysisOpen && (
          <CreateAnalysisDialog
            onClose={() => {
              setCreateAnalysisOpen(false);
            }}
          />
        )}
      </div>
    </Container>
  );
}
