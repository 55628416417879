import { Button, Typography } from "@material-ui/core";
import { ChangeEvent } from "react";

interface SelectFileProps {
  file: File | null;
  onChange: (file: File | null) => void;
  accept?: string;
}

export default function SelectFile(props: SelectFileProps) {
  const { file, onChange, accept } = props;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.files ? e.target.files[0] : null);
  }

  return (
    <div>
      <input hidden id="upload-file-input" accept={accept} onChange={handleChange} type="file"></input>
      <label htmlFor="upload-file-input">
        <Button variant="outlined" component="span">
          Select file
        </Button>
        <Typography style={{ display: "inline-block", marginLeft: 8 }} variant="body2">
          {file?.name}
        </Typography>
      </label>
    </div>
  );
}
