import { testId } from "@amplyfi/ui-components/testHelpers";
import { FormControl, FormControlLabel, FormGroup, FormHelperText, Switch } from "@material-ui/core";
import { FieldHandlerOptions, Handler } from "../../../helpers/fieldHandlers";
import { useFieldStyles } from "./styles";

export const booleanField: (props: FieldHandlerOptions) => Handler<boolean> =
  ({ label }) =>
  (value, name, onChange, { disabled, error }) => {
    const classes = useFieldStyles();

    return (
      <FormControl className={classes.control} error={!!error}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                data-testid={testId("entity-knowledge-base", "edit", name)}
                disabled={disabled}
                checked={!!value}
                onChange={() => onChange(!value)}
              />
            }
            labelPlacement="start"
            label={label}
            style={{ width: "100%", paddingRight: 6 }}
            aria-invalid={false}
          />
        </FormGroup>
        {error && <FormHelperText className={classes.error}>{error}</FormHelperText>}
      </FormControl>
    );
  };
