import axios from "axios";
import { LibrariesType } from "../models/queryParams";
import { SearchRequestV3 } from "../models/search";
import { getAnalysisV3Url } from "./general";
import { Element, ElementDataResponse } from "../models/element";
import { exportResponse } from "./export";

export async function getElements(
  searchRequest: SearchRequestV3,
  docType: LibrariesType[] | null = null,
  searchTerm?: string
): Promise<Element[]> {
  const searchResponse = await axios.post<Element[]>(
    getAnalysisV3Url("elements/document-count", docType, searchTerm),
    searchRequest
  );
  return searchResponse.data;
}

// This is used for returning the data used by the top-level table
export async function getAllElementsData(
  searchRequest: SearchRequestV3,
  docType: LibrariesType[] | null = null,
  searchTerm?: string
): Promise<ElementDataResponse> {
  const searchResponse = await axios.post<ElementDataResponse>(getAnalysisV3Url("elements", docType), searchRequest);
  return searchResponse.data;
}

export async function exportAllElementsData(
  searchRequest: SearchRequestV3,
  docType: LibrariesType[] | null = null
): Promise<void> {
  const searchResponse = await axios.post<File>(getAnalysisV3Url("elements", docType), searchRequest, {
    headers: {
      Accept: "text/csv",
      "Content-Type": "application/json",
    },
  });
  exportResponse(searchResponse, searchRequest.systemFilters.exportFileName as string);
}
