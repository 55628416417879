import axios from "axios";
import { EntityType } from "../models/entity";

const apiUrl = `${process.env.REACT_APP_ANALYSE_API}/v4`;

interface AutocompleteResponseNullable {
  aliases: string[];
  description: string | null;
  id: string;
  industries: string[] | null;
  name: string;
  type: EntityType;
  websites: string[] | null;
  wikiDataId: string | null;
}

interface AutocompleteResponse
  extends Omit<AutocompleteResponseNullable, "description" | "industries" | "websites" | "wikiDataId"> {
  description?: NonNullable<AutocompleteResponseNullable["description"]>;
  industries: NonNullable<AutocompleteResponseNullable["industries"]>;
  websites: NonNullable<AutocompleteResponseNullable["websites"]>;
  wikiDataId?: NonNullable<AutocompleteResponseNullable["wikiDataId"]>;
}

const transformResponse = ({
  description,
  wikiDataId,
  websites,
  industries,
  ...data
}: AutocompleteResponseNullable): AutocompleteResponse => ({
  ...data,
  description: description || undefined,
  industries: industries || [],
  websites: websites || [],
  wikiDataId: wikiDataId || undefined,
});

export async function requestNewOrganisation(
  name: string,
  website: string,
  altNames: string[]
): Promise<AutocompleteResponse> {
  const { data } = await axios.post<AutocompleteResponseNullable>(`${apiUrl}/organisation-request`, {
    altNames,
    name,
    website,
  });
  return transformResponse(data);
}

export async function requestNewTechnology(
  name: string,
  description: string,
  altNames: string[]
): Promise<AutocompleteResponse> {
  const { data } = await axios.post<AutocompleteResponseNullable>(`${apiUrl}/technology-request`, {
    altNames,
    description,
    name,
  });
  return transformResponse(data);
}
