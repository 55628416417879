import { Grey } from "@amplyfi/ui-components/theme/colors";
import { createStyles, darken, makeStyles, Theme } from "@material-ui/core";
import { useState } from "react";
import { useRouteMatch } from "react-router";
import HideOnFeedScroll from "../../Pages/Feed/Configuration/Components/HideOnScroll";
import LeftPanelCollapsed from "./LeftPanelCollapsed";

interface LeftPanelProps {
  width?: number;
}

export const MOBILE_LEFT_PANEL_HEIGHT = 64;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      fontSize: theme.typography.h3.fontSize,
      fontWeight: "bold",
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(2),

      "& svg": {
        marginRight: theme.spacing(),
      },
    },
    searchSuggestions: {
      alignSelf: "flex-start",
      width: "100%",
    },
    closeIcon: {
      right: theme.spacing(2),
    },
    addIcon: {
      height: 40,
      width: 40,
      color: theme.palette.links.primary,
      cursor: "pointer",

      "&:hover": {
        color: darken(theme.palette.links.primary, 0.2),
      },
    },
    collapseBar: (props: LeftPanelProps) => ({
      height: "100%",
      width: 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRight: `1px solid ${theme.palette.borders.main}`,
      cursor: "pointer",
      position: "absolute",
      left: props.width,
      "& svg": {
        color: Grey,
        transform: "rotate(180deg)",
        fontSize: 18,
      },
    }),
    leftPanelContainer: {
      height: "100%",
      position: "fixed",
      left: 0,
      zIndex: 1200,
      [theme.breakpoints.down("sm")]: {
        top: "auto",
        bottom: 0,
        right: 0,
        height: MOBILE_LEFT_PANEL_HEIGHT,
      },
    },
    listTitle: {
      color: Grey,
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    logoContainer: {
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(2),
      cursor: "pointer",
    },
    amplyfiLogo: {
      height: 14,
    },
    settingsContainer: {
      paddingBottom: 20,
      paddingLeft: theme.spacing(2),
    },
    subList: {
      marginTop: 20,
      paddingLeft: theme.spacing(2),
    },
  })
);

export default function LeftPanel(props: LeftPanelProps): JSX.Element {
  const { width } = props;
  const classes = useStyles(props);
  const analyseMatch = useRouteMatch({ path: ["/analyse/*", "/analyse/", "/advanced-search"] });
  const knowledgeMatch = useRouteMatch({ path: ["/research/*", "/research/", "/connectors/"] });
  const researchMatch = useRouteMatch({ path: ["/generative-research/*", "/generative-research/"] });

  const analyseTabActive = analyseMatch?.isExact;
  const knowledgeTabActive = knowledgeMatch?.isExact;
  const researchTabActive = researchMatch?.isExact;

  const [storedAnalyseUrl, setStoredAnalyseUrl] = useState("/analyse");

  return (
    <HideOnFeedScroll direction="up">
      <div className={classes.leftPanelContainer}>
        <LeftPanelCollapsed
          analyseTabActive={!!analyseTabActive}
          knowledgeTabActive={!!knowledgeTabActive}
          researchTabActive={!!researchTabActive}
          storedAnalyseUrl={storedAnalyseUrl}
          updateUrls={updateUrls}
          width={width}
        />
      </div>
    </HideOnFeedScroll>
  );

  function updateUrls() {
    const url = `${window.location.pathname}${window.location.search}`;
    if (analyseTabActive) {
      setStoredAnalyseUrl(url);
    }
  }
}
