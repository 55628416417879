import { createAction } from "@reduxjs/toolkit";
import { Entity } from "../../../models/entity";
import { QueryType } from "../../../models/query";

export const updateComponentsFilters = createAction("filters/updateFilters");

export const routeChange = createAction("route/change");

export const resetState = createAction("resetState");

export const resetFilters = createAction("filters/resetFilters");

export const clearFilters = createAction("clearFilters");

export const replaceQuery = createAction<{ type?: QueryType; entities: Entity[] }>("replaceQuery");
