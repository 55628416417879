import { PickledBluewood } from "@amplyfi/ui-components/theme/colors";
import { Theme } from "@material-ui/core/styles";
import { ChartOptions } from "chart.js";
import "chartjs-plugin-datalabels";
import { defaults } from "react-chartjs-2";

export function setupChartjsDefaults(theme: Theme): void {
  defaults.global.defaultFontFamily = theme.typography.fontFamily;
  defaults.global.defaultFontSize = 10;
  defaults.global.defaultFontColor = theme.palette.primary.main;
  defaults.global.tooltips = {
    ...defaults.global.tooltips,
    backgroundColor: PickledBluewood[200],
    titleSpacing: 8,
    bodySpacing: 8,
  };
  defaults.global.plugins = {
    showAllLabels: false,
    datalabels: {
      display: false,
    },
  };
}

export function withCursorPointer(options: ChartOptions): ChartOptions {
  return {
    ...options,
    ...({
      onHover: (event, activeElements) => {
        if (event && activeElements) {
          const htmlEvent = event.target as HTMLElement;
          htmlEvent.style.cursor = activeElements[0] ? "pointer" : "default";
        }
      },
    } as ChartOptions),
  };
}
