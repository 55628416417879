import moment from "moment";
import queryString from "query-string";
import { FilterType } from "../../../models/filter";
import { QueryType } from "../../../models/query";
import { LibrariesType, ParsedQueryParams, QueryFilterKeys, QueryParams } from "../../../models/queryParams";
import history from "./history";
import { TimePeriod } from "../../../models/chart";
import { DocumentType, FiltersTypeProps } from "../../../models/search";
import { Entity } from "../../../models/entity";
import { TimeIntervalType, Days } from "../../../helpers/timeIntervals";
import { urlEmptyHelper } from "../../../helpers/urlEmptyHelper";
import { dateGranularity } from "../../../helpers/dateHelpers";

export const timePeriodMap: Record<TimePeriod, moment.Moment> = {
  "1year": dateGranularity(moment.utc().add(-1, "years").startOf("day")),
  "3months": dateGranularity(moment.utc().add(-3, "months").startOf("day")),
  "3years": dateGranularity(moment.utc().add(-3, "years").startOf("day")),
  "5years": dateGranularity(moment.utc().add(-5, "years").startOf("day")),
  "6months": dateGranularity(moment.utc().add(-6, "months").startOf("day")),
};
export const DEFAULT_PERIOD: TimePeriod = "1year";
export const DEFAULT_START = timePeriodMap[DEFAULT_PERIOD].startOf("day");
export const DEFAULT_END = moment.utc().endOf("day").clone();
export const DEFAULT_INTERVAL: TimeIntervalType = "Month";
export const DEFAULT_DISPLAY_END = moment().clone();

export function getParsedQueryParams(query?: string): ParsedQueryParams {
  const queryParams = queryString.parse(query ?? window.location.search) as QueryParams;
  const {
    all,
    keywords,
    any,
    dataTypes,
    endDate,
    locations,
    none,
    sectors,
    subSectors,
    sources,
    startDate,
    documentId,
    relStrength,
    period,
    publishLocations,
    type,
    applicants,
    owners,
    authors,
    academicPublishLocations,
    publicationType,
    institution,
    institutionCountry,
    inventors,
    patentType,
    patentJurisdictions,
    lucene,
    adverseMediaCategories,
    activeInSectors,
    mentionedLocation,
    risks,
    libraries,
    includeUndatedDocuments,
    libraryIds,
    savedAnalysisId,
    entityId,
    alertDate,
    alertKeyPhrase,
    withinSentence,
    document,
    alert,
    filter,
  } = queryParams;

  const isLandingPage = window.location.pathname === "/analyse";

  const DEFAULT_LIBRARIES: LibrariesType[] = isLandingPage ? [] : [DocumentType.News, "Private"];

  const getLibraries = (str: string): LibrariesType[] => {
    if (str === "" && isLandingPage) {
      return [];
    } else if (str !== "") {
      const libraries = str.split(",");
      return [...libraries, ...(libraries.includes("Private") ? [] : ["Private"])] as LibrariesType[];
    } else {
      return DEFAULT_LIBRARIES;
    }
  };

  const parsedQueryParams: ParsedQueryParams = {
    all: all ? JSON.parse(all) : [],
    any: any ? JSON.parse(any) : [],
    dataTypes: dataTypes ? JSON.parse(dataTypes) : [],
    keywords: keywords ? JSON.parse(keywords) : [],
    locations: locations ? JSON.parse(locations) : [],
    publishLocations: publishLocations ? JSON.parse(publishLocations) : [],
    none: none ? JSON.parse(none) : [],
    sectors: sectors ? JSON.parse(sectors) : [],
    subSectors: subSectors ? JSON.parse(subSectors) : [],
    sources: sources ? JSON.parse(sources) : [],
    documentId,
    relStrength: relStrength ? JSON.parse(relStrength) : [],
    period: urlEmptyHelper(period),
    startDate: startDate ? Number(startDate) : undefined,
    endDate: endDate === Days.Today ? Days.Today : Number(endDate) || undefined,
    type: urlEmptyHelper(type as DocumentType),
    applicants: applicants ? JSON.parse(applicants) : [],
    owners: owners ? JSON.parse(owners) : [],
    authors: authors ? JSON.parse(authors) : [],
    academicPublishLocations: academicPublishLocations ? JSON.parse(academicPublishLocations) : [],
    publicationType: publicationType ? JSON.parse(publicationType) : [],
    institution: institution ? JSON.parse(institution) : [],
    institutionCountry: institutionCountry ? JSON.parse(institutionCountry) : [],
    inventors: inventors ? JSON.parse(inventors) : [],
    patentType: patentType ? JSON.parse(patentType) : [],
    patentJurisdictions: patentJurisdictions ? JSON.parse(patentJurisdictions) : [],
    lucene: lucene ?? undefined,
    adverseMediaCategories: adverseMediaCategories ? JSON.parse(adverseMediaCategories) : [],
    activeInSectors: activeInSectors ? JSON.parse(activeInSectors) : [],
    mentionedLocation: mentionedLocation ? JSON.parse(mentionedLocation) : [],
    risks: risks ? JSON.parse(risks) : [],
    libraries: typeof libraries === "string" ? getLibraries(libraries) : DEFAULT_LIBRARIES,
    includeUndatedDocuments: includeUndatedDocuments ? JSON.parse(includeUndatedDocuments) : true,
    libraryIds: libraryIds ? JSON.parse(libraryIds) : [],
    savedAnalysisId,
    entityId,
    alertDate,
    alertKeyPhrase,
    withinSentence: withinSentence === "true",
    document,
    alert,
    filter,
  };

  return parsedQueryParams;
}

export function getUpdateUrlSearch(params: Partial<ParsedQueryParams>): string {
  const {
    all,
    any,
    keywords,
    dataTypes,
    endDate,
    locations,
    none,
    sectors,
    subSectors,
    sources,
    startDate,
    documentId,
    relStrength,
    period,
    publishLocations,
    applicants,
    academicPublishLocations,
    authors,
    institution,
    institutionCountry,
    inventors,
    owners,
    patentType,
    publicationType,
    type,
    patentJurisdictions,
    lucene,
    adverseMediaCategories,
    activeInSectors,
    mentionedLocation,
    risks,
    libraries,
    libraryIds,
    includeUndatedDocuments,
    savedAnalysisId,
    entityId,
    alertDate,
    alertKeyPhrase,
    withinSentence,
    document,
    alert,
    filter,
  } = params;

  const queryParams: QueryParams = {
    all: removeEmpty(all),
    any: removeEmpty(any),
    keywords: removeEmpty(keywords),
    dataTypes: removeEmpty(dataTypes),
    endDate: endDate?.toString() ?? undefined,
    locations: removeEmpty(locations),
    publishLocations: removeEmpty(publishLocations),
    none: removeEmpty(none),
    sectors: removeEmpty(sectors),
    subSectors: removeEmpty(subSectors),
    sources: removeEmpty(sources),
    startDate: startDate?.toString() ?? undefined,
    documentId,
    relStrength: removeEmpty(relStrength),
    period: urlEmptyHelper(period),
    applicants: removeEmpty(applicants),
    academicPublishLocations: removeEmpty(academicPublishLocations),
    authors: removeEmpty(authors),
    institution: removeEmpty(institution),
    institutionCountry: removeEmpty(institutionCountry),
    inventors: removeEmpty(inventors),
    owners: removeEmpty(owners),
    patentType: removeEmpty(patentType),
    publicationType: removeEmpty(publicationType),
    type: type,
    patentJurisdictions: removeEmpty(patentJurisdictions),
    lucene: lucene,
    adverseMediaCategories: removeEmpty(adverseMediaCategories),
    activeInSectors: removeEmpty(activeInSectors),
    mentionedLocation: removeEmpty(mentionedLocation),
    risks: removeEmpty(risks),
    libraries: libraries?.toString() ?? removeEmpty(libraries),
    includeUndatedDocuments: includeUndatedDocuments?.toString() || "true",
    libraryIds: removeEmpty(libraryIds),
    savedAnalysisId: savedAnalysisId,
    entityId,
    alertDate,
    alertKeyPhrase,
    withinSentence: withinSentence?.toString() || "false",
    document,
    alert,
    filter,
  };
  return queryString.stringify(queryParams);
}

export function updateUrl(params: Partial<ParsedQueryParams>): void {
  const search = getUpdateUrlSearch(params);
  if (search !== window.location.search.slice(1)) {
    history.replace({ ...window.location, search });
  }
}

function removeEmpty<T>(arr?: T[]): string | undefined {
  if (!arr) {
    return undefined;
  }
  return arr.length > 0 ? JSON.stringify(arr) : undefined;
}

export const getQueryParamFieldNameByType = (type: QueryType): "all" | "any" | "none" => {
  const keys: Record<QueryType, "all" | "any" | "none"> = {
    [QueryType.AllQueries]: "all",
    [QueryType.AnyQueries]: "any",
    [QueryType.NoneQueries]: "none",
  };

  return keys[type];
};

export const getQueryParamFieldNameByFilterType = (type: FilterType): QueryFilterKeys => {
  const keys: Record<FilterType, QueryFilterKeys> = {
    [FilterType.DataTypes]: "dataTypes",
    [FilterType.Keywords]: "keywords",
    [FilterType.Locations]: "locations",
    [FilterType.Sectors]: "sectors",
    [FilterType.SubSectors]: "subSectors",
    [FilterType.Sources]: "sources",
    [FilterType.PublishLocations]: "publishLocations",
    [FilterType.RelationshipStrength]: "relStrength",
    [FilterType.Applicants]: "applicants",
    [FilterType.Owners]: "owners",
    [FilterType.Authors]: "authors",
    [FilterType.AcademicPublishLocations]: "academicPublishLocations",
    [FilterType.PublicationType]: "publicationType",
    [FilterType.Institution]: "institution",
    [FilterType.InstitutionCountry]: "institutionCountry",
    [FilterType.Inventors]: "inventors",
    [FilterType.PatentType]: "patentType",
    [FilterType.PatentJurisdictions]: "patentJurisdictions",
  };

  return keys[type];
};

export const convertEntityToFiltersArray = (array: Entity[]): FiltersTypeProps[] | undefined => {
  const result = array.map((x) => ({ filterTerm: x.name, excludeOrInclude: x.excludeOrInclude }));
  return result.length > 0 ? result : undefined;
};

export const resetAllParams = (): ParsedQueryParams => ({
  all: [],
  any: [],
  endDate: DEFAULT_END.clone().valueOf(),
  none: [],
  startDate: DEFAULT_START.clone().valueOf(),
  documentId: undefined,
  relStrength: [],
  period: DEFAULT_PERIOD,
  type: undefined,
  applicants: [],
  owners: [],
  authors: [],
  academicPublishLocations: [],
  publicationType: [],
  institution: [],
  institutionCountry: [],
  inventors: [],
  patentType: [],
  patentJurisdictions: [],
  lucene: "",
  dataTypes: [],
  keywords: [],
  locations: [],
  publishLocations: [],
  sectors: [],
  sources: [],
  subSectors: [],
  adverseMediaCategories: [],
  activeInSectors: [],
  mentionedLocation: [],
  risks: [],
  libraries: [],
  libraryIds: [],
  includeUndatedDocuments: true,
  savedAnalysisId: undefined,
  withinSentence: false,
  document: undefined,
  alert: undefined,
  filter: undefined,
});
