export function toSentenceCase(text: string): string {
  let i, j, str;
  str = text.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  const lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  const uppers = ["Uk", "Ltd", "Hr", "Inc", "I.t", "Plc", "Udt", "Blsa"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
}

export const toTitleCase = (str: string): string =>
  str
    .split(" ")
    .map((v) => `${v[0]?.toUpperCase()}${v.slice(1)}`)
    .join(" ");

interface Options {
  includeCount?: boolean;
  showPluralCount?: boolean;
  titleCase?: boolean;
}

export interface PluraliseHelper {
  (count: number, options?: Options): string;
}

export const pluralise =
  (singular: string, plural: string): PluraliseHelper =>
  (count, { includeCount = true, showPluralCount = false, titleCase = false } = {}) =>
    [
      ...(includeCount ? [count] : []),
      ...(count === 1
        ? [titleCase ? toTitleCase(singular) : singular.toLowerCase()]
        : [
            ...(showPluralCount && !includeCount ? [count] : []),
            titleCase ? toTitleCase(plural) : plural.toLowerCase(),
          ]),
    ].join(" ");

export const pluraliseDocuments = pluralise("document", "documents");
export const pluraliseDays = pluralise("day", "days");
export const pluraliseFeeds = pluralise("feed", "feeds");
export const pluraliseInsights = pluralise("insight", "insights");
export const pluraliseSignals = pluralise("signal", "signals");
export const pluraliseSignalTypes = pluralise("signal type", "signal types");
export const pluraliseSources = pluralise("source", "sources");
export const pluraliseWeeks = pluralise("week", "weeks");
export const pluraliseList = pluralise("was", "were");
