import AiButton from "@amplyfi/ui-components/components/Button";
import {
  Avatar,
  createStyles,
  darken,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, ArrowLeft, ArrowRight, Block, MailOutline } from "@material-ui/icons";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { getUsers } from "../../../http/user-management";
import BasicSearch from "../../Widgets/Search/BasicSearch";
import AddUserModal from "../Settings/Users/AddUserModal";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import AbstractSettingsPage from "../User/AbstractSettingsPage";
import { canUserAccountDelegatedAdmin } from "./permissions";
import { AmplyfiUser } from "../../../models/user";
import useScreenSize from "../../../hooks/useScreenSize";
import { getOrganisation } from "../../../helpers/user";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "90%",
      padding: theme.spacing(2),
    },
    header: {
      display: "flex",
      alignItems: "center",
    },
    connectorListItem: {
      display: "flex",
      height: 60,
      alignItems: "center",
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),

      "&:hover": {
        background: darken(theme.palette.componentBackground.main, 0.1),
        cursor: "pointer",
      },
    },
    connectorListIcon: {
      height: 36,
      width: 36,
      objectFit: "contain",
    },
    statusIcon: {
      marginLeft: theme.spacing(1),
    },
    connectorListTitle: {
      marginLeft: theme.spacing(1),
      flex: "1 1 auto",
      marginRight: theme.spacing(1),
    },
    row: {
      cursor: "pointer",
      "& > td": {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    footer: { display: "flex", alignItems: "center", justifyContent: "flex-end" },
  })
);

interface UserListItemProps extends AmplyfiUser {
  onClick: () => void;
}

function UserListItem(props: UserListItemProps): JSX.Element {
  const { userId, name, email, email_verified, blocked, picture, groups, onClick } = props;
  const styles = useStyles();
  const screenSize = useScreenSize();

  return (
    <TableRow onClick={() => onClick()} className={styles.row} key={userId}>
      <TableCell align="left">
        <ListItem>
          <ListItemAvatar>
            <Avatar className={styles.connectorListIcon} src={picture} alt="" />
          </ListItemAvatar>
          <ListItemText primary={name} secondary={email} />
        </ListItem>
      </TableCell>
      {screenSize === "desktop" && (
        <>
          {/* <TableCell align="left">
            <Typography variant="body1">{email}</Typography>
          </TableCell> */}
          <TableCell align="left">
            <Typography variant="body1">{
              groups
                .map(group => group.name)
                .join(", ")}
            </Typography>
          </TableCell>
        </>
      )}
      <TableCell align="right">
        {!email_verified && (
          <Tooltip title="Invite sent" placement="top">
            <IconButton size="small" className={styles.statusIcon}>
              <MailOutline />
            </IconButton>
          </Tooltip>
        )}
        {blocked && (
          <Tooltip title="User is blocked" placement="top">
            <IconButton size="small" className={styles.statusIcon}>
              <Block />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}

export default function UserManagement(): JSX.Element {
  const { user } = useAuth0();
  const organisation = getOrganisation(user);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [search, setSearch] = useState("");
  const history = useHistory();
  const screenSize = useScreenSize();
  const { data, isLoading, refetch } = useQuery(
    ["user-management-users", currentPage, search],
    () => getUsers(organisation?.id as string, currentPage, search, undefined),
    { refetchInterval: 0, cacheTime: 0 }
  );
  const styles = useStyles();
  const columns = [
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "groups",
      headerName: "Groups",
      hideMobile: true,
    },
    {
      field: "status",
    },
  ];
  const membersPerPage = 25;
  const startingNumber = (currentPage - 1) * membersPerPage + 1;
  const endingNumber = Math.min((currentPage - 1) * membersPerPage + membersPerPage, data?.count || 0);

  return (
    <AbstractSettingsPage
      title="Users"
      actions={
        <div style={{ display: "flex", alignItems: "center" }}>
          {canUserAccountDelegatedAdmin(user) && (
            <AiButton style={{ marginLeft: "auto" }} onClick={() => setShowAddModal(true)} startIcon={<Add />}>
              Create User
            </AiButton>
          )}
        </div>
      }
    >
      <BasicSearch fullWidth style={{ marginTop: 16 }} onTextSubmit={(value) => { setSearch(value); setCurrentPage(1); }} />
      <TableContainer style={{ marginTop: 16, maxHeight: "100%", overflow: "auto" }}>
        <Table padding="none" size="small">
          <TableHead>
            <TableRow className={styles.row}>
              {isLoading ? (
                <TableCell />
              ) : (
                columns
                  .filter((c) => (c.hideMobile ? screenSize === "desktop" : true))
                  .map((col) => (
                    <TableCell key={col.field} align="left" style={{ fontWeight: "bold" }}>
                      {col.headerName}
                    </TableCell>
                  ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell>
                  <Skeleton count={20} height={40} />
                </TableCell>
              </TableRow>
            ) : (
              data?.users.map((user) => (
                <UserListItem
                  onClick={() => {
                    history.push(`/manage/users/${user.userId}`);
                  }}
                  key={user.userId}
                  {...user}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <Typography variant="body2">
          {startingNumber} - {endingNumber} of {data?.count}
        </Typography>
        <IconButton disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
          <ArrowLeft />
        </IconButton>
        <IconButton disabled={endingNumber === data?.count} onClick={() => setCurrentPage(currentPage + 1)}>
          <ArrowRight />
        </IconButton>
      </div>
      {showAddModal ? (
        <AddUserModal
          title="Create User"
          onClose={() => {
            refetch();
            setShowAddModal(false);
          }}
        />
      ) : (
        <></>
      )}
    </AbstractSettingsPage>
  );
}
