import { FilterOptions } from "../components/Widgets/Filters/FilterDrawer";
import { Entity } from "../models/entity";
import { FilterType } from "../models/filter";
import { QueryType } from "../models/query";
import { ParsedQueryParams, QueryFilterKeys } from "../models/queryParams";

export const getIndividualSelectedFiltersCount = (urlState: ParsedQueryParams, filter: string): number => {
  switch (filter) {
    case FilterOptions.Sectors:
      return urlState.sectors.length + urlState.subSectors.length;

    case FilterOptions.Locations:
      return urlState.locations.length + urlState.publishLocations.length;

    case FilterOptions.Mentions:
      return urlState.all.length + urlState.any.length + urlState.none.length;
  }
  return urlState[filter as QueryFilterKeys]?.length ?? 0;
};

export const getIndividualSelectedFilters = (urlState: ParsedQueryParams, filter: string): Record<string, Entity[]> => {
  switch (filter) {
    case FilterOptions.Sectors:
      return {
        [FilterType.Sectors]: urlState.sectors,
        [FilterType.SubSectors]: urlState.subSectors,
      };

    case FilterOptions.Locations:
      return {
        [FilterType.Locations]: urlState.locations,
        [FilterType.PublishLocations]: urlState.publishLocations,
      };

    case FilterOptions.Mentions:
      return {
        [FilterType.Keywords]: [
          ...getStandardSearchEntity(urlState.all, QueryType.AllQueries),
          ...getStandardSearchEntity(urlState.any, QueryType.AnyQueries),
          ...getStandardSearchEntity(urlState.none, QueryType.NoneQueries),
        ],
      };
  }
  return {
    [filter as string]: urlState[filter as QueryFilterKeys],
  };
};

export const getStandardSearchEntity = (data: Entity[], queryType: QueryType): Entity[] => {
  return data.map(({ id, name, type }: Entity, index: number) => ({
    id,
    index,
    name,
    type,
    queryType,
  }));
};

export const getStandardSearchEntityByType = (data: Entity[]): Record<QueryType, Entity[]> => {
  return {
    [QueryType.AllQueries]: data.filter((x) => x.queryType === QueryType.AllQueries),
    [QueryType.AnyQueries]: data.filter((x) => x.queryType === QueryType.AnyQueries),
    [QueryType.NoneQueries]: data.filter((x) => x.queryType === QueryType.NoneQueries),
  };
};
