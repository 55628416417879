import React from "react";
import Card from "./Card";
import SentimentBarSummary from "../Charts/SentimentBarSummary";
import Header from "./Header";
import { cardDetails } from "../../helpers/cardDetailsHelper";
import { ExportButtonV2 } from "../Widgets/ExportButton";
import { exportFunctionsV2 } from "../../http/export";

export default function SentimentBarSummaryCard(): JSX.Element {
  return (
    <Card
      id={cardDetails.sentiment.dataTestId}
      title={<Header title={cardDetails.sentiment.title} />}
      infoContent={cardDetails.sentiment.readMore}
      dataTestId={cardDetails.sentiment.dataTestId}
      feedbackComponentTitle={"Sentiment bar chart"}
      content={<SentimentBarSummary />}
      size="medium"
      rhsContent={<ExportButtonV2 getExport={exportFunctionsV2.sentimentBar} />}
    />
  );
}
