import { Dialog, TextField, Typography } from "@material-ui/core";
import Error, { LuceneError } from "../../Widgets/Landing/BooleanQuery/Error";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { setLuceneQueryWithoutReset } from "../../../store/reducers/searchRequest/urlReducer";
import { MidGrey } from "@amplyfi/ui-components/theme/colors";
import { SnackFrequencyType } from "../../../models/snack";
import SyntaxGuide from "../../Widgets/Landing/BooleanQuery/SyntaxGuide";
import ValidationCheckMark from "../../Widgets/Landing/BooleanQuery/ValidationCheckMark";
import clsx from "clsx";
import { debounce } from "lodash";
import { getParsedQueryParams } from "../../../store/reducers/searchRequest/url-functions";
import { link } from "../../../css/mixins";
import { parse } from "lucene";
import { setSnack } from "../../../store/reducers/ui/snackReducer";
import { useDispatch } from "react-redux";
import Button from "@amplyfi/ui-components/components/Button";
import { zIndex } from "../../../helpers/componentsZIndex";
import DocCountLabel from "./DocCountLabel";
import { useAnalyseSelector } from "../../../store/reducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      borderLeft: `1px solid ${MidGrey}`,
      paddingLeft: theme.spacing(1),
      "& .MuiInput-multiline": {
        background: theme.palette.componentBackground.main,
        border: "none",
        height: "100%",
        "& ::placeholder": {
          fontStyle: "italic",
        },
      },
      "& textarea": {
        fontFamily: "monaco, monospace",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: 540,
      height: 280,
      borderTop: `1px solid ${theme.palette.borders.main}`,
      paddingTop: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    footer: {
      display: "flex",
      flexDirection: "column",
    },
    footerRow: {
      minHeight: 40,
      "& > span": {
        marginRight: theme.spacing(2),
        color: "#91979D",
        fontWeight: "bold",
      },
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${theme.palette.borders.main}`,
        paddingBottom: theme.spacing(1),
      },
      "&:not(:first-of-type)": {
        paddingTop: theme.spacing(1),
      },
    },
    operatorRow: {
      display: "inline-flex",
      alignItems: "flex-end",
    },
    debugRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > div > span": {
        marginRight: theme.spacing(2),
        color: "#91979D",
        fontWeight: "bold",
      },
    },
    link: {
      ...link(theme),
      color: `${theme.palette.links.primary} !important`,
    },
    dialog: {
      overflow: "hidden",
    },
    footerButton: {
      borderTop: `1px solid ${theme.palette.borders.contrastText}`,
      textAlign: "end",
      display: "flex",
      alignItems: "baseline",
      justifyContent: "right",
      marginTop: theme.spacing(2),
    },
    button: {
      margin: `${theme.spacing(2)}px 0 0 ${theme.spacing(2)}px`,
    },
  })
);

const operators = [
  {
    operator: "( )",
  },
  {
    operator: "AND",
  },
  {
    operator: "OR",
  },
  {
    operator: "NOT",
  },
  {
    operator: "Proximity ~",
  },
  {
    operator: "Wildcard *",
  },
];

interface AdvancedQueryBuilderProps {
  onApply: () => void;
}

export default function AdvancedQueryBuilder(props: AdvancedQueryBuilderProps): JSX.Element {
  const { onApply } = props;
  const [validated, setValidated] = useState(false);
  const classes = useStyles();
  const urlState = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const [error, setError] = useState<LuceneError | null>(null);
  const [query, setQuery] = useState("");
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setSnack({
        title: "Key Phrases Alert",
        body: "The Advanced Query Builder is not currently able to recognise Entities (Organisations, People, Places),\nplease use the Simple Query Builder to access Entity Recognition",
        type: SnackFrequencyType.OncePerSession,
      })
    );
  }, [dispatch]);
  const validateQuery = useCallback(
    (queryToValidate: string) => {
      try {
        parse(queryToValidate);
        setError(null);
        setValidated(true);
      } catch (e: unknown) {
        const luceneError = e as LuceneError;
        setError(luceneError);
      }
    },
    [setValidated, setError]
  );

  useEffect(() => {
    const query = getParsedQueryParams().lucene ?? "";
    setQuery(query);
    validateQuery(query);
  }, [validateQuery]);

  const debouncedUpdate = useMemo(() => debounce(validateQuery, 500), [validateQuery]);

  return (
    <>
      <div className={classes.container}>
        <TextField
          classes={{ root: classes.textarea }}
          multiline
          placeholder={"Start your Advanced Logic here"}
          value={query}
          rows={10}
          onChange={(e) => {
            setValidated(false);
            setError(null);
            setQuery(e.currentTarget.value);
            debouncedUpdate(e.target.value);
          }}
          spellCheck={false}
        />
        <div className={classes.footer}>
          <div className={clsx(classes.footerRow, classes.operatorRow)}>
            <Typography variant="caption">Operators:</Typography>
            {operators.map(({ operator }) => (
              <Typography key={operator} variant="caption">
                {operator}
              </Typography>
            ))}
            <Typography
              onClick={(e) => {
                setAnchor(e.currentTarget);
              }}
              className={classes.link}
              variant="caption"
              data-testid="syntax-guide-text"
            >
              Syntax guide
            </Typography>
            <Dialog
              className={classes.dialog}
              open={!!anchor}
              onClose={() => {
                setAnchor(null);
              }}
              style={{ zIndex: zIndex.documentViewerFeedback }}
              maxWidth="md"
            >
              <SyntaxGuide
                onClose={() => {
                  setAnchor(null);
                }}
              />
            </Dialog>
          </div>
          <div className={clsx(classes.footerRow, classes.debugRow)}>
            <div>
              <Typography variant="caption">Debug:</Typography>
              {error && <Error query={query} err={error} />}
            </div>

            {validated && query.trim().length > 0 && <ValidationCheckMark />}
          </div>
        </div>
      </div>
      <div className={classes.footerButton}>
        <DocCountLabel
          urlState={{
            ...urlState,
            lucene: query,
          }}
        />
        <Button
          className={classes.button}
          onClick={() => {
            dispatch(setLuceneQueryWithoutReset(query));
            onApply();
          }}
          disabled={!validated}
          type="button"
        >
          Apply Filters
        </Button>
      </div>
    </>
  );
}
