import { createStyles, makeStyles, Theme, TextField, Typography, FormControl } from "@material-ui/core";
import React from "react";
import { QueryTimedOut } from "./Validation/QueryTimedOut";
import { QueryInvalid } from "./Validation/QueryInvalid";
import { QueryActionable } from "./Validation/QueryActionable";
import { QueryTooNarrow } from "./Validation/QueryTooNarrow";
import { QueryDoesNotReturnEstimate } from "./Validation/QueryDoesNotReturnEstimate";
import {
  isQueryActionable,
  isQueryTooNarrow,
  queryDoesNotReturnEstimate,
  isQueryValid,
  MAX_HARVEST_DOCUMENTS,
} from "../helpers/queryValidation";
import { HarvestMetadata } from "../../../../models/harvest";
import { HarvestConfig } from "../models";

interface EnterHarvestQueryProps {
  email: string;
  metadata: HarvestMetadata | null;
  query: string;
  setQuery: (query: string) => void;
  harvestConfig: HarvestConfig;
}

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    label: {
      display: "flex",
      paddingBottom: spacing(1),
    },
    section: {
      marginBottom: spacing(2),
    },
    syntaxGuide: {
      fontWeight: "normal",
      marginLeft: "auto",
      "& a": {
        marginLeft: spacing(1),
      },
    },
    textarea: {
      width: "100%",
      zIndex: 2,
      "& > div": {
        height: "auto",
        padding: spacing(1),
      },
      "& textarea": {
        fontFamily: "monaco, monospace",
        fontSize: 16,
        height: "auto",
        lineHeight: 1.6,
      },
    },
    validation: {
      background: palette.componentBackground.light,
      borderRadius: `0 0 ${spacing(1)}px ${spacing(1)}px`,
      position: "relative",
      top: -1 * spacing(1),
      zIndex: 1,
    },
  })
);

export const EnterHarvestQuery = ({
  email,
  metadata,
  query,
  setQuery,
  harvestConfig: {
    oneOffConfiguration: { help, usageDescription },
  },
}: EnterHarvestQueryProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.section}>
      <Typography variant="h4" className={classes.label}>
        Harvest Query
        <span className={classes.syntaxGuide}>
          {help?.map(({ link, title }) => (
            <a key={link} href={link} target="_blank" rel="noreferrer">
              {title}
            </a>
          ))}
        </span>
      </Typography>
      <p>{usageDescription}</p>
      <TextField
        multiline
        rows={3}
        rowsMax={12}
        classes={{ root: classes.textarea }}
        defaultValue={query}
        spellCheck={false}
        onChange={({ target: { value } }) => setQuery(value)}
      />
      {metadata && (
        <div className={classes.validation}>
          {isQueryValid(metadata) ? (
            <>
              {isQueryActionable(metadata) &&
                (queryDoesNotReturnEstimate(metadata) ? (
                  <QueryDoesNotReturnEstimate />
                ) : (
                  <QueryActionable count={metadata.count} max={MAX_HARVEST_DOCUMENTS} />
                ))}
              {isQueryTooNarrow(metadata) && <QueryTooNarrow count={metadata.count} email={email} />}
            </>
          ) : (
            <>
              {metadata.detail ? (
                <QueryInvalid detail={metadata.detail} email={email} />
              ) : (
                <QueryTimedOut email={email} />
              )}
            </>
          )}
        </div>
      )}
    </FormControl>
  );
};
