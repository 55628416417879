import { useEffect, useState } from "react";
import { FeedViewType } from "../../../components/Pages/Feed/components/filterMenu/feedFilters";
import { analyticsEvent } from "../../../helpers/analytics";
import { getStartOfPastDayUtc } from "../../../helpers/dateHelpers";
import { useFeedViewStore } from "../../../store/hooks/useFeedViewStore";
import { useFiltersStore } from "../../../store/hooks/useFiltersStore";
import useQuestionAnswerStore from "../../../store/hooks/useQuestionAnswerStore";
import { MessageFrom } from "../sockets/types";
import useAmplyfiSocketParser, {
  AmplyfiSocketResponseTypes,
  MessageSource,
  SocketStatus,
} from "../sockets/useAmplyfiSocketParser";
import { parseMessage } from "./parsers";
import useInvestigationSuggestions from "../investigation_suggestions/useInvestigationSuggestions";

export interface Message {
  from: MessageFrom;
  text: string;
  type: AmplyfiSocketResponseTypes;
  sources?: MessageSource[];
  isFinished: boolean;
  suggestions: string[];
}

const defaultMessage: Message = {
  from: MessageFrom.System,
  text: "I'm the Amplyfi AI assistant. Ask me questions about this Feed",
  type: "question",
  sources: undefined,
  isFinished: true,
  suggestions: [],
};

const defaultDocMessage = (type: FeedViewType): Message => ({
  from: MessageFrom.System,
  text: `I'm the Amplyfi AI assistant. Ask me questions about this ${type}.`,
  type: "question",
  sources: undefined,
  isFinished: true,
  suggestions: [],
});

export function useLiveQAQuery(
  libraryId: string,
  amplyfiEntityId: string | undefined
): { messages: Message[]; sendMessage: (question: string) => void; clearMessages: () => void } {
  const {
    view: { type },
  } = useFeedViewStore();
  const { documentIds } = useQuestionAnswerStore(({ documentIds }) => ({
    documentIds,
  }));
  const { socket } = useAmplyfiSocketParser(["question", "question-about-documents", "investigation-questions"]);
  const timeFilter = useFiltersStore((state) => state.timeFilter);
  const [messages, setMessages] = useState<Message[]>([
    documentIds.length > 0 ? defaultDocMessage(type) : defaultMessage,
  ]);

  const {
    suggestions: initialSuggestions,
    status,
    socket: suggestionSocket,
  } = useInvestigationSuggestions(libraryId, documentIds[0]);

  useEffect(() => {
    if (initialSuggestions) {
      const msg: Message = {
        from: MessageFrom.System,
        text: "",
        suggestions: initialSuggestions,
        type: "investigation-questions",
        isFinished: status === SocketStatus.Done,
      };
      setMessages([documentIds.length > 0 ? defaultDocMessage(type) : defaultMessage, msg]);
    }
  }, [initialSuggestions, status, documentIds, type]);

  useEffect(() => {
    setMessages([documentIds.length > 0 ? defaultDocMessage(type) : defaultMessage]);
  }, [documentIds, type]);

  const [timeout, setMessageTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (socket.lastJsonMessage) {
      const newMessages = parseMessage(messages, socket.lastJsonMessage);
      setMessages(newMessages);

      if (timeout) {
        clearTimeout(timeout);
      }
      setMessageTimeout(
        setTimeout(() => {
          setMessages(newMessages.map((m) => ({ ...m, isFinished: true })));
        }, 2000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket.lastJsonMessage]);

  function sendMessage(question: string): void {
    suggestionSocket.getWebSocket()?.close();
    setMessages([
      ...messages.filter((m) => m.type !== "investigation-questions"),
      { from: MessageFrom.User, text: question, sources: [], isFinished: true, type: "question", suggestions: [] },
    ]);
    const newMessage = {
      action: "generative",
      question,
      conversationHistory:
        messages.length > 1
          ? messages.map((m) => ({
              role: m.from === MessageFrom.System ? "assistant" : "user",
              text: m.text,
            }))
          : [],
      libraryId,
      amplyfiEntityId,
      ...(documentIds.length > 0
        ? {
            documentIds,
            type: "question-about-documents",
          }
        : {
            startDate: getStartOfPastDayUtc(timeFilter),
            type: "question",
          }),
    };
    socket.sendJsonMessage(newMessage);
    analyticsEvent("QuestionAndAnswer", "QuestionAsked", question);
  }

  function clearMessages(): void {
    setMessages([documentIds.length > 0 ? defaultDocMessage(type) : defaultMessage]);
  }

  return { messages, sendMessage, clearMessages };
}
