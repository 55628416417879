import axios from "axios";
import { FeedDocument, MonitoringDocument } from "../models/monitoring";
import { EventsFilter, StrengthFilter } from "../store/hooks/useFiltersStore";

const v2Url = `${process.env.REACT_APP_ANALYSE_API}/v2`;
const apiUrl = `${process.env.REACT_APP_ANALYSE_API}/v3`;

export type FeedType = "all" | UserResponseFeedbackState;
export type FeedSortBy = "published" | "added" | "relevancy";
export type SortOrder = "descending" | "ascending";

export interface FeedDocumentCountResponse {
  documentCount: number;
}

export interface FeedResponse<T = FeedDocument> extends FeedDocumentCountResponse {
  documents: T[];
  inboxDocumentCount: number;
  favouriteDocumentCount: number;
  discardedDocumentCount: number;
  paginationToken: string | null;
}

export interface PagedData<T extends FeedDocument> {
  pages: FeedResponse<T>[];
}

export type UserResponseFeedbackState = "inbox" | "discarded" | "favourites";

export async function getSubscribedLibrariesFeed(
  organisationId: string,
  feedType: FeedType,
  paginationToken: string | null,
  searchTerm: string | null,
  alertTypes: string[],
  feedContent: string | null,
  userTags: string[],
  strength: StrengthFilter,
  events: EventsFilter,
  sectors: string[],
  sources: string[],
  topics: string[],
  sortBy?: FeedSortBy,
  sortOrder?: SortOrder,
  startDate?: string,
  endDate?: string
): Promise<FeedResponse<MonitoringDocument>> {
  const { data } = await axios.get<FeedResponse<MonitoringDocument>>(
    `${apiUrl}/organisations/${organisationId}/alerts-feed`,
    {
      params: {
        alertTypes,
        feedType,
        sortBy,
        sortOrder,
        startDate,
        userTags,
        events,
        sources,
        topics,
        feedContent: feedContent ?? undefined,
        paginationToken: paginationToken ? decodeURIComponent(paginationToken) : undefined,
        searchTerm: searchTerm ?? undefined,
        strength,
        sectors,
        endDate,
      },
    }
  );
  return data;
}

export async function getLibraryFeed(
  organisationId: string,
  libraryId: string,
  feedType: FeedType,
  paginationToken: string | null,
  searchTerm: string | null,
  alertTypes: string[],
  feedContent: string | null,
  userTags: string[],
  strength: StrengthFilter,
  events: EventsFilter,
  sectors: string[],
  sources: string[],
  topics: string[],
  sortBy?: FeedSortBy,
  sortOrder?: SortOrder,
  startDate?: string,
  endDate?: string
): Promise<FeedResponse<MonitoringDocument>> {
  const { data } = await axios.get<FeedResponse<MonitoringDocument>>(
    `${apiUrl}/organisations/${organisationId}/alerts-feed`,
    {
      params: {
        alertTypes,
        libraryId,
        feedType,
        sortBy,
        sortOrder,
        startDate,
        userTags,
        events,
        sources,
        topics,
        feedContent: feedContent ?? undefined,
        paginationToken: paginationToken ? decodeURIComponent(paginationToken) : undefined,
        searchTerm: searchTerm ?? undefined,
        strength,
        sectors,
        endDate,
      },
    }
  );
  return data;
}

export async function getAlertFeed(
  organisationId: string,
  libraryId: string,
  amplyfiEntityId: string,
  feedType: FeedType,
  paginationToken: string | null,
  searchTerm: string | null,
  alertTypes: string[],
  feedContent: string | null,
  userTags: string[],
  strength: StrengthFilter,
  events: EventsFilter,
  sectors: string[],
  sources: string[],
  topics: string[],
  sortBy?: FeedSortBy,
  sortOrder?: SortOrder,
  startDate?: string,
  endDate?: string
): Promise<FeedResponse<MonitoringDocument>> {
  const { data } = await axios.get<FeedResponse<MonitoringDocument>>(
    `${apiUrl}/organisations/${organisationId}/alerts-feed`,
    {
      params: {
        alertTypes,
        libraryId,
        feedType,
        sortBy,
        sortOrder,
        startDate,
        userTags,
        entityId: amplyfiEntityId,
        events,
        sources,
        topics,
        feedContent: feedContent ?? undefined,
        paginationToken: paginationToken ? decodeURIComponent(paginationToken) : undefined,
        searchTerm: searchTerm ?? undefined,
        strength,
        sectors,
        endDate,
      },
    }
  );
  return data;
}

export async function getDirectAlertDocument(organisationId: string, documentId: string): Promise<MonitoringDocument> {
  const { data } = await axios.get<FeedResponse<MonitoringDocument>>(
    `${apiUrl}/organisations/${organisationId}/alerts-feed`,
    {
      params: {
        documentId,
      },
    }
  );
  return data.documents[0];
}

export async function getAlertDocument(
  organisationId: string,
  libraryId: string,
  documentId: string
): Promise<MonitoringDocument> {
  const { data } = await axios.get<FeedResponse<MonitoringDocument>>(
    `${apiUrl}/organisations/${organisationId}/alerts-feed`,
    {
      params: {
        libraryId,
        documentId,
      },
    }
  );
  return data.documents[0];
}

export async function linkEntityToLibrary(
  organisationId: string,
  libraryId: string,
  amplyfiEntityId: string
): Promise<void> {
  await axios.put(
    `${v2Url}/organisations/${organisationId}/libraries/${libraryId}/amplyfi-entities/${amplyfiEntityId}`
  );
}

export type FilterType = "sector" | "source" | "documentTopicId" | "documentTypeTag";

export async function getAlertFeedHistoricFilterCount(
  organisationId: string,
  libraryId: string,
  filterType: FilterType,
  id: string,
  startDate: string
): Promise<FeedDocumentCountResponse & { id: string }> {
  const { data } = await axios.get<FeedDocumentCountResponse>(
    `${apiUrl}/organisations/${organisationId}/alerts-feed/document-count`,
    {
      params: {
        libraryId,
        startDate,
        [filterType]: id,
      },
    }
  );
  return { ...data, id };
}
