import { EntityType } from "./entity";
import { DocumentType } from "./search";

export interface DocumentListItem {
  title: string;
  source: string;
  date: string;
  relevance: number;
  id: string;
  url: string;
  sentiment: number;
  snippet: string;
  type: DocumentType;
  index: number;
}

export interface DocumentDetailedItem {
  id: string;
  title: string;
  url: string;
  source: string;
  authors: string[];
  body: string;
  publishDate: string;
  publishLocation: string;
  entities: DocumentEntity[];
  sentiment: number;
  type: string;
}

export interface DocumentEntity {
  id: string;
  name: string;
  type: EntityType | undefined;
  documentId: string;
  startingPos: number;
  endingPos: number;
  startingSentencePos: number;
  endingSentencePos: number;
  sentiment: number;
}

export interface DocumentFilters {
  minimumSentiment: number;
  maximumSentiment: number;
}

export enum SortTypes {
  Ascending = "Ascending",
  Descending = "Descending",
}

export type SortType = SortTypes.Ascending | SortTypes.Descending;
