import { create } from "zustand";

interface SearchStoreState {
  clear: () => void;
  search: string | null;
  setSearch: (search: string | null) => void;
}

const useSearchStore = create<SearchStoreState>((set) => ({
  clear: () => set({ search: null }),
  search: null,
  setSearch: (search) => set({ search }),
}));

export default useSearchStore;
