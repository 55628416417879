import { testId } from "@amplyfi/ui-components/testHelpers";
import AiButton from "@amplyfi/ui-components/components/Button";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";
import { analyticsEvent } from "../../../../helpers/analytics";
import { EnterHarvestQuery } from "../CreateHarvest/EnterHarvestQuery";
import { HarvestQueryExamples } from "../CreateHarvest/HarvestQueryExamples";
import { HarvestDescription } from "../CreateHarvest/HarvestDescription";
import { createHarvest, validateHarvest } from "../../../../http/harvest";
import { HarvestMetadata } from "../../../../models/harvest";
import { LibraryItem } from "../../../../models/search/documentLibrary";
import { isQueryActionable, isQueryUseful, MAX_HARVEST_DOCUMENTS } from "../helpers/queryValidation";
import { AccountGroup } from "../../../../models/account";
import { transformQueryDefaults } from "../helpers/query";
import { DocumentPreviewList } from "../CreateHarvest/DocumentPreview";
import { HarvestConfig } from "../models";
import { useStyles } from "./modalStyles";
import { harvestSourceCanReturnPreviews, harvestSourceHasLanguageDefaults } from "../helpers/harvest";
import { LibraryEntity } from "../../../../http/harvesterCollections";
import { useAlertTypes } from "../../../../hooks/useAlertTypes";
import { createAlertForLibrary } from "../../../../http/alert-types";

interface StepEnterQueryProps {
  language: string;
  library: LibraryItem;
  organisation: AccountGroup;
  entity: LibraryEntity | null;
  setLanguage: (language: string) => void;
  harvestConfig: HarvestConfig;
  metadata: HarvestMetadata | null;
  query: string;
  setQuery: (query: string) => void;
  onBack: () => void;
  postHarvestCreate: () => void;
  setMetadata: (metadata: HarvestMetadata) => void;
}

const VALIDATION_EMAIL = "support@amplyfi.com";

export const StepEnterQuery = ({
  language,
  library,
  entity,
  organisation,
  setLanguage,
  harvestConfig,
  metadata,
  query,
  setQuery,
  onBack,
  postHarvestCreate,
  setMetadata,
}: StepEnterQueryProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [hasBeenValidated, setHasBeenValidated] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [key, setKey] = useState("");
  const [generateSignals, setGenerateSignals] = useState(false);
  const { data: alertTypes } = useAlertTypes();
  const alertIds = alertTypes?.filter(alertType => !!(harvestConfig.alertTypes || []).find(a => a.toLowerCase() === alertType.name.toLowerCase()))
    .map((alertType) => alertType.alertTypeId) || [];

  const handleCreateHarvest = async ({ count }: HarvestMetadata) => {
    setIsBusy(true);
    const transformedQuery = harvestSourceHasLanguageDefaults(harvestConfig)
      ? transformQueryDefaults(query, { language })
      : query;

    try {
      await createHarvest(
        organisation,
        library,
        entity,
        transformedQuery,
        harvestConfig.id,
        count,
        MAX_HARVEST_DOCUMENTS,
        true,
        generateSignals
      );
      await Promise.all(alertIds.map(alertTypeId => createAlertForLibrary(library.id, {
        alertTypeId: alertTypeId as string,
        libraryId: library.id,
        amplyfiEntityId: "ALL",
      })))
      analyticsEvent("Harvest", "HarvestCreated");
      dispatch(setSnack({ body: "Harvest started successfully", title: "Success" }));
    } catch (e) {
      dispatch(setSnack({ body: "There was a problem starting the harvest", title: "Failure" }));
    }
    setIsBusy(false);
    postHarvestCreate();
  };

  const handleExternalValidation = async () => {
    setIsBusy(true);
    const transformedQuery = harvestSourceHasLanguageDefaults(harvestConfig)
      ? transformQueryDefaults(query, { language })
      : query;
    const validation = await validateHarvest(transformedQuery, harvestConfig.id);
    analyticsEvent("Harvest", "HarvestValidation", `${validation.isValid}`);
    setMetadata(validation);
    setHasBeenValidated(validation.isValid);
    setIsBusy(false);
  };

  const forceSetQuery = (q: string) => {
    setQuery(q);
    setKey(q);
  };

  useEffect(() => {
    setHasBeenValidated(false);
  }, [query]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: "50%", paddingRight: 35 }}>
        <HarvestDescription
          language={language}
          library={library}
          organisation={organisation}
          setLanguage={setLanguage}
          harvestConfig={harvestConfig}
          entity={entity}
        />
        <EnterHarvestQuery
          email={VALIDATION_EMAIL}
          key={key}
          metadata={metadata}
          query={query}
          setQuery={setQuery}
          harvestConfig={harvestConfig}
        />
        <HarvestQueryExamples harvestConfig={harvestConfig} setQuery={forceSetQuery} />
        {!metadata && <div style={{ height: 105 }}></div>}
        <div>
          <FormControlLabel
            style={{ marginTop: 8 }}
            control={
              <Switch
                checked={generateSignals}
                onChange={(_, _generateSignals) => setGenerateSignals(_generateSignals)}
              />
            }
            label="Generate Signals"
          />
        </div>
        <div className={classes.buttonsContainer}>
          <AiButton
            data-testid={testId("harvest", "create-harvest", "back")}
            className={classes.button}
            color="secondary"
            variant="outlined"
            type="button"
            onClick={onBack}
          >
            Back
          </AiButton>
          {!!metadata && isQueryActionable(metadata) && hasBeenValidated ? (
            <>
              <AiButton
                data-testid={testId("harvest", "create-harvest", "validate")}
                disabled
                className={classes.button}
                color="secondary"
                variant="outlined"
                type="button"
              >
                Validate
              </AiButton>
              <AiButton
                data-testid={testId("harvest", "create-harvest", "create")}
                disabled={isBusy}
                className={clsx(classes.button, classes.buttonPrimary)}
                onClick={() => handleCreateHarvest(metadata)}
                type="button"
                aria-busy={isBusy}
              >
                Harvest{" "}
                {isBusy && (
                  <span className={classes.buttonLoading}>
                    <AiCircularProgress size={20} />
                  </span>
                )}
              </AiButton>
            </>
          ) : (
            <>
              <AiButton
                data-testid={testId("harvest", "create-harvest", "validate")}
                disabled={!isQueryUseful(query) || isBusy}
                className={classes.button}
                onClick={handleExternalValidation}
                color="secondary"
                variant="outlined"
                type="button"
                aria-busy={isBusy}
              >
                Validate{" "}
                {isBusy && (
                  <span className={classes.buttonLoading}>
                    <AiCircularProgress size={20} />
                  </span>
                )}
              </AiButton>
              <AiButton
                data-testid={testId("harvest", "create-harvest", "create")}
                disabled
                className={clsx(classes.button, classes.buttonPrimary)}
                type="button"
              >
                Harvest
              </AiButton>
            </>
          )}
        </div>
      </div>
      <div className={classes.previewContainer}>
        <Typography variant="h2">Document Previews</Typography>
        <hr />
        {harvestSourceCanReturnPreviews(harvestConfig) ? (
          <>
            {!metadata || !!metadata?.documentPreviews?.length ? (
              <DocumentPreviewList documentPreviews={metadata?.documentPreviews} />
            ) : (
              <p>No previews are available.</p>
            )}
          </>
        ) : (
          <p>Previews for this source are not currently supported.</p>
        )}
      </div>
    </div>
  );
};
