import { useInfiniteQuery } from "react-query";
import { AmplyfiUnknownEntity } from "../components/Pages/EntityKnowledgeBase/models";
import {
  EntityResponse,
  getAmplyfiEntity,
  getUnverifiedAmplyfiEntities,
  searchAmplyfiEntities,
} from "../http/amplyfiEntities";
import { useQuery, UseQueryResult } from "./useQuery";

const ignoreAmplyfiEntityIds = ["ALL"];

interface Config {
  cacheTime?: number;
  refetchInterval?: number;
  staleTime?: number;
}

interface AmplyfiEntitiesOptions {
  id?: string;
  name?: string;
}

const isIgnoredAmplyfiEntity = (id: string): boolean => ignoreAmplyfiEntityIds.includes(id);

export const useAmplyfiEntities = (options: AmplyfiEntitiesOptions): UseQueryResult<EntityResponse> =>
  useQuery(["amplyfi-entities", options], async () => {
    const { id, name } = options;
    return id || name
      ? searchAmplyfiEntities({ id, name })
      : ({ count: 0, entities: [], paginationToken: null } as EntityResponse);
  });

export const useAmplyfiEntityId = <T extends AmplyfiUnknownEntity = AmplyfiUnknownEntity>(
  id?: string,
  config: Config = { staleTime: Infinity }
): UseQueryResult<T> =>
  useQuery(
    ["amplyfi-entity", id],
    async () => (id && !isIgnoredAmplyfiEntity(id) ? getAmplyfiEntity(id) : Promise.resolve(undefined)),
    config
  );

export function useUnverifiedAmplyfiEntities() {
  const query = useInfiniteQuery(
    ["amplyfi-entity", "unverified"],
    async ({ pageParam = null }) => getUnverifiedAmplyfiEntities(pageParam),
    {
      staleTime: Infinity,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      getNextPageParam: (lastPage) => lastPage?.paginationToken || undefined,
    }
  );
  return {
    ...query,
    data: query.data?.pages.reduce<EntityResponse>(
      (acc, page) => {
        return {
          ...page,
          entities: [...acc.entities, ...page.entities],
          paginationToken: page.paginationToken,
        };
      },
      {
        count: 0,
        entities: [],
        paginationToken: null,
      }
    ),
  };
}
