import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { useEffect } from "react";
import useDocumentStatistics from "../../../../hooks/useDocumentStatistics";
import { MonitoringDocument } from "../../../../models/monitoring";
import { AbstractTabProps } from "../tabs";
import Tab from "./Tab";

export default function StatisticsTab({ documentId, document, documentMeta, onLoadCallback }: AbstractTabProps) {
  const id = (document as MonitoringDocument)?.documentId || documentId;
  const { data, isLoading } = useDocumentStatistics(id);

  useEffect(() => {
    if (data?.length) {
      onLoadCallback?.(data.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return isLoading ? (
    <AiCircularProgress />
  ) : (
    <Tab
      showCopyMultiple
      bullets={data}
      copyMultiple={{
        handler: documentMeta,
        text: "Copy all",
        tooltipText: "Copy all Statistics and document info",
      }}
      copySingular={{ text: "Copy Statistic", handler: (a) => a, tooltipText: "Copy single Statistic" }}
      noContent="No statistical information was extracted"
    />
  );
}
