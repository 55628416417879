
export function defaultRoutes() {
  return [
    "/feed",
    "/feed/tab/:tab",
    "/feed/tab/:tab/:resourceId",
    "/feed/library/:libraryId",
    "/feed/library/:libraryId/tab/:tab",
    "/feed/library/:libraryId/tab/:tab/:resourceId",
    "/feed/library/:libraryId/interest/:amplyfiEntityId",
    "/feed/library/:libraryId/interest/:amplyfiEntityId/tab/:tab",
    "/feed/library/:libraryId/interest/:amplyfiEntityId/tab/:tab/:resourceId",
  ];
}

export default function Outlet() {
  return (
    <div></div>
  );
}
