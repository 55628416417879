import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { Typography } from "@material-ui/core";
import { blueShades } from "../../Charts/helpers/colourScaleHexes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scale: {
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    greyContainer: {
      display: "flex",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
    },
    scaleEntry: {
      height: 10,
      width: 19,
    },
    textContainer: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(0.5),
      width: 200,
    },
  })
);

export default function HeatmapScale(): JSX.Element {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.scale}>
        <Typography style={{ paddingRight: 8 }}>Low</Typography>
        <div style={{ display: "flex" }}>
          {blueShades.map((hex) => (
            <div className={classes.scaleEntry} key={hex} style={{ background: hex }} />
          ))}
        </div>
        <Typography style={{ paddingLeft: 8 }}>High</Typography>
      </div>
    </div>
  );
}
