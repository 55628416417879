import { getDocument } from "../http/document";
import { useQuery } from "./useQuery";

export default function useDocument(documentId: string | undefined) {
  return useQuery(
    ["document", documentId],
    async () => {
      if (!documentId) {
        return null;
      }
      return getDocument(documentId, []);
    },
    { refetchInterval: false }
  );
}
