import { useQuery, UseQueryResult } from "./useQuery";
import { TimeIntervalType } from "../helpers/timeIntervals";
import { convertSearchStateToSearchRequestV3 } from "../helpers/search";
import { SearchRequestV3 } from "../models/search";
import { useAnalyseSelector } from "../store/reducers";
import { ElementResponse, ElementType } from "../models/element";
import { getElements } from "../http/element";
import { useFlags } from "launchdarkly-react-client-sdk";

export const BAR_CHARTS_ELEMENT_COUNT = 250;

export default function useElement(
  dateInterval: TimeIntervalType,
  elementTypes: ElementType[],
  searchTerm?: string
): UseQueryResult<ElementResponse> {
  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const searchRequest: SearchRequestV3 = {
    ...convertSearchStateToSearchRequestV3(url),
  };
  const flags = useFlags();

  searchRequest.systemFilters = {
    ...searchRequest.systemFilters,
    dateInterval,
    size: BAR_CHARTS_ELEMENT_COUNT,
    elementTypes: elementTypes,
  };

  return useQuery(["elements", searchRequest, url.libraries, searchTerm], async () => {
    const response = await getElements(
      { ...searchRequest, useElasticSampler: !flags.disableElasticSampling },
      url.libraries,
      searchTerm
    );

    return {
      elements: response,
    };
  });
}
