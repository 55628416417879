import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import Container from "@material-ui/core/Container";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { analyticsEvent } from "../../../helpers/analytics";
import useCountApi from "../../../hooks/useCountApi";
import { useWholeDocuments } from "../../../hooks/useDocuments";
import useDocumentTimeline from "../../../hooks/useDocumentTimeline";
import useSavedAnalysis from "../../../hooks/useSavedAnalysis";
import { SnackFrequencyType } from "../../../models/snack";
import { useAnalyseSelector } from "../../../store/reducers";
import { resetFilters } from "../../../store/reducers/searchRequest/actions";
import {
  hasAnyEntitiesSelector,
  hasAnyQueriesSelector,
  hasOnlyNoneSelector,
  resetFilterOptions,
} from "../../../store/reducers/searchRequest/urlReducer";
import { setSnack } from "../../../store/reducers/ui/snackReducer";
import Header from "../../Widgets/Header/Header";
import BuildQueryCard from "../../Widgets/Landing/Cards/BuildQueryCard";
import DocumentLibraries from "../../Widgets/Landing/Cards/DocumentLibraries";
import { usePageStyles } from "../useLandingStyles";

function docCountString(docCount: number | undefined, enableSearch: boolean, libraryIds: string[]) {
  return (enableSearch && docCount !== undefined) || libraryIds.length
    ? `Analyse ${docCount?.toLocaleString()} docs`
    : "Search";
}

function getTitle(props: { title?: string; emptyTitle: string; loading: boolean }) {
  const { title, emptyTitle, loading } = props;
  return loading ? <AiCircularProgress size={20} useContainer={false} /> : title || emptyTitle;
}

export default function Landing(): JSX.Element {
  const classes = usePageStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const libraryIds = url.libraryIds;
  const hasAnyQueries = useAnalyseSelector(hasAnyQueriesSelector);
  const hasEntities = useAnalyseSelector(hasAnyEntitiesSelector);
  const hasOnlyNone = useAnalyseSelector(hasOnlyNoneSelector);
  const [validated, setValidated] = useState(false);
  const { isLoading: timelineLoading } = useDocumentTimeline();
  const { data: docCount, isLoading: countLoading } = useCountApi(url, true, true);
  const { isLoading: documentsLoading } = useWholeDocuments();
  const [advanced, setAdvanced] = useState(!!url.lucene);
  const advancedValidated = advanced && validated && hasAnyQueries;
  const standardValidated = !advanced && hasAnyQueries && !hasOnlyNone;
  const isLoading = documentsLoading || timelineLoading;
  const enableSearch = !isLoading && (advancedValidated || standardValidated);
  const hasResults = !!docCount && docCount > 0;
  const { savedAnalysisId } = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { data: analysisData, isLoading: analysisLoading } = useSavedAnalysis(savedAnalysisId);

  useEffect(() => {
    if (!history.location.search) {
      dispatch(resetFilterOptions());
    }
  }, [dispatch, history.location.search]);

  useEffect(() => {
    dispatch(resetFilterOptions());
  }, [dispatch]);

  useEffect(() => {
    if (hasEntities) {
      dispatch(
        setSnack({
          title: "Limited Entity Search Alert",
          body: 'Warning! Entity Searches are Limited - The entities (organisations, people, places) used in your search will not be recognised in the Titles, Abstracts and Bodies of Patents & Scientific Papers. If you wish to search these terms please select the "Free Text" option.',
          type: SnackFrequencyType.OncePerSession,
        })
      );
    }
  }, [dispatch, hasEntities]);

  return (
    <Container maxWidth="xl">
      <div className={classes.landingMainContainer}>
        <Header
          title={getTitle({ title: analysisData?.name, emptyTitle: "New Analysis", loading: analysisLoading })}
          buttonText={docCountString(docCount, enableSearch, url.libraryIds)}
          buttonDisabled={(!enableSearch || !hasResults) && libraryIds.length <= 0}
          isLoading={countLoading}
          onButtonClicked={() => {
            history.push({
              pathname: "/analyse/overview",
              search: window.location.search,
            });
            analyticsEvent("Landing", "QuerySubmitted", "Submitting query");
            dispatch(resetFilters());
          }}
        />
        <div className={classes.landingContainer}>
          <div className={classes.landingSubContainer}>
            <BuildQueryCard
              advanced={advanced}
              setAdvanced={setAdvanced}
              validated={validated}
              setValidated={setValidated}
            />
          </div>
          <div className={classes.landingSubContainer}>
            <DocumentLibraries />
          </div>
        </div>
      </div>
    </Container>
  );
}
