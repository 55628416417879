import { useQuery } from "./useQuery";
import { convertSearchStateToSearchRequestV2 } from "../helpers/search";
import { searchDocuments } from "../http/document";
import { SearchResponse } from "../models/search";
import { useAnalyseSelector } from "../store/reducers";
import { hasAnyQueriesSelector } from "../store/reducers/searchRequest/urlReducer";

export function useWholeDocuments(): {
  isLoading: boolean;
  data: SearchResponse;
} {
  const { parsed: url } = useAnalyseSelector((x) => x.searchRequest.url);
  const hasAnyQueries = useAnalyseSelector(hasAnyQueriesSelector);
  const request = convertSearchStateToSearchRequestV2(url);
  const libraries = url.libraries;
  const { isLoading, data = {} as SearchResponse } = useQuery(["allDocuments", request, libraries], async () => {
    if (!hasAnyQueries || url.libraries.length === 0) {
      return undefined;
    }
    return searchDocuments(request, libraries);
  });
  return { isLoading, data };
}
