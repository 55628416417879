import { Typography } from "@material-ui/core";
import { DocumentPreview } from "../../../../models/harvest";

const DocumentPreviewItem = ({ name, displayUrl, snippet }: DocumentPreview): JSX.Element => (
  <div>
    {displayUrl !== name && <Typography variant="h3">{name}</Typography>}
    <a href={displayUrl}>{displayUrl}</a>
    <p>{snippet}</p>
    <hr />
  </div>
);

interface DocumentPreviewListProps {
  documentPreviews?: DocumentPreview[];
}

export const DocumentPreviewList = ({ documentPreviews }: DocumentPreviewListProps): JSX.Element => (
  <div>
    {documentPreviews?.map((item) => (
      <DocumentPreviewItem
        name={item.name}
        displayUrl={item.displayUrl}
        snippet={item.snippet}
        id={item.id}
        key={item.id}
      />
    ))}
  </div>
);
