import Button from "@amplyfi/ui-components/components/Button";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import TextSearch from "@amplyfi/ui-components/components/TextSearch";
import {
  Checkbox,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { link } from "../../../css/mixins";
import { dateFormat } from "../../../helpers/dateHelpers";
import { getComparator, Order } from "../../../helpers/filterHelper";
import { MAX_DOCUMENT_LIMIT } from "../../../helpers/getSelectedFiltersCount";
import useCountApi from "../../../hooks/useCountApi";
import useDocumentLibraries from "../../../hooks/useDocumentLibraries";
import { LibrariesType } from "../../../models/queryParams";
import { DocumentType } from "../../../models/search";
import { LibraryItem } from "../../../models/search/documentLibrary";
import { useAnalyseSelector } from "../../../store/reducers";
import { updateDocumentLibraryId, updateSavePending } from "../../../store/reducers/searchRequest/urlReducer";
import SimpleDialog from "../Dialog/SimpleDialog";
import DocCountLabel from "./DocCountLabel";
import { clearSelectedDocument } from "../../../store/reducers/searchResponse/relatedDocumentViewerReducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.componentBackground.main,
      alignItems: "center",
      marginTop: theme.spacing(4),
    },
    searchSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    textSearch: {
      backgroundColor: theme.palette.componentBackground.main,
    },
    link: {
      ...link(theme),
      marginRight: theme.spacing(3),
    },
    tableSection: {
      height: "400px",
      margin: `${theme.spacing(4)}px 0`,
    },
    footer: {
      borderTop: `1px solid ${theme.palette.borders.contrastText}`,
      textAlign: "end",
      display: "flex",
      alignItems: "baseline",
      justifyContent: "right",
    },
    errorText: {
      color: "#E6385B",
      marginRight: theme.spacing(2),
    },
    button: {
      margin: `${theme.spacing(2)}px 0 0 ${theme.spacing(2)}px`,
    },
  })
);

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof LibraryItem;
  label: string;
  numeric: boolean;
  width: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "LIBRARY NAME",
    width: 190,
  },
  {
    id: "lastDocumentReceived",
    numeric: true,
    disablePadding: false,
    label: "CONTENT UPDATED",
    width: 150,
  },
  {
    id: "documentCount",
    numeric: true,
    disablePadding: false,
    label: "TOTAL DOCS",
    width: 145,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof LibraryItem) => void;
  onClearAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onClearAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof LibraryItem) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={numSelected === 0}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onClearAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface SelectLibrariesProps {
  onApply: () => void;
}

export default function SelectLibraries(props: SelectLibrariesProps): JSX.Element {
  const { onApply } = props;
  const classes = useStyles();
  const urlState = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { data = [], isLoading } = useDocumentLibraries();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof LibraryItem>("documentCount");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [warningPromptEnabled, setWarningPromptEnabled] = useState<boolean>(false);
  const { data: docCount = 0 } = useCountApi({
    ...urlState,
    libraryIds: data.filter((library) => selected.includes(library.id)).map((r) => r.id),
  });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof LibraryItem) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClearAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const getFilteredData = (): LibraryItem[] => {
    return searchText.length ? data.filter((r) => r.name.toLowerCase().includes(searchText.toLowerCase())) : data;
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  useEffect(() => {
    if (data.length && urlState.libraryIds.length) {
      setSelected(data.filter((r) => urlState.libraryIds.includes(r.id)).map((r) => r.id));
    } else if (data.length && !urlState.libraryIds.length) {
      const maxDocLibrary = data.reduce((prev, current) => {
        return prev.documentCount > current.documentCount ? prev : current;
      });
      dispatch(updateDocumentLibraryId(maxDocLibrary.id));
    }
  }, [urlState.libraryIds, data, dispatch]);

  function handleShowAnalysis() {
    if (docCount > MAX_DOCUMENT_LIMIT) {
      setWarningPromptEnabled(true);
    } else {
      dispatch(updateDocumentLibraryId(data.filter((library) => selected.includes(library.id)).map((r) => r.id)));
      dispatch(clearSelectedDocument());
      dispatch(updateSavePending(true));
      onApply();
    }
  }

  function getDocumentTypes(libraryIds: readonly string[]): LibrariesType[] {
    let libraries = [] as LibrariesType[];
    if (libraryIds.includes("news")) {
      libraries = [DocumentType.News];
      if (libraryIds.length > 1) {
        libraries = [...libraries, "Private"];
      }
    } else if (libraryIds.length > 0) {
      libraries = ["Private"];
    }
    return libraries;
  }

  return (
    <div className={classes.container}>
      <div className={classes.searchSection}>
        <TextSearch
          label="Search All Libraries"
          focusedLabel="Type and hit enter"
          className={classes.textSearch}
          value={searchText}
          setValue={setSearchText}
          totalCount={getFilteredData().length}
        />
        <div className={classes.searchSection}>
          <Typography variant="h6" className={classes.link} onClick={() => setSelected([])}>
            Clear
          </Typography>
        </div>
      </div>
      <div className={classes.tableSection}>
        {isLoading ? (
          <AiCircularProgress />
        ) : (
          <TableContainer className={classes.tableSection}>
            <Table stickyHeader>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onClearAllClick={handleClearAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(getFilteredData(), getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.id as string);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id as string)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isItemSelected} />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        {row.lastDocumentReceived ? dateFormat(row.lastDocumentReceived) : "-"}
                      </TableCell>
                      <TableCell align="right">{row.documentCount.toLocaleString()}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className={classes.footer}>
        {!selected.length && !isLoading ? (
          <Typography variant="button" className={classes.errorText}>
            Please Select at Least 1 Library
          </Typography>
        ) : (
          <DocCountLabel
            urlState={{
              ...urlState,
              libraryIds: data.filter((library) => selected.includes(library.id)).map((r) => r.id),
              libraries: getDocumentTypes(selected),
            }}
          />
        )}
        <Button disabled={!selected.length} className={classes.button} onClick={handleShowAnalysis} type="button">
          Show Analysis
        </Button>
      </div>
      {warningPromptEnabled && (
        <SimpleDialog
          title="Warning: Slow Performance"
          content="You have selected more than 10 million documents for Analysis, this may result in slow loading times, or errors. Are you sure you want to proceed?"
          onClose={() => setWarningPromptEnabled(false)}
          onConfirm={() => {
            dispatch(updateDocumentLibraryId(data.filter((library) => selected.includes(library.id)).map((r) => r.id)));
            setWarningPromptEnabled(false);
            onApply();
          }}
        />
      )}
    </div>
  );
}
