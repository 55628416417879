import axios from "axios";
import { DocumentDetailedItem } from "../models/document";
import { Entity } from "../models/entity";
import { SearchRequestV2, SearchResponse } from "../models/search";
import { AcademicDocSearchResponse, PatentSearchResponse } from "../models/search/search";
import { getAnalysisV2Url } from "./general";
import { enableStatus } from "../helpers/axios";
import { LibrariesType } from "../models/queryParams";

export async function getDocument(
  documentId: string,
  selectedDocumentFilters: Entity[],
  libraries: LibrariesType[] | null = null
): Promise<{ data: DocumentDetailedItem; status: number }> {
  return await axios.post<DocumentDetailedItem>(
    `${getAnalysisV2Url(`documents/${encodeURIComponent(documentId)}`, libraries)}`,
    selectedDocumentFilters
  );
}

export async function searchDocuments(
  searchRequest: SearchRequestV2,
  docTypes: LibrariesType[] | null = null
): Promise<SearchResponse> {
  const searchResponse = await axios.post<SearchResponse>(getAnalysisV2Url("search/documents", docTypes), searchRequest);

  return searchResponse.data;
}

export async function getFirstDocument(
  searchRequest: SearchRequestV2,
  docTypes: LibrariesType[] | null = null
): Promise<{
  data: DocumentDetailedItem | undefined;
  status: number;
}> {
  const { data, status } = await axios.post<DocumentDetailedItem & PatentSearchResponse & AcademicDocSearchResponse>(
    getAnalysisV2Url("search/documents/first-result", docTypes),
    searchRequest,
    { validateStatus: enableStatus(404) }
  );
  return { data, status };
}

export async function getQuotes(documentId: string, size?: number): Promise<string[]> {
  const { data } = await axios.get<string[]>(getAnalysisV2Url(`documents/${documentId}/quotes`), { params: { size } });
  return data;
}

export async function getSummary(documentId: string): Promise<string[]> {
  const { data } = await axios.get<string[]>(getAnalysisV2Url(`documents/${documentId}/extractive-summary`));
  return data;
}

export async function getStatistics(documentId: string, size?: number): Promise<string[]> {
  const { data } = await axios.get<string[]>(getAnalysisV2Url(`documents/${documentId}/statistics`), { params: { size } });
  return data;
}
