import axios from "axios";
import { GeoMetricsResponse, GeoResponse } from "../models/geo";
import { LibrariesType } from "../models/queryParams";
import { SearchRequestV2 } from "../models/search";
import { getAnalysisV2Url } from "./general";

export async function getGeography(
  searchRequest: SearchRequestV2,
  docType: LibrariesType[] | null = null
): Promise<GeoResponse> {
  const searchResponse = await axios.post<GeoResponse>(getAnalysisV2Url("search/geography", docType), searchRequest);
  return searchResponse.data;
}

export async function getGeographyMetrics(
  searchRequest: SearchRequestV2,
  docType: LibrariesType[] | null = null
): Promise<GeoMetricsResponse> {
  const searchResponse = await axios.post<GeoMetricsResponse>(
    getAnalysisV2Url("search/geography/metrics", docType),
    searchRequest
  );
  return searchResponse.data;
}
