import AiButton from "@amplyfi/ui-components/components/Button";
import {
  createStyles,
  darken,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { getOrganisation } from "../../../helpers/user";
import { getNestedGroups, Group } from "../../../http/user-management";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import EmptyState from "../../Widgets/EmptyState/EmptyState";
import BasicSearch from "../../Widgets/Search/BasicSearch";
import AddGroupModal from "../Settings/Users/AddGroupModal";
import AbstractSettingsPage from "../User/AbstractSettingsPage";
import { canUserAccountDelegatedAdmin } from "./permissions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "90%",
      padding: theme.spacing(2),
    },
    header: {
      display: "flex",
      alignItems: "center",
    },
    connectorList: {
      marginTop: theme.spacing(1),
    },
    connectorListItem: {
      display: "flex",
      height: 60,
      alignItems: "center",
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),

      "&:hover": {
        background: darken(theme.palette.componentBackground.main, 0.1),
        cursor: "pointer",
      },
    },
    connectorListIcon: {
      height: 36,
      width: 36,
      objectFit: "contain",
    },
    connectorListTitle: {
      marginLeft: theme.spacing(1),
      flex: "1 1 auto",
    },
    row: {
      cursor: "pointer",
      "& > td": {
        paddingTop: 8,
        paddingBottom: 8,
      },
      "& > td > div": {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
  })
);

function GroupListItem(props: Group): JSX.Element {
  const { id, name, description } = props;
  const history = useHistory();
  const styles = useStyles();

  return (
    <TableRow onClick={() => history.push(`/manage/groups/${id}`)} className={styles.row}>
      <TableCell align="left">
        <div>
          <Typography className={styles.connectorListTitle} variant="body1" color="secondary">
            {name}
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        <Typography className={styles.connectorListTitle} variant="body2">
          {description}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default function GroupManagement(): JSX.Element {
  const { user } = useAuth0();
  const organisation = getOrganisation(user);
  const { data, isLoading, refetch } = useQuery(
    ["group-management"],
    () => (organisation ? getNestedGroups(organisation.id) : undefined),
    {
      refetchInterval: 0,
      cacheTime: 0,
    }
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [search, setSearch] = useState("");
  const styles = useStyles();
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 70,
    },
    {
      field: "description",
      headerName: "Description",
      width: 70,
    },
  ];

  const isEmpty = !isLoading && data?.length === 0;

  return (
    <AbstractSettingsPage
      title="Groups"
      actions={
        <div style={{ display: "flex", alignItems: "center" }}>
          {canUserAccountDelegatedAdmin(user) && (
            <AiButton style={{ marginLeft: "auto" }} onClick={() => setShowAddModal(true)} startIcon={<Add />}>
              Create Group
            </AiButton>
          )}
        </div>
      }
    >
      <Typography variant="h3" style={{ marginTop: 16 }}>
        {organisation?.name || <Skeleton />}
      </Typography>
      <BasicSearch fullWidth style={{ marginTop: 16 }} onTextChange={(value) => setSearch(value)} />
      {isEmpty ? (
        <EmptyState title="You currently don't have access to any groups" />
      ) : (
        <TableContainer style={{ maxHeight: "100%", overflow: "auto", marginTop: 16 }}>
          <Table padding="none" size="small" className={styles.connectorList}>
            <TableHead>
              <TableRow className={styles.row}>
                {columns.map((col) => (
                  <TableCell style={{ fontWeight: "bold" }}>{col.headerName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <Skeleton height={40} count={20} />
              ) : (
                data
                  ?.filter((group) => group.name.toLowerCase().includes(search.toLowerCase()))
                  .map((group) => <GroupListItem {...group} />)
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {showAddModal ? (
        <AddGroupModal
          onClose={() => {
            refetch();
            setShowAddModal(false);
          }}
        />
      ) : (
        <></>
      )}
    </AbstractSettingsPage>
  );
}
