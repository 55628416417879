import { List, makeStyles } from "@material-ui/core";
import { ReactComponent as SignalIcon } from "../../../../../../assets/signal.svg";
import { getDocumentLogo } from "../../../../../../helpers/imageHelpers";
import useInsight from "../../../../../../hooks/queries/insights/useInsight";
import useAIHistory from "../../../../../../hooks/useAIHistory";
import { useFeedViewStore } from "../../../../../../store/hooks/useFeedViewStore";
import useReaderStore from "../../../../../../store/hooks/useReaderStore";
import useSearchStore from "../../../../../../store/hooks/useSearchStore";
import EditableListItem from "../../EditableListItem";
import { signalsPage } from "../../filterMenu/feedFilters";
import OverviewSection from "./OverviewSection";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: 80,
    maxHeight: 80,
    padding: theme.spacing(1),
  },
}));

export default function Signals() {
  const { selectedDocumentId } = useReaderStore((state) => state);
  const { setFromDeepLink } = useReaderStore();
  const { setView } = useFeedViewStore();
  const { data } = useInsight(selectedDocumentId as string);
  const classes = useStyles();
  const { clear } = useSearchStore();
  const { pushDocument } = useAIHistory();
  return (
    <OverviewSection icon={<SignalIcon fill="#004fd0" />} title="Signals">
      <List>
        {data?.insights[0].sources.map((source) => (
          <EditableListItem
            disableGutters
            imageProps={{
              ErrorImage: <></>,
            }}
            imageContainerClass={classes.imageContainer}
            onClick={() => {
              clear();
              setFromDeepLink(true);
              setView(signalsPage);
              pushDocument(source.documentId, "signals");
            }}
            image={getDocumentLogo(source.documentId)}
            title={source.title}
            description={source.url}
          />
        ))}
      </List>
    </OverviewSection>
  );
}
