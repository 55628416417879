import AiButton from "@amplyfi/ui-components/components/Button";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { TestDataProps } from "@amplyfi/ui-components/types";
import { Done as DoneIcon } from "@material-ui/icons";
import {
  createStyles,
  FormControl,
  InputAdornment,
  makeStyles,
  Theme,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import useValidation from "../../../../hooks/useValidation";
import { zIndex } from "../../../../helpers/componentsZIndex";

interface AddNewOrganisationDialogProps extends Partial<TestDataProps> {
  name?: string;
  website?: string;
  altNames?: string;
  onClose?: () => void;
  onSave: (name: string, website: string, altNames: string[]) => void;
}

const ALT_NAME_DELIMITER = ",";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    field: {
      display: "block",
      marginBottom: spacing(1),
      marginTop: spacing(2),
    },
    hilight: {
      color: "#65a7e3",
    },
    subtext: {
      fontSize: 11,
      marginTop: spacing(1),
    },
    rule: {
      border: 0,
      borderTop: "1px solid #e8e8e8",
      marginBottom: spacing(2),
      marginTop: spacing(2),
    },
  })
);

export default function AddNewOrganisationDialog(props: AddNewOrganisationDialogProps): JSX.Element {
  const { "data-testid": dataTestId, name = "", website = "", altNames = "", onClose, onSave } = props;
  const classes = useStyles();
  const [orgName, setOrgName] = useState<string>(name);
  const [orgWebsite, setOrgWebsite] = useState<string>(website);
  const [orgAltNames, setOrgAltNames] = useState<string>(altNames);
  const [isSaving, setIsSaving] = useState(false);

  const { valid: isValidName } = useValidation(["minLength"], [], [orgName]);
  const { valid: isValidWebsite } = useValidation(["minLength"], [], [orgWebsite]);

  const sanitiseMultipleValues = (organisationAltNames: string): string[] =>
    organisationAltNames.split(new RegExp(`\\s*${ALT_NAME_DELIMITER}\\s*`)).filter((a) => a);

  async function addNewOrganisation() {
    setIsSaving(true);
    onSave(orgName, orgWebsite, sanitiseMultipleValues(orgAltNames));
  }

  return (
    <Dialog
      open
      style={{ zIndex: zIndex.selectMenu + 1 }}
      aria-labelledby="new-organisation-entity-title"
      data-testid={dataTestId}
    >
      <DialogTitle>Add a New Organisation</DialogTitle>
      <DialogContent>
        <Typography className={clsx(classes.subtext, classes.hilight)}>
          The more complete and accurate the details provided, the better your monitoring and analysis.
        </Typography>
        <FormControl className={classes.field}>
          <TextField
            fullWidth
            required
            aria-invalid={!isValidName}
            error={!isValidName}
            label="Name"
            InputLabelProps={{ shrink: true }}
            value={orgName}
            onChange={({ target: { value } }) => setOrgName(value)}
            onKeyDown={(e) => e.stopPropagation()}
            data-testid={testId(dataTestId, "new-organisation", "name")}
            InputProps={
              isValidName
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <DoneIcon />
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        </FormControl>
        <FormControl className={classes.field}>
          <TextField
            fullWidth
            required
            aria-invalid={!isValidWebsite}
            error={!!orgWebsite.length && !isValidWebsite}
            label="Organisation Website"
            InputLabelProps={{ shrink: true }}
            value={orgWebsite}
            onChange={({ target: { value } }) => setOrgWebsite(value)}
            onKeyDown={(e) => e.stopPropagation()}
            data-testid={testId(dataTestId, "new-organisation", "website")}
            InputProps={
              isValidWebsite
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <DoneIcon />
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        </FormControl>
        <FormControl className={classes.field}>
          <TextField
            fullWidth
            label="Alternative Names"
            InputLabelProps={{ shrink: true }}
            value={orgAltNames}
            onChange={({ target: { value } }) => setOrgAltNames(value)}
            onKeyDown={(e) => e.stopPropagation()}
            data-testid={testId(dataTestId, "new-organisation", "alternative-names")}
          />
          <Typography variant="body2" className={classes.subtext} color="textSecondary">
            <em>Comma separated names that this Organisation is known by e.g. BP, British Petroleum, BP Plc… etc</em>
          </Typography>
        </FormControl>
        <hr className={classes.rule} />
        <Typography variant="body2" className={classes.subtext} color="textSecondary" align="right">
          One of our team will check over this
        </Typography>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <AiButton amplyfiType="secondary" onClick={() => onClose()}>
            Cancel
          </AiButton>
        )}
        <AiButton disabled={isSaving || !isValidName || !isValidWebsite} onClick={() => addNewOrganisation()}>
          Save
        </AiButton>
      </DialogActions>
    </Dialog>
  );
}
