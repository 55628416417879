import { create } from "zustand";

interface QuestionAnswerStoreState {
  display: boolean;
  documentIds: string[];
  setDisplay: (display: boolean) => void;
  setDocumentIds: (documentIds: string[]) => void;
}

const useQuestionAnswerStore = create<QuestionAnswerStoreState>((set) => ({
  display: false,
  setDisplay: (display) => set({ display }),

  documentIds: [],
  setDocumentIds: (documentIds) => set({ documentIds }),
}));

export default useQuestionAnswerStore;
