import { Melrose } from "@amplyfi/ui-components/theme/colors";
import { Box, Button, Typography } from "@material-ui/core";
import { ScheduleOutlined } from "@material-ui/icons";
import Stack from "../../../stack/Stack";
import useReaderStore from "../../../../../../../store/hooks/useReaderStore";
import useWizardState from "../../../../../../../hooks/useWizardState";
import { CreateReportWizardState, initialState } from "../CreateReportWizard";
import useCreateReport from "../../../../../../../hooks/mutations/feed/useCreateReport";
import moment from "moment";
import { useEffect } from "react";
import { useRefreshReports } from "../../../../../../../hooks/queries/reports/useReports";
import { Report } from "../../../../../../../components/Pages/Feed/components/reader/create_report/CreateReportWizard";

export default function ReportConfirmPage() {
  const [state, setState] = useWizardState<CreateReportWizardState>("create-report");
  const setSelectedPanel = useReaderStore((state) => state.setSelectedPanel);
  const { mutateAsync } = useCreateReport(state.selectedEntityId as string);
  const refetch = useRefreshReports();

  async function finish() {
    const report: Report = {
      created: moment().utc().toISOString(),
      reportName: state.reportName as string,
      reportType: state.reportType as string,
      startDate: state.startDate,
      endDate: state.endDate,
      question: state.reportQuestion?.toString() ?? "",
      userId: state.userId,
    };

    await mutateAsync(report);
    refetch();
    setState(initialState);
  }

  useEffect(() => {
    finish();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" padding={12}>
      <Stack spacing={3} direction="vertical">
        <ScheduleOutlined
          fontSize="large"
          style={{ fontSize: 48, textAlign: "center", display: "block", marginLeft: "auto", marginRight: "auto" }}
          htmlColor={Melrose}
        />
        <Typography align="center" variant="h2">
          Your report is being generated
        </Typography>
        <Typography align="center" variant="body1">
          This may take a short while, we'll let you know when your report is ready
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            onClick={() => setSelectedPanel(null)}
            disableElevation
            variant="outlined"
            color="secondary"
            style={{ margin: "auto", paddingBottom: 12, paddingTop: 12, paddingLeft: 36, paddingRight: 36 }}
          >
            Done
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
