import { Validator } from "../../../helpers/fieldHandlers";

export const requiredValidationMessage = "";

export const invalidMessageFn = (validators: Validator[], required: boolean) => (val: string) => {
  if (required && val === "") {
    return requiredValidationMessage;
  }
  return validators.find(({ matches, negate }) => {
    const m = matches.test(val);
    return !negate ? m : !m;
  })?.message;
};
