import AiButton from "@amplyfi/ui-components/components/Button";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { TestDataProps } from "@amplyfi/ui-components/types";
import { Done as DoneIcon } from "@material-ui/icons";
import {
  createStyles,
  FormControl,
  InputAdornment,
  makeStyles,
  Theme,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import useValidation from "../../../../hooks/useValidation";
import { zIndex } from "../../../../helpers/componentsZIndex";

interface AddNewTechnologyDialogProps extends Partial<TestDataProps> {
  name?: string;
  description?: string;
  altNames?: string;
  onClose?: () => void;
  onSave: (name: string, description: string, altNames: string[]) => void;
}

const ALT_NAME_DELIMITER = ",";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    field: {
      display: "block",
      marginBottom: spacing(1),
      marginTop: spacing(2),
    },
    hilight: {
      color: "#65a7e3",
    },
    multiline: {
      "& .MuiInputBase-root": {
        height: "auto !important",
      },
      "& .MuiInputBase-inputMultiline": {
        padding: `0 ${spacing(0.5)}px`,
      },
    },
    subtext: {
      fontSize: 11,
      marginTop: spacing(1),
    },
    rule: {
      border: 0,
      borderTop: "1px solid #e8e8e8",
      marginBottom: spacing(2),
      marginTop: spacing(2),
    },
  })
);

export default function AddNewTechnologyDialog(props: AddNewTechnologyDialogProps): JSX.Element {
  const { "data-testid": dataTestId, name = "", description = "", altNames = "", onClose, onSave } = props;
  const classes = useStyles();
  const [techName, setTechName] = useState<string>(name);
  const [techAltNames, setTechAltNames] = useState<string>(altNames);
  const [techDescription, setTechDescription] = useState<string>(description);
  const [isSaving, setIsSaving] = useState(false);

  const { valid: isValidName } = useValidation(["minLength"], [], [techName]);
  const { valid: isValidDescription } = useValidation(["minLength"], [], [techDescription]);

  const sanitiseMultipleValues = (technologyAltNames: string): string[] =>
    technologyAltNames.split(new RegExp(`\\s*${ALT_NAME_DELIMITER}\\s*`)).filter((a) => a);

  async function addNewTechnology() {
    setIsSaving(true);
    onSave(techName, techDescription, sanitiseMultipleValues(techAltNames));
  }

  return (
    <Dialog
      style={{ zIndex: zIndex.selectMenu + 1 }}
      open
      aria-labelledby="new-technology-entity-title"
      data-testid={dataTestId}
    >
      <DialogTitle>Add a New Topic</DialogTitle>
      <DialogContent>
        <Typography className={clsx(classes.subtext, classes.hilight)}>
          The more complete and accurate the details provided, the better your monitoring and analysis.
        </Typography>
        <FormControl className={classes.field}>
          <TextField
            fullWidth
            required
            aria-invalid={!isValidName}
            error={!isValidName}
            label="Name"
            InputLabelProps={{ shrink: true }}
            value={techName}
            onChange={({ target: { value } }) => setTechName(value)}
            onKeyDown={(e) => e.stopPropagation()}
            data-testid={testId(dataTestId, "new-technology", "name")}
            InputProps={
              isValidName
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <DoneIcon />
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        </FormControl>
        <FormControl className={classes.field}>
          <TextField
            fullWidth
            label="Key Terms"
            InputLabelProps={{ shrink: true }}
            value={techAltNames}
            placeholder="Pseudonyms, Acronyms"
            onChange={({ target: { value } }) => setTechAltNames(value)}
            onKeyDown={(e) => e.stopPropagation()}
            data-testid={testId(dataTestId, "new-technology", "key-names")}
          />
          <Typography variant="body2" className={classes.subtext} color="textSecondary">
            <em>
              Comma separated terms the topic is known by e.g. ML, Machine Learning, Natural Language Processing, NLP…
              etc
            </em>
          </Typography>
        </FormControl>
        <FormControl className={classes.field}>
          <TextField
            fullWidth
            multiline
            rows={3}
            required
            aria-invalid={!isValidDescription}
            error={!!techDescription.length && !isValidDescription}
            label="Description"
            InputLabelProps={{ shrink: true }}
            value={techDescription}
            placeholder="Describe the topic for other users"
            onChange={({ target: { value } }) => setTechDescription(value)}
            onKeyDown={(e) => e.stopPropagation()}
            data-testid={testId(dataTestId, "new-technology", "description")}
            className={classes.multiline}
            InputProps={
              isValidDescription
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <DoneIcon style={{ marginRight: 8 }} />
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
          />
        </FormControl>
        <hr className={classes.rule} />
        <Typography variant="body2" className={classes.subtext} color="textSecondary" align="right">
          One of our team will check over this
        </Typography>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <AiButton amplyfiType="secondary" onClick={() => onClose()}>
            Cancel
          </AiButton>
        )}
        <AiButton disabled={isSaving || !isValidName || !isValidDescription} onClick={() => addNewTechnology()}>
          Save
        </AiButton>
      </DialogActions>
    </Dialog>
  );
}
