import { Typography } from "@material-ui/core";
import React from "react";
import { Connector } from "../../../../http/connectors";

interface SummaryPageProps {
  connector: Connector;
}

export default function SummaryPage(props: SummaryPageProps): JSX.Element {
  const { connector } = props;
  return (
    <div>
      <Typography variant="h2">Source Summary</Typography>
      <Typography variant="body1">Name: {connector.name}</Typography>
      {(connector.attributes || []).map((a) => (
        <Typography variant="body1" key={a.name}>
          {a.name}: {a.isSecureValue ? "********" : a.value}
        </Typography>
      ))}
    </div>
  );
}
