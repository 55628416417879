import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

interface ValidationProps {
  icon?: React.ReactNode;
  title: React.ReactNode;
  messages?: React.ReactNode[];
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: spacing(1),
    },
    icon: {
      marginRight: spacing(1),
      paddingTop: spacing(1),
    },
    messages: {
      paddingBottom: spacing(0.5),
      paddingTop: spacing(0.5),
    },
    scroller: {
      maxHeight: 100,
      overflow: "auto",
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
    },
    title: {
      fontWeight: "bold",
    },
  })
);

export const Validation: React.FC<ValidationProps> = ({ icon, messages, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {!!icon && <span className={classes.icon}>{icon}</span>}
      <div className={classes.scroller}>
        <Typography className={classes.title}>{title}</Typography>
        {messages?.map((m, i) => (
          <Typography key={i} className={classes.messages}>
            {m}
          </Typography>
        ))}
      </div>
    </div>
  );
};
