import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { AppState } from "../";
import { TimeSelectorType } from "../../../components/Charts/TimeSelector";
import { dateGranularity } from "../../../helpers/dateHelpers";
import { getStandardSearchEntityByType } from "../../../helpers/getIndividualSelectedFilters";
import { intervalUpdate } from "../../../helpers/timeHelper";
import { Days, TimeIntervalType } from "../../../helpers/timeIntervals";
import { Analysis } from "../../../models/analysis";
import { TimePeriod } from "../../../models/chart";
import { Entity, EntityType } from "../../../models/entity";
import { FilterType } from "../../../models/filter";
import { QueryType } from "../../../models/query";
import { ParsedQueryParams } from "../../../models/queryParams";
import { DocumentType } from "../../../models/search";
import { clearFilters, replaceQuery, routeChange } from "./actions";
import {
  DEFAULT_END,
  DEFAULT_PERIOD,
  DEFAULT_START,
  getParsedQueryParams,
  getQueryParamFieldNameByFilterType,
  getQueryParamFieldNameByType,
  updateUrl,
} from "./url-functions";

interface UrlState {
  lowestInterval: TimeIntervalType;
  interval: TimeIntervalType;
  parsed: ParsedQueryParams;
  previousAny: Entity[];
  savePending: boolean;
}

const parsed = getParsedQueryParams();
const interval = intervalUpdate(parsed);

const initialState: UrlState = {
  parsed: {
    ...parsed,
    period: parsed.period || DEFAULT_PERIOD,
    endDate: parsed.endDate || DEFAULT_END.valueOf(),
    startDate: parsed.startDate || DEFAULT_START.valueOf(),
  },
  lowestInterval: interval,
  interval,
  previousAny: [],
  savePending: false,
};

const filterTypeMap: Record<FilterType, keyof ParsedQueryParams> = {
  academicPublishLocations: "academicPublishLocations",
  applicants: "applicants",
  authors: "authors",
  dataTypes: "dataTypes",
  institution: "institution",
  institutionCountry: "institutionCountry",
  inventors: "inventors",
  keywords: "keywords",
  locations: "locations",
  owners: "owners",
  patentType: "patentType",
  publicationType: "publicationType",
  publishLocations: "publishLocations",
  relationshipStrength: "relStrength",
  sectors: "sectors",
  subSectors: "subSectors",
  sources: "sources",
  patentJurisdictions: "patentJurisdictions",
};

const urlSlice = createSlice({
  name: "url",
  initialState,
  reducers: {
    addMultipleQueries(state: UrlState, action: PayloadAction<{ entities: Entity[] }>) {
      let { entities } = action.payload;
      const parsedParams = getParsedQueryParams();
      entities = entities.map(({ id, name, type, queryType }: Entity, index: number) => ({
        id,
        name,
        type,
        queryType,
      }));
      const queries = getStandardSearchEntityByType(entities);
      parsedParams[getQueryParamFieldNameByType(QueryType.AllQueries)] = queries[QueryType.AllQueries];
      parsedParams[getQueryParamFieldNameByType(QueryType.AnyQueries)] = queries[QueryType.AnyQueries];
      parsedParams[getQueryParamFieldNameByType(QueryType.NoneQueries)] = queries[QueryType.NoneQueries];
      state.savePending = true;
      updateUrlAndSetState(parsedParams, state);
    },
    addQuery(state: UrlState, action: PayloadAction<{ type?: QueryType; entity: Entity }>) {
      const { entity, type } = action.payload;
      const fieldName = getQueryParamFieldNameByType(type || QueryType.AnyQueries);
      const parsedParams = getParsedQueryParams();
      const existingEntity = parsedParams[fieldName].some((x) => x.id === entity.id);
      if (!existingEntity) {
        parsedParams[fieldName].push(entity);
      }
      state.savePending = true;
      updateUrlAndSetState(parsedParams, state);
    },
    removeQuery(state: UrlState, action: PayloadAction<{ type: QueryType; index: number }>) {
      const { index, type } = action.payload;
      const fieldName = getQueryParamFieldNameByType(type || QueryType.AnyQueries);
      const parsedParams = getParsedQueryParams();
      const existingEntity = parsedParams[fieldName][index];
      if (existingEntity) {
        parsedParams[fieldName].splice(index, 1);
      }
      state.savePending = true;
      updateUrlAndSetState(parsedParams, state);
    },
    toggleOption(state: UrlState, action: PayloadAction<{ type: FilterType; entity: Entity }>) {
      const { entity, type } = action.payload;
      const parsedParams = getParsedQueryParams();
      const fieldName = getQueryParamFieldNameByFilterType(type);
      const filters = parsedParams[fieldName];
      const index = filters.findIndex((x) => x.id === entity.id);
      if (index > -1) {
        filters.splice(index, 1);
      } else {
        filters.push(entity);
      }
      updateUrlAndSetState(parsedParams, state);
    },
    setFilters(state: UrlState, action: PayloadAction<{ type: FilterType; entities: Entity[] }>) {
      const filters = action.payload;
      const parsedParams = getParsedQueryParams();
      const fieldName = getQueryParamFieldNameByFilterType(filters.type);
      parsedParams[fieldName] = filters.entities;
      state.savePending = true;
      updateUrlAndSetState(parsedParams, state);
    },
    refineSearch(state: UrlState, action: PayloadAction<{ type: FilterType; entity: Entity }>) {
      const parsedParams = getParsedQueryParams();
      const { entity, type } = action.payload;
      parsedParams.documentId = undefined;
      updateUrlAndSetState(parsedParams, state);
      addOptionToState(state, type, entity);
    },
    addKeyword(state: UrlState, action: PayloadAction<{ entity: Entity }>) {
      const { entity } = action.payload;
      addOptionToState(state, FilterType.Keywords, entity);
    },
    addOption(state: UrlState, action: PayloadAction<{ type: FilterType; entity: Entity }>) {
      const { entity, type } = action.payload;
      addOptionToState(state, type, entity);
    },
    removeOption(state: UrlState, action: PayloadAction<{ type: FilterType; entity: Entity }>) {
      const { entity, type } = action.payload;
      removeOptionFromState(state, type, entity);
    },
    resetFilterOptions(state: UrlState) {
      const parsedParams = getParsedQueryParams();
      resetFilters(parsedParams);
      updateUrlAndSetState(parsedParams, state);
    },
    clearAllFilters(state: UrlState) {
      const parsedParams = getParsedQueryParams();
      makeAllUndefined(parsedParams);
      updateUrlAndSetState(parsedParams, state);
    },
    resetFiltersOfType(state: UrlState, action: PayloadAction<FilterType>) {
      const parsedParams = getParsedQueryParams();
      const field = filterTypeMap[action.payload];
      (parsedParams as Partial<ParsedQueryParams>)[field] = undefined;
      updateUrlAndSetState(parsedParams, state);
    },
    resetQuery(state: UrlState) {
      const parsedParams = getParsedQueryParams();
      parsedParams.lucene = undefined;
      parsedParams.all = [];
      parsedParams.any = [];
      parsedParams.none = [];
      updateUrlAndSetState(parsedParams, state);
    },
    onGlobalDatesChange(
      state: UrlState,
      action: PayloadAction<{ dates: (number | undefined)[]; period?: TimeSelectorType; isEndDateToday?: boolean }>
    ) {
      const {
        dates: [start, end],
        period,
        isEndDateToday,
      } = action.payload;
      const parsedParams = getParsedQueryParams();
      parsedParams.startDate = start;
      parsedParams.endDate = isEndDateToday ? Days.Today : end;
      parsedParams.period = start ? period : "custom";
      const interval = intervalUpdate(parsedParams);
      state.lowestInterval = interval;
      state.interval = interval;
      state.savePending = true;
      updateUrlAndSetState(parsedParams, state);
    },
    onTimelineChange(state: UrlState, { payload: [start, end] }: PayloadAction<number[]>) {
      const parsedParams = getParsedQueryParams();
      parsedParams.startDate = dateGranularity(moment.utc(start)).startOf("day").valueOf();
      parsedParams.endDate = dateGranularity(moment.utc(end)).startOf("day").valueOf();
      parsedParams.period = "custom";
      const interval = intervalUpdate(parsedParams);
      state.lowestInterval = interval;
      state.interval = interval;
      updateUrlAndSetState(parsedParams, state);
    },
    onSelectPeriod(
      state: UrlState,
      { payload: [[startDate, endDate], period] }: PayloadAction<[[number, number], TimePeriod]>
    ) {
      const parsedParams = getParsedQueryParams();
      parsedParams.startDate = startDate;
      parsedParams.endDate = endDate;
      parsedParams.period = period;
      const interval = intervalUpdate(parsedParams);
      state.lowestInterval = interval;
      state.interval = interval;
      updateUrlAndSetState(parsedParams, state);
    },
    viewNewsDoc(state: UrlState, action: PayloadAction<string>) {
      const queryParams = getParsedQueryParams();
      queryParams.documentId = action.payload;
      queryParams.type = DocumentType.News;
      updateUrlAndSetState(queryParams, state);
    },
    setLuceneQuery(state: UrlState, action: PayloadAction<string>) {
      const parsedParams = getParsedQueryParams();
      resetFilters(parsedParams);
      parsedParams.all = [];
      parsedParams.any = [];
      parsedParams.none = [];
      parsedParams.lucene = action.payload;
      updateUrlAndSetState(parsedParams, state);
    },
    updateInterval(state: UrlState, action: PayloadAction<TimeIntervalType>) {
      const parsedParams = getParsedQueryParams();
      state.interval = action.payload;
      updateUrlAndSetState(parsedParams, state);
    },
    setLuceneQueryWithoutReset(state: UrlState, action: PayloadAction<string>) {
      const parsedParams = getParsedQueryParams();
      parsedParams.lucene = action.payload;
      state.savePending = true;
      updateUrlAndSetState(parsedParams, state);
    },
    updateUndatedDocuments(state: UrlState, action: PayloadAction<boolean>) {
      const parsedParams = getParsedQueryParams();
      parsedParams.includeUndatedDocuments = action.payload;
      updateUrlAndSetState(parsedParams, state);
    },
    updateSelectedAnalysis(state: UrlState, action: PayloadAction<Analysis>) {
      const parsedParams = getParsedQueryParams();
      const { id, content } = action.payload;
      const params = {
        ...parsedParams,
        ...getParsedQueryParams(content),
        savedAnalysisId: id,
      };
      updateUrlAndSetState(params, state);
    },
    removeSelectedAnalysis(state: UrlState) {
      const parsedParams = getParsedQueryParams();
      const params = {
        ...parsedParams,
        savedAnalysisId: undefined,
      };
      updateUrlAndSetState(params, state);
    },
    clearDocumentLibraryId(state: UrlState) {
      const parsedParams = getParsedQueryParams();
      const params = {
        ...parsedParams,
        libraryIds: undefined,
        libraries: undefined,
      };
      updateUrlAndSetState(params, state);
    },
    updateDocumentLibraryId(state: UrlState, action: PayloadAction<string | string[]>) {
      //libraryId news is a special case that requires documentType News
      const parsedParams = getParsedQueryParams();

      const type = action.payload;
      if (Array.isArray(type)) {
        parsedParams.libraryIds = type;
        updatedDocumentTypesFromArray(parsedParams);
      } else if (parsedParams.libraryIds.includes(type)) {
        parsedParams.libraryIds = parsedParams.libraryIds.filter((x) => x !== type);
        if (type === "news") {
          parsedParams.libraries = parsedParams.libraries.filter((x) => x !== DocumentType.News);
        } else if (
          parsedParams.libraryIds.length === 0 ||
          (parsedParams.libraryIds.length === 1 && parsedParams.libraryIds[0] === "news")
        ) {
          parsedParams.libraries = parsedParams.libraries.filter((x) => x !== "Private");
        }
      } else {
        parsedParams.libraryIds = [...parsedParams.libraryIds, type];
        if (type === "news") {
          if (!parsedParams.libraries.includes(DocumentType.News)) {
            parsedParams.libraries = [...parsedParams.libraries, DocumentType.News];
          }
        } else {
          if (!parsedParams.libraries.includes("Private")) {
            parsedParams.libraries = [...parsedParams.libraries, "Private"];
          }
        }
      }
      updateUrlAndSetState(parsedParams, state);
    },
    updateSavePending(state: UrlState, action: PayloadAction<boolean>) {
      return {
        ...state,
        savePending: action.payload,
      };
    },
    toggleWithinSentence(state: UrlState, action: PayloadAction<boolean>) {
      const parsedParams = getParsedQueryParams();
      parsedParams.withinSentence = action.payload;
      updateUrlAndSetState(parsedParams, state);
    },
  },
  extraReducers: {
    [routeChange.type]: (state: UrlState) => {
      const { parsed } = state;
      updateUrlAndSetState(parsed, state);
    },
    [clearFilters.type]: function (state: UrlState) {
      const parsedParams = getParsedQueryParams();
      resetFilters(parsedParams);
      updateUrlAndSetState(parsedParams, state);
    },
    [replaceQuery.type]: function (state: UrlState, action: PayloadAction<{ type?: QueryType; entities: Entity[] }>) {
      const { type, entities } = action.payload;
      const parsedParams = getParsedQueryParams();
      parsedParams.all = [];
      parsedParams.any = [];
      parsedParams.none = [];
      parsedParams.lucene = undefined;
      if (!type || type === QueryType.AnyQueries) {
        parsedParams.any.push(...entities);
      } else if (type === QueryType.AllQueries) {
        parsedParams.all.push(...entities);
      } else if (type === QueryType.NoneQueries) {
        parsedParams.none.push(...entities);
      }
      resetFilters(parsedParams);
      updateUrlAndSetState(parsedParams, state);
    },
  },
});

export default urlSlice.reducer;

export const hasAnyQueriesSelector = (state: AppState): boolean => {
  const url = state.searchRequest.url.parsed;
  return paramsHasAnyQueries(url);
};

export const hasAnyEntitiesSelector = (state: AppState): boolean => {
  const url = state.searchRequest.url.parsed;
  const isEntity = (x: Entity) => x.type && x.type !== EntityType.None;

  return url.any.some(isEntity) || url.all.some(isEntity);
};

export const hasOrganisationSelector = (state: AppState): boolean => {
  const url = state.searchRequest.url.parsed;
  const isOrganisation = (x: Entity) => x.type === EntityType.Organisation;

  return url.any.some(isOrganisation) || url.all.some(isOrganisation);
};

export const hasOnlyNoneSelector = (state: AppState): boolean => {
  const url = state.searchRequest.url.parsed;
  return url.any.length === 0 && url.all.length === 0 && url.none.length > 0 && !url.lucene;
};

const paramsHasAnyQueries = (url: ParsedQueryParams): boolean => {
  return url.any.length > 0 || url.all.length > 0 || url.none.length > 0 || !!url.lucene;
};

function updatedDocumentTypesFromArray(parsedParams: ParsedQueryParams) {
  if (parsedParams.libraryIds.includes("news")) {
    parsedParams.libraries = [DocumentType.News];
    if (parsedParams.libraryIds.length > 1) {
      parsedParams.libraries = [...parsedParams.libraries, "Private"];
    }
  } else if (parsedParams.libraryIds.length > 0) {
    parsedParams.libraries = ["Private"];
  } else {
    parsedParams.libraries = [];
  }
}

function addOptionToState(state: UrlState, type: FilterType, entity: Entity) {
  const parsedParams = getParsedQueryParams();
  const fieldName = getQueryParamFieldNameByFilterType(type);
  const filters = parsedParams[fieldName];
  const index = filters.findIndex((x) => x.id === entity.id);
  if (index === -1) {
    filters.push(entity);
  }
  updateUrlAndSetState(parsedParams, state);
}

function removeOptionFromState(state: UrlState, type: FilterType, entity: Entity) {
  const parsedParams = getParsedQueryParams();
  const fieldName = getQueryParamFieldNameByFilterType(type);
  const filters = parsedParams[fieldName];
  const index = filters.findIndex((x) => x.id === entity.id);
  if (index > -1) {
    filters.splice(index, 1);
  }
  updateUrlAndSetState(parsedParams, state);
}

export const {
  addMultipleQueries,
  toggleOption,
  resetFilterOptions,
  resetFiltersOfType,
  addOption,
  removeOption,
  refineSearch,
  onGlobalDatesChange,
  onTimelineChange,
  addKeyword,
  addQuery,
  removeQuery,
  viewNewsDoc,
  onSelectPeriod,
  setFilters,
  setLuceneQuery,
  resetQuery,
  updateInterval,
  updateUndatedDocuments,
  updateSelectedAnalysis,
  removeSelectedAnalysis,
  clearAllFilters,
  updateDocumentLibraryId,
  clearDocumentLibraryId,
  setLuceneQueryWithoutReset,
  updateSavePending,
  toggleWithinSentence,
} = urlSlice.actions;

function updateUrlAndSetState(parsedParams: Partial<ParsedQueryParams>, state: UrlState) {
  updateUrl(parsedParams);
  setState(state);
}

function setState(state: UrlState) {
  state.parsed = getParsedQueryParams();
}

function makeAllUndefined(queryParams: Partial<ParsedQueryParams>) {
  Object.values(filterTypeMap).forEach((value) => {
    queryParams[value] = undefined;
  });
  queryParams.period = DEFAULT_PERIOD;
  queryParams.startDate = DEFAULT_START.valueOf();
  queryParams.endDate = DEFAULT_END.valueOf();
}

function resetFilters(queryParams: Partial<ParsedQueryParams>) {
  makeAllUndefined(queryParams);

  queryParams.documentId = undefined;
}
