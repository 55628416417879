import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import clsx from "clsx";
import SelectFilterLegend from "./SelectFilterLegend";
import { useAnalyseSelector } from "../../../store/reducers";
import { GetFilters } from "../../Navigation/SelectedFiltersHeader";
import { Entity, ExcludeOrInclude } from "../../../models/entity";
import { FilterObject, FilterOption, FilterType } from "../../../models/filter";
import { useDispatch } from "react-redux";
import Button from "@amplyfi/ui-components/components/Button";
import { link } from "../../../css/mixins";
import TextSearch from "@amplyfi/ui-components/components/TextSearch";
import { MAX_SELECTED_FILTERS } from "../../../helpers/getSelectedFiltersCount";
import { setSnack } from "../../../store/reducers/ui/snackReducer";
import { SnackFrequencyType } from "../../../models/snack";
import { setFilters } from "../../../store/reducers/searchRequest/urlReducer";
import { zIndex } from "../../../helpers/componentsZIndex";
import { ParsedQueryParams } from "../../../models/queryParams";
import DocCountLabel from "./DocCountLabel";
import { getQueryParamFieldNameByFilterType } from "../../../store/reducers/searchRequest/url-functions";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.componentBackground.main,
      marginTop: theme.spacing(4),
      display: "flex",
    },
    inputSection: {
      width: "50%",
      borderRight: `1px solid ${theme.palette.borders.contrastText}`,
    },
    activeFilterSection: {
      width: "50%",
    },
    activeFilterHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    handerlabel: {
      color: "#748998",
      marginBottom: theme.spacing(1.5),
      textTransform: "uppercase",
    },
    activeFilters: {
      width: 280,
      margin: theme.spacing(1.5),
      overflowY: "auto",
      overflowX: "hidden",
      height: 280,
    },
    activeFilterLabel: {
      paddingLeft: theme.spacing(1.5),
    },
    footer: {
      borderTop: `1px solid ${theme.palette.borders.contrastText}`,
      textAlign: "end",
      display: "flex",
      alignItems: "baseline",
      justifyContent: "right",
    },
    button: {
      margin: `${theme.spacing(2)}px 0 0 ${theme.spacing(2)}px`,
    },
    link: {
      ...link(theme),
    },
    textSearch: {
      backgroundColor: theme.palette.componentBackground.main,
      marginBottom: theme.spacing(2.5),
    },
    searchSection: {
      paddingRight: theme.spacing(4),
    },
    list: {
      overflowY: "auto",
      overflowX: "hidden",
      height: 280,
      paddingRight: theme.spacing(4),
    },
    selectInput: {
      margin: `0 0 ${theme.spacing(2.5)}px ${theme.spacing(1.5)}px`,
      width: "100%",
      background: theme.palette.componentBackground.main,
      borderRadius: theme.spacing(0.75),
    },
  })
);

interface SelectDocumentSourcesProps {
  filterType: FilterType;
  onApply: () => void;
}

export default function SelectDocumentFilters(props: SelectDocumentSourcesProps): JSX.Element {
  const { filterType, onApply } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const urlState = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const [searchText, setSearchText] = useState("");
  const filters = useAnalyseSelector((x) => x.searchRequest.filter.initialFilters);
  const filterOptions = filters.find((x) => x.type === filterType) as FilterObject;
  const [selected, setSelected] = useState<Entity[]>(
    (urlState[filterType as keyof ParsedQueryParams] as Entity[]) ?? []
  );
  const [excludedOrIncluded, setExcludedOrIncluded] = useState(ExcludeOrInclude.Include);

  const getFilteredData = (): FilterOption[] => {
    return searchText.length && filterOptions
      ? filterOptions.options.filter((r) => r.entity.name.toLowerCase().includes(searchText.toLowerCase()))
      : filterOptions?.options ?? [];
  };

  useEffect(() => {
    setSelected((urlState[filterType as keyof ParsedQueryParams] as Entity[]) ?? []);
  }, [urlState, filterType]);

  useEffect(() => {
    if (selected.length) {
      const isExcluded = selected.some((x) => x.excludeOrInclude === ExcludeOrInclude.Exclude);
      if (isExcluded) {
        setExcludedOrIncluded(ExcludeOrInclude.Exclude);
      } else {
        setExcludedOrIncluded(ExcludeOrInclude.Include);
      }
    }
  }, [excludedOrIncluded, selected]);

  const handleClose = (entity: Entity) => {
    setSelected(selected.filter((e) => e.id !== entity.id));
  };

  const setActiveFilter = (entity: Entity) => {
    if (selected.length >= MAX_SELECTED_FILTERS) {
      dispatch(
        setSnack({
          title: "Maximum Filters Reached",
          body: `Warning! You can only add a maximum of ${MAX_SELECTED_FILTERS} filters. Please remove some filters before adding more.`,
          type: SnackFrequencyType.Every,
        })
      );
      return;
    }
    if (!selected.some((e) => e.id === entity.id)) {
      setSelected([...selected, { ...entity, excludeOrInclude: excludedOrIncluded }]);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.inputSection}>
          <div className={classes.searchSection}>
            <TextSearch
              label={`Search All ${filterType}`}
              focusedLabel="Type and hit enter"
              className={classes.textSearch}
              value={searchText}
              setValue={setSearchText}
              totalCount={getFilteredData().length}
            />
          </div>
          <Typography className={classes.handerlabel} variant="h6">
            Top {filterType} by volume
          </Typography>
          <div className={classes.list}>
            {getFilteredData().map(
              (detail) =>
                !selected.some((e) => e.id === detail.entity.id) && (
                  <SelectFilterLegend key={detail.entity.id} detail={detail} setActiveFilter={setActiveFilter} />
                )
            )}
          </div>
        </div>

        <div className={classes.activeFilterSection}>
          <Select
            MenuProps={{
              style: { zIndex: zIndex.selectMenu },
            }}
            className={classes.selectInput}
            value={excludedOrIncluded}
            onChange={(e) => {
              setExcludedOrIncluded(e.target.value as ExcludeOrInclude);
              // Ensuring old selectedFilters are converted to the correct "include" or "exclude"
              setSelected(
                selected.map((selectedFilter) => ({
                  ...selectedFilter,
                  excludeOrInclude: e.target.value as ExcludeOrInclude,
                }))
              );
            }}
          >
            <MenuItem value={ExcludeOrInclude.Include}>
              <Typography variant="h6">Include Only These...</Typography>
            </MenuItem>
            <MenuItem value={ExcludeOrInclude.Exclude}>
              <Typography variant="h6">Exclude Only These...</Typography>
            </MenuItem>
          </Select>
          <div className={classes.activeFilterHeader}>
            <Typography className={clsx(classes.handerlabel, classes.activeFilterLabel)} variant="h6">
              Active Filters
            </Typography>
            <Typography variant="h6" className={classes.link} onClick={() => setSelected([])}>
              Clear
            </Typography>
          </div>
          <div className={classes.activeFilters}>
            <GetFilters data={{ [filterType]: selected }} label={"News"} handleClose={handleClose} />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <DocCountLabel
          urlState={{
            ...urlState,
            [getQueryParamFieldNameByFilterType(filterType)]: selected,
          }}
        />
        <Button
          className={classes.button}
          onClick={() => {
            dispatch(
              setFilters({
                type: filterType,
                entities: [...selected],
              })
            );
            onApply();
          }}
          type="button"
        >
          Apply Filters
        </Button>
      </div>
    </>
  );
}
