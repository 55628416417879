import React, { useState } from "react";
import { Box, List, Typography } from "@material-ui/core";
import EditableListItem from "../../../EditableListItem";
import { useFeedViewStore } from "../../../../../../../store/hooks/useFeedViewStore";
import { useLibraryEntities } from "../../../../../../../hooks/useCollectionConfigs";
import { getAmplyfiEntityLogo } from "../../../../../../../helpers/imageHelpers";
import Stack from "../../../stack/Stack";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import TextEntryField from "../../../../Configuration/Components/TextEntryField";
import { CreateReportWizardState } from "../CreateReportWizard";
import useWizardState from "../../../../../../../hooks/useWizardState";

export default function ReportInterestSearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const { libraryId: selectedFeedId } = useFeedViewStore();
  const { data: feedEntities = [] } = useLibraryEntities(selectedFeedId || "");
  const [state, setState] = useWizardState<CreateReportWizardState>("create-report");

  return (
    <Box padding={4}>
      <Stack spacing={2}>
        <Box
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "#fff",
          }}
        >
          <Typography style={{ paddingBottom: 8 }} variant="h2">
            Which interest do you want to create a report for?
          </Typography>
          <TextEntryField
            fullWidth
            variant="outlined"
            placeholder="Search for interest"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && <CloseIcon cursor="pointer" onClick={() => setSearchTerm("")} />}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <List>
          {feedEntities
            .filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map(({ amplyfiEntityId, name }) => (
              <EditableListItem
                displayErrorAlt
                key={amplyfiEntityId}
                compact={true}
                title={name}
                image={getAmplyfiEntityLogo(amplyfiEntityId)}
                selected={amplyfiEntityId === state.selectedEntityId}
                onClick={() =>
                  setState({
                    ...state,
                    selectedEntityId: amplyfiEntityId,
                    nextDisabled: false,
                  })
                }
                style={amplyfiEntityId === state.selectedEntityId ? { backgroundColor: "#f0f0f0" } : {}}
              />
            ))}
        </List>
      </Stack>
    </Box>
  );
}
