import { useEffect, useState } from "react";
import parseQAMessage from "./queries/question_answer/parsers";
import useAmplyfiSocketParser from "./queries/sockets/useAmplyfiSocketParser";

export default function useGenerativeSummary(documentId?: string) {
  const { socket } = useAmplyfiSocketParser(["summary"]);
  const [text, setText] = useState<string>("");

  useEffect(() => {
    socket.sendJsonMessage({ action: "summary", type: "summary", documentId: documentId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socket.lastJsonMessage) {
      const { message } = parseQAMessage({ message: socket.lastJsonMessage, messages: [] });
      setText(text.concat(message || ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket.lastJsonMessage]);
  return text;
}
