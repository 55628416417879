import axios from "axios";
import Icon from "@material-ui/icons";

const apiUrl = `${process.env.REACT_APP_ANALYSE_API}/v2`;

export interface CollectionConfig {
  collectionConfigId?: string;
  name: string;
  description: string | null;
  siteHarvestWebsites?: string[];
  rssUrls: string[];
  isCustom?: boolean;
  icon?: keyof Icon.SvgIconComponent;
  aliases?: string[];
}

export interface MappedConfig extends CollectionConfig {
  id: string;
}

export async function getCollectionConfigs(): Promise<MappedConfig[]> {
  const { data } = await axios.get<{ collectionConfigs: CollectionConfig[] }>(
    `${apiUrl}/harvesters/collection-configs`
  );
  return data.collectionConfigs.map((config) => ({ ...config, id: config.collectionConfigId as string }));
}

export async function getCollectionConfig(configId: string): Promise<MappedConfig> {
  const { data } = await axios.get(`${apiUrl}/harvesters/collection-configs/${configId}`);
  return { ...data, id: data.collectionConfigId as string };
}

export async function createCollectionConfig(config: CollectionConfig): Promise<CollectionConfig> {
  const { data } = await axios.post(`${apiUrl}/harvesters/collection-configs`, config);
  return data;
}

export async function editCollectionConfig(config: CollectionConfig): Promise<CollectionConfig[]> {
  const { data } = await axios.put(`${apiUrl}/harvesters/collection-configs/${config.collectionConfigId}`, config);
  return data;
}

export async function getCollectionConfigsForLibrary(
  organisationId: string,
  libraryId: string
): Promise<CollectionConfig[]> {
  const { data } = await axios.get(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/collection-configs`
  );
  return data.collectionConfigs;
}

export async function linkConfigToLibrary(
  organisationId: string,
  libraryId: string,
  configId: string
): Promise<CollectionConfig[]> {
  const { data } = await axios.put(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/collection-configs/${configId}`
  );
  return data;
}

export async function unlinkConfigFromLibrary(
  organisationId: string,
  libraryId: string,
  configId: string
): Promise<CollectionConfig[]> {
  const { data } = await axios.delete(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/collection-configs/${configId}`
  );
  return data.libraryIds;
}

interface LibraryEntityNullable {
  amplyfiEntityId: string;
  description: string | null;
  industries: string[] | null;
  name: string;
  websites: string[] | null;
}

export interface LibraryEntity extends Omit<LibraryEntityNullable, "websites" | "industries"> {
  industries: NonNullable<LibraryEntityNullable["industries"]>;
  websites: NonNullable<LibraryEntityNullable["websites"]>;
}

export async function getLibraryEntities(organisationId: string, libraryId: string): Promise<LibraryEntity[]> {
  const { data } = await axios.get<{
    entities: LibraryEntityNullable[];
  }>(`${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/amplyfi-entities`);

  return data.entities.map((ent) => ({
    ...ent,
    websites: ent.websites || [],
    industries: ent.industries || [],
  }));
}

export async function deleteConfig(configId: string) {
  await axios.delete(`${apiUrl}/harvesters/collection-configs/${configId}`);
}
