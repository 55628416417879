import { AccountGroup } from "../../../../models/account";
import { HarvestMetadata } from "../../../../models/harvest";
import { LibraryItem } from "../../../../models/search/documentLibrary";

export const MAX_HARVEST_DOCUMENTS = Number(process.env.REACT_APP_MAX_HARVEST_DOCUMENTS || 10000);

export const isOrganisationValid = (organisation: AccountGroup | null): boolean => !!organisation;

export const isLibraryValid = (library: LibraryItem | null): boolean => !!library?.id;

export const isQueryUseful = (query: string): boolean => !!query.length;

export const isQueryValid = ({ isValid }: HarvestMetadata): boolean => isValid;

export const isQueryTooBroad = (validation: HarvestMetadata): boolean =>
  isQueryValid(validation) && validation.count > MAX_HARVEST_DOCUMENTS;

export const isQueryTooNarrow = (validation: HarvestMetadata): boolean =>
  isQueryValid(validation) && validation.count > -1 && validation.count < 1;

export const queryDoesNotReturnEstimate = (validation: HarvestMetadata): boolean =>
  isQueryValid(validation) && validation.count === -1;

export const isQueryActionable = (validation: HarvestMetadata): boolean =>
  isQueryValid(validation) && !isQueryTooNarrow(validation);
