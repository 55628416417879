import { DocumentDetailedItem } from "../../../models/document";
import { MonitoringDocument } from "../../../models/monitoring";
import { friendlyDateFormat } from "../../../helpers/dateHelpers";

const newLine = "\n";

export type FeatureHandler = (feature: string[]) => string;

export const extractDocumentMeta =
  (doc?: DocumentDetailedItem | MonitoringDocument): FeatureHandler =>
  (feature) => {
    const date: string = (doc as DocumentDetailedItem).publishDate || (doc as MonitoringDocument).date || "";

    return [
      doc?.title,
      newLine,
      doc?.source,
      `Published: ${friendlyDateFormat(date, { friendlyWithinDays: 0 })}`,
      doc?.url,
      ...(feature ? [newLine, ...feature] : []),
    ].join(newLine);
  };

export const copyText = (text: string): Promise<void> => navigator.clipboard.writeText(text);
