import { ChartCustomizationType } from "../models/chart-customizations";
import { Entity } from "../models/entity";

export function convertCustomizationToEntity(topic?: {
  id: string;
  name: string;
  type: ChartCustomizationType;
}): Entity {
  return {
    id: topic?.id ?? "",
    name: topic?.name ?? "",
    type: topic?.type === "KeyPhrase" ? undefined : topic?.type,
  };
}
