import { ExportComponent, exportFunctionsV2 } from "../http/export";
import { SearchRequestV2 } from "../models/search";
import { getParsedQueryParams } from "../store/reducers/searchRequest/url-functions";
import { getCurrentSearchRequestV2 } from "./search";

export const getExportV2 = (fileName: string, type: ExportComponent, override?: Partial<SearchRequestV2>): void => {
  const request = { ...getCurrentSearchRequestV2(), ...override };
  request.systemFilters.exportFileName = fileName;
  const { libraries } = getParsedQueryParams();
  exportFunctionsV2[type](request, libraries);
};
