import AiButton from "@amplyfi/ui-components/components/Button";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { Box, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { Category as ContentTypeIcon, Warning, Add as AddIcon, Done } from "@material-ui/icons";
import { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOrgId } from "../../../../helpers/user";
import useAIHistory from "../../../../hooks/useAIHistory";
import useLibrary from "../../../../hooks/useLibrary";
import { blockLibraryTypeTag } from "../../../../http/search/documentLibrary";
import { MonitoringDocument } from "../../../../models/monitoring";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import { goToLibrarySettingsTab } from "../Routing";
import { LibraryFilter } from "../../../../http/libraryFilters";
import SimpleDialog from "../../../Widgets/Dialog/SimpleDialog";
import { useFeedHistoricFilterCounts } from "../../../../hooks/useFeedHistoricFilterCounts";
import DynamicComponent from "../Configuration/Components/DynamicComponent";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { analyticsEvent } from "../../../../helpers/analytics";
import useDialogStore from "../../../../store/hooks/useDialogStore";
import useFeedUpdates from "../../../../hooks/useFeedUpdates";
import { blueShades } from "../../../Charts/helpers/colourScaleHexes";
import { pluraliseDays, pluraliseWeeks } from "../../../../helpers/textHelper";

interface BlockTypeTagsProps {
  "data-testId"?: string;
  document: MonitoringDocument;
  libraryId: string | null;
  onClose: (e: React.MouseEvent<Element, MouseEvent>, hasChanged: boolean) => void;
}

interface BlockTypeTagContentProps {
  document: MonitoringDocument;
  handleBlockTypeTag: (documentId: string, typeTag: LibraryFilter) => Promise<void>;
  isBusy: boolean;
  libraryId: string | null;
  onClose: MouseEventHandler;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  icon: {
    marginRight: spacing(1),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  subTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: spacing(3),
    gap: spacing(1),
  },
  content: {
    marginBottom: spacing(2),
  },
  list: {
    display: "flex",
    listStyle: "none",
    maxHeight: 200,
    overflow: "auto",
    padding: 0,
    flexWrap: "wrap",
    gap: spacing(1),
    marginBlock: spacing(2),
  },
}));

const INCREMENT_ITEMS = 5;
const HISTORIC_FILTER_COUNT_DAYS = 7;

const PLURALISE_OPTIONS = { includeCount: false, showPluralCount: true };

const unifyTag = ({ id }: LibraryFilter): string => id;

interface BlockTypeProps {
  libraryId: string;
  documentTypeTag: LibraryFilter;
  disabled: boolean;
  onClick: MouseEventHandler;
  isSelected?: boolean;
}

function BlockType(props: BlockTypeProps) {
  const { documentTypeTag, disabled, onClick, libraryId, isSelected } = props;
  const { data: historicCounts, isLoading } = useFeedHistoricFilterCounts(
    libraryId,
    "documentTypeTag",
    [unifyTag(documentTypeTag)],
    HISTORIC_FILTER_COUNT_DAYS
  );
  return (
    <AiButton
      disabled={disabled}
      onClick={onClick}
      style={isSelected ? { opacity: 0.5 } : {}}
    >
      {isSelected && <Done style={{ marginRight: 8, fontSize: 18 }} />}
      {documentTypeTag.name}
      <Box style={{ marginLeft: 8 }}>{isLoading && <AiCircularProgress size={12} />}</Box>
      {!!historicCounts?.[unifyTag(documentTypeTag)] &&
        ` (${historicCounts[unifyTag(documentTypeTag)].documentCount})`}
    </AiButton>
  );
}

const BlockTypeTagContent = ({
  document: { documentId, documentTypeTags = [], libraryId },
  handleBlockTypeTag,
  onClose,
}: BlockTypeTagContentProps) => {
  const classes = useStyles();
  const history = useAIHistory();
  const { data: library } = useLibrary(libraryId);
  const [blockedTypeTags, setBlockedTypeTags] = useState<LibraryFilter["id"][]>(library?.blockedDocumentTypeTags.map(({ id }) => id) || []);
  const [noItems, setNoItems] = useState<number>(INCREMENT_ITEMS);
  const [selectedTypeTags, setSelectedTypeTags] = useState<LibraryFilter["id"][]>([]);
  const removeDialog = useDialogStore((state) => state.removeDialog);
  const { reset } = useFeedUpdates();


  const handleConfirm = async () => {
    for (const typeTagId of selectedTypeTags) {
      const typeTag = documentTypeTags.find(s => unifyTag(s) === typeTagId);
      if (typeTag) {
        await handleBlockTypeTag(documentId, typeTag);
      }
    }
    removeDialog();
    reset();
  };

  useEffect(() => {
    if (library?.blockedDocumentTypeTags) {
      setBlockedTypeTags(library.blockedDocumentTypeTags.map(({ id }) => id));
    }
  }, [library]);

  return (
    <SimpleDialog
      title={
        <Typography variant="h2" className={classes.title}>
          <ContentTypeIcon className={classes.icon} /> Block Content Types
        </Typography>
      }
      data-testid={testId("document", "block-content-type")}
      onClose={onClose}
      content={
        <DynamicComponent>
          <>
            <Box className={classes.content}>
              <Box className={classes.subTitle}>
                <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <Warning />
                  <Typography variant="h4">Blocking this content type will remove past signals, as well as preventing future ones.</Typography>
                </Box>
                <Typography variant="h4"> Please Note: <Typography component="span" style={{ fontStyle: "italic" }}>Unblocking content types will not return previously removed signals.</Typography></Typography>
              </Box>
              <Box className={classes.list}>
                {documentTypeTags.slice(0, noItems).map((typeTag, i) => {
                  return (
                    <Box key={typeTag.id}>
                      <BlockType
                        libraryId={libraryId}
                        documentTypeTag={typeTag}
                        disabled={blockedTypeTags.includes(unifyTag(typeTag))}
                        isSelected={selectedTypeTags.includes(unifyTag(typeTag))}
                        onClick={() => {
                          setSelectedTypeTags(prevSectors => {
                            if (prevSectors.includes(unifyTag(typeTag))) {
                              return prevSectors.filter(s => s !== unifyTag(typeTag));
                            } else {
                              return [...prevSectors, unifyTag(typeTag)];
                            }
                          });
                        }}
                      />
                    </Box>
                  );
                })}
                {noItems < documentTypeTags.length && (
                  <Typography style={{ marginTop: 0, textAlign: "right" }}>
                    <AiButton
                      amplyfiType="secondary"
                      disabled={noItems >= documentTypeTags.length}
                      onClick={() => setNoItems(Math.min(noItems + INCREMENT_ITEMS, documentTypeTags.length))}
                    >
                      <AddIcon />See more
                    </AiButton>
                  </Typography>
                )}
              </Box>
              <Typography>Counts show the number of signals received in the last
                {HISTORIC_FILTER_COUNT_DAYS % 7 === 0
                  ? pluraliseWeeks(HISTORIC_FILTER_COUNT_DAYS / 7, PLURALISE_OPTIONS)
                  : pluraliseDays(HISTORIC_FILTER_COUNT_DAYS, PLURALISE_OPTIONS)}{" "}
                containing that content type.
              </Typography>
              {libraryId && (
                <Typography style={{ marginTop: 4 }}>
                  Review your blocked sectors within{" "}
                  <Link
                    style={{ textDecoration: "none", color: blueShades[3] }}
                    href={goToLibrarySettingsTab(libraryId, "filters")}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.pushFeedSettings(libraryId, "filters");
                      removeDialog();
                    }}
                  >
                    Interest filter settings
                  </Link>
                </Typography>
              )}
            </Box>
          </>
        </DynamicComponent>
      }
      onConfirm={handleConfirm}
      primary="Confirm"
      secondary="Cancel"
    />
  );
};

export const BlockTypeTags = ({ document, libraryId, onClose }: BlockTypeTagsProps): JSX.Element => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const { refetch } = useLibrary(document.libraryId);
  const removeDialog = useDialogStore((state) => state.removeDialog);

  const handleBlockTypeTag = async (documentId: string, typeTag: LibraryFilter) => {
    setIsBusy(true);
    setHasChanged(true);
    const body = await blockLibraryTypeTag(getOrgId(user), document.libraryId, documentId, typeTag);
    if (!body) {
      analyticsEvent("Feed", "FilterTypeTagBlocked", typeTag.id);
    }
    refetch();
    dispatch(
      setSnack({
        body: body || `${typeTag.name} content type has now been blocked within this library`,
        title: body ? "Failed" : "Success",
      })
    );
  };

  useEffect(() => {
    if (!document) {
      setIsBusy(false);
    }
  }, [document]);

  return document ? (
    <BlockTypeTagContent
      document={document}
      handleBlockTypeTag={handleBlockTypeTag}
      isBusy={isBusy}
      libraryId={libraryId}
      onClose={(e) => {
        onClose(e, hasChanged);
        removeDialog();
      }}
    />
  ) : (
    <></>
  );
};
