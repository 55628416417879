import axios from "axios";
import { useQuery } from "react-query";
import useAPI from "../api/useAPI";
import { InsightDocument } from "./useInsights";
import { FeedResponse } from "../../../http/feed";
import { useFeedViewStore } from "../../../store/hooks/useFeedViewStore";
import { FeedViewType } from "../../../components/Pages/Feed/components/filterMenu/feedFilters";

export const useInsightKey = "insights-feed-single-document";

export default function useInsight(documentId: string) {
  const api = useAPI("v5", "insights-feed");
  const { view } = useFeedViewStore();
  return useQuery([useInsightKey, documentId], async () => {
    if (view.type === FeedViewType.Insight) {
      const { data } = await axios.get<FeedResponse<InsightDocument>>(api, {
        params: {
          documentId,
        },
      });
      return data.documents[0];
    }
  });
}
