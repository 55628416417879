import { DEFAULT_MAX_SENTIMENT, DEFAULT_MIN_SENTIMENT } from "../../helpers/getRequestDefaults";
import {
  DocumentTabRDV,
  changeSortFilterRDV,
  onResetDocumentFilters,
  setSelectedDocumentTab,
} from "../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import { MenuItem, Select, Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import AnalyseTooltip from "@amplyfi/ui-components/components/Tooltip";
import { DarkSilver } from "@amplyfi/ui-components/theme/colors";
import { Entity } from "../../models/entity";
import React from "react";
import SortButton from "@amplyfi/ui-components/components/SortButton";
import { SortTypes } from "../../models/document";
import { useAnalyseSelector } from "../../store/reducers";
import { useDispatch } from "react-redux";
import { zIndex } from "../../helpers/componentsZIndex";
import useRelatedDocumentList from "../../hooks/useRelatedDocumentList";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { testId } from "@amplyfi/ui-components/testHelpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      padding: theme.spacing(2.5),
      borderBottom: `1px solid ${theme.palette.borders.main}`,
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    scopeContainer: {
      minHeight: theme.spacing(5),
      maxHeight: theme.spacing(10),
      overflow: "auto",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
    scope: {
      display: "flex",
      flexWrap: "wrap",
    },
    dropdownSortContainer: {
      display: "flex",
    },
    filterText: {
      marginRight: theme.spacing(0.5),
      fontWeight: "bold",
      color: DarkSilver,

      "& + &": {
        "&:before": {
          content: "'AND '",
          fontWeight: "normal",
        },
      },
    },
    viewingText: {
      color: DarkSilver,
      marginRight: theme.spacing(0.5),
    },
    clearBtn: {
      background: "none",
      border: "none",
      padding: 0,

      "&:hover": {
        cursor: "pointer",
      },
    },
    clearText: {
      color: theme.palette.links.primary,
    },
    dropdownContainer: {
      width: "100%",
      marginRight: theme.spacing(1),

      "& .MuiInputBase-root": {
        width: "100%",
      },
    },
    dropdown: {
      fontWeight: "bold",
      "& > li": {
        fontWeight: "bold",
      },
    },
    tooltipStyle: {
      zIndex: zIndex.modalAndMenu,
      maxWidth: 170,

      "& .MuiTooltip-tooltip": {
        padding: theme.spacing(1.5),
      },
    },
    dropdownLabel: {
      display: "flex",
      alignItems: "center",
    },
    loading: {
      width: "fit-content",
      marginLeft: theme.spacing(1),
    },
  })
);

export default function DocumentIndexHeader(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataTestIds = ["related-document-viewer", "header"];
  const { libraries } = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const {
    documentFilters: {
      keywordFilters,
      keyPhraseFilters,
      patentApplicantsOwnersFilters,
      academicInstitutionsFilters,
      academicCountryFilters,
      patentJurisdictions,
      publishLocationsFilters,
      sectorsFilters,
      subSectorFilters,
      activeInFilters,
      adverseMediaFilters,
    },
    selectedDocumentType,
    News,
    Academic,
    Patents,
    All,
    Private,
  } = useAnalyseSelector(({ searchResponse: { relatedDocumentViewer } }) => relatedDocumentViewer);
  const arrayFilters = [
    keywordFilters,
    keyPhraseFilters,
    patentApplicantsOwnersFilters,
    academicInstitutionsFilters,
    academicCountryFilters,
    patentJurisdictions,
    publishLocationsFilters,
    sectorsFilters,
    subSectorFilters,
    adverseMediaFilters,
    activeInFilters,
  ];

  const documentMap = {
    News,
    Academic,
    Patents,
    All,
    Private,
  };

  const newsSentimentExist =
    News.documentFilters.minimumSentiment !== DEFAULT_MIN_SENTIMENT ||
    News.documentFilters.maximumSentiment !== DEFAULT_MAX_SENTIMENT;

  const RDVFiltersExist = arrayFilters.some((x) => x.length > 0) || newsSentimentExist;
  const { data: { All: allData } = { All: { documentCount: 0 } }, isLoading: allDataLoading } =
    useRelatedDocumentList("All");
  const librariesData = useRelatedDocumentList(libraries);
  const librariesLoading: Record<DocumentTabRDV, boolean> = libraries.reduce((prev, library, index) => {
    const libraryData = Array.isArray(librariesData) ? librariesData[index] : librariesData;
    return !!libraryData ? { ...prev, [library]: libraryData.isLoading } : prev;
  }, {} as Record<DocumentTabRDV, boolean>);

  const handleClearFilters = () => dispatch(onResetDocumentFilters());

  const handleDropdownChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const tab = e.target.value as DocumentTabRDV;
    dispatch(setSelectedDocumentTab(tab));
  };
  const handleSortClick = (args?: "asc" | "desc") => {
    const sortValue = args === "asc" ? SortTypes.Ascending : SortTypes.Descending;
    dispatch(changeSortFilterRDV({ type: selectedDocumentType, value: sortValue }));
  };

  const availableLibrarySelection: { label: string; docCount: number; value: DocumentTabRDV; loading: boolean }[] = [
    {
      label: "All Docs",
      docCount: allData.documentCount || 0,
      value: "All",
      loading: allDataLoading,
    },
    ...Object.keys(librariesLoading).map((library) => ({
      label: `${library} Docs`,
      docCount: documentMap[library as DocumentTabRDV].totalCount || 0,
      value: library as DocumentTabRDV,
      loading: librariesLoading[library as DocumentTabRDV],
    })),
  ];

  return (
    <div className={classes.headerContainer}>
      <Typography variant="h3" className={classes.title}>
        Related Documents
      </Typography>
      {RDVFiltersExist && (
        <div className={classes.scopeContainer}>
          <span className={classes.scope}>
            <>
              <Typography className={classes.viewingText}>Viewing </Typography>
              {arrayFilters.map((filterArray) =>
                filterArray.map((filter: Entity | string, index: number) => (
                  <Typography className={classes.filterText} key={index}>
                    {typeof filter === "string" ? filter : filter.name}
                  </Typography>
                ))
              )}
              {newsSentimentExist && (
                <Typography className={classes.filterText}>
                  Sentiment {News.documentFilters.minimumSentiment} - {News.documentFilters.maximumSentiment}
                </Typography>
              )}
              <AnalyseTooltip
                title="This will clear the tooltip filters and you will see all the related documents for your query. You can go to the tooltip to reapply"
                classes={{ popper: classes.tooltipStyle }}
              >
                <button onClick={handleClearFilters} className={classes.clearBtn}>
                  <Typography className={classes.clearText}>Clear</Typography>
                </button>
              </AnalyseTooltip>
            </>
          </span>
        </div>
      )}
      <div className={classes.dropdownSortContainer}>
        <div className={classes.dropdownContainer}>
          <Select
            data-testid={testId(...dataTestIds, "document-filter")}
            labelId="documents-type-dropdown-label"
            id="documents-type-dropdown"
            value={selectedDocumentType}
            onChange={handleDropdownChange}
            MenuProps={{
              style: { zIndex: zIndex.modalAndMenu },
              classes: { list: classes.dropdown },
            }}
            classes={{ root: classes.dropdown }}
            disabled
          >
            {availableLibrarySelection.map((tab) => (
              <MenuItem
                data-testid={testId(...dataTestIds, "document-filter", tab.value.toLowerCase())}
                value={tab.value}
                style={{ zIndex: zIndex.modalAndMenu }}
                disabled={!tab.loading && tab.docCount === 0}
                key={tab.label}
              >
                <div className={classes.dropdownLabel}>
                  {tab.label}
                  {tab.loading ? (
                    <AiCircularProgress size={15} className={classes.loading} />
                  ) : (
                    ` (${tab.docCount.toLocaleString()})`
                  )}
                </div>
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <SortButton
            data-testid={testId(...dataTestIds, "sort")}
            onClick={handleSortClick}
            order={documentMap[selectedDocumentType].sortFilter === SortTypes.Ascending ? "asc" : "desc"}
            disabled={All.totalCount === 0}
          />
        </div>
      </div>
    </div>
  );
}
