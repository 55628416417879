import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useAnalyseSelector } from "../../../store/reducers";
import KeyPhrasesCard from "../../Cards/KeyPhrasesCard";
import LocationCard from "../../Cards/LocationCard";
import OrganisationsCard from "../../Cards/OrganisationsCard";
import SentimentOverTimeCard from "../../Cards/SentimentOverTimeCard";
import SentimentSentencesCard from "../../Cards/SentimentSentencesCard";
import GeoMapCard from "../../Charts/GeoMapCard";
import NoLibrarySelected from "../../Widgets/NoLibrarySelected/NoLibrarySelected";
import SentimentBarSummaryCard from "../../Cards/SentimentBarSummaryCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1.5),
    },
  })
);

export default function Overview(): JSX.Element {
  const classes = useStyles();
  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const libraries = url.libraries;

  return (
    <>
      <>
        <Grid container spacing={2} className={classes.container}>
          {libraries.length === 0 ? (
            <Grid item xs={12} key="no-library-selected">
              <NoLibrarySelected />
            </Grid>
          ) : (
            [
              <OrganisationsCard />,
              <KeyPhrasesCard />,
              <GeoMapCard />,
              <LocationCard />,
              <SentimentOverTimeCard />,
              <SentimentBarSummaryCard />,
              <SentimentSentencesCard />,
            ].map((component, i) => (
              <Grid item xs={12} key={`${i}`}>
                {component}
              </Grid>
            ))
          )}
        </Grid>
      </>
    </>
  );
}
