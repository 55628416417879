import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import useGenerativeSummary from "../../../../hooks/useGenerativeSummary";
import { MonitoringDocument } from "../../../../models/monitoring";
import { AbstractTabProps } from "../tabs";
import Tab from "./Tab";

export default function SummaryTab({ documentId, document, documentMeta }: AbstractTabProps) {
  const id = (document as MonitoringDocument)?.documentId || documentId;
  const data = useGenerativeSummary(id);

  return data.length === 0 ? (
    <AiCircularProgress />
  ) : (
    <Tab
      content={data.split("\n")}
      copyMultiple={{
        handler: documentMeta,
        text: "Copy all",
        tooltipText: "Copy Summary and document info",
      }}
      copySingular={{ text: "Copy Summary", handler: (a) => a }}
      noContent="No summary was extracted"
    />
  );
}
