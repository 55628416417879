type EntityTypeGuard = "type";
type EntityNonEditableFields =
  | "id"
  | "name"
  | "deleted"
  | "usedInLibraries"
  | "createdAt"
  | "createdBy"
  | "updatedAt"
  | "updatedBy"
  | "createdByOrganisation"
  | "boardMembers"
  | "categories"
  | "competitors"
  | "facebookUri"
  | "foundingDate"
  | "investments"
  | "ipo"
  | "isPublic"
  | "linkedInUri"
  | "location"
  | "partnerships"
  | "quarterlyRevenues"
  | "revenue"
  | "secondaryDescription"
  | "subsidiaries"
  | "twitterUri"
  | "employeesMin"
  | "employeesMax"
  | "yearlyRevenues"
  | "profileData";

export enum EntityType {
  Organisation = "Organisation",
  Technology = "Technology",
}

interface AmplyfiEntityAudit {
  createdByOrganisation: null | string;
  createdAt: null | string;
  createdBy: null | string;
  updatedAt: null | string;
  updatedBy: null | string;
}

interface AmplyfiEntity extends AmplyfiEntityAudit {
  aliases: string[];
  deleted: boolean;
  description: string;
  id: string;
  name: string;
  usedInLibraries?: string[];
  verified: false | null;
  wikidataId: string;
}

interface AmplyfiOrganisationBoardMember {
  name: string;
  summary: string;
}

export interface AmplyfiOrganisationRelatedOrg {
  name: string;
  summary: string;
  type: string;
}

interface AmplyfiOrganisationRevenue {
  value: number;
  currency: string;
}

interface AmplyfiInvestment {
  date: string;
  is_current: boolean;
  series: string;
}

interface Classification {
  name: string;
  code: string;
}

interface Report {
  filing_url: string;
  filing_date: string;
  form_type: string;
}

export interface ProfileData {
  boardMembers: AmplyfiOrganisationBoardMember[] | null;
  categories: { name: string }[] | null;
  customers: AmplyfiOrganisationRelatedOrg[] | null;
  competitors: AmplyfiOrganisationRelatedOrg[] | null;
  description: string | null;
  employeesMin: number | null;
  employeesMax: number | null;
  facebookUri: string | null;
  foundingDate: string | null;
  investments: AmplyfiInvestment[] | null;
  ipo: { date: string } | null;
  isPublic: boolean | null;
  linkedInUri: string | null;
  location: {
    address: string;
  } | null;
  marketCapitalisation: AmplyfiOrganisationRevenue | null;
  naicsClassification: Classification[] | null;
  partnerships: AmplyfiOrganisationRelatedOrg[] | null;
  quarterlyRevenues: { revenue_date: string; revenue: AmplyfiOrganisationRevenue }[] | null;
  revenue: AmplyfiOrganisationRevenue;
  secForms: Report[] | null;
  sicClassification: Classification[] | null;
  subsidiaries: AmplyfiOrganisationRelatedOrg[];
  twitterUri: string;
  yearlyRevenues: { year: string; revenue: AmplyfiOrganisationRevenue }[];
}

export type AmplyfiOrganisation = AmplyfiEntity & {
  [x in EntityTypeGuard]: EntityType.Organisation;
} & {
  employeeCount: number;
  founded: string | null;
  industries: string[];
  linkedin: string;
  profileData: ProfileData;
  socialFollowerCount: number;
  websites?: string[];
};

export type AmplyfiTechnology = AmplyfiEntity & {
  [x in EntityTypeGuard]: EntityType.Technology;
} & {
  subTechnologies: string[];
  technologyType: string;
};

export type AmplyfiUnknownEntity = AmplyfiOrganisation | AmplyfiTechnology;

export type AmplyfiEditableFields<Fields extends AmplyfiEntity> = Omit<
  Fields,
  EntityTypeGuard | EntityNonEditableFields
>;

export type AmplyfiAllEditableFields = AmplyfiEditableFields<AmplyfiOrganisation> &
  AmplyfiEditableFields<AmplyfiTechnology>;
