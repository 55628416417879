import { create } from "zustand";

export interface PageProps {
  id: string;
  title: string;
  thumbnail?: string;
  scrollable?: boolean;
  hideBack?: boolean;
}

interface PageStoreState {
  pages: PageProps[];
  removePage: (id: string) => void;
  addPage: (page: PageProps) => void;
  updatePage: (page: PageProps) => void;
  pop: () => void;
  reset: () => void;
}

const usePageStore = create<PageStoreState>((set) => ({
  pages: [],
  removePage: (id: string) => set((state) => ({ pages: state.pages.filter((p) => p.id !== id) })),
  addPage: (page: PageProps) => set((state) => ({ pages: [...state.pages, page] })),
  updatePage: (page: PageProps) =>
    set((state) => ({ pages: state.pages.map((p) => (p.id === page.id ? { ...p, ...page } : p)) })),
  pop: () => set((state) => ({ pages: state.pages.slice(0, -1) })),
  reset: () => set((state) => ({ pages: state.pages[0] ? [state.pages[0]] : [] })),
}));

export default usePageStore;
