import { CanUserPermissionCheck, getPermissions, userPermission } from "../../../helpers/user";

export const feature = "research";
const organisationFeature = "organisation";

const research = userPermission(feature);
const organisation = userPermission(organisationFeature);

export const canUserReadResearch: CanUserPermissionCheck = (user) => getPermissions(user).includes(research.read());

export const canUserReadGenerativeResearch: CanUserPermissionCheck = (user) =>
  getPermissions(user).includes("read:generative-research");

export const canUserUpdateOrganisationConnectors: CanUserPermissionCheck = (user) =>
  getPermissions(user).includes(organisation.update("connectors"));

export const canUserDeleteOrganisationConnectors: CanUserPermissionCheck = (user) =>
  getPermissions(user).includes(organisation.delete("connectors"));

export const canUserCreateResearchExtendedConnectors: CanUserPermissionCheck = (user) =>
  getPermissions(user).includes(research.create("extendedConnectors"));
