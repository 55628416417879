import {
  onEntitiesSearchError,
  onEntitiesSearchLoading,
  onEntitiesSearchSuccess,
  resetEntitiesSearch,
  onKeyPhrasesSearchError,
  onKeyPhrasesSearchLoading,
  onKeyPhrasesSearchSuccess,
  resetKeyPhrasesSearch,
} from "../reducers/customization/customizationReducer";
import { getEntitiesSearchSaga, getKeyPhrasesSearchSaga } from "./generate-search-sagas";

export const customizationKeyPhrasesSearch = getKeyPhrasesSearchSaga(
  onKeyPhrasesSearchLoading,
  onKeyPhrasesSearchError,
  onKeyPhrasesSearchSuccess,
  resetKeyPhrasesSearch
);

export const customizationEntitiesSearch = getEntitiesSearchSaga(
  onEntitiesSearchLoading,
  onEntitiesSearchError,
  onEntitiesSearchSuccess,
  resetEntitiesSearch
);
