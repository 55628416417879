import { Entity } from "../models/entity";
import { FilterType } from "../models/filter";
import { ParsedQueryParams } from "../models/queryParams";

interface FilterListInterface {
  generalFilters: Record<string, Entity[]>;
  newsFilters: Record<string, Entity[]>;
  academicFilters: Record<string, Entity[]>;
  patentFilters: Record<string, Entity[]>;
}

export const getSelectedFiltersLists = (urlState: ParsedQueryParams): FilterListInterface => {
  const generalFilters: Record<string, Entity[]> = {
    [FilterType.Keywords]: urlState.keywords,
    [FilterType.DataTypes]: urlState.dataTypes,
  };
  const newsFilters: Record<string, Entity[]> = {
    [FilterType.Sources]: urlState.sources,
    [FilterType.Sectors]: urlState.sectors,
    [FilterType.SubSectors]: urlState.subSectors,
    [FilterType.Locations]: urlState.locations,
    [FilterType.PublishLocations]: urlState.publishLocations,
  };
  const academicFilters: Record<string, Entity[]> = {
    [FilterType.Authors]: urlState.authors,
    [FilterType.AcademicPublishLocations]: urlState.academicPublishLocations,
    [FilterType.PublicationType]: urlState.publicationType,
    [FilterType.Institution]: urlState.institution,
    [FilterType.InstitutionCountry]: urlState.institutionCountry,
  };
  const patentFilters: Record<string, Entity[]> = {
    [FilterType.Inventors]: urlState.inventors,
    [FilterType.Owners]: urlState.owners,
    [FilterType.Applicants]: urlState.applicants,
    [FilterType.PatentType]: urlState.patentType,
  };

  return { generalFilters, newsFilters, academicFilters, patentFilters };
};
