import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useAnalyseSelector } from "../../store/reducers";
import TextLabelWithCounter from "@amplyfi/ui-components/components/TextSearch/TextLabelWithCounter";
import { DarkSilver, Melrose, PastelPurple } from "@amplyfi/ui-components/theme/colors";
import {
  EntityGroupType,
  setHighlightedDocumentEntities,
} from "../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import { useDispatch } from "react-redux";
import { getDocumentColourRDV } from "../../models/entityColourMap";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.componentBackground.main,
      display: "flex",
      height: "100%",
      alignItems: "center",
      borderRadius: "0 6px 6px 0",
      border: `1px solid ${theme.palette.borders.main}`,
      borderLeft: "none",
      padding: theme.spacing(0, 0.75),
      "& > *": {
        "* + &": {
          marginRight: theme.spacing(2),
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    mentionsText: {
      color: DarkSilver,
      letterSpacing: "initial",
    },
  })
);

type EntitiesState = { disabled: boolean; NA: boolean; totalCount?: number };

export default function DocumentEntities(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    selectedDocumentEntites: entitiesGroups,
    highlightedDocumentEntities,
    selectedDocumentLoading,
  } = useAnalyseSelector((x) => x.searchResponse.relatedDocumentViewer);
  const personEntitiesState: EntitiesState = {
    disabled: !highlightedDocumentEntities.includes(EntityGroupType.Person),
    NA: entitiesGroups.Person === undefined,
    totalCount: entitiesGroups.Person?.length,
  };
  const placesEntitiesState: EntitiesState = {
    disabled: !highlightedDocumentEntities.includes(EntityGroupType.Country),
    NA: entitiesGroups.Country === undefined,
    totalCount: entitiesGroups.Country?.length,
  };
  const organisationsEntitiesState: EntitiesState = {
    disabled: !highlightedDocumentEntities.includes(EntityGroupType.Organisation),
    NA: entitiesGroups.Organisation === undefined,
    totalCount: entitiesGroups.Organisation?.length,
  };
  const handleHighlightEntities = (type: EntityGroupType) => {
    if (highlightedDocumentEntities.includes(type)) {
      dispatch(setHighlightedDocumentEntities(highlightedDocumentEntities.filter((x) => x !== type)));
    } else {
      dispatch(setHighlightedDocumentEntities([...highlightedDocumentEntities, type]));
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.mentionsText} variant="caption">
        Mentions
      </Typography>
      <div>
        <TextLabelWithCounter
          loading={selectedDocumentLoading}
          label="People"
          backgroundColor={PastelPurple}
          labelColor={Melrose}
          NA={personEntitiesState.NA}
          totalCount={personEntitiesState.totalCount}
          disabled={personEntitiesState.disabled}
          onClick={
            !personEntitiesState.NA && !!personEntitiesState.totalCount && !selectedDocumentLoading
              ? () => handleHighlightEntities(EntityGroupType.Person)
              : undefined
          }
        />
      </div>
      <div>
        <TextLabelWithCounter
          loading={selectedDocumentLoading}
          label="Locations"
          backgroundColor="#E7F4F9"
          labelColor={getDocumentColourRDV(EntityGroupType.Country)}
          NA={placesEntitiesState.NA}
          totalCount={placesEntitiesState.totalCount}
          disabled={placesEntitiesState.disabled}
          onClick={
            !placesEntitiesState.NA && !!placesEntitiesState.totalCount && !selectedDocumentLoading
              ? () => handleHighlightEntities(EntityGroupType.Country)
              : undefined
          }
        />
      </div>
      <div>
        <TextLabelWithCounter
          loading={selectedDocumentLoading}
          label="Organisations"
          backgroundColor="#F8E5FF"
          labelColor="#DB7CFD"
          NA={organisationsEntitiesState.NA}
          totalCount={organisationsEntitiesState.totalCount}
          disabled={organisationsEntitiesState.disabled}
          onClick={
            !organisationsEntitiesState.NA && !!organisationsEntitiesState.totalCount && !selectedDocumentLoading
              ? () => handleHighlightEntities(EntityGroupType.Organisation)
              : undefined
          }
        />
      </div>
    </div>
  );
}
