import React, { useCallback, useEffect, useState } from "react";
import ModalFormContent from "@amplyfi/ui-components/components/ModalFormContent";
import { createStyles, darken, FormControlLabel, makeStyles, Theme, Typography } from "@material-ui/core";
import AnalyseSwitch from "@amplyfi/ui-components/components/Switch";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { AiDate, dateError, dateGranularity } from "../../helpers/dateHelpers";
import { DEFAULT_DISPLAY_END, DEFAULT_START, timePeriodMap } from "../../store/reducers/searchRequest/url-functions";
import { B10Highlight, SemiGrey, DarkSilver, SemiDarkSilver, White } from "@amplyfi/ui-components/theme/colors";
import { link, useButtonStyles } from "../../css/mixins";
import { analyticsEvent } from "../../helpers/analytics";
import { useDispatch } from "react-redux";
import { onGlobalDatesChange, updateUndatedDocuments } from "../../store/reducers/searchRequest/urlReducer";
import Button from "@amplyfi/ui-components/components/Button";
import { timePeriodLabels, TimeSelectorType } from "../Charts/TimeSelector";
import { useAnalyseSelector } from "../../store/reducers";
import clsx from "clsx";
import useRelatedDocumentList from "../../hooks/useRelatedDocumentList";
import { SortTypes } from "../../models/document";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { zIndex } from "../../helpers/componentsZIndex";
import { useFullscreenContainer } from "@amplyfi/ui-components/hooks/useFullscreenContainer";
import { Days } from "../../helpers/timeIntervals";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 424,
      padding: theme.spacing(1, 2),
    },
    datePickerContainer: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2, 0),
      borderBottom: `1px solid ${theme.palette.borders.main}`,
    },
    datePickerField: {
      flex: "1 1 50%",
      height: 65,
      maxWidth: 175,
    },
    datePickerHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    datePicker: {
      cursor: "pointer",
      "& .MuiInputBase-root": {
        cursor: "pointer",
        "&:hover": {
          backgroundColor: SemiGrey,
        },
      },
      "& .MuiInputBase-input": {
        cursor: "pointer",
      },
    },
    label: {
      marginBottom: theme.spacing(1),
      color: DarkSilver,
      display: "flex",
      justifyContent: "space-between",
    },
    link: {
      ...link(theme),
    },
    radioContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      display: "flex",
      marginLeft: theme.spacing(1),
    },
    subheading: {
      color: DarkSilver,
      marginLeft: theme.spacing(1),
    },
    selector: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
    },
    tertiary: {
      marginRight: theme.spacing(1.5),
    },
    selectedDate: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "3px",
      color: White,
      "& > *": {
        backgroundColor: theme.palette.secondary.main,
        color: White,
        "&:hover": {
          background: darken(theme.palette.secondary.main, 0.1),
          transition: "color 0.5s ease-in-out",
        },
      },
      "&:hover": {
        background: darken(theme.palette.secondary.main, 0.1),
        transition: "color 0.5s ease-in-out",
        borderRadius: "3px",
      },
    },
    betweenDate: {
      backgroundColor: B10Highlight,
      borderRadius: 3,
      "&:hover": {
        background: darken(B10Highlight, 0.1),
        transition: "color 0.5s ease-in-out",
      },
      "& > *": {
        "&:hover": {
          background: darken(B10Highlight, 0.1),
          transition: "color 0.5s ease-in-out",
        },
      },
    },
    day: {
      "& .MuiIconButton-root": {
        borderRadius: 3,

        "&:hover": {
          borderRadius: 3,
        },
      },
    },
    dateRangeIcon: {
      color: SemiDarkSilver,
    },
  })
);

type AiDatePicker = {
  error: ReturnType<typeof dateError>;
  name: string;
};

enum ExcludeDirection {
  Before,
  After,
}

const timelinePeriodLabels: { key: TimeSelectorType; label: string }[] = [
  { key: "custom", label: "All" },
  ...timePeriodLabels.filter((x) => x.key !== "custom"),
];

export const timelineFilterLabels: Record<TimeSelectorType, string> = {
  "3months": "Last 3 months",
  "6months": "Last 6 months",
  "1year": "Last 1 year",
  "3years": "Last 3 years",
  "5years": "Last 5 years",
  custom: "All time",
};

interface TimelineDialogProps {
  includeUndatedDocuments: boolean;
  onApply?: () => void;
}

export default function TimelineDialog(props: TimelineDialogProps): JSX.Element {
  const { includeUndatedDocuments, onApply } = props;

  const classes = useStyles();
  const labelDateFormat = "D MMM YYYY";
  const [undatedDocuments, setIncludeUndatedDocuments] = useState<boolean>(includeUndatedDocuments);
  const { period, endDate, startDate } = useAnalyseSelector((x) => x.searchRequest.url.parsed);

  const [tempStartDate, setTempStartDate] = useState<AiDate>(DEFAULT_START.clone());
  const [tempEndDate, setTempEndDate] = useState<AiDate>(DEFAULT_DISPLAY_END.clone());
  const [tempPeriod, setTempPeriod] = useState<TimeSelectorType | undefined>(period as TimeSelectorType);
  const [earliestDateSelected, setEarliestDateSelected] = useState(false);
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);
  const [isEndDateToday, setEndDateToday] = useState(false);

  const dispatch = useDispatch();
  const { data } = useRelatedDocumentList("All", SortTypes.Ascending, false, true, 1, false);
  const earliestDate = data?.All.documents.length ? moment(data.All.documents[0].date) : null;
  const mixinClasses = useButtonStyles();
  const clearButtonStyling = mixinClasses.clearButtonStyling;
  const container = useFullscreenContainer();

  useEffect(() => {
    setIncludeUndatedDocuments(includeUndatedDocuments);
  }, [includeUndatedDocuments]);

  useEffect(() => {
    setTempStartDate(startDate ? dateGranularity(moment(startDate)) : null);
  }, [startDate]);

  useEffect(() => {
    if (endDate === Days.Today) {
      setEndDateToday(true);
      setTempEndDate(endDate ? dateGranularity(DEFAULT_DISPLAY_END) : null);
    } else {
      setTempEndDate(endDate ? moment.utc(endDate) : null);
    }
  }, [endDate]);

  useEffect(() => {
    setTempPeriod(period as TimeSelectorType);
  }, [period]);

  useEffect(() => {
    if (!tempPeriod && !tempStartDate && !tempEndDate) {
      setTempPeriod("custom");
    }
    if (!tempStartDate) {
      setTempStartDate(earliestDate ? earliestDate : null);
    }
  }, [tempPeriod, tempStartDate, tempEndDate, earliestDate]);

  const renderDayPicker = useCallback(
    (
      day: MaterialUiPickersDate,
      selectedDate: MaterialUiPickersDate,
      dayInCurrentMonth: boolean,
      dayComponent: JSX.Element
    ) => {
      if (!dayInCurrentMonth) {
        return dayComponent;
      }
      if (day?.isSame(selectedDate)) {
        return <div className={clsx(classes.day, classes.selectedDate)}> {dayComponent} </div>;
      }
      if (day?.isBetween(tempStartDate, tempEndDate)) {
        return <div className={clsx(classes.day, classes.betweenDate)}> {dayComponent} </div>;
      }
      return <div className={classes.day}>{dayComponent}</div>;
    },
    [tempStartDate, tempEndDate, classes.day, classes.selectedDate, classes.betweenDate]
  );

  const start: AiDatePicker = {
    error: dateError(tempStartDate),
    name: "Start Date",
  };

  const onEarliestDate = () => {
    setTempStartDate(earliestDate);
    setEarliestDateSelected(true);
    setTempPeriod(undefined);
  };

  const onLatestDate = () => {
    setEndDateToday(true);
    setTempPeriod(undefined);
    setTempEndDate(DEFAULT_DISPLAY_END.clone());
  };

  const setDateRange = (key: TimeSelectorType) => {
    if (key !== "custom") {
      const start = timePeriodMap[key].clone();
      const end = DEFAULT_DISPLAY_END.clone();
      setTempStartDate(start);
      setTempEndDate(end);
      setEarliestDateSelected(false);
    } else {
      setTempStartDate(earliestDate ? earliestDate : null);
      setEarliestDateSelected(earliestDate ? true : false);
      setTempEndDate(DEFAULT_DISPLAY_END);
    }
    setEndDateToday(false);
  };

  const applyTimelineFilters = () => {
    analyticsEvent(
      "Filters",
      "ChangingDate",
      tempStartDate && tempEndDate ? `${tempStartDate.format("DD/MM/YYYY")}-${tempEndDate.format("DD/MM/YYYY")}` : "All"
    );

    dispatch(updateUndatedDocuments(undatedDocuments));
    dispatch(
      onGlobalDatesChange({
        dates: [
          tempStartDate && tempPeriod !== "custom" ? moment.utc(tempStartDate.toObject()).valueOf() : undefined,
          tempEndDate && tempPeriod !== "custom"
            ? moment.utc(tempEndDate.toObject()).endOf("day").valueOf()
            : undefined,
        ],
        period: tempPeriod,
        isEndDateToday,
      })
    );
    onApply?.();
  };

  const isEarliestLinkDisabled = () => {
    return (!!tempStartDate && earliestDateSelected) || moment(tempStartDate).endOf("day").isSame(earliestDate, "day");
  };

  const isTodayLinkDisabled = () => {
    return !!tempEndDate && isEndDateToday;
  };

  const disableDate = (day: MaterialUiPickersDate, excludeDirection: ExcludeDirection): boolean => {
    if (excludeDirection === ExcludeDirection.After) {
      return day?.isAfter(tempEndDate) || false;
    } else {
      return day?.isBefore(tempStartDate) || false;
    }
  };

  return (
    <ModalFormContent
      handleSubmit={applyTimelineFilters}
      title="Timeline filters"
      submitButtonText="Apply timeline"
      submitScopeText="This will apply to all components"
      data-testid="time-filters"
      disableSubmit={!!tempEndDate && !!tempStartDate && tempEndDate < tempStartDate}
    >
      <div className={classes.container}>
        <Typography variant="h6" className={classes.label}>
          Time range
        </Typography>
        <div className={classes.selector}>
          {timelinePeriodLabels.map(({ key, label }) => (
            <Button
              amplyfiType={tempPeriod === key ? "selectedTertiary" : "tertiary"}
              onClick={() => {
                setTempPeriod(key);
                setDateRange(key);
              }}
              key={key}
            >
              {label}
            </Button>
          ))}
        </div>

        <div className={classes.datePickerContainer}>
          <div className={classes.datePickerField}>
            <span className={classes.datePickerHeader}>
              <Typography variant="h6" className={classes.label}>
                Start Date
              </Typography>
              <button className={clearButtonStyling} onClick={isEarliestLinkDisabled() ? undefined : onEarliestDate}>
                <Typography variant="h6" className={clsx(isEarliestLinkDisabled() ? null : classes.link)}>
                  Earliest
                </Typography>
              </button>
            </span>
            <KeyboardDatePicker
              disableFuture
              variant="inline"
              error={!!start.error}
              format={labelDateFormat}
              helperText={start.error || undefined}
              onChange={(value) => {
                setTempStartDate(value);
                setTempPeriod(undefined);
                if (!tempEndDate) {
                  setTempEndDate(DEFAULT_DISPLAY_END.clone());
                }
                setEarliestDateSelected(false);
              }}
              className={classes.datePicker}
              value={dateGranularity(tempStartDate)}
              inputProps={{ readOnly: true, onClick: () => setShowStartDatePicker(true) }}
              SelectProps={{ className: classes.betweenDate }}
              shouldDisableDate={(day) => disableDate(day, ExcludeDirection.After)}
              renderDay={renderDayPicker}
              open={showStartDatePicker}
              onClose={() => setShowStartDatePicker(false)}
              keyboardIcon={<DateRangeIcon className={classes.dateRangeIcon} />}
              InputAdornmentProps={{ onClick: () => setShowStartDatePicker(true) }}
              PopoverProps={{ style: { zIndex: zIndex.modalAndMenu }, container }}
              autoOk
            />
          </div>
          <div className={classes.datePickerField}>
            <span className={classes.datePickerHeader}>
              <Typography variant="h6" className={classes.label}>
                End Date
              </Typography>
              <button className={clearButtonStyling} onClick={isTodayLinkDisabled() ? undefined : onLatestDate}>
                <Typography className={clsx(isTodayLinkDisabled() ? null : classes.link)} variant="h6">
                  Today
                </Typography>
              </button>
            </span>
            <KeyboardDatePicker
              variant="inline"
              format={labelDateFormat}
              onChange={(value) => {
                setTempEndDate(value);
                setTempPeriod(undefined);
                setEndDateToday(false);
              }}
              value={dateGranularity(tempEndDate)}
              inputProps={{ readOnly: true, onClick: () => setShowEndDatePicker(true) }}
              minDate={tempStartDate ?? undefined}
              shouldDisableDate={(day) => disableDate(day, ExcludeDirection.Before)}
              renderDay={renderDayPicker}
              keyboardIcon={<DateRangeIcon className={classes.dateRangeIcon} />}
              InputAdornmentProps={{ onClick: () => setShowEndDatePicker(true) }}
              open={showEndDatePicker}
              onClose={() => setShowEndDatePicker(false)}
              className={classes.datePicker}
              PopoverProps={{ style: { zIndex: zIndex.modalAndMenu }, container }}
              autoOk
            />
          </div>
        </div>
        <div className={classes.radioContainer}>
          <FormControlLabel
            control={
              <AnalyseSwitch
                toggleSelected={() => {
                  setIncludeUndatedDocuments(!undatedDocuments);
                }}
                selected={undatedDocuments === true}
                selectedLabel=""
                unselectedLabel=""
              />
            }
            label={
              <Typography className={classes.subheading} variant="h6">
                Include documents without timestamp
              </Typography>
            }
            labelPlacement="end"
          />
        </div>
      </div>
    </ModalFormContent>
  );
}
