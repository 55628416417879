import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import FeedbackTextButton from "../Widgets/Feedback/FeedbackTextButton";
import FeedbackModal from "../Widgets/Feedback/FeedbackModal";
import CardTitle from "./CardTitle";

type CardSizes = "small" | "medium" | "large";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexGrow: 1,
      borderRadius: 10,
      flexDirection: "column",
      background: theme.palette.componentBackground.main,
      border: `1px solid ${theme.palette.borders.contrastText}`,
    },
    title: { padding: 16 },
    content: {
      padding: "17px 25px 25px",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      overflow: "auto",
    },
    contentTopBorder: {
      borderTop: `1px solid ${theme.palette.borders.main}`,
    },
    skeletonContainer: {
      height: "100%",
    },
    skeleton: { height: "100%" },
  })
);

interface CardProps {
  title?: JSX.Element;
  description?: string;
  content: JSX.Element;
  containerClasses?: string;
  contentClasses?: string;
  contentId?: string;
  loading?: boolean;
  size?: CardSizes;
  feedbackComponentTitle?: string;
  infoContent?: string | JSX.Element;
  viewMoreContent?: JSX.Element;
  rhsContent?: JSX.Element;
  id?: string;
  dataTestId?: string;
  isError?: boolean;
  error?: string;
}

export default function Card(props: CardProps): JSX.Element {
  const {
    title,
    content,
    containerClasses,
    contentClasses,
    rhsContent,
    loading = false,
    size = "small",
    feedbackComponentTitle,
    description,
    infoContent,
    viewMoreContent,
    id,
    dataTestId,
    contentId,
    isError,
    error,
  } = props;
  const classes = useStyles();
  let componentHeight;
  switch (size) {
    case "small":
      componentHeight = 400;
      break;
    case "medium":
      componentHeight = 500;
      break;
    case "large":
      componentHeight = 700;
      break;
    default:
      break;
  }
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div
      data-testid={dataTestId}
      id={id}
      className={clsx(classes.container, containerClasses)}
      style={{ maxHeight: size !== "large" ? componentHeight : "100%", overflow: loading ? "hidden" : undefined }}
    >
      {loading ? (
        <div className={classes.skeletonContainer}>
          <Skeleton
            className={classes.skeleton}
            style={{ lineHeight: "inherit", minHeight: "100%", width: "100%" }}
            height={componentHeight}
          />
        </div>
      ) : (
        <>
          {!!title && (
            <CardTitle
              titleText={title}
              titleDescription={description}
              info={isError ? undefined : infoContent}
              viewMoreContent={isError ? undefined : viewMoreContent}
              rhsContent={isError ? undefined : rhsContent}
            />
          )}
          <div className={clsx(classes.content, title && classes.contentTopBorder, contentClasses)} id={contentId}>
            {isError ? error : content}
            {!!feedbackComponentTitle && !isError && (
              <>
                <FeedbackTextButton handleClick={() => setShowModal(true)} />
                <FeedbackModal
                  open={showModal}
                  onClose={(_e, _reason) => setShowModal(false)}
                  showCloseIcon={false}
                  componentName={feedbackComponentTitle}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
