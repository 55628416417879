import moment from "moment";
import { Entity } from "../models/entity";
import { ParsedQueryParams } from "../models/queryParams";
import { SearchRequestV2, SearchRequestUserFilters, SearchRequestV3 } from "../models/search";
import {
  convertEntityToFiltersArray,
  DEFAULT_INTERVAL,
  getParsedQueryParams,
} from "../store/reducers/searchRequest/url-functions";

function valueToUndefined(value: Entity[]): Entity[] | undefined {
  if (value.length === 0) {
    return undefined;
  }
  return value;
}

export function convertSearchStateToSearchRequestV2(
  state: ParsedQueryParams,
  customDates?: { startDate?: number; endDate?: number }
): SearchRequestV2 {
  const {
    any: anySearchTerms,
    all: allSearchTerms,
    none: exclusionSearchTerms,
    lucene: luceneQuery,
    withinSentence,
  } = state;

  const request = {
    query: {
      allSearchTerms: valueToUndefined(allSearchTerms),
      anySearchTerms: valueToUndefined(anySearchTerms),
      exclusionSearchTerms: valueToUndefined(exclusionSearchTerms),
      luceneQuery,
      withinSentence,
    },
    userFilters: convertFiltersToSearchRequest(state, customDates),
    systemFilters: {
      dateInterval: DEFAULT_INTERVAL,
    },
  };

  return request;
}

export function convertSearchStateToSearchRequestV3(
  state: ParsedQueryParams,
  customDates?: { startDate?: number; endDate?: number }
): SearchRequestV3 {
  const {
    any: anySearchTerms,
    all: allSearchTerms,
    none: exclusionSearchTerms,
    lucene: luceneQuery,
    withinSentence,
  } = state;

  const request = {
    query: {
      allSearchTerms: valueToUndefined(allSearchTerms),
      anySearchTerms: valueToUndefined(anySearchTerms),
      exclusionSearchTerms: valueToUndefined(exclusionSearchTerms),
      luceneQuery,
      withinSentence,
    },
    userFilters: convertFiltersToSearchRequest(state, customDates),
    systemFilters: {
      dateInterval: DEFAULT_INTERVAL,
    },
  };

  return request;
}

export function getCurrentSearchRequestV2(): SearchRequestV2 {
  return convertSearchStateToSearchRequestV2(getParsedQueryParams());
}

function convertFiltersToSearchRequest(
  state: ParsedQueryParams,
  customDates?: { startDate?: number; endDate?: number }
): SearchRequestUserFilters {
  const {
    sectors,
    sources,
    keywords,
    locations,
    publishLocations,
    activeInSectors,
    adverseMediaCategories,
    mentionedLocation,
    risks,
    subSectors,
    includeUndatedDocuments,
    libraryIds,
  } = state;
  const startDate = customDates?.startDate ?? state.startDate;
  const endDate = customDates?.endDate ?? state.endDate;

  const news = {
    publishedLocations: convertEntityToFiltersArray(publishLocations),
    sectors: convertEntityToFiltersArray(sectors),
    sources: convertEntityToFiltersArray(sources),
    activeInSectors: convertEntityToFiltersArray(activeInSectors),
    adverseMediaCategories: convertEntityToFiltersArray(adverseMediaCategories),
    mentionedLocation: convertEntityToFiltersArray(mentionedLocation),
    risks: convertEntityToFiltersArray(risks),
    subSectors: convertEntityToFiltersArray(subSectors),
  };

  const filters: SearchRequestUserFilters = {
    keywords: valueToUndefined([...locations, ...keywords]),
    keyPhrases: undefined,
    startDate: startDate ? moment.utc(startDate).toISOString() : undefined,
    endDate: endDate ? moment.utc(endDate).toISOString() : undefined,
    includeUndatedDocuments,
    libraryIds,
    news: Object.values(news).filter((d) => d !== undefined).length > 0 ? news : undefined,
  };

  return filters;
}
