import load from "@segment/load-script";
import { AnalyticsPlugin } from "analytics";
import { IndentifyPayload } from "../analytics.types";

interface Config {
  apiKey: string;
  enabled?: boolean;
}

interface Initialize {
  account: {
    id: string;
    name: string;
    is_paying?: string;
    monthly_value?: string;
    planLevel?: string;
    planPrice?: string;
    creationDate?: string;
    [x: string]: string | undefined;
  };
  visitor: {
    id: string;
    email?: string;
    full_name?: string;
    role?: string;
    [x: string]: string | undefined;
  };
}

interface Payload {
  event: string;
  properties: Record<string, number | string | boolean>;
}

const clientSource = (apiKey: string) => `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;

export default function plugin(config: Config): AnalyticsPlugin {
  return {
    config,
    name: "pendo",
    identify({
      payload: {
        traits: { organisationId, organisationName },
        userId,
      },
    }: {
      payload: IndentifyPayload;
    }) {
      const p: Initialize = {
        account: { id: organisationId, name: organisationName },
        visitor: { id: userId },
      };
      window.pendo?.initialize(p);
    },
    initialize({ config: { apiKey } }: { config: Config }) {
      if (!apiKey) {
        return;
      }

      window.pendo_config = { apiKey };
      if (!window.pendo) {
        load(clientSource(apiKey));
      }
    },
    loaded() {
      return !!window?.pendo;
    },
    track({ payload: { event, properties } }: { payload: Payload }) {
      window.pendo?.track(event, properties);
    },
  };
}
