import React, { useState } from "react";
import { Sentence } from "../../../models/sentence";
import AnalysePagination from "@amplyfi/ui-components/components/Pagination";
import SentenceList from "../../Widgets/Misc/SentenceList";

const SENTIMENT_SENTENCES_PAGE_SIZE = 5;

interface PaginatedSentenceListProps {
  sentences: Sentence[];
}

export default function PaginatedSentenceList(props: PaginatedSentenceListProps): JSX.Element {
  const { sentences } = props;
  const [page, setPage] = useState(1);

  const start = (page - 1) * SENTIMENT_SENTENCES_PAGE_SIZE;
  const end = page * SENTIMENT_SENTENCES_PAGE_SIZE;
  const sentencesSlice = sentences.slice(start, end);

  return (
    <>
      <SentenceList sentences={sentencesSlice} />
      {sentences.length > 0 && (
        <AnalysePagination
          page={page}
          onChange={(event, value) => setPage(value)}
          count={Math.ceil(sentences.length / SENTIMENT_SENTENCES_PAGE_SIZE)}
        />
      )}
    </>
  );
}
