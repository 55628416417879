import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FilterObject,
  FilterOption,
  FilterResponse,
  FilterType,
  getEntityTypeFromFilterType,
  getLabelFromFilterType,
} from "../../../models/filter";
import { clearFilters, replaceQuery } from "./actions";
import { getParsedQueryParams, getQueryParamFieldNameByFilterType } from "./url-functions";

interface FilterState {
  initialFilters: FilterObject[];
  loading: boolean;
  error: boolean;
  open: boolean;
  drawerOpen: boolean;
}

const initialState: FilterState = {
  initialFilters: [],
  loading: true,
  error: false,
  open: false,
  drawerOpen: false,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    onFiltersChange(state: FilterState, action: PayloadAction<FilterResponse>) {
      const filters = action.payload;
      const parsedParams = getParsedQueryParams();
      const filterArr = Object.keys(filters).map<FilterObject>((key: string) => {
        const filterType = key as FilterType;
        const object = filters[filterType];
        const fieldName = getQueryParamFieldNameByFilterType(filterType);
        return {
          label: getLabelFromFilterType(filterType),
          type: filterType,
          options: (object || []).map<FilterOption>((x) => ({
            entity: {
              id: x.id || x.name,
              name: x.name,
              type: getEntityTypeFromFilterType(filterType),
            },
            selected: !!parsedParams[fieldName]?.find(({ name }) => name === x.name),
            count: x.count,
          })),
        };
      });

      filterArr.push({
        label: "Keywords",
        options: [],
        type: FilterType.Keywords,
      });
      state.initialFilters = filterArr;
      state.loading = false;
    },
    onFilterLoading(state: FilterState) {
      state.loading = true;
    },
    onSetModalOpen(state: FilterState, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
    toggleDrawerOpen(state: FilterState, action: PayloadAction<boolean>) {
      state.drawerOpen = action.payload;
    },
  },
  extraReducers: {
    [clearFilters.type]: function (state: FilterState) {
      state.initialFilters = [];
    },
    [replaceQuery.type]: function (state: FilterState) {
      state.initialFilters = [];
    },
  },
});

export const { onFilterLoading, onFiltersChange, onSetModalOpen, toggleDrawerOpen } = filterSlice.actions;

export default filterSlice.reducer;
