import { CheckCircleOutline as CheckIcon } from "@material-ui/icons";
import { Validation } from "./Validation";
import React from "react";
import { friendlyNumber } from "../../../../../helpers/friendlyNumber";

interface QueryActionableProps {
  count: number;
  max?: number;
}

export const QueryActionable = ({ count, max }: QueryActionableProps): JSX.Element => (
  <Validation
    icon={<CheckIcon style={{ color: max !== undefined && count > max ? "brown" : "green" }} />}
    messages={
      max !== undefined && count > max
        ? [`We will harvest the first ${friendlyNumber(max)} documents from this query.`]
        : undefined
    }
    title={`Total estimated documents - ${count.toLocaleString()}`}
  />
);
