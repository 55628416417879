import { Route } from "react-router-dom";
import { TabId } from "../../../hooks/useFeedSettingTabs";
import { defaultRoutes } from "./components/Outlet";

export interface UrlParams {
  amplyfiEntityId?: string;
  resourceId?: string;
  libraryId?: string;
  tab?: string;
}

export const goToFeed = () => "/feed";
export const goToDashboard = (libraryId: string | null, amplyfiEntityId: string | null, tab?: string) => {
  const librarySegment = libraryId ? `/library/${libraryId}` : "";
  const dashboardSegment = amplyfiEntityId ? `/interest/${amplyfiEntityId}` : "";
  const tabSegment = tab ? `/tab/${tab}` : "/tab/signals";
  return `${goToFeed()}${librarySegment}${dashboardSegment}${tabSegment}`;
};
export const goToReport = (libraryId: string | null, amplyfiEntityId: string | null, reportId: string, tab?: string) =>
  `${goToDashboard(libraryId, amplyfiEntityId, "reports")}/${reportId}`;
export const goToLibrarySettingsTab = (libraryId: string, tabId: TabId) =>
  `${goToFeed()}/library/${libraryId}/settings/${tabId}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RerouteToLiveFeed(props: any) {
  window.location.replace(`https://app.amplyfi.com${props.location.pathname}${props.location.search}`);
  return <div></div>
}

// Advice is to use the same key for all similar routes to stop unnecessary re-renders
// https://stackoverflow.com/questions/59369709/how-to-pass-key-to-react-route
const Routes: JSX.Element[] = [
  ...defaultRoutes().map((route) => <Route key="/feed" path={route} component={RerouteToLiveFeed} />),
  <Route key="research" path="/generative-research" component={RerouteToLiveFeed} />,
];

export default Routes;
