import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { useDispatch } from "react-redux";
import useDocumentLibraries from "../../../hooks/useDocumentLibraries";
import { QueryType } from "../../../models/query";
import { useAnalyseSelector } from "../../../store/reducers";
import { getQueryParamFieldNameByType } from "../../../store/reducers/searchRequest/url-functions";
import { addQuery, removeQuery } from "../../../store/reducers/searchRequest/urlReducer";
import EntityAutocomplete from "../Entity/EntityAutocomplete";
import EntityList from "./EntityList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: theme.spacing(4),
      background: theme.palette.componentBackground.main,
      width: "100%",
    },
    autocomplete: {
      margin: 0,
    },
    label: {
      color: theme.palette.links.primary,
      fontWeight: "bold",
      fontSize: theme.typography.h4.fontSize,
      marginBottom: theme.spacing(),
      display: "block",
    },
    autocompleteInput: {
      background: theme.palette.accent.main,
    },
    autocompleteInputBase: {
      "& > *::placeholder": {
        opacity: 0.75,
      },
    },
  })
);

interface StandardSearchField {
  label: string;
  type: QueryType;
  dataTestId: string;
}

const fields: StandardSearchField[] = [
  {
    label: "All these words",
    type: QueryType.AllQueries,
    dataTestId: "field_allWords",
  },
  {
    label: "Any of these words or phrases",
    type: QueryType.AnyQueries,
    dataTestId: "field_anyWords",
  },
  {
    label: "None of these words",
    type: QueryType.NoneQueries,
    dataTestId: "field_noneWords",
  },
];

const typeJoinerMap: Record<QueryType, "and" | "or"> = {
  allQueries: "and",
  anyQueries: "or",
  noneQueries: "and",
};

export default function StandardSearch(): JSX.Element {
  const classes = useStyles();
  const onFormSubmit = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    return;
  };

  const dispatch = useDispatch();
  const urlState = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { isLoading } = useDocumentLibraries();
  return (
    <form onSubmit={onFormSubmit} className={classes.form}>
      {fields.map((field) => {
        const fieldName = getQueryParamFieldNameByType(field.type);
        return (
          <div data-testid={`container-${field.dataTestId}`} key={field.type}>
            <label className={classes.label}>{field.label}</label>
            <EntityAutocomplete
              placeholder="Add keyword..."
              onAdd={(entity) => {
                dispatch(addQuery({ entity, type: field.type }));
              }}
              inputClasses={classes.autocompleteInput}
              inputBaseClasses={classes.autocompleteInputBase}
              endAdornment={null}
              dataTestId={field.dataTestId}
              disabled={isLoading}
            />
            <div style={{ marginTop: 16 }}>
              <EntityList
                entities={urlState[fieldName]}
                onRemove={(index) => {
                  dispatch(removeQuery({ type: field.type, index }));
                }}
                joinedBy={typeJoinerMap[field.type]}
              />
            </div>
          </div>
        );
      })}
    </form>
  );
}
