import moment from "moment";
import React, { useState } from "react";
import { cardDetails } from "../../helpers/cardDetailsHelper";
import { dateGranularity } from "../../helpers/dateHelpers";
import { useAnalyseSelector } from "../../store/reducers";
import { DEFAULT_END } from "../../store/reducers/searchRequest/url-functions";
import VisualisationSettingsContent from "../Widgets/VisualisationSettingsContent";
import AnalysisComponent from "@amplyfi/ui-components/components/AnalysisComponent";
import ExportModalContent from "@amplyfi/ui-components/components/ExportContent";
import FeedbackModal from "../Widgets/Feedback/FeedbackModal";
import { testId } from "@amplyfi/ui-components/testHelpers";
import useDocumentTimeline from "../../hooks/useDocumentTimeline";
import { getExportV2 } from "../../helpers/exportDataHelper";
import { useAnalysisComponentState } from "../../hooks/useAnalysisComponentState";
import TimelineDialog from "../Widgets/TimelineDialog";
import DocumentTimeline from "../Charts/Nivo/DocumentTimeline";
import { DOCUMENT_TIMELINE_HEIGHT } from "../../helpers/nivo";
import { useTimelineFilterLabel } from "../../hooks/useTimelineFilterLabel";
import { FullScreenHandle } from "react-full-screen";

export default function DocumentTimelineCard(): JSX.Element {
  const {
    lowestInterval,
    interval,
    parsed: { startDate: globalStartDate, endDate: globalEndDate, includeUndatedDocuments = true },
  } = useAnalyseSelector((x) => x.searchRequest.url);
  const { smoothing, skewCorrection } = useAnalyseSelector((x) => x.searchResponse.documentTimeline);

  const startDate = globalStartDate ? dateGranularity(moment.utc(globalStartDate)) : undefined;
  const endDate = globalEndDate ? moment.utc(globalEndDate) : DEFAULT_END;

  const timelineFilterLabel = useTimelineFilterLabel();
  const [showModal, setShowModal] = useState(false);
  const [fullScreenHandle, setFullScreenHandle] = useState<FullScreenHandle | undefined>(undefined);

  const { documentsTimeline, isError, isLoading } = useDocumentTimeline({
    endDate,
    includeUndatedDocuments,
    startDate,
    interval,
  });

  const analysisState = useAnalysisComponentState(
    isError,
    isLoading,
    !!documentsTimeline && documentsTimeline.timeline.length === 0,
    setShowModal
  );

  const onFullScreenChange = (state: boolean, handle: FullScreenHandle) => {
    if (state === handle.active) {
      setFullScreenHandle(handle);
    }
  };

  return (
    <>
      <AnalysisComponent
        id="document-timeline"
        data-testid={testId("document-timeline")}
        infoTooltipContent={cardDetails.documentsOverTime.readMore}
        onFeedbackClick={() => setShowModal(true)}
        title={cardDetails.documentsOverTime.title}
        vizualisationContent={
          <VisualisationSettingsContent
            showSkewCorrection
            data-testid="document-timeline"
            lowestInterval={lowestInterval}
            timeInterval={interval}
          />
        }
        exportContent={
          <ExportModalContent
            data-testid={testId("document-timeline-export")}
            componentName={cardDetails.documentsOverTime.title}
            handleClose={() => void 0}
            handleExport={({ fileName }: { fileName: string }) => {
              getExportV2(fileName, "timeline", {
                systemFilters: {
                  dateInterval: interval,
                },
              });
            }}
          />
        }
        timeContent={<TimelineDialog includeUndatedDocuments={includeUndatedDocuments} />}
        analysisState={analysisState?.state}
        onPrimaryClick={analysisState?.primaryAction}
        onSecondaryClick={analysisState?.secondaryAction}
        timelineFilterLabel={timelineFilterLabel}
        onFullScreenChange={onFullScreenChange}
      >
        <div>
          <DocumentTimeline
            documentsTimeline={documentsTimeline}
            interval={interval}
            height={DOCUMENT_TIMELINE_HEIGHT}
            smoothing={smoothing}
            skewCorrection={skewCorrection}
            fullScreenHandle={fullScreenHandle}
          />
          <FeedbackModal
            open={showModal}
            onClose={(_e, _reason) => setShowModal(false)}
            showCloseIcon={false}
            componentName={cardDetails.documentsOverTime.title}
          />
        </div>
      </AnalysisComponent>
    </>
  );
}
