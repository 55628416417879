import React from "react";
import SimpleDialog from "../../../Widgets/Dialog/SimpleDialog";
interface LoadMoreDialogProps {
  onCancel: () => void;
  onConfirm: () => void;
}
export default function LoadMoreDialog(props: LoadMoreDialogProps): JSX.Element {
  const { onCancel, onConfirm } = props;
  return (
    <SimpleDialog
      title="Load More Documents"
      content="Loading more documents will lead to re-ordering of documents according to relevance and resetting of filters. Are you sure you want to load more documents?"
      onClose={onCancel}
      onConfirm={onConfirm}
      showHideForever
      storageId="loadMoreDialogSaved"
    />
  );
}
