import { Grid } from "@material-ui/core";
import ComparisonCard from "../../Cards/ComparisonCard";
import ReportTemplate from "../../Widgets/ReportTemplate";

export default function ComparisonReport(): JSX.Element {
  return (
    <ReportTemplate
      title="Comparison Report"
      description="A comparison of the documents returned by your search."
      readMore="Explore the differences between entities in your libraries."
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ComparisonCard />
        </Grid>
      </Grid>
    </ReportTemplate>
  );
}
