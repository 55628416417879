import { Button, ButtonProps, Fade, useTheme } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { useRef, useState } from "react";
import useParentWidth from "../../../../../hooks/queries/util/useParentWidth";

interface InformativeButtonProps extends ButtonProps {
  hideTextBelowWidth?: number | Breakpoint;
  informativeText?: string;
}

const TRANSITION_DURATION = 600;

export default function InformativeButton({
  hideTextBelowWidth = 0,
  informativeText,
  ...props
}: InformativeButtonProps) {
  const [clicked, setClicked] = useState(false);
  const [clickedTimeout, setClickedTimeout] = useState<NodeJS.Timeout | null>(null);
  const buttonRef = useRef(null);
  const { parentWidth } = useParentWidth(buttonRef);

  const theme = useTheme();
  const width = theme.breakpoints.width(hideTextBelowWidth as unknown as Breakpoint) || (hideTextBelowWidth as number);

  return (
    <Button
      {...props}
      ref={buttonRef}
      onClick={(e) => {
        props.onClick && props.onClick(e);
        if (informativeText) {
          setClicked(true);
          if (clickedTimeout) {
            clearTimeout(clickedTimeout);
          }
          setClickedTimeout(setTimeout(() => setClicked(false), 3000));
        }
      }}
    >
      {clicked && parentWidth > width && (
        <Fade timeout={TRANSITION_DURATION} in={clicked}>
          <span>{informativeText}</span>
        </Fade>
      )}
      {!clicked && parentWidth > width && (
        <Fade timeout={TRANSITION_DURATION} in={!clicked}>
          <span>{props.children}</span>
        </Fade>
      )}
    </Button>
  );
}
