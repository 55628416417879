import React from "react";
import { Button } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

interface Crumb {
  id: string;
  text: string;
  count?: number;
}

interface BreadcrumbProps {
  crumbs: Crumb[];
  onCrumbClicked: (crumb: Crumb, index: number) => void;
}

export default function Breadcrumb(props: BreadcrumbProps): JSX.Element {
  const { onCrumbClicked, crumbs } = props;
  return (
    <div>
      {crumbs.map((crumb, i) => (
        <div key={`${crumb.text}-${i}`} style={{ display: "inline-flex", alignItems: "center" }}>
          <Button color={i < crumbs.length - 1 ? "secondary" : "primary"} onClick={() => onCrumbClicked(crumb, i)}>
            {crumb.text} {crumb.count === undefined ? "" : `(${crumb.count})`}
          </Button>
          {i < crumbs.length - 1 && <ChevronRight />}
        </div>
      ))}
    </div>
  );
}
