import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { QueryType } from "../../../models/query";
import EntityAutocomplete from "../Entity/EntityAutocomplete";
import { useAnalyseSelector } from "../../../store/reducers";
import { GetMetionedFilters } from "../../Navigation/SelectedFiltersHeader";
import { getStandardSearchEntity, getStandardSearchEntityByType } from "../../../helpers/getIndividualSelectedFilters";
import { DOCUMENT_FILTER_CONTAINER__WIDTH } from "./FilterDrawer";
import { Entity } from "../../../models/entity";
import { FilterType } from "../../../models/filter";
import { useDispatch } from "react-redux";
import Button from "@amplyfi/ui-components/components/Button";
import { link } from "../../../css/mixins";
import { addMultipleQueries, toggleWithinSentence } from "../../../store/reducers/searchRequest/urlReducer";
import DocCountLabel from "./DocCountLabel";
import { getQueryParamFieldNameByType } from "../../../store/reducers/searchRequest/url-functions";
import { ParsedQueryParams } from "../../../models/queryParams";
import ConnectionStrength from "./ConnectionStrength";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.componentBackground.main,
      marginTop: theme.spacing(4),
      display: "flex",
    },
    inputSection: {
      width: "50%",
      borderRight: `1px solid ${theme.palette.borders.contrastText}`,
      paddingRight: theme.spacing(4),
    },
    activeFilterSection: {
      width: "50%",
    },
    activeFilterHeader: {
      display: "flex",
      justifyContent: "space-between",
    },
    handerlabel: {
      color: "#748998",
      marginBottom: theme.spacing(1.5),
      textTransform: "capitalize",
    },
    autocomplete: {
      margin: 0,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.typography.h4.fontSize,
      marginBottom: theme.spacing(),
      display: "block",
    },
    autocompleteInputBase: {
      borderRadius: 0,
      backgroundColor: theme.palette.componentBackground.main,

      "& > *::placeholder": {
        opacity: 0.75,
      },
    },
    activeFilters: {
      margin: theme.spacing(1.5),
      overflowY: "auto",
      overflowX: "hidden",
      width: DOCUMENT_FILTER_CONTAINER__WIDTH,
      height: 300,
    },
    activeFilterLabel: {
      paddingLeft: theme.spacing(1.5),
    },
    input: {
      marginBottom: theme.spacing(4),
    },
    connectionStrength: {
      borderTop: `1px solid ${theme.palette.borders.contrastText}`,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    footer: {
      borderTop: `1px solid ${theme.palette.borders.contrastText}`,
      textAlign: "end",
      display: "flex",
      alignItems: "baseline",
      justifyContent: "right",
    },
    button: {
      margin: `${theme.spacing(2)}px 0 0 ${theme.spacing(2)}px`,
    },
    link: {
      ...link(theme),
    },
  })
);

interface StandardSearchField {
  label: string;
  type: QueryType;
  dataTestId: string;
}

const fields: StandardSearchField[] = [
  {
    label: "All of these (AND)",
    type: QueryType.AllQueries,
    dataTestId: "field_allWords",
  },
  {
    label: "Any of these (OR)",
    type: QueryType.AnyQueries,
    dataTestId: "field_anyWords",
  },
  {
    label: "None of these (NOT)",
    type: QueryType.NoneQueries,
    dataTestId: "field_noneWords",
  },
];

interface SelectMentionedElementsProps {
  onApply: () => void;
}

export default function SelectMentionedElements(props: SelectMentionedElementsProps): JSX.Element {
  const { onApply } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const urlState = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { withinSentence } = urlState;
  const [connectionStrength, setConnectionStrength] = useState<boolean>(withinSentence);

  const getKeywordFilter = (entities: Entity[]): Record<FilterType.Keywords, Entity[]> => {
    return {
      [FilterType.Keywords]: entities,
    };
  };

  const [selected, setSelected] = useState<Record<string, Entity[]>>(getKeywordFilter([]));

  useEffect(() => {
    setSelected(
      getKeywordFilter([
        ...getStandardSearchEntity(urlState.all, QueryType.AllQueries),
        ...getStandardSearchEntity(urlState.any, QueryType.AnyQueries),
        ...getStandardSearchEntity(urlState.none, QueryType.NoneQueries),
      ])
    );
  }, [urlState.all, urlState.any, urlState.none]);

  const onFormSubmit = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    return;
  };

  const handleClose = (entity: Entity, type: FilterType) => {
    setSelected(getKeywordFilter(selected[FilterType.Keywords].filter((e) => e.id !== entity.id)));
  };

  const getSearchInputs = (): JSX.Element => {
    return (
      <form onSubmit={onFormSubmit}>
        {fields.map((field) => {
          return (
            <div className={classes.input} key={field.type}>
              <label className={classes.label}>{field.label}</label>
              <EntityAutocomplete
                placeholder="Add Element"
                onAdd={(entity) => {
                  const existingEntity = selected[FilterType.Keywords].some((x) => x.id === entity.id);

                  if (!existingEntity) {
                    setSelected(
                      getKeywordFilter([
                        ...selected[FilterType.Keywords],
                        {
                          ...entity,
                          queryType: field.type,
                        },
                      ])
                    );
                  }
                }}
                inputBaseClasses={classes.autocompleteInputBase}
                endAdornment={null}
                dataTestId={field.dataTestId}
              />
            </div>
          );
        })}
      </form>
    );
  };

  const getEntitiesByURL = (urlState: ParsedQueryParams, entities: Entity[]): ParsedQueryParams => {
    const queries = getStandardSearchEntityByType(entities);
    urlState[getQueryParamFieldNameByType(QueryType.AllQueries)] = queries[QueryType.AllQueries];
    urlState[getQueryParamFieldNameByType(QueryType.AnyQueries)] = queries[QueryType.AnyQueries];
    urlState[getQueryParamFieldNameByType(QueryType.NoneQueries)] = queries[QueryType.NoneQueries];
    return urlState;
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.inputSection}>
          <Typography className={classes.handerlabel} variant="h6">
            AUTOSUGGESTED ELEMENTS
          </Typography>
          {getSearchInputs()}
        </div>
        <div className={classes.activeFilterSection}>
          <div className={classes.activeFilterHeader}>
            <Typography className={clsx(classes.handerlabel, classes.activeFilterLabel)} variant="h6">
              ACTIVE FILTERS
            </Typography>
            <Typography variant="h6" className={classes.link} onClick={() => setSelected(getKeywordFilter([]))}>
              Clear
            </Typography>
          </div>
          <div className={classes.activeFilters}>
            <GetMetionedFilters
              data={getStandardSearchEntityByType(selected[FilterType.Keywords])}
              label={"Element"}
              handleClose={handleClose}
            />
          </div>
        </div>
      </div>
      <div className={classes.connectionStrength}>
        <ConnectionStrength defaultValue={connectionStrength} onChange={setConnectionStrength} />
      </div>
      <div className={classes.footer}>
        <DocCountLabel
          urlState={getEntitiesByURL(
            { ...urlState, withinSentence: connectionStrength },
            selected[FilterType.Keywords]
          )}
        />
        <Button
          className={classes.button}
          onClick={() => {
            dispatch(addMultipleQueries({ entities: selected[FilterType.Keywords] }));
            dispatch(toggleWithinSentence(connectionStrength));
            onApply();
          }}
          type="button"
        >
          Apply Filters
        </Button>
      </div>
    </>
  );
}
