import { AutocompleteResponse, getAutocompleteResults } from "../http/autocomplete";
import { AutocompleteType } from "../models/autocomplete";
import { useQuery, UseQueryResult } from "./useQuery";

export default function useEntitySuggestions(
  text: string | undefined,
  type: AutocompleteType = AutocompleteType.Organisation
): UseQueryResult<AutocompleteResponse[] | null> {
  return useQuery(["entity-suggestion", text, type], () => (text ? getAutocompleteResults(text, type) : null));
}
