import { DocumentDetailedItem } from "../../../models/document";
import { MonitoringDocument } from "../../../models/monitoring";
import { FeatureHandler } from "./copyHelper";
import HighlightsTab from "./Tab/HighlightsTab";
import QuotesTab from "./Tab/QuotesTab";
import StatisticsTab from "./Tab/StatisticsTab";
import SummaryTab from "./Tab/SummaryTab";

export interface AbstractTabProps {
  document?: MonitoringDocument | DocumentDetailedItem;
  documentId?: string;
  documentMeta: FeatureHandler;
  onLoadCallback?: (count: number) => void;
}

export interface DocumentSummaryTab {
  copyMultipleFeature: (feature: string[]) => string[];
  component: (props: AbstractTabProps) => JSX.Element | null;
  id: string;
  isBeta?: boolean;
  name: string;
  tooltip: string;
}

export const tabs: DocumentSummaryTab[] = [
  {
    copyMultipleFeature: (highlights) => ["Highlights", ...highlights.map((d) => `* ${d}`)],
    component: HighlightsTab,
    id: "highlights",
    name: "Highlights",
    tooltip: "Most representative sentences extracted",
  },
  {
    copyMultipleFeature: (quotes) => ["Quotes", ...quotes.map((d) => `* ${d}`)],
    component: QuotesTab,
    id: "quotes",
    isBeta: false,
    name: "Quotes",
    tooltip: "Sentences with attributable quotes",
  },
  {
    copyMultipleFeature: (stats) => ["Statistics", ...stats.map((d) => `* ${d}`)],
    component: StatisticsTab,
    id: "statistics",
    isBeta: true,
    name: "Statistics",
    tooltip: "Sentences with stats or numbers",
  },
  {
    copyMultipleFeature: (summary) => ["Summary", ...summary.map((d) => `* ${d}`)],
    component: SummaryTab,
    id: "summary",
    isBeta: false,
    name: "Summary",
    tooltip: "Summary",
  },
];
