import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Entity } from "../../../models/entity";
import { resetState } from "../searchRequest/actions";

export interface CompanyProfileState {
  selectedSentenceFilters: string[];
  sentenceEntityFilters: Entity[];
}

const initialState: CompanyProfileState = {
  selectedSentenceFilters: [],
  sentenceEntityFilters: [],
};

const companyProfileSlice = createSlice({
  name: "companyProfile",
  initialState,
  reducers: {
    onAddRiskSentencesFilter(state: CompanyProfileState, { payload }: PayloadAction<Entity>) {
      state.sentenceEntityFilters.push(payload);
    },
    onRemoveRiskSentencesFilter(state: CompanyProfileState, { payload: filterIndex }: PayloadAction<number>) {
      if (filterIndex > -1) {
        state.sentenceEntityFilters.splice(filterIndex, 1);
      }
    },
    onChangeRiskSentencesFilter(state: CompanyProfileState, { payload }: PayloadAction<string>) {
      if (state.selectedSentenceFilters.includes(payload)) {
        state.selectedSentenceFilters = state.selectedSentenceFilters.filter((x) => x !== payload);
      } else {
        state.selectedSentenceFilters.push(payload);
      }
    },
    resetCompanyRisk(state: CompanyProfileState) {
      state.sentenceEntityFilters = [];
      state.selectedSentenceFilters = [];
    },
  },
  extraReducers: {
    [resetState.type]: function () {
      return initialState;
    },
  },
});

export const {
  onAddRiskSentencesFilter,
  onRemoveRiskSentencesFilter,
  onChangeRiskSentencesFilter,
  resetCompanyRisk,
} = companyProfileSlice.actions;

export default companyProfileSlice.reducer;
