import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Button, List, Typography, makeStyles } from "@material-ui/core";
import { Add, Close, Language, RssFeed } from "@material-ui/icons";
import { useState } from "react";
import { distinct } from "../../../../../helpers/arrayHelpers";
import useCreateContentSource from "../../../../../hooks/mutations/feed/useCreateContentSource";
import useValidation from "../../../../../hooks/useValidation";
import { useCreateFeedStore } from "../../../../../store/hooks/useCreateFeedStore";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import EditableListItem from "../../components/EditableListItem";
import Stack from "../../components/stack/Stack";
import CircleHighlight from "../Components/CircleHighlight";
import { BasicEmptyState } from "../Components/DynamicComponent";
import TextEntryField from "../Components/TextEntryField";
import AddSourcesPanel from "./AddSourcesPanel";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        display: "grid",
        gridTemplateRows: "max-content",
        gridTemplateColumns: "1fr",
    }
}))

interface ContentSource {
    id: string;
    name: string;
    icon: JSX.Element;
    value?: string;
}

const contentTypes = [
    {
        id: "website",
        name: "Website",
        icon: <Language color="secondary" />,
    },
    {
        id: "RSS",
        name: "RSS",
        icon: <RssFeed color="secondary" />
    }
]

interface ContentTypeSelectorProps {
    onCancel: () => void;
    onAdd: (content: ContentSource) => void;
}

function ContentTypeSelector(props: ContentTypeSelectorProps) {
    const [selected, setSelected] = useState("");
    const [value, setValue] = useState("");
    const { valid } = useValidation(["minLength", "websiteOptionalProtocol"], [], [value]);

    function handleCancel() {
        setSelected("")
        props.onCancel();
    }

    function handleConfirm() {
        props.onAdd({ ...(contentTypes.find(c => c.id === selected) as ContentSource), value });
    }

    return <Stack style={{ width: "100%" }} spacing={2}>
        <Typography variant="h4">Content type</Typography>
        <Stack alignItems="center" spacing={1} direction="horizontal">
            {contentTypes.map(c =>
                <Stack
                    onClick={() => setSelected(c.id)}
                    style={{ cursor: "pointer" }}
                    padding={6}
                    alignItems="center" justifyContent="center" border={`1px solid ${selected === c.id ? "blue" : "#c3c3c3"}`} flex="1 1 auto"
                    spacing={1} key={c.id} textAlign="center">
                    {c.icon}
                    <Typography variant="body2">{c.name}</Typography>
                </Stack>)
            }
        </Stack>
        {selected && <>
            <TextEntryField value={value} onChange={(e) => setValue(e.target.value)} label={`Add ${selected}`} fullWidth />
            <Stack marginTop="auto" spacing={2} direction="horizontal">
                <Button
                    disabled={!valid} style={{ height: 40 }}
                    color="secondary" onClick={handleConfirm} variant="outlined">Add</Button>
                <Button style={{ height: 40 }} color="secondary" onClick={handleCancel}>Cancel</Button>
            </Stack> </>}
    </Stack>
}

export default function CreateSourcePanel() {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const { toggleSource } = useCreateFeedStore();
    const [localSources, setLocalSources] = useState<ContentSource[]>([]);
    const { setCustomPanel } = useReaderStore();
    const [addingContent, toggleAddingContent] = useState(false);
    const { valid } = useValidation(["minLength"], [], [name]);
    const { valid: descriptionValid } = useValidation(["minLength"], [], [description]);
    const { mutateAsync: createContentSource, isLoading } = useCreateContentSource();

    function handleCancelContentType() {
        toggleAddingContent(false);
    }

    function handleCancel() {
        setCustomPanel(<AddSourcesPanel />);
    }

    async function handleConfirm() {
        const value = await createContentSource({
            name,
            description,
            siteHarvestWebsites: localSources.filter(s => s.id === "website").map(s => s.value as string),
            rssUrls: localSources.filter(s => s.id === "RSS").map(s => s.value as string)
        });

        toggleSource({
            id: value.collectionConfigId as string,
            name: value.name,
            description: value.description as string,
        });

        setCustomPanel(<AddSourcesPanel />);
    }

    function handleAdd(content: ContentSource) {
        setLocalSources(distinct([...localSources, content], ["value"]));
        toggleAddingContent(false);
    }

    return <Stack className={classes.root} spacing={2}>
        <Stack spacing={2}>
            <Typography variant="h2">Create Content Source</Typography>
            <Stack spacing={2}>
                <TextEntryField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    fullWidth
                />

                <TextEntryField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                />
            </Stack>
            <Stack spacing={2}>
                <Stack direction="horizontal" spacing={2} style={{ justifyContent: "space-between", flex: "0 0 100%" }}>
                    <Typography variant="h4">Content Sources</Typography>
                    <Button
                        color="secondary"
                        startIcon={<Add />}
                        onClick={() => toggleAddingContent(!addingContent)}>Content source</Button>
                </Stack>
                <List disablePadding style={{ flex: "0 0 100%" }}>
                    {localSources.length === 0 && <BasicEmptyState title="No content sources added" />}
                    {localSources.map(s =>
                        <EditableListItem
                            selected
                            onClick={() => setLocalSources(localSources.filter(ls => ls.value !== s.value))}
                            title={s.value as string}
                            description={s.name}
                            image={s.icon}
                            actions={[
                                <CircleHighlight inverted icon>
                                    <Close fontSize="small" />
                                </CircleHighlight>
                            ]}
                        />
                    )}
                </List>
                {addingContent && <ContentTypeSelector onCancel={handleCancelContentType} onAdd={handleAdd} />}
            </Stack>
        </Stack>
        <Stack marginTop="auto" spacing={2} direction="horizontal">
            <Button style={{ height: 40, width: 150, marginLeft: "auto" }} color="secondary" onClick={handleCancel} variant="outlined">Cancel</Button>
            <Button
                disabled={!valid || !descriptionValid || isLoading || localSources.length === 0}
                startIcon={isLoading && <AiCircularProgress size={14} />}
                style={{ color: "white", height: 40, width: 150 }}
                color="secondary" onClick={handleConfirm}
                variant="contained">Confirm</Button>
        </Stack>
    </Stack>
}