import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { link } from "../../css/mixins";
import AnalyseTooltip from "@amplyfi/ui-components/components/Tooltip";
import ViewMoreButton from "../Widgets/ViewMoreButton";
import { ClickAwayListener, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Silver } from "@amplyfi/ui-components/theme/colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      padding: 25,
      justifyContent: "space-between",
      alignItems: "center",
    },
    titleContainer: {
      borderRight: `1px solid ${theme.palette.borders.main}`,
      paddingRight: theme.spacing(3),
      flexShrink: 0,
      [theme.breakpoints.down("md")]: {
        flexShrink: 1,
      },
    },
    description: {
      display: "flex",
      marginLeft: theme.spacing(3),
      alignItems: "center",
      color: Silver,
      overflow: "hidden",
    },
    info: {
      ...link(theme),
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(3),
      whiteSpace: "nowrap",
    },
    componentRight: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(1.5),
    },
    rhs: {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        flexShrink: 1,
      },
    },
    tooltip: {
      maxHeight: 450,
      overflow: "auto",
    },
  })
);

interface CardProps {
  titleText: JSX.Element;
  titleDescription?: string;
  viewMoreContent?: JSX.Element;
  info?: string | JSX.Element;
  rhsContent?: string | JSX.Element;
}

export default function CardTitle(props: CardProps): JSX.Element {
  const { titleText, titleDescription, viewMoreContent, info, rhsContent } = props;
  const [openTooltip, setOpenTooltip] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={classes.titleContainer}>{titleText}</div>
        {!!titleDescription && (
          <div className={classes.description}>
            <Typography>{titleDescription}</Typography>
          </div>
        )}
        {info && (
          <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
            <div style={{ display: "flex" }}>
              <AnalyseTooltip
                title={<div className={classes.tooltip}>{info}</div>}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                interactive
                open={openTooltip}
                onClose={() => setOpenTooltip(false)}
              >
                <Typography variant="h6" className={classes.info} onClick={() => setOpenTooltip(true)}>
                  Info
                </Typography>
              </AnalyseTooltip>
            </div>
          </ClickAwayListener>
        )}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {rhsContent && <div className={classes.rhs}>{rhsContent}</div>}
        {viewMoreContent && (
          <div className={classes.componentRight}>
            <ViewMoreButton>{viewMoreContent}</ViewMoreButton>
          </div>
        )}
      </div>
    </div>
  );
}
