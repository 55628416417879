import { LinkBlueElectric, Melrose, PinkishPurple, Silver, SkyBlue } from "@amplyfi/ui-components/theme/colors";
import { EntityType } from "../../../../models/entity";

export const entityTypeMapColor = {
  [EntityType.Organisation]: PinkishPurple,
  [EntityType.Person]: Melrose,
  [EntityType.Country]: SkyBlue,
  [EntityType.Settlement]: SkyBlue,
  [EntityType.KeyPhrase]: LinkBlueElectric,
  [EntityType.None]: Silver,
};

export const elementTypeMapColor = (elementType: EntityType): string => {
  return entityTypeMapColor[elementType];
};
