import { SearchResult } from "../http/connectors";

export function hasNewResults(searchResult: SearchResult): boolean {
  return Object.keys(searchResult?.connectorCompletions || {}).some((val) => {
    return searchResult?.connectorCompletions[val] === false;
  });
}

export function areConnectorsLoading(searchResult?: SearchResult): boolean {
  if (!searchResult) {
    return false;
  }
  return Object.keys(searchResult.connectorStatuses || {}).some(
    (val) => searchResult?.connectorStatuses[val] === "in-progress"
  );
}
