import { testId } from "@amplyfi/ui-components/testHelpers";
import { Silver } from "@amplyfi/ui-components/theme/colors";
import { Backdrop, Theme, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { drawerBreakPointStyling } from "../../../css/mixins";
import {
  getIndividualSelectedFilters,
  getIndividualSelectedFiltersCount,
  getStandardSearchEntityByType,
} from "../../../helpers/getIndividualSelectedFilters";
import { convertSearchStateToSearchRequestV2 } from "../../../helpers/search";
import { searchFilters } from "../../../http/general";
import { Entity } from "../../../models/entity";
import { FilterType } from "../../../models/filter";
import { QueryType } from "../../../models/query";
import { useAnalyseSelector } from "../../../store/reducers";
import { onFiltersChange, toggleDrawerOpen } from "../../../store/reducers/searchRequest/filterReducer";
import { removeQuery } from "../../../store/reducers/searchRequest/urlReducer";
import { closedPanelWidth } from "../../App";
import SelectedFiltersHeader, { GetMetionedFilters } from "../../Navigation/SelectedFiltersHeader";
import AdvancedQueryBuilder from "./AdvancedQueryBuilder";
import FilterButton from "./FilterButton";
import SelectDocumentFilters from "./SelectDocumentFilters";
import SelectLibraries from "./SelectLibraries";
import SelectMentionedElements from "./SelectMentionedElements";
import TimeRangeFilters from "./TimeRangeFilters";

export const DOCUMENT_FILTER_CONTAINER__WIDTH = 280;
const FILTER_VIWER_CONTAINER_WIDTH = 640;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterDrawer: {
      position: "fixed",
      zIndex: 1300,
      top: 0,
      bottom: 0,
      [theme.breakpoints.down("sm")]: {
        left: 0,
      },
      [theme.breakpoints.up("md")]: {
        left: closedPanelWidth,
      },
    },
    container: {
      display: "flex",
      height: "100%",
      position: "relative",
      overflow: "hidden",
    },
    sideBar: (props: { width?: number; isDrawerOpen?: boolean }) => ({
      marginLeft: props.width,
      border: `1px solid ${theme.palette.borders.contrastText}`,
      zIndex: props.isDrawerOpen ? 1800 : 1300,
      transition: "margin 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    }),
    indexContainer: {
      display: "flex",
      flexDirection: "column",
      width: DOCUMENT_FILTER_CONTAINER__WIDTH,
      backgroundColor: theme.palette.componentBackground.main,
      border: `1px solid ${theme.palette.borders.contrastText}`,
      padding: `${theme.spacing(1.5)}px 0px`,
    },
    filterViewerContainer: {
      minWidth: FILTER_VIWER_CONTAINER_WIDTH,
      backgroundColor: theme.palette.appBackground.main,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      overflow: "auto",
    },
    filterViwerContent: {
      padding: theme.spacing(5),
      backgroundColor: theme.palette.componentBackground.main,
      height: "100%",
    },
    filterHeader: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1.5),
      alignItems: "center",
    },
    closeIcon: {
      cursor: "pointer",
      color: Silver,
      fontSize: 22,
    },
    filterLabel: {
      color: "#CAD6E0",
      margin: theme.spacing(1.5),
    },
    drawer: (props: { width?: number; isDrawerOpen?: boolean }) => ({
      "& .Mui-paper": {
        ...drawerBreakPointStyling(theme),
      },
      "& .MuiDrawer-paperAnchorLeft": {
        left: closedPanelWidth,
      },
      "& .MuiBackdrop-root": {
        left: closedPanelWidth,
      },
    }),
    activeFilterSection: {
      backgroundColor: theme.palette.appBackground.main,
      marginTop: theme.spacing(2),
      height: "100%",
    },
    activeFilterLabel: {
      color: "#748998",
      textTransform: "capitalize",
    },
    activeFilters: {
      margin: theme.spacing(1.5),
      overflowY: "auto",
      overflowX: "hidden",
      width: DOCUMENT_FILTER_CONTAINER__WIDTH,
      height: "50vh",
      paddingRight: theme.spacing(2.25),
    },
    backdrop: {
      left: closedPanelWidth,
    },
  })
);

export interface FilterOptionsData {
  panelName: FilterOptions;
  title: string;
  discription: string;
  showCounts: boolean;
  header: string;
}

export enum FilterOptions {
  Libraries = "libraries",
  Mentions = "mentions",
  TimeRange = "timeRange",
  Sources = "sources",
  Locations = "locations",
  Sectors = "sectors",
  Advanced = "advanced",
}

const filterOptionsData: FilterOptionsData[] = [
  {
    panelName: FilterOptions.Libraries,
    title: "Libraries",
    discription: "Select the Document Libraries you would like to Analyse",
    showCounts: true,
    header: "Select Libraries",
  },
  {
    panelName: FilterOptions.Mentions,
    title: "Mentions",
    discription: "Filter your content based on Element mentioned within the content",
    showCounts: true,
    header: "Filter by Mentioned Elements",
  },
  {
    panelName: FilterOptions.TimeRange,
    title: "Time Range",
    discription: "Filter your content based on Published Dates within a Time Range",
    showCounts: false,
    header: "Filter by Time Range",
  },
  {
    panelName: FilterOptions.Sources,
    title: "Sources",
    discription: "Filter by the Publishing Source of the Documents",
    showCounts: true,
    header: "Filter by Document Source",
  },
  {
    panelName: FilterOptions.Locations,
    title: "Locations",
    discription: "Filter by the Publishing Location of the Documents",
    showCounts: true,
    header: "Filter by Published Location",
  },
  {
    panelName: FilterOptions.Sectors,
    title: "Sectors",
    discription: "Filter by the Sector Alignment of the Documents",
    showCounts: true,
    header: "Filter by Document Sector",
  },
  {
    panelName: FilterOptions.Advanced,
    title: "Advanced",
    discription: "Filter your content using code-based logic and syntax",
    showCounts: false,
    header: "Advanced Filter",
  },
];

export const queryTypeJoinerMap: Record<QueryType, "AND" | "OR" | "NOT"> = {
  allQueries: "AND",
  anyQueries: "OR",
  noneQueries: "NOT",
};

const getFilterContant = (option: FilterOptions, onClose: () => void): JSX.Element | undefined => {
  switch (option) {
    case FilterOptions.Libraries:
      return <SelectLibraries onApply={onClose} />;

    case FilterOptions.Mentions:
      return <SelectMentionedElements onApply={onClose} />;

    case FilterOptions.TimeRange:
      return <TimeRangeFilters onApply={onClose} />;

    case FilterOptions.Sources:
      return <SelectDocumentFilters filterType={"sources" as FilterType} onApply={onClose} />;

    case FilterOptions.Locations:
      return <SelectDocumentFilters filterType={"locations" as FilterType} onApply={onClose} />;

    case FilterOptions.Sectors:
      return <SelectDocumentFilters filterType={"sectors" as FilterType} onApply={onClose} />;

    case FilterOptions.Advanced:
      return <AdvancedQueryBuilder onApply={onClose} />;
  }
};

export default function FilterDrawer(): JSX.Element {
  const dispatch = useDispatch();
  const drawerOpen = useAnalyseSelector((x) => x.searchRequest.filter.drawerOpen);
  const urlState = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const { libraryIds } = urlState;
  const globalData = getIndividualSelectedFilters(urlState, FilterOptions.Mentions);
  const [activeFilterOption, setActiveFilterOption] = React.useState<FilterOptionsData | undefined>();
  const dataTestId = "filter-drawer";

  useEffect(() => {
    if (!libraryIds.length && drawerOpen) {
      setActiveFilterOption(filterOptionsData[0]);
    }
  }, [libraryIds, setActiveFilterOption, drawerOpen, dispatch]);

  useEffect(() => {
    async function getFilters() {
      const request = convertSearchStateToSearchRequestV2(urlState);
      const response = await searchFilters(request, urlState.libraries);
      dispatch(onFiltersChange(response));
    }

    if (libraryIds.length > 0) {
      getFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryIds]);

  const getFilterContainerWidth = (): number => {
    let width = closedPanelWidth;
    if (drawerOpen) {
      width += DOCUMENT_FILTER_CONTAINER__WIDTH;
      if (activeFilterOption) {
        width += FILTER_VIWER_CONTAINER_WIDTH;
      }
    }
    return width;
  };
  const classes = useStyles({
    width: getFilterContainerWidth(),
    isDrawerOpen: drawerOpen,
  });

  const handleClose = (entity: Entity, type: FilterType, itemIndex?: number) => {
    dispatch(removeQuery({ type: entity.queryType || QueryType.AllQueries, index: itemIndex || 0 }));
  };

  return (
    <div className={classes.filterDrawer}>
      <div className={classes.container}>
        <div className={classes.indexContainer}>
          <FilterButton
            detail={filterOptionsData[0]}
            activeFilter={activeFilterOption}
            setActiveFilter={setActiveFilterOption}
            count={urlState.libraryIds.length}
          />
          <Typography className={classes.filterLabel} variant="h6">
            FILTERS
          </Typography>
          {filterOptionsData.map(
            (option) =>
              option.panelName !== "libraries" && (
                <FilterButton
                  key={option.panelName}
                  detail={option}
                  activeFilter={activeFilterOption}
                  setActiveFilter={(filter) => {
                    if (libraryIds.length > 0) setActiveFilterOption(filter);
                  }}
                  count={getIndividualSelectedFiltersCount(urlState, option.panelName)}
                  showApplied={option.panelName === FilterOptions.Advanced && !!urlState.lucene}
                />
              )
          )}
          <div className={classes.activeFilterSection}>
            <Typography className={clsx(classes.filterLabel, classes.activeFilterLabel)} variant="h6">
              ACTIVE FILTERS
            </Typography>
            <div className={classes.activeFilters}>
              <SelectedFiltersHeader hideHeader={true} />
              <GetMetionedFilters
                data={getStandardSearchEntityByType(globalData[FilterType.Keywords])}
                label={"Element"}
                handleClose={handleClose}
              />
            </div>
          </div>
        </div>

        <div
          className={classes.filterViewerContainer}
          style={{
            minWidth: activeFilterOption ? FILTER_VIWER_CONTAINER_WIDTH : "0px",
          }}
        >
          {activeFilterOption && (
            <div className={classes.filterViwerContent}>
              <div className={classes.filterHeader}>
                <Typography variant="h2">{activeFilterOption.header}</Typography>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => setActiveFilterOption(undefined)}
                  data-testid={testId(dataTestId, "close")}
                />
              </div>
              <Typography className={classes.activeFilterLabel} variant="h4">
                {activeFilterOption.discription}
              </Typography>
              {getFilterContant(activeFilterOption.panelName, () => setActiveFilterOption(undefined))}
            </div>
          )}
        </div>
      </div>
      <Backdrop
        className={classes.backdrop}
        open={!!activeFilterOption}
        onClick={() => {
          setActiveFilterOption(undefined);
          dispatch(toggleDrawerOpen(false));
        }}
      ></Backdrop>
    </div>
  );
}
