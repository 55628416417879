import { BarChart } from "@material-ui/icons";
import useDocumentStatistics from "../../../../../../hooks/useDocumentStatistics";
import useReaderStore from "../../../../../../store/hooks/useReaderStore";
import OverflowList from "../overflowList/OverflowList";
import OverviewSection from "./OverviewSection";

export default function Stats() {
  const { selectedDocumentId } = useReaderStore((state) => state);
  const { data, isLoading } = useDocumentStatistics(selectedDocumentId as string);
  return (
    <OverviewSection isLoading={isLoading} icon={<BarChart />} title="Stats">
      {data && <OverflowList fontStyle="italic" emptyText="No Stats found" items={data} />}
    </OverviewSection>
  );
}
