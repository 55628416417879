import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    button: {
      padding: `${spacing(1.25)}px ${spacing(2)}px`,
      minWidth: 100,
      "&.MuiButton-root.Mui-disabled": {
        color: "inherit",
      },
    },
    buttonLoading: {
      marginLeft: spacing(1),
    },
    buttonPrimary: {
      "&.MuiButton-root.Mui-disabled": {
        color: "#fff",
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: spacing(3.5),
      "& > :last-child": {
        marginLeft: spacing(4),
      },
    },
    icon: {
      verticalAlign: "middle",
    },
    section: {
      marginBottom: spacing(2),
    },
    title: {
      display: "flex",
      justifyContent: "left",
      marginBottom: spacing(3),
    },
    previewContainer: {
      height: 500,
      width: "50%",
      overflow: "auto",
      border: "1px solid black",
      borderRadius: 10,
      padding: 10,
    },
  })
);
