import {
  createStyles,
  IconButton,
  makeStyles,
  StandardTextFieldProps,
  TextField,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

interface BasicSearchProps extends StandardTextFieldProps {
  defaultValue?: string;
  onTextSubmit?: (text: string) => void;
  onTextChange?: (text: string) => void;
  disabledButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fauxDisabled: {
      cursor: "default",
      opacity: 0.5,
      "&:hover": {
        background: "0",
      },
    },
  })
);

export default function BasicSearch({ onTextSubmit, ...props }: BasicSearchProps): JSX.Element {
  const { onTextChange, placeholder, defaultValue, disabledButton = false, ...rest } = props;
  const [text, setText] = useState<string>("");
  // eslint-disable-next-line
  const classes = useStyles();

  useEffect(() => {
    if (onTextChange) {
      onTextChange(text);
    }
  }, [text, onTextChange]);

  function onKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter") {
      if (onTextSubmit) {
        onTextSubmit(text);
      }
    }
  }

  return (
    <TextField
      {...rest}
      defaultValue={defaultValue}
      spellCheck={false}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      onChange={(e) => setText(e.target.value)}
      InputProps={{
        endAdornment: (
          <Tooltip title={disabledButton ? "Select a source group to search" : ""}>
            <IconButton
              className={clsx(disabledButton && classes.fauxDisabled)}
              disableRipple={disabledButton}
              onClick={
                disabledButton
                  ? undefined
                  : () => {
                      if (onTextSubmit) {
                        onTextSubmit(text);
                      }
                    }
              }
            >
              <Search />
            </IconButton>
          </Tooltip>
        ),
      }}
    />
  );
}
