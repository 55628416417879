import React from "react";
import { Typography } from "@material-ui/core";

export default function NoPermissionsPage(): JSX.Element {
  return (
    <Typography style={{ alignSelf: "center", margin: "0 auto" }} variant="h3">
      Expecting to see something here? Contact <a href="mailto:support@amplyfi.com">support@amplyfi.com</a>
    </Typography>
  );
}
