import { WarningOutlined as WarningIcon } from "@material-ui/icons";
import React from "react";
import { Validation } from "./Validation";

interface QueryTooNarrowProps {
  count: number;
  email: string;
}

export const QueryTooNarrow = ({ count, email }: QueryTooNarrowProps): JSX.Element => (
  <Validation
    icon={<WarningIcon color="error" />}
    messages={[
      `We can not harvest ${count} documents. Try to increase the date gap or write a broader query.`,
      <>
        For help contact -{" "}
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          {email}
        </a>
      </>,
    ]}
    title={`Total estimated documents - ${count.toLocaleString()}`}
  />
);
