import { testId } from "@amplyfi/ui-components/testHelpers";
import { Box, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { AccountTree as SourceIcon, Warning } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOrgId } from "../../../../helpers/user";
import useAIHistory from "../../../../hooks/useAIHistory";
import useLibrary from "../../../../hooks/useLibrary";
import { blockLibrarySource } from "../../../../http/search/documentLibrary";
import { MonitoringDocument } from "../../../../models/monitoring";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";
import { useAuth0 } from "../../../Auth0/AuthWrapper";
import { goToLibrarySettingsTab } from "../Routing";
import SimpleDialog from "../../../Widgets/Dialog/SimpleDialog";
import { useFeedHistoricFilterCounts } from "../../../../hooks/useFeedHistoricFilterCounts";
import { pluraliseSignals } from "../../../../helpers/textHelper";
import { analyticsEvent } from "../../../../helpers/analytics";
import useDialogStore from "../../../../store/hooks/useDialogStore";
import useFeedUpdates from "../../../../hooks/useFeedUpdates";
import { blueShades } from "../../../Charts/helpers/colourScaleHexes";

interface BlockSourceProps {
  document?: MonitoringDocument;
  libraryId: string | null;
  onClose: (e: React.MouseEvent<Element, MouseEvent>, changed: boolean) => void;
}

interface BlockSourceContentProps {
  document: MonitoringDocument;
  handleBlockSource: (e: React.MouseEvent<Element, MouseEvent>, document: MonitoringDocument) => Promise<void>;
  isBusy: boolean;
  libraryId: string | null;
  onClose: (e: React.MouseEvent<Element, MouseEvent>, changed: boolean) => void;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  icon: {
    marginRight: spacing(1),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  subTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: spacing(3),
    gap: spacing(1),
  },
  content: {
    marginTop: spacing(3),
    marginBottom: spacing(2),
  },
}));

const HISTORIC_FILTER_COUNT_DAYS = 7;

const BlockSourceContent = ({ document, handleBlockSource, libraryId, onClose }: BlockSourceContentProps) => {
  const classes = useStyles();
  const history = useAIHistory();
  const { source, url } = document;
  const { origin, host } = new URL(url);
  const { data: historicCounts } = useFeedHistoricFilterCounts(
    document.libraryId,
    "source",
    [document.source],
    HISTORIC_FILTER_COUNT_DAYS
  );
  const sourceCount = historicCounts?.[document.source].documentCount;
  const removeDialog = useDialogStore((state) => state.removeDialog);

  return (
    <SimpleDialog
      title={
        <Typography variant="h2" className={classes.title}>
          <SourceIcon className={classes.icon} /> Block a source
        </Typography>
      }
      data-testid={testId("document", "block-source")}
      onClose={(e) => {
        onClose(e, false);
        removeDialog();
      }}
      content={
        <>
          <Typography variant="h3">
            Source: {source} (
            <Link href={origin} style={{ textDecoration: "none", color: blueShades[3] }} target={source} rel="noopener noreferrer">
              {host}
            </Link>
            )
          </Typography>
          <Box className={classes.content}>
            <Box className={classes.subTitle}>
              <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <Warning />
                <Typography variant="h4"> Blocking this source will remove past signals from {source}, as well as preventing future ones.</Typography>
              </Box>
              <Typography variant="h4"> Please Note: <Typography component="span" style={{ fontStyle: "italic" }}>Unblocking a source will not return previously removed signals.</Typography></Typography>
            </Box>
            <Typography>This source had <Typography component="span" style={{ fontWeight: 600 }}>{pluraliseSignals(sourceCount ?? 0)}</Typography> in the past week.</Typography>            {libraryId && (
              <Typography style={{ marginTop: 4 }}>
                Review your blocked sources within{" "}
                <Link
                  style={{ textDecoration: "none", color: blueShades[3] }}
                  href={goToLibrarySettingsTab(libraryId, "filters")}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.pushFeedSettings(libraryId, "filters");
                    removeDialog();
                  }}
                >
                  Interest filter settings
                </Link>
              </Typography>
            )}
          </Box>
        </>
      }
      primary="Confirm"
      secondary="Cancel"
      onConfirm={(e) => handleBlockSource(e, document)}
    />
  );
};

export const BlockSource = ({ document, libraryId, onClose }: BlockSourceProps): JSX.Element => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(false);
  const { refetch } = useLibrary(libraryId);
  const removeDialog = useDialogStore((state) => state.removeDialog);
  const { reset } = useFeedUpdates();

  const handleBlockSource = async (
    e: React.MouseEvent<Element, MouseEvent>,
    { documentId, source }: MonitoringDocument
  ) => {
    setIsBusy(true);
    const body = await blockLibrarySource(getOrgId(user), libraryId, documentId, source);
    if (!body) {
      analyticsEvent("Feed", "FilterSourceBlocked", source);
    }
    refetch();
    onClose(e, true);
    reset();
    dispatch(
      setSnack({
        body: body || `${source} source has now been blocked within this library`,
        title: body ? "Failed" : "Success",
      })
    );
    removeDialog();
  };

  useEffect(() => {
    if (!document) {
      setIsBusy(false);
    }
  }, [document]);

  return document ? (
    <BlockSourceContent
      document={document}
      handleBlockSource={handleBlockSource}
      isBusy={isBusy}
      libraryId={libraryId}
      onClose={onClose}
    />
  ) : (
    <></>
  );
};
