import { Box, Button, Checkbox, FormControlLabel, FormGroup, Switch, Typography, makeStyles } from "@material-ui/core";
import { useState } from "react";
import useCreateAlertType from "../../../../../hooks/mutations/feed/useCreateAlertType";
import { useCreateFeedStore } from "../../../../../store/hooks/useCreateFeedStore";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import KeyphraseAutocomplete from "../../components/autocomplete/KeyphraseAutocomplete";
import Stack from "../../components/stack/Stack";
import { GenericResult } from "../../models";
import { BasicEmptyState } from "../Components/DynamicComponent";
import TextEntryField from "../Components/TextEntryField";
import AddSignalsPanel from "./AddSignalsPanel";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        display: "grid",
        gridTemplateRows: "max-content",
        gridTemplateColumns: "1fr",
    }
}))

const ALL_ENTITY_ID = "ALL";

export default function CreateSignalTypePanel() {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [topics, setTopics] = useState<{ text: string, exact?: boolean }[]>([]);
    const [selectedEntities, setSelectedEntities] = useState<string[]>([ALL_ENTITY_ID]);
    const { interests, toggleSignal } = useCreateFeedStore();
    const { mutateAsync: createAlertType } = useCreateAlertType();
    const { setCustomPanel } = useReaderStore();

    function setExact(topic: { text: string, exact?: boolean }) {
        setTopics(topics.map(t => t.text === topic.text ? { ...t, exact: topic.exact } : t));
    }

    function handleCancel() {
        setCustomPanel(<AddSignalsPanel />);
    }

    async function handleConfirm() {
        const newAlert = await createAlertType({
            name,
            fuzzyTopics: topics.filter(t => !t.exact).map(t => t.text),
            exactTopics: topics.filter(t => t.exact).map(t => t.text),
            description: ""
        });

        toggleSignal({ id: newAlert.alertTypeId as string, name, feeds: selectedEntities });
        setCustomPanel(<AddSignalsPanel />)
    }

    function handleSelectedChange(results: GenericResult[]) {
        setTopics(results.map(r => ({ text: r.name, exact: topics.find(t => t.text === r.name)?.exact || false })));
    }

    return <Stack className={classes.root} spacing={2}>
        <Stack spacing={2}>
            <Typography variant="h2">Create Signal Type</Typography>
            <Stack spacing={0}>
                <Typography variant="h4">Name</Typography>
                <TextEntryField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    fullWidth
                />
            </Stack>

            <Stack spacing={2}>
                <Typography variant="h4">Topics</Typography>
                <KeyphraseAutocomplete
                    compact
                    defaultSelected={topics.map(t => ({ id: t.text, name: t.text, exact: t.exact }))}
                    onSelectedChange={handleSelectedChange}
                    itemActions={(item: GenericResult) => [
                        <Box>
                            <Typography variant="caption">Exact</Typography>
                            <Switch
                                checked={topics.find(t => t.text === item.name)?.exact || false}
                                onChange={(_, checked) => setExact({ text: item.name, exact: checked })}
                            />
                        </Box>,
                    ]}
                />
                {topics.length === 0 && <BasicEmptyState title="No topics selected" />}
            </Stack>
            <FormGroup>
                {[{ id: ALL_ENTITY_ID, name: "All" }, ...interests].map(interest => <FormControlLabel
                    style={{ marginLeft: interest.id !== ALL_ENTITY_ID ? 16 : 0 }}
                    disabled={interests.length === 0 || (interest.id !== ALL_ENTITY_ID && selectedEntities.includes(ALL_ENTITY_ID))}
                    value={interest.id}
                    label={interest.name}
                    checked={selectedEntities.includes(interest.id) || selectedEntities.includes(ALL_ENTITY_ID)}
                    control={<Checkbox checked={selectedEntities.includes(interest.id) || selectedEntities.includes(ALL_ENTITY_ID)} />}
                    onChange={() =>
                        selectedEntities.includes(interest.id)
                            ? setSelectedEntities(selectedEntities.filter((e) => e !== interest.id))
                            : setSelectedEntities([...selectedEntities, interest.id])
                    }
                />)
                }
            </FormGroup>
        </Stack>
        <Stack marginTop="auto" spacing={2} direction="horizontal">
            <Button style={{ height: 40, width: 150, marginLeft: "auto" }} color="secondary" onClick={handleCancel} variant="outlined">Cancel</Button>
            <Button style={{ color: "white", height: 40, width: 150 }} color="secondary" onClick={handleConfirm} variant="contained">Confirm</Button>
        </Stack>
    </Stack>
}