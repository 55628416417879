import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { setSnack } from "../../../store/reducers/ui/snackReducer";

interface UseAddToListProps<TResponseType, TRequestType> {
    url: string;
    key: string;
    initialData: (item: Partial<TRequestType>) => TRequestType;
    onDataChange: (oldData: TResponseType | undefined, newData: TRequestType) => TResponseType;
    onError?: {
        message: string;
        callback?: () => void;
    }
}

// This is a convenience function for typical POST requests that add an item to a list.
// The expected result of the POST request is the newly created item.
export default function useCreate<TResponseType, TRequestType>(props: UseAddToListProps<TResponseType, TRequestType>) {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation<TRequestType, unknown, Partial<TRequestType>, unknown>(async (item: Partial<TRequestType>) => {
        const { data } = await axios.post<TRequestType>(props.url, item);
        return data;
    }, {
        onMutate(variables) {
            return queryClient.setQueriesData<TResponseType | undefined>([props.key], ((oldData: TResponseType | undefined) => {
                if (!oldData) {
                    return undefined;
                }
                return props.onDataChange(oldData, props.initialData(variables));
            }))
        },
        onSuccess(data) {
            queryClient.setQueriesData<TResponseType | undefined>([props.key], ((oldData: TResponseType | undefined) => {
                if (!oldData) {
                    return undefined;
                }
                return props.onDataChange(oldData, data);
            }))
        },
        onError(err) {
            if (props.onError) {
                dispatch(setSnack({ title: props.onError.message }));
                props.onError.callback?.();
            }
        },
        onSettled() {
            queryClient.invalidateQueries(props.key);
        }
    });
}