import axios from "axios";
import { LibraryItemSettings } from "../models/search/documentLibrary";

const apiUrl = `${process.env.REACT_APP_ANALYSE_API}/v2/organisations`;

export async function getUserNotifications(
  organisationId: string,
  userId: string | undefined
): Promise<LibraryItemSettings> {
  const { data } = await axios.get<LibraryItemSettings>(`${apiUrl}/${organisationId}/users/${userId}/email-settings`);
  return data;
}

export async function updateUserNotifications(
  organisationId: string,
  userId: string | undefined,
  settings: LibraryItemSettings
): Promise<unknown> {
  const { data } = await axios.put<LibraryItemSettings>(
    `${apiUrl}/${organisationId}/users/${userId}/email-settings`,
    settings
  );
  return data;
}
