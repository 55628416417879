import React from "react";
import { Typography, makeStyles, createStyles, Theme } from "@material-ui/core";
import Card from "./Card";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "50vh",
    },
  })
);

export default function ErrorCard(): JSX.Element {
  const classes = useStyles();
  return (
    <Card
      isError={false}
      containerClasses={classes.container}
      title={<Typography variant="h1">Something went wrong!</Typography>}
      content={<Typography>There was an error generating this page</Typography>}
    />
  );
}
