import { Box, Card, CardHeader, Grid, Typography } from "@material-ui/core";
import Stack from "../../../stack/Stack";
import useWizardState from "../../../../../../../hooks/useWizardState";
import { CreateReportWizardState, ReportTimeframe } from "../CreateReportWizard";
import { useFeedViewStore } from "../../../../../../../store/hooks/useFeedViewStore";
import moment from "moment";
import useReports from "../../../../../../../hooks/queries/reports/useReports";
import { useTotalFeedCounts } from "../../../../../../../hooks/useFeed";
import { useAmplyfiEntityId } from "../../../../../../../hooks/useAmplyfiEntities";
import { EntityType } from "../../../../../EntityKnowledgeBase/models";
import { useFlags } from "launchdarkly-react-client-sdk";
import DynamicComponent from "../../../../Configuration/Components/DynamicComponent";
import { Warning } from "@material-ui/icons";
import { useMemo } from "react";

interface ReportTypeProps {
  id: "PESTEL" | "SWOT" | "Summary" | "ResearchQuestion";
  title: string;
  description: string;
  featureFlag?: string;
  minimumSignalCount: number;
  validTypes: string[];
  validDateRanges: ReportTimeframe[];
}

function ReportType(props: ReportTypeProps) {
  const { libraryId } = useFeedViewStore();
  const [state, setState] = useWizardState<CreateReportWizardState>("create-report");

  const descriptionLines = props.description.split("\n").map((line, index) => (
    <Typography key={index} color="inherit" style={{ marginBottom: 16 }}>
      {line}
    </Typography>
  ));

  const startDate = useMemo(() => {
    const time = props.validDateRanges.filter((d) => d !== "custom")[props.validDateRanges.length - 1];
    return time
      ? moment()
          .subtract(...time.split(" "))
          .utc()
          .toISOString()
      : undefined;
  }, [props.validDateRanges]);

  const { data: feedCounts } = useTotalFeedCounts(libraryId, state.selectedEntityId, startDate);
  const signalCount = feedCounts?.inboxDocumentCount ?? 0;

  return signalCount >= props.minimumSignalCount ? (
    <Card
      onClick={() =>
        setState({
          ...state,
          reportType: props.id,
          nextDisabled: false,
          validDateRanges: props.validDateRanges,
          reportTitle: props.title,
        })
      }
      elevation={0}
      style={{
        height: 200,
        borderRadius: 12,
        outline: state.reportType === props.id ? "2px solid blue" : "1px solid #d3d3d3",
        cursor: "pointer",
      }}
    >
      <CardHeader
        titleTypographyProps={{ variant: "h4", style: { lineHeight: 3.5 } }}
        title={props.title}
        subheader={descriptionLines}
      />
    </Card>
  ) : (
    <Card
      style={{
        height: 200,
        borderRadius: 12,
        outline: "1px solid #d3d3d3",
        position: "relative",
      }}
      elevation={0}
    >
      <CardHeader
        titleTypographyProps={{ variant: "h4", style: { lineHeight: 3.5 } }}
        title={props.title}
        subheader={`${props.title} requires at least ${props.minimumSignalCount} signals.`}
      ></CardHeader>
      <Warning style={{ position: "absolute", top: 16, right: 16 }} />
    </Card>
  );
}

export const REPORT_TYPES: ReportTypeProps[] = [
  {
    id: "SWOT",
    title: "SWOT Analysis",
    description: "Strengths, weaknesses, opportunities, and threats, tailored from personalized signals in your feed",
    minimumSignalCount: 10,
    validTypes: [EntityType.Organisation],
    validDateRanges: ["1 month", "3 months", "custom"],
  },
  {
    id: "PESTEL",
    title: "PESTEL Analysis",
    description:
      "Examines a spectrum of political, economic, social, technological, environmental, and legal factors using a curated selection of articles and signals",
    minimumSignalCount: 15,
    validTypes: [EntityType.Organisation, EntityType.Technology],
    validDateRanges: ["1 month", "3 months", "custom"],
  },
  {
    id: "Summary",
    title: "Summary",
    description: "Captures a snapshot of the most significant signals from your feed",
    featureFlag: "feedSummaryReport",
    minimumSignalCount: 15,
    validTypes: [EntityType.Organisation, EntityType.Technology],
    validDateRanges: ["1 week"],
  },
  {
    id: "ResearchQuestion",
    title: "Research Question",
    description:
      "Addresses your complex research questions with insights drawn from the most relevant signals in your feed",
    featureFlag: "feedResearchReport",
    minimumSignalCount: 15,
    validTypes: [EntityType.Organisation, EntityType.Technology],
    validDateRanges: ["1 month", "3 months", "custom"],
  },
];

export default function ReportTypeSelector() {
  const { libraryId } = useFeedViewStore();
  const [state] = useWizardState<CreateReportWizardState>("create-report");
  const { data: entity } = useAmplyfiEntityId(state.selectedEntityId || undefined);
  const flags = useFlags();
  const { isLoading: reportsLoading } = useReports(libraryId, state.selectedEntityId);

  const reportTypes = REPORT_TYPES.filter(({ featureFlag }) => !featureFlag || flags[featureFlag]);
  const validForEntityType = (r: ReportTypeProps): boolean => r.validTypes.some((type) => type === entity?.type ?? "");
  const { isLoading } = useTotalFeedCounts(libraryId, state.selectedEntityId);
  const validReportType = (r: ReportTypeProps): boolean => validForEntityType(r);

  return (
    <Box padding={4}>
      <Stack spacing={2}>
        <Typography variant="h2">What type of report do you want to create?</Typography>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 12 }}>
          <Typography variant="body1">Selected Interest:</Typography>
          <Typography
            style={{
              border: "1px solid #f0f0f0",
              borderRadius: 5,
              paddingInline: 8,
              paddingBlock: 4,
              boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.10)",
            }}
            variant="h4"
          >
            {entity?.name}
          </Typography>
        </Box>
        <DynamicComponent isLoading={isLoading || reportsLoading}>
          {!reportTypes.some(validReportType) && reportTypes.some(validForEntityType) && (
            <Typography variant="h3">
              All types of report have been created today for this interest. You can create more tomorrow.
            </Typography>
          )}
          {!reportTypes.some(validForEntityType) && (
            <Typography variant="h3">All types of report are not valid for this {entity?.type} interest.</Typography>
          )}
          <Grid spacing={2} container>
            {reportTypes
              .filter((t) => validForEntityType(t))
              .map((r) => (
                <Grid item xs={12} lg={6}>
                  <ReportType {...r} />
                </Grid>
              ))}
          </Grid>
        </DynamicComponent>
      </Stack>
    </Box>
  );
}
