import { ConnectorSortBy, ConnectorSortOrder, getSearchResults, SearchResult } from "../connectors";

export function searchResults(
  searchId: string | undefined,
  selectedConnectors: string[],
  selectedResults: string[],
  timestamp: number,
  sortBy: ConnectorSortBy = ConnectorSortBy.Relevancy,
  sortOrder: ConnectorSortOrder = ConnectorSortOrder.Descending,
  page = 0,
  size = 50
): Promise<SearchResult> | undefined {
  if (searchId && searchId.length > 0) {
    return getSearchResults(
      searchId as string,
      selectedConnectors,
      selectedResults,
      timestamp,
      sortBy,
      sortOrder,
      page,
      size
    );
  }
  return undefined;
}
