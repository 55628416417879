import React, { useState } from "react";
import { TreeView, TreeItem } from "@material-ui/lab";
import { Datum, Flare } from "./sunburstHelpers";
import { ExpandMore, ChevronRight } from "@material-ui/icons";

interface TopicListProps {
  data: Flare;
  onItemClicked: (item: Datum) => void;
}

interface ListItemProps {
  data: Datum;
  onClick: (data: Datum) => void;
}

function findChildRecursively(name: string, children: Datum[]) {
  const child = children.find((c) => c.name === name);
  if (!child) {
    return children.reduce<Datum | null>((accum, current) => {
      return accum || current.children?.find((c) => c.name === name) || null;
    }, null);
  } else {
    return child;
  }
}

function RecursiveList(props: TopicListProps): JSX.Element {
  const { data, onItemClicked } = props;
  const [expanded, setExpanded] = useState<string[]>([]);

  return (
    <TreeView
      expanded={expanded}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onNodeSelect={(event: React.ChangeEvent<HTMLElement>, nodeId: string) => {
        const selectedItem = findChildRecursively(nodeId, data.children || []);
        if (selectedItem && !event.target.closest(".MuiTreeItem-iconContainer")) {
          onItemClicked(selectedItem);
        }
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onNodeToggle={(event: React.ChangeEvent<HTMLElement>, nodeids) => {
        if (event.target.closest(".MuiTreeItem-iconContainer")) {
          setExpanded(nodeids);
        }
      }}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
    >
      {(data.children || []).map((c, i) => (
        <RecursiveListItem key={`${i}-${c.name}`} data={c} onClick={onItemClicked} />
      ))}
    </TreeView>
  );
}

function RecursiveListItem(props: ListItemProps): JSX.Element {
  const { data, onClick } = props;
  return (
    <TreeItem nodeId={data.name} label={data.name}>
      {(data.children || []).map((c, i) => (
        <RecursiveListItem key={`${i}-${c.name}`} data={c} onClick={onClick} />
      ))}
    </TreeItem>
  );
}

export default function TopicList(props: TopicListProps): JSX.Element {
  return <RecursiveList {...props} />;
}
