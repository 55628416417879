import { useQuery, UseQueryResult } from "./useQuery";
import { convertSearchStateToSearchRequestV2 } from "../helpers/search";
import { getDocCount } from "../http/count";
import { ParsedQueryParams } from "../models/queryParams";
import { SearchRequestV2 } from "../models/search";

export default function useCountApi(
  url: ParsedQueryParams,
  isInitial?: boolean,
  resetDates?: boolean
): UseQueryResult<number, unknown> {
  const request = convertSearchStateToSearchRequestV2(url);
  if (isInitial) {
    resetFiltersRequest(request);
  }

  const queryId = isInitial ? ["counts-api-initial", request, url.libraries] : ["counts-api", request, url.libraries];

  return useQuery(
    queryId,
    url.libraries.length === 0
      ? async () => Promise.resolve(0)
      : async () => getDocCount(request, url.libraries, resetDates)
  );
}

const resetFiltersRequest = (request: SearchRequestV2): SearchRequestV2 => ({
  ...request,
  userFilters: {
    endDate: undefined,
    keywords: [],
    keyPhrases: [],
    news: undefined,
    startDate: undefined,
    libraryIds: [],
  },
});
