import axios from "axios";
import { LibrariesType } from "../models/queryParams";
import { SearchRequestV2 } from "../models/search";
import { SentenceResponse } from "../models/sentence";
import { getAnalysisV2Url } from "./general";

export async function searchSectorSentences(
  searchRequest: SearchRequestV2,
  docType: LibrariesType[] | null = null
): Promise<SentenceResponse> {
  const searchResponse = await axios.post<SentenceResponse>(
    getAnalysisV2Url("search/sectors/mentions", docType),
    searchRequest
  );

  return searchResponse.data;
}
