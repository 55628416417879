import clsx from "clsx";
import React from "react";
import { Harvest } from "../../../../models/harvest";
import { COLUMNS, columnValueClassName, useHavestTableStyles } from "./harvestTable";
import { HarvestConfig } from "../models";

interface HarvestItemProps {
  harvest: Harvest;
  harvestConfig?: HarvestConfig;
}

export const HarvestItem = ({ harvest, harvestConfig }: HarvestItemProps): JSX.Element => {
  const classes = useHavestTableStyles();

  return (
    <li>
      {COLUMNS.map(({ Decorator, header, onClick }, i) => (
        <div
          className={clsx(classes.column, classes[columnValueClassName(i)])}
          data-label={header?.toString()}
          key={header?.toString()}
          onClick={onClick?.(harvest)}
        >
          <Decorator harvest={harvest} harvestConfig={harvestConfig} />
        </div>
      ))}
    </li>
  );
};
