import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography, darken, makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { distinct } from "../../../../../helpers/arrayHelpers";

interface ResponsiveListItem {
    id: string;
    name: string;
    disabled?: boolean;
    highlighted?: boolean;
}

interface ResponsiveHorizontalListProps {
    onItemClicked?: (id: string) => void;
    icon?: React.ReactNode;
    items: ResponsiveListItem[];
}

const useStyles = makeStyles(({ spacing }) => ({
    boxContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        paddingBottom: 8,
        "&:hover": {
            background: darken("#fff", 0.05),
        },
    },
    item: ({ highlighted }: { highlighted: boolean }) => ({
        fontSize: 12,
        color: highlighted ? "#000" : "#6A6A6A",
        fontWeight: highlighted ? "bold" : undefined,
        marginRight: spacing(1),
        position: "relative",
        maxWidth: "80%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        display: "inline-flex",
        overflow: "hidden",
    })
}))

function Item(item: ResponsiveListItem & { hidden: boolean }) {
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles({ highlighted: item.highlighted || false });
    return <Typography hidden={false} className={clsx(classes.item, "responsive-item")} id={item.id} ref={ref} variant="body1">{item.name}{!item.hidden && ","}</Typography>
}

export default function ResponsiveHorizontalList(props: ResponsiveHorizontalListProps) {
    const ref = useRef<HTMLDivElement>(null);
    const classes = useStyles({ highlighted: false });
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const fittedItems = props.items;
    const [filteredItems, setFilteredItems] = useState<string[]>([]);

    function recalculateFilteredItemCount(element: HTMLElement | null = ref.current) {
        if (element) {
            const items = Array.prototype.slice.call(element.getElementsByClassName("responsive-item"));
            const firstHiddenIndex = items.findIndex((f: HTMLElement) => f.offsetTop > 10);
            const filteredItems = items.filter((f: HTMLElement) => f.offsetTop > 10).map((f: HTMLElement) => f.id);
            setFilteredItems([items[firstHiddenIndex - 1]?.id, ...filteredItems]);
        }
    }

    useEffect(() => {
        recalculateFilteredItemCount(ref.current);
    }, [ref])

    useEffect(() => {
        window.addEventListener("resize", () => recalculateFilteredItemCount(ref.current));

        return () => {
            window.removeEventListener("resize", () => recalculateFilteredItemCount);
        }
    }, [])

    return <Box className={classes.boxContainer}>
        <div onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget)
        }} ref={ref} style={{ width: "100%", overflow: "hidden", height: 30, position: "relative" }}>
            {props.icon && <Typography style={{ fontSize: 12, color: "#6A6A6A", display: "inline-block", transform: "translateY(6px)", marginRight: 8 }} variant="body2">{props.icon}</Typography>}
            <Item {...props.items[0]} key={props.items[0].id} hidden={false} />
            {
                props.items.filter((_, index) => index > 0).map((item) => <Item key={item.id} {...item} hidden={filteredItems.includes(item.id)} />)
            }
            {filteredItems.length > 1 && <Button onClick={(e) => null} style={{ color: "#6A6A6A", marginBottom: 4, padding: 0, position: "absolute", right: -8, top: 8 }} id="remaining-btn">+{filteredItems.length}</Button>}
        </div>
        {anchorEl && <Menu onClose={(e: MouseEvent) => {
            e.stopPropagation();
            setAnchorEl(null)
        }
        } anchorEl={anchorEl} open>
            {
                distinct(fittedItems, "name").map(item => <MenuItem disabled={item.disabled} onClick={(e) => {
                    e.stopPropagation();
                    if (props.onItemClicked) {
                        props.onItemClicked(item.id);
                        setAnchorEl(null);
                    }
                }} key={`menuItem-${item.id}`}>
                    <ListItemText primary={item.name} />
                    {props.onItemClicked && !item.disabled && <ListItemIcon style={{ marginLeft: 16, paddingRight: 0, justifyContent: "flex-end" }}><Add /></ListItemIcon>}
                </MenuItem>)
            }
        </Menu>}
    </Box>
}