import { createStyles, darken, makeStyles, Theme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const textEllipses: CSSProperties = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const tooltipStyling = (theme: Theme): CSSProperties => ({
  boxShadow: `0 0 10px 5px ${theme.palette.borders.dark}`,
  borderRadius: 10,
});

export const link = (theme: Theme): CSSProperties => ({
  color: theme.palette.links.primary,
  cursor: "pointer",

  "&:hover": {
    color: darken(theme.palette.links.primary, 0.2),
  },
});

export const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    clearButtonStyling: {
      backgroundColor: "transparent",
      border: "none",

      "&:focus": {
        outline: "none",
      },
    },
  })
);

export const drawerBreakPointStyling = (theme: Theme): CSSProperties => ({
  [theme.breakpoints.down(1100)]: {
    width: "100%",
  },
});
