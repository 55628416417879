import { PickledBluewood, White } from "@amplyfi/ui-components/theme/colors";
import { Typography } from "@material-ui/core";
import { ChartData, ChartOptions } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { errorMessages } from "../../helpers/errorHelper";
import { pluraliseDocuments } from "../../helpers/textHelper";
import useSentiment from "../../hooks/useSentiment";
import {
  onSetOpenCloseDrawer,
  addSentimentFilter as addSentimentFilterRDV,
} from "../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import { withCursorPointer } from "./helpers/ChartUtility";

export default function SentimentBarSummary(): JSX.Element {
  const { data, isLoading, isError } = useSentiment("Month");
  const entries = data?.sentimentHistogram ?? [];
  const history = useHistory();
  const dispatch = useDispatch();
  function formatData(): ChartData {
    return {
      labels: entries.map((value) => pluraliseDocuments(value.documentCount ?? 0)),
      datasets: [
        {
          data: entries.map((value) => value.documentCount),
          backgroundColor: entries.map((_, index) => colors[index]),
          barPercentage: 0.9,
          categoryPercentage: 0.9,
          minBarLength: 3,
        },
      ],
    };
  }

  const colors = [
    "#F44336",
    "#F55336",
    "#F66437",
    "#F77537",
    "#F88638",
    "#F99738",
    "#FAA739",
    "#FBB839",
    "#FCC93A",
    "#FDDA3A",
    "#FFEB3B",
    "#EDE53D",
    "#DBDF3F",
    "#C9D941",
    "#B7D343",
    "#A5CD45",
    "#93C747",
    "#81C149",
    "#6FBB4B",
    "#5DB54D",
    "#4CAF50",
  ];

  const options: ChartOptions = {
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: true,
            maxRotation: 0,
            autoSkip: false,
            callback: function (label, index, labels) {
              switch (index) {
                case 0:
                  return "Negative" as string;
                case (labels.length - 1) / 2:
                  return "Neutral" as string;
                case labels.length - 1:
                  return "Positive" as string;
                default:
                  return "";
              }
            },
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: White,
      titleFontSize: 0,
      bodyFontColor: PickledBluewood[400],
      callbacks: {
        label: function tooltipLabel(tooltipItem: Chart.ChartTooltipItem, data: ChartData): string {
          return tooltipItem.label as string;
        },
      },
    },
    onClick: (ev, d) => {
      if (d && d[0]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { _index } = d[0] as any;
        const result = entries[_index];
        history.push({ search: window.location.search });

        dispatch(onSetOpenCloseDrawer(true));
        dispatch(addSentimentFilterRDV(result));
      }
    },
  };

  if (isLoading) {
    return <Skeleton height={100} />;
  } else if (isError) {
    return <Typography variant="body2">{errorMessages.sentimentBar}</Typography>;
  } else {
    return <Bar data={formatData()} options={withCursorPointer(options)} height={80} />;
  }
}
