import { ActiveOption } from "../../../models/chart-customizations";
import { EntityType } from "../../../models/entity";
import CustomizationModalContent from "./CustomizationModalContent";

interface CustomizationModalProps {
  onReset: () => ActiveOption[] | undefined;
  options: ActiveOption[];
  onApply: (selectedOptions: ActiveOption[], secondaryOptions: ActiveOption[]) => void;
  secondaryOptions?: ActiveOption[];
}

export default function CustomizationModal(props: CustomizationModalProps): JSX.Element {
  const { onApply, onReset, options, secondaryOptions } = props;
  return (
    <>
      <CustomizationModalContent
        types={[EntityType.KeyPhrase, EntityType.Organisation, EntityType.Person, EntityType.Country]}
        options={options}
        onApply={(selectedOptions: ActiveOption[], secondaryOptions: ActiveOption[]) => {
          onApply(selectedOptions, secondaryOptions);
        }}
        onReset={onReset}
        secondaryOptions={secondaryOptions}
      />
    </>
  );
}
