import { AnalyseTooltipProps } from "@amplyfi/ui-components/components/Tooltip";
import AnalyseTooltip from "@amplyfi/ui-components/components/Tooltip/EntityTooltip";
import { ClickAwayListener } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { tooltipStyling } from "../../../css/mixins";
import { analyticsEvent } from "../../../helpers/analytics";
import { convertCustomizationToEntity } from "../../../helpers/customization";
import { logError } from "../../../helpers/logger";
import { convertSearchStateToSearchRequestV2 } from "../../../helpers/search";
import { searchDocuments } from "../../../http/document";
import { getTooltip, Tooltip } from "../../../http/tooltip";
import { Entity, EntityType, HeatMapEntry } from "../../../models/entity";
import { FilterType } from "../../../models/filter";
import { QueryType } from "../../../models/query";
import { ParsedQueryParams } from "../../../models/queryParams";
import { DocumentType, TooltipType } from "../../../models/search";
import { useAnalyseSelector } from "../../../store/reducers";
import { getUpdateUrlSearch, resetAllParams } from "../../../store/reducers/searchRequest/url-functions";
import { addQuery, refineSearch } from "../../../store/reducers/searchRequest/urlReducer";
import {
  addHeatmapFilter as addHeatmapFilterRDV,
  onSetOpenCloseDrawer,
  viewRelatedDocuments as viewRelatedDocumentsRDV,
} from "../../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import EntityProfileLogo from "../EntityProfileLogo";

const CONTENT_WIDTH_MAX = 350;
const CONTENT_WIDTH_MIN = 286;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: CONTENT_WIDTH_MAX,
      borderRadius: 10,
    },
    container: {
      padding: theme.spacing(2.5),
      paddingBottom: theme.spacing(1.25),
      maxWidth: CONTENT_WIDTH_MAX,
      minWidth: CONTENT_WIDTH_MIN,
    },
    title: {
      flexGrow: 1,
    },
    entityLogo: {
      width: theme.spacing(3.75),
      height: theme.spacing(3.75),
      flexShrink: 0,
      marginRight: theme.spacing(),
    },
    titleContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1.25),
    },
    tooltip: {
      padding: 0,
      backgroundColor: theme.palette.componentBackground.main,
      border: `1px solid ${theme.palette.borders.light}`,
      ...tooltipStyling(theme),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    tooltipArrow: {
      fontSize: 18,
      width: 18,
      "&:before": {
        border: `1px solid ${theme.palette.borders.light}`,
      },
      color: theme.palette.common.white,
    },
  })
);

interface EntityTooltipProps {
  onClose: () => void;
  filterType?: FilterType;
  entity?: Entity;
  documentType?: DocumentType;
  centerPopover?: boolean;
  tooltipType?: TooltipType;
  hideViewDocs?: boolean;
  children: JSX.Element;
  heatmapEntry?: HeatMapEntry;
  className?: string;
  showListActions?: boolean;
}

export default function EntityTooltip(props: EntityTooltipProps & Partial<AnalyseTooltipProps>): JSX.Element {
  const {
    entity,
    onClose,
    documentType,
    tooltipType,
    hideViewDocs = false,
    heatmapEntry,
    filterType = FilterType.Keywords,
    children,
    className,
    style,
    classes: tooltipClasses,
    showListActions = true,
    ...rest
  } = props;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tooltip, setTooltip] = useState<Tooltip>();
  const urlStateParsed = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const [workbookOrUrlParsed, setWorkbookOrUrlParams] = useState<ParsedQueryParams | null>(null);
  const dispatch = useDispatch();

  const onRefineSearch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (entity) {
      if (filterType === FilterType.Keywords) {
        dispatch(addQuery({ entity, type: QueryType.AllQueries }));
      } else {
        dispatch(
          refineSearch({
            type: filterType,
            entity,
          })
        );
      }
      handlePopoverClose();

      analyticsEvent("TooltipSearch", "RefinedSearch", `${entity.name}-${entity.id}`);
    }
  };

  const onReplaceSearch = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (entity) {
      const parsedURL: Partial<ParsedQueryParams> = {
        ...resetAllParams(),
        all: [entity],
        libraries: urlStateParsed.libraries,
      };
      const updatedURL = getUpdateUrlSearch(parsedURL);
      handlePopoverClose();
      window.open(`/analyse/overview?${updatedURL}`, "_blank");
      handlePopoverClose();
      analyticsEvent("TooltipSearch", "ReplacedSearch", `${entity.name}-${entity.id}`);
    }
  };

  useEffect(() => {
    setWorkbookOrUrlParams(urlStateParsed);
  }, [urlStateParsed]);

  useEffect(() => {
    async function get() {
      setLoading(true);
      if (workbookOrUrlParsed !== null && entity) {
        const request = convertSearchStateToSearchRequestV2(workbookOrUrlParsed);

        try {
          if (!!heatmapEntry) {
            request.userFilters.keywords = [
              convertCustomizationToEntity(heatmapEntry.heatmapPrimary),
              convertCustomizationToEntity(heatmapEntry.heatmapSecondary),
            ];
            const { documentCount } = await searchDocuments(request, urlStateParsed.libraries);
            setTooltip({
              name: entity.name,
              documentsCount: documentCount,
              mentionsCount: 0,
              description: "",
              sentiment: null,
              sentimentOverTime: [],
            });
          } else {
            const tooltip = await getTooltip(
              request,
              {
                id: entity.id,
                name: entity.name,
                type: entity.type,
              },
              tooltipType,
              urlStateParsed.libraries
            );
            setTooltip(tooltip);
          }
        } catch (err) {
          logError(err);
          setError(true);
        }

        setLoading(false);
      }
    }

    if (entity && entity.name) {
      get();
    }
  }, [entity, workbookOrUrlParsed, documentType, tooltipType, heatmapEntry, urlStateParsed.libraries]);

  const handlePopoverClose = () => {
    onClose();
    setError(false);
  };

  const onViewRelatedDocuments = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (entity) {
      const isPublishedLocations = !entity.type && documentType === DocumentType.News;

      if (!!heatmapEntry) {
        dispatch(addHeatmapFilterRDV(heatmapEntry));
      } else {
        dispatch(
          viewRelatedDocumentsRDV({
            entities: !isPublishedLocations ? [entity] : undefined,
            publishLocations: isPublishedLocations ? [entity.name] : undefined,
            type: documentType ?? DocumentType.News,
            tooltipType,
          })
        );
      }
      handlePopoverClose();
      dispatch(onSetOpenCloseDrawer(true));
    }
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <span className={className}>
        <AnalyseTooltip
          data-testid="entity-tooltip"
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow, ...tooltipClasses }}
          open={!!entity}
          arrow
          onClose={onClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          interactive
          filterType={filterType}
          tooltip={tooltip}
          tooltipLoading={loading}
          tooltipError={error}
          onRefineSearch={!!heatmapEntry || !showListActions ? undefined : onRefineSearch}
          onReplaceSearch={!!heatmapEntry || !showListActions ? undefined : onReplaceSearch}
          onViewRelatedDocuments={!hideViewDocs && showListActions ? onViewRelatedDocuments : undefined}
          logo={
            !!entity?.type ? (
              <div className={classes.titleContainer}>
                {entity.type && (
                  <>
                    <EntityProfileLogo
                      id={entity.id.split("-")[0]}
                      entityType={entity.type}
                      className={classes.entityLogo}
                    />
                  </>
                )}
                {tooltipType === TooltipType.Geo && (
                  <EntityProfileLogo
                    id={entity.id.split("-")[0]}
                    entityType={EntityType.Country}
                    className={classes.entityLogo}
                  />
                )}
              </div>
            ) : undefined
          }
          title={
            <Typography className={classes.title} variant="h3">
              {entity?.name}
            </Typography>
          }
          {...rest}
        >
          {children}
        </AnalyseTooltip>
      </span>
    </ClickAwayListener>
  );
}
