const QUERY_DEFAULTS = {
  language: "language",
} as const;

export type QueryDefault = keyof typeof QUERY_DEFAULTS;

export type QueryDefaults = Record<QueryDefault, string>;

export const UNASSIGNED_VALUE = "UNASSIGNED";

export const transformQueryDefaults = (query: string, defaults: QueryDefaults): string =>
  Object.keys(defaults).reduce(
    (acc, curr) =>
      defaults[curr as QueryDefault] === UNASSIGNED_VALUE
        ? acc
        : `${acc
            .replace(new RegExp(`(^|\\s)${QUERY_DEFAULTS[curr as QueryDefault]}:\\S*(\\s|$)`, "gmi"), " ")
            .trim()} ${QUERY_DEFAULTS[curr as QueryDefault]}:"${defaults[curr as QueryDefault]}"`,
    query
  );
