import { createStyles, FormControl, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { zIndex } from "../../../../helpers/componentsZIndex";
import { useHarvestLibraryEntities } from "../../../../hooks/useCollectionConfigs";
import { LibraryEntity } from "../../../../http/harvesterCollections";
import { useEffect, useRef } from "react";

interface SelectEntityProps {
  organisationId?: string;
  libraryId?: string;
  setEntity: (entities: LibraryEntity | null) => void;
  amplyfiEntityId: string;
  sourceChanged: boolean;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    item: {
      alignItems: "baseline",
      display: "flex",
    },
    select: {
      width: "100%",
    },
    control: {
      marginBottom: spacing(2),
      marginTop: spacing(1),
    },
    label: {
      paddingBottom: spacing(1),
    },
    loading: {
      cursor: "wait",
    },
    placeholder: {
      fontStyle: "italic",
    },
  })
);

const REMOVE_ENTITY_LIST = ["news", "private"];
const ENTITY_PLACEHOLDER = "Select an entity…";
const NO_ENTITIES_PLACEHOLDER = "No entities available for this library";

export const SelectEntity = ({
  organisationId,
  sourceChanged,
  libraryId,
  setEntity,
  amplyfiEntityId,
}: SelectEntityProps): JSX.Element => {
  const classes = useStyles();
  const { data = [], isLoading } = useHarvestLibraryEntities(organisationId, libraryId);
  const placeholder = !!data.length || isLoading ? ENTITY_PLACEHOLDER : NO_ENTITIES_PLACEHOLDER;

  const entities = data
    .filter(({ amplyfiEntityId }) => !REMOVE_ENTITY_LIST.includes(amplyfiEntityId))
    .sort(({ name: a }, { name: b }) => a.localeCompare(b));

  const handleEntityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedEntity = entities.find((entity) => entity.amplyfiEntityId === event.target.value);
    setEntity(selectedEntity || null);
  };

  const amplyfiEntityExists = entities.some((entity) => entity.amplyfiEntityId === amplyfiEntityId);
  const prevLibraryId = useRef(libraryId);

  useEffect(() => {
    if (prevLibraryId.current !== libraryId) {
      setEntity(null);
    }
    prevLibraryId.current = libraryId;
  }, [libraryId, setEntity]);

  useEffect(() => {
    if (sourceChanged) {
      setEntity(null);
    }
  }, [sourceChanged, setEntity]);

  return (
    <FormControl fullWidth className={classes.control}>
      <Typography variant="h4" className={classes.label}>
        Entity
      </Typography>
      <Select
        MenuProps={{
          style: { zIndex: zIndex.selectMenu },
        }}
        className={clsx(classes.select, isLoading && classes.loading)}
        defaultValue={ENTITY_PLACEHOLDER}
        disabled={!libraryId || isLoading || !entities?.length}
        onChange={handleEntityChange}
        value={amplyfiEntityExists ? amplyfiEntityId : placeholder}
      >
        <MenuItem disabled value={placeholder} className={classes.placeholder}>
          {placeholder}
        </MenuItem>
        {entities.map(({ amplyfiEntityId, name }) => (
          <MenuItem key={amplyfiEntityId} value={amplyfiEntityId} className={classes.item}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
