import { testId } from "@amplyfi/ui-components/testHelpers";
import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Modal from "../../../Widgets/Misc/Modal";
import { HarvestMetadata } from "../../../../models/harvest";
import { LibraryItem } from "../../../../models/search/documentLibrary";
import { AccountGroup } from "../../../../models/account";
import { HarvestConfig } from "../models";
import { StepEnterContext } from "../CreateHarvest/StepEnterContext";
import { useStyles } from "../CreateHarvest/modalStyles";
import { StepEnterQuery } from "../CreateHarvest/StepEnterQuery";
import useHarvestConfig from "../../../../hooks/useHarvestConfig";
import { LibraryEntity } from "../../../../http/harvesterCollections";

interface CreateHarvestModalProps extends React.ComponentProps<typeof Modal> {
  refreshListing: () => void;
  preloadedOrganisations?: AccountGroup[] | null;
}

enum Step {
  enterContext,
  enterQuery,
}

const DEFAULT_LANGUAGE = "en";

export const CreateHarvestModal = ({
  preloadedOrganisations = null,
  onClose,
  open,
  refreshListing,
  ...rest
}: CreateHarvestModalProps): JSX.Element => {
  const { data: harvestConfigs } = useHarvestConfig();
  const [library, setLibrary] = useState<LibraryItem | null>(null);
  const [metadata, setMetadata] = useState<HarvestMetadata | null>(null);
  const [organisation, setOrganisation] = useState<AccountGroup | null>(null);
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const [harvestConfig, setHarvestConfig] = useState<HarvestConfig>();
  const [query, setQuery] = useState<string>("");
  const [step, setStep] = useState<Step>(Step.enterContext);
  const classes = useStyles();
  const [entity, setEntity] = useState<LibraryEntity | null>(null);

  const handleClose = () => {
    onClose?.({}, "escapeKeyDown");
  };

  useEffect(() => {
    if (harvestConfigs) {
      const [defaultConfig] = harvestConfigs;
      setHarvestConfig(defaultConfig);
      setQuery(defaultConfig.placeholder || "");
    }
  }, [harvestConfigs]);

  useEffect(() => {
    if (open) {
      if (harvestConfigs) {
        const [defaultConfig] = harvestConfigs;
        setHarvestConfig(defaultConfig);
      }
      setLanguage(DEFAULT_LANGUAGE);
      setLibrary(null);
      setMetadata(null);
      setOrganisation(null);
      setStep(Step.enterContext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setLibrary(null);
  }, [organisation]);

  useEffect(() => {
    setQuery(harvestConfig?.placeholder || "");
  }, [harvestConfig]);

  return (
    <Modal
      showCloseIcon
      data-testid={testId("harvest", "create-harvest")}
      maxWidth={false}
      onClose={handleClose}
      open={open}
      {...rest}
    >
      <div className={classes.title}>
        <Typography variant="h1">Create New Harvest</Typography>
      </div>

      {harvestConfig ? (
        <>
          {step === Step.enterContext && (
            <StepEnterContext
              organisation={organisation}
              setOrganisation={setOrganisation}
              library={library}
              setLibrary={setLibrary}
              harvestConfig={harvestConfig}
              setHarvestConfig={setHarvestConfig}
              onNext={() => {
                setMetadata(null);
                setStep(Step.enterQuery);
              }}
              amplyfiEntityId={entity?.amplyfiEntityId || ""}
              setEntity={setEntity}
            />
          )}
          {step === Step.enterQuery && !!library && !!organisation && (
            <StepEnterQuery
              language={language}
              library={library}
              organisation={organisation}
              setLanguage={setLanguage}
              harvestConfig={harvestConfig}
              metadata={metadata}
              query={query}
              setQuery={setQuery}
              onBack={() => {
                setStep(Step.enterContext);
              }}
              postHarvestCreate={() => {
                refreshListing();
                handleClose();
              }}
              setMetadata={setMetadata}
              entity={entity}
            />
          )}
        </>
      ) : (
        "loading"
      )}
    </Modal>
  );
};
