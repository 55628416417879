import ProfileMenu, { AiUser } from "@amplyfi/ui-components/components/ProfileMenu";
import Tooltip from "@amplyfi/ui-components/components/Tooltip";
import { LinkBlueElectric, PickledBluewood } from "@amplyfi/ui-components/theme/colors";
import { IconButton, List, ListItem, Theme, createStyles, makeStyles } from "@material-ui/core";
import { BarChart, ChatBubbleOutline, FindInPage, MoreHoriz, NoteAdd, RssFeed, Settings } from "@material-ui/icons";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import appUrl from "../../../appUrl";
import AmplyfiLogo from "../../../assets/amplyfi-mini-logo.svg";
import { ReactComponent as EntityKnowledgeBaseIcon } from "../../../assets/entity-knowledge-base.svg";
import { link } from "../../../css/mixins";
import { useHeaderMenuStyles } from "../../../css/useHeaderMenuStyles";
import { MENU_ITEM_HEIGHT, useLeftPanelStyling } from "../../../css/useLeftPanelStyling";
import { saveContent } from "../../../helpers/analysisHelper";
import { zIndex } from "../../../helpers/componentsZIndex";
import useSavedAnalysis from "../../../hooks/useSavedAnalysis";
import { useAnalyseSelector } from "../../../store/reducers";
import { updateSavePending } from "../../../store/reducers/searchRequest/urlReducer";
import { AmplyfiLink } from "../../AmplyfiLink";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import { canUserReadAnalysis } from "../../Pages/Analyse/permissions";
import { canUserReadGenerativeResearch, canUserReadResearch } from "../../Pages/DeepKnowledge/permissions";
import { goToEntityKnowledgeBase } from "../../Pages/EntityKnowledgeBase/Routing";
import { canUserUpdateEntityKnowledgeBase } from "../../Pages/EntityKnowledgeBase/permissions";
import { goToFeed } from "../../Pages/Feed/Routing";
import { canUserReadFeed } from "../../Pages/Feed/permissions";
import { canUserReadHarvest } from "../../Pages/Harvest/permissions";
import SimpleDialog from "../Dialog/SimpleDialog";
import CreateAnalysisDialog from "../SavedAnalysis/CreateAnalysisDialog";

const classIndex = {
  zIndex: `${zIndex.modalAndMenu}!important`,
} as unknown as React.CSSProperties;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      ...link(theme),
    },
    collapsedMainNavs: {
      marginBottom: MENU_ITEM_HEIGHT,
      padding: 0,
      flex: "1 1 auto",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "flex-start",
    },
    collapsedLink: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    selectedCollapsedListItem: {
      borderLeft: `3px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.accent.main,
      "& svg": {
        fill: `${theme.palette.primary.main} !important`,
      },
    },
    pageIcons: {
      height: 24,
      width: 24,
    },
    collapseBar: (props: { open: boolean }) => ({
      height: "100%",
      width: 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRight: `1px solid ${theme.palette.borders.main}`,
      cursor: "pointer",
      "& svg": {
        color: PickledBluewood[100],
        transform: `rotate(${props.open ? 0 : 180}deg)`,
      },
    }),
    collapsedPopovers: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
    },
    popoverListItem: {
      display: "flex",
      margin: theme.spacing(1, 0),
      paddingRight: 0,
      justifyContent: "center",
      cursor: "pointer",
      "& svg": {
        fill: theme.palette.appBackground.light,
        fontSize: 18,
      },
    },
    popoverContainer: {
      padding: theme.spacing(2),
    },
    popoverTitle: {
      color: theme.palette.appBackground.light,
    },
    logoContainer: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    amplyfiLogo: {
      height: 20,
      width: 20,
    },
    settings: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: 20,
    },
    tooltip: {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: "bold",
    },
    popover: {
      zIndex: classIndex.zIndex,
    },
    betaLogo: {
      color: LinkBlueElectric,
      position: "absolute",
      top: "8px",
      right: "8px",
      fontWeight: 700,
      fontSize: "10px",
      opacity: "50%",
    },
    betaLogoSelected: {
      opacity: "100%",
    },
    endActions: {
      marginTop: "auto",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    desktopTab: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    mobileTab: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

interface LeftPanelCollapsedProps {
  updateUrls: () => void;
  analyseTabActive: boolean;
  researchTabActive: boolean;
  knowledgeTabActive: boolean;
  storedAnalyseUrl: string;
  width?: number;
}

export default function LeftPanelCollapsed(props: LeftPanelCollapsedProps): JSX.Element {
  // eslint-disable-next-line
  const { knowledgeTabActive, analyseTabActive, researchTabActive, storedAnalyseUrl, updateUrls, width } = props;
  const { user, logout } = useAuth0();
  const history = useHistory();
  const classes = useStyles({ open: true });
  const leftPanelClasses = useLeftPanelStyling({ width });
  const headerStyles = useHeaderMenuStyles();
  const savePending = useAnalyseSelector((x) => x.searchRequest.url.savePending);
  const dispatch = useDispatch();
  const [savePromptEnabled, setSavePromptEnabled] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const { savedAnalysisId } = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const { data, refetch } = useSavedAnalysis(savedAnalysisId);

  const feedMatch = useRouteMatch({ path: [goToFeed(), `${goToFeed()}/*`] });
  const feedActive = feedMatch;

  const settingsMatch = useRouteMatch({ path: ["/manage/*", "/manage/"] });
  const settingsActive = settingsMatch?.isExact;
  const { feedGenerativeResearch } = useFlags();

  function handleNavigation(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, redirectTo: string) {
    if (savePending) {
      event.preventDefault();
      setRedirectUrl(redirectTo);
      setSavePromptEnabled(true);
      return;
    }
    updateUrls();
  }

  async function handleSave() {
    if (data?.name) {
      await saveContent(savedAnalysisId ?? "");
      refetch();
    } else {
      setCreateDialogOpen(true);
    }
    dispatch(updateSavePending(false));
  }

  const onConfirmAnalysisWarning = () => {
    handleSave();
    setSavePromptEnabled(false);
    if (data?.name) {
      dispatch(updateSavePending(false));
      updateUrls();
      if (redirectUrl) {
        history.push(redirectUrl);
      }
    }
  };

  return (
    <div className={leftPanelClasses.menuContainer}>
      <List className={classes.collapsedMainNavs}>
        <ListItem className={clsx(leftPanelClasses.menuItem, classes.logoContainer)}>
          <img src={AmplyfiLogo} alt="" className={classes.amplyfiLogo} />
        </ListItem>
        {canUserReadFeed(user) && (
          <>
            <Tooltip arrow title="Feed" placement="right" classes={{ tooltip: classes.tooltip }}>
              <ListItem
                disableGutters
                className={clsx(leftPanelClasses.menuItem, feedActive && leftPanelClasses.selectedMenuItem)}
              >
                <AmplyfiLink onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.location.href = "https://app.amplyfi.com";
                }} to="/feed" className={classes.collapsedLink}>
                  <RssFeed className={classes.pageIcons} />
                </AmplyfiLink>
              </ListItem>
            </Tooltip>
          </>
        )}
        {feedGenerativeResearch && canUserReadGenerativeResearch(user) && (
          <>
            <Tooltip arrow title="Research" placement="right" classes={{ tooltip: classes.tooltip }}>
              <ListItem
                disableGutters
                className={clsx(
                  leftPanelClasses.menuItem,
                  researchTabActive && leftPanelClasses.selectedMenuItem,
                )}
              >
                <AmplyfiLink
                  onClick={(e) => handleNavigation(e, "/generative-research")}
                  to="/generative-research"
                  className={classes.collapsedLink}
                >
                  <ChatBubbleOutline className={classes.pageIcons} />
                </AmplyfiLink>
              </ListItem>
            </Tooltip>
          </>
        )}
        {canUserReadResearch(user) && (
          <>
            <Tooltip arrow title="Research" placement="right" classes={{ tooltip: classes.tooltip }}>
              <ListItem
                disableGutters
                className={clsx(
                  leftPanelClasses.menuItem,
                  knowledgeTabActive && leftPanelClasses.selectedMenuItem,
                  classes.desktopTab
                )}
              >
                <AmplyfiLink
                  onClick={(e) => handleNavigation(e, "/research")}
                  to="/research"
                  className={classes.collapsedLink}
                >
                  <FindInPage className={classes.pageIcons} />
                </AmplyfiLink>
              </ListItem>
            </Tooltip>
          </>
        )}
        {canUserReadAnalysis(user) && (
          <Tooltip arrow title="Analysis" placement="right" classes={{ tooltip: classes.tooltip }}>
            <ListItem
              disableGutters
              className={clsx(
                leftPanelClasses.menuItem,
                analyseTabActive && leftPanelClasses.selectedMenuItem,
                classes.desktopTab
              )}
            >
              <AmplyfiLink
                onClick={(e) =>
                  handleNavigation(e, analyseTabActive ? "/analyse" + window.location.search : storedAnalyseUrl)
                }
                to={analyseTabActive ? "/analyse" + window.location.search : storedAnalyseUrl}
                className={classes.collapsedLink}
              >
                <BarChart className={classes.pageIcons} />
              </AmplyfiLink>
            </ListItem>
          </Tooltip>
        )}
        <Tooltip arrow title="More" placement="right" classes={{ tooltip: classes.tooltip }}>
          <ListItem
            disableGutters
            className={clsx(
              leftPanelClasses.menuItem,
              settingsActive && leftPanelClasses.selectedMenuItem,
              classes.mobileTab
            )}
          >
            <AmplyfiLink onClick={(e) => handleNavigation(e, "/manage")} to="/manage" className={classes.collapsedLink}>
              <MoreHoriz className={classes.pageIcons} />
            </AmplyfiLink>
          </ListItem>
        </Tooltip>
      </List>
      <div className={classes.endActions}>
        <div style={{ marginTop: "auto", textAlign: "center" }}>
          {canUserUpdateEntityKnowledgeBase(user) && (
            <Tooltip arrow title="Entity catalogue" placement="right" classes={{ tooltip: classes.tooltip }}>
              <AmplyfiLink
                onClick={(e) => handleNavigation(e, goToEntityKnowledgeBase())}
                to={goToEntityKnowledgeBase()}
              >
                <IconButton>
                  <EntityKnowledgeBaseIcon />
                </IconButton>
              </AmplyfiLink>
            </Tooltip>
          )}
          {canUserReadHarvest(user) && (
            <Tooltip arrow title="Harvest automation" placement="right" classes={{ tooltip: classes.tooltip }}>
              <AmplyfiLink onClick={(e) => handleNavigation(e, "/harvest")} to="/harvest">
                <IconButton>
                  <NoteAdd />
                </IconButton>
              </AmplyfiLink>
            </Tooltip>
          )}
          <Tooltip arrow title="User settings" placement="right" classes={{ tooltip: classes.tooltip }}>
            <AmplyfiLink onClick={(e) => handleNavigation(e, "/manage/profile")} to="/manage/profile">
              <IconButton>
                <Settings />
              </IconButton>
            </AmplyfiLink>
          </Tooltip>
          <ProfileMenu
            user={{ ...user, logout: () => logout({ returnTo: appUrl }) } as AiUser}
            key="person"
            useInitialsIcon
            iconClassName={headerStyles.headerIcon}
            popoverClassName={classes.popover}
            accountLink="/manage/profile"
          />
        </div>
        {savePromptEnabled && (
          <SimpleDialog
            title="Warning: Analysis Not Saved"
            content="Leaving this screen will mean your Analysis is lost. Are you sure you want to proceed?"
            onClose={() => {
              setSavePromptEnabled(false);
              dispatch(updateSavePending(false));
              if (redirectUrl) {
                history.push(redirectUrl);
              }
            }}
            primary="Save Analysis"
            secondary="Don't save"
            onConfirm={onConfirmAnalysisWarning}
          />
        )}
        {createDialogOpen && (
          <CreateAnalysisDialog
            onClose={() => {
              setCreateDialogOpen(false);
              if (redirectUrl) {
                history.push(redirectUrl);
              }
            }}
            redirectUrl={redirectUrl ?? undefined}
          />
        )}
      </div>
    </div>
  );
}
