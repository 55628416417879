import axios, { AxiosError, AxiosInstance } from "axios";
import {
  DocumentPreview,
  Harvest,
  HarvestItem,
  HarvestMetadata,
  HarvestMetadataDetail,
  RejectionReasons,
} from "../models/harvest";
import { LibraryItem } from "../models/search/documentLibrary";
import { HarvestConfig } from "../components/Pages/Harvest/models";
import { Organisation } from "../models/user";
import { LibraryEntity } from "./harvesterCollections";

export const harvestClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HARVESTING_API,
});

export const unauthenticatedClient: AxiosInstance = axios.create();

interface CreateHarvestResponse {
  details: string;
}

export async function createHarvest(
  { id: organisationId, name: organisationName }: Organisation,
  { id: libraryId, name: libraryName }: LibraryItem,
  entity: LibraryEntity | null,
  query: string,
  source: string,
  hits: number,
  maxResults: number,
  customerRequest: boolean,
  generateSignals: boolean
): Promise<CreateHarvestResponse> {
  const amplyfi_entity_id = entity?.amplyfiEntityId || null;
  const { data } = await harvestClient.post<CreateHarvestResponse>("/harvest", {
    customerRequest,
    hits,
    libraryId,
    libraryName,
    amplyfi_entity_id,
    maxResults,
    organisationId,
    organisationName,
    query,
    source,
    generateSignals,
  });
  return data;
}

interface MetadataResponse {
  detail?: HarvestMetadataDetail;
  hits?: number;
  documentPreviews?: DocumentPreview[];
}

const handleResponse = ({ detail, hits, documentPreviews }: MetadataResponse = {}) => ({
  detail,
  documentPreviews,
  count: hits || 0,
  isValid: hits !== undefined,
});

export async function validateHarvest(query: string, source: string): Promise<HarvestMetadata> {
  try {
    const { data } = await harvestClient.post<MetadataResponse>("/preview", {
      query,
      source,
    });
    return handleResponse(data);
  } catch (err) {
    const { response: { data } = {} } = err as AxiosError<MetadataResponse>;
    return handleResponse(data);
  }
}

interface HarvestsResponse {
  count: number;
  harvests: Harvest[];
}

export async function getHarvests(): Promise<HarvestsResponse["harvests"]> {
  const { data } = await harvestClient.get<HarvestsResponse>("/harvests");

  return data.harvests;
}

export async function getOrganisationHarvests(organisationId: string): Promise<HarvestsResponse["harvests"]> {
  const { data } = await harvestClient.get<HarvestsResponse>(`/harvests/${organisationId}`);

  return data.harvests;
}

interface HarvestResponse {
  item: HarvestItem;
}

export async function getHarvest(organisationId: string, harvestId: string): Promise<HarvestResponse["item"]> {
  const { data } = await harvestClient.get<HarvestResponse>(`/harvest/${organisationId}/${harvestId}`);

  return data.item;
}

interface DocumentStatusResponse {
  failed: number;
  rejected: number;
  inProgress: number;
  succeeded: number;
  rejectionReasons?: RejectionReasons;
}

export async function getDocumentStatuses(harvestId: string): Promise<DocumentStatusResponse> {
  const { data } = await harvestClient.get(`/pipeline-status/${harvestId}`);
  return data;
}

interface HarvestStatusResponse {
  count: number;
  harvests: Harvest[];
}

export async function getHarvestStatus(orgId: string, libId: string): Promise<HarvestStatusResponse> {
  const { data } = await harvestClient.get<HarvestStatusResponse>("/v2/harvests", {
    params: {
      libId,
      orgId,
      status: "processing,harvesting,queued",
    },
  });
  return data;
}

interface HarvestUrlResponse {
  formData: {
    fields: {
      key: string;
      policy: string;
      signature: string;
      "x-amz-security-token": string;
      AWSAccessKeyId: string;
    };
    url: string;
  };
  location: string;
}

export async function getHarvestUrl() {
  const { data } = await harvestClient.get<HarvestUrlResponse>("/v2/harvests/upload-url");
  return data;
}

export async function getHarvestConfig() {
  const { data } = await harvestClient.get<HarvestConfig[]>("/configuration/harvesting");
  return data;
}
