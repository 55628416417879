import { UseQueryResult, useQuery } from "../../useQuery";
import { Report } from "../../../components/Pages/Feed/components/reader/create_report/CreateReportWizard";
import { getEntityReports, getLibraryReports, getReport, getSubscribedLibraryReports } from "../../../http/report";
import { useQueryClient } from "react-query";

type ReportFormat = "md" | "pdf";

export const reportsKey = "reports";

export default function useReports(libraryId: string | null, amplyfiEntityId: string | null) {
  return useQuery(
    [reportsKey, libraryId, amplyfiEntityId],
    async () => {
      if (!libraryId) {
        return getSubscribedLibraryReports();
      }
      return amplyfiEntityId ? getEntityReports(libraryId, amplyfiEntityId) : getLibraryReports(libraryId);
    },
    {
      refetchInterval(data) {
        //refresh every 10 seconds if there are reports in progress
        return data && data.some((d) => d.reportType !== "Done") ? 1000 * 10 : false;
      },
    }
  );
}

export const useRefreshReports = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(reportsKey);
  };
};

function useReport(
  libraryId: string,
  amplyfiEntityId: string,
  reportId: string,
  format: ReportFormat = "md"
): UseQueryResult<Report> {
  return useQuery(["single-report", libraryId, amplyfiEntityId, reportId, format], async () => {
    if (!reportId) {
      return undefined;
    }
    return getReport(libraryId, amplyfiEntityId, reportId, format);
  });
}

export function useDisplayableReport(
  libraryId: string,
  amplyfiEntityId: string,
  reportId: string
): UseQueryResult<Report> {
  return useReport(libraryId, amplyfiEntityId, reportId, "md");
}

export function useDownloadableReport(
  libraryId: string,
  amplyfiEntityId: string,
  reportId: string
): UseQueryResult<Report> {
  return useReport(libraryId, amplyfiEntityId, reportId, "pdf");
}
