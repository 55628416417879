import { testId } from "@amplyfi/ui-components/testHelpers";
import { Switch } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";
import { useAnalyseSelector } from "../../../../store/reducers";
import { hasAnyQueriesSelector, resetQuery } from "../../../../store/reducers/searchRequest/urlReducer";
import BooleanQueryBuilder from "../../../Pages/Analyse/BooleanQueryBuilder";
import { usePageStyles } from "../../../Pages/useLandingStyles";
import StandardSearch from "../../Search/StandardSearch";
import Warning from "../BooleanQuery/Warning";

interface BuildQueryCardProps {
  setAdvanced: (advanced: boolean) => void;
  advanced: boolean;
  validated: boolean;
  setValidated: (validated: boolean) => void;
}

export default function BuildQueryCard(props: BuildQueryCardProps): JSX.Element {
  const { setAdvanced, advanced, validated, setValidated } = props;
  const classes = usePageStyles();
  const dispatch = useDispatch();
  const hasQueries = useAnalyseSelector(hasAnyQueriesSelector);
  return (
    <div className={classes.card}>
      <div className={classes.cardTitle}>
        <Typography variant="h3">Build your query</Typography>
        <div className={classes.cardRight}>
          <Warning />
          <div>
            <Switch
              onChange={() => {
                if (!hasQueries || window.confirm("Search will be lost, continue?")) {
                  setAdvanced(!advanced);
                  dispatch(resetQuery());
                }
              }}
              data-testid={testId("advanced-query")}
              checked={advanced}
            />
            <Typography className={classes.advancedLabel} component="label" variant="caption">
              Advanced
            </Typography>
          </div>
        </div>
      </div>
      <div className={clsx(classes.cardContent, classes.queryBuilder)}>
        {advanced ? <BooleanQueryBuilder validated={validated} setValidated={setValidated} /> : <StandardSearch />}
      </div>
    </div>
  );
}
