import axios from "axios";
import { CreateReportWizardState } from "../../../components/Pages/Feed/components/reader/create_report/CreateReportWizard";
import useAPI from "../api/useAPI";
import { useQuery } from "react-query";
import moment from "moment";
import useWizardState from "../../useWizardState";

export type ApiResponseQuestionVerification = {
  explanation: string | null;
  question_valid: boolean | null;
  verdict: string | null;
};

export default function useVerifyQuestion(amplyfiEntityId: string, reportQuestion: string) {
  const [state] = useWizardState<CreateReportWizardState>("create-report");
  const api = useAPI("v5", `amplyfi-entities/${amplyfiEntityId}/report-verification`, {
    useLibrary: true,
    useOrganisation: false,
  });
  return useQuery(["verify-question", amplyfiEntityId, reportQuestion], async ({ signal }) => {
    if (reportQuestion.trim() === "") return undefined;
    const { data } = await axios.post<ApiResponseQuestionVerification>(
      api,
      {
        created: moment().utc().toISOString(),
        reportName: state.reportName as string,
        reportType: state.reportType as string,
        startDate: state.startDate,
        endDate: state.endDate,
        question: state.reportQuestion as string,
      },
      { signal }
    );
    return data;
  });
}
