import React from "react";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import AiButton from "@amplyfi/ui-components/components/Button";
import { toggleDrawerOpen } from "../../../store/reducers/searchRequest/filterReducer";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "& > *": {
        marginTop: theme.spacing(2),
        maxWidth: 200,
      },
    },
    warningIcon: {
      fontSize: 64,
    },
  })
);

export default function NoLibrarySelected(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.container}>
      <Typography variant="h2">No Data to Show</Typography>
      <WarningRounded className={classes.warningIcon} fontSize="large" color="error" />
      <Typography align="center" variant="h3">
        Please Select at Least 1 Library to Analyse
      </Typography>
      <AiButton onClick={() => dispatch(toggleDrawerOpen(true))}>Select Libraries</AiButton>
    </div>
  );
}
