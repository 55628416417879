import { Card, CardContent, Typography } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import React from "react";
import useGenerativeSummary from "../../../../../../hooks/useGenerativeSummary";
import useReaderStore from "../../../../../../store/hooks/useReaderStore";
import OverviewSection from "./OverviewSection";

export default function Summary() {
  const { selectedDocumentId } = useReaderStore((state) => state);
  const data = useGenerativeSummary(selectedDocumentId as string);

  return (
    <OverviewSection isLoading={data.length === 0} icon={<Language />} title="Summary">
      <Card
        elevation={0}
        style={{
          background: "#F7FAFC",
          cursor: "pointer",
          borderRadius: 6,
          height: "auto",
          overflow: "auto",
        }}
      >
        <CardContent>
          <Typography style={{ whiteSpace: "pre-line", lineHeight: 1.5, fontSize: 15, fontWeight: 400, color: "#004FD0" }} variant="body2">
            <ul>{data.split("- ").filter(i => i.trim().length > 0).map((d, i) => <li key={i}>{d}</li>)}</ul>
          </Typography>
        </CardContent>
      </Card>
    </OverviewSection>
  );
}
