import { idToWikiId } from "./alertHelper";

export const getEntityLogo = (entityId?: string | null): string | undefined =>
  entityId ? `${process.env.REACT_APP_AMPLYFI_LOGO_URL}/wikidata/${idToWikiId(entityId)}` : undefined;

export const getWebsiteLogo = (website?: string): string | undefined =>
  website ? `${process.env.REACT_APP_AMPLYFI_LOGO_URL}/website/${website}` : undefined;

export const getAmplyfiEntityLogo = (amplyfiEntityId?: string): string | undefined =>
  amplyfiEntityId ? `${process.env.REACT_APP_AMPLYFI_LOGO_URL}/entity/${amplyfiEntityId}` : undefined;

export const getDocumentLogo = (documentId: string, crop = true): string =>
  `${process.env.REACT_APP_AMPLYFI_LOGO_URL}/document/${documentId}?cropped=${crop}`;
