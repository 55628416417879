import AnalyseButton from "@amplyfi/ui-components/components/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { link } from "../../../css/mixins";
import { analyticsEvent } from "../../../helpers/analytics";
import { logError } from "../../../helpers/logger";
import { createAnalysis } from "../../../http/saved-analyses";
import { updateSelectedAnalysis } from "../../../store/reducers/searchRequest/urlReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: "300px",
      [theme.breakpoints.up("sm")]: {
        minWidth: "500px",
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.borders.main}`,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    content: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    label: {
      marginBottom: theme.spacing(),
    },
    textField: {
      width: "100%",
      marginBottom: theme.spacing(),
    },
    link: {
      ...link(theme),
    },
    cancelButton: {
      marginLeft: "auto",
      marginRight: theme.spacing(),
    },
    actions: {
      display: "flex",
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderTop: `1px solid ${theme.palette.borders.main}`,
      marginTop: theme.spacing(2),
    },
  })
);

interface CreateAnalysisDialogProps {
  onClose: () => void;
  avoidRedirection?: boolean;
  redirectUrl?: string;
}

export default function CreateAnalysisDialog(props: CreateAnalysisDialogProps): JSX.Element {
  const classes = useStyles();
  const { onClose, avoidRedirection, redirectUrl } = props;
  const [searchName, setSearchName] = useState<string>("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function create() {
    setLoading(true);
    try {
      const newSearch = await createAnalysis(searchName, window.location.search.slice(1));
      dispatch(updateSelectedAnalysis(newSearch));
      analyticsEvent("SearchOptions", "AnalysisSaved", `${searchName}`);
      if (!avoidRedirection && !!redirectUrl) {
        history.push(redirectUrl);
      } else if (!avoidRedirection && !redirectUrl) {
        history.push({
          pathname: "/analyse/landing",
          search: window.location.search,
        });
      }
      onClose();
    } catch (err) {
      logError(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog disableScrollLock id="save-search-popover" open onClose={onClose}>
      <DialogTitle>
        <Typography variant="h2">Create Analysis</Typography>
      </DialogTitle>
      <DialogContent style={{ minWidth: 600 }}>
        <Typography className={classes.label} component="p">
          Analysis name
        </Typography>
        <TextField
          className={classes.textField}
          value={searchName}
          onChange={(e) => {
            setSearchName(e.target.value);
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          placeholder={"E.g. Finance south west"}
          data-testid="save_search_text_field"
        />
      </DialogContent>
      <DialogActions>
        <AnalyseButton
          data-testid="save-search-cancel-button"
          disabled={loading}
          className={classes.cancelButton}
          onClick={onClose}
          amplyfiType="secondary"
        >
          Cancel
        </AnalyseButton>
        <AnalyseButton onClick={create} disabled={loading || !searchName.trim()}>
          Create
        </AnalyseButton>
      </DialogActions>
    </Dialog>
  );
}
