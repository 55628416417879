import EntityTooltip from "@amplyfi/ui-components/components/Tooltip/EntityTooltip";
import { Silver } from "@amplyfi/ui-components/theme/colors";
import { ClickAwayListener, createStyles, makeStyles, Theme } from "@material-ui/core";
import { AxisProps } from "@nivo/axes";
import { Datum, Point, ResponsiveLine, Serie } from "@nivo/line";
import moment, { Moment, unitOfTime } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FullScreenHandle } from "react-full-screen";
import { useDispatch } from "react-redux";
import {
  convertTimelineToNivoData,
  DOCUMENT_TIMELINE_HEIGHT,
  timePeriodFormatMap,
  timePeriodTickValuesMap,
} from "../../../helpers/nivo";
import { gridLines, TimeIntervalType } from "../../../helpers/timeIntervals";
import { TimePeriod } from "../../../models/chart";
import { Entity } from "../../../models/entity";
import { DocumentType, SearchResponse } from "../../../models/search";
import { SmoothingType } from "../../../store/reducers/searchResponse/documentTimelineReducer";
import {
  onSetOpenCloseDrawer,
  viewRelatedDocuments,
} from "../../../store/reducers/searchResponse/relatedDocumentViewerReducer";
import { formatToPercentage } from "../helpers/scale";

interface DocumentTimelineProps {
  documentsTimeline?: SearchResponse;
  period?: TimePeriod;
  height?: number;
  interval?: TimeIntervalType;
  skewCorrection: boolean;
  smoothing: SmoothingType;
  fullScreenHandle?: FullScreenHandle;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      position: "relative",
      "& path:nth-of-type(even)": {
        strokeDasharray: (props: DocumentTimelineProps) => "0",
      },
    },
    tooltip: {
      position: "fixed",
    },
  })
);

const LEFT_MARGIN = 65;
const TOP_MARGIN = 25;

const timeIntervalsWithMoment: Record<TimeIntervalType, unitOfTime.StartOf> = {
  Year: "year",
  Quarter: "quarter",
  Month: "month",
  Week: "isoWeek",
  Day: "day",
};

export default function DocumentTimeline(props: DocumentTimelineProps): JSX.Element {
  const {
    documentsTimeline,
    period = "5years",
    height = DOCUMENT_TIMELINE_HEIGHT,
    interval = "Month",
    skewCorrection,
    smoothing,
    fullScreenHandle,
  } = props;
  const datasets: Record<DocumentType.News, SearchResponse | undefined> = {
    [DocumentType.News]: documentsTimeline,
  };
  const nivoLineContainer = React.useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const [tooltipDataPoint, setTooltipDataPoint] = useState({ x: 0, y: 0 });
  const areaRef = useRef(null);

  const { nivoColours, timeline, originalTimeline } = Object.entries(datasets)
    .filter(([, value]) => value !== undefined && value.timeline.length > 0)
    .reduce<{ nivoColours: string[]; timeline: Serie[]; originalTimeline: Serie[] }>(
      (prev, [key, value]) => {
        const docType = key as unknown as DocumentType;
        const response = value as SearchResponse;
        const { nivoDatasets, nivoColours } = convertTimelineToNivoData(docType, response.timeline, skewCorrection);
        const originalTimeline = convertTimelineToNivoData(docType, response.timeline, false).nivoDatasets;

        return {
          nivoColours: [...prev.nivoColours, ...nivoColours],
          timeline: [...prev.timeline, ...nivoDatasets],
          originalTimeline: [...prev.originalTimeline, ...originalTimeline],
        };
      },
      { nivoColours: [], timeline: [], originalTimeline: [] }
    );
  const classes = useStyles(props);
  const [selectedPoint, setSelectedPoint] = useState<Point | null>(null);

  const everyN = gridLines[interval ?? "Month"];
  const longestTimeline = timeline.reduce<Datum[]>(
    (prev, next) => (next.data.length > prev.length ? next.data : prev),
    []
  );
  const gridXValues = longestTimeline.reduce<string[]>((prev, next, index) => {
    if (index % everyN === 0) {
      return [...prev, next.x as string];
    }
    return prev;
  }, []);

  const key = { documentsTimeline, period, interval, skewCorrection, smoothing };

  const getDocumentCount = () => {
    let documentCount = 0;
    if (skewCorrection && selectedPoint) {
      const originalDataset = originalTimeline.find((x) => x.id === selectedPoint.serieId);
      if (originalDataset) {
        const originalDatum = originalDataset.data.find((x) => x.x === selectedPoint.data.xFormatted);
        if (originalDatum) {
          documentCount = Number(originalDatum?.y?.toString()) ?? 0;
        }
      }
    } else if (selectedPoint) {
      documentCount = Number(selectedPoint.data.yFormatted);
    }
    return documentCount;
  };

  const exitFullScreen = () => {
    if (fullScreenHandle?.active) {
      fullScreenHandle.exit();
    }
  };

  const getDatesOnIntervals = (selectedDate: Moment): { startDate?: number; endDate?: number } => {
    return {
      startDate: selectedDate.startOf(timeIntervalsWithMoment[interval]).valueOf(),
      endDate: selectedDate.endOf(timeIntervalsWithMoment[interval]).valueOf(),
    };
  };

  const onViewRelatedDocuments = async (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (selectedPoint) {
      const selectedDate = selectedPoint.data.xFormatted ? moment.utc(selectedPoint.data.xFormatted) : undefined;
      const dates = selectedDate ? getDatesOnIntervals(selectedDate) : null;

      dispatch(
        viewRelatedDocuments({
          startDate: dates?.startDate,
          endDate: dates?.endDate,
          type: DocumentType.News,
          entities: [
            {
              name: moment(selectedPoint.data.xFormatted).format("DD/MM/YYYY"),
            },
          ] as Entity[],
        })
      );
      dispatch(onSetOpenCloseDrawer(true));
      exitFullScreen();
    }
  };

  useEffect(() => {
    const onHide = (e: Event) => {
      setSelectedPoint(null);
    };
    window.addEventListener("resize", onHide);
    window.addEventListener("scroll", onHide);

    return () => {
      window.removeEventListener("resize", onHide);
      window.removeEventListener("scroll", onHide);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setSelectedPoint(null)}>
      <div
        style={{ height: fullScreenHandle?.active ? "75vh" : height }}
        className={classes.wrapper}
        ref={nivoLineContainer}
        onClick={() => setSelectedPoint(null)}
      >
        <ResponsiveLine
          key={JSON.stringify(key)}
          data={timeline}
          margin={{ top: TOP_MARGIN, bottom: 90, left: LEFT_MARGIN, right: 65 }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: true,
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            reverse: false,
            stacked: false,
          }}
          yFormat=""
          axisTop={null}
          axisRight={null}
          axisBottom={{
            legendPosition: "end",
            tickSize: 0,
            tickPadding: 5,
            tickRotation: -45,
            format: timePeriodFormatMap[period],
            tickValues: timePeriodTickValuesMap[period],
          }}
          curve={smoothing}
          axisLeft={getLeftAxis()}
          enablePoints={false}
          enableGridX={true}
          enableGridY={true}
          gridXValues={gridXValues.length}
          gridYValues={5}
          useMesh={true}
          colors={nivoColours}
          tooltip={(input, a) => {
            if (input.point.x !== tooltipDataPoint.x && input.point.y !== tooltipDataPoint.y) {
              setTooltipDataPoint({ x: input.point.x, y: input.point.y });
            }
            return <div></div>;
          }}
          // TODO: Pull out theme
          theme={{
            fontFamily: "Gordita",
            grid: {
              line: {
                stroke: Silver,
                opacity: 0.4,
              },
            },
          }}
          layers={["grid", "markers", "axes", "areas", "crosshair", "lines", "points", "slices", "mesh", "legends"]}
          onClick={(point, event) => {
            event.persist();
            event.stopPropagation();
            if (nivoLineContainer.current) {
              // Calulating postion relative to window screen and nivo/line
              const timelineRect = nivoLineContainer.current.getBoundingClientRect();
              const tempo = { ...point };
              tempo.x = tooltipDataPoint.x + timelineRect.x + LEFT_MARGIN;
              tempo.y = tooltipDataPoint.y + timelineRect.y + TOP_MARGIN;
              setSelectedPoint(tempo);
            }
          }}
        />

        {selectedPoint && (
          <EntityTooltip
            title={moment(selectedPoint?.data?.xFormatted).format("DD/MM/YYYY")}
            filterType="details"
            tooltip={{
              name: moment(selectedPoint?.data?.xFormatted).format("DD/MM/YYYY"),
              documentsCount: getDocumentCount(),
              mentionsCount: 0,
              description: "",
            }}
            PopperProps={{
              container: fullScreenHandle?.active ? nivoLineContainer.current : undefined,
              popperOptions: {
                positionFixed: true,
                modifiers: {
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: nivoLineContainer.current,
                  },
                },
              },
              anchorEl: {
                clientWidth: nivoLineContainer.current ? nivoLineContainer.current.getBoundingClientRect().width : 0,
                clientHeight: nivoLineContainer.current ? nivoLineContainer.current.getBoundingClientRect().height : 0,
                getBoundingClientRect: () => {
                  return new DOMRect(selectedPoint.x, selectedPoint.y + 5, 0, 0);
                },
              },
            }}
            onViewRelatedDocuments={onViewRelatedDocuments}
            open={!!selectedPoint}
          >
            <div ref={areaRef}></div>
          </EntityTooltip>
        )}
      </div>
    </ClickAwayListener>
  );

  function getLeftAxis(): AxisProps<number> {
    const presets = { tickSize: 0, tickValues: 4 };
    if (skewCorrection) {
      return {
        ...presets,
        format: formatToPercentage,
      };
    }
    return presets;
  }
}
