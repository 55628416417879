import axios from "axios";
import { enableStatus } from "../helpers/axios";
import { AlertType } from "../models/monitoring";
import { LibraryItem, LibraryItemSettings } from "../models/search/documentLibrary";

const apiUrl = `${process.env.REACT_APP_ANALYSE_API}/v2`;

export interface LibraryAlert {
  alertId?: string;
  alertType?: AlertType;
  alertTypeId?: string | null;
  amplyfiEntityId: string;
  created?: string;
  libraryId?: string;
  mentionedLocations: string[];
  subscribedGroupIds?: string[];
  subscribedUserIds?: string[];
  topics: string[];
  updated?: string;
  websites?: string[];
}

export async function getAlert(libraryId: string, alertId: string): Promise<LibraryAlert> {
  const { data } = await axios.get<LibraryAlert>(`${apiUrl}/libraries/${libraryId}/alerts/${alertId}`);
  return data;
}

export async function addEntityToLibrary(organisationId: string, libraryId: string, entityId: string): Promise<void> {
  await axios.put(`${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/amplyfi-entities/${entityId}`);
}

export async function removeEntityFromLibrary(
  organisationId: string,
  libraryId: string,
  entityId: string
): Promise<void> {
  await axios.delete(`${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/amplyfi-entities/${entityId}`);
}

export async function editLibrarySettings(
  organisationId: string,
  libraryId: string,
  settings: LibraryItemSettings
): Promise<string | undefined> {
  const { data, status } = await axios.put<string | undefined>(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/email-settings`,
    settings,
    {
      validateStatus: enableStatus(400, 404),
    }
  );
  return status === 404 ? "Library not found" : data;
}

export async function getAlertTypeUsage(
  organisationId: string,
  alertId: string
): Promise<{ count: number; libraries: LibraryItem[] }> {
  const { data } = await axios.get<{ count: number; libraries: LibraryItem[] }>(
    `${apiUrl}/organisations/${organisationId}/libraries`,
    {
      params: { alertTypeId: alertId },
    }
  );
  return data;
}

export async function getLibrariesBySourceConfig(
  organisationId: string,
  configId: string
): Promise<{ count: number; libraries: LibraryItem[] }> {
  const { data } = await axios.get<{ count: number; libraries: LibraryItem[] }>(
    `${apiUrl}/organisations/${organisationId}/libraries`,
    {
      params: { collectionConfigId: configId },
    }
  );
  return data;
}
