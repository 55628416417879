import { createStyles, Container, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { ViewHarvestsCard } from "./Cards/ViewHarvestsCard";
import { CONTAINER_WIDTH } from "../useLandingStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_WIDTH,
      margin: "0 auto",
    },
    content: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function HarvestPage(): JSX.Element {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <div className={classes.container}>
        <ViewHarvestsCard />
      </div>
    </Container>
  );
}
