import axios, { CancelTokenSource } from "axios";
import { EntityType } from "../models/entity";
import { FilterResponse } from "../models/filter";
import { LibrariesType } from "../models/queryParams";
import { AutocompleteResponse, SearchRequestV2 } from "../models/search";

export function getV2Url(endpoint: string, docType: LibrariesType[] | null = null): string {
  const baseUrl = `${process.env.REACT_APP_ANALYSE_API}/v2/${endpoint}`;
  const docString = typeof docType === "string" || docType === null ? docType : docType.join("&documentType=");
  return docType ? baseUrl.concat(`?documentType=${docString}`) : baseUrl;
}

export function getAnalysisV2Url(endpoint: string, docType: LibrariesType[] | null = null): string {
  const baseUrl = `${process.env.REACT_APP_ANALYSIS_API}/v2/${endpoint}`;
  const docString = typeof docType === "string" || docType === null ? docType : docType.join("&documentType=");
  return docType ? baseUrl.concat(`?documentType=${docString}`) : baseUrl;
}

export function getAnalysisV3Url(endpoint: string, docType: LibrariesType[] | null = null, searchTerm?: string): string {
  const baseUrl = `${process.env.REACT_APP_ANALYSIS_API}/v3/${endpoint}`;
  const docString = typeof docType === "string" || docType === null ? docType : docType.join("&documentType=");
  // logically, we could build a URL object an manipulate the search params, but because of our unconventional approach to multiple document types
  // it automatically URL encodes the ampersand and question mark.
  const docUrlString = docString ? `?documentType=${docString}` : "";
  const searchTermString = searchTerm ? `&searchTerm=${encodeURIComponent(searchTerm)}` : "";
  return `${baseUrl}${docUrlString}${searchTermString}`;
}

export async function searchFilters(searchRequest: SearchRequestV2, docType: LibrariesType[]): Promise<FilterResponse> {
  const searchResponse = await axios.post<FilterResponse>(getAnalysisV2Url("search/filters", docType), {
    ...searchRequest,
    systemFilters: {
      ...searchRequest.systemFilters,
      filterOptionsCount: 100,
    },
  });

  return searchResponse.data;
}

export async function autocompleteEntities(
  searchTerm: string,
  cancelToken: CancelTokenSource,
  docType: LibrariesType[],
  type?: EntityType
): Promise<AutocompleteResponse[]> {
  if (docType.length === 0) return Promise.resolve([]);
  const searchResponse = await axios.get<AutocompleteResponse[]>(
    getAnalysisV2Url(`autocomplete/entities/${encodeURIComponent(searchTerm)}`, docType),
    {
      params: {
        entityType: type,
      },
      cancelToken: cancelToken.token,
    }
  );

  return searchResponse.data;
}

export async function autocompleteCountries(
  searchTerm: string,
  cancelToken: CancelTokenSource
): Promise<AutocompleteResponse[]> {
  const searchResponse = await axios.get<AutocompleteResponse[]>(
    getAnalysisV2Url(`autocomplete/countries/${encodeURIComponent(searchTerm)}`),
    {
      cancelToken: cancelToken.token,
    }
  );

  return searchResponse.data;
}
