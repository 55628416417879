import { Entity } from "../models/entity";
import { useAnalyseSelector } from "../store/reducers";

export interface RelatedDocumentFilters {
  keywords: Entity[];
  keyPhrases: Entity[];
  news: {
    sectors: string[];
    subSectors: string[];
    activeInSectors: string[];
    maximumSentiment: number;
    minimumSentiment: number;
    publishLocations: string[];
    adverseMediaCategories: string[];
  };
}

export default function useRelatedDocumentFilters(): RelatedDocumentFilters {
  const {
    documentFilters: {
      keywordFilters,
      keyPhraseFilters,
      sectorsFilters,
      subSectorFilters,
      adverseMediaFilters,
      publishLocationsFilters,
      activeInFilters,
    },
    News: {
      documentFilters: { maximumSentiment, minimumSentiment },
    },
    dates: { startDate, endDate },
  } = useAnalyseSelector((x) => x.searchResponse.relatedDocumentViewer);

  return {
    keyPhrases: keyPhraseFilters,
    keywords: !startDate && !endDate ? keywordFilters : [],
    news: {
      sectors: sectorsFilters.map((x) => x.name),
      activeInSectors: activeInFilters.map((x) => x.name),
      subSectors: subSectorFilters.map((x) => x.name),
      maximumSentiment,
      minimumSentiment,
      publishLocations: publishLocationsFilters,
      adverseMediaCategories: adverseMediaFilters.map((x) => x.name),
    },
  };
}
