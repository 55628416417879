import { WarningOutlined as WarningIcon } from "@material-ui/icons";
import React from "react";
import { Validation } from "./Validation";
import { HarvestMetadataDetail } from "../../../../../models/harvest";

interface QueryInvalidProps {
  email: string;
  detail: HarvestMetadataDetail;
}

export const QueryInvalid = ({ detail: { /*htmlMessage,*/ message }, email }: QueryInvalidProps): JSX.Element => (
  <Validation
    icon={<WarningIcon color="error" />}
    title="Error in harvest query"
    messages={[
      /*
      ...(!!htmlMessage
        ? [
            <>
              Please correct <code dangerouslySetInnerHTML={{ __html: htmlMessage }} />
            </>,
          ]
        : []),
      */
      ...(!!message ? [<>API Error: {message}</>] : []),
      <>
        For help contact -{" "}
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          {email}
        </a>
      </>,
    ]}
  />
);
