import { useFlags } from "launchdarkly-react-client-sdk";
import { getHarvestConfig } from "../http/harvest";
import { useQuery } from "./useQuery";

export default function useHarvestConfig() {
  const flags = useFlags();
  return useQuery(
    ["harvest-config"],
    async () => {
      const harvestConfigs = await getHarvestConfig();
      return harvestConfigs.filter((c) => !c.featureFlag || !!flags[c.featureFlag]);
    },
    { staleTime: Infinity }
  );
}
