import { TestDataProps } from "@amplyfi/ui-components/types";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

interface RequestNewProps extends Partial<TestDataProps> {
  requestNew: () => void;
  text?: string;
}

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    action: {
      background: "none",
      border: 0,
      color: palette.links.primary,
      cursor: "pointer",
      margin: 0,
      padding: spacing(3),
      textAlign: "left",
      textDecoration: "underline",
      width: "100%",
    },
    requestNew: {
      backgroundColor: palette.action.hover,
    },
  })
);

export default function RequestNew(props: RequestNewProps): JSX.Element {
  const { "data-testid": dataTestId, requestNew, text = "Request a new organisation?" } = props;
  const classes = useStyles();

  return (
    <div className={classes.requestNew}>
      <button data-testid={dataTestId} className={classes.action} onClick={requestNew}>
        {text}
      </button>
    </div>
  );
}
