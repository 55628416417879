import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Close as CloseIcon } from "@material-ui/icons";
import { TestDataProps } from "@amplyfi/ui-components/types";
import { testId } from "@amplyfi/ui-components/testHelpers";
import React, { MouseEventHandler, useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AiButton from "@amplyfi/ui-components/components/Button";
import clsx from "clsx";
import { zIndex } from "../../../helpers/componentsZIndex";

import useScreenSize from "../../../hooks/useScreenSize";

export interface AbstractDialogProps {
  onClose: MouseEventHandler;
  onConfirm?: MouseEventHandler;
  onSecondary?: MouseEventHandler | null;
}

interface DialogStyleProps {
  dialogHeight?: number;
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  dialog: {
    height: (props: DialogStyleProps) => props.dialogHeight,
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 750,
    },
    "& .MuiDialog-paper": {
      overflow: "visible",
    },
    "& .MuiBackdrop-root": {
      [breakpoints.down("xs")]: {
        background: "black",
      },
    },
    [breakpoints.down("xs")]: {
      "& .MuiDialog-paper": {
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        margin: 0,
        padding: 0,
      },
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    flexGrow: 1,
  },
  actions: {
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    height: 80,
    "& > :first-child": {
      marginLeft: "auto",
    },
  },
  close: {
    alignSelf: "flex-start",
    marginLeft: spacing(2),
  },
  scrollbarPadding: {
    padding: `${spacing(1)}px 0 0 ${spacing(3)}px`,
  },
  noContentPadding: {
    "&:first-child": {
      padding: 0,
    },
  },
  closeOutsideContent: {
    position: "absolute",
    right: -spacing(2),
    top: -spacing(5),
    zIndex: 1000,
  },
  closeOutsideContentMobile: {
    marginRight: spacing(1),
    marginLeft: "auto",
  },
  content: {
    [breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

export interface SimpleDialogProps extends AbstractDialogProps, Partial<TestDataProps> {
  hideTitle?: boolean;
  title: string | JSX.Element;
  content?: string | JSX.Element | JSX.Element[] | React.ReactNode;
  showHideForever?: boolean;
  storageId?: string;
  primary?: string | null;
  secondary?: string | null;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  actions?: JSX.Element[] | JSX.Element;
  scrollbarPadding?: boolean;
  noContentPadding?: boolean;
  closeOnBackdropClick?: boolean;
  confirmIsAsync?: boolean;
  disabled?: boolean;
}

export default function SimpleDialog(props: SimpleDialogProps): JSX.Element {
  const {
    "data-testid": dataTestId,
    hideTitle = false,
    title,
    content,
    onClose,
    onConfirm,
    onSecondary = onClose,
    showHideForever,
    storageId,
    primary = "Confirm",
    secondary = "Cancel",
    maxWidth = "sm",
    actions,
    scrollbarPadding = false,
    noContentPadding = false,
    closeOnBackdropClick = true,
    confirmIsAsync = true,
  } = props;
  const [dialogHeight, setDialogHeight] = useState<number | undefined>(undefined);
  const classes = useStyles({ dialogHeight });
  const [hide, setHideForever] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const screenSize = useScreenSize();

  function confirm(e: React.MouseEvent<Element, MouseEvent>) {
    if (confirmIsAsync) {
      setConfirmClicked(true);
    }
    if (showHideForever) {
      if (hide && storageId) {
        localStorage.setItem(storageId, "true");
      }
    }

    onConfirm?.(e);
  }

  useEffect(() => {
    if (!confirmIsAsync) {
      setConfirmClicked(confirmIsAsync);
    }
  }, [confirmIsAsync]);

  useEffect(() => {
    const resizeHandler = () => {
      if (window.visualViewport) {
        setDialogHeight(window.visualViewport.height);
        window.scrollTo(0, 0);
      }
    };
    window.visualViewport?.addEventListener("resize", resizeHandler);
    return () => window.visualViewport?.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <Dialog
      className={classes.dialog}
      data-testid={testId(dataTestId)}
      maxWidth={maxWidth}
      fullWidth
      open
      onClose={(e, reason) => {
        if (closeOnBackdropClick || reason !== "backdropClick") {
          onClose(e as React.MouseEvent<Element, MouseEvent>);
        }
      }}
      style={{ zIndex: zIndex.modalAndMenu }}
    >
      {!hideTitle && (
        <DialogTitle disableTypography data-testid={testId(dataTestId, "title")} className={classes.titleContainer}>
          <div className={classes.title}>
            {React.isValidElement(title) ? title : <Typography variant="h2">{title}</Typography>}
          </div>
          <IconButton data-testid={testId(dataTestId, "close")} onClick={onClose} className={classes.close}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      {content && (
        <DialogContent
          className={clsx(
            noContentPadding && classes.noContentPadding,
            scrollbarPadding && classes.scrollbarPadding,
            classes.content
          )}
        >
          {hideTitle && screenSize === "mobile" && (
            <IconButton
              data-testid={testId(dataTestId, "close")}
              onClick={onClose}
              className={clsx(classes.close, classes.closeOutsideContentMobile)}
            >
              <CloseIcon />
            </IconButton>
          )}
          {typeof content === "string" ? (
            <DialogContentText variant="body1" color="textPrimary">
              {content}
            </DialogContentText>
          ) : (
            content
          )}
        </DialogContent>
      )}
      {!!(primary || secondary) && (
        <>
          <Divider />
          <DialogActions data-testid={testId(dataTestId, "actions")} className={classes.actions}>
            {!!showHideForever && (
              <FormControlLabel
                data-testid={testId(dataTestId, "hide-forever")}
                style={{ marginLeft: 8 }}
                control={<Checkbox onChange={(e) => setHideForever(e.target.checked)} />}
                label="Don’t show this message again"
              />
            )}
            {actions}
            {!!secondary && (
              <AiButton
                data-testid={testId(dataTestId, "secondary")}
                amplyfiType="secondary"
                onClick={onSecondary ?? undefined}
                disabled={!onSecondary}
              >
                {secondary}
              </AiButton>
            )}
            {!!primary && (
              <AiButton
                data-testid={testId(dataTestId, "primary")}
                endIcon={confirmClicked ? <AiCircularProgress primaryColor="white" size={16} /> : undefined}
                disabled={confirmClicked || !onConfirm}
                onClick={confirm}
              >
                {primary}
              </AiButton>
            )}
          </DialogActions>
        </>
      )}
      {hideTitle && screenSize === "desktop" && (
        <IconButton
          data-testid={testId(dataTestId, "close")}
          onClick={onClose}
          className={clsx(classes.close, classes.closeOutsideContent)}
        >
          <CloseIcon htmlColor="white" />
        </IconButton>
      )}
    </Dialog>
  );
}
