import { createStyles, FormControl, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { zIndex } from "../../../../helpers/componentsZIndex";
import { getOrganisations } from "../../../../http/accounts";
import { AccountGroup } from "../../../../models/account";
import { setSnack } from "../../../../store/reducers/ui/snackReducer";

interface SelectOrganisationProps {
  readOnly?: boolean;
  organisation: AccountGroup | { name: string; id: string } | null;
  setOrganisation: (organisation: AccountGroup | null) => void;
  organisations?: AccountGroup[] | null;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    item: {
      alignItems: "baseline",
      display: "flex",
    },
    select: {
      width: "100%",
    },
    control: {
      marginBottom: spacing(1),
      marginTop: spacing(1),
    },
    label: {
      paddingBottom: spacing(1),
    },
    loading: {
      cursor: "wait",
    },
    placeholder: {
      fontStyle: "italic",
    },
  })
);

const ORGANISATION_PLACEHOLDER = "Select an organisation…";

export const SelectOrganisation = ({
  readOnly = false,
  organisation,
  setOrganisation,
  organisations: preloadedOrganisations = null,
}: SelectOrganisationProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [organisations, setOrganisations] = useState<AccountGroup[] | null>(null);

  async function fetchOrganisations() {
    try {
      setIsLoading(true);
      const { organisations } = await getOrganisations();
      setOrganisations(organisations.sort(({ name: a }, { name: b }) => a.localeCompare(b)));
      setIsLoading(false);
    } catch (e) {
      dispatch(
        setSnack({
          body: "There was a problem getting organisations.",
          title: "Failure",
        })
      );
    }
  }

  useEffect(() => {
    if (!readOnly && preloadedOrganisations === null && organisations === null) {
      fetchOrganisations();
    } else {
      setOrganisations(preloadedOrganisations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl fullWidth className={classes.control}>
      <Typography variant="h4" className={classes.label}>
        Organisation
      </Typography>
      <Select
        MenuProps={{
          style: { zIndex: zIndex.selectMenu },
        }}
        readOnly={readOnly}
        className={clsx(classes.select, isLoading && classes.loading)}
        disabled={isLoading || !organisations?.length}
        onChange={({ target: { value } }) => setOrganisation(organisations?.find(({ id }) => id === value) || null)}
        value={organisation?.id || ORGANISATION_PLACEHOLDER}
      >
        <MenuItem disabled value={ORGANISATION_PLACEHOLDER} className={classes.placeholder}>
          {ORGANISATION_PLACEHOLDER}
        </MenuItem>
        {organisations?.map(({ id, name }) => (
          <MenuItem key={id} value={id} className={classes.item}>
            {name}
          </MenuItem>
        ))}
        {readOnly && organisation && (
          <MenuItem key={organisation.id} value={organisation.id} className={classes.item}>
            {organisation.name}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
