import { create } from "zustand";
import { ReaderPanel } from "../../components/Pages/Feed/components/reader/Reader";

interface TabData {
  id: string;
  data?: {
    suggestedInvestigation: string;
  };
}
export interface PanelData {
  id: ReaderPanel;
  data?: {
    question: string;
  };
}

interface ReaderStoreState {
  selectedDocumentId: string | null;
  setSelectedDocumentId: (selectedDocumentId: string | null) => void;

  selectedLibraryId: string | null;
  setSelectedLibraryId: (selectedLibraryId: string | null) => void;

  selectedEntityId: string | null;
  setSelectedEntityId: (selectedEntityId: string | null) => void;

  selectedReportId: string | null;
  setSelectedReportId: (selectedReportId: string | null) => void;

  selectedPanel: PanelData | null;
  setSelectedPanel: (selectedView: PanelData | null) => void;

  selectedTab: TabData;
  setSelectedTab: (tab: TabData) => void;

  fromDeepLink: boolean;
  setFromDeepLink: (fromDeepLink: boolean) => void;

  isCardSelected: boolean;
  setIsCardSelected: (isCardSelected: boolean) => void;

  renderType: "default" | "overlay";
  setRenderType: (renderType: "default" | "overlay") => void;

  customPanel: JSX.Element | null;
  setCustomPanel: (customPanel: JSX.Element | null) => void;

  isBackButtonVisible: boolean,
  setIsBackButtonVisible: (isBackButtonVisible: boolean) => void;

  reset: () => void;
}

const useReaderStore = create<ReaderStoreState>((set, _) => ({
  selectedDocumentId: null,
  setSelectedDocumentId: (selectedDocumentId) => set({ selectedDocumentId }),

  selectedLibraryId: null,
  setSelectedLibraryId: (selectedLibraryId) => set({ selectedLibraryId }),

  selectedEntityId: null,
  setSelectedEntityId: (selectedEntityId: string | null) => set({ selectedEntityId }),

  selectedReportId: null,
  setSelectedReportId: (selectedReportId) => set({ selectedReportId }),

  selectedPanel: null,
  setSelectedPanel: (selectedPanel) => set({ selectedPanel }),

  selectedTab: { id: "overview" },
  setSelectedTab: (selectedTab) => set({ selectedTab }),

  fromDeepLink: false,
  setFromDeepLink: (fromDeepLink) => set({ fromDeepLink }),

  isCardSelected: false,
  setIsCardSelected: (isCardSelected) => set({ isCardSelected }),

  renderType: "default",
  setRenderType: (renderType) => set({ renderType }),

  customPanel: null,
  setCustomPanel: (customPanel) => set({ customPanel }),

  isBackButtonVisible: false,
  setIsBackButtonVisible: (isBackButtonVisible) => set({ isBackButtonVisible }),

  reset: () => set({
    selectedDocumentId: null,
    selectedLibraryId: null,
    selectedEntityId: null,
    selectedReportId: null,
    selectedPanel: null,
    selectedTab: { id: "overview" },
    fromDeepLink: false,
    isCardSelected: false,
    renderType: "default",
    customPanel: null,
  }),
}));

export default useReaderStore;
