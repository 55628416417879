import { SearchRequestV2 } from "../models/search";

export default function geographyFilters(searchRequest: SearchRequestV2, geographyCount?: number): SearchRequestV2 {
  return {
    ...searchRequest,
    systemFilters: {
      ...searchRequest.systemFilters,
      geographyCount,
    },
  };
}
