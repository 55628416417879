import axios from "axios";
import { enableStatus } from "../../helpers/axios";
import { Entity } from "../../models/entity";
import { DashboardItem, LibraryItem } from "../../models/search/documentLibrary";
import { LibraryFilter } from "../libraryFilters";

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

const apiUrl = `${process.env.REACT_APP_ANALYSIS_API}/v2`;

interface OrganisationLibrariesResponse {
  count: number;
  libraries: LibraryItem[];
}

export async function getDocumentLibraries(organisationId: string): Promise<OrganisationLibrariesResponse> {
  const { data } = await axios.get<OrganisationLibrariesResponse>(
    `${apiUrl}/organisations/${organisationId}/libraries`
  );
  return {
    ...data,
    libraries: data.libraries.map((library) => ({
      ...library,
      allowedDocumentLocations: transformAllowedDocumentLocations(
        (library.allowedDocumentLocations as unknown as string[]) || []
      ),
    })),
  };
}

const transformAllowedDocumentLocations = (
  allowedDocumentLocations?: string[]
): LibraryItem["allowedDocumentLocations"] =>
  allowedDocumentLocations?.map((id) => ({
    id,
    name: id.replace(new RegExp(`^${id.split("-")[0]}-`), ""),
  }));

export async function getDocumentLibrary(organisationId: string, libraryId: string): Promise<LibraryItem> {
  const {
    data: { allowedDocumentLocations, ...library },
  } = await axios.get<Omit<LibraryItem, "allowedDocumentLocations"> & { allowedDocumentLocations?: string[] }>(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}`
  );
  return {
    ...library,
    allowedDocumentLocations: transformAllowedDocumentLocations(allowedDocumentLocations),
  };
}

export async function createDocumentLibrary(
  organisationId: string,
  name: Entity["name"],
  description = `Documents for '${name}' user alert`,
  dashboards: DashboardItem[],
  subscribedUserIds: string[],
  subscribedGroupIds: string[],
  allowedDocumentSectors: LibraryFilter[] = [],
  allowedPublishedLocations: LibraryFilter[] = [],
  allowedDocumentLocations: LibraryFilter[] = [],
  blockNoPublishDate = true
): Promise<LibraryItem> {
  const { data } = await axios.post<LibraryItem>(
    `${apiUrl}/organisations/${organisationId}/libraries`,
    {
      description,
      name,
      subscribedUserIds,
      subscribedGroupIds,
      dashboards: dashboards.map((d) => ({ ...d, id: d.amplyfiEntityId })),
      allowedDocumentSectors,
      allowedPublishedLocations: allowedPublishedLocations.map(({ id }) => id),
      allowedDocumentLocations: allowedDocumentLocations.map(({ id }) => id),
      blockNoPublishDate,
    },
    { validateStatus: enableStatus(400) }
  );
  return data;
}

export async function editDocumentLibrary(
  organisationId: string,
  {
    name,
    description = `Documents for '${name}' user alert`,
    dashboards,
    allowedDocumentLocations = [],
    ...libraryItem
  }: PartialBy<LibraryItem, "description">
): Promise<string> {
  const { data } = await axios.put<LibraryItem>(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryItem.id}`,
    {
      ...libraryItem,
      name,
      description,
      dashboards: dashboards.map((d) => ({ ...d, id: d.amplyfiEntityId })),
      allowedDocumentLocations: allowedDocumentLocations.map(({ id }) => id),
    }
  );
  return data.id;
}

export async function deleteDocumentLibrary(organisationId: string, libraryId: string): Promise<void> {
  await axios.delete(`${apiUrl}/organisations/${organisationId}/libraries/${libraryId}`);
}

export async function blockLibrarySource(
  organisationId: string,
  libraryId: string | null,
  documentId: string,
  source: string
): Promise<string | undefined> {
  const suffix = libraryId ? `libraries/${libraryId}/block-source` : "block-source";
  const { data } = await axios.put<string | { success: true }>(
    `${apiUrl}/organisations/${organisationId}/${suffix}`,
    { documentId, source },
    { validateStatus: enableStatus(400) }
  );
  return typeof data === "string" ? data : undefined;
}

export async function unblockLibrarySource(
  organisationId: string,
  libraryId: string | null,
  source: string
): Promise<string | undefined> {
  const suffix = libraryId ? `libraries/${libraryId}/block-source` : "block-source";
  const { data } = await axios.delete<string | { success: true }>(
    `${apiUrl}/organisations/${organisationId}/${suffix}`,
    { data: { source } }
  );
  return typeof data === "string" ? data : undefined;
}

export async function unblockLibrarySector(
  organisationId: string,
  libraryId: string | null,
  name: string
): Promise<string | undefined> {
  const { data } = await axios.delete<string | { success: true }>(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/block-document-sector`,
    { data: { name } }
  );
  return typeof data === "string" ? data : undefined;
}

export async function blockLibrarySector(
  organisationId: string,
  libraryId: string | null,
  documentId: string,
  sector: LibraryFilter
): Promise<string | undefined> {
  const { data } = await axios.put<string | { success: true }>(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/block-document-sector`,
    { ...sector, documentId }
  );
  return typeof data === "string" ? data : undefined;
}

export async function unblockLibraryTopic(
  organisationId: string,
  libraryId: string | null,
  name: string
): Promise<string | undefined> {
  const { data } = await axios.delete<string | { success: true }>(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/block-document-topic`,
    { data: { name } }
  );
  return typeof data === "string" ? data : undefined;
}

export async function blockLibraryTopic(
  organisationId: string,
  libraryId: string | null,
  documentId: string,
  topic: LibraryFilter
): Promise<string | undefined> {
  const { data } = await axios.put<string | { success: true }>(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/block-document-topic`,
    { ...topic, documentId }
  );
  return typeof data === "string" ? data : undefined;
}

export async function unblockLibraryTypeTag(
  organisationId: string,
  libraryId: string | null,
  { id }: LibraryFilter
): Promise<string | undefined> {
  const { data } = await axios.delete<string | { success: true }>(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/block-document-type-tag`,
    { data: { id } }
  );
  return typeof data === "string" ? data : undefined;
}

export async function blockLibraryTypeTag(
  organisationId: string,
  libraryId: string | null,
  documentId: string,
  typeTag: LibraryFilter
): Promise<string | undefined> {
  const { data } = await axios.put<string | { success: true }>(
    `${apiUrl}/organisations/${organisationId}/libraries/${libraryId}/block-document-type-tag`,
    { ...typeTag, documentId }
  );
  return typeof data === "string" ? data : undefined;
}
