import { testId } from "@amplyfi/ui-components/testHelpers";
import AiButton from "@amplyfi/ui-components/components/Button";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { SelectOrganisation } from "./SelectOrganisation";
import { SelectLibrary } from "./SelectLibrary";
import { LibraryItem } from "../../../../models/search/documentLibrary";
import { isLibraryValid, isOrganisationValid } from "../helpers/queryValidation";
import { AccountGroup } from "../../../../models/account";
import { SelectSource } from "./SelectSource";
import { getOrganisations } from "../../../../http/accounts";
import { getHarvestConfig, getHarvestSourceOptions } from "../helpers/harvest";
import { useStyles } from "./modalStyles";
import { HarvestConfig } from "../models";
import useHarvestConfig from "../../../../hooks/useHarvestConfig";
import { SelectEntity } from "./SelectEntity";
import { LibraryEntity } from "../../../../http/harvesterCollections";

interface StepEnterContextProps {
  organisation: AccountGroup | null;
  setOrganisation: (organisation: AccountGroup | null) => void;
  library: LibraryItem | null;
  setLibrary: (library: LibraryItem | null) => void;
  harvestConfig: HarvestConfig;
  setHarvestConfig: (harvestConfig: HarvestConfig) => void;
  onNext: () => void;
  setEntity: (entity: LibraryEntity | null) => void;
  amplyfiEntityId: string;
}

export const StepEnterContext = ({
  organisation,
  setOrganisation,
  library,
  setLibrary,
  harvestConfig,
  setHarvestConfig,
  onNext,
  setEntity,
  amplyfiEntityId,
}: StepEnterContextProps): JSX.Element => {
  const { data: harvestConfigs } = useHarvestConfig();
  const classes = useStyles();
  const CACHE_REFRESH_TIME = 4 * 60 * 60 * 1000; // 4 hours
  const [showEntityDropdown, setShowEntityDropdown] = useState(false);
  const [selectedSource] = useState(null);
  const [sourceChanged, setSourceChanged] = useState(false);

  useEffect(() => {
    setSourceChanged(true);
  }, [selectedSource]);

  const { data: organisations } = useQuery(
    ["all-organisations"],
    async () => (await getOrganisations()).organisations,
    {
      cacheTime: CACHE_REFRESH_TIME,
    }
  );

  return (
    <>
      <SelectOrganisation organisation={organisation} setOrganisation={setOrganisation} organisations={organisations} />
      <SelectLibrary organisationId={organisation?.id} libraryId={library?.id} setLibrary={setLibrary} />
      <SelectSource
        disabled={!organisation || !library}
        harvestSourceId={harvestConfig.id}
        harvestSourceOptions={getHarvestSourceOptions(harvestConfigs)}
        setHarvestSourceId={(harvestSourceId: string) =>
          setHarvestConfig(getHarvestConfig(harvestSourceId, harvestConfigs))
        }
        setShowEntityDropdown={setShowEntityDropdown}
      />
      {showEntityDropdown && (
        <SelectEntity
          organisationId={organisation?.id}
          sourceChanged={sourceChanged}
          libraryId={library?.id}
          setEntity={setEntity}
          amplyfiEntityId={amplyfiEntityId}
        />
      )}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <AiButton
          data-testid={testId("harvest", "create-harvest", "next")}
          disabled={!isOrganisationValid(organisation) || (!isLibraryValid(library) && !amplyfiEntityId)}
          className={clsx(classes.button, classes.buttonPrimary)}
          onClick={onNext}
          type="button"
        >
          Next
        </AiButton>
      </div>
    </>
  );
};
