import { Box, Chip, List, makeStyles } from "@material-ui/core";
import { LiveHelpOutlined as InvestigateIcon } from "@material-ui/icons";
import useInvestigationSuggestions from "../../../../../../hooks/queries/investigation_suggestions/useInvestigationSuggestions";
import { SocketStatus } from "../../../../../../hooks/queries/sockets/useAmplyfiSocketParser";
import useReaderStore from "../../../../../../store/hooks/useReaderStore";
import OverviewSection from "./OverviewSection";

const useStyles = makeStyles(({ spacing }) => ({
  cardChip: {
    background: "#F0F3F7",
    color: "#000",
    height: "auto",
    marginBottom: spacing(1),
    padding: spacing(1),
    whiteSpace: "normal",

    "& .MuiChip-label": {
      whiteSpace: "normal",
    },
  },
}));

export interface InvestigationSuggestionsProps {
  libraryId: string;
}

export default function InvestigationSuggestions({ libraryId }: InvestigationSuggestionsProps) {
  const classes = useStyles();
  const { selectedDocumentId, setSelectedTab } = useReaderStore((state) => state);
  const { suggestions, status } = useInvestigationSuggestions(libraryId, selectedDocumentId || "");
  const suggestionFinished = (): boolean => status === SocketStatus.Done;

  return (
    <OverviewSection
      minHeight={220}
      isLoading={suggestions[0].length === 0}
      icon={<InvestigateIcon color="primary" />}
      title="Investigate"
    >
      <List>
        {suggestions.map((s) => (
          <Box
            display="block"
            onClick={
              suggestionFinished()
                ? () => {
                    setSelectedTab({ id: "investigation", data: { suggestedInvestigation: s } });
                  }
                : undefined
            }
          >
            <Chip
              className={classes.cardChip}
              key={s}
              label={s}
              style={{ cursor: suggestionFinished() ? "pointer" : undefined }}
            />
          </Box>
        ))}
      </List>
    </OverviewSection>
  );
}
