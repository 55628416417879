import axios from "axios";
import { useQuery } from "../../useQuery";
import useAPI from "../api/useAPI";

export type ResourceType = "signal"

export interface Comment {
    commentId: string;
    comment: string;
    userId: string;
    created: string;
}

export interface CommentsResponse {
    comments: Comment[];
}

export const commentKey = "comments";

export default function useComments(resourceType: ResourceType, resourceId: string) {
    const api = useAPI("v5", `${resourceType}/${resourceId}/${commentKey}`, { useOrganisation: false, useLibrary: true });

    return useQuery([commentKey, resourceId, api], async () => {
        const { data } = await axios.get<CommentsResponse>(api);
        return data;
    })
}