import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import SentimentScore from "./SentimentScore";

interface SentimentMarksProps {
  sentiment?: number;
  label: string;
  colour: string;
  containerClasses?: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 30,
    },
    mark: {
      "& > div": {
        borderRadius: 5,
        marginRight: theme.spacing(1),
        height: 16,
        width: 16,
      },
      display: "flex",
      alignItems: "center",
    },
    markContainer: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
    },
    label: {
      fontWeight: "bold",
    },
  })
);
export default function SentimentMark(props: SentimentMarksProps): JSX.Element {
  const { sentiment, label, colour, containerClasses } = props;

  const classes = useStyles();
  return (
    <div className={clsx(classes.container, containerClasses)}>
      <div className={classes.mark}>
        <div style={{ backgroundColor: colour }} />
        <Typography className={classes.label} variant="body2" data-testid="sentiment-sentences-label">
          {label}
        </Typography>
      </div>
      {sentiment && <SentimentScore sentiment={sentiment} colour={colour} />}
    </div>
  );
}
