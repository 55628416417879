import {
  createStyles,
  darken,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ExitToApp, Settings } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import appUrl from "../../../appUrl";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import AccountDetails from "../User/Tabs/AccountDetails";
import Security from "../User/Tabs/Security";
import GroupPage from "./Group";
import GroupManagement from "./GroupManagement";
import UserPage from "./User";
import UserManagement from "./UserManagement";
import DigestEmails from "../User/Tabs/DigestEmails";

interface SettingsPageProps {
  title?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flex: "1 1 auto",
    },
    sidebar: {
      display: "flex",
      flexDirection: "column",
      flex: "0 0 240px",
      [theme.breakpoints.down("sm")]: {
        flex: "1 1 auto",
        background: "#FFF",
        padding: theme.spacing(2),
      },
    },
    content: {
      display: "flex",
      flex: "1 1 auto",
      background: "#FFF",
      height: `calc(100% - ${theme.spacing(10)}px)`,
      overflow: "auto",
      padding: theme.spacing(4),
      maxHeight: "100%",
    },
    header: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    leftSpacing: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    listItem: {
      cursor: "pointer",

      "&:hover": {
        background: darken(theme.palette.componentBackground.main, 0.2),
      },
    },
    active: {
      background: darken(theme.palette.componentBackground.main, 0.1),
    },
    hidden: {
      display: "none",
    },
    hiddenMobile: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    endActions: {
      marginTop: "auto",
      marginBottom: theme.spacing(4),
    },
  })
);

const pages = [
  {
    title: "Account",
    pages: [
      { title: "Profile", path: "/manage/profile" },
      { title: "Digest Emails", path: "/manage/digestEmails" },
      { title: "Security", path: "/manage/security" },
    ],
  },
  {
    title: "Organisation",
    pages: [
      { title: "Users", path: "/manage/users" },
      { title: "Groups", path: "/manage/groups" },
    ],
  },
];

export default function SettingsPage(props: SettingsPageProps): JSX.Element {
  const { logout } = useAuth0();
  const styles = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const routeMatch = useRouteMatch({ path: "/manage/*", exact: true });
  const rootPage = useRouteMatch({ path: "/manage", exact: true });

  return (
    <div className={styles.container}>
      <div className={clsx(styles.sidebar, !rootPage?.isExact && styles.hiddenMobile)}>
        <div className={styles.header}>
          <Settings fontSize="small" />
          <Typography style={{ marginLeft: theme.spacing(1) }} variant="h2">
            Settings
          </Typography>
        </div>
        {pages.map((page) => (
          <React.Fragment key={page.title}>
            <Typography className={styles.leftSpacing} style={{ marginTop: 16 }} variant="h4">
              {page.title}
            </Typography>
            <List>
              {page.pages.map((subPage) => (
                <ListItem
                  className={clsx(styles.listItem, { [styles.active]: location.pathname === subPage.path })}
                  component={Link}
                  to={subPage.path}
                  key={subPage.path}
                >
                  <ListItemText primary={subPage.title} />
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        ))}
        <List className={styles.endActions}>
          <ListItem className={styles.listItem} onClick={() => logout({ returnTo: appUrl })}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            Logout
          </ListItem>
        </List>
      </div>
      <div className={clsx(styles.content, !routeMatch?.isExact && styles.hidden)}>
        <Switch>
          <Route exact path="/manage/profile" component={AccountDetails} />
          <Route exact path="/manage/digestEmails" component={DigestEmails} />
          <Route exact path="/manage/security" component={Security} />
          <Route exact path="/manage/users" component={UserManagement} />
          <Route path="/manage/users/:userId" component={UserPage} />
          <Route exact path="/manage/groups" component={GroupManagement} />
          <Route path="/manage/groups/:groupId" component={GroupPage} />
        </Switch>
      </div>
    </div>
  );
}
