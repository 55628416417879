import { BrightPink } from "@amplyfi/ui-components/theme/colors";
import { Equalizer, People, Subject, TrendingDown, TrendingUp } from "@material-ui/icons";
import { distinct } from "../../../../../helpers/arrayHelpers";
import { toTitleCase } from "../../../../../helpers/textHelper";
import { MonitoringSentence, AlertType, MatchedAlert } from "../../../../../models/monitoring";
import { parseWikidata } from "../../../../../helpers/wikidataHelper";

export const ActionTypes = {
  ReachOut: {
    title: "Reach Out",
    color: "#730fc3",
  },
  UpdateAccountPlan: {
    title: "Update Account Plan",
    color: "#e8833b",
  },
  MonitorEventGoingForward: {
    title: "Monitor Event Going Forward",
    color: "#FFC107",
  },
  ResearchTopic: {
    title: "Research Topic",
    color: "#207868",
  },
};

export const InsightTypes = {
  Summary: {
    title: "Popular",
    icon: <Equalizer color="primary" />,
  },
  EmergingRelationships: {
    title: "Emerging Relationships",
    icon: <People color="primary" />,
  },
  PositiveSentiment: {
    title: "Positive Sentiment",
    icon: <TrendingUp color="primary" />,
  },
  NegativeSentiment: {
    title: "Negative Sentiment",
    icon: <TrendingDown color="primary" />,
  },
};

function titleCaseToNormalCase(str: string) {
  return str.replace(/([A-Z])/g, " $1").trim();
}

export function getInsightActionType(action: string) {
  const result = ActionTypes[action as keyof typeof ActionTypes];
  if (result) {
    return result;
  } else {
    return {
      title: titleCaseToNormalCase(action),
      color: BrightPink,
    };
  }
}

export function getInsightType(type: string) {
  const result = InsightTypes[type as keyof typeof InsightTypes];
  if (result) {
    return result;
  } else {
    return {
      title: titleCaseToNormalCase(type),
      icon: <Subject color="primary" />,
    };
  }
}

export function allAlertTypesFromSentences(sentences: MonitoringSentence[]): (AlertType | string)[] {
  const alertTypes = distinct(sentences.map(({ alertTypes }) => alertTypes).flat());
  const matchedAlerts = sentences
    .map(({ matchedAlerts = [] }) => matchedAlerts)
    .flat()
    .filter(({ topic }) => !!topic) as (MatchedAlert & { topic: string })[];

  const matchedAlertTypes = alertTypes
    .map((alertType) => {
      const matchingAlerts = matchedAlerts.filter(({ alertTypeName }) => alertTypeName === alertType);
      return matchingAlerts.length
        ? matchingAlerts.map(({ topic }) => `${alertType} | ${toTitleCase(topic)}`)
        : [alertType];
    })
    .flat();

  return matchedAlertTypes;
}

export function reduceEntityHighlightsInSentences(sentences: MonitoringSentence[]): string[] {
  return sentences.reduce<string[]>((acc, { entityHighlights = [] }) => {
    entityHighlights.forEach((entity) => {
      if (entity) {
        acc.push(parseWikidata(entity));
      }
    });
    return acc;
  }, []);
}

export function reduceRelatedEntitiesInSentences(sentences: MonitoringSentence[]): string[] {
  return sentences.reduce<string[]>((acc, curr) => {
    curr.relatedEntities.forEach((entity) => {
      if (entity) {
        acc.push(parseWikidata(entity));
      }
    });
    return acc;
  }, []);
}