import AiButton from "@amplyfi/ui-components/components/Button";
import { B1Base } from "@amplyfi/ui-components/theme/colors";
import {
  createStyles,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { AddCircle, Close } from "@material-ui/icons";
import { useState } from "react";
import { ColumnItem } from "../../../models/element";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: B1Base,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      width: 644,
      height: 400,
    },
    selectedHeader: {
      display: "flex",
      padding: theme.spacing(2.5, 0, 2.5, 2.5),
      borderBottom: `1px solid ${theme.palette.borders.main}`,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      paddingBottom: 0,
      overflow: "auto",
      height: "100%",

      "& + &": {
        borderLeft: `1px solid ${theme.palette.borders.main}`,
      },
    },
    listItem: {
      width: "100%",
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemAdd: {
      background: "#FFF",
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(2)}px)`,
      padding: theme.spacing(0.5, 2),
    },
    listItemAddContent: {
      flex: "0 0 90%",
    },
    listItemContent: {
      background: "#FFF",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1, 2),
      width: 100,
      flex: "0 0 80%",
    },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      background: "#FFF",
      width: "100%",
      flex: "1 1 100%",
      gridColumnStart: 1,
      gridColumnEnd: 3,
      padding: theme.spacing(2),
    },
  })
);

interface ColumnEditorProps {
  allColumns: ColumnItem[];
  selectedColumns: ColumnItem[];
  onColumnsSelected: (hiddenColumnIds: string[]) => void;
  onClose: () => void;
}

function isSelected(selected: string[], entity: string) {
  return selected.includes(entity);
}

export default function ColumnEditor(props: ColumnEditorProps): JSX.Element {
  const { allColumns, selectedColumns, onColumnsSelected, onClose } = props;
  const [selectedColumnIds, setSelectedColumnIds] = useState<string[]>(
    selectedColumns.map((column) => column.accessor)
  );
  const classes = useStyles();
  return (
    <div>
      <div className={classes.selectedHeader}>
        <Typography variant="h4">Column Settings</Typography>
      </div>
      <div className={classes.container}>
        <List style={{ overflow: "auto", height: "100%" }}>
          <ListSubheader disableSticky>Available Columns</ListSubheader>
          {allColumns.map((column) => (
            <ListItem disabled={isSelected(selectedColumnIds, column.accessor)} className={classes.listItemAdd}>
              <ListItemText className={classes.listItemAddContent} primary={column.Header} />
              {!isSelected(selectedColumnIds, column.accessor) && (
                <ListItemIcon style={{ marginLeft: 8 }}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedColumnIds([...selectedColumnIds, column.accessor]);
                    }}
                  >
                    <AddCircle fontSize="small" color="secondary" />
                  </IconButton>
                </ListItemIcon>
              )}
            </ListItem>
          ))}
        </List>
        <List style={{ overflow: "auto", height: "100%" }}>
          <ListSubheader disableSticky>Selected Columns</ListSubheader>
          {selectedColumnIds.map((column) => (
            <ListItem className={classes.listItem}>
              <ListItemText
                className={classes.listItemContent}
                primary={allColumns.find((col) => col.accessor === column)?.Header || ""}
              />
              {column !== "name" && (
                <ListItemIcon style={{ marginLeft: 8, fontSize: 8 }}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedColumnIds(selectedColumnIds.filter((col) => col !== column));
                    }}
                  >
                    <Close fontSize="small" color="primary" />
                  </IconButton>
                </ListItemIcon>
              )}
            </ListItem>
          ))}
        </List>
        <div className={classes.footer}>
          <AiButton
            amplyfiType="secondary"
            style={{ marginLeft: 8 }}
            onClick={() => {
              setSelectedColumnIds(["name"]);
            }}
          >
            Clear
          </AiButton>
          <AiButton
            style={{ marginLeft: 8 }}
            onClick={() => {
              onColumnsSelected(
                allColumns
                  .filter((column) => !selectedColumnIds.includes(column.accessor))
                  .map((column) => column.accessor)
              );
              onClose();
            }}
          >
            Apply
          </AiButton>
        </div>
      </div>
    </div>
  );
}
