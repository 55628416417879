import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { DetailedHTMLProps } from "react";

const useStyles = makeStyles(() => ({
    container: {
        display: "inline-block",
        lineHeight: 0,
        borderRadius: "50%",
        background: (props: CircleHighlightProps) => props.inverted ? "#FFF" : "rgba(48, 36, 251, 0.1)",
        transition: "all .4s",
        boxShadow: (props: CircleHighlightProps) => props.inverted ? "0px 0px 10px rgba(0, 0, 0, 0.1)" : "none",
    },
    innerSpan: {
        display: "inline-block",
        paddingTop: (props) => props.icon ? undefined : "50%",
        paddingBottom: (props) => props.icon ? undefined : "50%",
        marginLeft: (props) => props.icon ? undefined : 6,
        marginRight: (props) => props.icon ? undefined : 6,
        padding: (props) => props.icon ? 6 : undefined,
        color: (props) => props.inverted ? "#000" : "#3024FB"
    }
}));

interface CircleHighlightProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    children: JSX.Element;
    inverted?: boolean;
    icon?: boolean;
}

export default function CircleHighlight(props: CircleHighlightProps) {
    const classes = useStyles(props);
    return <span {...props} className={clsx(classes.container, props.className)}><span className={classes.innerSpan}>{props.children}</span></span>
}