import { Box, Tab, Tabs, Typography, makeStyles } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import { useEffect } from "react";
import { isInsight } from "../../../../../../helpers/documentHelpers";
import { InsightDocument } from "../../../../../../hooks/queries/insights/useInsights";
import useFeedDoc from "../../../../../../hooks/useFeedDocument";
import { useFeedViewStore } from "../../../../../../store/hooks/useFeedViewStore";
import useQuestionAnswerStore from "../../../../../../store/hooks/useQuestionAnswerStore";
import useReaderStore from "../../../../../../store/hooks/useReaderStore";
import DynamicComponent from "../../../Configuration/Components/DynamicComponent";
import FeedCard from "../../../Configuration/Insights/FeedCard";
import { FeedViewType } from "../../filterMenu/feedFilters";
import Investigation from "../Investigation";
import Overview from "../Overview";
import SignalCard from "../../../Configuration/Insights/SignalCard";
import { MonitoringDocument } from "../../../../../../models/monitoring";
import Comments from "../Comments";
import Stack from "../../stack/Stack";
import { useFlags } from "launchdarkly-react-client-sdk";

const tabs = [
  {
    id: "overview",
    label: () => "Overview",
    component: Overview,
  },
  {
    id: "investigation",
    label: () => "Investigation",
    component: Investigation,
  },
  {
    id: "comments",
    label: (data: MonitoringDocument) =>
      <Stack direction="horizontal" spacing={1}>
        <Typography>Comments</Typography>
        <Box padding={0.5} paddingLeft={2} paddingRight={2} borderRadius={45} bgcolor="#FFF0DE" display="flex" alignItems="center">
          <Typography color="primary">{data.commentsCount}</Typography>
        </Box>
      </Stack>,
    component: Comments,
    visible: FeedViewType.Signal,
    featureFlag: "feedSignalComments"
  }
];

const useStyles = makeStyles(({ breakpoints }) => ({
  panel: {
    display: "grid",
    gridTemplateRows: "min-content 60px auto",
    gridTemplateColumns: "100%",
    backgroundColor: "white",
  },
}));

export default function CardPanel() {
  const view = useFeedViewStore(({ view }) => view);
  const { setDocumentIds } = useQuestionAnswerStore((state) => state);
  const { selectedTab, setSelectedTab, selectedDocumentId, selectedLibraryId, setSelectedLibraryId } = useReaderStore(
    (state) => state
  );
  const { data, isLoading } = useFeedDoc(selectedLibraryId, selectedDocumentId as string);
  const classes = useStyles();
  const { amplyfiEntityId } = useFeedViewStore();
  const flags = useFlags();

  useEffect(() => {
    if (data) {
      setSelectedLibraryId(data.libraryId);
      setDocumentIds(
        isInsight(selectedDocumentId)
          ? (data as InsightDocument).insights[0].sources.map((s) => s.documentId)
          : [selectedDocumentId as string]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box className={classes.panel}>
      <DynamicComponent isLoading={isLoading}>
        {isInsight(selectedDocumentId) ? <FeedCard
          isWithinReader
          showReasoning={false}
          view={view}
          feedDocument={data as InsightDocument}
          type={FeedViewType.Insight}
        /> : <SignalCard compact feedDocument={data as MonitoringDocument} />}
        <TabContext value={selectedTab.id}>
          <>
            <Tabs
              style={{ position: "sticky", top: 0, alignSelf: "flex-start", zIndex: 100, background: "white" }}
              onChange={(_, value) => setSelectedTab({ id: value })}
              variant="fullWidth"
              value={selectedTab.id}
            >
              {tabs.filter(v => v.visible ? view.type === v.visible : true).filter(v => v.featureFlag ? flags[v.featureFlag] : true).map((tab) => (
                <Tab key={tab.id} fullWidth label={data ? tab.label(data as MonitoringDocument) : ""} value={tab.id} />
              ))}
            </Tabs>
            {tabs.map((t) => (
              <Box
                key={`tab-${t.id}`}
                position="relative"
                padding={2}
                style={{ display: selectedTab.id === t.id ? "block" : "none" }}
              >
                {t.component({
                  libraryId: data ? (data.libraryId as string) : "",
                  amplyfiEntityId: amplyfiEntityId || undefined,
                })}
              </Box>
            ))}
          </>
        </TabContext>
      </DynamicComponent>
    </Box>
  );
}
