import { List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import React, { useState } from "react";
import AccountDetails from "./Tabs/AccountDetails";
import Security from "./Tabs/Security";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    flex: "1 1 100%",
    marginLeft: 6,
  },
  sidebar: {
    flex: "0 0 280px",
    borderLeft: "1px solid #E8E8E8",
    borderRight: "1px solid #E8E8E8",
    paddingTop: theme.spacing(3),
    background: "#FFFFFF",
  },
  sidebarTitle: {
    marginLeft: theme.spacing(2),
  },
  content: {
    maxWidth: "100%",
    flex: "1 1 auto",
    padding: theme.spacing(2),
  },
  listItem: {
    "&.Mui-selected": {
      background: "#89C5F0",
    },
    "&:hover": {
      background: "#F5F5F5",
      cursor: "pointer",
    },
  },
}));

const pages = [
  { name: "Account Details", component: <AccountDetails /> },
  { name: "Security", component: <Security /> },
];

export default function UserPage() {
  const [selectedPage, setSelectedPage] = useState(pages[0].component);
  const styles = useStyles();
  return (
    <div className={styles.main}>
      <div className={styles.sidebar}>
        <Typography className={styles.sidebarTitle} variant="h4">
          Account
        </Typography>
        <List>
          {pages.map((page) => (
            <ListItem
              selected={selectedPage === page.component}
              className={styles.listItem}
              onClick={() => setSelectedPage(page.component)}
            >
              <ListItemText style={{ flex: "1 0 90%" }} primary={page.name} />
              <ListItemIcon>
                <ChevronRight />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </div>
      <div className={styles.content}>{selectedPage}</div>
    </div>
  );
}
