import { Fade, Typography, TypographyProps, makeStyles } from "@material-ui/core";
import { distinct } from "../../../../helpers/arrayHelpers";
import AnimatedEllipsis from "./animatedEllipsis/AnimatedEllipsis";

const useStyles = makeStyles(() => ({
  match: {
    background: "rgba(255, 255, 0, .5)",
    position: "relative",
    textDecoration: "inherit",
  },
  // simulate a text caret and flash it to indicate loading
  loadingCaret: {
    display: "inline-block",
    width: 2,
    height: 20,
    background: "black",
    animation: "blinker .75s linear infinite",
  },
}));

interface TextRendererProps extends TypographyProps {
  children: string;
  match?: string[] | string | null;
  options?: { matchSuffixPartials?: boolean; matchPrefixPartials?: boolean };
  maxLength?: number;
  isLoading?: boolean;
  animateText?: boolean;
}

// function escapes strings so that they can safely be used within a Regular Expression
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
const escapeRegExp = (str: string): string => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const textMatches = (matches: string[], matchExactWord = false): RegExp =>
  new RegExp(
    [
      ...(matchExactWord ? ["\\b"] : []),
      "(",
      matches.map(escapeRegExp).join("|"),
      ")",
      ...(matchExactWord ? ["\\b"] : []),
    ].join(""),
    "gi"
  );

export const TextRenderer = ({
  match = "",
  children: text,
  options: { matchSuffixPartials = true, matchPrefixPartials = false } = {},
  maxLength = text.length,
  variant = "inherit",
  isLoading,
  animateText,
  ...props
}: TextRendererProps): JSX.Element => {
  const classes = useStyles();
  const matches: string[] = distinct((typeof match === "string" ? [match] : match ?? []).filter((m) => !!m));
  const children = [text.substring(0, maxLength), ...(maxLength < text.length ? ["…"] : [])].join("");
  const regEx = textMatches(matches);
  const replacedText: (string | JSX.Element)[] = [];
  const matchAll = [...children.matchAll(regEx)];
  let offset = 0;

  if (isLoading) {
    return (
      <Typography>
        <AnimatedEllipsis />
      </Typography>
    );
  }

  if (!match || !match.length) {
    return (
      <Typography variant={variant} {...props}>
        {animateText ? children.split(" ").map(c => <Fade in><span>{c} </span></Fade>) : children}
      </Typography>
    );
  }

  matchAll.forEach(({ "0": match, index = 0 }) => {
    replacedText.push(children.substring(offset, index));
    replacedText.push(
      <span id={`search-result-${index + 1}`} key={`${match}-${index}`} className={classes.match}>
        {match}
      </span>
    );
    offset = index + match.length;
  });
  replacedText.push(children.substring(offset));

  return (
    <Typography variant={variant} {...props}>
      {replacedText}
    </Typography>
  );
};
