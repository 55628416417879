import AnalysisComponent from "@amplyfi/ui-components/components/AnalysisComponent";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { onSetModalOpen } from "../store/reducers/searchRequest/filterReducer";

export const useAnalysisComponentState = (
  isError: boolean,
  isLoading: boolean,
  noData: boolean,
  setModal: (arg: boolean) => void
): {
  state?: React.ComponentProps<typeof AnalysisComponent>["analysisState"];
  primaryAction?: () => void;
  secondaryAction?: () => void;
} => {
  const dispatch = useDispatch();
  const history = useHistory();

  if (isError) {
    return {
      state: "Error",
      primaryAction: () => window.location.reload(),
      secondaryAction: () => setModal(true),
    };
  } else if (isLoading) {
    return { state: "Loading" };
  } else if (noData) {
    return {
      state: "NoData",
      primaryAction: () => dispatch(onSetModalOpen(true)),
      secondaryAction: () => history.push({ pathname: "/analyse", search: window.location.search }),
    };
  } else return {};
};
