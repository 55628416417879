import useWebSocket, { Options } from "react-use-websocket";
import useAuthToken from "../authToken/useAuthToken";

export default function useAuthenticatedSocket(url: string, options?: Options, connect = true) {
  const token = useAuthToken();
  return useWebSocket(
    url,
    {
      ...options,
      share: false,
      queryParams: { ...(options ? options.queryParams : {}), authorization: token as string },
    },
    token !== null && connect
  );
}
