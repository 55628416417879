import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { dateError } from "../../../../../../../helpers/dateHelpers";
import { useAmplyfiEntityId } from "../../../../../../../hooks/useAmplyfiEntities";
import useFeed, { useTotalFeedCounts } from "../../../../../../../hooks/useFeed";
import useWizardState from "../../../../../../../hooks/useWizardState";
import { useFeedViewStore } from "../../../../../../../store/hooks/useFeedViewStore";
import TextEntryField from "../../../../Configuration/Components/TextEntryField";
import Stack from "../../../stack/Stack";
import { CreateReportWizardState, ReportTimeframe } from "../CreateReportWizard";
import { REPORT_TYPES } from "./ReportTypeSelector";

interface TimeRangeProps {
  time: ReportTimeframe;
  onValid: (valid: boolean) => void;
}

function TimeRange(props: TimeRangeProps) {
  const { time } = props;
  const [state, setState] = useWizardState<CreateReportWizardState>("create-report");
  const { libraryId } = useFeedViewStore();
  const { data: feed } = useFeed(libraryId, state.selectedEntityId, {
    feedType: "inbox",
    sortBy: "published",
    sortOrder: "ascending",
    strength: null,
    events: null,
    sources: [],
    topics: [],
    userTags: [],
    sectors: [],
    signalTypes: [],
    search: "",
    startDate: moment("0001-01-01").utc().toISOString(),
  });

  const filteredDocs = feed?.documents.filter((d) => !!d.date) || [];
  const minDate = filteredDocs.length > 0 ? filteredDocs[0].date : undefined;

  const startDate = useMemo(() => {
    return time && time !== "custom"
      ? moment()
        .subtract(...time.split(" "))
        .utc()
        .toISOString()
      : undefined;
  }, [time]);

  const { data: counts, isLoading } = useTotalFeedCounts(
    libraryId,
    state.selectedEntityId,
    time !== "custom" ? startDate : state.startDate || startDate,
    time !== "custom" ? undefined : state.endDate || undefined
  );

  const currentDate = new Date().toISOString();

  function timeFrameToStartEndDate(timeFrame: string) {
    state.startDate = moment()
      .subtract(...timeFrame.split(" "))
      .utc()
      .toISOString();
    state.endDate = moment().utc().toISOString();
  }

  const handleTimeFrameChange = (timeFrameValue: CreateReportWizardState["timeFrame"]) => {
    if (timeFrameValue === "custom") {
      const startDate = state.startDate || minDate;
      const endDate = state.endDate || currentDate;
      setState({ ...state, timeFrame: timeFrameValue, startDate, endDate });
    } else {
      timeFrameToStartEndDate(timeFrameValue as string);
      setState({ ...state, endDate: undefined, timeFrame: timeFrameValue });
    }
  };

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    if (date && dateError(date) === false) {
      setState({ ...state, startDate: date.startOf("day").toISOString(), timeFrame: "custom" });
    }
  };

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    if (date && dateError(date) === false) {
      const newState: CreateReportWizardState = {
        ...state,
        endDate: date.endOf("day").toISOString(),
        timeFrame: "custom",
      };
      setState(newState);
    }
  };

  function getEndIcon(isLoading: boolean, disabled: boolean, timeFrame: string | null) {
    if (timeFrame === "custom") {
      return undefined;
    }
    if (isLoading) {
      return <AiCircularProgress size={12} />;
    } else if (disabled) {
      return <Warning style={{ fontSize: 12 }} />;
    }
  }

  const minimumSignalCount = REPORT_TYPES.find((r) => r.id === state.reportType)?.minimumSignalCount || Infinity;
  const disabled = (counts?.inboxDocumentCount || 0) < minimumSignalCount;

  useEffect(() => {
    props.onValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.timeFrame]);

  useEffect(() => {
    if (time === state.timeFrame) {
      props.onValid(!disabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <>
      {time && (
        <Tooltip title={disabled ? "There are not enough signals for this time period to generate a report" : ""}>
          <div style={{ flex: "1 1 auto", height: "fit-content" }}>
            <Button
              endIcon={getEndIcon(isLoading, disabled, time)}
              disabled={(isLoading || disabled) && time !== "custom"}
              disableElevation
              variant={time === state.timeFrame ? "contained" : "outlined"}
              color={time === state.timeFrame ? "secondary" : undefined}
              style={{
                width: "100%",
                color: time === state.timeFrame ? "white" : undefined,
                paddingBottom: 12,
                paddingTop: 12,
              }}
              onClick={() => handleTimeFrameChange(time)}
            >
              {time?.replace(/\b\w/g, (l) => l.toUpperCase())}
            </Button>
          </div>
        </Tooltip>
      )}
      {state.timeFrame === "custom" && time === "custom" && (
        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          display="flex"
          width="100%"
          justifyContent="space-between"
          marginTop={2}
        >
          <KeyboardDatePicker
            label="Start Date"
            onChange={handleStartDateChange}
            minDate={minDate}
            value={state.startDate || minDate}
            disabled={!minDate}
            format="DD/MM/YYYY"
            disableFuture
          />
          <KeyboardDatePicker
            label="End Date"
            onChange={handleEndDateChange}
            value={state.endDate || currentDate}
            minDate={state.startDate || minDate}
            disableFuture
            format="DD/MM/YYYY"
          />
        </Box>
      )}
    </>
  );
}

export default function ReportInfoPage() {
  const [state, setState] = useWizardState<CreateReportWizardState>("create-report");
  const { data: entity } = useAmplyfiEntityId(state.selectedEntityId || undefined);

  function toggleNextDisabled(forceDisable?: boolean) {
    const isReportNameValid = (state.reportName || "").trim().length > 0;
    const isTimeFrameSet = !!state.timeFrame;

    setState({
      ...state,
      nextDisabled: !(isReportNameValid && isTimeFrameSet) || forceDisable === true,
    });
  }

  useEffect(() => {
    toggleNextDisabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reportName, state.timeFrame]);

  return (
    <Box padding={4}>
      <Stack spacing={2}>
        <Typography variant="h2">Create {state.reportTitle} Report</Typography>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 12 }}>
          <Typography variant="body1">Selected Interest:</Typography>
          <Typography
            style={{
              border: "1px solid #f0f0f0",
              borderRadius: 5,
              paddingInline: 8,
              paddingBlock: 4,
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.10)",
            }}
            variant="h4"
          >
            {entity?.name}
          </Typography>
        </Box>
        <TextEntryField
          onChange={(e) => setState({ ...state, reportName: e.target.value })}
          defaultValue={state.reportName}
          fullWidth
          label="Name your report"
        />
        <Box height={110} position="relative" display="flex">
          {state.validDateRanges.map((t: ReportTimeframe) => (
            <TimeRange
              time={t}
              onValid={(valid) => {
                toggleNextDisabled(!valid);
              }}
            />
          ))}
        </Box>
      </Stack>
    </Box>
  );
}
