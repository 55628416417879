import { useQueryClient } from "react-query";
import { LibraryFilter } from "../../../http/libraryFilters";
import { createDocumentLibrary } from "../../../http/search/documentLibrary";
import { DashboardItem, LibraryItem } from "../../../models/search/documentLibrary";
import useMutation from "../../useMutation";
import useOrgId from "../../useOrgId";
import { useDispatch } from "react-redux";
import { setSnack } from "../../../store/reducers/ui/snackReducer";

interface CreateFeedProps {
    name: string;
    description: string;
    dashboards: DashboardItem[];
    subscribedUserIds: string[];
    subscribedGroupIds: string[];
    allowedDocumentSectors?: LibraryFilter[];
    allowedPublishLocations?: LibraryFilter[];
    allowedDocumentLocations?: LibraryFilter[];
    blockNoPublishDate?: boolean;
}

export default function useCreateFeed() {
    const orgId = useOrgId();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation<CreateFeedProps, LibraryItem>(async (props) => {
        const data = await createDocumentLibrary(
            orgId, props.name, props.description, props.dashboards,
            props.subscribedUserIds, props.subscribedGroupIds, props.allowedDocumentSectors,
            props.allowedPublishLocations, props.allowedDocumentLocations, props.blockNoPublishDate)
        return data;
    }, [], {
        onSettled: async (feed) => {
            queryClient.setQueriesData<LibraryItem[]>(["document-libraries"], (oldData = queryClient.getQueryData<LibraryItem[]>(["document-libraries"])) => {
                if (!oldData) {
                    return [];
                }
                if (feed) {
                    return [...oldData, feed].sort((a, b) => a.name.localeCompare(b.name));
                }
                return oldData;
            });
        },
        onError: () => {
            dispatch(setSnack({ body: "There was a problem creating your library", title: "Failed" }));
        }
    })
}