import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress"
import RequiredInfoPage from "../Feed/Configuration/Create_New/RequiredInfoPage"
import Stack from "../Feed/components/stack/Stack"
import { Button, Typography, makeStyles } from "@material-ui/core"
import { useCreateFeedStore } from "../../../store/hooks/useCreateFeedStore"
import useValidation from "../../../hooks/useValidation"
import useReaderStore from "../../../store/hooks/useReaderStore"
import useAIHistory from "../../../hooks/useAIHistory";
import TextEntryField from "../Feed/Configuration/Components/TextEntryField"
import useScreenSize from "../../../hooks/useScreenSize"
import { ResearchFeed } from "../../../hooks/queries/generative-research/useEntityExtraction"
import { useDispatch } from "react-redux";
import { setSnack } from "../../../store/reducers/ui/snackReducer"
import { useEffect } from "react"
import useEntityExtraction from "../../../hooks/queries/generative-research/useEntityExtraction"
import { getEntityLogo } from "../../../helpers/imageHelpers"

const useStyles = makeStyles(({ breakpoints }) => ({
    button: {
        padding: 12,
        width: 130,
        [breakpoints.down("sm")]: {
            width: "100%",
        }
    },
    readerContainer: {
        display: "flex",
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
        width: "40%",
        [breakpoints.down("sm")]: {
            width: "100%"
        }
    },
}));

const pages = [<RequiredInfoPage />]

interface ResearchQuestionFeedPanelProps {
    historyId?: string;
}

export default function ResearchQuestionFeedPanel(props: ResearchQuestionFeedPanelProps) {
    const classes = useStyles();
    const history = useAIHistory();
    const {
        name, page, setName, reset,
        isCurrentPageValid, interests, sources, signals,
        mentionedLocations, publishedLocations, sectors,
        setInterests,
        enablePublishDate
    } = useCreateFeedStore();
    const { setCustomPanel, setSelectedPanel } = useReaderStore();
    const { valid } = useValidation(["minLength"], [], [name]);
    const screenSize = useScreenSize();
    const { data, isLoading } = useEntityExtraction(props.historyId);
    const dispatch = useDispatch();

    async function handleCreateResearchFeed(feed: ResearchFeed) {
        dispatch(setSnack({ title: "Feed is creating", body: "Click here to view your report in the feed", onClick: () => history.push("/feed") }));
        reset();
    }

    useEffect(() => {
        if (data) {
            setInterests([...data.entities, ...data.topics].map(t => ({
                id: t.wikidataId,
                name: t.name,
                image: getEntityLogo(t.wikidataId),
                type: t.type
            })));
        }
    }, [data, setInterests])

    return (
        <>
            <Stack spacing={2} padding={4}>
                <Typography variant="h2">Create a Monitoring Feed</Typography>
                <TextEntryField fullWidth label="Create new feed" placeholder="Untitled Feed" value={name} onChange={(e) => setName(e.target.value)} />
                {isLoading ? <AiCircularProgress /> : pages[page]}

                <Stack
                    direction={screenSize === "desktop" ? "horizontal" : "vertical"} spacing={2}
                    style={{ justifyContent: "flex-end", alignItems: "center", marginTop: 64, paddingBottom: 128 }}>
                    <Button className={classes.button} variant="outlined" onClick={() => {
                        setSelectedPanel(null);
                        setCustomPanel(null);
                        reset();
                    }}>
                        Cancel
                    </Button>
                    <Button
                        className={classes.button}
                        endIcon={isLoading && <AiCircularProgress size={12} />}
                        disabled={!isCurrentPageValid || !valid || isLoading}
                        style={{ color: "#FFF" }}
                        color="secondary" variant="contained"
                        onClick={() => {
                            const feedData = {
                                interests,
                                sources,
                                signals,
                                mentionedLocations,
                                publishedLocations,
                                sectors,
                                enablePublishDate
                            };
                            handleCreateResearchFeed(feedData);
                        }}>
                        {page === pages.length - 1 ? "Create Feed" : "Next"}
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}
