// eslint-disable-next-line
// @ts-ignore
import QuillMention from "quill-mention";
import quill from "quill";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { init as initSentry } from "@sentry/browser";
import mapboxgl from "mapbox-gl";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import createSagaMiddleware from "redux-saga";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { QueryParamProvider } from "use-query-params";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import appUrl from "./appUrl";
import App from "./components/App";
import "./css/root.css";
import { ignoreErrors } from "./helpers/errors";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./store/reducers";
import root from "./store/sagas/root";

quill.register({ "modules/mention": QuillMention });
const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 1000 * 60 * 5, retry: false } } });

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENV !== "local") {
  initSentry({
    ignoreErrors,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}
if (process.env.REACT_APP_MAPBOX_TOKEN) {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  // eslint-disable-next-line
  // @ts-ignore
  mapboxgl.workerClass = MapboxWorker;
}

// eslint-disable-next-line
const onRedirectCallback = (appState?: AppState) => {
  window.location.replace(appState?.targetUrl || appUrl);
};
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
    }),
    sagaMiddleware,
  ],
});
sagaMiddleware.run(root);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH_DOMAIN as string}
            clientId={process.env.REACT_APP_AUTH_CLIENT_ID as string}
            redirectUri={appUrl}
            audience={process.env.REACT_APP_AUTH_AUDIENCE as string}
            onRedirectCallback={onRedirectCallback}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </Auth0Provider>
        </QueryParamProvider>
      </BrowserRouter>
    </Provider>
    <link href="https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css" rel="stylesheet" />
  </QueryClientProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
