import AiButton from "@amplyfi/ui-components/components/Button";
import { Box } from "@material-ui/core";
import { Wizard, useWizard } from "react-use-wizard";
import ReportTypeSelector from "./pages/ReportTypeSelector";
import ReportInfoPage from "./pages/ReportInfoPage";
import ReportQuestionPage from "./pages/ReportQuestionPage";
import useWizardState from "../../../../../../hooks/useWizardState";
import { BaseWizardState } from "../../../../../Widgets/Wizard/Wizard";
import ReportConfirmPage from "./pages/ReportConfirmPage";
import ReportInterestSearch from "./pages/ReportInterestSearch";
import { useFeedViewStore } from "../../../../../../store/hooks/useFeedViewStore";
import { useEffect } from "react";

function Footer() {
  const { previousStep, isFirstStep, isLastStep, nextStep, goToStep, activeStep } = useWizard();
  const [state, setState] = useWizardState<CreateReportWizardState>("create-report");

  return (
    <Box
      paddingLeft={4}
      paddingRight={4}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
      paddingY={2}
      position={"sticky"}
      bottom={0}
      zIndex={1}
      bgcolor="white"
    >
      {!isLastStep && (
        <>
          <AiButton
            disabled={isFirstStep}
            onClick={() => {
              if (state.reportType !== "ResearchQuestion" && activeStep === 4) { goToStep(2) }
              else { previousStep(); }
            }}
          >
            Previous
          </AiButton>
          <AiButton
            disabled={isLastStep || state.nextDisabled}
            onClick={() => {
              setState({ ...state, nextDisabled: true });
              if (state.reportType !== "ResearchQuestion" && activeStep === 2) { goToStep(4) }
              else { nextStep(); }
            }}
          >
            Next
          </AiButton>
        </>
      )}
    </Box>
  );
}

export type ReportTimeframe = "1 week" | "1 month" | "3 months" | "custom" | null;

export interface CreateReportWizardState extends BaseWizardState {
  reportName: string | null;
  reportType: string | null;
  timeFrame: ReportTimeframe;
  startDate?: string | null;
  endDate?: string | null;
  selectedEntityId: string | null;
  reportTitle: string | null;
  validDateRanges: ReportTimeframe[];
  reportQuestion: string | null;
  reportValidated: boolean;
  userId: string | null;
}

export const initialState: CreateReportWizardState = {
  reportName: null,
  reportType: null,
  timeFrame: null,
  nextDisabled: true,
  initialPage: 0,
  startDate: null,
  endDate: null,
  selectedEntityId: null,
  reportTitle: null,
  validDateRanges: [],
  reportQuestion: null,
  reportValidated: false,
  userId: null,
};

export enum ReportStatus {
  InProgress = "InProgress",
  Pending = "Pending",
  Done = "Done",
  Failed = "Failed",
}

export interface Report {
  reportName: string;
  reportType: string;
  reportId?: string;
  report?: string;
  startDate?: string | null;
  endDate?: string | null;
  status?: ReportStatus;
  created: string;
  entityId?: string;
  libraryId?: string;
  question?: string | null;
  userId: string | null;
}

export default function CreateReportWizard() {
  const { amplyfiEntityId } = useFeedViewStore();
  const [state, setState] = useWizardState<CreateReportWizardState>("create-report");

  useEffect(() => {
    if (amplyfiEntityId) {
      setState({
        ...state,
        selectedEntityId: amplyfiEntityId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amplyfiEntityId]);

  return (
    <Wizard startIndex={amplyfiEntityId ? 1 : state.initialPage || 0} footer={<Footer />}>
      <ReportInterestSearch />
      <ReportTypeSelector />
      <ReportInfoPage />
      <ReportQuestionPage />
      <ReportConfirmPage />
    </Wizard>
  );
}
