import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog } from "@material-ui/core";
import clsx from "clsx";
import { Silver } from "@amplyfi/ui-components/theme/colors/color";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { TestDataProps } from "@amplyfi/ui-components/types";

interface ModalProps extends React.ComponentProps<typeof Dialog>, Partial<TestDataProps> {
  title?: string;
  showCloseIcon?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      "& .MuiPaper-rounded": {
        borderRadius: "10px",
      },
    },
    backgroundOverlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundColor: "black",
      opacity: 0.5,
      zIndex: 1,
    },
    closeIcon: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
      cursor: "pointer",
      color: Silver,
    },
    content: {
      color: "black",
      position: "relative",
      padding: `${theme.spacing(7.5)}px ${theme.spacing(8)}px `,
    },
    titleContainer: {
      fontSize: "40px",
      textAlign: "left",
      marginTop: 0,
      color: theme.palette.primary.main,
    },
  })
);

const Modal = (props: ModalProps): JSX.Element => {
  const classes = useStyles();
  const { className, title, children, showCloseIcon = false, onClose, "data-testid": dataTestId, ...rest } = props;

  return (
    <>
      <Dialog
        className={clsx(classes.container, className)}
        data-testid={testId(dataTestId)}
        {...rest}
        onClose={onClose}
        fullWidth
      >
        <div className={classes.content}>
          {showCloseIcon && (
            <CloseIcon
              className={classes.closeIcon}
              data-testid={testId(dataTestId, "close")}
              onClick={!!onClose ? (e) => onClose(e, "backdropClick") : undefined}
            />
          )}
          {title && (
            <h1 className={classes.titleContainer} data-testid={testId(dataTestId, "title")}>
              {title}
            </h1>
          )}
          <div>{children}</div>
        </div>
      </Dialog>
    </>
  );
};

export default Modal;
