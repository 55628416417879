import ReadMoreTemplate, { CustomisationList } from "../components/Pages/Analyse/ReadMoreTemplate";

export interface CardDetails {
  title: string;
  description: string;
  readMore: JSX.Element | string;
  dataTestId?: string;
}

interface CardDetailsProps {
  [key: string]: CardDetails;
}

export const cardDetails: CardDetailsProps = {
  documentsOverTime: {
    title: "Documents Timeline",
    description: "A line chart view of Document count over time.",
    readMore: (
      <ReadMoreTemplate
        overview="A line chart view of Document count over time."
        usefulFor="Noticing changes in publishing that could indicate increasing or decreasing research, innovation and discussion around a subject."
        availableFor="Single Organisation, Multiple Organisations, Single Topics, Multiple Topics, People, Places."
      />
    ),
  },
  keyPhrases: {
    title: "List of Keyphrases",
    description:
      "The most prevalent phrases found in your results. This analysis combines common, related terms into groups, so that you can find both strong and weak signals more easily.",
    readMore: (
      <ReadMoreTemplate overview="The most prevalent phrases found in your results. This analysis combines common, related terms into groups, so that you can find both strong and weak signals more easily." />
    ),
    dataTestId: "key-phrases-card",
  },
  company: {
    title: "Organisation overview",
    description: "An overview of the first organisation identified in your search query.",
    readMore: (
      <ReadMoreTemplate
        overview="An overview of the first organisation identified in your search query."
        usefulFor="Quickly understanding an organisation and its broad activities and this has been correctly identified within the system."
        availableFor="Single Organisation, Multiple Organisations"
      />
    ),
  },
  geography: {
    title: "Geography",
    description:
      "An overview of the distribution of Documents by country, shaded to indicate the number of documents associated with a location.",
    readMore: (
      <ReadMoreTemplate
        overview="An overview of the distribution of Documents by country, shaded to indicate the number of documents associated with a location."
        usefulFor="Identifying the locations most involved in your subject matter, so that you can focus future queries better."
        availableFor=" Single Organisation, Multiple Organisations, Single Topics, Multiple Topics, People, Places."
        customisations={
          <CustomisationList
            data={[
              {
                title: "Published",
                text: "The countries where the documents returned by your query were published.",
              },
              {
                title: "Mentioned",
                text: "The countries most discussed in the documents returned by your query.",
              },
            ]}
          />
        }
      />
    ),
    dataTestId: "geography-card",
  },
  documentSector: {
    title: "Document Sector Connection",
    description:
      "The top 5 sectors and related activities, found in documents, that the first organisation in your search is actively involved in.",
    readMore: (
      <ReadMoreTemplate
        overview="The top 5 sectors and related activities, found in documents, that the first organisation in your search is actively involved in."
        usefulFor="Identifying unknown activities related to an organisation."
        availableFor="Single Organisation."
      />
    ),
  },
  sentimentOverTime: {
    title: "Sentiment Over Time",
    description:
      "The average sentiment, scored from -1 (strong negative) to +1 (strong positive) in Documents, related to your search.",
    readMore: (
      <ReadMoreTemplate
        overview="The average sentiment, scored from -1 (strong negative) to +1 (strong positive) in Documents, related to your search."
        usefulFor="Monitoring media sentiment trends, spikes and dips to narrow timelines to interesting events."
      />
    ),
  },
  globalMarket: {
    title: "Global Market Coverage",
    description:
      "Shows the regional connection of entities or topics identified as interesting within in the documents returned by your search.",
    readMore: (
      <ReadMoreTemplate
        overview="Shows the regional connection of entities or topics identified as interesting within in the documents returned by your search."
        usefulFor="Identifying the geographic spread of relevant organisations or topics."
        availableFor="Single Organisation, Multiple Organisations, Single Topics, Multiple Topics, People, Places"
        customisations="Customise the information in the graph using key phrases, organisations or people"
      />
    ),
    dataTestId: "global-market-card",
  },
  sentimentRegions: {
    title: "Sentiment Across Countries",
    description: "The average sentiment of Documents in your results, divided by the Country they are published in.",
    readMore: (
      <ReadMoreTemplate
        overview="The average sentiment of Documents in your results, divided by the Country they are published in."
        usefulFor="Monitoring media sentiment in different countries to identify strengths and weaknesses in reputation."
        availableFor="Single Organisation, Multiple Organisations, Single Topics, Multiple Topics, People, Places"
      />
    ),
    dataTestId: "sentiment-region-card",
  },
  trendsOverTime: {
    title: "Trends Over Time",
    description:
      "For key topics or entities, view their trend over time and be able to compare with other detected trends.",
    readMore: (
      <ReadMoreTemplate
        overview="For key topics or entities, view their trend over time and be able to compare with other detected trends."
        usefulFor="Spotting changes trends, spikes and dips of topic significance, against other topics."
        availableFor="Single Organisation, Multiple Organisations, Single Topics, Multiple Topics, People, Places."
      />
    ),
    dataTestId: "trends-over-time",
  },
  sentiment: {
    title: "Sentiment",
    description: "The number of Documents scored by sentiment from -1 (strong negative) to +1 (strong positive).",
    readMore: (
      <ReadMoreTemplate
        overview="The number of Documents scored by sentiment from -1 (strong negative) to +1 (strong positive)."
        usefulFor="Spotting any skew in sentiment e.g. lots of negative and positve with few neutrail, or focusing in on documents that are particularly negative or positive."
        availableFor="Single Organisation, Multiple Organisations, Single Topics, Multiple Topics, People, Places."
      />
    ),
    dataTestId: "sentiment-bars-graph",
  },
  sentimentSectors: {
    title: "Sentiment Across Sectors",
    description:
      "The Sentiment of Documents (Positive or Negative) linked to the first Organisation in your Query, grouped by the Sector of the Document. Showing the Total Number of Documents in your Results grouped in that Sector.",
    readMore: (
      <ReadMoreTemplate
        overview="The Sentiment of Documents (Positive or Negative) linked to the first Organisation in your Query, grouped by the Sector of the Document. Showing the Total Number of Documents in your Results grouped in that Sector."
        usefulFor=" Monitoring Organisation Media Sentiment across sectors."
        availableFor="Single Organisation"
      />
    ),
    dataTestId: "sentiment-across-sectors",
  },
  sentimentSentences: {
    title: "Sentiment Across Sentences",
    description: "Positive and Negative Mentions of the subject(s) of your query in Documents.",
    readMore: (
      <ReadMoreTemplate
        overview="Positive and Negative Mentions of the subject(s) of your query in Documents."
        usefulFor="Identifying the cause of sentiment changes."
        availableFor="Single Organisation, Multiple Organisations, Single Topics, Multiple Topics, People, Places."
      />
    ),
    dataTestId: "sentiment-across-sentences",
  },
  adverseMedia: {
    title: "Adverse Media",
    description:
      'The negative connections between an organisation and "Adverse Media" events in Documents at a mention, by mention level',
    readMore: (
      <ReadMoreTemplate
        overview='The negative connections between an organisation and "Adverse Media" events in Documents at a mention, by mention level'
        usefulFor="Commercial Due Diligence related to an organisation."
        availableFor="Single Organisation"
      />
    ),
    dataTestId: "adverse-media-component",
  },
  riskGraph: {
    title: "Risk Monitoring",
    description:
      'A timeline of Risk Events, linked to the Organisation in your search, that indicate "Credit Distress" based on Documents. Covering - public opinion, loss of contracts, loss of key people.',
    readMore: (
      <ReadMoreTemplate
        overview='A timeline of Risk Events, linked to the Organisation in your search, that indicate "Credit Distress" based on Documents. Covering - public opinion, loss of contracts, loss of key people.'
        usefulFor="Events that indicate future risk, beyond the standard Adverse Media."
        availableFor="Single Organisation"
        functionality="Tooltip hover on bar to view related documents."
        customisations="Adapt the timeline Toggle items on or off."
      />
    ),
    dataTestId: "risk-monitoring-graph",
  },
  riskSentences: {
    title: "Risk Sentences",
    description:
      'Mentions in Documents linking the first organisation in your query with Risks, that indicate "Credit Distress".',
    readMore: (
      <ReadMoreTemplate
        overview=' Mentions in Documents linking the first organisation in your query with Risks, that indicate "Credit Distress".'
        usefulFor="Identifying specific causes of risks"
        availableFor="Single Organisation"
        customisations="Filter by risk mentioned"
      />
    ),
    dataTestId: "risk-sentences",
  },
  sectorSentences: {
    title: "Active In Sentences",
    description: "Mentions in Documents linking the first organisation in your query with sectors.",
    readMore: (
      <ReadMoreTemplate
        overview="Mentions in Documents linking the first organisation in your query with sectors."
        usefulFor="Finding weaker signals linking an organisation to broader range of activities than sector classification."
        availableFor="Single Organisation"
        customisations="Filter by sector mentioned"
      />
    ),
    dataTestId: "active-in-sentences",
  },
  heatMap: {
    title: "Connection Strength",
    description:
      "Identify which organisations or people are connected most strongly to topics identified in documents returned by your search.",
    readMore: (
      <ReadMoreTemplate
        overview="Identify which organisations or people are connected most strongly to topics identified in documents returned by your search."
        usefulFor="Side-by-side comparison of interests, based on connection strength."
        availableFor="Single Organisation, Multiple Organisations, Single Topics, Multiple Topics, People, Places."
        customisations="Customise the information in the graph using key phrases, organisations or people."
      />
    ),
    dataTestId: "heat-map-component",
  },
  organisations: {
    title: "List of Organisations",
    description: "The most connected Organisations found in Documents in your results.",
    readMore: <ReadMoreTemplate overview="The most connected Organisations found in Documents in your results." />,
    dataTestId: "organisations-list-card",
  },
  people: {
    title: "List of People",
    description: "The most connected People found in Documents in your results.",
    readMore: <ReadMoreTemplate overview="The most connected People found in Documents in your results." />,
    dataTestId: "people-list-card",
  },
  locations: {
    title: "List of Locations",
    description: "The most connected Locations found in Documents in your results.",
    readMore: <ReadMoreTemplate overview="The most connected Locations found in Documents in your results." />,
    dataTestId: "locations-list-card",
  },
  locationsOverTime: {
    title: "Locations Over Time",
    description:
      "For locations or entities, view their locations over time and be able to compare with other detected locations.",
    readMore: (
      <ReadMoreTemplate
        overview="For key locations or entities, view their locations over time and be able to compare with other detected locations."
        usefulFor="Changes in location, spikes and dips of topic significance, against other topics."
        availableFor="Single Organisation, Multiple Organisations, Single Topics, Multiple Topics, People, Places."
      />
    ),
    dataTestId: "locations-over-time",
  },
  comparison: {
    title: "Comparison",
    description: "A comparison of the documents returned by your search",
    readMore: (
      <ReadMoreTemplate
        overview="Explore the differences between entities in your libraries."
        usefulFor="Comparing changes between entities in your libraries"
        availableFor=""
      />
    ),
    dataTestId: "comparison-over-time",
  },
};
