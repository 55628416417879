import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import { Box, Button, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import useCreateFeed from "../../../../../hooks/mutations/feed/useCreateFeed";
import useAIHistory from "../../../../../hooks/useAIHistory";
import useOrgId from "../../../../../hooks/useOrgId";
import useScreenSize from "../../../../../hooks/useScreenSize";
import useValidation from "../../../../../hooks/useValidation";
import { createAlertForLibrary } from "../../../../../http/alert-types";
import { linkEntityToLibrary } from "../../../../../http/feed";
import { linkConfigToLibrary } from "../../../../../http/harvesterCollections";
import { useCreateFeedStore } from "../../../../../store/hooks/useCreateFeedStore";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import { useAuth0 } from "../../../../Auth0/AuthWrapper";
import Reader from "../../components/reader/Reader";
import Stack from "../../components/stack/Stack";
import TextEntryField from "../Components/TextEntryField";
import { CreateFeedSectionListItem } from "./CreateFeedSection";
import RequiredInfoPage from "./RequiredInfoPage";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
    root: {
        display: "flex",
        width: "100%",
        position: "relative",
        paddingBottom: spacing(12),
    },
    contentContainer: {
        flex: "0 0 60%",
        padding: spacing(8),
        [breakpoints.down("sm")]: {
            flex: "0 0 100%",
            padding: spacing(2),
        }
    },
    readerContainer: {
        display: "flex",
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
        width: "40%",
        [breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    button: {
        padding: 12,
        width: 130,
        [breakpoints.down("sm")]: {
            width: "100%",
        }
    }
}));

export function getSectionItem<T extends CreateFeedSectionListItem>(items: T[], id: string): T {
    return items.find(i => i.id === id) as T;
}

const pages = [<RequiredInfoPage />]

export default function CreateFeed() {
    const classes = useStyles();
    const {
        name, page, setName, nextPage, reset,
        isCurrentPageValid, interests, sources, signals,
        mentionedLocations, publishedLocations, sectors,
        enablePublishDate,
        setInterests,
        setSignals,
        setSources,
    } = useCreateFeedStore();
    const { setCustomPanel, setSelectedPanel, selectedPanel, reset: resetReader } = useReaderStore();
    const { valid } = useValidation(["minLength"], [], [name]);
    const { mutateAsync: createFeed, isLoading: creating } = useCreateFeed();
    const [saving, setSaving] = useState(false);

    function handleNext() {
        setSelectedPanel(null);
        setCustomPanel(null);

        if (page === pages.length - 1) {
            save();
        } else {
            nextPage();
        }
    }

    const history = useAIHistory();
    const orgId = useOrgId();
    const user = useAuth0();

    useEffect(() => {
        resetReader();
        setInterests([]);
        setSignals([]);
        setSources([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function save() {
        setSaving(true);
        const newLibrary = await createFeed(
            {
                name,
                description: "",
                dashboards: interests.map((c) => ({
                    id: c.id,
                    amplyfiEntityId: c.id,
                    name: c.name,
                    entityName: c.name,
                    description: undefined,
                    dashboardType: "CompetitorAnalysis",
                    subjectType: "Entity",
                })),
                subscribedUserIds: [user.user.sub as string],
                subscribedGroupIds: [],
                allowedDocumentSectors: sectors,
                allowedPublishLocations: publishedLocations,
                allowedDocumentLocations: mentionedLocations,
                blockNoPublishDate: enablePublishDate
            }
        );

        if (newLibrary.id) {
            await Promise.all(
                [
                    ...interests.map((c) =>
                        linkEntityToLibrary(orgId, newLibrary.id as string, c.id)
                    ),
                    ...sources.map((config) =>
                        linkConfigToLibrary(orgId, newLibrary.id as string, config.id as string)
                    ),
                    ...signals.map((alertType) => alertType.feeds.map((feed) =>
                        createAlertForLibrary(newLibrary.id as string,
                            { alertTypeId: alertType.id, libraryId: newLibrary.id as string, amplyfiEntityId: feed }
                        )
                    ))
                ]
            );
        }
        reset();
        history.pushFeed(newLibrary.id as string, null);
        setSaving(false);
    }

    const screenSize = useScreenSize();

    return (
        <Box className={classes.root}>
            <Stack className={classes.contentContainer} spacing={2}>
                <TextEntryField fullWidth label="Create new feed" placeholder="Untitled Feed" value={name} onChange={(e) => setName(e.target.value)} />
                {pages[page]}
                <Stack
                    direction={screenSize === "desktop" ? "horizontal" : "vertical"} spacing={2}
                    style={{ justifyContent: "flex-end", alignItems: "center", marginTop: 64, paddingBottom: 128 }}>

                    <Button className={classes.button} variant="outlined" onClick={() => {
                        setSelectedPanel(null);
                        setCustomPanel(null);
                        reset();
                        history.goBack()
                    }}>Cancel</Button>
                    <Button
                        className={classes.button}
                        endIcon={creating && <AiCircularProgress size={12} />}
                        disabled={!isCurrentPageValid || !valid || creating || saving}
                        style={{ color: "#FFF" }}
                        color="secondary" variant="contained"
                        onClick={handleNext}>{page === pages.length - 1 ? "Create Feed" : "Next"}
                    </Button>
                </Stack>
            </Stack>
            {selectedPanel && <Box className={classes.readerContainer}><Reader /></Box>}
        </Box>
    )
}