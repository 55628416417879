import { foragerClient } from "./connectors";

const LOGIN_URL = `${process.env.REACT_APP_FORAGER_URL}/v1/security`;

export async function login(connectorId: string): Promise<void> {
  localStorage.setItem("connectorAuthUrl", window.location.href);
  localStorage.setItem("connectorAuthId", connectorId);
  const endpoint = await getEndpoint(connectorId);
  window.open(endpoint, "_self");
  return;
}

async function getEndpoint(connectorId: string): Promise<string> {
  const { data } = await foragerClient.get(`${LOGIN_URL}/${connectorId}`);
  return data.endpoint;
}

export async function authorize(code: string): Promise<boolean> {
  try {
    await foragerClient.put(`${LOGIN_URL}/${localStorage.getItem("connectorAuthId")}`, {
      code,
    });
    window.location.href = localStorage.getItem("connectorAuthUrl") as string;
    localStorage.removeItem("connectorAuthUrl");
    localStorage.removeItem("connectorAuthId");
    return true;
  } catch (e) {
    localStorage.removeItem("connectorAuthUrl");
    localStorage.removeItem("connectorAuthId");
    return false;
  }
}
