import { CheckCircleOutline as CheckIcon } from "@material-ui/icons";
import { Validation } from "./Validation";
import React from "react";

export const QueryDoesNotReturnEstimate = (): JSX.Element => (
  <Validation
    icon={<CheckIcon style={{ color: "green" }} />}
    title={"The query was valid, but this harvest type does not return a total."}
  />
);
