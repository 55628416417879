import AiButton from "@amplyfi/ui-components/components/Button";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import IconButton from "@amplyfi/ui-components/components/IconButton";
import { DarkSilver } from "@amplyfi/ui-components/theme/colors";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  TableContainer,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateRangeOutlined, DeleteOutline, EditOutlined, MoreHoriz, Timeline } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as EmptyAnalysisIcon } from "../../../assets/empty-analysis.svg";
import useSavedAnalysesList from "../../../hooks/useSavedAnalysesList";
import { deleteAnalysis } from "../../../http/saved-analyses";
import { Analysis } from "../../../models/analysis";
import { toggleDrawerOpen } from "../../../store/reducers/searchRequest/filterReducer";
import {
  clearAllFilters,
  clearDocumentLibraryId,
  resetFilterOptions,
  updateSelectedAnalysis,
} from "../../../store/reducers/searchRequest/urlReducer";
import CreateAnalysisDialog from "./CreateAnalysisDialog";
import SavedAnalysisRenameDialog from "./SavedAnalysisRenameDialog";
import { clearSelectedDocument } from "../../../store/reducers/searchResponse/relatedDocumentViewerReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyState: {
      height: 400,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    emptyStateContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      "& > *": {
        marginBottom: theme.spacing(2),
      },
    },
    textContainer: {
      textAlign: "center",
    },
    table: {
      tableLayout: "fixed",
      width: "100%",
      borderSpacing: 0,
    },
    row: {
      border: 0,
      "&:first-of-type": {
        "& td": {
          borderTop: 0,
        },
      },
    },
    body: {
      border: 0,
    },
    header: {
      background: theme.palette.appBackground.main,
      border: 0,
      outline: 0,
      padding: 0,
      margin: 0,
      height: 40,
    },
    headerCell: {
      border: 0,

      "& > span": {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        whiteSpace: "nowrap",
        "& > :first-child": {
          marginRight: theme.spacing(1),
        },
      },
    },
    cell: {
      borderTop: "1px solid #E8E8E8",
      borderBottom: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      margin: 0,

      "&:first-of-type": {
        paddingLeft: theme.spacing(2),
      },
      "&:last-of-type": {
        paddingRight: theme.spacing(2),
      },
    },
    firstColumn: {
      width: "70%",

      "& span": {
        justifyContent: "start",
      },
    },
    menuItemIcon: {
      minWidth: 32,
    },
    menuItemDelete: {
      color: theme.palette.error.main,
    },
    link: {
      color: theme.palette.links.primary,
      textDecoration: "none",
      fontSize: 11,
      fontWeight: "bold",
      letterSpacing: 0.2,
    },
    infoText: {
      color: DarkSilver,
    },
    title: {
      cursor: "pointer",
    },
  })
);

function EmptyState(): JSX.Element {
  const classes = useStyles();
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Paper className={classes.emptyState}>
      <div className={classes.emptyStateContainer}>
        <EmptyAnalysisIcon />
        <div className={classes.textContainer}>
          <Typography className={classes.infoText} variant="body1">
            You have no Analyses yet.
          </Typography>
          <Typography className={classes.infoText} variant="body1">
            Analyses will help you to understand patterns in large number of documents
          </Typography>
        </div>
        <AiButton
          onClick={() => {
            history.push({
              pathname: "/analyse/overview",
              search: window.location.search,
            });
            dispatch(clearAllFilters());
            dispatch(clearDocumentLibraryId());
            dispatch(clearSelectedDocument());
            dispatch(resetFilterOptions());
            dispatch(toggleDrawerOpen(true));
            return;
          }}
          style={{ padding: "10px 24px" }}
        >
          Create Analysis
        </AiButton>
        <Link className={classes.link} target="_blank" to={{ pathname: "https://amplyfi.com/support/" }}>
          Visit our Support Site
        </Link>
      </div>
      {createDialogOpen && (
        <CreateAnalysisDialog
          onClose={() => {
            setCreateDialogOpen(false);
          }}
        />
      )}
    </Paper>
  );
}

export default function SavedAnalysisTable(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { data: analyses = [], isLoading, refetch } = useSavedAnalysesList();
  const [anchorEl, setAnchor] = useState<HTMLElement | null>(null);
  const [selectedAnalysis, setSelectedAnalysis] = useState<Analysis | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  function handleMoreClicked(event: React.MouseEvent<HTMLElement, MouseEvent>, analysis: Analysis) {
    setSelectedAnalysis(analysis);
    setAnchor(event.currentTarget);
  }

  function onMenuClose() {
    setAnchor(null);
  }

  function onTitleClicked(analysis: Analysis) {
    dispatch(updateSelectedAnalysis(analysis));
    history.push({
      pathname: "/analyse/overview",
      search: window.location.search,
    });
  }

  if (isLoading) {
    return <AiCircularProgress />;
  }

  if (analyses.length === 0) {
    return <EmptyState />;
  }

  async function removeAnalysis() {
    setAnchor(null);
    await deleteAnalysis(selectedAnalysis?.id as string);
    setDeleteDialogOpen(false);
    refetch();
  }

  return (
    <div>
      <TableContainer elevation={0} component={Paper}>
        <table className={classes.table}>
          <thead className={classes.header}>
            <tr>
              <td className={clsx(classes.firstColumn, classes.headerCell)}>
                <span>
                  <Timeline htmlColor="#989FAE" /> <Typography variant="caption">Analysis Name</Typography>
                </span>
              </td>
              <td align="right" className={classes.headerCell}>
                <span>
                  <DateRangeOutlined htmlColor="#989FAE" /> <Typography variant="caption">Date Created</Typography>
                </span>
              </td>
              <td className={classes.headerCell}></td>
            </tr>
          </thead>
          <tbody className={classes.body}>
            {analyses.map((analysis, index) => (
              <tr className={classes.row} key={`${analysis.id}`}>
                <td
                  onClick={() => onTitleClicked(analysis)}
                  className={clsx(classes.firstColumn, classes.cell, classes.title)}
                >
                  <Typography variant="h4">{analysis.name}</Typography>
                </td>
                <td align="right" className={classes.cell}>
                  <Typography variant="h4">
                    {moment(analysis.date_created).format("DD, MMM, YYYY").toString()}
                  </Typography>
                </td>
                <td align="right" className={classes.cell}>
                  <IconButton
                    onClick={(e) => handleMoreClicked(e, analysis)}
                    icon={<MoreHoriz htmlColor="#989FAE" />}
                  ></IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={onMenuClose}
        open={anchorEl !== null}
        anchorEl={anchorEl}
      >
        <MenuItem onClick={() => setRenameDialogOpen(true)}>
          <ListItemIcon className={classes.menuItemIcon}>
            <EditOutlined />
          </ListItemIcon>
          Edit Name
        </MenuItem>
        <MenuItem onClick={() => setDeleteDialogOpen(true)} className={classes.menuItemDelete}>
          <ListItemIcon className={classes.menuItemIcon}>
            <DeleteOutline color="error" />
          </ListItemIcon>
          Delete Analysis
        </MenuItem>
      </Menu>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h2">Delete Analysis</Typography>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600 }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your analysis?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AiButton
            amplyfiType="secondary"
            onClick={() => {
              setAnchor(null);
              setDeleteDialogOpen(false);
            }}
          >
            Cancel
          </AiButton>
          <AiButton amplyfiType="negative" onClick={() => removeAnalysis()}>
            Yes, delete it
          </AiButton>
        </DialogActions>
      </Dialog>

      {renameDialogOpen && selectedAnalysis && (
        <SavedAnalysisRenameDialog
          analysis={selectedAnalysis}
          onSave={() => {
            setAnchor(null);
            setRenameDialogOpen(false);
            refetch();
          }}
          onClose={() => {
            setAnchor(null);
            setRenameDialogOpen(false);
          }}
        />
      )}
    </div>
  );
}
