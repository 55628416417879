import AiButton from "@amplyfi/ui-components/components/Button";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { getPermissions } from "../../../../helpers/user";
import { ConnectorGroup, ConnectorVisibility } from "../../../../http/connectors";
import { useAuth0 } from "../../../Auth0/AuthWrapper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldWrapper: {
      minWidth: 600,
      display: "flex",
    },
    textField: {
      flex: "1 1 auto",
      "& .MuiFormControlLabel-label": {
        marginRight: "auto",
      },
    },
  })
);

interface CreateConnectorProps {
  initialGroup?: ConnectorGroup;
  onClose: () => void;
  onConfirm: (name: string, scope: ConnectorVisibility) => void;
}

export default function CreateConnectorGroupModal(props: CreateConnectorProps): JSX.Element {
  const classes = useStyles();
  const { onClose, onConfirm, initialGroup } = props;
  const { user } = useAuth0();

  const scopes = getPermissions(user);
  const isOrgAdmin = scopes.includes("delete:organisation:connectors");

  const [sourceName, setSourceName] = useState(initialGroup?.name || "");
  const [selectedScope, setSelectedScope] = useState<ConnectorVisibility>(
    initialGroup?.scope || ConnectorVisibility.User
  );
  return (
    <Dialog maxWidth="lg" open onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h2">Create source group</Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.fieldWrapper}>
          <FormControlLabel
            className={classes.textField}
            control={
              <TextField
                fullWidth
                defaultValue={sourceName}
                style={{ display: "block" }}
                placeholder="Enter source group name"
                variant="outlined"
                onChange={(e) => setSourceName(e.target.value)}
              />
            }
            labelPlacement="top"
            label="Source group name"
          />
        </div>
        <div className={classes.fieldWrapper}>
          <FormControlLabel
            style={{ marginTop: 16 }}
            className={classes.textField}
            label="Available to"
            labelPlacement="top"
            disabled={initialGroup !== undefined || !isOrgAdmin}
            control={
              <Select
                disabled={initialGroup !== undefined || !isOrgAdmin}
                fullWidth
                defaultValue={selectedScope}
                onChange={(e) => setSelectedScope(e.target.value as ConnectorVisibility)}
              >
                <MenuItem value={ConnectorVisibility.User}>Me</MenuItem>
                <MenuItem value={ConnectorVisibility.Organisation}>My Organisation</MenuItem>
              </Select>
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <AiButton style={{ marginLeft: "auto" }} amplyfiType="secondary" onClick={onClose}>
          Cancel
        </AiButton>
        <AiButton onClick={() => onConfirm(sourceName, selectedScope)}>Confirm</AiButton>
      </DialogActions>
    </Dialog>
  );
}
