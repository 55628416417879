import {
    Box,
    Card,
    Chip,
    Theme,
    Typography,
    makeStyles
} from "@material-ui/core";
import { Business } from "@material-ui/icons";
import clsx from "clsx";
import { ReactComponent as SignalIcon } from "../../../../../assets/signal.svg";
import { distinct } from "../../../../../helpers/arrayHelpers";
import { getDocumentLogo, getWebsiteLogo } from "../../../../../helpers/imageHelpers";
import useAIHistory from "../../../../../hooks/useAIHistory";
import { useFeedDocument } from "../../../../../hooks/useFeed";
import {
    MonitoringDocument
} from "../../../../../models/monitoring";
import useDialogStore from "../../../../../store/hooks/useDialogStore";
import useReaderStore from "../../../../../store/hooks/useReaderStore";
import MonitorNewOrganisationDialog from "../../MonitorNewOrganisationDialog";
import AiImage from "../../components/Image";
import Stack from "../../components/stack/Stack";
import ResponsiveHorizontalList from "./ResponsiveHorizontalList";
import { SignalCardHeader } from "./SignalCardHeader";
import { allAlertTypesFromSentences, reduceEntityHighlightsInSentences, reduceRelatedEntitiesInSentences } from "./utils";
import { StrongSignalIcon } from "../Components/StrongSignalIcon";
import { WeakSignalIcon } from "../Components/WeakSignalIcon";

const useStyles = makeStyles<Theme, { compact: boolean; }>(({ spacing }) => ({
    itemImage: {
        height: 100,
        width: 100,
        objectFit: "cover",
        borderRadius: 4,
        marginLeft: "auto",
    },
    insightEntityImage: {
        maxWidth: 32,
        maxHeight: 32,
    },
    signalCard: {
        cursor: "pointer",
        marginBottom: spacing(2),
        paddingTop: spacing(2),
        padding: 0,
    },
    selectedCard: {
        borderLeft: "4px solid #004FD0",
    },
    chipsStyle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: spacing(1),
    },
    chipStyle: {
        display: "flex",
        alignItems: "center",
        fontWeight: "bolder",
        gap: spacing(0.5),
    },
}));

export interface SignalCardProps {
    compact?: boolean;
    showReasoning?: boolean;
    feedDocument: MonitoringDocument;
}

function SignalCardContent(props: SignalCardProps) {
    const classes = useStyles({ compact: props.compact || false });
    return <Box style={{ cursor: "pointer" }} paddingLeft={2} paddingRight={2} display="grid" gridTemplateColumns="1fr 100px" gridGap={16}>
        <Stack spacing={2}>
            <Typography style={{ lineHeight: 1.5 }} variant="h3">
                {props.feedDocument.title}
            </Typography>
            {!props.compact && <Typography style={{ fontSize: 15, lineHeight: 1.6, color: "#737373" }} variant="body2">
                {props.feedDocument.sentences[0].text}
            </Typography>}
        </Stack>
        <AiImage ErrorImage={<></>}
            className={classes.itemImage}
            src={getDocumentLogo(props.feedDocument.documentId, true)}
            alt={props.feedDocument.title} />
    </Box>;
}

function SignalCardFooter(props: SignalCardProps) {
    const classes = useStyles({ compact: props.compact || false });
    const { feedDocument } = props;
    const isStrong = feedDocument.strength === "Strong";
    const isWeak = feedDocument.strength === "Weak";

    return <Box padding={2} display="flex" justifyContent="space-between">
        <Stack onClick={(e) => {
            e.stopPropagation();
            window.open(props.feedDocument.url, "_blank");
        }
        } direction="horizontal" spacing={2}>
            <AiImage
                displayErrorAlt
                className={clsx(classes.insightEntityImage, classes.logoImage)}
                src={feedDocument.url ? getWebsiteLogo(new URL(feedDocument.url).host) : undefined}
                alt={feedDocument.source}
                ErrorImage={undefined}
            />
            <Stack direction="horizontal" spacing={2}>
                <Typography style={{ fontSize: 13, color: "#232E3A", fontWeight: 600 }} variant="body2">
                    {props.feedDocument.source}
                </Typography>
            </Stack>
        </Stack>
        <Box className={classes.chipsStyle}>
            {props.feedDocument.isEvent && <Chip label="Event" />}
            {isStrong && (
                <Typography className={classes.chipStyle}>
                    <StrongSignalIcon /><span>Strong</span>
                </Typography>
            )}
            {isWeak && (
                <Typography className={classes.chipStyle}>
                    <WeakSignalIcon /><span>Weak</span>
                </Typography>
            )}
        </Box>
    </Box>;
}

function mapEntitiesToChips(entities: string[], matched: boolean) {
    const chips = distinct(entities).map((entity) => {
        return {
            id: entity,
            name: entity,
            disabled: matched,
            highlighted: matched,
        };
    }).sort((a, b) => a.name.localeCompare(b.name));
    return chips;
}
function SignalCardAdditionalContent(props: SignalCardProps) {
    const matchedEntities = mapEntitiesToChips(reduceEntityHighlightsInSentences(props.feedDocument.sentences), true);
    const unmatchedEntities = mapEntitiesToChips(reduceRelatedEntitiesInSentences(props.feedDocument.sentences), false);
    const { refetch } = useFeedDocument(props.feedDocument.libraryId, props.feedDocument.documentId, true);
    const { setDialog, removeDialog } = useDialogStore(state => ({ setDialog: state.setDialog, removeDialog: state.removeDialog }));

    const entityList = distinct([...matchedEntities, ...unmatchedEntities], "id");

    function handleItemClicked(id: string) {
        setDialog(<MonitorNewOrganisationDialog defaultFeedId={props.feedDocument.libraryId} entityName={id} onClose={() => removeDialog()} onConfirm={() => {
            refetch();
            removeDialog();
        }} />);
    }
    return <Stack style={{ padding: 8, paddingLeft: 16, paddingRight: 16, background: "#FAFAFA" }} spacing={0}>
        <ResponsiveHorizontalList onItemClicked={handleItemClicked} icon={<Business />} items={entityList} />
        <ResponsiveHorizontalList icon={<SignalIcon fill="#6A6A6a" />} items={distinct(allAlertTypesFromSentences(props.feedDocument.sentences).map(type => ({
            id: type,
            name: type,
        })), ["id"])} />
    </Stack>;
}

export default function SignalCard(props: SignalCardProps) {
    const classes = useStyles({ compact: props.compact || false });
    const history = useAIHistory();
    const { selectedDocumentId, isCardSelected, setIsCardSelected } = useReaderStore();

    return (
        <Card
            onClick={(e) => {
                e.stopPropagation();
                history.pushDocument(props.feedDocument.documentId);
                setIsCardSelected(true);
            }}
            elevation={props.compact ? 0 : undefined}
            className={clsx(classes.signalCard, {
                [classes.selectedCard]: selectedDocumentId === props.feedDocument.documentId && !props.compact && isCardSelected,
            })}
        >
            <Stack spacing={1}>
                <SignalCardHeader {...props} />
                <SignalCardContent {...props} />
                <SignalCardFooter {...props} />
                {!props.compact && <SignalCardAdditionalContent {...props} />}
            </Stack>
        </Card>
    );
}
