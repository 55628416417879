import { create } from "zustand";
import { InsightDocument } from "../../hooks/queries/insights/useInsights";

interface SalesPitchEmailStoreState {
  clearInsightDocument: () => void;
  insightDocument: InsightDocument | null;
  setInsightDocument: (insightDocument: InsightDocument) => void;
}

const useSalesPitchEmailStore = create<SalesPitchEmailStoreState>((set) => ({
  clearInsightDocument: () => set({ insightDocument: null }),
  insightDocument: null,
  setInsightDocument: (insightDocument) => set({ insightDocument }),
}));

export default useSalesPitchEmailStore;
