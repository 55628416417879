import googleAnalyticsPlugin from "@analytics/google-analytics";
import { LDInspectionFlagUsedHandler } from "launchdarkly-js-client-sdk";
import pendoPlugin from "./analytic-plugins/pendo";
import Analytics, { AnalyticsPlugin } from "analytics";
import datadogRumPlugin, { CustomMethods, name } from "./analytic-plugins/datadog-rum";
import { EventAction, EventCategory, IndentifyPayload, TrackPayloadProperties } from "./analytics.types";
import { AuthUser } from "../models/user";
import { getGroups, getOrganisation, getUserId } from "./user";
import { ignoreErrors } from "./errors";

const googleV3TrackingId = process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID; // REACT_APP_GA_ID
const googleV4TrackingId = process.env.REACT_APP_ANALYTICS_GOOGLE_V4_TRACKING_ID;

const datadogRumClientToken = process.env.REACT_APP_ANALYTICS_DATADOG_RUM_CLIENT_TOKEN; // REACT_APP_DD_RUM_CLIENT
const datadogRumAppId = process.env.REACT_APP_ANALYTICS_DATADOG_RUM_APP_ID; // REACT_APP_DD_RUM_APP

const pendoApiKey = process.env.REACT_APP_ANALYTICS_PENDO_API_KEY;

const version = process.env.REACT_APP_COMMIT_SHA;

const ANALYTICS_ENABLED = (process.env.REACT_APP_ANALYTICS_ENABLED || "true").toLowerCase() === "true";

const measurementIds = [
  ...(googleV4TrackingId ? [googleV4TrackingId] : []),
  ...(googleV3TrackingId ? [googleV3TrackingId] : []),
];

const plugins: AnalyticsPlugin[] = [
  ...(measurementIds.length > 0
    ? [
        googleAnalyticsPlugin({
          measurementIds,
          customDimensions: {
            group: "dimension2",
            organisationId: "dimension3",
            organisationName: "dimension4",
            userId: "dimension1",
          },
          enabled: ANALYTICS_ENABLED,
        }),
      ]
    : []),
  datadogRumPlugin({
    applicationId: datadogRumAppId || "",
    clientToken: datadogRumClientToken || "",
    defaultPrivacyLevel: "mask",
    enabled: ANALYTICS_ENABLED && !!(datadogRumClientToken && datadogRumAppId),
    enableExperimentalFeatures: ["feature_flags"],
    premiumSampleRate: 100,
    service: "deep-insight",
    sampleRate: 100,
    trackFrustrations: true,
    trackInteractions: true,
    version: version || "",
    beforeSend: ({ error, type }) => {
      if (type === "error" && ignoreErrors.some((err) => error.message.includes(err))) {
        return false;
      }
    },
  }),
  pendoPlugin({
    apiKey: pendoApiKey || "",
    enabled: ANALYTICS_ENABLED && !!pendoApiKey,
  }),
];

const analytics = Analytics({
  plugins,
  app: "deep-insight",
});

export function createConnection(user: AuthUser): void {
  const userId = getUserId(user);
  const groups = getGroups(user);
  const { id: organisationId, name: organisationName } = getOrganisation(user);

  const traits: IndentifyPayload["traits"] = {
    userId, // Google Custom Dimension 1
    group: organisationName, // Google Custom Dimension 2
    organisationId, // Google Custom Dimension 3
    organisationName, // Google Custom Dimension 4
    groups: groups.map((g) => g.name),
  };
  analytics.identify(userId, traits);
}

export function pageView(page: string): void {
  analytics.page({ page });
}

export function analyticsEvent(category: EventCategory, action: EventAction, label: string | string[] = ""): void {
  const payload: TrackPayloadProperties = {
    action,
    category,
    label: Array.isArray(label) ? label.join(", ") : label,
  };
  analytics.track(action, payload);
}

export const featureFlagUsed: LDInspectionFlagUsedHandler["method"] = (...args): void => {
  const plugin = analytics.plugins?.[name as keyof typeof analytics.plugins] as unknown as
    | Partial<CustomMethods>
    | undefined;
  plugin?.featureFlagUsed?.(...args);
};
