import { Entity, EntityPrevalence, EntityType } from "./entity";
import { PositionedKeyPhrase } from "./keyPhrase";
import { QueryType } from "./query";

export enum FilterType {
  Sources = "sources",
  Sectors = "sectors",
  SubSectors = "subSectors",
  Locations = "locations",
  DataTypes = "dataTypes",
  Keywords = "keywords",
  PublishLocations = "publishLocations",
  RelationshipStrength = "relationshipStrength",
  Applicants = "applicants",
  Owners = "owners",
  Authors = "authors",
  AcademicPublishLocations = "academicPublishLocations",
  PublicationType = "publicationType",
  Institution = "institution",
  InstitutionCountry = "institutionCountry",
  Inventors = "inventors",
  PatentType = "patentType",
  PatentJurisdictions = "patentJurisdictions",
}

export function getEntityTypeFromFilterType(filterType: FilterType): EntityType {
  const obj = {
    [FilterType.DataTypes]: EntityType.None,
    [FilterType.Keywords]: EntityType.None,
    [FilterType.Locations]: EntityType.Country,
    [FilterType.Sectors]: EntityType.None,
    [FilterType.Sources]: EntityType.None,
    [FilterType.SubSectors]: EntityType.None,
    [FilterType.RelationshipStrength]: EntityType.None,
    [FilterType.PublishLocations]: EntityType.None,
    [FilterType.Applicants]: EntityType.None,
    [FilterType.Owners]: EntityType.None,
    [FilterType.Authors]: EntityType.None,
    [FilterType.AcademicPublishLocations]: EntityType.None,
    [FilterType.PublicationType]: EntityType.None,
    [FilterType.Institution]: EntityType.None,
    [FilterType.InstitutionCountry]: EntityType.None,
    [FilterType.Inventors]: EntityType.None,
    [FilterType.PatentType]: EntityType.None,
    [FilterType.PatentJurisdictions]: EntityType.None,
  };

  return obj[filterType];
}

export function getLabelFromFilterType(filterType: FilterType | QueryType): string {
  const obj: Record<FilterType | QueryType, string> = {
    [FilterType.DataTypes]: "Data Types",
    [FilterType.Keywords]: "Keyword",
    [FilterType.Locations]: "Locations",
    [FilterType.Sectors]: "Sectors",
    [FilterType.SubSectors]: "SubSectors",
    [FilterType.Sources]: "Sources",
    [FilterType.RelationshipStrength]: "Relationship Strength",
    [FilterType.PublishLocations]: "Published Location",
    [FilterType.Applicants]: "Applicants",
    [FilterType.Owners]: "Owners",
    [FilterType.Authors]: "Authors",
    [FilterType.AcademicPublishLocations]: "Academic Published Locations",
    [FilterType.PublicationType]: "Publication Type",
    [FilterType.Institution]: "Institution",
    [FilterType.InstitutionCountry]: "Institution Country",
    [FilterType.Inventors]: "Inventors",
    [FilterType.PatentType]: "Patent Type",
    [FilterType.PatentJurisdictions]: "Patent Jurisdictions",
    [QueryType.AllQueries]: "All",
    [QueryType.AnyQueries]: "Any",
    [QueryType.NoneQueries]: "None",
  };

  return obj[filterType];
}

export interface FilterObject {
  type: FilterType;
  label: string;
  options: FilterOption[];
}

export interface FilterOption {
  entity: Entity;
  count: number;
  selected?: boolean;
}

interface FilterCount {
  name: string;
  id: string;
  count: number;
}

export type FilterResponse = Record<FilterType, FilterCount[]>;

export interface TopicsSearchResponse {
  matches: PositionedKeyPhrase[];
}

type EntitySearch = {
  matches: EntityPrevalence[];
  maxPrevalence?: number;
};

export type EntitiesSearchResponse = Record<
  EntityType.Country | EntityType.Organisation | EntityType.Person,
  EntitySearch
>;
