import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import React from "react";
import { useAmplyfiEntityId } from "../../../../hooks/useAmplyfiEntities";
import { AmplyfiOrganisation, AmplyfiTechnology, EntityType } from "../models";
import { Entity } from "./entity/Entity";

export function GetEntity({ id, onClose }: { id: string; onClose: () => void }): JSX.Element {
  const { data: entity, isLoading, error } = useAmplyfiEntityId(id, { cacheTime: 0, refetchInterval: 0 });

  if (isLoading) {
    return <AiCircularProgress size={30} />;
  }
  if (error) {
    return <p>Error occured</p>;
  }

  if (entity) {
    const { type } = entity;
    if (type === EntityType.Organisation) {
      return <Entity<AmplyfiOrganisation> entity={entity} />;
    }
    if (type === EntityType.Technology) {
      return <Entity<AmplyfiTechnology> entity={entity} />;
    }
    return <p>Unhandled entity type: {type}</p>;
  }

  return <>hello {id}</>;
}
