import { TestDataProps } from "@amplyfi/ui-components/types";
import { createStyles, makeStyles, Menu, MenuItem, MenuListProps, Theme, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { zIndex } from "../../../helpers/componentsZIndex";
import { disableReport } from "../../../helpers/testHelpers/reportsHelper";
import { useAnalyseSelector } from "../../../store/reducers";
import {
  hasAnyEntitiesSelector,
  hasOrganisationSelector,
  updateSavePending,
  updateSelectedAnalysis,
} from "../../../store/reducers/searchRequest/urlReducer";
import { AmplyfiLinkWithQueryParams } from "../../AmplyfiLink";
import { AnalyseRoute } from "../../Navigation/analyseRoutes";
import useSavedAnalysis from "../../../hooks/useSavedAnalysis";
import { useDispatch } from "react-redux";
import AiCircularProgress from "@amplyfi/ui-components/components/CircularProgress";
import AiButton from "@amplyfi/ui-components/components/Button";
import Save from "@material-ui/icons/Save";
import useCountApi from "../../../hooks/useCountApi";
import CreateAnalysisDialog from "../../Widgets/SavedAnalysis/CreateAnalysisDialog";
import { saveContent } from "../../../helpers/analysisHelper";
import useReports from "../../../hooks/useReports";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nav: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      "& > *": {
        marginRight: 4,
      },
      justifyContent: "space-between",
      cursor: "default",
    },
    breadcrumb: {
      fontWeight: "bold",
    },
    selectedBreadcrumb: {
      fontWeight: "bold",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
    },
    selectedIcon: {
      color: theme.palette.links.primary,
      cursor: "pointer",
    },
    menuAnchor: {
      position: "absolute",
      bottom: -10,
      left: 11,
    },
    menuPaper: {
      border: `1px solid ${theme.palette.borders.main}`,
      borderRadius: 10,
    },
    menuList: {
      "& .MuiListItem-root": {
        borderBottom: `1px solid ${theme.palette.borders.main}`,
        "&:last-of-type": {
          borderBottom: 0,
        },
      },
      padding: 0,
      margin: theme.spacing(1, 1),
    },
    saveButton: {
      marginLeft: theme.spacing(3),
    },
    analysis: {
      alignItems: "center",
      display: "flex",
    },
    saveIcon: {
      marginLeft: theme.spacing(1),
    },
    countLabel: {
      color: "#748998",
      marginLeft: theme.spacing(1.5),
      textTransform: "capitalize",
    },
  })
);

export default function TopNav(): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { reports, selectedReport } = useReports(location.pathname);
  const hasEntities = useAnalyseSelector(hasAnyEntitiesSelector);
  const hasOrganisations = useAnalyseSelector(hasOrganisationSelector);
  const url = useAnalyseSelector((x) => x.searchRequest.url.parsed);
  const savePending = useAnalyseSelector((x) => x.searchRequest.url.savePending);
  const { data: docCount = 0 } = useCountApi(url);
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [items, setItems] = useState<AnalyseRoute[]>([]);
  const { savedAnalysisId } = url;
  const { data, isLoading, refetch } = useSavedAnalysis(savedAnalysisId);

  useEffect(() => {
    if (data) {
      dispatch(updateSelectedAnalysis(data));
    }
  }, [data, dispatch]);

  const handleClose = () => {
    setItems([]);
  };
  const menuRef = useCallback((node) => {
    if (node !== null) {
      setAnchorEl(node);
    }
  }, []);

  async function handleSave() {
    if (data?.name) {
      await saveContent(savedAnalysisId ?? "");
      refetch();
    } else {
      setCreateDialogOpen(true);
    }
    dispatch(updateSavePending(false));
  }

  return (
    <nav role="navigation" className={classes.nav}>
      <>
        <div className={classes.analysis}>
          <AmplyfiLinkWithQueryParams className={classes.breadcrumb} to="/analyse">
            Analyses
          </AmplyfiLinkWithQueryParams>
          <ChevronRight />
          {isLoading ? (
            <AiCircularProgress size={16} useContainer={false} />
          ) : (
            <Typography>{data?.name || "New Analysis"}</Typography>
          )}

          <AiButton
            disabled={!savePending && !!url.savedAnalysisId}
            className={classes.saveButton}
            onClick={() => handleSave()}
          >
            Save Analysis
            <Save className={classes.saveIcon} />
          </AiButton>
          <Typography className={classes.countLabel} variant="h4">
            {`${docCount.toLocaleString()} Filtered Docs`}
          </Typography>
        </div>
        {reports.length > 1 && (
          <div>
            <Typography
              data-testid="select-report-dropdown"
              onClick={(e) => {
                setItems(reports.filter((x) => x.to !== selectedReport?.to));
              }}
              className={classes.selectedBreadcrumb}
            >
              {selectedReport?.title ?? "Select a report"}
              <span style={{ position: "relative", display: "flex" }}>
                <KeyboardArrowDownIcon className={classes.selectedIcon} />
                <span ref={menuRef} className={classes.menuAnchor} />
              </span>
            </Typography>
            <Menu
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={items.length > 0}
              anchorEl={anchorEl}
              onClose={handleClose}
              getContentAnchorEl={null}
              PaperProps={{
                className: classes.menuPaper,
              }}
              MenuListProps={
                {
                  className: classes.menuList,
                  "data-testid": "navmenu",
                } as MenuListProps & Partial<TestDataProps>
              }
              style={{ zIndex: zIndex.modalAndMenu }}
            >
              {items.map((report) => (
                <MenuItem
                  disabled={disableReport(
                    hasOrganisations,
                    report?.requiresOrganisations,
                    hasEntities,
                    report?.requiresEntities
                  )}
                  onClick={() => {
                    handleClose();
                    history.push({ pathname: report.to, search: window.location.search });
                  }}
                  key={report.to}
                >
                  {report.title}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
        {createDialogOpen && (
          <CreateAnalysisDialog
            onClose={() => {
              setCreateDialogOpen(false);
            }}
            avoidRedirection
          />
        )}
      </>
    </nav>
  );
}
