import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { Silver } from "@amplyfi/ui-components/theme/colors";
import { useButtonStyles } from "../../../css/mixins";

interface FeedbackTextButtonProps {
  handleClick: () => void;
}
const useStyles = makeStyles(() =>
  createStyles({
    feedback: {
      textAlign: "right",
      // width: "100%",
    },
    feedbackBtn: {
      color: Silver,
      cursor: "pointer",
    },
  })
);

export default function FeedbackTextButton(props: FeedbackTextButtonProps): JSX.Element {
  const classes = useStyles();
  const mixinClasses = useButtonStyles();
  const clearButtonStyling = mixinClasses.clearButtonStyling;
  const { handleClick } = props;

  return (
    <div className={classes.feedback}>
      <button type="button" className={clsx(classes.feedbackBtn, clearButtonStyling)} onClick={handleClick}>
        Give Feedback
      </button>
    </div>
  );
}
