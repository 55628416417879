import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    score: (props: SentimentScoreProps) => {
      return {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        height: props.height ? props.height : undefined,
      };
    },
  })
);

interface SentimentScoreProps {
  sentiment: number;
  colour: string;
  height?: number;
}

export default function SentimentScore(props: SentimentScoreProps): JSX.Element {
  const classes = useStyles(props);
  const { sentiment, colour } = props;
  return (
    <Typography variant="body2" className={classes.score}>
      {sentiment > 0 ? "+" : "-"}
      {Math.abs(sentiment).toFixed(2)}
      {sentiment > 0 ? <ArrowDropUpIcon style={{ color: colour }} /> : <ArrowDropDownIcon style={{ color: colour }} />}
    </Typography>
  );
}
