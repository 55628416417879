import FeedbackMenu, { FeedbackStatus } from "@amplyfi/ui-components/components/Feedback/index";
import AnalyseTooltip from "@amplyfi/ui-components/components/Tooltip";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../helpers/analytics";
import { useAnalyseSelector } from "../../store/reducers";
import { zIndex } from "../../helpers/componentsZIndex";
import { setSnack } from "../../store/reducers/ui/snackReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: "0 30px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    tooltipStyle: {
      zIndex: zIndex.modalAndMenu,

      "& .MuiTooltip-tooltip": {
        padding: theme.spacing(1.5),
      },
    },
  })
);

export default function DocumentFeedback(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { selectedDocumentId, selectedDocumentLoading, firstResultLoaded, selectedDocumentError } = useAnalyseSelector(
    (x) => x.searchResponse.relatedDocumentViewer
  );
  const sendFeedback = ({ status, message }: { status: FeedbackStatus; message?: string }) => {
    const element = document.querySelector("[data-testid='related-document-viewer__document__title']");
    const title = element?.textContent ?? "";
    const analyticsMessage = `{
      "message": "${message}",
      "document Id": "${selectedDocumentId}",
      "document title": "${title}",
    }`;
    analyticsEvent(
      "Document",
      status === FeedbackStatus.Postive ? "PositiveFeedbackRaised" : "NegativeFeedbackRaised",
      analyticsMessage
    );
    dispatch(
      setSnack({
        autoHideDuration: 2000,
        title: "Feedback sent",
      })
    );
  };

  const isDisabled = !firstResultLoaded || selectedDocumentLoading || selectedDocumentError;

  return (
    <div className={classes.container}>
      <AnalyseTooltip
        title="Give Feedback"
        classes={{ popper: classes.tooltipStyle }}
        disableHoverListener={isDisabled}
      >
        <div>
          <FeedbackMenu sendFeedback={sendFeedback} disabled={isDisabled} zIndex={zIndex.documentViewerFeedback} />
        </div>
      </AnalyseTooltip>
    </div>
  );
}
