import IconButton from "@amplyfi/ui-components/components/IconButton/index";
import Legend from "@amplyfi/ui-components/components/Legends/index";
import { Grey } from "@amplyfi/ui-components/theme/colors";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { textEllipses } from "../../../css/mixins";
import { ActiveOption } from "../../../models/chart-customizations";
import { EntityType } from "../../../models/entity";
import { entityTypeMapColor } from "./helpers/entityTypeMapColor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeList: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 0,
      overflowY: "auto",
      flex: "1 1 auto",
    },
    activeOptionListItem: {
      display: "flex",
      marginBottom: theme.spacing(1),
      "&:last-of-type": {
        marginBottom: 0,
      },
      border: `1px solid ${theme.palette.borders.main}`,
      background: theme.palette.accent.main,
      justifyContent: "space-between",
      padding: theme.spacing(0.5, 1),
      alignItems: "center",
      borderRadius: 10,
    },
    activeNameContainer: {
      display: "flex",
      alignItems: "center",
    },
    activeCancelIcon: {
      cursor: "pointer",
      color: Grey,
      fontSize: 13.75,
    },
    activeText: {
      marginLeft: theme.spacing(),
      ...textEllipses,
    },
    icon: {
      transform: "rotate(90deg)",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: theme.spacing(1),
    },
    iconContainer: {
      marginLeft: theme.spacing(1),
    },
  })
);

interface CustomizationSelectedOptionsProps {
  selectedOptions: ActiveOption[];
  setSelectedOptions: (args: ActiveOption[]) => void;
}

export default function CustomizationSelectedOptions(props: CustomizationSelectedOptionsProps): JSX.Element {
  const classes = useStyles();
  const { selectedOptions, setSelectedOptions } = props;

  return (
    <ul className={classes.activeList}>
      {selectedOptions.map((option) => (
        <li className={classes.container} key={option.id}>
          <Legend label={option.name} color={entityTypeMapColor[option.type ?? EntityType.None]} fixedWidth={false} />
          <span className={classes.iconContainer}>
            <IconButton
              onClick={() => {
                setSelectedOptions(selectedOptions.filter((x) => x !== option));
              }}
              icon={<CloseIcon className={classes.activeCancelIcon} />}
            />
          </span>
        </li>
      ))}
    </ul>
  );
}
