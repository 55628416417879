import moment from "moment";
import { TimeFilterDays } from "../store/hooks/useFiltersStore";

export type AiDate = moment.Moment | null;

type Validator = {
  fn: (date: NonNullable<AiDate>, lowerBound?: AiDate) => boolean;
  msg: string;
};

const validators: Validator[] = [
  {
    fn: (date) => date.isValid(),
    msg: "Must be a valid date",
  },
  {
    fn: (date) => !date.isAfter(undefined, "day"),
    msg: "Must not be in the future",
  },
  {
    fn: (date, lowerBound) => (lowerBound ? !date.isBefore(lowerBound, "day") : true),
    msg: "Must not be before start",
  },
];

export const dateError = (date: AiDate, lowerBound?: AiDate): string | false =>
  (date && validators.find(({ fn }) => !fn(date, lowerBound))?.msg) || false;

export function dateGranularity(date: moment.Moment): moment.Moment;
export function dateGranularity(date: null): undefined;
export function dateGranularity(date?: moment.Moment | null): moment.Moment | undefined;
export function dateGranularity(date?: moment.Moment | null): moment.Moment | undefined {
  return date?.set({ milliseconds: 0, seconds: 0, minute: 0, hour: 0 });
}

interface DateFormatOptions {
  inputFormat?: string;
  outputFormat?: string;
  friendlyWithinDays?: number;
  relativePrefix?: string;
  absolutePrefix?: string;
}

export const dateFormat = (d: string, { inputFormat, outputFormat = "DD/MM/YYYY" }: DateFormatOptions = {}): string =>
  moment.utc(d, inputFormat).format(outputFormat);

export const friendlyDateFormat = (
  date: string,
  { friendlyWithinDays = 7, relativePrefix = "", absolutePrefix = "", ...options }: DateFormatOptions = {}
): string => {
  const utcDate = moment.utc(date, options.inputFormat);
  const lowerBound = moment.utc().subtract(friendlyWithinDays, "days");
  return (
    utcDate.isAfter(lowerBound)
      ? [...(relativePrefix ? [relativePrefix] : []), utcDate.fromNow()]
      : [...(absolutePrefix ? [absolutePrefix] : []), dateFormat(date, options)]
  ).join(" ");
};

export const getStartOfPastDayUtc = (timeFilter: TimeFilterDays): string | undefined =>
  timeFilter ? moment.utc().subtract(timeFilter, "days").startOf("day").toISOString() : undefined;
