import AiChip from "@amplyfi/ui-components/components/Chip";
import { LightSilver } from "@amplyfi/ui-components/theme/colors";
import { testId } from "@amplyfi/ui-components/testHelpers";
import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";

interface UnverifiedChipProps {
  id: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    chipUnverified: {
      background: LightSilver,
      color: "inherit",
      "& .MuiChip-label": {
        color: "#000",
      },
    },
  })
);

export function UnverifiedChip({ id }: UnverifiedChipProps): JSX.Element {
  const classes = useStyles();

  return (
    <AiChip
      data-testid={testId("entity-knowledge-base", "entity", "unverified")}
      key={`entity-${id}-is-unverified`}
      className={classes.chipUnverified}
      label="Unverified"
      size="small"
    />
  );
}
